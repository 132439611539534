import { InvokeLambdaStep } from "../ssm/invokelambdastep";
import { StepTypes } from "./strings";
import {
  ActionNodeOperationDetails,
  readLambdaPayload,
} from "./actionnodestep";
import { readLambdaParam, RunbookStepInput } from "../ssm/nodeinputoutput";
import { ParameterType } from "../ssm/strings";
import { awsFriendlyName } from "../ssm/util";

export class LoopStep extends InvokeLambdaStep {
  static fromControlNodeDefinition(snippetDef) {
    return LoopStep.createNewLoopStep(snippetDef, "ec2", "describe_instances");
  }
  static createNewLoopStep(
    snippetDef,
    service,
    operation,
    operationDescription,
  ) {
    const name = `LoopForEach_${Math.floor(1000 + 9000 * Math.random())}`;
    const payload = LoopStep._makeLambdaPayload(name, service, operation);
    const json = {
      name,
      action: "aws:invokeLambdaFunction",
      inputs: {
        FunctionName: snippetDef.content.lambda_arn,
        Payload: payload,
      },
      maxAttempts: 1,
      onFailure: "Abort",
    };

    const loopStep = new LoopStep(json, snippetDef);
    return loopStep;
  }

  static _makeLambdaPayload(name, service, operation) {
    const json = {
      StepName: name + "LoopPause",
      AutomationExecutionId: "{{automation:EXECUTION_ID}}",
      workflow_session: "{{ WorkflowSession}} ",
      items: [],
      parameter: "InstanceIds",
      command: {
        aws_call: {
          service,
          operation,
        },
      },
    };

    return JSON.stringify(json);
  }

  constructor(stepJSON, snippetDef) {
    super(stepJSON);
    this.stepType = StepTypes.LoopStep;
    this.editable = true;
    this.lambdaPayload = readLambdaPayload(stepJSON);
    this.service = this.lambdaPayload?.command?.aws_call?.service || "";
    this.operation = this.lambdaPayload?.command?.aws_call?.operation || "";
    this.parameter = this.lambdaPayload?.parameter || "";
    this.items = this.lambdaPayload?.items || [];
    this.actionNodeDef = snippetDef;
    this.lambda_arn = snippetDef ? snippetDef.content.lambda_arn : {};
    this.postprocess_arn = snippetDef ? snippetDef.content.postprocess_arn : "";
    this.outputs = [];
    this.snippetDef = snippetDef;
    this.parameterInputs = [];
    this.editorNodeId = snippetDef.editorNodeId;
  }

  setParameter = parameter => {
    this.parameter = parameter;
    this.lambdaPayload.parameter = parameter;
    this.parameterInputs =
      this.allParameterInputs?.filter(input => input.name !== parameter) ||
      this.parameterInputs;
  };

  setService = service => {
    this.service = service;
    this.lambdaPayload.command.aws_call.service = service;
  };

  setOperation = operation => {
    this.operation = operation;
    this.lambdaPayload.command.aws_call.operation = operation;
  };

  setItemsSource = inputSource => {
    this.itemsSource = inputSource;
  };

  setOperationDetails = operationDetails => {
    if (!operationDetails.attachToSSMStep) {
      operationDetails = new ActionNodeOperationDetails(operationDetails);
    }
    operationDetails.attachToSSMStep(this);
    this.operationDetails = operationDetails;
    this.allParameterInputs = this.operationDetails.parameterInputs;
    this.parameterInputs = this.operationDetails.parameterInputs.filter(
      input => input.name !== this.parameter,
    );
    if (!this.allParameterInputs.find(pi => pi.name === this.parameter)) {
      this.parameter = "";
    }
    this.outputs = this.operationDetails.outputs;
  };

  pauseNodeName = () => {
    return `${this.name}LoopPause`;
  };

  postProcessNodeName = () => {
    return `${this.name}LoopPostProcess`;
  };

  writeItemsValue = () => {
    let val = "[]";
    switch (this.itemsInput?.source?.type) {
      case "userProvided":
        val = `{{ ${awsFriendlyName(
          this.itemsInput.source.sourceValue.name,
        )} }}`;
        break;
      case "constant":
        val =
          typeof this.itemsInput.source.sourceValue === "string"
            ? this.itemsInput.source.sourceValue
            : JSON.stringify(this.itemsInput.source.sourceValue);
        break;
      case "snippetOutput":
      case "actionNode":
        val = this.itemsInput.source.sourceValue.getSSMPayloadValue();
        break;
      default:
        break;
    }
    return val;
  };
  writeStaticParameters = () => {
    return this.writeInputParams();
  };

  writeLambdaPayload = () => {
    const str = `{
      "alias": "{{alias}}",
      "region_name": "{{regionName}}",
      "workflow_session": "{{ WorkflowSession}} ",
      "StepName": "${this.pauseNodeName()}",
      "AutomationExecutionId": "{{automation:EXECUTION_ID}}",
      "items": ${this.writeItemsValue()},
      "parameter": "${this.parameter}",
      "static_parameters": ${this.writeStaticParameters()},
      "command": {
        "aws_call": {
          "service": "${this.service}",
          "operation": "${this.operation}"
        }
      }
    }`;
    return str;
  };

  allInputs = () => [this.itemsInput, ...this.parameterInputs];

  readLoopParameterSourceFromSSM = runbook => {
    const itemsInputSource = readLambdaParam(runbook, this.items);
    if (itemsInputSource[0].type !== "deleted") {
      this.itemsInput = new RunbookStepInput(
        this,
        "items",
        ParameterType.StringList,
        true,
        itemsInputSource[0],
      );
    }
    return;
  };

  readInputSources = runbook => {
    this.readLoopParameterSourceFromSSM(runbook);
    const inputsWithSources = readActionNodeInputSourcesFromSSM(
      this.lambdaPayload,
      runbook,
    );

    this.parameterInputs = this.parameterInputs || [];

    let inputName;
    for (inputName of Object.keys(inputsWithSources)) {
      // -isarray is coming from readActionNodeInputSourcesFromSSM
      // and it;s used to know if the value of current key name was an array
      if (inputName.includes("-isarray")) continue;
      // eslint-disable-next-line no-loop-func
      let found = this.parameterInputs.find(input => input.name === inputName);
      if (!found) {
        const input = new RunbookStepInput(
          this,
          inputName,
          inputsWithSources[`${inputName}-isarray`]
            ? ParameterType.StringList
            : ParameterType.String,
          true, // we don't know if it is required yet, we'll set this when we get API data
          inputsWithSources[inputName],
        );
        this.parameterInputs.push(input);
      }
    }
  };

  consumedOutputs = () => {
    const baseOutputs = super.consumedOutputs();
    if (
      this.itemsInput.source.type === "snippetOutput" ||
      this.itemsInput.source.type === "actionNode"
    ) {
      baseOutputs.push(this.itemsInput.source.sourceValue);
    }
    return baseOutputs;
  };

  invokeStepSSM = () => {
    return {
      action: "aws:invokeLambdaFunction",
      inputs: {
        FunctionName: this.lambda_arn,
        Payload: this.writeLambdaPayload(),
      },
      outputs: [
        {
          Name: "executionId",
          Selector: "$.state_machine_execution_id",
          Type: "String",
        },
      ],
      isEnd: false,
      maxAttempts: 1,
      name: this.name,
      nextStep: this.pauseNodeName(),
      onFailure: "Abort",
    };
  };

  pauseStep = () => ({
    name: this.pauseNodeName(),
    action: "aws:pause",
    inputs: {},
    nextStep: this.postProcessNodeName(),
  });

  postProcessStep = () => ({
    name: this.postProcessNodeName(),
    action: "aws:invokeLambdaFunction",
    inputs: {
      FunctionName: this.postprocess_arn,
      Payload: `{"StateMachineExecutionId": "{{${this.name}.executionId}}"}`,
    },
    outputs: [
      {
        Name: "StateMachineOutput",
        Selector: "$.Payload.output",
        Type: "String",
      },
    ],
    isEnd: !this.nextStep,
    nextStep: this.nextStep,
  });

  toSSM = () => [
    this.invokeStepSSM(),
    this.pauseStep(),
    this.postProcessStep(),
  ];

  writeInputParams = () => {
    const inputlist = this.parameterInputs
      .map(input => input.writeInputParam())
      .filter(param => !!param)
      .join(", ");
    return `{ ${inputlist} }`;
  };

  isHealthyStep() {
    let item = this.itemsInput ? this.itemsInput.source : null;
    if (item && (item.type === "actionNode" || item.type === "constant")) {
      if (
        (Array.isArray(item?.sourceValue) && item.sourceValue.length === 0) ||
        item.sourceValue === null
      ) {
        return false;
      }
    }
    return true;
  }
}

function readActionNodeInputSourcesFromSSM(lambdaPayload, runbook) {
  const sources = {};

  const inputs = lambdaPayload.static_parameters;
  let name;
  for (name of Object.keys(inputs || [])) {
    const readLambdaParamReturnValue = readLambdaParam(runbook, inputs[name]);

    const hasInput = name in inputs;
    const source = hasInput && readLambdaParamReturnValue[0];
    if (source) {
      sources[name] = source;
      sources[`${name}-isarray`] = readLambdaParamReturnValue[1];
    }
  }
  return sources;
}
