import React from "react";
import * as Animation from "./animation";

export function findText() {
  let foundClassname,
    foundElement,
    foundContainer,
    highlightedText,
    nonHighlightedText1,
    nonHighlightedText2;
  const text = this.props.text || this.props.children;
  //eslint-disable-next-line
  const string = this.props.searchValue.replace(
    /[-/\\^$*+?.()|[\]{}]/g,
    "\\$&",
  );
  const regEx = new RegExp(`${string}`, "gi");
  if (
    this.props.searchValue.length >= this.props.searchMin &&
    regEx.test(text)
  ) {
    foundClassname = "select-search-found";
    foundElement = true;
    foundContainer = "select-search-found-container";
    this.props.scrollToResult();
    const highlightResult = highlightText.call(this, text);
    highlightedText = highlightResult.highlightedText;
    nonHighlightedText1 = highlightResult.nonHighlightedText1;
    nonHighlightedText2 = highlightResult.nonHighlightedText2;
  } else {
    foundClassname = "";
    foundElement = false;
    foundContainer = "";
    highlightedText = "";
    nonHighlightedText1 = this.state.originalText;
    nonHighlightedText2 = "";
  }
  this.setState({
    foundClassname,
    foundElement,
    foundContainer,
    highlightedText,
    nonHighlightedText1,
    nonHighlightedText2,
  });
}

function highlightText(text) {
  if (text) {
    const indexStart = this.state.originalText
      .toLowerCase()
      .indexOf(this.props.searchValue.toLowerCase());
    const indexEnd = indexStart + this.props.searchValue.length;
    const searchText = this.state.originalText.substring(indexStart, indexEnd);
    const nonHighlightedText1 = this.state.originalText.substring(
      0,
      indexStart,
    );
    const nonHighlightedText2 = this.state.originalText.substring(indexEnd);
    return {
      highlightedText: searchText,
      nonHighlightedText1,
      nonHighlightedText2,
    };
  }
}

function setNextTab(element) {
  let aTag = element.getElementsByTagName("a")[0];

  function tabListener(e) {
    if (e.key === "Tab") {
      try {
        e.preventDefault();
        aTag.focus();
        document.removeEventListener("keydown", tabListener, false);
      } catch (e) {
        console.log(e);
      }
    }
  }
  document.addEventListener("keydown", tabListener, false);
}

export function getText(jsx) {
  let type = typeof jsx;
  if (type === "object") {
    return jsx.props.children;
  } else if (type === "string") {
    return jsx;
  }
  return jsx;
}

export function scrollToResult() {
  clearTimeout(this.timer);
  this.timer = setTimeout(() => {
    if (this.state.searchValue !== this.scrollToText) {
      const element = this.ref.current.querySelector(`.select-search-found`);
      if (element) {
        const options = {
          block: "center",
          alignToTop: true,
          behavior: "smooth",
        };
        element.scrollIntoView(options);
        setNextTab.call(this, element);
      }
      this.scrollToText = this.state.searchValue;
    }
  }, 100);
}

export function setStyle() {
  let style = this.props.style;

  if (this.props.icon) {
    const iconStyle = {
      backgroundImage: `url(${this.props.icon})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "16px 16px",
      backgroundPosition: "5px 50%",
      paddingLeft: "25px",
    };
    style = { ...style, ...iconStyle };
  }
  if (this.props.style.hasOwnProperty("width")) {
    this.setState({
      width: this.props.style.width,
    });
  }
  this.setState({
    style,
  });
}

/* used by Select to instaniate child SelectOption components */
export function initChildren() {
  return React.Children.map(this.props.children, child => {
    if (this.props.value === child.props.value) {
      this.matchedSelectedValue = true;
    }
    return React.cloneElement(child, {
      onClick: this.onClick,
      selectedValue: this.props.value,
      setDisplay: this.setDisplay,
      setValue: this.setValue,
      searchValue: this.state.searchValue,
      searchMin: this.props.searchMin,
      scrollToResult: this.scrollToResult,
      expanded: this.state.toggled,
      filterOnSearch: this.props.filterOnSearch,
      optionHeight: this.props.optionHeight,
    });
  });
}

/* used by Select to ininitialize the total height of the select component */
export function calculateHeight(children) {
  const optionHeight = parseInt(this.props.optionHeight);
  let totalHeight = children ? children.length * optionHeight : 0;
  if (!this.props.allowEmpty) {
    totalHeight -= optionHeight;
  }
  if ((this.props.required && this.props.requiredText) || this.props.title) {
    totalHeight += optionHeight;
  }
  return totalHeight + 1;
}

export function selectFirstInstance() {
  const element = getFirstInstance.call(this);
  if (element) {
    this.setState(
      {
        searchSuccess: true,
      },
      () => {
        const value = element.dataset.value;
        const text = element.dataset.text;
        this.onClick(value, text);
      },
    );
  }
}

function getFirstInstance() {
  return this.ref.current.querySelector(`.select-search-found`);
}

export function setZIndex() {
  const elements = document.querySelectorAll(".overlay");
  let count = 0;
  for (let i = elements.length; i > 0; i--) {
    const element = elements[count];
    const zIndex = i * 1000;
    element.style.zIndex = zIndex;
    count++;
  }
}

export function initBorder() {
  if (this.props.animationBorder === "none") {
    return;
  } else if (this.props.parent) {
    createBorder.call(this);
  }
}

export function setContainerBorder() {
  const wrapperDiv = this.ulRef.current;
  const titleBarDiv = this.titleBarRef.current;
  const titleBarContracted =
    this.props.required && !this.state.value
      ? "title-bar-contracted-required"
      : "title-bar-contracted";
  if (this.state.toggled) {
    addRemoveCssClass(
      wrapperDiv,
      "select-container-expanded",
      "select-container-contracted",
    );
    addRemoveCssClass(titleBarDiv, "title-bar-expanded", titleBarContracted);
  } else {
    addRemoveCssClass(
      wrapperDiv,
      "select-container-contracted",
      "select-container-expanded",
    );
    addRemoveCssClass(titleBarDiv, titleBarContracted, "title-bar-expanded");
  }
}

function createBorder() {
  const element = this.props.parent;
  Animation.createAnimatedBorder(element);
}

export function removeFoundFormatting() {
  this.performSearch("");
}

export function getInlineStyle() {
  if (
    !this.state.foundElement &&
    this.props.searchValue.length >= this.props.searchMin &&
    this.props.filterOnSearch
  ) {
    return {
      display: "none",
      height: `${this.props.optionHeight}px`,
    };
  }
  return {
    height: `${this.props.optionHeight}px`,
  };
}

function addRemoveCssClass(div, add, remove) {
  div.classList.remove(remove);
  div.classList.add(add);
}

export const setIconImage = propsIcon => {
  if (propsIcon) {
    const icon = propsIcon.match(/\./g) ? propsIcon : `${propsIcon}.svg`;
    return require(`@assets/images/icons/${icon}`);
  }
};
