import React from "react";
import { Consumer } from "../../../../../runbook-editor-lib/runbook-editor.context";
import Snippet from "./Snippet";
import { getBlacklistConnectorList } from "@lib/utils";

export default class SnippetSnippetsList extends React.Component {
  render() {
    return (
      <Consumer>
        {({ snippets, connectors }) => {
          const blacklistConnectorList = getBlacklistConnectorList(connectors);
          return Object.keys(snippets?.SNIPPET || {})
            .filter(snippet => snippet !== "Execution")
            .map((snippetName, index) => {
              return (
                <Snippet
                  snippets={snippets}
                  snippetName={snippetName}
                  key={snippetName + index}
                  searchVal={this.props.searchVal}
                  blacklistConnectorList={blacklistConnectorList}
                />
              );
            });
        }}
      </Consumer>
    );
  }
}
