import React, { useState } from "react";
import { useSelector } from "react-redux";
import getInstructions from "./utils/InstructionHelper.tsx";
import GetStarted from "@components/shared/GetStarted/GetStartedForm";
import ConnectorForm from "./utils/ConnectorForm";
import { HeaderMain } from "@components/modules";

export default function CloudwatchSetup() {
  const aliasList = useSelector(
    state => state.getStartedReducer.userDefaultAccounts,
  );
  const awsAccountId = useSelector(
    state => state.appReducer.appConfig.awsAccountId,
  );
  const [alias, setAlias] = useState(aliasList.find(alias => alias.is_default));

  let instructionPanel = getInstructions({
    alias: alias,
    awsAccountId: awsAccountId,
  });

  return (
    <GetStarted
      instructionPanel={instructionPanel}
      connectorForm={<ConnectorForm aliasChanged={setAlias} alias={alias} />}
      showCustomForm={true}
      customHeader={<HeaderMain />}
      customContainerClass={`cw-form-container`}
      customTitleClass={`cw-form-title`}
      customPanelClass={`cw-form-panel`}
      customPanelLeftClass={`cw-form-left`}
    />
  );
}
