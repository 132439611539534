import React from "react";
import "./NoResults.scss";
import NoSearchImg from "@assets/images/icons/search-es.svg";

type NoSearchResultFoundProps = {
  message: string;
  detail?: string;
  errImgSrc?: string;
  className?: string;
  fallbackComponent?: React.ReactNode;
};

const NoResultFound: React.FC<NoSearchResultFoundProps> = ({
  message = "No Results Found",
  detail = "",
  errImgSrc = "",
  className = "",
  fallbackComponent,
}) => {
  const ShowErrorImg = () => {
    if (errImgSrc) {
      return (
        <img
          src={require(`@assets/images/icons/${errImgSrc}`)}
          alt="error"
          height="72"
          width="72"
        />
      );
    }
    return <img src={NoSearchImg} alt="error" height="72" width="72" />;
  };

  return (
    <div className={`no-result-container ${className}`}>
      <ShowErrorImg />
      <p className="no-result-title"> {message} </p>
      <p className="no-result-detail"> {detail || null} </p>
      {fallbackComponent}
    </div>
  );
};

export default NoResultFound;
