import React from "react";
import ConfigureMapItem from "./ConfigureMapItem";
import { Consumer } from "./output-configure.context";

export default class ConfigureOutput extends React.PureComponent {
  state = {
    outputObjectArray: [],
  };

  componentDidMount() {
    this.composeOutputOptions();
  }

  composeOutputOptions = () => {
    console.log(this.props.outputString);
    let item = this.props.outputString.split(".");
    item.splice(0, 2);
    this.setState({
      outputObjectArray: item,
    });
  };

  getValue = item => {
    const regEx = new RegExp("\\[(.?)\\]");
    if (regEx.test(item)) {
      return item.match(regEx)[1];
    } else {
      return "*";
    }
  };

  formatItem = (item, index) => {
    const regEx = new RegExp(/\[(.?)\]/);
    const style = {
      marginLeft: index * 10 + "px",
      paddingTop: "8px",
      paddingBottom: "8px",
      display: "flex",
      alignItems: "center",
      height: "48px",
      borderBottom: "1px solid #555",
    };
    let leading = "";
    if (index) {
      leading = "-";
    }
    if (regEx.test(item)) {
      let label = item.replace(/\[(.*)\]/, "");
      return (
        <div style={style} key={label}>
          <ConfigureMapItem
            label={label}
            leading={leading}
            value={this.getValue(item)}
            itemKey={item}
          />
        </div>
      );
    } else {
      return (
        <div style={style} key={item}>
          {leading} {item}
        </div>
      );
    }
  };

  render() {
    return (
      <Consumer>
        {({ saveOutput, returnToTree }) => (
          <div className="configue-output__wrap">
            <div>
              <div className="mb-20 pt-10">
                The following JSON path with * (wild card) results in selection
                of all elements in an array. To select an individual element,
                replace the * with an index number. For instance, index 0 maps
                to the first element of the array.
              </div>
            </div>
            {this.state.outputObjectArray.map((outputItem, index) => {
              return this.formatItem(outputItem, index);
            })}
            <div className="modal-buttons-footer__editor pt-20">
              <button
                type="button"
                className="footer-btn footer-btn-cancel"
                onClick={returnToTree}
              >
                Go Back
              </button>
              <button
                type="button"
                className="footer-btn footer-btn-save"
                onClick={saveOutput}
              >
                Save Output
              </button>
            </div>
          </div>
        )}
      </Consumer>
    );
  }
}
