import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DataTable } from "@components/ui/data-table";
import tableColumns from "./tableColumns";
import { SettingsPanelHeader } from "../settings-panel-components";

import { Wait, Confirm, FTNotification } from "@components/ui";
import ManageS3Bucket from "./ManageS3Bucket";
import { toggleDeleteConfirmBox } from "@redux/actions/AWSSettings.actions";
import { isFetching } from "@redux/actions/settingsPanel.action";
import Api from "@lib/api/api";

type S3BucketsProps = {};

const S3Buckets: React.FC<S3BucketsProps> = () => {
  const dispatch = useDispatch();
  const showConfirm = useSelector(state => state.uiReducer.showConfirm);
  const [selectedS3Bucket, setSelectedS3Bucket] = useState(undefined);
  const loadingMessage = useSelector(state => state.runbooksReducer.message);
  const [selectedS3BucketList, setSelectedS3BucketList] = useState([]);
  const [showManageS3BucketForm, setShowManageS3BucketForm] = useState(false);
  const aliasList = useSelector(
    state => state.getStartedReducer.userDefaultAccounts,
  );

  const toggleS3BucketFormVisibility = () => {
    setShowManageS3BucketForm(true);
  };

  const onRemoveHandler = row => {
    setSelectedS3Bucket(row);
    dispatch(toggleDeleteConfirmBox(true));
  };

  const s3Col = tableColumns(onRemoveHandler);

  /**
   * Loop over the list of target accounts(alias) to fetch
   * the list of s3 buckets selected corresponding to each
   * alias on Settings page
   */

  const fetchSelectedS3BucketList = useCallback(async () => {
    let promises = [];
    aliasList.forEach(alias => {
      promises.push(Api.fetchSelectedS3BucketList(alias.alias));
    });
    try {
      dispatch(isFetching(true, "Fetching S3 Buckets"));
      let response = await Promise.all(promises);
      let s3BucketList = [];
      response.forEach((res, i) => {
        let s3BucketListResponse =
          typeof res.body === "string" ? JSON.parse(res.body) : res.body;
        s3BucketList = s3BucketList.concat(
          s3BucketListResponse.map(obj => {
            obj["alias"] = aliasList[i]["alias"];
            return obj;
          }),
        );
      });
      dispatch(isFetching(false, ""));
      setSelectedS3BucketList(s3BucketList);
    } catch (e) {
      dispatch(isFetching(false, ""));
      console.log(e);
    }
  }, [aliasList, dispatch]);

  useEffect(() => {
    fetchSelectedS3BucketList();
  }, [fetchSelectedS3BucketList]);

  return (
    <>
      {!!loadingMessage && <Wait text={loadingMessage} />}
      {showConfirm && (
        <Confirm
          heading="Delete Confirmation"
          cancelText="Cancel"
          confirmText="Delete"
          message="The entry for this S3 bucket will be deleted."
          confirmValue={selectedS3Bucket ? selectedS3Bucket.Name : ""}
          confirmRequiredText={`Confirm by typing '${
            selectedS3Bucket ? selectedS3Bucket.Name : ""
          }' below`}
          verifiedCallback={async () => {
            dispatch(isFetching(true, "Deleting S3 Bucket"));
            try {
              dispatch(toggleDeleteConfirmBox(false));
              await Api.removeS3Bucket(
                selectedS3Bucket?.alias,
                selectedS3Bucket?.Name,
              );
              setSelectedS3BucketList(
                selectedS3BucketList.filter(
                  s3Bucket => s3Bucket.Name !== selectedS3Bucket.Name,
                ),
              );
              FTNotification.success({
                title: "S3 Bucket Deleted Successfully!",
              });
            } catch (error) {
              FTNotification.error({
                title: "Could not delete S3 Bucket",
                message: error.message,
              });
            }
            dispatch(isFetching(false, ""));
          }}
        />
      )}
      {showManageS3BucketForm && (
        <ManageS3Bucket
          closeForm={setShowManageS3BucketForm}
          fetchSelectedS3BucketList={fetchSelectedS3BucketList}
        />
      )}
      <div className="d-flex flex-column2">
        <SettingsPanelHeader
          isBreadCrumb={true}
          page="s3-buckets"
          heading="S3 Buckets"
          breadCrumbText="Settings"
          toggleS3BucketFormVisibility={toggleS3BucketFormVisibility}
        />
        <div className="mt-40 ml-15 gr-list-container aws-settings-list">
          <DataTable
            className="snippet-list-table"
            columns={s3Col}
            data={selectedS3BucketList}
            responsive={true}
            fixedHeader={true}
          />
        </div>
      </div>
    </>
  );
};

export default S3Buckets;
