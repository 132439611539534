import { SSMActionNames } from "./strings";
import { Step } from "./steps";
import moment from "moment";

export class SleepStep extends Step {
  static ACTION = SSMActionNames.SLEEP;
  constructor(stepJSON) {
    super(stepJSON);
    this.iso8601Duration = moment.duration(stepJSON.inputs.Duration);
    this.untilTimestamp = stepJSON.inputs.Timestamp;
  }
}
