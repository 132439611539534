import React from "react";
import { Tab, TabCollection, TabContent } from "@components/ui/tabs";
import Output from "../../output";
import Details from "../../details";
import RequiredInputs from "./Helpers/RequiredInputs";
import { ControlNames } from "@containers/RunbookEditor/runbook-editor-lib/neuropssteps/strings";

type OpsgenieProps = {};

const Opsgenie: React.FC<OpsgenieProps> = () => {
  return (
    <TabCollection
      active="input"
      activeClassName="editor-detail-tab-wrap editor-detail-active-tab"
      inactiveClassName="editor-detail-tab-wrap editor-detail-inactive-tab"
      contentTop={50}
    >
      <Tab title="Input" name="input">
        <TabContent>
          <h4 className="editor-node-name">{ControlNames.Opsgenie}</h4>
          <RequiredInputs />
        </TabContent>
      </Tab>
      <Tab title="Output" name="output">
        <TabContent>
          <Output />
        </TabContent>
      </Tab>
      <Tab title="Details" name="details">
        <TabContent>
          <Details />
        </TabContent>
      </Tab>
    </TabCollection>
  );
};

export default Opsgenie;
