import React from "react";
import "./profile.scss";
import LeftPanel from "./get-started-components/left-panel";
import RightPanel from "./get-started-components/RightPanel";
import { Wait } from "@components/ui";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const GetStarted = props => {
  const userDefaultAccounts = useSelector(
    state => state.getStartedReducer.userDefaultAccounts,
  );
  const isAccountDetailFetched = useSelector(
    state => state.getStartedReducer.isAccountDetailFetched,
  );
  return (
    <>
      {props.message && <Wait text={props.message} />}
      <div className="d-flex flex-column2">
        <div className="ml-n1 mb-20">
          {isAccountDetailFetched && !userDefaultAccounts.length ? (
            <Link to="/settings" className="breadcrumb-link pointer">
              Settings
            </Link>
          ) : (
            <div className="d-flex align-items-baseline">
              <Link to="/settings" className="breadcrumb-link pointer">
                Settings
              </Link>{" "}
              <span className="breadcrumb-pike">/</span>
              <Link
                to="/settings/aws-settings"
                className="breadcrumb-link pointer"
              >
                AWS Target Accounts
              </Link>
            </div>
          )}
        </div>
        <div className="onboarding-wrap__flex get-started">
          <LeftPanel />
          <RightPanel />
        </div>
      </div>
    </>
  );
};

export default GetStarted;
