import React from "react";
import { DataTable } from "@components/ui";
import { Consumer } from "../runbook-details-lib/runbook-details.context";
import cloneDeep from "lodash/cloneDeep";

export default class RunbookDetailsDataTable extends React.Component {
  handleSort = (column, sortDirection) =>
    console.log(column.selector, sortDirection);

  render() {
    return (
      <Consumer>
        {({ runbook, isFetchingVersion }) => {
          let columns = [
            {
              name: "Name",
              selector: "Name",
              sortable: true,
            },
            {
              name: "Type",
              selector: "Type",
              sortable: true,
            },
            {
              name: "Description",
              selector: "Description",
              sortDirection: false,
            },
            {
              name: "Default Value",
              selector: "DefaultValue",
              sortable: false,
            },
          ];
          const data = runbook?.Parameters;
          // on run-runbook.js, we modify the param input Type
          // for autocomplete mapping (String|ec2|InstanceId),
          //  so disabling this here to NOT show it to users in "Type" field ->
          let copiedData = [];
          if (data) {
            /* The Filter logic removes the internal Parameters from Inputs */
            const filteredData = data.filter(
              p =>
                !(
                  p.Name === "AutomationAssumeRole" ||
                  p.Name === "WorkflowSession"
                ),
            );
            copiedData = cloneDeep(filteredData);
            copiedData.forEach(i => {
              i.Type = i.Type.split("|")?.[0];
            });
          }
          return (
            <DataTable
              columns={columns}
              data={copiedData}
              fixedHeader={true}
              fixedHeaderScrollHeight="192px"
              updating={isFetchingVersion}
            />
          );
        }}
      </Consumer>
    );
  }
}
