import React from "react";
import { connect } from "react-redux";

class Version extends React.PureComponent {
  render() {
    return (
      <div className="ml-15 fine-print mb-20">
        <span className="version-opacity">
          Version: {this.props.RunbookApiVersion}
        </span>
      </div>
    );
  }
}

const mapStateToProps = () => ({
  RunbookApiVersion: process.env.REACT_APP_TAG,
});

export default connect(mapStateToProps)(Version);
