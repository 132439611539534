import React from "react";
import getInstructions from "./utils/InstructionHelper";
import ConnectorForm from "./utils/ConnectorForm";
import GetStarted from "@components/shared/GetStarted/GetStartedForm";

const SplunkOnCallSetup = () => {
  const Instructions = getInstructions();
  return (
    <GetStarted
      instructionPanel={Instructions}
      connectorForm={<ConnectorForm />}
      showCustomForm
    />
  );
};

export default SplunkOnCallSetup;
