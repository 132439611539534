import React from "react";
import { Consumer } from "./drop-down.context";
import PropTypes from "prop-types";

export class DropDownOption extends React.Component {
  render() {
    return (
      <Consumer>
        {({ clickHandler }) => (
          <div
            className={`dropdown-item 
            ${
              this.props.text.includes("Delete") ? " dropdown-item-delete" : ""
            }`}
            onClick={() => {
              if (this.props.handleClick) {
                this.props.handleClick(this.props.value, this.props.version);
              }
              clickHandler(this.props.text, this.props.value);
            }}
          >
            <div className={this.props.className} id={this.props.text}>
              {this.props.text}
              {this.props.children}
            </div>
            <div className="dropdown-divider"></div>
          </div>
        )}
      </Consumer>
    );
  }
}

export const DropDownOptionMulti = props => (
  <Consumer>
    {({ clickHandler }) => (
      <div className="dropdown-multi-option">
        <input
          type="checkbox"
          className="styled-check-box"
          checked={props.checked}
          onChange={() => {
            clickHandler(props.text);
          }}
          id={`dropdown-option-checkbox-${props.id}`}
        />
        <label
          className="label-for-check-box"
          htmlFor={`dropdown-option-checkbox-${props.id}`}
        >
          {props.text}
        </label>
      </div>
    )}
  </Consumer>
);

DropDownOptionMulti.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
DropDownOptionMulti.defaultProps = {
  checked: false,
};
