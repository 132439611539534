export const recursiveDataParser = data => {
  let parsedData = {};
  for (const key in data) {
    let value = data[key];
    parsedData[key] = value;
    try {
      let parsedValue = JSON.parse(value);
      parsedData[key] = recursiveDataParser(parsedValue);
    } catch (err) {}
  }
  return parsedData;
};

export const limitExceeded = (usage, limit) => {
  return usage >= limit;
};
