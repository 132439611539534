import React from "react";

export default class IconLeft extends React.Component {
  render() {
    return (
      <React.Fragment>
        {this.props.iconLeft && (
          <div onClick={this.props.toggle}>
            <div ref={this.ref}>
              <img
                src={this.props.iconLeft}
                alt="icon"
                onClick={this.props.toggle}
                className="dropdown-ignore select-icon"
              />
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}
