import React from "react";
import PanelMetaInfo from "./panel-meta-info";
import PanelTags from "./panel-tags";
import PanelOverflowMenu from './panel-overflow-menu'
import { Link } from "react-router-dom";
import { RUNBOOK_SET_ACTIVE } from "@redux/types";
import { connect } from "react-redux";

const Panel = props => (
  <div className="runbook-item fade-in">
    <Link
      to={`/workflows/${props.runbook.Name}`}
      onMouseDown={() => {
        props.setActiveRunbook(props.runbook);
      }}
    >
    <div className="runbook-item__container">
      <PanelMetaInfo
        runbook={props.runbook}
      />
      <PanelTags tags={props.runbook.Tags} />
    </div>
    </Link>
    <PanelOverflowMenu 
      runbook={props.runbook}
    />
  </div>
);

const mapState = () => ({});

const mapDispatch = dispatch => ({
  setActiveRunbook: runbook =>
    dispatch({
      type: RUNBOOK_SET_ACTIVE,
      payload: runbook,
    }),
});

export default connect(mapState, mapDispatch)(Panel);
