import { DefaultNodeModel } from "storm-react-diagrams";
import { dropSnippet } from "./runbook-editor.helpers";
import { SnippetLibrary } from "./snippetlibrary";
import { RunbookReader } from "./runbook/runbookreader";
import { NeurOpsRunbook } from "./runbook/runbook";
import { stepFromControlNodeData } from "./neuropssteps/builder";
import Application from "./application";
import { StepTypeChecker } from "./neuropssteps/steptypechecker";

export function _onDrop(event, self) {
  dropSnippet(event);
  const { app, runbookObj } = self.state;

  // Check if event data is stringified. If not stringify it.
  const eventData =
    typeof event.dataTransfer.getData("storm-diagram-node") !== "string"
      ? JSON.stringify(event.dataTransfer.getData("storm-diagram-node"))
      : event.dataTransfer.getData("storm-diagram-node");
  const data = JSON.parse(eventData);
  const node = new DefaultNodeModel(data.name, "rgb(192,255,0)");

  //var points = app.getDiagramEngine().getRelativeMousePoint(event);
  const points = (app && app.getMousePosition(event)) || {};
  node.x = points.x;
  node.y = points.y;
  if (!node.hasOwnProperty("width")) {
    node.width = 64;
  }
  if (!node.hasOwnProperty("height")) {
    node.height = 64;
  }
  node.extras = {};
  // Set the runbookNode to be an Action object from our model.
  data.extras.editorNodeId = node.id;
  node.extras.runbookNode = stepFromControlNodeData(data, node.id);
  runbookObj && runbookObj.addStep(node.extras.runbookNode);
  addPorts(node);

  node.addListener({
    selectionChanged: self.selectNode.bind(self, node),
  });

  /*
    These setTimeouts feel clumsy, but we need to make sure the diagram has had a chance to build the nodes
  */
  setTimeout(() => {
    app.getDiagramEngine().getDiagramModel().addNode(node);
    self.props.updateRunbookObj(runbookObj);
    self.forceUpdate();
  }, 50);
  setTimeout(() => {
    app.addCssClass(node);
  }, 150);
}

/**
 * Add InPort and OutPort to runbookNode on the basis
 * of isStart and isEnd attributes from
 * the content information in snippetDef
 * @param {Object} node RunbookNode
 */
function addPorts(node) {
  let content = node?.extras?.runbookNode?.snippetDef?.content;
  if (content?.isStart === true) {
    node.addOutPort("Out");
  } else if (content?.isEnd === true) {
    node.addInPort("In");
  } else {
    node.addInPort("In");
    node.addOutPort("Out");
  }
}

export function _onDelete(selectedNode) {
  if (!selectedNode) {
    return;
  }
  const { runbookObj } = this.state;
  runbookObj.removeStep(selectedNode);
  this.props.updateRunbookObj(runbookObj);
  this.forceUpdate();
}

export function _resetDiagramAndRun(self) {
  if (!self.props.snippets || self.props.snippets.length === 0) {
    return;
  }
  const snippetLibrary = new SnippetLibrary(self.props.snippets);
  const app = Application;
  app.selectNode = self.selectNode.bind(self);
  self.setState({ app: app });
  let runbookObj;
  if (self.props.runbook) {
    const reader = new RunbookReader(snippetLibrary);
    runbookObj = reader.readAPIResponse(self.props.runbook);
    loadRunbookAwsData(self, runbookObj);
  } else {
    runbookObj = new NeurOpsRunbook("New Workflow");
  }

  self.setState({ runbookObj }, () => {
    self.runDiagram(runbookObj);
    self.props.updateRunbookObj(runbookObj);
  });
}

function loadRunbookAwsData(self, runbook) {
  let step;
  for (step of runbook.mainSteps) {
    loadAwsDataForSSMStep(self, step);
  }
}

function loadAwsDataForSSMStep(self, ssmStep) {
  if (
    StepTypeChecker.isActionNodeStep(ssmStep.ssm) ||
    StepTypeChecker.isLoopStep(ssmStep.ssm) ||
    StepTypeChecker.isWaitForResourceStep(ssmStep.ssm)
  ) {
    const { service, operation } = ssmStep;
    if (service) {
      self.props.fetchAwsOperations(service);
    }

    if (service && operation) {
      self.props.fetchAwsOperationDetails(service, operation);
    }
  }
}

export function _selectNode(node, self) {
  self.props.setActiveNode();
  const { app } = self.state;
  const nodesList = app.getNodesDetail();
  const runbookNode =
    node && node.extras.runbookNode ? node.extras.runbookNode : {};
  let nodeInfo = null;
  if (runbookNode && runbookNode.name && nodesList[runbookNode.name]) {
    nodeInfo = nodesList[runbookNode.name];
  }
  // add a new node to diagram then select it
  if (!nodeInfo && node) {
    app.setNodesDetail(node);
  }
  self.props.setActiveNode(node);
}

export function _deselectNode(self) {
  self.props.setActiveNode();
}

export function positionDiagram() {
  const leftPaddingWidth = 0;
  const diagramObject = document.querySelector(".srd-link-layer ");
  const diagramContainer = document.querySelector(".srd-diagram");
  if (diagramObject) {
    const rightPanelRect = document
      .querySelector(".editor-detail-wrap")
      .getBoundingClientRect();
    let height;
    // diagramContainer.style.marginRight =
    //   rightPanelRect.width + leftToolbarWidth + "px";
    height = window.innerHeight - 100;
    diagramContainer.style.height = height + "px";
    resizeDiagram(diagramContainer, rightPanelRect.width, leftPaddingWidth);
  }
}

export function resizeDiagram(diagramContainer, rightPanelWidth, leftStart) {
  const leftMenuElement = document.querySelector(".editor-menu-wrap");
  const leftPanelWidth = leftMenuElement.getBoundingClientRect().width;
  let totalWidthAvailable =
    document.body.clientWidth - rightPanelWidth - leftStart - leftPanelWidth;
  diagramContainer.style.width = totalWidthAvailable + "px";
}
