import React from "react";
import Select, { components } from "react-select";
import { getSelectStyle, formatOptionLabel, fuzzySearch } from "./helper";
import arrowIcon from "@assets/images/icons/select-carrot.svg";

type ReactSelectProps = {
  id: string; // ID for Select Component
  name?: string; // Name of Select component
  value: any; // Newly Selected Value
  defaultValue?: any; // Default Value
  handleChange: (d) => void; // Callback function called on Change of option
  selectOptions: Array<any>; // List of Options
  isDisabled?: boolean; // Flag to disable dropdown
  isMulti?: boolean; // Flag to enable multiple select
  isLoading?: boolean; // Flag to show Loading message while options are loading
  required?: boolean; // Flag to notify if Option Selection is mandatory
  className?: string; // Custom Class for whole React Select Container
  customStyle?: object; // Inline styles passed from prop
  customControlClass?: string; // Custom class Select Control Container
  customValueContainerClass?: string; //Custom class for Value container in dropdown header
  customSingleValueClass?: string; // Custom class for label in Value container
  customMenuClass?: string; // Custom class for Select options list container
  customMenuListClass?: string; // Custom class for Select options container
  customOptionClass?: string; // Custom class for select options
};

const ReactSelect: React.FC<ReactSelectProps> = ({
  id = "",
  name = "",
  value = undefined,
  handleChange,
  selectOptions,
  isDisabled = false,
  isMulti = false,
  className = "",
  isLoading = false,
  required = false,
  customStyle = {},
  customControlClass = "",
  customOptionClass = "",
  customValueContainerClass = "",
  customSingleValueClass = "",
  customMenuClass = "",
  customMenuListClass = "",
}) => {
  const selectStyles = getSelectStyle(customStyle);

  const CustomControl = ({ children, innerRef, innerProps }) => (
    <div
      ref={innerRef}
      {...innerProps}
      className={`rs-control ${customControlClass} ${
        required && !value?.value ? "required-unfulfilled" : ""
      }`}
    >
      {children}
    </div>
  );

  const CustomValueContainer = ({ children, innerRef, innerProps }) => (
    <div
      ref={innerRef}
      {...innerProps}
      className={`rs-value-container ${customValueContainerClass}`}
    >
      {children}
    </div>
  );

  const CustomSingleValue = ({ children, innerRef, innerProps }) => (
    <div
      ref={innerRef}
      {...innerProps}
      className={`rs-value-single ${customSingleValueClass}`}
    >
      {children}
    </div>
  );

  const DropdownIndicator = props => (
    <components.DropdownIndicator {...props}>
      <img
        src={arrowIcon}
        alt="arrowIcon"
        className={`pointer rs-dropdown-icon ml-5 ${
          props.selectProps.menuIsOpen
            ? "rs-dropdown-icon-expanded"
            : "rs-dropdown-icon-collapsed"
        }`}
        width="12"
        height="12"
      />
    </components.DropdownIndicator>
  );

  const CustomMenu = ({ children, innerProps, innerRef }) => (
    <div
      ref={innerRef}
      {...innerProps}
      className={`rs-menu ${customMenuClass}`}
    >
      {children}
    </div>
  );

  const CustomMenuList = ({ children, innerProps, innerRef }) => (
    <div
      ref={innerRef}
      {...innerProps}
      className={`rs-menu-list ${customMenuListClass}`}
    >
      {children}
    </div>
  );

  const CustomOption = ({ innerRef, innerProps, isSelected, children }) => (
    <div
      ref={innerRef}
      {...innerProps}
      className={`rs-option ${
        isSelected ? "rs-option-selected" : ""
      } ${customOptionClass}`}
    >
      {children}
    </div>
  );

  return (
    <Select
      id={id || ""}
      components={{
        ValueContainer: CustomValueContainer,
        Control: CustomControl,
        DropdownIndicator,
        Option: CustomOption,
        SingleValue: CustomSingleValue,
        Menu: CustomMenu,
        MenuList: CustomMenuList,
      }}
      styles={selectStyles}
      className={`rs ${className}`}
      name={name}
      value={value?.value ? value : null}
      onChange={handleChange}
      options={selectOptions}
      isDisabled={isDisabled}
      isMulti={isMulti}
      isLoading={isLoading}
      formatOptionLabel={formatOptionLabel}
      filterOption={fuzzySearch}
      classNamePrefix="rs"
      placeholder="Select from below"
    />
  );
};
export default ReactSelect;
