import React from "react";
import {
  Logo,
  Add,
  Versions,
  Settings,
  Bottom,
  Search,
  Spacer,
  Help,
} from "./menu-items";

export default class EditorMenu extends React.Component {
  render() {
    return (
      <div className="editor-menu-wrap">
        <Logo />
        <Add />
        <Versions />
        <Settings />
        <Spacer />
        <Bottom>
          <Search />
          <Help />
        </Bottom>
      </div>
    );
  }
}
