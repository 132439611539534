import React from "react";
import { Accordion, AccordionLabel, AccordionSection } from "@components/ui";
import { getSelectedOption } from "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.helpers";
import { InputSelector } from "@containers/RunbookEditor/runbook-editor-components/editor-right-panel/input-selector";

export default class InputsRequired extends React.Component {
  state = {
    activeNode: this.props.activeNode,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.activeNode !== this.props.activeNode) {
      this.setState({
        activeNode: this.props.activeNode,
      });
    }
  }

  render() {
    const { requiredInputs } = this.props;
    let options = requiredInputs;
    return (
      <React.Fragment>
        {options && options.length > 0 && (
          <Accordion isExpanded={true} useArrow={true}>
            <AccordionLabel className="editor-accordion-label mt-10">
              Required Inputs
            </AccordionLabel>
            <AccordionSection>
              {options.map((input, i) => {
                return (
                  <InputSelector
                    optionFilterId={this.props.optionFilterId}
                    toggleInputOption={this.props.toggleInputOption}
                    input={input}
                    selectedOption={getSelectedOption(input)}
                    key={`${input.name}-${i}`}
                  />
                );
              })}
            </AccordionSection>
          </Accordion>
        )}
      </React.Fragment>
    );
  }
}
