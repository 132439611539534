import React from "react";

const TerraformSettingsTokens = require("@assets/images/screenshots/settings-terraform/Terraform-Settings-tokens.jpg");
export default () => (
  <>
    <div className="list-row">
      <div className="step-number">
        <div>1</div>
      </div>
      <div>Sign In to terraform Cloud</div>
    </div>
    <div className="list-row">
      <div className="step-number">
        <div>2</div>
      </div>
      <div>
        Copy the organization name and paste it in the <b>organization</b> text
        field.
      </div>
    </div>
    <div className="list-row">
      <div className="step-number">
        <div>3</div>
      </div>
      <div>
        User should go to Profile and select User Settings in Tokens Or click
        this link: <br />
        https://app.terraform.io/app/settings/tokens
      </div>
    </div>
    <div className="list-image">
      <img
        src={TerraformSettingsTokens}
        alt="TerraformSettingsTokens"
        width="90%"
        height="350"
      />
    </div>
    <div className="list-row">
      <div className="step-number">
        <div>4</div>
      </div>
      <div>
        Click <b>Create API token</b> button
      </div>
    </div>
    <div className="list-row">
      <div className="step-number">
        <div>5</div>
      </div>
      <div>Add description and create new token</div>
    </div>
    <div className="list-row">
      <div className="step-number">
        <div>6</div>
      </div>
      <div>
        Copy the generated token and paste it in the<b> Token </b>text field.
      </div>
    </div>
    <b>Note:</b> Generated Token can be either User or Team token.
  </>
);
