import React from "react";
import { Modal, Button } from "@components/ui";
import { SettingsPanelHeader } from "../settings-panel-components";
import APIKeysList from "./APIKeysList.tsx";
import FormInput from "../settings-panel-components/SettingsRightConfigPanel/FormInput";
import * as Yup from "yup";
import { Formik } from "formik";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { createAPIKey, fetchAPIKeys } from "@redux/actions/apikeys.actions";
import { Wait } from "@components/ui";

class APIKeys extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCreateAPIKeyPopup: false,
      validationSchema: null,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.apiKeyList !== this.props.apiKeyList) {
      this.setState({
        validationSchema: Yup.object({
          name: Yup.string()
            .required("API Key Name is required")
            .default("")
            .notOneOf(
              this.props.apiKeyList
                ? this.props.apiKeyList.map(e => e.ApikeyName)
                : [],
              "API Key already exists",
            )
            .trim("API Key Name is required"),
        }),
      });
    }
  }

  toggleAPIKeysModal = () => {
    this.setState({
      showCreateAPIKeyPopup: !this.state.showCreateAPIKeyPopup,
    });
  };

  getFooter = (handleSubmit, isSubmitting) => {
    return (
      <div className="modal-buttons-footer">
        <Button
          text="Cancel"
          buttonStyle="secondary"
          style={{ width: "50%" }}
          onClick={this.toggleAPIKeysModal}
          className={isSubmitting ? "disabled" : ""}
          type="button"
          isLoading={isSubmitting}
        />
        <Button
          text="Save"
          buttonStyle="primary"
          style={{ width: "50%" }}
          className={isSubmitting ? "disabled" : ""}
          size="large"
          isLoading={isSubmitting}
          onClick={handleSubmit}
        />
      </div>
    );
  };

  onSubmitHandler = async (values, actions) => {
    actions.setSubmitting(true);
    let action = await this.props.createAPIKey(values["name"]);

    if (action.type === "API_KEYS_CREATE_SUCCESS") {
      actions.setStatus({
        formSubmitMessage: this.props.createAPIKeySuccess?.status,
      });
      setTimeout(() => {
        this.toggleAPIKeysModal();
        this.props.fetchAPIKeys();
      }, 1500);
    } else {
      actions.setStatus({
        formSubmitMessage: this.props.createAPIKeyError,
      });
      setTimeout(() => {
        this.toggleAPIKeysModal();
        this.props.fetchAPIKeys();
      }, 1500);
    }
  };

  render() {
    return (
      <>
        {this.props.message && <Wait text={this.props.message} />}
        <div className="d-flex flex-column2">
          {/* <Breadcrumbs text={"Settings"} navigateTo="settings" /> */}
          <SettingsPanelHeader
            isBreadCrumb={true}
            page="api-keys"
            toggleAPIKeysModal={this.toggleAPIKeysModal}
            heading="API Keys"
            breadCrumbText="Settings"
          />
          <>
            {this.state.showCreateAPIKeyPopup && (
              <Formik
                initialValues={{
                  name: "",
                }}
                onSubmit={this.onSubmitHandler}
                validationSchema={this.state.validationSchema}
              >
                {props => {
                  const {
                    touched,
                    errors,
                    handleChange,
                    handleSubmit,
                    status,
                    isSubmitting,
                  } = props;
                  return (
                    <Modal
                      onClose={this.toggleAPIKeysModal}
                      title="Create API Key"
                      showClose={true}
                      footer={this.getFooter(handleSubmit, isSubmitting)}
                    >
                      <FormInput
                        fieldName="API Key Name"
                        name="name"
                        id="name"
                        handleChange={handleChange}
                        touched={touched}
                        errors={errors}
                        autoComplete="off"
                      />
                      <div style={{ marginTop: "1rem" }}>
                        {status && status.formSubmitMessage}
                      </div>
                    </Modal>
                  );
                }}
              </Formik>
            )}
          </>
          <div className="mt-40 ml-15">
            <APIKeysList enableEditAccount={this.enableEditAccount} />
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = state => ({
  createAPIKeySuccess: state.APIKeysReducer.createAPIKeySuccess,
  createAPIKeyError: state.APIKeysReducer.createAPIKeyError,
  isCreating: state.APIKeysReducer.isCreatingAPIKey,
  apiKeyList: state.APIKeysReducer.apiKeyList,
});
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      createAPIKey,
      fetchAPIKeys,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(APIKeys);
