import { SnippetStep } from "./snippetstep";
import { StepTypes } from "./strings";
import { SSMActionNames, ParameterType } from "../ssm/strings";
import { Parameter } from "../ssm/parameters";
import {
  RunbookStepInput,
  RunbookStepInputSource,
} from "../ssm/nodeinputoutput";
import { isEmpty } from "@lib/utils";

export default class SlackConnectorStep extends SnippetStep {
  static fromControlNodeDefinition(controlDef) {
    const stepJSON = {
      ...controlDef.content,
      name: `${controlDef.name}_${Math.floor(1000 * Math.random())}`,
      action: SSMActionNames.INVOKE_LAMBDA,
      maxAttempts: 1,
      inputs: {
        ClientContext: super._writeSnippetDetailsForContext(controlDef),
        FunctionName: controlDef.content.lambda_arn,
        Payload: "",
      },
      editorNodeId: controlDef.editorNodeId,
    };

    return new SlackConnectorStep(stepJSON, controlDef);
  }
  stepType;

  constructor(stepJSON, controlDef) {
    super(stepJSON, controlDef);
    this.stepType = StepTypes.SlackConnectorStep;
    window.setTimeout(e => this.showHideWarning(!this.isHealthyStep()), 200);
  }

  readInputSources(runbook) {
    let lambdaInput = this.ssm.inputs.Payload;
    // We can just parse this without doing any manipulation because we know that the input for
    // a SlackConnectorStep will always be valid JSON.
    const parsedInput = lambdaInput && JSON.parse(lambdaInput);
    this.parameterInputs = [];

    // create the input for the channel
    const channelSource = new RunbookStepInputSource(
      "constant",
      parsedInput?.channel_name,
    );
    this.parameterInputs.push(
      new RunbookStepInput(
        this,
        "channel_name",
        ParameterType.String,
        true,
        channelSource,
      ),
    );

    // create the input for the message
    const messageSource = new RunbookStepInputSource(
      "constant",
      parsedInput?.message,
    );
    this.parameterInputs.push(
      new RunbookStepInput(
        this,
        "message",
        ParameterType.String,
        true,
        messageSource,
      ),
    );

    // create the input for the message
    const dynamicVarsSource = new RunbookStepInputSource(
      "constant",
      parsedInput?.dynamic_variables || {},
    );
    this.parameterInputs.push(
      new RunbookStepInput(
        this,
        "dynamic_variables",
        ParameterType.StringMap,
        false,
        dynamicVarsSource,
      ),
    );
  }

  isHealthyStep() {
    let boolList = [];
    this.parameterInputs.forEach(param => {
      const sourceValue = param.source.sourceValue;
      if (
        param.required &&
        (sourceValue === null ||
          sourceValue instanceof Parameter ||
          !sourceValue)
      ) {
        boolList.push(false);
      }
    });
    return boolList.every(Boolean);
  }

  get message(): string {
    const msgInput = this.parameterInputs.find(inp => inp.name === "message");
    return msgInput.source.sourceValue;
  }

  set message(m: string) {
    const msgInput = this.parameterInputs.find(inp => inp.name === "message");
    msgInput.source.sourceValue = m;
  }

  get dynamicVariables(): any {
    const dynamicVarsInput = this.parameterInputs.find(
      inp => inp.name === "dynamic_variables",
    );
    return typeof dynamicVarsInput.source.sourceValue === "string"
      ? JSON.parse(dynamicVarsInput.source.sourceValue)
      : dynamicVarsInput.source.sourceValue;
  }

  get displayMessage(): string {
    const dvs = this.dynamicVariables;
    let msg = this.message;
    for (const k of Object.keys(dvs)) {
      msg = msg.replace(`{{${dvs[k]}}}`, `{{${k}}}`);
    }
    return msg;
  }

  set displayMessage(dm: string) {
    const dvs = this.dynamicVariables;
    let msg = dm;
    for (const k of Object.keys(dvs)) {
      msg = msg.replace(`{{${k}}}`, `{{${dvs[k]}}}`);
    }
    this.message = msg;
  }

  dynamicInputs = () => {
    const dynamicVars = this.dynamicVariables;
    const result: RunbookStepInput[] = [];
    for (const dv of Object.keys(dynamicVars)) {
      const val = dynamicVars[dv] as string;
      const [stepName, outputName] = val.split(".");
      const mainStepObj = (this as any).runbook?.mainStepIndex[stepName];
      const stepOutput =
        !isEmpty(mainStepObj) &&
        mainStepObj?.outputs.find(output => output.name === outputName);
      const src = new RunbookStepInputSource("snippetOutput", stepOutput);
      result.push(
        new RunbookStepInput(this, dv, ParameterType.String, false, src),
      );
    }
    return result;
  };
}
