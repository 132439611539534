import { sortObject } from "@lib/utils";

export const resortRunbooksList = (sortBy, propsRunbookList) => {
  let __sortBy, __direction;
  switch (sortBy) {
    case "Name (A-Z)":
      __sortBy = "Name";
      __direction = "asc";
      break;

    case "Name (Z-A)":
      __sortBy = "Name";
      __direction = "desc";
      break;

    default:
      break;
  }
  if (__sortBy) {
    let sorted = sortObject(propsRunbookList, __sortBy, __direction);
    return sorted;
  }
};
