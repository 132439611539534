import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DataTable } from "@components/ui/data-table";
import tableColumns from "./tableColumns";
import { SettingsPanelHeader } from "../../settings-panel-components";
import { Wait, Confirm, FTNotification } from "@components/ui";
import CloudFormationModal from "./CloudFormationModal";
import { toggleDeleteConfirmBox } from "@redux/actions/AWSSettings.actions";
import {
  fetchCloudFormationTemplates,
  deleteCloudFormationTemplate,
} from "@redux/actions/settingsPanel.action";
import { fetchGitRepos } from "@redux/actions/gitRepo.actions";

type CloudFormationProps = {};

const CloudFormation: React.FC<CloudFormationProps> = props => {
  const newCloudFormationValues = {
    templateId: "",
    repoId: "",
    filePath: "",
    ref: "",
  };
  const dispatch = useDispatch();
  const showConfirm = useSelector(state => state.uiReducer.showConfirm);
  const loadingMessage = useSelector(state => state.runbooksReducer.message);
  const cloudFormationTemplateList = useSelector(
    state => state.settingsPanelReducer.cloudFormationTemplateList,
  );

  const [selectedTemplate, setSelectedTemplate] = useState(
    newCloudFormationValues,
  );

  const [showCloudFormationModal, setShowCloudFormationModal] = useState(false);
  const [cloudFormationMode, setCloudFormationMode] = useState("create");
  const [isDeleting, setIsDeleting] = useState(false);

  const toggleCloudFormationFormVisibility = mode => {
    setShowCloudFormationModal(true);
    if (mode === "create") {
      setSelectedTemplate(newCloudFormationValues);
      setCloudFormationMode(mode);
    }
  };

  const onEditHandler = row => {
    setSelectedTemplate(row);
    setCloudFormationMode("edit");
    toggleCloudFormationFormVisibility("edit");
  };

  const onRemoveHandler = row => {
    setSelectedTemplate(row);
    dispatch(toggleDeleteConfirmBox(true));
  };

  const cloudFormationColumns = tableColumns(onRemoveHandler, onEditHandler);

  useEffect(() => {
    dispatch(fetchGitRepos());
    dispatch(fetchCloudFormationTemplates());
  }, [dispatch]);

  return (
    <>
      {!!loadingMessage && <Wait text={loadingMessage} />}
      {showConfirm && (
        <Confirm
          heading="Delete Confirmation"
          cancelText="Cancel"
          confirmText="Delete"
          message="This CloudFormation Template will be deleted permanently."
          confirmValue={selectedTemplate.templateId}
          confirmRequiredText={`Confirm by typing '${selectedTemplate.templateId}' below`}
          isLoading={isDeleting}
          verifiedCallback={async () => {
            setIsDeleting(true);
            const action = await dispatch(
              deleteCloudFormationTemplate(selectedTemplate.templateId),
            );
            dispatch(toggleDeleteConfirmBox(false));
            setIsDeleting(false);
            if (action.type === "DELETE_CLOUDFORMATION_TEMPLATE_SUCCESS") {
              FTNotification.success({
                title: "CloudFormation Template Deleted Successfully!",
              });
            } else {
              FTNotification.error({
                title: "Could not delete CloudFormation Template",
                message: "",
              });
            }
          }}
        />
      )}
      {showCloudFormationModal && (
        <CloudFormationModal
          selectedTemplate={selectedTemplate}
          closeForm={setShowCloudFormationModal}
          mode={cloudFormationMode}
          templateList={cloudFormationTemplateList}
        />
      )}

      <div className="d-flex flex-column2">
        <SettingsPanelHeader
          isBreadCrumb={true}
          page="cloudformation-templates"
          heading="CloudFormation Templates"
          breadCrumbText="Settings"
          toggleCloudFormationFormVisibility={
            toggleCloudFormationFormVisibility
          }
        />
        <div className="mt-40 ml-15 gr-list-container aws-settings-list">
          <DataTable
            className="snippet-list-table"
            columns={cloudFormationColumns}
            data={cloudFormationTemplateList}
            responsive={true}
            fixedHeader={true}
          />
        </div>
      </div>
    </>
  );
};

export default CloudFormation;
