import { connect } from "react-redux";
import ExecutionDetails from "../ExecutionDetails";
import {
  fetchExecutionDetails,
  resumeExecution,
} from "@redux/actions/execution.action";

const mapState = ({ executionsReducer, websocketReducer }) => {
  return {
    isFetchingDetails: executionsReducer.isFetchingDetails,
    isFetchingDetailsSuccess: executionsReducer.isFetchingDetailsSuccess,
    executionDetails:
      executionsReducer.executionDetails[executionsReducer.executionId],
    executionIsRunning: executionsReducer.executionIsRunning,
    executionMessage: executionsReducer.message,
    executionId: executionsReducer.executionId,
    websocketExecutionSteps: websocketReducer.executionSteps,
    websocketExecutionFinalStatus: websocketReducer.executionFinalStatus,
    connectionId: websocketReducer.connectionId,
  };
};

const mapDispatch = dispatch => ({
  fetchExecutionDetails: exeuctionId =>
    dispatch(fetchExecutionDetails(exeuctionId)),
  resumeExecution: (executionId, stepName, action, connectionId) =>
    dispatch(resumeExecution(executionId, stepName, action, connectionId)),
});

export default connect(mapState, mapDispatch)(ExecutionDetails);
