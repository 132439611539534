import React from "react";
import {
  SettingsPanelContent,
  SettingsPanelConfigPane,
} from "./settings-panel-components";
import "./settings-panel-components/SettingsRightConfigPanel/settings-panel-config-pane.scss";
import { connect } from "react-redux";
import { setTitle } from "@lib/utils";

class SettingsPanel extends React.Component {
  constructor(props) {
    super(props);
    setTitle("Fylamynt | Settings");
  }

  render() {
    return (
      <div className="d-flex flex-column2">
        <SettingsPanelContent />
        {this.props.showConfigPanel && (
          <div className="slide-panel slide-out-from-right side-panel__user-mgmt">
            <SettingsPanelConfigPane />
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = state => ({
  showConfigPanel: state.settingsPanelReducer.showConfigPanel,
});

export default connect(mapStateToProps, null)(SettingsPanel);
