import React, { useState } from "react";
import "./NoAccountWarning.scss";
import warningSign from "@assets/images/icons/warning-triangle.svg";
import { Link } from "react-router-dom";
import { RouteConstants } from "../../../routes/Constants";
import { Consumer } from "../../../containers/RunbookDetails/runbook-details-lib/runbook-details.context";
import { getUnconfiguredNodeList } from "@lib/utils";

type NoAccountWarningProps = {
  isAWSAccountConfigured?: boolean;
  fromComponent: string;
  unconfiguredNodes?: Array<object>;
};

const NoAccountWarning: React.FC<NoAccountWarningProps> = ({
  isAWSAccountConfigured,
  fromComponent,
  unconfiguredNodes,
}) => {
  const [isWarningVisible, setIsWarningVisible] = useState(true);
  const handleCrossButtonClick = () => {
    setIsWarningVisible(!isWarningVisible);
  };

  const getWarningText = unconfiguredNodeList => {
    if (!unconfiguredNodeList) unconfiguredNodeList = [];
    let unconfiguredNodes = getUnconfiguredNodeList(unconfiguredNodeList);
    return (
      <>
        <span className="warning-text">
          {fromComponent === "Runbooks"
            ? `Connect your AWS account to start using workflows.`
            : `Running this workflow is disabled. Please complete the setup for
    `}
        </span>
        {fromComponent === "Runbooks" && !isAWSAccountConfigured && (
          <Link to={RouteConstants["aws"].url} className="warning-link">
            &nbsp;Complete Setup
          </Link>
        )}
        {fromComponent === "RunbookDetails" && !isAWSAccountConfigured && (
          <>
            <Link to={RouteConstants["aws"].url} className="warning-link">
              {RouteConstants["aws"].displayName}
            </Link>
            {unconfiguredNodes && unconfiguredNodes.length > 1 ? (
              <span>,&nbsp;</span>
            ) : unconfiguredNodes && unconfiguredNodes.length === 1 ? (
              <span>&nbsp;and&nbsp;</span>
            ) : (
              <span>.</span>
            )}
          </>
        )}
        {fromComponent === "RunbookDetails" &&
          unconfiguredNodes &&
          unconfiguredNodes.map((nodeName, index) =>
            RouteConstants.hasOwnProperty(nodeName) ? (
              <React.Fragment key={nodeName}>
                <Link
                  className="warning-link"
                  to={RouteConstants[nodeName].url}
                >
                  {RouteConstants[nodeName].displayName}
                </Link>
                {index === unconfiguredNodes.length - 2 ? (
                  <span>&nbsp;and&nbsp;</span>
                ) : index === unconfiguredNodes.length - 1 ? (
                  <span>.</span>
                ) : (
                  <span>,&nbsp;</span>
                )}
              </React.Fragment>
            ) : (
              ""
            ),
          )}
      </>
    );
  };
  return (
    isWarningVisible && (
      <Consumer>
        {props => (
          <div className="warning-container">
            <div>
              <img
                src={warningSign}
                alt="warningSign"
                width="20"
                height="20"
                className="mr-15"
              />
              {getWarningText(unconfiguredNodes)}
            </div>
            <div className="warning-container-cross">
              <span onClick={handleCrossButtonClick} className="cross-icon">
                &nbsp;&nbsp;✕&nbsp;&nbsp;
              </span>
            </div>
          </div>
        )}
      </Consumer>
    )
  );
};

export default NoAccountWarning;
