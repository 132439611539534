import React from "react";
import "../settings-panel.scss";
import { OverflowMenu } from "@components/ui";
import { useHistory } from "react-router-dom";

const getMenuOptions = (props, history) => {
  switch (props.status) {
    case "Connected":
      return (
        <>
          <div
            className="button-grey"
            title={props.name}
            id={`node_${props.name?.toLowerCase()}`}
            onMouseDown={e => {
              let eventObj = e;
              props.handleClick(eventObj);
            }}
          >
            Edit Settings
          </div>
          {props.name?.toLowerCase() !== "aws" && (
            <div
              onMouseDown={e => {
                let eventObj = e;
                props.handleRemove(eventObj, props.name);
              }}
              className="button-delete"
            >
              Remove Integration
            </div>
          )}
        </>
      );

    case "api-key":
      return (
        <div
          onMouseDown={e => {
            props.handleClick(e);
          }}
          className="button-grey"
          title={props.name}
        >
          Manage Keys
        </div>
      );

    case "git":
      return (
        <div
          onMouseDown={e => {
            props.handleClick(e);
          }}
          className="button-grey"
          title={props.name}
        >
          Manage Repos
        </div>
      );
    case "ssh":
      return (
        <div
          onMouseDown={e => {
            props.handleClick(e);
          }}
          className="button-grey"
          title={props.name}
        >
          Manage Targets
        </div>
      );
    case "s3":
      return (
        <div
          onMouseDown={e => {
            props.handleClick(e);
          }}
          className="button-grey"
          title={props.name}
        >
          Manage Buckets
        </div>
      );
    case "cloudformation":
      return (
        <div
          onMouseDown={e => {
            props.handleClick(e);
          }}
          className="button-grey"
          title={props.name}
        >
          Manage Templates
        </div>
      );

    case "cloudwatch":
      return (
        <div
          onMouseDown={props.handleClick}
          className="button-grey"
          title={props.name}
        >
          Manage Targets
        </div>
      );

    default:
      return (
        <div
          onMouseDown={e => {
            if (props.addIntegrationUrl) {
              history.push(props.addIntegrationUrl);
            } else if (props.name?.toLowerCase() === "datadog") {
              props.handleClick(e);
            }
          }}
          className="button-grey"
          title={props.name}
        >
          Add Integration
        </div>
      );
  }
};

const handleTileClick = (e, props, history) => {
  if (props.status !== "Connected") {
    if (props.addIntegrationUrl) history.push(props.addIntegrationUrl);
  } else {
    props.handleClick(e);
  }
};

export default props => {
  let history = useHistory();
  return (
    <div
      className="integration-tile w-inline-block fade-in"
      title={props.name}
      onClick={e => handleTileClick(e, props, history)}
    >
      <OverflowMenu
        menuIconStyle={{ zIndex: 1, top: "18px" }}
        menuStyle={{ top: "45px", zIndex: 1 }}
      >
        {getMenuOptions(props, history)}
      </OverflowMenu>
      <div className="settings-icon radio__xl">
        <h4 className="integration-title">{props.displayName}</h4>
        <div
          className={`bg-${props.name?.replace(/\s/g, "")?.toLowerCase()}`}
        ></div>
      </div>
      {![
        "API-Keys",
        "Git",
        "Ssh",
        "S3",
        "Cloudformation",
        "Cloudwatch",
      ].includes(props.name) && (
        <div className="settings-name radio__xl">
          <div
            className={
              props.status?.toLowerCase() === "connected"
                ? "status-icon"
                : "status-icon status-icon__inactive"
            }
          ></div>
          <div className="status">{props.status}</div>
        </div>
      )}
    </div>
  );
};
