import React from "react";
import {
  Accordion,
  AccordionLabel,
  AccordionSection,
  TextInput,
} from "@components/ui";
import { Consumer } from "../../../../runbook-editor-lib/runbook-editor.context";
import Tag from "./tag";

export default class SettingsTags extends React.PureComponent {
  checkForEnterKey = (e, callBack) => {
    if (e.key === "Enter") {
      callBack(e.target.value);
      setTimeout(() => {
        const element = document.querySelector("#new-tag");
        element.value = "";
        element.focus();
      }, 10);
    }
  };
  render() {
    return (
      <Consumer>
        {({ runbook, addTagToRunbook }) => (
          <Accordion isExpanded={true} useArrow={true}>
            <AccordionLabel className="editor-accordion-label">
              Tags
            </AccordionLabel>
            <AccordionSection>
              <div className="editor-detail-panel" style={{marginBottom: "0px"}}>
              <TextInput
                placeholder="Press Enter to add tag"
                name="new-tag"
                className="rule-input"
                onKeyPress={e => this.checkForEnterKey(e, addTagToRunbook)}
                id="new-tag"
              />
              </div>
              <div
                className="paragraph oval-wrapper"
                style={{ maxWidth: "250px" }}
              >
                {runbook &&
                  runbook.hasOwnProperty("Tags") &&
                  runbook.Tags.map(tag => <Tag text={tag} key={tag} />)}
              </div>
            </AccordionSection>
          </Accordion>
        )}
      </Consumer>
    );
  }
}
