import "./runbook-editor.scss";
import "./snippets.scss";

import {
  EditorDesignArea,
  EditorMenu,
  EditorRightPanel,
  EditorSlideouts,
  EditorToolbar,
} from "./runbook-editor-components";
import {
  SaveWarning,
  UnsavedWarning,
} from "./runbook-editor-components/editor-dialogs";
import {
  isEmpty,
  findUnconfiguredNodes,
  sortObject,
  urlHasChanged,
} from "@lib/utils";

import EditorSaveNewVersionModal from "./runbook-editor-components/editor-menu-left/menu-slideouts/versions/EditorSaveNewVersionModal";
import LimitDialog from "@containers/WarningDialogs/LimitDialog";
import { Prompt } from "react-router";
import { Provider } from "./runbook-editor-lib/runbook-editor.context";
import React from "react";
import { Redirect } from "react-router-dom";
import { RunbookRun } from "@components/modules";
import { MetaTag, Modal } from "@components/ui";
import { RunbookScheduleModal } from "@components/modules";
import iconPlus from "@assets/images/icons/icon-plus.svg";
import { shouldForceDefaultValues } from "./runbook-editor-lib/runbook-editor.helpers";
import Tour from "reactour";
import { getTourSteps } from "./Tour/helpers/TourSteps";
import WelcomeDialog from "@assets/images/screenshots/welcome-dialog/WelcomeDialog.jpg";
import { RouteConstants } from "../../routes/Constants";
import { cleanRunbooks } from "@lib/utils";
import Api from "@lib/api/api";

export default class RunbookEditor extends React.Component {
  state = {
    activePanel: null,
    activePanelTitle: null,
    runbookId: this.props.match.params.runbookId,
    preparingEditor: true,
    showRunModal: false,
    showSaveWarning: false,
    showUnsavedWarning: false,
    ignoreUnsavedWarning: false,
    hasUpdatedSinceSave: true,
    runbookObj: null,
    savingRunbook: false,
    redirect: false,
    redirectTo: null,
    prepareMessage: null,
    showSaveNewVersionModal: false,
    runModalAsSave: false,
    nextVersion: "",
    reloadEditor: false,
    toggleScheduleModal: false,
    tryingToSchedule: false,
    isWorkflowLimitDialogVisible: false,
    isRunsLimitDialogVisible: false,
    unconfiguredNodes: [],
    isConfigured: true,
    isTourOpen: false,
    isWelcomeDialogVisible: false,
  };

  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.editorDesignReference = React.createRef();
    this.timer = null;
    this.location = window.location.href;
    window.addEventListener("beforeunload", this.beforeunload);
  }

  beforeunload = e => {
    if (this.props.runbookDidUpdate) {
      e.preventDefault();
      e.returnValue =
        "You have unsaved changes, are you sure you want to leave?";
    }
  };

  componentDidMount() {
    if (this.checkIfRunbookExists()) {
      this.setState(
        {
          isExistingRunbook: true,
        },
        () => {
          this.fetchRunbookDetails();
        },
      );
    } else {
      this.fetchRunbookDetails();
    }
    this.props.getResourcesList();
    this.gotoExecutionDetails = false;
    this.props.clearNewRunbook(false);
    this.props.fetchSettingsConnectors();
  }

  componentWillUnmount() {
    this.props.unsetNode();
    this.props.notifyRunbookUpdate(false);
    this.props.unsetActiveRunbook();
    window.removeEventListener("beforeunload", this.beforeunload);
    window.onbeforeunload = null;
  }

  componentDidUpdate(prevProps, prevState) {
    this.isPreparing(prevProps);
    this.isSaving(prevProps);
    this.saveError(prevProps);
    this.loadedDifferentVersion(prevProps);
    this.reloaded(prevState);
    this.saveError(prevProps);
    this.checkIfNeedToRedirect();
    if (prevState.runbookObj !== this.state.runbookObj) {
      this.setState({
        unconfiguredNodes: findUnconfiguredNodes(
          this.props.connectors,
          this.state.runbookObj,
        ),
      });
    }
  }

  checkIfRunbookExists = () => {
    //TODO : this function will be used to identify if a runbook exists in the list or not via API or persist-store
    return false;
  };

  checkIfNeedToRedirect = () => {
    if (this.props.executionId.length && this.gotoExecutionDetails) {
      this.setState({
        redirect: true,
        redirectTo: `${RouteConstants.executionDetails.url}/${this.props.executionId}`,
      });
    }
  };

  saveError(prevProps) {
    if (
      this.props.runbookFetchError &&
      this.props.runbookFetchError !== prevProps.runbookFetchError
    ) {
      this.setState(
        {
          isSaving: false,
          savingRunbook: false,
        },
        () => {
          setTimeout(() => {
            this.loadVersion(this.props.activeRunbookVersion);
          }, 2000);
        },
      );
    }
  }

  fetchRunbookDetails = () => {
    if (this.props.runbookNew && isEmpty(this.props.activeRunbook)) {
      this.props.fetchActiveRunbook(this.state.runbookId).then(() => {
        this.setState({ reloadEditor: true });
        this.getSnippets();
      });
    } else if (this.props.runbookNew) {
      this.getSnippets();
    } else {
      /* if we already have an activerunbook, then all we need to do is determine if we need snippets */
      if (this.props.activeRunbook && !isEmpty(this.props.activeRunbook)) {
        this.setState({ reloadEditor: true });
        this.getSnippets();
      } else {
        this.updateMessage("Getting workflow details, please wait");
        this.props.fetchActiveRunbook(this.state.runbookId).then(message => {
          this.setState({ reloadEditor: true });
          this.getSnippets();
        });
      }
    }
  };

  updateRunbook = (key, value) => {
    this.props.updateRunbookData(key, value);
  };

  getSnippets = () => {
    if (!this.props.snippetsByCategory) {
      this.updateMessage("Getting actions, please wait...");
      this.props.fetchSnippets();
    }
  };

  updateMessage = message => {
    const show = message ? true : false;
    this.props.showWaitMessage(show);
    this.props.setWaitMessage(message);
  };

  runExecution = (runbookName, parameters) => {
    this.props.doExecution(
      runbookName,
      parameters,
      this.props.activeRunbookVersion,
    );
    this.gotoExecutionDetails = true;
  };

  goBack = url => {
    if (url) {
      this.url = url;
    }
    if (!this.state.ignoreUnsavedWarning) {
      if (this.runbookHasChanged()) {
        this.setState({
          showUnsavedWarning: true,
        });
        return;
      }
    }
    this.props.setActiveRunbook(this.state.runbookObj);

    if (this.props.runbookNew) {
      this.props.setRunbookIsNew(false);
    }
    if (!this.url) {
      //this.props.history.push("/workflows");
      /*
       used redirect instead of props.history.push
       as it maintains the browser history stack
       even if we choose to handle context differently
      */
      this.setState({
        redirect: true,
        redirectTo: RouteConstants.runbooks.url,
      });
    } else {
      //this.props.history.push(this.url);
      this.setState({
        redirect: true,
        redirectTo: this.url,
      });
    }
  };

  runbookHasChanged = () => {
    if (this.props.runbookDidUpdate) {
      return true;
    }
    if (!this.props.runbookNew) {
      return false;
    } else if (this.state.ignoreUnsavedWarning) {
      return false;
    } else {
      return true;
    }
  };

  toggleRunbookModal = () => {
    this.setState({
      showRunModal: !this.state.showRunModal,
      runModalAsSave: false,
    });
  };

  toggleWarning = () => {
    this.setState({
      showSaveWarning: !this.state.showSaveWarning,
    });
  };

  toggleUnsavedWarning = () => {
    this.setState({
      showUnsavedWarning: !this.state.showUnsavedWarning,
    });
  };

  toggleWarningIgnore = () => {
    this.setState(
      {
        ignoreUnsavedWarning: true,
      },
      () => {
        !!this.state.nextVersion
          ? this.loadVersion(this.state.nextVersion)
          : this.goBack();
      },
    );
  };

  toggleScheduleModal = () => {
    if (shouldForceDefaultValues(this.state.runbookObj, true)) {
      this.setState({
        showRunModal: true,
        runModalAsSave: true,
        tryingToSchedule: true,
      });
      return;
    }
    if (this.state.toggleScheduleModal === true) {
      this.setState(prevState => ({
        toggleScheduleModal: !prevState.toggleScheduleModal,
        showRunModal: false,
        runModalAsSave: false,
        tryingToSchedule: false,
      }));
    } else {
      this.setState(prevState => ({
        toggleScheduleModal: !prevState.toggleScheduleModal,
      }));
    }
  };

  /* this gets called when a new node is dropped into the edit area, or specific step information changes */
  updateRunbookObj = runbookObj => {
    this.setState({
      runbookObj,
    });
  };

  updateRunbookDescription = updatedDescription => {
    if (!isEmpty(this.state.runbookObj)) {
      const updatedRunbookObj = Object.assign(
        Object.create(Object.getPrototypeOf(this.state.runbookObj)), // Dev: Vivek - Need to change this logic (Including root cause)
        { ...this.state.runbookObj, description: updatedDescription },
      );

      updatedRunbookObj.setDescription.call(
        updatedRunbookObj,
        updatedDescription,
      );

      this.setState({
        runbookObj: updatedRunbookObj,
      });
    }
  };

  isPreparing(prevProps) {
    if (this.props.isFetchingActiveRunbook) {
      if (!this.state.preparingEditor) {
        this.setState({
          preparingEditor: true,
          message: this.props.fetchingMessage,
        });
      }
    } else if (this.props.isFetchingSnippets) {
      if (!this.state.preparingEditor) {
        this.setState({
          preparingEditor: true,
          message: this.props.fetchingMessage,
        });
      }
    } else if (
      !this.props.isFetchingActiveRunbook &&
      prevProps.isFetchingActiveRunbook
    ) {
      this.setState({ preparingEditor: false });
    } else {
      if (prevProps.preparingEditor !== this.props.preparingEditor) {
        this.setState({ preparingEditor: false }); //, () => this.updateMessage());
      } else if (this.state.preparingEditor) {
        this.setState({ preparingEditor: false }); //, () => this.updateMessage());
      }
    }
  }

  setActivePanel = (activePanel, activePanelTitle) => {
    const _activePanel =
      activePanel === this.state.activePanel ? "" : activePanel;
    this.setState({ activePanel: _activePanel, activePanelTitle });
  };

  closeTabs = event => {
    this.setState({
      activePanel: null,
      activePanelTitle: "",
    });
  };

  onDragOver = e => {
    e.preventDefault();
  };

  loadVersion = version => {
    this.state.showUnsavedWarning && this.toggleUnsavedWarning();
    if (!this.state.ignoreUnsavedWarning) {
      if (this.runbookHasChanged()) {
        this.setState({
          showUnsavedWarning: true,
          nextVersion: version,
        });
        return;
      }
    }
    if (!!this.state.nextVersion) {
      this.setState({ ignoreUnsavedWarning: false });
      this.props.notifyRunbookUpdate(false);
    }
    this.setState({ nextVersion: "" }, () => {
      this.updateMessage(`Loading version ${version}`);
      this.props.onChangeVersionAction(this.state.runbookId, version);
    });
  };

  loadedDifferentVersion = prevProps => {
    if (
      prevProps.activeRunbookVersion !== this.props.activeRunbookVersion &&
      !!prevProps.activeRunbookVersion
    ) {
      this.updateMessage();
    }
  };

  reloaded = prevState => {
    if (prevState.runbookObj !== this.state.runbookObj) {
      this.updateMessage();
    }
  };

  saveError = prevProps => {
    if (
      prevProps.runbookSaveError !== this.props.runbookSaveError &&
      this.props.runbookSaveError
    ) {
      this.setState({
        showRunModal: false,
        runModalAsSave: false,
      });
      setTimeout(() => {
        this.updateMessage();
      }, 2000);
    }
  };

  updateActiveNode = (node, Key, value) => {
    this.props.updateNode(node, {
      Key,
      value,
    });
  };

  /**
   * Workaround fix is that make API call again and the check the HTTP status and also check
   * if currentVersion and latestVersion is different, we are validating it against LatestVersion only,
   * because AWS also has validation check only latestVersion
   */
  showScheduleModal = async runbookObj => {
    runbookObj = cleanRunbooks(runbookObj);
    const activeRunbook = this.props.activeRunbook;
    try {
      await Api.updateRunbook(activeRunbook, runbookObj.toSSM());
    } catch (e) {
      const err = e.ERROR?.response;
      if (err) {
        console.log(err?.status);
        if (
          err?.status === 409 &&
          this.props.activeRunbookVersion !==
            this.props.activeRunbook?.LatestVersion
        ) {
          return false;
        }
      }
    }

    return true;
  };

  saveRunbook = async () => {
    if (isEmpty(this.props.activeRunbook.Description)) {
      this.toggleWarning();
      return;
    } else if (shouldForceDefaultValues(this.state.runbookObj)) {
      this.setState({
        showRunModal: true,
        runModalAsSave: true,
      });
      return;
    } else if (this.props.activeRunbookVersion === "Draft") {
      this.props.saveNewRunbook(this.state.runbookObj);
    } else {
      this.props.unsetNode();
      this.props.updateRunbook(this.state.runbookObj);
    }
    this.setState({
      showRunModal: false,
      runModalAsSave: false,
    });
    this.props.notifyRunbookUpdate(false);

    if (this.state.tryingToSchedule) {
      /// TODO: remove API call from here, handle it better by moving the API call here.
      const showSchedule = await this.showScheduleModal(this.state.runbookObj);
      if (showSchedule) this.toggleScheduleModal();
    }
  };

  addTagToRunbook = tag => {
    this.props.addTagToRunbook(tag);
    this.props.notifyRunbookUpdate(true);
  };

  removeTagFromRunbook = tag => {
    this.props.removeTagFromRunbook(tag);
  };

  saveNewVersion = () => {
    this.setState({
      showSaveNewVersionModal: true,
    });
  };

  isSaving(prevProps) {
    if (this.props.runbookIsSaving) {
      if (prevProps.runbookIsSaving !== this.props.runbookIsSaving) {
        this.setState({
          preparingEditor: true,
          savingRunbook: true,
          showRunModal: false,
        });
      }
    } else {
      if (
        prevProps.runbookIsSaving !== this.props.runbookIsSaving &&
        prevProps.runbookIsSaving
      ) {
        this.setState(
          {
            preparingEditor: false,
            savingRunbook: false,
            runbookNew: false,
          },
          () => {
            this.props.setRunbookIsNew(false);
            this.props.addToRunbooks(this.props.activeRunbook);
          },
        );
      }
    }
  }

  rerenderEditor = () =>
    this.editorDesignReference.current &&
    this.editorDesignReference.current.rerenderEditor();

  getAllNodesInEditor = () =>
    this.editorDesignReference.current &&
    this.editorDesignReference.current.getAllNodesInEditor();

  addLinkToStep = (activeNode, runbookStep) => {
    this.editorDesignReference.current &&
      this.editorDesignReference.current.addLinkToStep(activeNode, runbookStep);
  };

  goToProfilePage = () => {
    this.setState({
      redirect: true,
      redirectTo: {
        pathname: RouteConstants.userProfile.url,
        state: { activeTab: "account-plan" },
      },
    });
  };

  toggleWorkflowLimitDialog = () => {
    this.setState({
      isWorkflowLimitDialogVisible: !this.state.isWorkflowLimitDialogVisible,
    });
  };

  toggleRunsLimitDialog = () => {
    this.setState({
      isRunsLimitDialogVisible: !this.state.isRunsLimitDialogVisible,
    });
  };

  toggleIsTourOpen = () => {
    this.setState({ isTourOpen: !this.state.isTourOpen });
  };

  toggleIsWelcomeDialogOpen = () => {
    this.setState({
      isWelcomeDialogVisible: !this.state.isWelcomeDialogVisible,
    });
  };

  startTour = () => {
    this.toggleIsWelcomeDialogOpen();
    this.toggleIsTourOpen();
  };

  render() {
    const tags = {
      meta: {
        content:
          "width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0",
        name: "viewport",
      },
    };
    const contextValue = {
      activePanel: this.state.activePanel,
      activePanelTitle: this.state.activePanelTitle,
      activeRunbookVersion: this.props.activeRunbookVersion,
      addTagToRunbook: this.addTagToRunbook,
      removeTagFromRunbook: this.removeTagFromRunbook,
      setActivePanel: this.setActivePanel,
      snippets: this.props.snippetsByCategory,
      versions: sortObject(
        this.props.activeRunbook ? this.props.activeRunbook["Versions"] : [],
        "DocumentVersion",
        "desc",
        true,
      ),
      runbookId: this.state.runbookId,
      loadVersion: this.loadVersion,
      toggleRunbookModal: this.toggleRunbookModal,
      runbook: this.props.activeRunbook,
      activeNode: this.props.activeNode,
      updateActiveNode: this.updateActiveNode,
      activeActionNode: this.props.activeActionNode,
      setActiveActionNode: this.props.setActiveActionNode,
      clearActiveActionNode: this.props.clearActiveActionNode,
      updateRunbookObj: this.updateRunbookObj,
      runbookObj: this.state.runbookObj,
      saveRunbook: this.saveRunbook,
      goBack: this.goBack,
      updateRunbook: this.updateRunbook,
      showRunModal: this.state.showRunModal,
      fetchAwsServices: this.props.fetchAwsServices,
      fetchAwsOperations: this.props.fetchAwsOperations,
      fetchAwsOperationDetails: this.props.fetchAwsOperationDetails,
      awsServices: this.props.awsServices,
      awsOperations: this.props.awsOperations,
      awsOperationDetails: this.props.awsOperationDetails,
      runbookDidUpdate: this.props.runbookDidUpdate,
      notifyRunbookUpdate: this.props.notifyRunbookUpdate,
      deleteRunbookVersion: this.props.deleteRunbookVersion,
      runbookVersionDeleteStatus: this.props.runbookVersionDeleteStatus,
      datadogMonitorList: this.props.datadogMonitorList,
      currentDatadogMonitor: this.props.currentDatadogMonitor,
      datadogError: this.props.datadogError,
      isFetchingDatadogMonitorList: this.props.isFetchingDatadogMonitorList,
      isFetchingDatadogMonitor: this.props.isFetchingDatadogMonitor,
      isConnectingDatadogMonitor: this.props.isConnectingDatadogMonitor,
      getDatadogMonitorList: this.props.getDatadogMonitorList,
      getDatadogMonitor: this.props.getDatadogMonitor,
      updateDefaultVersion: this.props.updateDefaultVersion,
      lastAwsOperationFetched: this.props.lastAwsOperationFetched,
      terraformWorkspaces: this.props.terraformWorkspaces,
      resourcesList: this.props.resourcesList,
      isVersionUpdateSuccess: this.props.isVersionUpdateSuccess,
      isVersionUpdateFailed: this.props.isVersionUpdateFailed,
      isFetchingVersion: this.props.isFetchingVersion,
      toggleScheduleModal: this.toggleScheduleModal,
      runbookSchedule: this.props.runbookSchedule,
      connectors: this.props.connectors,
      resetWalkthrough: this.toggleIsWelcomeDialogOpen,
    };

    return (
      <Provider value={contextValue}>
        {this.state.isWelcomeDialogVisible && (
          <Modal
            onClose={this.toggleIsWelcomeDialogOpen}
            title="Welcome to The Workflow Editor"
            showClose={true}
            backgroundCanClose={false}
            coverClass={`modal-cover__editor-welcome`}
            containerClass={`modal-container__editor-welcome`}
            contentClass={`modal-content__editor`}
          >
            <div className="welcome-dialog">
              <div className="welcome-dialog-image-container">
                <img alt="welcome" src={WelcomeDialog}></img>
              </div>
              <span>
                The Fylamynt Workflow Editor allows you to build Workflows with
                little or no code. Fylamynt's visual interface is fast,
                easy-to-use and flexible.
                <br />
                <br />
                Let's take a look around. It shouldn't take more than a minute.
              </span>
              <div className="welcome-dialog-footer">
                <button
                  className="welcome-dialog-footer-button"
                  onClick={this.startTour}
                >
                  Let's Go
                </button>
              </div>
            </div>
          </Modal>
        )}
        <Tour
          onRequestClose={this.toggleIsTourOpen}
          steps={getTourSteps(
            this.toggleIsTourOpen,
            this.toggleIsWelcomeDialogOpen,
          )}
          isOpen={this.state.isTourOpen}
          maskClassName="mask"
          maskSpace={0}
          className={`helper`}
          rounded={0}
          showNavigation={false}
          showNavigationNumber={false}
          showNumber={false}
          showButtons={false}
          closeWithMask={false}
        />
        <MetaTag tags={tags} removeMetaTags={["viewport"]} />
        {this.state.showSaveNewVersionModal && (
          <EditorSaveNewVersionModal
            onClose={() => {
              this.setState({ showSaveNewVersionModal: false });
            }}
          />
        )}
        <div className="editor-page-wrap" ref={this.ref}>
          <EditorToolbar
            toggleWorkflowLimitDialog={this.toggleWorkflowLimitDialog}
            toggleRunsLimitDialog={this.toggleRunsLimitDialog}
            unconfiguredNodes={this.state.unconfiguredNodes}
          />
          <EditorMenu openTour={this.toggleIsTourOpen} />
          <EditorSlideouts
            updateRunbookDescription={this.updateRunbookDescription}
            actionButton={{
              icon: iconPlus,
              action: this.saveNewVersion,
              activePanel: "versions",
            }}
            closePanel={this.closeTabs}
            activePanel={this.state.activePanel}
          />
          <EditorDesignArea
            ref={this.editorDesignReference}
            onDragOver={this.onDragOver}
            preparing={this.state.preparingEditor}
            snippets={this.props.snippets}
            runbook={this.props.activeRunbook}
            setActiveNode={this.props.setActiveNode}
            runbookObj={this.state.runbookObj}
            updateRunbookObj={this.updateRunbookObj}
            notifyRunbookUpdate={this.props.notifyRunbookUpdate}
            awsServices={this.props.awsServices}
            awsOperations={this.props.awsOperations}
            awsOperationDetails={this.props.awsOperationDetails}
            fetchAwsServices={this.props.fetchAwsServices}
            fetchAwsOperations={this.props.fetchAwsOperations}
            fetchAwsOperationDetails={this.props.fetchAwsOperationDetails}
            reloadEditor={this.state.reloadEditor}
            connectors={this.props.connectors}
            unconfiguredNodes={this.state.unconfiguredNodes}
          />
          <EditorRightPanel
            rerenderEditor={this.rerenderEditor}
            addLinkToStep={this.addLinkToStep}
            fetchJiraDetails={this.props.fetchJiraDetails}
            fetchTargetAccountAlarmsList={
              this.props.fetchTargetAccountAlarmsList
            }
            unconfiguredNodes={this.state.unconfiguredNodes}
          />
          {this.state.showRunModal && (
            <RunbookRun
              runbookObj={this.state.runbookObj}
              toggleRunbookModal={this.toggleRunbookModal}
              runExecution={this.runExecution}
              runModalAsSave={this.state.runModalAsSave}
              saveRunbook={this.saveRunbook}
            />
          )}
          {this.state.toggleScheduleModal && (
            <RunbookScheduleModal
              toggleScheduleModal={this.toggleScheduleModal}
              currentSchedule={this.props.runbookSchedule}
            />
          )}
          {this.state.redirect && <Redirect to={this.state.redirectTo} push />}
          {this.state.showSaveWarning && (
            <SaveWarning toggleWarning={this.toggleWarning} />
          )}
          {this.state.showUnsavedWarning && (
            <UnsavedWarning
              toggleWarning={this.toggleUnsavedWarning}
              toggleWarningIgnore={this.toggleWarningIgnore}
            />
          )}
        </div>
        {!this.state.showUnsavedWarning && (
          /* we need urlHasChanged to detect if it's just a hashtag that changed (which we can ignore ) */
          <Prompt
            when={this.props.runbookDidUpdate && urlHasChanged(this.location)}
            message="You have unsaved changes, are you sure you want to leave?"
          />
        )}
        {this.state.isRunsLimitDialogVisible && (
          <LimitDialog
            limitType={"runs"}
            goTo={this.goToProfilePage}
            toggleDialog={this.toggleRunsLimitDialog}
          />
        )}
        {this.state.isWorkflowLimitDialogVisible && (
          <LimitDialog
            limitType={"workflow"}
            goTo={this.goToProfilePage}
            toggleDialog={this.toggleWorkflowLimitDialog}
          />
        )}
      </Provider>
    );
  }
}
