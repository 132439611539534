import React, { useState, useEffect, useContext } from "react";
import * as yup from "yup";
import { TextInput } from "@components/ui";
import { RunbookEditorContext } from "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.context";

const urlSchema = yup.object().shape({
  url: yup.string().required().url(),
});

const URL = ({ title, value, onChange }) => {
  const initialState = value;

  const [url, setUrl] = useState(initialState);
  const [error, setError] = useState(true);

  const context = useContext(RunbookEditorContext);
  const runbookNode = context.activeNode.extras.runbookNode;

  useEffect(() => {
    urlSchema
      .validate({ url })
      .then(() => {
        setError(false);
      })
      .catch(() => {
        setError(true);
      });
  }, [url]);

  useEffect(() => {
    if (runbookNode) runbookNode.showHideWarning(error);
  }, [runbookNode, error, url]);

  return (
    <div className="url-wrap">
      <TextInput
        name={title}
        label={title}
        labelPosition="top"
        labelClassName="label"
        id="url-editor-input"
        className={error ? "rule-input rule-input-error" : "rule-input"}
        value={url}
        onChange={value => {
          onChange(value);
          setUrl(value);
        }}
      />
    </div>
  );
};

export default URL;
