import React from "react";
import { Consumer } from "../../../runbook-editor-lib/runbook-editor.context";

export default class Spacer extends React.Component {
  render() {
    return (
      <Consumer>
        {({ setActivePanel, activePanel }) => (
          <div
            onClick={() => setActivePanel()}
            className={`editor-menu-item-container editor-menu-item-spacer `}
          ></div>
        )}
      </Consumer>
    );
  }
}
