import React from "react";
import { Consumer } from "../../../../../runbook-editor-lib/runbook-editor.context";
import { Accordion, AccordionLabel, AccordionSection } from "@components/ui";
import SnippetControl from "./SnippetControl";
import { getFilteredSnippetList, addBucketName } from "@lib/utils";

export default class SnippetControlsList extends React.Component {
  render() {
    return (
      <Consumer>
        {({ snippets }) => {
          return (
            snippets.hasOwnProperty("CONTROL") &&
            Object.keys(snippets.CONTROL).map(key => {
              const category = "CONTROLS";
              const visibleSnippets = snippets.CONTROL[key].filter(
                snippet => snippet.visible !== false,
              );
              const finalSnippetList = getFilteredSnippetList(
                addBucketName(visibleSnippets, `CONTROL STRUCTURES`),
                this.props.searchVal,
              );
              return (
                finalSnippetList.length > 0 && (
                  <Accordion isExpanded={true} useArrow={true} key={category}>
                    <AccordionLabel className="editor-accordion-label">
                      CONTROL STRUCTURES
                    </AccordionLabel>
                    <AccordionSection>
                      <div className="snippet-block">
                        {finalSnippetList.map(snippet => (
                          <SnippetControl snippet={snippet} key={snippet.id} />
                        ))}
                      </div>
                    </AccordionSection>
                  </Accordion>
                )
              );
            })
          );
        }}
      </Consumer>
    );
  }
}
