import React from "react";
import { OverflowMenu } from "@components/ui";

export default (onRemove, onEdit) => {
  return [
    {
      name: "Module Id",
      selector: "module_id",
      grow: 2,
    },
    {
      name: "Repo Id",
      selector: "repo_id",
      grow: 2,
    },
    {
      name: "Directory",
      selector: "directory",
      grow: 2,
    },
    {
      name: "Ref",
      selector: "ref",
      grow: 2,
    },
    {
      name: "",
      selector: "options",
      cell: row => (
        <OverflowMenu>
          <div className="button-grey" onMouseDown={() => onEdit(row)}>
            Edit
          </div>
          <div className="button-delete" onMouseDown={() => onRemove(row)}>
            Delete
          </div>
        </OverflowMenu>
      ),
      grow: 1,
    },
  ];
};
