import React from "react";
import { HeaderMain } from "@components/modules";
import { Consumer } from "../snippet-details-lib/snippet-details.context";

export default () => (
  <Consumer>
    {({ snippet }) => (
      <React.Fragment>
        <div>
          <HeaderMain
            title={snippet.name}
            breadCrumbText="Actions / Action Details"
          />
          <div className="margin-top-5b">
            {snippet?.tags?.map((tag, i) => {
              return (
                <span className="tag" key={i}>
                  {tag}
                </span>
              );
            })}
          </div>
        </div>
      </React.Fragment>
    )}
  </Consumer>
);
