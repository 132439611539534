import React from "react";
import ReactTooltip from "react-tooltip";
import { useHistory } from "react-router-dom";
import { RouteConstants } from "../../../routes/Constants";
import iconTrash from "@assets/images/icons/icon-trash.svg";
import iconDownload from "@assets/images/icons/icon-download-2.svg";
import iconCopy from "@assets/images/icons/icon-copy.svg";
import iconExecute from "@assets/images/icons/icon-executions.svg";
import iconSchedule from "@assets/images/icons/icon-calendar.svg";
import "./RunbookDetailHeaderButtons.scss";

/**
 * Converts JS Object to JSON doc and saves it.
 * @param {object} data - JavaScript Object to be converted to JSON document
 * @param {string} name - The name of file to be downloaded
 * @param {string} type - The type of document (ex: "text/utf-8", "text/plain", etc)
 * @return {void} - Nothing
 *
 */

const saveWorkflowToJson = (
  data,
  name = "workflow",
  type = "application/json",
) => {
  const {
    URL: { createObjectURL, revokeObjectURL },
  } = window;

  let jsonData;

  if (data) {
    jsonData = {
      Name: data.Name,
      Tags: data.Tags,
      Content: data.Content,
    };
  } else {
    jsonData = {};
  }

  let blob = new Blob([JSON.stringify(jsonData)], { type });
  let url = createObjectURL(blob);

  // Create 'a' element and assign data
  let anchor = document.createElement("a");
  anchor.style.display = "none";
  anchor.setAttribute("href", url);
  anchor.setAttribute("download", name + ".json");
  anchor.click();

  // Clean up
  setTimeout(() => {
    revokeObjectURL(url);
  }, 200);
};

const WorkflowButtons = props => {
  const history = useHistory();

  return (
    <div className="wf-btn-container">
      <div
        className="wf-btn-item delete mr-5"
        data-for="deleteWorkflow"
        data-tip="Delete Workflow"
        onClick={() => props.setShowConfirm(props.runbook.Name)}
      >
        <img src={iconTrash} alt="Delete Workflow" width={16} height={16} />
      </div>
      <ReactTooltip
        id="deleteWorkflow"
        place="bottom"
        effect="solid"
        type="light"
      />
      <div
        className="wf-btn-item mr-5"
        data-for="downloadWorkflow"
        data-tip="Download Workflow"
        onClick={() => saveWorkflowToJson(props.runbook, props.runbook.Name)}
      >
        <img
          src={iconDownload}
          alt="Download Workflow"
          width={16}
          height={16}
        />
      </div>
      <ReactTooltip
        id="downloadWorkflow"
        place="bottom"
        effect="solid"
        type="light"
      />
      <div
        className="wf-btn-item mr-5"
        data-for="duplicateWorkflow"
        data-tip="Duplicate Workflow"
        onClick={() => props.openDuplicateRunbookModal(props.runbook)}
      >
        <img
          src={iconCopy}
          alt="Duplicate Workflow"
          title="Duplicate Workflow"
          width={16}
          height={16}
        />
      </div>
      <ReactTooltip
        id="duplicateWorkflow"
        place="bottom"
        effect="solid"
        type="light"
      />
      <div
        className="wf-btn-item mr-5"
        data-for="viewExecutions"
        data-tip="View Workflow Executions"
        onClick={() =>
          history.push(
            `${RouteConstants.executions.url}?searchTerm=${props.runbook.name}`,
          )
        }
      >
        <img
          src={iconExecute}
          alt="View Workflow Executions"
          width={16}
          height={16}
        />
      </div>
      <ReactTooltip
        id="viewExecutions"
        place="bottom"
        effect="solid"
        type="light"
      />
      <div
        className="wf-btn-item"
        data-for="viewSchedules"
        data-tip="View Workflow Schedules"
        onClick={() =>
          history.push(`/schedules?searchTerm=${props.runbook.name}`)
        }
      >
        <img
          src={iconSchedule}
          alt="View Workflow Schedules"
          width={16}
          height={16}
        />
      </div>
      <ReactTooltip
        id="viewSchedules"
        place="bottom"
        effect="solid"
        type="light"
      />
    </div>
  );
};

export default WorkflowButtons;
