import Api from "@lib/api";
import {
  EXECUTION_IS_FETCHING,
  EXECUTION_IS_RUNNING,
  EXECUTION_FETCH_ERROR,
  EXECUTION_FETCH_SUCCESS,
  EXECUTION_FETCHING_DETAILS,
  EXECUTION_DETAILS_SUCCESS,
  EXECUTION_RESUME_SUCCESS,
  EXECUTION_STEP_FETCH_ERROR,
  RUNBOOK_EXECUTION_SUCCESS,
  RUNBOOK_EXECUTION_ERROR,
} from "@redux/types";

export const fetchExecutions = (workflow_name = null) => {
  return async dispatch => {
    _isFetching(dispatch, true, "Fetching Executions...");
    const data = await Api.getExecutions(workflow_name).catch(e =>
      dispatch({ type: EXECUTION_FETCH_ERROR, payload: e }),
    );
    dispatch({
      type: EXECUTION_FETCH_SUCCESS,
      payload: data.executions,
    });
    _isFetching(dispatch, false, "");
  };
};

export const fetchExecutionDetails = id => {
  return async dispatch => {
    _isFetchingDetails(dispatch, true);
    const data = await Api.getRunbookExecution(id).catch(() => {
      dispatch({
        type: EXECUTION_STEP_FETCH_ERROR,
        payload: true,
      });
    });
    dispatch({
      type: EXECUTION_DETAILS_SUCCESS,
      payload: data,
      executionId: id,
    });
    _isFetchingDetails(dispatch, false);
  };
};

export const resumeExecution = (id, stepName, action, connectionId) => {
  return async dispatch => {
    const data = await Api.executeResumeStep(
      id,
      stepName,
      action,
      connectionId,
    ).catch(e => {
      console.log("Error attempting to resume execution");
      return Promise.reject();
    });
    dispatch({
      type: EXECUTION_RESUME_SUCCESS,
      payload: data,
    });
    dispatch(fetchExecutionDetails(id));
    return Promise.resolve();
  };
};

//dummy function for now
export const doExecution = (runbookName, parameters, version) => {
  return async dispatch => {
    dispatch({
      type: EXECUTION_IS_RUNNING,
      payload: true,
      message: "Executing workflow, please wait...",
    });
    const data = await Api.executeRunbook(
      runbookName,
      parameters,
      version,
    ).catch(e => dispatch({ type: RUNBOOK_EXECUTION_ERROR, payload: data }));
    dispatch({
      type: RUNBOOK_EXECUTION_SUCCESS,
      payload: data,
    });
    dispatch({
      type: EXECUTION_IS_RUNNING,
      payload: false,
      message: "Complete",
    });
  };
};

function _isFetching(dispatch, fetching, message = "") {
  return dispatch({
    type: EXECUTION_IS_FETCHING,
    payload: fetching,
    message: message,
  });
}

function _isFetchingDetails(dispatch, fetching) {
  return dispatch({
    type: EXECUTION_FETCHING_DETAILS,
    payload: fetching,
  });
}
