import { getText } from "./select.lib";

export function onClick(e) {
  e.stopPropagation();
  this.props.onClick(this.getValue(), this.props.text || this.props.children);
}

export function getValue() {
  if (typeof this.props.value !== "undefined") {
    return this.props.value;
  } else {
    let text = getText(this.props.children);
    return text;
  }
}
