import React from "react";
import "./unconfigured-message.scss";
import InfoIcon from "@assets/images/icons/icon-info.svg";
import { Link } from "react-router-dom";

const UnconfiguredMessage = () => {
  return (
    <div className="right-panel-container">
      <div className="right-panel-container-items">
        <div>
          <img src={InfoIcon} alt="info icon" width="40px" height="40px" />
        </div>
        <div>
          <p className="editor-detail-placeholder-text">
            Looks like this integration isn't setup yet.
          </p>
        </div>
        <button className="right-panel-container-button">
          <Link type="button" role="button" to="/settings">
            Complete Setup
          </Link>
        </button>
      </div>
    </div>
  );
};

export default UnconfiguredMessage;
