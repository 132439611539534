/* this gets called when we are setting the text of the SelecteSelected text input */
export function setValue() {
  this.setState({
    value: this.props.selectedText,
    text: this.props.selectedText,
  });
}

export function onClick(e) {
  e.stopPropagation();
  this.props.toggle();
  this.props.setStaticValue(e.target.dataset.value, e.target.dataset.text);
  this.setTitleText();
}

export function forceOnclick(e) {
  e.stopPropagation();
  this.props.toggle();
}

export function setTitleText() {
  this.setState({
    title: this.props.selectedText ? "" : this.props.title,
  });
}

export function onChange(e) {
  this.setState(
    {
      text: e.target.value,
      value: e.target.value,
    },
    () => {
      this.props.performSearch(this.state.value);
    },
  );
}

export function clear(e) {
  e.stopPropagation();
  this.setState(
    {
      value: "",
      text: "",
    },
    () => {
      this.props.setStaticValue();
      this.props.performSearch(this.state.value);
      this.inputRef.current.focus();
      this.props.clearSearch();
    },
  );
}

export function inputClick(e) {
  e.stopPropagation();
  if (this.props.expanded) {
    return;
  }
  this.props.toggle();
  this.setState({
    showClear: true,
  });
}

export function keyPress(e) {
  e.cancelBubble = true;
  if (e.key === "Enter") {
    e.stopPropagation();
    this.props.enterSelect();
    this.inputRef.current.blur();
  } else if (e.key === "Backspace") {
    e.stopPropagation();
  }
}
