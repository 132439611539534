import React from "react";
import { IconSearch } from "@components/ui/icons";

export default class Search extends React.Component {
  render() {
    return (
      <div className="editor-menu-item-container" style={{display:"none"}}>
        <IconSearch className="editor-menu-item-svg" />
      </div>
    );
  }
}
