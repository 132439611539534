import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  toggleConfigPanel,
  saveConnecterSchema,
  savingConnecterSchema,
  editConnecterSchema,
  isFetching,
  updateStatus,
} from "@redux/actions/settingsPanel.action";
import { fetchAPIKeys } from "@redux/actions/apikeys.actions";
import {
  isEmpty,
  formatLabelName,
  getIntersection,
  sortObjectKeys,
} from "@lib/utils";
import { Button, Wait } from "@components/ui";
import { Formik } from "formik";
import * as Yup from "yup";
import FormInput from "./FormInput";
import { recursiveDataParser } from "@src/utils/common.ts";
import Api from "@lib/api/api";
import InstanaWebhookStatus from "./Layouts/InstanaWebhookStatus";
import InstanaWebhookKey from "./Layouts/InstanaWebhookKey";
import SlackNotification from "./Layouts/SlackNotification";
import FormInputTextArea from "./FormInputTextArea";

class ConfigPanelContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: false,
      validationSchema: {},
      initialValues: {},
      formData: {},
      schemaPropertyInfo: {},
      requiredFields: [],
      showFormSubmitMessage: false,
      slackNotificationFlag: false,
      slackChannels: [],
      selectedSlackChannels: [],
      instanaWebhookStatus: false,
    };
  }

  getCurrentSchema = () => {
    const nodeName = this.props.currentNode.name.toLowerCase();
    return this.props.currentNode.status === "Connected"
      ? this.props.connectors[nodeName].schema
      : this.props.currentNodeSchema;
  };

  getValidationSchema = formData => {
    const currentSchema = this.getCurrentSchema();
    let validationSchema = {};
    for (const key in formData) {
      if (currentSchema.required.indexOf(key) !== -1) {
        const label = formatLabelName(key);
        validationSchema[key] = Yup.string()
          .required(`${label} is required`)
          .trim("This field can't be empty");
      }
    }
    validationSchema["slackChannel"] = Yup.array();
    return validationSchema;
  };

  getFormFieldValue = formData => {
    let fieldValues = { ...formData };
    for (const key in fieldValues) {
      if (fieldValues[key].constructor.name === "Object") {
        fieldValues[key] = JSON.stringify(fieldValues[key]);
      }
      if (fieldValues[key].constructor.name === "Array") {
        fieldValues[key] = fieldValues[key].join();
      }
    }

    return fieldValues;
  };

  filterFields = formData => {
    const currentSchema = this.getCurrentSchema();

    let filteredFields = {};
    for (const key in formData) {
      if (
        !!key &&
        currentSchema.properties.hasOwnProperty(key) &&
        !currentSchema["properties"][key]["hidden"]
      ) {
        filteredFields[key] = formData[key];
      }
    }

    return filteredFields;
  };

  async componentDidMount() {
    !this.props.apiKeyList?.length && this.props.fetchAPIKeys();

    let currentNodeName = this.props.currentNode?.name?.toLowerCase() || "";

    let schemaPropertyInfo = !!currentNodeName
      ? this.props.connectors[currentNodeName]?.schema?.properties
      : {};

    let rawFormData = {},
      slackNotificationFlag,
      slackChannels = [...this.state.slackChannels],
      selectedSlackChannels = [],
      instanaWebhookStatus;

    if (isEmpty(this.props.currentNodeConfigInfo)) {
      let formSchema = { ...this.props.currentNodeSchema.properties };
      !isEmpty(formSchema) &&
        Object.keys(formSchema).map(val => (rawFormData[val] = ""));
    } else {
      rawFormData = {
        ...JSON.parse(this.props.currentNodeConfigInfo),
      };
    }
    if (this.props.currentNode.name.toLowerCase() === "slack") {
      if (!this.state.slackChannels.length) {
        this.props.isFetching(true, "Fetching Slack Channels");
        const response = await Api.fetchSlackChannels("slack");
        if (!isEmpty(response) && response.channels) {
          slackChannels = response.channels;
        }
        this.props.isFetching(false, "");
      }

      slackNotificationFlag =
        rawFormData.hasOwnProperty("notification_status") &&
        (rawFormData.notification_status.toLowerCase() === "off"
          ? false
          : true);
      selectedSlackChannels = rawFormData.hasOwnProperty(
        "notification_channels",
      )
        ? getIntersection(rawFormData.notification_channels, slackChannels)
        : [];
    }
    if (this.props.currentNode.name.toLowerCase() === "instana") {
      instanaWebhookStatus =
        rawFormData.hasOwnProperty("webhook_status") &&
        (rawFormData.webhook_status.toLowerCase() === "off" ? false : true);
    }
    const formData = this.filterFields(rawFormData);
    const validationSchema = this.getValidationSchema(rawFormData);
    const initialValues = this.getFormFieldValue(rawFormData);

    this.setState({
      schemaPropertyInfo,
      formData,
      initialValues,
      validationSchema: Yup.object(validationSchema),
      showForm: true,
      slackNotificationFlag,
      slackChannels,
      selectedSlackChannels,
      instanaWebhookStatus,
    });
  }

  getFooter = formik => {
    return (
      <div className="side-panel__footer">
        <Button
          text="Cancel"
          buttonStyle="secondary"
          style={{ width: "50%" }}
          onClick={() => this.props.toggleConfigPanel(false)}
          type="button"
          isLoading={this.props.isLoading}
        />
        <Button
          text={isEmpty(this.props.currentNodeSchema) ? "Update" : "Save"}
          buttonStyle="primary"
          style={{ width: "50%" }}
          size="large"
          onClick={formik.handleSubmit}
          isLoading={this.props.isLoading}
        />
      </div>
    );
  };

  getSubtext = field =>
    this.state.schemaPropertyInfo.hasOwnProperty(field) &&
    "(" + this.state.schemaPropertyInfo[field]["description"] + ")";

  getFormFields = formik => {
    const currentSchema = this.getCurrentSchema();
    const sortedFormData = sortObjectKeys(this.state.formData);
    return (
      !isEmpty(sortedFormData) &&
      Object.keys(sortedFormData).map((field, key) => {
        let fieldValue = formik.values[field];
        try {
          fieldValue = JSON.parse(formik.values[field]);
        } catch (err) {}
        let maskedEntries = [
          "api_id",
          "api_key",
          "app_key",
          "JIRA_PUBLIC_KEY",
          "access_token",
          "token",
          "ssh_private_key",
          "external_id",
          "instana_api_token",
          "authToken",
          "passphrase",
          "password",
          "APIToken",
          "opsgenie_api_token",
          "pagerduty_api_token",
        ];

        let textAreas = ["JIRA_PUBLIC_KEY", "ssh_private_key"];
        let isMasked = maskedEntries.includes(field) ? true : null;

        return !textAreas.includes(field) ? (
          <FormInput
            fieldName={formatLabelName(field)}
            name={field}
            showJson={true}
            id={field}
            className={`overflow-ellipsis`}
            fieldValue={fieldValue}
            touched={formik.touched}
            errors={formik.errors}
            placeholder={`Enter ${formatLabelName(field)}`}
            subText={this.getSubtext(field)}
            key={key}
            autoComplete="off"
            readOnly={currentSchema.properties[field].read_only}
            isMasked={isMasked}
          />
        ) : (
          <FormInputTextArea
            fieldName={formatLabelName(field)}
            name={field}
            showJson={true}
            id={field}
            fieldValue={fieldValue}
            touched={formik.touched}
            errors={formik.errors}
            placeholder={`Enter ${formatLabelName(field)}`}
            subText={this.getSubtext(field)}
            key={key}
            autoComplete="off"
            readOnly={currentSchema.properties[field].read_only}
            isMasked={isMasked}
            className={"text-area"}
          />
        );
      })
    );
  };

  toggleSlackNotification = async () => {
    // let slackChannels = [...this.state.slackChannels];
    let action;
    if (this.state.slackNotificationFlag) {
      this.props.isFetching(true, "Disabling Slack Notifications");
      action = await this.props.updateStatus(
        "slack",
        "disable",
        "notification_status",
      );
    } else {
      this.props.isFetching(true, "Enabling Slack Notifications");
      action = await this.props.updateStatus(
        "slack",
        "enable",
        "notification_status",
      );
    }
    this.props.isFetching(false, "");
    if (action.type === "CONNECTOR_STATUS_UPDATE_SUCCESS")
      this.setState(prevState => ({
        slackNotificationFlag: !prevState.slackNotificationFlag,
        // slackChannels,
      }));
  };

  toggleInstanaWebhookStatus = () => {
    this.setState(prevState => ({
      instanaWebhookStatus: !prevState.instanaWebhookStatus,
    }));
  };

  handleChannelOptions = list => {
    this.setState({ selectedSlackChannels: list });
  };

  selectWebhookApiKeyName = (apiKey, name, setFieldValue) => {
    setFieldValue("webhook_api_key_name", apiKey ? apiKey.ApikeyName : "");
    setFieldValue("webhook_api_key_value", apiKey ? apiKey.Value : "");
  };

  buildJSON = formData => {
    let parsedFormData = recursiveDataParser(formData);
    return {
      credentials: parsedFormData,
    };
  };

  /**
   * This method to set common status of multiple places.
   * this is to avoid code duplication
   * @param {boolean} status current status [true | false]
   * @param {any} actions formik actions object
   * @param {string} msg message to be shown of form
   * @param {any} error
   */
  networkCallStatus = (status, actions, msg = "", error = undefined) => {
    actions.setSubmitting(status);
    this.props.isFetching(status, msg);

    if (error) {
      actions.setStatus({
        isError: true,
        formSubmitMessage: error,
      });
    }
  };

  /**
   * This method validates Twilio connector's credentials before updating
   * @param {Any} formInput Input args from formik
   * @param {Any} actions Fromik actions object
   */
  validateTwilioCredentials = async (formInput, actions) => {
    try {
      this.networkCallStatus(true, actions, "Validating Twilio Credentials");

      const response = await Api.validateTwilioToken(formInput);

      if (response?.ERROR?.response.status === 401) {
        const err = "Account SID or Auth Token is invalid";

        this.networkCallStatus(false, actions, "", err);
        return false;
      }
    } catch (error) {
      console.log(error);
      this.networkCallStatus(false, actions);
    }

    return true;
  };

  handleSubmit = async (values, actions) => {
    if (
      this.state.slackNotificationFlag &&
      this.state.slackChannels.length &&
      this.state.selectedSlackChannels.length === 0
    ) {
      actions.setFieldError("slackChannel", "Select Slack Channel");
    } else {
      actions.setSubmitting(true);
      let formData = values;
      if (this.props.currentNode.name.toLowerCase() === "slack") {
        delete formData.notification_status;
        if (this.state.slackNotificationFlag) {
          if (!this.state.slackChannels.length) {
            this.toggleSlackNotification();
          } else {
            formData[
              "notification_channels"
            ] = this.state.selectedSlackChannels;
          }
        }
      }
      if (this.props.currentNode.name.toLowerCase() === "instana") {
        delete formData.webhook_status;
        formData["webhook_status"] =
          this.state.instanaWebhookStatus === true ? "ON" : "OFF";
      }
      if (this.props.currentNode.name.toLowerCase() === "jira") {
        const url = formData["JIRA_URL"];
        formData["JIRA_URL"] += `${url[url.length - 1] === "/" ? "" : "/"}`;
      }

      if (this.props.currentNode.name.toLowerCase() === "twilio") {
        const isValid = await this.validateTwilioCredentials(values, actions);
        if (!isValid) return;
      }

      const finalFormData = this.buildJSON(formData);
      if (!!this.props.currentNodeSchema) {
        await this.props.editConnecterSchema(
          finalFormData,
          this.props.currentNode.name.toLowerCase(),
        );
      } else {
        await this.props.saveConnecterSchema(
          finalFormData,
          this.props.currentNode.name.toLowerCase(),
        );
      }
      actions.setSubmitting(false);
      if (this.props.formSubmittedSuccess) {
        actions.setStatus({
          formSubmitMessage: "Thank you! Your submission has been received!",
        });
        this.props.toggleConfigPanel(false);
      } else {
        actions.setStatus({ formSubmitMessage: this.props.errorMessage });
      }
    }
  };

  render() {
    return (
      <>
        {this.props.message && <Wait text={this.props.message} />}
        <div className="side-panel__container">
          {this.state.showForm && (
            <Formik
              initialValues={this.state.initialValues}
              validationSchema={this.state.validationSchema}
              onSubmit={this.handleSubmit}
            >
              {formik => (
                <>
                  <form>
                    {["instana", "datadog"].includes(
                      this.props.currentNode.name.toLowerCase(),
                    ) && (
                      <InstanaWebhookKey
                        formik={formik}
                        getSubtext={this.getSubtext}
                        apiKeyList={this.props.apiKeyList}
                        selectWebhookApiKeyName={this.selectWebhookApiKeyName}
                      />
                    )}
                    {this.getFormFields(formik)}
                    {this.props.currentNode.name.toLowerCase() === "slack" && (
                      <SlackNotification
                        slackNotificationFlag={this.state.slackNotificationFlag}
                        slackChannels={this.state.slackChannels}
                        selectedSlackChannels={this.state.selectedSlackChannels}
                        toggleSlackNotification={this.toggleSlackNotification}
                        handleChannelOptions={this.handleChannelOptions}
                      />
                    )}
                    {this.props.currentNode.name.toLowerCase() ===
                      "instana" && (
                      <InstanaWebhookStatus
                        instanaWebhookStatus={this.state.instanaWebhookStatus}
                        toggleInstanaWebhookStatus={
                          this.toggleInstanaWebhookStatus
                        }
                      />
                    )}
                  </form>
                  <div
                    className={`${
                      formik.status?.isError ? "input-feedback" : ""
                    } pt-15`}
                  >
                    {!!formik.status && formik.status.formSubmitMessage}
                  </div>
                  {this.getFooter(formik)}
                </>
              )}
            </Formik>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  currentNode: state.settingsPanelReducer.currentNode,
  currentNodeSchema: state.settingsPanelReducer.currentNodeSchema,
  isLoading: state.settingsPanelReducer.formSaving,
  formSubmittedSuccess: state.settingsPanelReducer.formSubmittedSuccess,
  formSubmittedError: state.settingsPanelReducer.formSubmittedError,
  currentNodeConfigInfo: state.settingsPanelReducer.currentNodeConfigInfo,
  connectors: state.settingsPanelReducer.connectors,
  errorMessage: state.settingsPanelReducer.errorMessage,
  message: state.runbooksReducer.message,
  apiKeyList: state.APIKeysReducer.apiKeyList,
});
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      toggleConfigPanel,
      saveConnecterSchema,
      savingConnecterSchema,
      editConnecterSchema,
      isFetching,
      updateStatus,
      fetchAPIKeys,
    },
    dispatch,
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(ConfigPanelContent);
