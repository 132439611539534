import React from "react";
import { TextInput } from "@components/ui";
import { executionStatusMap } from "@lib/utils/constants";
import { SelectMultiple, SelectMultipleOption } from "@components/ui";

let executionStatuses = Object.values(executionStatusMap);

export default props => (
  <div className="grid-3-columns">
    <div>
      <TextInput
        placeholder="Search by Workflow Name"
        name="executions-search"
        className="text-input text-input__darker"
        onChange={props.updateSearchTerm}
        value={props.searchTerm}
      />
    </div>
    <div></div>
    <div className="">
      <SelectMultiple
        title="Filter by Status"
        dropDownStyle="pushdown"
        maxHeight={"250"}
        onChange={props.updateFilterByStatuses}
        showReset={false}
        optionHeight={34}
        titleHeight={40}
        values={props.selectedStatuses}
      >
        {executionStatuses.map(status => {
          const isChecked = !!props.selectedStatuses.find(
            selectedStatus => selectedStatus === status,
          );
          return (
            <SelectMultipleOption
              value={status}
              text={status}
              checked={isChecked}
              key={status}
            />
          );
        })}
      </SelectMultiple>
    </div>
  </div>
);
