import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useRef,
} from "react";
import { Accordion, AccordionLabel, AccordionSection } from "@components/ui";
import { RunbookEditorContext } from "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.context";
import { RunbookStepInputSource } from "@containers/RunbookEditor/runbook-editor-lib/ssm/nodeinputoutput";
import { isEmpty } from "@lib/utils";
import ReactSelect from "@components/ui/React-Select/ReactSelect";
import Api from "@lib/api/api";
import "./RequiredInputs.scss";

type RequiredInputsProps = {};

const RequiredInputs: React.FC<RequiredInputsProps> = () => {
  const isFirstRun = useRef(true);
  const context = useContext(RunbookEditorContext) as any;
  const runbookNode = context.activeNode.extras.runbookNode;
  const notifyRunbookUpdate = context.notifyRunbookUpdate;
  const aliasList = context.resourcesList.targets;

  const [isLoadingS3BucketList, setIsLoadingS3BucketList] = useState(false);
  const [s3BucketList, setS3BucketList] = useState([]);

  const [selectedAlias, setSelectedAlias] = useState("");
  const [selectedS3Bucket, setSelectedS3Bucket] = useState("");

  const getParamInput = useCallback(
    (input: string) => runbookNode.parameterInputs.find(p => p.name === input),
    [runbookNode.parameterInputs],
  );

  const getS3BucketList = useCallback(async (alias: string) => {
    setIsLoadingS3BucketList(true);
    try {
      const res = await Api.fetchSelectedS3BucketList(alias);
      let s3BucketList =
        typeof res.body === "string" ? JSON.parse(res.body) : res.body;
      setIsLoadingS3BucketList(false);
      setS3BucketList(s3BucketList);
    } catch (error) {
      setIsLoadingS3BucketList(false);
      console.log(error);
    }
  }, []);

  const setInitialParamValues = useCallback(() => {
    const alias = getParamInput("alias").source?.sourceValue;
    if (alias && alias.constructor.name === "String") {
      setSelectedAlias(alias);
    }

    const s3Bucket = getParamInput("bucket").source?.sourceValue;
    if (s3Bucket && s3Bucket.constructor.name === "String") {
      setSelectedS3Bucket(s3Bucket);
    }
  }, [getParamInput]);

  useEffect(() => {
    if (runbookNode) {
      runbookNode.showHideWarning(!selectedAlias || !selectedS3Bucket);
    }
  }, [runbookNode, getParamInput, selectedAlias, selectedS3Bucket]);

  useEffect(() => {
    if (isFirstRun.current) {
      setInitialParamValues();
      isFirstRun.current = false;
    }
  }, [setInitialParamValues]);

  useEffect(() => {
    !!selectedAlias && getS3BucketList(selectedAlias);
  }, [selectedAlias, getS3BucketList]);

  const setPayload = (input, value) => {
    switch (input) {
      case "alias":
        let alias = getParamInput("alias");
        alias.source = new RunbookStepInputSource(`constant`, value);
        break;
      case "bucket":
        let s3Bucket = getParamInput("bucket");
        s3Bucket.source = new RunbookStepInputSource(`constant`, value);
        break;
    }
    notifyRunbookUpdate(true);
  };

  return (
    <Accordion isExpanded={true} useArrow={true}>
      <AccordionLabel className="editor-accordion-label margin-top-10">
        Required Inputs
      </AccordionLabel>
      <AccordionSection>
        <div className="s3">
          <div className="s3-input">
            <label className="s3-input-label">Select Alias</label>
            <ReactSelect
              id="s3-bucket-alias"
              name="s3-bucket-alias"
              value={{
                value: selectedAlias,
                label: selectedAlias ? selectedAlias : "Select from below",
              }}
              handleChange={data => {
                if (!isEmpty(data) && !!data.value) {
                  setSelectedAlias(data.value);
                  setSelectedS3Bucket("");
                  setPayload("alias", data.value);
                  setPayload("bucket", "");
                }
              }}
              selectOptions={aliasList.map(r => {
                return {
                  value: r.alias,
                  label: r.alias,
                };
              })}
              required
            />
          </div>
          {!!selectedAlias && (
            <div className="s3-input">
              {isLoadingS3BucketList ? (
                <label className="s3-input-label loading-text">
                  <i>Loading S3 bucket List...</i>
                </label>
              ) : (
                <>
                  <label className="s3-input-label">Select S3 Bucket</label>
                  <ReactSelect
                    id="s3-bucket"
                    name="s3-bucket"
                    value={{
                      value: selectedS3Bucket,
                      label: selectedS3Bucket
                        ? selectedS3Bucket
                        : "Select from below",
                    }}
                    handleChange={data => {
                      if (!isEmpty(data) && !!data.value) {
                        setSelectedS3Bucket(data.value);
                        setPayload("bucket", data.value);
                      }
                    }}
                    selectOptions={s3BucketList.map(r => {
                      return {
                        value: r.Name,
                        label: r.Name,
                      };
                    })}
                    required
                  />
                </>
              )}
            </div>
          )}
        </div>
      </AccordionSection>
    </Accordion>
  );
};

export default RequiredInputs;
