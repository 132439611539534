import React, { useState } from "react";
import { BackArrow } from "@components/ui/icons";
import { Consumer } from "../../../runbook-editor-lib/runbook-editor.context";

const Logo = () => {
  const [component, setComponent] = useState("NeuropsLogo");

  return (
    <Consumer>
      {({ goBack }) => (
        <div className="editor-menu-item-container" style={{ height: "40px" }}>
          <div
            onMouseOver={() => setComponent("BackArrow")}
            onMouseOut={() => setComponent("NeuropsLogo")}
            onClick={() => goBack("/workflows")}
            style={{
              position: "absolute",
              zIndex: "20",
              background: "none",
              top: "0",
              left: "0",
              width: "40px",
              height: "40px",
            }}
          ></div>
          <div style={{ position: "relative", zIndex: "10" }}>
            {component === "NeuropsLogo" ? (
              <div className="logo-wrap-editor editor-menu-fylamynt-logo"></div>
            ) : (
              <BackArrow name="backarrow" className="editor-menu-logo" />
            )}
          </div>
        </div>
      )}
    </Consumer>
  );
};

export default Logo;
