import React from "react";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { Modal, Button, Wait, FTNotification } from "@components/ui";
import { isFetching } from "@redux/actions/settingsPanel.action";
import FormInput from "../settings-panel-components/SettingsRightConfigPanel/FormInput";
import { getValidationSchema } from "./utils/ValidationSchema";
import { capitalizeFirstLetter } from "@lib/utils";

import Api from "@lib/api";
import {
  addSSHTarget,
  updateSSHTarget,
} from "@redux/actions/sshTargets.action";
import { TypeSSHTarget } from "./index";

type ManageSSHTargetProps = {
  selectedTarget?: any;
  closeForm: (flag: boolean) => void;
  mode: string;
  sshTargets: Array<TypeSSHTarget>;
};

const TEXT_AREA_STYLE = {
  width: "100%",
  height: "6.5rem",
  paddingTop: "0.5rem",
};

const INLINE_BUTTON_STYLE = { width: "50%" };

const ManageSSHTargets: React.FC<ManageSSHTargetProps> = ({
  selectedTarget,
  mode,
  closeForm,
  sshTargets,
}) => {
  const dispatch = useDispatch();
  const loadingMessage = useSelector(state => state.runbooksReducer.message);
  const initialValues = selectedTarget;
  const validationSchema = getValidationSchema(mode, sshTargets);

  const getFooter = (handleSubmit: any) => {
    return (
      <div className="modal-buttons-footer">
        <Button
          text="Cancel"
          buttonStyle="secondary"
          style={INLINE_BUTTON_STYLE}
          size="large"
          type="button"
          onClick={() => closeForm(false)}
        />
        <Button
          text={`${mode === "add" ? "Add" : "Update"} SSH Target`}
          style={INLINE_BUTTON_STYLE}
          buttonStyle="primary"
          size="large"
          onClick={handleSubmit}
        />
      </div>
    );
  };

  const onSubmitHandler = async (formik: any, actions: any) => {
    actions.setSubmitting(true);
    dispatch(
      isFetching(true, `${mode === "add" ? "Saving" : "Updating"} SSH Target`),
    );
    const payload = {
      targetName: formik.targetName,
      privateKey: formik.privateKey,
      hostIdentifier: formik.hostIdentifier,
    };
    try {
      if (mode === "add") {
        await Api.addSSHTarget(payload);
        dispatch(addSSHTarget(payload));
        FTNotification.success({
          title: "SSH Target added successfully!",
        });
      } else {
        await Api.updateSSHTarget(payload, payload.targetName);
        dispatch(updateSSHTarget(payload));
        FTNotification.success({
          title: "SSH Target updated successfully!",
        });
      }
      closeForm(false);
    } catch (error) {
      if (mode === "add") {
        FTNotification.error({
          title: "Could not add ssh target",
          message: error.message,
        });
      } else {
        FTNotification.error({
          title: "Could not update ssh target",
          message: error.message,
        });
      }
    }
    dispatch(isFetching(false, ""));
    actions.setSubmitting(false);
  };

  return (
    <>
      {loadingMessage && <Wait text={loadingMessage} />}
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmitHandler}
        validationSchema={validationSchema}
      >
        {formik => {
          const { touched, errors, handleChange, handleSubmit } = formik;
          return (
            <Modal
              onClose={() => {
                closeForm(false);
              }}
              title={`${capitalizeFirstLetter(mode)} SSH Target`}
              footer={getFooter(handleSubmit)}
              showClose={true}
            >
              <div className="form-item__mb-l">
                <FormInput
                  fieldName="Target Name"
                  name={"targetName"}
                  id="targetName"
                  handleChange={handleChange}
                  touched={touched}
                  errors={errors}
                  readOnly={mode === "edit"}
                />
              </div>
              <div className="form-item form-item__margin-bottom form-item__mb-l">
                <label
                  htmlFor="privateKey"
                  className="label label__padding-left"
                >
                  Private Key:
                </label>
                <div className="field-wrapper">
                  <textarea
                    className={`text-input w-input ${
                      formik.errors.privateKey &&
                      formik.touched.privateKey &&
                      "input-error"
                    }`}
                    id="Private RSA Key"
                    name="privateKey"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.privateKey}
                    cols={50}
                    rows={4}
                    style={TEXT_AREA_STYLE}
                  />
                </div>
                {formik.errors.privateKey && formik.touched.privateKey ? (
                  <div className="input-feedback">
                    {formik.errors.privateKey}
                  </div>
                ) : null}
              </div>

              <FormInput
                fieldName="Host Identifier"
                name={"hostIdentifier"}
                id="hostIdentifier"
                handleChange={handleChange}
                touched={touched}
                errors={errors}
                readOnly={false}
              />
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};

export default ManageSSHTargets;
