import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getAppConfig } from "@redux/actions/app.actions";

class AppConfig extends React.Component<any, any> {
  componentDidMount() {
    this.props.getAppConfig();
  }

  render() {
    return this.props.appConfig && Object.values(this.props.appConfig).length
      ? this.props.children
      : null;
  }
}

const mapStateToProps = state => {
  return { appConfig: state.appReducer.appConfig };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ getAppConfig }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AppConfig);
