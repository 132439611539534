import React, { useState, useRef } from "react";
import { Field } from "formik";
import JSONPretty from "react-json-pretty";
import useOutsideClickDetector from "@hooks/useOutsideClickDetector";
import IconEye from "@components/ui/icons/icon-eye";
import IconEyeCancelled from "@components/ui/icons/icon-eye-cancelled";

const FormInputTextArea = props => {
  const [jsonViewVisible, setJsonViewVisibility] = useState(false);
  const [inputType, setInputType] = useState("password");
  let targetRef = useRef(null);
  useOutsideClickDetector(targetRef, () => setJsonViewVisibility(false));

  return (
    <div className="form-item form-item__margin-bottom">
      {props.showJson ? (
        <div className="form-field-label">
          <label
            htmlFor={props.fieldName}
            className="label label__padding-left"
          >
            {props.fieldName}
            {props.subText && <br />}
            {props.subText}
          </label>
          {props.fieldValue?.constructor.name === "Object" ? (
            <div className={"json-detail"}>
              <div
                style={{
                  position: "relative",
                  top: 0,
                  right: 0,
                }}
                className="editor-toolbar-json-content-button"
                ref={targetRef}
                onClick={e => setJsonViewVisibility(!jsonViewVisible)}
              >
                {jsonViewVisible && (
                  <div
                    onClick={event => event.stopPropagation()}
                    style={{
                      position: "absolute",
                      backgroundColor: "#3d3d3d",
                      padding: "0px 10px",
                      top: "23px",
                      right: "0%",
                    }}
                  >
                    <JSONPretty
                      id="json-pretty"
                      data={props.fieldValue}
                    ></JSONPretty>
                  </div>
                )}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        <label htmlFor={props.fieldName} className="label label__padding-left">
          {props.fieldName}
          {props.subText && <br />}
          {props.subText}
        </label>
      )}
      <div className="field-wrapper">
        {props.isMasked && inputType === "password" ? (
          <Field
            type={inputType}
            name={props.name}
            style={{ width: "92%", paddingRight: "3rem" }}
            placeholder={!!props.placeholder ? props.placeholder : ``}
            className={
              props.errors[props.name] && props.touched[props.name]
                ? `text-input w-input error`
                : `text-input w-input`
            }
            readOnly={props.readOnly}
            autoComplete={props.autoComplete}
          />
        ) : (
          <Field
            as={"textarea"}
            name={props.name}
            style={{ width: "92%", paddingRight: "3rem" }}
            placeholder={!!props.placeholder ? props.placeholder : ``}
            className={
              props.errors[props.name] && props.touched[props.name]
                ? `text-area text-input w-input error`
                : `text-area text-input w-input`
            }
            readOnly={props.readOnly}
            autoComplete={props.autoComplete}
          />
        )}
        {props.isMasked &&
          (inputType === "password" ? (
            <IconEyeCancelled
              className="input-eye-icon"
              handleClick={() => setInputType("text")}
            />
          ) : (
            <IconEye
              className="input-eye-icon"
              handleClick={() => setInputType("password")}
            />
          ))}
      </div>
      {props.errors[props.name] && props.touched[props.name] && (
        <div className="input-feedback">{props.errors[props.name]}</div>
      )}
    </div>
  );
};

export default FormInputTextArea;
