import React from "react";
import { Redirect } from "react-router";
import {
  RunbookDetailsHeader,
  RunbookDetailsBlock,
  RunbookDetailsVersions,
  RunbookDetailsTabs,
} from "./runbook-details-components";
import { RunbooksDuplicate } from "./../Runbooks/runbooks-components";
import { Provider } from "./runbook-details-lib/runbook-details.context";
import {
  findUnconfiguredNodes,
  getUrlParameter,
  isEmpty,
  isRunbookListEmpty,
} from "@lib/utils";
import { Wait, Confirm, FTNotification } from "@components/ui";
import NoAccountWarning from "@components/ui/WarningSign/NoAccountWarning";
import LimitDialog from "@containers/WarningDialogs/LimitDialog";
import { RunbookRun, RunbookScheduleModal } from "@components/modules";
import { limitExceeded } from "../../utils/common";
import { RouteConstants } from "../../routes/Constants";
import "./runbook-details.scss";

const { runbookOwners } = require("@config/constants.json");
export default class RunbookDetails extends React.Component {
  state = {
    showRunModal: false,
    toggleScheduleModal: false,
    doRedirect: false,
    redirectUrl: "",
    runbookId: getUrlParameter("last"),
    waitMessge: "",
    showWait: false,
    currentTabOwner: runbookOwners.self,
    showRunbooksDuplicate: false,
    isWorkflowLimitDialogVisible: false,
    isRunsLimitDialogVisible: false,
    unconfiguredNodes: [],
    runModalAsSave: false,
    tryingToSchedule: false,
  };

  componentDidMount() {
    if (isRunbookListEmpty(this.props.runbookList)) {
      this.props.fetchRunbooks(runbookOwners.self);
    }
    const args = new URLSearchParams(this.props.location?.search);
    let argument;
    let version = null;
    for (argument of args.entries()) {
      if (argument[0] === "v") version = argument[1];
    }
    this.props.fetchActiveRunbook(this.state.runbookId, version);
    this.gotoExecutionDetails = false;
    this.props.unsetDuplicateRunbook();
    if (isEmpty(this.props.connectors)) {
      this.props.fetchSettingsConnectors();
    }
  }

  componentWillUnmount() {
    //this.props.setActiveVersion();
  }

  setCurrentTabOwner = runbook => {
    if (this.isReleasedRunbook(runbook)) {
      this.setState({ currentTabOwner: runbookOwners.fylamynt });
    }
  };

  isReleasedRunbook = runbook => {
    if (runbook.hasOwnProperty("Tags")) {
      let releaseTags =
        runbook?.Tags &&
        runbook.Tags?.filter(tag => tag?.startsWith("release:"));
      return releaseTags && releaseTags.length > 0;
    } else {
      return false;
    }
  };

  /* if we were in a new runbook and clicked the 'back' button, we want to clear out that state and go to the runbooks list */
  forwardToRunbooks() {}

  toggleRunbookModal = async () => {
    if (this.state.showRunModal === false) {
      this.props.getResourcesList();
      try {
        await this.props.fetchAccountPlanInfo();
        if (
          limitExceeded(
            this.props.quotas?.executions?.current_usage,
            this.props.quotas?.executions?.limit,
          )
        ) {
          this.toggleWorkflowLimitDialog();
        } else {
          this.setState({
            showRunModal: !this.state.showRunModal,
          });
        }
      } catch (e) {
        FTNotification.error({
          title: "Could not fetch Quotas",
        });
      }
    } else {
      this.setState({
        showRunModal: !this.state.showRunModal,
      });
    }
  };

  toggleScheduleModal = () => {
    if (this.state.toggleScheduleModal === true) {
      this.setState(prevState => ({
        toggleScheduleModal: !prevState.toggleScheduleModal,
        showRunModal: false,
        runModalAsSave: false,
        tryingToSchedule: false,
      }));
    } else {
      this.setState(prevState => ({
        toggleScheduleModal: !prevState.toggleScheduleModal,
      }));
    }
  };

  openEditor = () => {
    this.setState({
      redirectUrl: `${RouteConstants.runbookEditor.url}/${this.state.runbookId}`,
      doRedirect: true,
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.isRunbookDeleted) {
      window.location.href = "/workflows";
    }

    if (this.props.executionId?.length && this.gotoExecutionDetails) {
      this.setState({
        doRedirect: true,
        redirectUrl: `${RouteConstants.executionDetails.url}/${this.props.executionId}`,
      });
    } else {
      this.formatWaitMessage(prevProps);
    }
    // Get all unconfigured nodes.
    if (prevProps.activeRunbook !== this.props.activeRunbook) {
      this.setState({
        unconfiguredNodes: findUnconfiguredNodes(
          this.props.connectors,
          this.props.activeRunbook.Content,
        ),
      });
    }
  }

  formatWaitMessage = prevProps => {
    let update = false;
    let showWait, waitMessage, callBack;
    let shouldUpdateFetchingMessage = Boolean(
      this.props.isFetching && !this.state.showWait,
    );
    let shouldNotShowWait = Boolean(
      !this.props.isFetching &&
        this.state.showWait &&
        !this.props.runbookFetchError &&
        !this.props.showLoading,
    );
    let shouldShowFetchingError = Boolean(
      this.props.runbookFetchError &&
        this.props.runbookFetchError !== prevProps.runbookFetchError,
    );
    if (shouldUpdateFetchingMessage) {
      showWait = true;
      waitMessage = "Fetching workflow details...";
      update = true;
    } else if (shouldNotShowWait) {
      this.setCurrentTabOwner(this.props.activeRunbook);
      showWait = false;
      waitMessage = "";
      update = true;
    } else if (shouldShowFetchingError) {
      showWait = true;
      waitMessage =
        "Error fetching workflow details (will return to Workflows list)";
      update = true;
      callBack = () => {
        setTimeout(() => {
          window.location.href = "/workflows";
        }, 2000);
      };
    } else if (
      prevProps.showLoading !== this.props.showLoading &&
      this.props.message !== ""
    ) {
      showWait = true;
      waitMessage = this.props.message;
      update = true;
    }
    if (update) {
      this.setState(
        {
          showWait,
          waitMessage,
        },
        () => {
          if (callBack) {
            callBack();
          }
        },
      );
    }
  };

  runExecution = (runbook, parameters, version) => {
    this.props.doExecution(runbook, parameters, version);
    this.gotoExecutionDetails = true;
  };

  renderConfirmDelete = runbookName => {
    this.setState({
      confirmHeading: "Delete Confirmation",
      confirmMessage:
        "This Workflow and its Versions be deleted permanently. If it is currently in use, existing executions will be deleted.",
      confirmCancelText: "Cancel",
      confirmConfirmText: Object.keys(this.props.runbookSchedule).length
        ? "Delete (with all schedules)"
        : "Delete",
      confirmValue: runbookName,
      confirmRequiredText: `Confirm by typing '${runbookName}' below`,
    });
    this.props.setShowConfirm(true);
  };

  deleteRunbook = runbook => {
    this.props.setShowConfirm(false);
    this.props.deleteRunbook(runbook, this.state.currentTabOwner);
  };

  toggleDuplicateRunbookModal = () => {
    this.setState({ showRunbooksDuplicate: !this.state.showRunbooksDuplicate });
  };

  openDuplicateRunbookModal = async runbook => {
    try {
      await this.props.fetchAccountPlanInfo();
      if (
        limitExceeded(
          this.props.quotas?.workflows?.current_usage,
          this.props.quotas?.workflows?.limit,
        )
      ) {
        this.toggleWorkflowLimitDialog();
      } else {
        this.setState({
          showRunbooksDuplicate: true,
          baseRunbookName: runbook.Name,
        });
      }
    } catch (e) {
      FTNotification.error({
        title: "Could not fetch Quotas",
      });
    }
  };

  goToProfilePage = () => {
    this.setState({
      doRedirect: true,
      redirectUrl: {
        pathname: RouteConstants.userProfile.url,
        state: { activeTab: "account-plan" },
      },
    });
  };

  toggleWorkflowLimitDialog = () => {
    this.setState({
      isWorkflowLimitDialogVisible: !this.state.isWorkflowLimitDialogVisible,
    });
  };

  toggleRunsLimitDialog = () => {
    this.setState({
      isRunsLimitDialogVisible: !this.state.isRunsLimitDialogVisible,
    });
  };

  render() {
    const contextValue = {
      runbook: this.props.activeRunbook,
      setActiveVersion: this.props.setActiveVersion,
      isFetchingVersion: this.props.isFetchingVersion,
      activeRunbookVersion: this.props.activeRunbookVersion,
      toggleRunbookModal: this.toggleRunbookModal,
      runExecution: this.runExecution,
      openEditor: this.openEditor,
      snippets: this.props.snippets,
      setShowConfirm: this.renderConfirmDelete,
      onDeleteRunbook: this.onDeleteRunbook,
      updateDefaultVersion: this.props.updateDefaultVersion,
      isVersionUpdateSuccess: this.props.isVersionUpdateSuccess,
      isVersionUpdateFailed: this.props.isVersionUpdateFailed,
      isDeleting: this.props.isDeleting,
      deleteRunbookVersion: this.props.deleteRunbookVersion,
      runbookVersionDeleteStatus: this.props.runbookVersionDeleteStatus,
      openDuplicateRunbookModal: this.openDuplicateRunbookModal,
      runbookSchedule: this.props.runbookSchedule,
      unconfiguredNodes: this.state.unconfiguredNodes,
      toggleScheduleModal: this.toggleScheduleModal,
    };
    return (
      <Provider value={contextValue}>
        {this.state.showWait && <Wait text={this.state.waitMessage} />}

        <div className="d-flex flex-column2">
          {this.props.showConfirm && (
            <Confirm
              heading={this.state.confirmHeading}
              cancelText={this.state.cancelText}
              confirmText={this.state.confirmConfirmText}
              message={this.state.confirmMessage}
              confirmValue={this.state.confirmValue}
              confirmRequiredText={this.state.confirmRequiredText}
              verifiedCallback={val => this.deleteRunbook(val)}
            />
          )}
          <RunbookDetailsHeader />
          {((this.props.isAccountDetailFetched &&
            !this.props.userDefaultAccounts?.length) ||
            this.state.unconfiguredNodes.length > 0) && (
            <NoAccountWarning
              fromComponent="RunbookDetails"
              isAWSAccountConfigured={
                this.props.userDefaultAccounts?.length > 0
              }
              unconfiguredNodes={this.state.unconfiguredNodes}
            />
          )}
          <RunbookDetailsBlock />
        </div>
        <div className="mt-50">
          <RunbookDetailsVersions
            quotas={this.props.quotas}
            fetchAccountPlanInfo={this.props.fetchAccountPlanInfo}
            toggleRunsLimitDialog={this.toggleRunsLimitDialog}
          />
          <RunbookDetailsTabs />
        </div>
        {this.state.showRunModal && (
          <RunbookRun
            toggleRunbookModal={this.toggleRunbookModal}
            runExecution={this.runExecution}
            runModalAsSave={this.state.runModalAsSave}
          />
        )}
        {this.state.toggleScheduleModal && (
          <RunbookScheduleModal
            toggleScheduleModal={this.toggleScheduleModal}
            currentSchedule={this.props.runbookSchedule}
          />
        )}
        {this.state.showRunbooksDuplicate && (
          <RunbooksDuplicate
            toggleDuplicateRunbookModal={this.toggleDuplicateRunbookModal}
            baseRunbookName={this.state.baseRunbookName}
          />
        )}
        {this.state.isRunsLimitDialogVisible && (
          <LimitDialog
            limitType={"runs"}
            goTo={this.goToProfilePage}
            toggleDialog={this.toggleRunsLimitDialog}
          />
        )}
        {this.state.isWorkflowLimitDialogVisible && (
          <LimitDialog
            limitType={"workflow"}
            goTo={this.goToProfilePage}
            toggleDialog={this.toggleWorkflowLimitDialog}
          />
        )}
        {this.state.doRedirect && <Redirect to={this.state.redirectUrl} push />}

        {this.props.runbookDuplicate &&
          (window.location.href = `/workflows/${this.props.runbookDuplicate}`)}
      </Provider>
    );
  }
}
