import produce from "immer";
import {
  FETCH_RUNBOOK_LIST_SUCCESS,
  FETCH_RUNBOOK_LIST_ERROR,
  FETCH_RUNBOOK_DETAILS_SUCCESS,
  FETCH_RUNBOOK_DETAILS_ERROR,
  FETCHING_RUNBOOKS,
  ADD_RUNBOOK_TO_LIST,
  REMOVE_RUNBOOK_FROM_LIST,
  SET_FILTERED_RUNBOOKS,
} from "@redux/types";
import { deleteDuplicate, searchRunbooksByName } from "@lib/utils";
import { runbookOwners } from "@config/constants";

const INITIAL_STATE = {
  totalSelfRunbooks: [],
  runbookList: {
    [runbookOwners.self]: [],
    [runbookOwners.fylamynt]: [],
  },
  runbookListFiltered: {
    [runbookOwners.self]: [],
    [runbookOwners.fylamynt]: [],
  },
  runbookListDetails: [],
  runbookSearchTerm: "",
  showLoading: false,
  message: "",
  runbookFetchError: false,
  isRunbookDeleted: false,
};

export default (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    switch (action.type) {
      case FETCHING_RUNBOOKS:
        draft.message = action.message;
        draft.showLoading = action.payload;
        break;

      case FETCH_RUNBOOK_LIST_SUCCESS:
        if (action.owner === runbookOwners.self) {
          draft.totalSelfRunbooks = action.payload;
        }
        draft.runbookList[runbookOwners.self] = draft.totalSelfRunbooks.filter(
          val => {
            let tagsVal =
              val.Tags &&
              val.Tags.filter(tagVal => tagVal.startsWith("release:"));
            return (
              !val.hasOwnProperty("Tags") || (tagsVal && tagsVal.length === 0)
            );
          },
        );

        draft.runbookList[
          runbookOwners.fylamynt
        ] = draft.totalSelfRunbooks.filter(val => {
          if (val.hasOwnProperty("Tags")) {
            let tagsVal =
              val.Tags &&
              val.Tags.filter(tagVal => tagVal.startsWith("release:"));
            return tagsVal && tagsVal.length > 0;
          } else {
            return false;
          }
        });
        draft.runbookListDetails = [];
        break;

      case ADD_RUNBOOK_TO_LIST:
        draft.runbookList[action.owner].push(action.payload);
        break;

      case REMOVE_RUNBOOK_FROM_LIST:
        const runbookIndex = searchRunbooksByName(
          state.runbookList[action.owner],
          action.payload,
        );
        const filteredRunbookIndex = searchRunbooksByName(
          state.runbookListFiltered[action.owner],
          action.payload,
        );

        draft.runbookList[action.owner].splice(runbookIndex, 1);
        filteredRunbookIndex !== -1 &&
          draft.runbookListFiltered[action.owner].splice(
            filteredRunbookIndex,
            1,
          );
        draft.isRunbookDeleted = true;
        break;

      case FETCH_RUNBOOK_LIST_ERROR:
        console.log("Error retrieving workflows ", action.payload);
        break;

      case FETCH_RUNBOOK_DETAILS_SUCCESS:
        draft.runbookListDetails = deleteDuplicate(
          "name",
          action.payload.name,
          draft.runbookListDetails,
        );
        draft.runbookListDetails.push(action.payload);
        break;

      case FETCH_RUNBOOK_DETAILS_ERROR:
        draft.runbookFetchError = action.payload ? true : false;
        break;

      case SET_FILTERED_RUNBOOKS:
        draft.runbookListFiltered[action.activeOwner] =
          action.runbookList[action.activeOwner];
        draft.runbookSearchTerm = action.searchTerm;
        break;

      default:
        break;
    }
  });
