import React from "react";
import { Consumer } from "../execution-details-lib/execution-details.context";

export default class Inputs extends React.Component {
  render() {
    return (
      <Consumer>
        {({ executionDetails }) => (
          <React.Fragment>
            {executionDetails && (
              <div className="mt-20">
                <div className="content-text">
                  <div className="ml-15">
                    This execution uses the following input parameters:
                  </div>
                  <div className="mt-20 mb-20">
                    {/* The Filter logic removes the internal Parameters from Inputs */}
                    {Object.keys(executionDetails.Parameters)
                      .filter(
                        key =>
                          !(
                            key === "AutomationAssumeRole" ||
                            key === "WorkflowSession"
                          ),
                      )
                      .map(key => {
                        const value = executionDetails.Parameters[key];
                        return (
                          <div key={key} className="table-col-2">
                            <div className="table-col-left">{key}</div>
                            <div className="table-col-right">{value}</div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            )}
          </React.Fragment>
        )}
      </Consumer>
    );
  }
}
