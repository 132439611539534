import React, { useState, useEffect, useContext } from "react";
import * as yup from "yup";
import { TextInput } from "@components/ui";
import { RunbookEditorContext } from "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.context";

// Handle input cleared edge case
const Number = ({
  title,
  value,
  onChange,
  isRequired = false,
  isDecimal = false,
}) => {
  // Initiate State and convert it to string.
  const initialState = value ? value.toString() : null;

  const [number, setNumber] = useState(initialState);
  const [errorState, setErrorState] = useState("");

  const context: any = useContext(RunbookEditorContext);
  const runbookNode = context.activeNode.extras.runbookNode;
  const notifyRunbookUpdate = context.notifyRunbookUpdate;

  const numberSchema = yup.object().shape({
    number: isRequired
      ? yup
          .number()
          .integer()
          .required()
          .test(value => value.toString().match(/^[-]?[0-9]+$/))
      : yup
          .number()
          .integer()
          .test(value => value.toString().match(/^[-]?[0-9]+$/)),
  });

  const decimalSchema = yup.object().shape({
    number: isRequired
      ? yup
          .number()
          .required()
          .test(value => value.toString().match(/\d*([.]\d*)?/))
      : yup.number().test(value => value.toString().match(/\d*([.]\d*)?/)),
  });

  useEffect(() => {
    if (number || isRequired) {
      let schema = isDecimal ? decimalSchema : numberSchema;
      schema
        .validate({ number })
        .then(() => {
          setErrorState(null);
        })
        .catch(() => {
          setErrorState(
            isDecimal
              ? "Please enter a valid decimal number"
              : "Please enter a valid integer",
          );
        });
    }
    // Clean up after input is cleared
    if (number === "" && !isRequired) {
      setErrorState(null);
      setNumber(null);
    }
  }, [number, errorState, isDecimal, isRequired, decimalSchema, numberSchema]);

  useEffect(() => {
    if (errorState) {
      runbookNode.errors = { errorMessage: errorState };
      notifyRunbookUpdate(true);
    }
    if (number && errorState === null) {
      runbookNode.errors = null;
      notifyRunbookUpdate(true);
    }
    // Clean up after input is cleared
    if (number === null && errorState === null) {
      runbookNode.errors = null;
      notifyRunbookUpdate(true);
    }
  }, [number, errorState, runbookNode, notifyRunbookUpdate]);

  useEffect(() => {
    if (runbookNode) {
      runbookNode.showHideWarning(errorState);
    }
  }, [runbookNode, number, errorState]);

  return (
    <TextInput
      name={title}
      label={title}
      labelPosition="top"
      labelClassName="label"
      id="number-editor-input"
      className={errorState ? "rule-input rule-input-error" : "rule-input"}
      value={number}
      onChange={value => {
        onChange(value);
        setNumber(value);
      }}
    />
  );
};

export default Number;
