import React from "react";
import getInstructions from "./profile-instructions-helper";
import TerraFormZip from "@assets/archives/fylamynt-aws-integration-terraform.zip";
import FylamyntPythonAWS from "@assets/archives/fylamynt-aws-integration-python.zip";

const LeftPanel = props => {
  const mainHeading = "Create AWS AssumeRole";
  const instructions = getInstructions();
  const linkStyle = {
    width: "100%",
    display: "inline-block",
  };

  return (
    <>
      <div className="instructions__left get-started">
        <div className="instructions__title">
          <h1 className="page-title">{mainHeading}</h1>
        </div>
        <div className="onboarding-panel">
          <h2 className="h2-margin">
            Option 1:&nbsp;Use Terraform template for Creating Assume Role
          </h2>
          <div className="d-inline-flex">
            <div className="btn-download">
              <a
                href={TerraFormZip}
                download
                className="link-white"
                style={linkStyle}
              >
                Terraform Template
              </a>
            </div>
          </div>
        </div>
        <div className="onboarding-panel">
          <h2 className="h2-margin">
            Option 2:&nbsp;Use Python script for Creating Assume Role
          </h2>
          <div className="d-inline-flex">
            <div className="btn-download">
              <a
                href={FylamyntPythonAWS}
                download
                className="link-white"
                style={linkStyle}
              >
                Python Script
              </a>
            </div>
          </div>
        </div>
        <div className="onboarding-panel">
          <h2 className="h2-margin">
            Option 3: Manually Create AWS AssumeRole
          </h2>
          {instructions}
        </div>
      </div>
    </>
  );
};

export default LeftPanel;
