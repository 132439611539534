import * as editorUtils from "../../runbook-editor-lib/editor-design-area.helper";

import Application from "../../runbook-editor-lib/application";
import { Consumer } from "../../runbook-editor-lib/runbook-editor.context";
import { DiagramWidget } from "storm-react-diagrams";
import { ParameterType } from "@containers/RunbookEditor/runbook-editor-lib/ssm/strings";
import React from "react";
import { StepTypeChecker } from "@containers/RunbookEditor/runbook-editor-lib/neuropssteps/steptypechecker";
import { preventZoomError } from "../../runbook-editor-lib/prevent-zoom-errors";
import { isCurrentNodeConfigured, findUnconfiguredNodes } from "@lib/utils";

class EditorDesignArea extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { app: null };
    this.nodeSelected = null;
    this.previousNode = null;
    preventZoomError();
    window.addEventListener("keyup", this.onKeyUp, false);
  }

  componentDidMount() {
    this.setState({
      runbookObj: null,
    });
  }

  componentWillUnmount() {
    window.removeEventListener("keyup", this.onKeyUp, false);
  }

  componentDidUpdate(prevProps) {
    if (this.props.preparing) {
      return;
    } else {
      if (
        (this.props.preparing !== prevProps.preparing &&
          !this.props.preparing) ||
        (this.props.reloadEditor !== prevProps.reloadEditor &&
          this.props.reloadEditor)
      ) {
        this.addDiagram();
      }
      //console.log(`awsDataNowReady`, this.awsDataNowReady(prevProps));
      if (this.awsDataNowReady(prevProps)) {
        // TODO: fix the action node input types
        this.fixActionNodeInputTypes(this.props.runbookObj.mainSteps);
        //console.log(`fixed runbook inputs`, { runbook: this.props.runbookObj });
      }
      this.checkIfRunbookVersionChanged();
    }
  }

  addDiagram = () => {
    const app = Application;
    app.selectNode = this.selectNode.bind(this);
    editorUtils._resetDiagramAndRun(this);
  };

  preventZoomError = () => {
    let el = this.ref.current;
    el.addEventListener(
      "touchmove",
      function (event) {
        event.preventDefault();
      },
      { passive: false },
    );
  };

  fixActionNodeInputTypes(steps) {
    let step;
    for (step of steps) {
      if (
        StepTypeChecker.isActionNodeStep(step.ssm) ||
        StepTypeChecker.isLoopStep(step.ssm) ||
        StepTypeChecker.isWaitForResourceStep(step.ssm)
      ) {
        const opKey = `${step.service}.${step.operation}`;
        const operationDetails = this.props.awsOperationDetails[opKey];
        // console.log("fixActionNodeInputTypes 1", {
        //   operationDetails,
        //   opdetailstate: this.props.awsOperationDetails,
        //   opKey
        // });
        let input;
        for (input of step.parameterInputs) {
          const inputName = input.name;
          if (!operationDetails) {
            continue;
          }
          const awsType = operationDetails.input[inputName];

          if (typeof awsType === "string") {
            input.setType(awsType);
          } else if (Array.isArray(awsType)) {
            input.setType(ParameterType.StringList);
          } else if (typeof awsType === "object") {
            input.setType(ParameterType.StringMap);
          }
        }
      }
    }
  }
  awsDataNowReady(prevProps) {
    if (this.props.awsOperationDetails !== prevProps.awsOperationDetails) {
      let step;
      for (step of this.state.runbookObj.mainSteps) {
        if (
          StepTypeChecker.isActionNodeStep(step.ssm) ||
          StepTypeChecker.isLoopStep(step.ssm) ||
          StepTypeChecker.isWaitForResourceStep(step.ssm)
        ) {
          const opKey = `${step.service}.${step.operation}`;
          if (!this.props.awsOperationDetails[opKey]) {
            return false;
          }
        }
      }
      return true;
    }
    return false;
  }

  checkIfRunbookVersionChanged() {
    if (this.props.runbookObj && this.state.runbookObj) {
      if (this.props.runbookObj.version !== this.state.runbookObj.version) {
        this.deselectNode();
      }
    }
  }

  runDiagram(runbook) {
    const { app } = this.state;
    if (!runbook) {
      return;
    }
    /**
     * Adding additional field in steps data to get the info if the connector
     * is configured.
     */
    let unconfiguredNodes = findUnconfiguredNodes(
      this.props.connectors,
      runbook,
    );
    for (const item of runbook.mainSteps) {
      item.is_configured = isCurrentNodeConfigured(
        item.name.toLowerCase(),
        unconfiguredNodes,
      );
    }

    // define notifyRunbookUpdate() in NeurOpsRunbook object to be used for links actions
    runbook.notifyRunbookUpdate = this.props.notifyRunbookUpdate;
    runbook.updateRunbookObj = this.props.updateRunbookObj;
    let activeModel = app.drawDiagram(runbook);

    // listen for dropping, removing nodes on canvas
    activeModel.addListener({
      nodesUpdated: e => {
        this.props.notifyRunbookUpdate(true);
      },
    });
  }

  selectNode(node) {
    if (node.name === this.previousNode && node.selected) {
      return;
    }
    if (node.selected) {
      this.previousNode = node.name;
      editorUtils._deselectNode(this);
      setTimeout(() => {
        this.nodeSelected = node;
        editorUtils._selectNode(node, this);
      }, 100);
    } else {
      this.nodeSelected = null;
      this.previousNode = null;
      editorUtils._deselectNode(this);
    }
  }

  deselectNode() {
    this.previousNode = null;
    editorUtils._selectNode(null, this);
  }

  removeBacspaceEvent = target =>
    target?.id.includes("react-select") ||
    target.className.includes("monaco-mouse-cursor") ||
    target.className.includes("filters-add") ||
    target.className.includes("twilio-inputs") ||
    target.className.includes("editor-search-input") ||
    target?.id.includes("input-password") ||
    target?.id.includes("url-editor-input") ||
    target?.id.includes("number-editor-input");

  onKeyUp = event => {
    switch (event.key) {
      case "Delete":
      case "Backspace":
        const e = event || window.event;
        e.cancelBubble = true;
        const target = e.target;
        if (!!target && this.removeBacspaceEvent(target)) {
          e.stopImmediatePropagation();
        } else this.onDelete();
        break;

      default:
        break;
    }
  };

  onDrop = event => {
    event.preventDefault();
    editorUtils._onDrop(event, this);
  };

  onDelete = () => {
    if (this.nodeSelected) {
      const selectedNode = this.nodeSelected;
      editorUtils._onDelete.call(this, selectedNode);
      this.deselectNode();
    }
  };

  rerenderEditor = () => {
    this.forceUpdate();
  };

  getAllNodesInEditor = () => {
    return this.state.app.activeModel.nodes;
  };

  addLinkToStep = (activeNode, step) => {
    let allNodesInEditor = this.getAllNodesInEditor();
    let nodeForStep = Object.values(allNodesInEditor).find(
      node => node.extras.runbookNode.name === step.name,
    );
    let ports = {}; //new DefaultLinkModel();
    ports.sourcePort = Object.values(activeNode.ports).find(port => !port.in);
    ports.targetPort = Object.values(nodeForStep.ports).find(port => port.in);

    const link = ports.sourcePort.link(ports.targetPort);

    this.state.app.activeModel.addLink(link);
  };

  render() {
    const { app } = this.state;
    editorUtils.positionDiagram();
    return (
      <Consumer>
        {() => (
          <div
            className="editor-design-area-container"
            onClick={this.props.onClick}
            onDrop={this.onDrop}
            onDragOver={this.props.onDragOver}
          >
            <div>
              {app && !this.props.preparing && (
                <DiagramWidget
                  smartRouting={true}
                  maxNumberPointsPerLink={0}
                  diagramEngine={app.getDiagramEngine()}
                ></DiagramWidget>
              )}
            </div>
          </div>
        )}
      </Consumer>
    );
  }
}

export default EditorDesignArea;
