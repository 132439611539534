import React from "react";

export default props => {
  return (
    <div className="runbook-item__tag-wrap">
      {props.tags &&
        props.tags.map((tag, idx) => {
          return (
            <React.Fragment key={idx}>
              <div className="tag tag_primary-light">
                <div className="tag__inner-wrap">
                  <div>{tag}</div>
                </div>
              </div>
            </React.Fragment>
          );
        })}
    </div>
  );
};
