import React from "react";
import { Consumer } from "./accordion.context";
import { listenForDivChange } from "./accordion.helpers";

export default class AccordionSection extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  state = {
    height: null,
  };

  componentDidMount() {
    listenForDivChange.call(this);
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  toggle = (showAnimation, isExpanded) => {
    if (!showAnimation) {
      return;
    }
    let style = isExpanded ? {} : { maxHeight: "0", overflow: "hidden" };
    return style;
  };
  render() {
    return (
      <Consumer>
        {({ isExpanded, showAnimation }) => (
          <div
            ref={this.ref}
            className={`accordion-section ${
              isExpanded && !showAnimation
                ? "accordion-expanded"
                : "accordion-contracted"
            }`}
            style={this.toggle(showAnimation, isExpanded)}
          >
            <div style={this.props.style}>{this.props.children}</div>
          </div>
        )}
      </Consumer>
    );
  }
}
