import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DataTable } from "@components/ui/data-table";
import tableColumns from "./tableColumns";
import { SettingsPanelHeader } from "../settings-panel-components";
import { fetchGitRepos } from "@redux/actions/gitRepo.actions";
import { Wait, Confirm, FTNotification, Modal } from "@components/ui";
import ManageGitRepo from "./ManageGitRepo";
import { toCamelCaseKeys } from "@lib/utils/index";
import { toggleDeleteConfirmBox } from "@redux/actions/AWSSettings.actions";
import { removeGitRepo } from "@redux/actions/gitRepo.actions";
import { mapData } from "./utils/helper";
import Api from "@lib/api";
import { isFetching } from "@redux/actions/settingsPanel.action";
import "./gitRepos.scss";

const GitRepos = () => {
  const dispatch = useDispatch();
  const showConfirm = useSelector(state => state.uiReducer.showConfirm);
  const newGitRepoValues = {
    name: "",
    sshKey: "",
    url: "",
    accessToken: "",
  };
  const [selectedRepo, setSelectedRepo] = useState(newGitRepoValues);
  const loadingMessage = useSelector(state => state.runbooksReducer.message);
  const gitRepoData = useSelector(state => state.GITReposReducer.gitRepoList);
  const [showManageGitRepoForm, setShowManageGitRepoForm] = useState(false);
  const [gitRepoMode, setGitRepoMode] = useState("create");
  const [showSSHKey, setShowSSHKey] = useState(false);
  const [showAccessToken, setShowAccessToken] = useState(false);

  const toggleGitRepoFormVisibility = mode => {
    setShowManageGitRepoForm(true);
    if (mode === "create") {
      setSelectedRepo(newGitRepoValues);
      setGitRepoMode(mode);
    }
  };

  const onEditHandler = row => {
    setSelectedRepo(toCamelCaseKeys(row));
    setGitRepoMode("edit");
    toggleGitRepoFormVisibility("edit");
  };
  const onRemoveHandler = row => {
    setSelectedRepo(toCamelCaseKeys(row));
    dispatch(toggleDeleteConfirmBox(true));
  };

  const onShowSSHKeyHandler = row => {
    setSelectedRepo(toCamelCaseKeys(row));
    setShowSSHKey(true);
  };

  const onShowAccessTokenHandler = row => {
    setSelectedRepo(toCamelCaseKeys(row));
    setShowAccessToken(true);
  };

  const gitRepoCol = tableColumns(
    onRemoveHandler,
    onEditHandler,
    onShowSSHKeyHandler,
    onShowAccessTokenHandler,
  );

  useEffect(() => {
    dispatch(fetchGitRepos());
  }, [dispatch]);

  return (
    <>
      {showSSHKey && (
        <Modal
          onClose={() => {
            setShowSSHKey(false);
          }}
          title={"SSH Key"}
          showClose={true}
          appendToPortal={true}
          contentClass="pr-50"
        >
          <textarea
            className={`text-area copy-text`}
            id="sshKey"
            name="sshKey"
            value={selectedRepo.sshKey}
            cols={60}
            rows={4}
            readOnly
          />
        </Modal>
      )}
      {showAccessToken && (
        <Modal
          onClose={() => {
            setShowAccessToken(false);
          }}
          title={"Access Token"}
          showClose={true}
          appendToPortal={true}
          contentClass="pr-30"
        >
          <textarea
            className={`compact-text-area copy-text`}
            id="accessToken"
            name="accessToken"
            value={selectedRepo.accessToken}
            cols={60}
            rows={4}
            readOnly
          />
        </Modal>
      )}
      {!!loadingMessage && <Wait text={loadingMessage} />}
      {showConfirm && (
        <Confirm
          heading="Delete Confirmation"
          cancelText="Cancel"
          confirmText="Delete"
          message="This GitHub Repo will be deleted permanently."
          confirmValue={selectedRepo.name}
          confirmRequiredText={`Confirm by typing '${selectedRepo.name}' below`}
          verifiedCallback={async () => {
            dispatch(isFetching(true, "Deleting Git Account"));
            try {
              await Api.deleteGitRepo(selectedRepo.name);
              dispatch(toggleDeleteConfirmBox(false));
              dispatch(removeGitRepo(mapData(selectedRepo)));
              FTNotification.success({
                title: "Git Account Deleted Successfully!",
              });
            } catch (error) {
              FTNotification.error({
                title: "Could not delete Git Account",
                message: error.message,
              });
            }
            dispatch(isFetching(false, ""));
          }}
        />
      )}
      {showManageGitRepoForm && (
        <ManageGitRepo
          selectedRepo={selectedRepo}
          closeForm={setShowManageGitRepoForm}
          mode={gitRepoMode}
          repoList={gitRepoData}
        />
      )}

      <div className="d-flex flex-column2">
        <SettingsPanelHeader
          isBreadCrumb={true}
          page="git-repos"
          heading="GitHub Repos"
          breadCrumbText="Settings"
          toggleGitRepoFormVisibility={toggleGitRepoFormVisibility}
        />
        <div className="mt-40 ml-15 gr-list-container aws-settings-list">
          <DataTable
            className="snippet-list-table"
            columns={gitRepoCol}
            data={gitRepoData}
            responsive={true}
            fixedHeader={true}
          />
        </div>
      </div>
    </>
  );
};

export default GitRepos;
