import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Formik } from "formik";
import {
  isEmpty,
  formatLabelName,
  camelToSnake,
  capitalizeFirstLetter,
} from "@lib/utils";
import { Wait } from "@components/ui";
import * as Yup from "yup";
import {
  isFetching,
  setCurrentNode,
  saveConnecterSchema,
} from "@redux/actions/settingsPanel.action";
import FormInput from "@containers/SettingsPanel/settings-panel-components/SettingsRightConfigPanel/FormInput";
import { Redirect } from "react-router";
import { FormFooter } from "./Helpers";

type DefaultFormProps = {
  currentNodeSchema: any;
  setCurrentNode: (node: object) => void;
  connector: string;
  loadingMessage: string;
  isFetching: (flag, message) => void;
  saveConnecterSchema: (data, connector) => void;
};
type DefaultFormStateProps = {
  showForm: boolean;
  initialValues: any;
  validationSchema: any;
  doRedirect: boolean;
  redirectUrl: string;
};
class DefaultForm extends Component<DefaultFormProps, DefaultFormStateProps> {
  constructor(props) {
    super(props);
    this.state = {
      showForm: false,
      initialValues: {},
      validationSchema: {},
      doRedirect: false,
      redirectUrl: "",
    };
  }

  componentDidMount() {
    this.props.setCurrentNode({
      name: this.props.connector,
      status: "Not Setup",
    });
  }

  goBack = () => {
    this.setState({ doRedirect: true, redirectUrl: "/settings" });
  };

  filterFields = formData => {
    let filteredFields = {};
    for (const key in formData) {
      if (
        !!key &&
        this.props.currentNodeSchema.properties.hasOwnProperty(key) &&
        !this.props.currentNodeSchema["properties"][key]["hidden"]
      ) {
        filteredFields[key] = formData[key];
      }
    }

    return filteredFields;
  };

  getInitialValues = () => {
    let initialValues = {};
    let formSchema = { ...this.props.currentNodeSchema.properties };
    !isEmpty(formSchema) &&
      Object.keys(formSchema).map(val => (initialValues[val] = ""));
    initialValues = this.filterFields(initialValues);
    this.setState({ initialValues }, () => {
      this.getValidationSchema();
    });
  };

  getValidationSchema = () => {
    let validationSchema = {};
    let initialValues = { ...this.state.initialValues };
    for (const key in initialValues) {
      if (this.props.currentNodeSchema.required.indexOf(key) !== -1) {
        const label = this.getFieldName(key);
        validationSchema[key] = Yup.string()
          .required(`${label} is required`)
          .trim("This field can't be empty");
      }
    }
    this.setState({ validationSchema: Yup.object(validationSchema) }, () => {
      this.setState({ showForm: true });
    });
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.currentNodeSchema !== this.props.currentNodeSchema &&
      !isEmpty(this.props.currentNodeSchema)
    ) {
      this.getInitialValues();
    }
  }

  getFieldName = (field: string = "") => {
    /**
     * case if: handle Field format for Acronyms like 'URL', 'API' etc.
     * case else: use generic function to format field label.
     */
    if (field?.toLowerCase?.().includes?.("url")) {
      let fieldArr = field
        .toLowerCase()
        .split("_")
        .map(val => {
          return val === "url" ? val.toUpperCase() : capitalizeFirstLetter(val);
        });
      return fieldArr.join(" ");
    }
    return formatLabelName(field);
  };

  getFormFields = formik => {
    return (
      !isEmpty(this.state.initialValues) &&
      Object.keys(this.state.initialValues).map((field, key) => {
        let fieldValue = formik.values[field];
        try {
          fieldValue = JSON.parse(formik.values[field]);
        } catch (err) {}

        let maskedEntries = [
          "api_key",
          "app_key",
          "JIRA_PUBLIC_KEY",
          "access_token",
          "token",
          "ssh_private_key",
          "external_id",
          "instana_api_token",
          "authToken",
          "splunk_token",
          "passphrase",
          "password",
          "APIToken",
        ];
        let isMasked = maskedEntries.includes(field) ? true : null;
        return (
          <FormInput
            fieldName={this.getFieldName(field)}
            name={field}
            showJson={true}
            id={field}
            fieldValue={fieldValue}
            touched={formik.touched}
            errors={formik.errors}
            placeholder={`Enter ${this.getFieldName(field)}`}
            key={key}
            autoComplete="off"
            isMasked={isMasked}
          />
        );
      })
    );
  };

  getPayload = formData => {
    let payload = {};
    Object.keys(formData).forEach(key => {
      payload[camelToSnake(key)] = formData[key];
    });
    return { credentials: payload };
  };

  onSubmitHandle = async (values, actions) => {
    this.props.isFetching(true, "Integration in progress");
    actions.setSubmitting(true);
    const payload = this.getPayload(values);
    try {
      await this.props.saveConnecterSchema(payload, this.props.connector);
      actions.setStatus({
        formSubmitMessage: "Connector details saved successfully!!!",
      });
      setTimeout(() => {
        this.goBack();
      }, 1500);
    } catch (error) {
      actions.setStatus({
        formSubmitMessage: error?.message || "Oops! Something went wrong !!!",
      });
    }
    actions.setSubmitting(false);
    this.props.isFetching(false, "");
  };
  render() {
    return (
      <>
        {this.state.doRedirect && <Redirect to={this.state.redirectUrl} push />}
        {!!this.props.loadingMessage && (
          <Wait loading text={this.props.loadingMessage} />
        )}
        {this.state.showForm && (
          <Formik
            initialValues={this.state.initialValues}
            validationSchema={this.state.validationSchema}
            onSubmit={this.onSubmitHandle}
          >
            {formik => (
              <div className="d-flex flex-column2">
                <h2 className="pt-10 pb-10 mb-15">Authorize Fylamynt</h2>
                {this.getFormFields(formik)}
                <div className="mt-20">
                  {!!formik.status && formik.status.formSubmitMessage}
                </div>
                <FormFooter onSubmit={formik.handleSubmit} />
              </div>
            )}
          </Formik>
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  currentNodeSchema: state.settingsPanelReducer.currentNodeSchema,
  isLoading: state.settingsPanelReducer.formSaving,
  loadingMessage: state.runbooksReducer.message,
});
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      isFetching,
      setCurrentNode,
      saveConnecterSchema,
    },
    dispatch,
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(DefaultForm);
