import React from "react";
import { Button } from "@components/ui";
import { FTNotification } from "@components/ui";
import { Consumer } from "../runbook-details-lib/runbook-details.context";
import { useSelector } from "react-redux";
import { RouteConstants } from "../../../routes/Constants";
import { limitExceeded } from "../../../utils/common";
import ReactTooltip from "react-tooltip";
import { getUnconfiguredNodeList } from "@lib/utils";

export default props => {
  const userDefaultAccounts = useSelector(
    state => state.getStartedReducer.userDefaultAccounts,
  );
  const isAccountDetailFetched = useSelector(
    state => state.getStartedReducer.isAccountDetailFetched,
  );
  const noAccountAdded = isAccountDetailFetched && !userDefaultAccounts?.length;
  const getTooltipText = unconfiguredNodeList => {
    let unconfiguredNodes = getUnconfiguredNodeList(unconfiguredNodeList);
    return `Please complete the setup for ${
      noAccountAdded
        ? RouteConstants["aws"].displayName +
          (unconfiguredNodes.length > 1 ? "," : ".")
        : ""
    } ${unconfiguredNodes
      .map(
        nodeName =>
          ` ${
            RouteConstants.hasOwnProperty(nodeName)
              ? RouteConstants[nodeName].displayName
              : ""
          }`,
      )
      .toString()}`;
  };

  const handleScheduleRun = async toggleScheduleModal => {
    try {
      await props.fetchAccountPlanInfo();
      if (
        limitExceeded(
          props.quotas?.executions?.current_usage,
          props.quotas?.executions?.limit,
        )
      ) {
        props.toggleRunsLimitDialog();
        return;
      }
    } catch (e) {
      FTNotification.error({
        title: "Could not fetch Quotas",
      });
      return;
    }
    toggleScheduleModal();
  };

  return (
    <Consumer>
      {({
        toggleRunbookModal,
        openEditor,
        runbook,
        unconfiguredNodes,
        toggleScheduleModal,
      }) => (
        <div className="version-btn__wrap">
          <Button
            text="Editor"
            buttonStyle="secondary"
            size="medium"
            icon="icon-edit"
            onClick={openEditor}
            className="btn-secondary btn-details"
          ></Button>

          {(noAccountAdded || unconfiguredNodes.length > 0) && (
            <ReactTooltip
              id="submitTip"
              place="top"
              effect="solid"
              type="light"
            ></ReactTooltip>
          )}
          <div
            data-for="submitTip"
            data-tip={getTooltipText(unconfiguredNodes)}
            className="full-height"
          >
            <Button
              text="Schedule Run"
              buttonStyle="secondary"
              data-tip={getTooltipText(unconfiguredNodes)}
              size="medium"
              icon="icon-calendar"
              className={`btn-secondary btn-details-md ${
                noAccountAdded || unconfiguredNodes.length > 0
                  ? "no-pointer run-btn-disabled"
                  : "btn-primary "
              }`}
              onClick={() => handleScheduleRun(toggleScheduleModal)}
            ></Button>
          </div>
          <div
            data-for="submitTip"
            data-tip={getTooltipText(unconfiguredNodes)}
            className="full-height"
          >
            <Button
              text={"Run Now"}
              buttonStyle="primary"
              size="medium"
              icon="icon-play"
              onClick={toggleRunbookModal}
              className={`btn-details 
                ${
                  noAccountAdded || unconfiguredNodes.length > 0
                    ? "no-pointer run-btn-disabled "
                    : "btn-primary "
                }`}
            />
          </div>
        </div>
      )}
    </Consumer>
  );
};
