/**
 * This file exports JSON Editor
 * Expected Props -
 * 1. editorTitle: Optional*
 *      Title for JSON Editor. Needs String value.
 * 2. value: Required*
 *      Stringified JSON to be shown/edited.
 *      Should be passed along with indentation.
 * 3. onChange: Required*
 *      On change handler function to edit/update the passed value.
 *      This method updates the original value.
 * 4. className: Optional*
 *      Extra styles for Editor. Needs String value.
 * 5. displayContainerClassName: Optional*
 *      Extra styles for Text Area used to display content. Needs String value.
 * 4. title: Optional*
 *      Title for Text Area used to display content. Needs String value.
 */

import React, { useState } from "react";
import "./JSONEditor.scss";
import Editor from "@monaco-editor/react";
import { Modal, Button } from "@components/ui";
import arrowIcon from "@assets/images/icons/select-carrot.svg";
import deleteIcon from "@assets/images/icons/icon-trash.svg";

type JSONEditorProps = {
  editorTitle?: string;
  value: string;
  onChange: (value) => void;
  className?: string;
  displayContainerClassName?: string;
  title?: string;
  allowDelete?: boolean;
  onDelete?: () => void;
};

const JSONEditor: React.FC<JSONEditorProps> = ({
  editorTitle = "JSON Editor",
  value = "",
  onChange,
  className = "",
  displayContainerClassName = "",
  title = "content",
  allowDelete = false,
  onDelete,
}) => {
  const [showEditor, setShowEditor] = useState(false);
  const [showJSONError, setShowJSONError] = useState(false);
  const [showFooter, setShowFooter] = useState(false);
  const [editorContent, setEditorContent] = useState(value);
  const [displayContent, setDisplayContent] = useState(value);
  const [showDisplayContainer, setShowDisplayContainer] = useState(true);
  const [showHoverTooltip, setShowHoverTooltip] = useState(false);

  const toggleHoverTooltipVisibility = () =>
    setShowHoverTooltip(!showHoverTooltip);
  const toggleDisplayContainerVisibility = () => {
    setShowDisplayContainer(!showDisplayContainer);
  };

  const toggleEditorVisibility = () => {
    if (showEditor) {
      setShowJSONError(false);
    }
    setShowEditor(!showEditor);
  };

  const isValidJSON = value => {
    setEditorContent(value);
    try {
      JSON.parse(value);
      setShowJSONError(false);
    } catch (error) {
      setShowJSONError(true);
    }
  };

  const onEditorMount = () => {
    setShowFooter(true);
  };

  const onSaveJSON = () => {
    setDisplayContent(JSON.stringify(JSON.parse(editorContent), undefined, 4));
    onChange(JSON.parse(editorContent));
    toggleEditorVisibility();
  };

  const footerBtnStyle = { width: "50%" };

  const getFooter = () => {
    return (
      showFooter && (
        <div className="modal-buttons-footer">
          <Button
            text="Cancel"
            buttonStyle="secondary"
            style={footerBtnStyle}
            onClick={toggleEditorVisibility}
            type="button"
          />
          <Button
            text="Save"
            className={`${showJSONError && "submit-disabled"}`}
            buttonStyle="primary"
            style={footerBtnStyle}
            size="large"
            disabled={showJSONError}
            onClick={onSaveJSON}
          />
        </div>
      )
    );
  };

  return (
    <>
      {showEditor && (
        <Modal
          onClose={toggleEditorVisibility}
          title={editorTitle}
          backgroundCanClose={false}
          showClose={true}
          footer={getFooter()}
        >
          <div className="editor-container">
            <Editor
              height="50vh"
              language="json"
              theme="vs-dark"
              className={`mb-10 ${className}`}
              defaultValue={displayContent}
              onChange={value => {
                isValidJSON(value);
              }}
              onMount={onEditorMount}
            />
            <span className={`invalid-json ${showJSONError && "hide-error"}`}>
              Invalid JSON. Closing this window will discard invalid changes.
            </span>
          </div>
        </Modal>
      )}
      <div
        className="d-flex inline-accordion"
        onClick={toggleDisplayContainerVisibility}
      >
        <img
          src={arrowIcon}
          alt="arrowIcon"
          className={`pointer jv-icon ml-5 ${
            showDisplayContainer ? "jv-icon-expanded" : "jv-icon-collapsed"
          }`}
          width="10"
          height="10"
        />
        <label className="label ml-5 mb-0 pointer">{title}</label>
        {allowDelete && (
          <img
            src={deleteIcon}
            alt="deleteIcon"
            className={`pointer jv-icon-delete mr-10`}
            width="12"
            height="14"
            onClick={onDelete}
          />
        )}
      </div>
      {/* TODO: Add transition to container div on toggle */}
      {showDisplayContainer ? (
        <div
          className={`JSON-viewer-container ${displayContainerClassName}`}
          onMouseEnter={toggleHoverTooltipVisibility}
          onMouseLeave={toggleHoverTooltipVisibility}
        >
          {showHoverTooltip && (
            <div className="helper-content">Open JSON Editor</div>
          )}
          <textarea
            value={displayContent}
            onClick={toggleEditorVisibility}
            className={`compact-text-area JSON-viewer-content pointer`}
            readOnly
          />
        </div>
      ) : (
        <div className="mb-10"></div>
      )}
    </>
  );
};

export default JSONEditor;
