import React from "react";
import { Logo } from "@components/modules";
import "./TwilioSetup.scss";
import LeftPanel from "./setup-form-helper/left-panel";
import RightPanel from "./setup-form-helper/RightPanel";
import { Link } from "react-router-dom";

const TwilioSetup = () => {
  return (
    <div className="main-content">
      <div className="logo-header-flex">
        <div className="logo-header__inner-wrap">
          <Link to="/settings" className="breadcrumb-link">
            ← Back To Settings
          </Link>
        </div>
        <div className="logo-header__right-side"></div>
        <div className="logo-header__logo-wrap">
          <Logo />
        </div>
      </div>
      <div className="onboarding-wrap__flex">
        <LeftPanel />
        <RightPanel />
      </div>
    </div>
  );
};

export default TwilioSetup;
