import React from "react";
import "./Headers.scss";
import { Logo } from "@components/modules";
import { Link } from "react-router-dom";

const Headers = () => {
  return (
    <div className="header-container">
      <div className="header-container-inner">
        <Link to="/settings" className="breadcrumb-link">
          ← Back To Settings
        </Link>
        <Logo />
      </div>
    </div>
  );
};

export default Headers;
