export const getSnippetCss = snippetName => {
  if (!snippetName) {
    return;
  }
  const name = snippetName.toLowerCase();
  let firstPartOfName = name.split("_")[0];
  let className;

  /* look for specific snippet names first */
  if (name.includes("sms")) {
    return "snippet-icon-image connector-twilio";
  }
  switch (name) {
    case "stop_vms":
    case "stop_and_image":
      className = "snippet-stop-vms";
      break;
    case "get_http":
      className = "snippet-get-http";
      break;

    default:
      switch (firstPartOfName) {
        case "splunk":
          className = "connector-splunk";
          break;

        case "replace":
          className = "snippet-replace";
          break;

        case "conditional":
          className = "snippet-conditional";
          break;

        case "wait":
        case "waitforresource":
          className = "snippet-wait";
          break;

        case "success":
          className = "snippet-success";
          break;

        case "delete":
          className = "snippet-delete";
          break;

        case "fail":
          className = "snippet-fail";
          break;

        case "check":
          className = "snippet-check";
          break;

        case "stop":
          className = "snippet-stop";
          break;

        case "pick":
          className = "snippet-pick";
          break;

        case "container":
          className = "snippet-container";
          break;

        case "send":
          className = "snippet-email";
          break;

        case "http":
          className = "snippet-http";
          break;

        case "pulumi":
          className = "snippet-pulumi";
          break;

        case "ansible":
          className = "connector-ansible";
          break;

        case "modify":
          className = "snippet-modify";
          break;

        case "start":
          className = "snippet-start";
          break;

        case "run":
        case "launch":
          className = "snippet-launch";
          break;

        case "create":
          className = "snippet-create";
          break;

        case "register":
        case "attach":
          className = "snippet-attach";
          break;

        case "deregister":
        case "detach":
          className = "snippet-detach";
          break;

        case "pause":
          className = "snippet-pause";
          break;

        case "put":
          className = "snippet-cors";
          break;

        case "webhook":
          className = "snippet-webhook";
          break;

        case "jsonpath":
          className = "snippet-json";
          break;

        case "set":
          className = "snippet-policy";
          break;

        case "mount":
          className = "snippet-disk";
          break;

        case "waitforapproval":
          className = "snippet-wait";
          break;

        case "terraform":
          className = "snippet-terraform";
          break;

        case "awstrustedadvisor":
          className = "snippet-trustedadvisor";
          break;

        case "action":
        case "aws":
          className = "snippet-action";
          break;

        case "slack":
          className = "connector-slack";
          break;

        case "datadog":
          className = "connector-datadog";
          break;

        case "instana":
          className = "connector-instana";
          break;

        case "cloudwatch":
          className = "connector-cloudwatch";
          break;

        case "curl":
          className = "connector-curl";
          break;

        case "lambda":
          className = "connector-lambda";
          break;

        case "cloudformation":
          className = "connector-cloudformation";
          break;

        case "ssh":
          className = "connector-ssh";
          break;

        case "jira":
          className = "connector-jira";
          break;

        case "copy":
          className = "snippet-copy";
          break;

        case "get":
        case "fetch":
          className = "snippet-fetch";
          break;

        case "loop":
          className = "snippet-loop";
          break;

        case "filter":
          className = "snippet-filter";
          break;

        case "eks":
          className = "snippet-eks";
          break;

        case "deploy":
          className = "snippet-deploy";
          break;

        case "kubectl":
          className = "snippet-kubectl";
          break;

        case "add":
          className = "snippet-add";
          break;

        case "provision":
          className = "snippet-provision";
          break;

        case "make":
          className = "snippet-make";
          break;

        case "describe":
          className = "snippet-describe";
          break;

        case "update":
          className = "snippet-update";
          break;

        case "tag":
          className = "snippet-tag";
          break;

        case "disable":
          className = "snippet-disable";
          break;

        case "upsert":
          className = "snippet-upsert";
          break;

        case "pagerduty":
          className = "connector-pagerduty";
          break;

        case "python":
          className = "connector-python";
          break;

        case "jenkins":
          className = "connector-jenkins";
          break;

        default:
          className = `snippet-icon-default`;
          break;
      }
      break;
  }
  return `snippet-icon-image ${className}`;
};
