import React from "react";
import "./GetStartedForm.scss";
import "./Utils/LeftPanel.scss";
import "./Utils/RightPanel.scss";
import Headers from "./Utils/Headers";
import { kebabToCamel } from "@lib/utils";
import { useLocation } from "react-router-dom";
import DefaultForm from "./Utils/DefaultForm";
import { RouteConstants } from "../../../routes/Constants";

type GetStartedProps = {
  instructionPanel: any;
  connectorForm?: any;
  showCustomForm?: boolean;
  customHeader?: React.ReactElement;
  customContainerClass?: string;
  customTitleClass?: string;
  customPanelClass?: string;
  customPanelLeftClass?: string;
};

const GetStarted: React.FC<GetStartedProps> = ({
  instructionPanel,
  connectorForm,
  showCustomForm,
  customHeader,
  customContainerClass = "",
  customTitleClass = "",
  customPanelClass = "",
  customPanelLeftClass = "",
}) => {
  const path = useLocation()?.pathname?.split("/");
  const connectorName = path?.[2];
  const formattedName =
    RouteConstants[kebabToCamel(connectorName)]?.displayName;
  const formContent = showCustomForm ? (
    connectorForm
  ) : (
    <DefaultForm connector={connectorName} />
  );
  return (
    <div className={`gs-container ${customContainerClass}`}>
      {customHeader ? customHeader : <Headers />}
      <div className="gs-body">
        <div
          className={`gs-title ${customTitleClass}`}
        >{`${formattedName} Integration`}</div>
        <div className={`gs-panel ${customPanelClass}`}>
          <div
            className={`gs-panel-inner gs-panel-left ${
              customPanelLeftClass || ""
            }`}
          >
            {instructionPanel}
          </div>
          <div className="gs-panel-inner gs-panel-right">{formContent}</div>
        </div>
      </div>
    </div>
  );
};
export default GetStarted;
