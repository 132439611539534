import React, { useContext } from "react";
import {
  LabelTop,
  AccordionSection,
  Select,
  SelectOption,
} from "@components/ui";
import { Consumer } from "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.context";

const JiraRequiredFields = ({
  project,
  projectsList,
  updateSelectedProject,
  issueKey,
  issues,
  selectIssue,
}) => {
  const { notifyRunbookUpdate } = useContext(Consumer as any);

  return (
    <AccordionSection>
    <div className="editor-detail-panel editor-detail-panel-column">
    <div className="editor-select-container">
        <LabelTop
          label={"Jira Projects"}
          labelClassName="label"
        ></LabelTop>
        <Select
          required={true}
          value={project && typeof project === "string" ? project : ""}
          title={
            project && typeof project === "string"
              ? project
              : "Select from below"
          }
          onChange={newValue => {
            console.log("Changed JIRA required field: ", newValue);
            updateSelectedProject(newValue, notifyRunbookUpdate);
          }}
          optionHeight={32}
          style={{ width: "calc(100% - 8px)"}}
          iconLeft={"icon-filter"}
        >
          {projectsList &&
            projectsList.map(output => (
              <SelectOption
                value={output}
                text={output}
                key={output}
                className="text-small"
              />
            ))}
        </Select>
        <LabelTop
          label={"Issue List"}
          labelClassName="label"
        ></LabelTop>
        <Select
          title={
            issueKey && typeof issueKey === "string"
              ? issueKey
              : "Select from below"
          }
          value={issueKey && typeof issueKey === "string" ? issueKey : ""}
          onChange={value => selectIssue(value, true, true)}
          iconLeft={"icon-filter"}
          required={true}
          optionHeight={32}
          style={{ width: "calc(100% - 8px)"}}
        >
          {issues &&
            issues.map(output => (
              <SelectOption
                value={output}
                text={output}
                key={output}
                className="text-small"
              />
            ))}
        </Select>
      </div>
      </div>
    </AccordionSection>
  );
};

export default JiraRequiredFields;
