import React from "react";
import { connect } from "react-redux";
import { TabCollection, Tab, TabContent } from "@components/ui/tabs";
import Panels from "./runbooks-panels/panels";
import { runbookOwners } from "@config/constants";
import { Consumer } from "../runbooks-lib/runbooks.context";

class RunbooksPanelsContainer extends React.Component {
  getRunbooksLength = (searchTerm, sortType, owner, activeOwner) => {
    if (searchTerm || sortType) {
      if (activeOwner === owner)
        return this.props.runbookListFiltered[owner].length;
      else return this.props.runbookList[owner].length;
    } else {
      return this.props.runbookList[owner].length;
    }
  };
  render() {
    return (
      <Consumer>
        {props => {
          return (
            <div className="runbook-tabs mt-40 ml-15">
              <TabCollection
                active={runbookOwners.self}
                tabChange={props.handleChange}
              >
                <Tab
                  title="My Workflows"
                  size={this.getRunbooksLength(
                    props.runbookSearchTerm,
                    props.sortType,
                    runbookOwners.self,
                    props.activeTabOwner,
                  )}
                  isWorkflow={this.props.isWorkflow}
                  name={runbookOwners.self}
                >
                  <TabContent>
                    <Panels />
                  </TabContent>
                </Tab>
                <Tab
                  title="Fylamynt Workflows"
                  size={this.getRunbooksLength(
                    props.runbookSearchTerm,
                    props.sortType,
                    runbookOwners.fylamynt,
                    props.activeTabOwner,
                  )}
                  isWorkflow={this.props.isWorkflow}
                  name={runbookOwners.fylamynt}
                >
                  <TabContent>
                    <Panels />
                  </TabContent>
                </Tab>

                {/* <Tab title="AWS Native Workflows" name={runbookOwners.amazon}>
                  <TabContent>
                    <Panels />
                  </TabContent>
                </Tab> */}
              </TabCollection>
            </div>
          );
        }}
      </Consumer>
    );
  }
}

const mapStateToProps = state => {
  return {
    runbookList: state.runbooksReducer.runbookList,
    runbookListFiltered: state.runbooksReducer.runbookListFiltered,
  };
};

export default connect(mapStateToProps)(RunbooksPanelsContainer);
