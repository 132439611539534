import React from "react";
import { connect } from "react-redux";
import "./twilio-inputs.scss";
import { Accordion, AccordionLabel, AccordionSection } from "@components/ui";
import { RunbookStepInputSource } from "@containers/RunbookEditor/runbook-editor-lib/ssm/nodeinputoutput";
import ReactSelect from "@components/ui/React-Select/ReactSelect";
import { fetchSenderNumbers } from "@redux/actions/snippets.actions";
import { isEmpty } from "@lib/utils";
import StringList from "@components/ui/InputTypes/StringList/StringList";

type PROPS_TYPE = {
  activeNode: any;
  notifyRunbookUpdate: any;
  senderNumbers: Array<any>;
  fetchSenderNumbers: any;
  isFetchingSenderNumbers: boolean;
};

type STATE_TYPE = {
  selectedSenderNumber: string;
  message: string;
  isFetching: boolean;
};
class TwilioInputs extends React.Component<PROPS_TYPE, STATE_TYPE> {
  runbookNode: any;
  constructor(props: PROPS_TYPE) {
    super(props);
    this.state = {
      selectedSenderNumber: "",
      message: "",
      isFetching: true,
    };

    this.runbookNode = this.props.activeNode.extras.runbookNode;
  }

  async componentDidMount() {
    const values = this.getExistingValues(this.runbookNode.parameterInputs);

    this.setState({ ...values });

    // Fetch the senders number
    if (!this.props.senderNumbers?.length) this.props.fetchSenderNumbers();
  }

  /**
   * This functions prepares and returns existing value object such as
   * it could directly be set as new state
   * @param inputs
   */
  getExistingValues = (inputs: [any]) => {
    const sourceMap = {};

    inputs?.forEach(v => {
      const sourceValue = v?.source.sourceValue;
      if (v.name === "message") {
        sourceMap["message"] =
          typeof sourceValue === "string" ? sourceValue : "";
      } else if (v.name === "sender_number") {
        sourceMap["selectedSenderNumber"] =
          typeof sourceValue === "string" ? sourceValue : "";
      }
    });

    return sourceMap;
  };

  /**
   * This method finds and returns param input object
   * @param param Prama name in runbookNode
   */
  getParamInput = (param: string) =>
    this.runbookNode.parameterInputs.find(p => p.name === param);

  getExistingRecipientsNumber = () => {
    const paramInput = this.getParamInput("recipients_number");
    const sourceValue = paramInput?.source.sourceValue;
    return Array.isArray(sourceValue) ? sourceValue : [];
  };

  /**
   * onChangeHandler for RecipientsNumber input
   * @param input
   */
  onRecipientsNumberChange = (value: any) => {
    const recipients_number = this.getParamInput("recipients_number");
    recipients_number.source = new RunbookStepInputSource(`constant`, value);
    this.props.notifyRunbookUpdate(true);
  };

  /**
   * onChangeHandler for Message input
   * @param input
   */
  onMessageChange = (input: any) => {
    const { value } = input.target;
    this.setState({ message: value });

    const message = this.getParamInput("message");
    message.source = new RunbookStepInputSource(`constant`, value);
    this.props.notifyRunbookUpdate(true);
  };

  /**
   * onSelect handler of sndersNumber Select input
   * @param selectedSenderNumber
   */
  onSenderNumberSelect = (selectedSenderNumber: string) => {
    this.setState({ selectedSenderNumber });
    const sender_number = this.getParamInput("sender_number");
    sender_number.source = new RunbookStepInputSource(
      `constant`,
      selectedSenderNumber,
    );

    this.props.notifyRunbookUpdate(true);
  };

  render() {
    return (
      <Accordion isExpanded={true} useArrow={true}>
        <AccordionLabel className="editor-accordion-label margin-top-10">
          Required Inputs
        </AccordionLabel>
        <AccordionSection>
          <div className="editor-detail-panel flex-column2">
            <StringList
              title="Recipients Number"
              StringListArr={this.getExistingRecipientsNumber()}
              onChange={this.onRecipientsNumberChange}
            />
          </div>
          <div className="editor-detail-panel flex-column2">
            <label className="label">Message</label>
            <textarea
              className={`compact-text-area w-auto twilio-inputs 
              ${this.state.message.length <= 0 ? "twilio-input-error" : ""}`}
              name="message"
              value={this.state.message}
              id="message"
              maxLength={2048}
              required={true}
              onChange={this.onMessageChange}
            />
          </div>

          <div className="editor-detail-panel flex-column2">
            <label className="label">Select 'Send From' Phone Number</label>
            {this.props.isFetchingSenderNumbers ? (
              <i>Fetching Sender Numbers</i>
            ) : (
              <ReactSelect
                id="twilio-sender-number"
                name="twilio-sender-number"
                value={{
                  value: this.state.selectedSenderNumber,
                  label: this.state.selectedSenderNumber
                    ? this.state.selectedSenderNumber
                    : "Select Number",
                }}
                handleChange={data => {
                  if (!isEmpty(data) && !!data.value) {
                    this.onSenderNumberSelect(data.value);
                  }
                }}
                selectOptions={this.props.senderNumbers.map(no => {
                  return {
                    value: no,
                    label: no,
                  };
                })}
                required
              />
            )}
          </div>
        </AccordionSection>
      </Accordion>
    );
  }
}

const mapStateToProps = state => ({
  senderNumbers: state.snippetsReducer.senderNumbers,
  isFetchingSenderNumbers: state.snippetsReducer.isFetchingSenderNumbers,
});

const mapDispatchToProps = dispatch => {
  return {
    fetchSenderNumbers: () => dispatch(fetchSenderNumbers()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TwilioInputs);
