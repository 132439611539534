import React from "react";
import Output from "./../output";
import Details from "./../details";
import { Tab, TabCollection, TabContent } from "@components/ui/tabs";
import {
  Accordion,
  AccordionLabel,
  AccordionSection,
  LabelTop,
} from "@components/ui";
import { fetchCloudwatchTargetAccounts } from "@redux/actions/snippets.actions";
import { connect } from "react-redux";
import { isEqual } from "underscore";
import ReactSelect from "@components/ui/React-Select/ReactSelect";
import { RunbookStepInputSource } from "@containers/RunbookEditor/runbook-editor-lib/ssm/nodeinputoutput";
import Api from "@lib/api";

class CloudwatchAlertConnectorPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cloudwatchConfiguredTargetAccounts: [],
      selectedAlarmArn: null,
      selectedAlias: null,
      targetAccountAlarmsList: [],
      isFetchingAlarmList: false,
    };
    this.activeNode = props.activeNode.extras.runbookNode;
    this.ssmAlias = this.getParamValue("alias");
    this.ssmAlarmArn = this.getParamValue("alarm_arn");
  }

  getParamValue(name) {
    return this.activeNode.parameterInputs.find(p => p.name === name);
  }

  componentDidMount() {
    if (this.ssmAlias.source.type === "constant") {
      this.setState({ selectedAlias: this.ssmAlias.source.sourceValue });
      this.fetchTargetAccountAlarmsList(this.ssmAlias.source.sourceValue)
    }
    if (this.ssmAlarmArn.source.type === "constant") {
      this.setState({ selectedAlarmArn: this.ssmAlarmArn.source.sourceValue });
    }

    if (this.props.cloudwatchConfiguredTargetAccounts.length === 0) {
      this.props.fetchCloudwatchTargetAccounts();
    } else {
      this.setState({
        cloudwatchConfiguredTargetAccounts: this.props
          .cloudwatchConfiguredTargetAccounts,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      !isEqual(
        prevProps.cloudwatchConfiguredTargetAccounts,
        this.props.cloudwatchConfiguredTargetAccounts,
      )
    ) {
      this.setState({
        cloudwatchConfiguredTargetAccounts: this.props
          .cloudwatchConfiguredTargetAccounts,
      });
    }
  }

  selectAlias(alias, remove_arn = true) {
    if (!alias) return;
    this.ssmAlias.source = new RunbookStepInputSource("constant", alias);
    this.setState({
      selectedAlias: alias,
    });
    if (remove_arn) {
      //this.ssmAlarmArn.source = new RunbookStepInputSource("constant", "");
      this.setState({
        selectedAlarmArn: null,
      });
      this.activeNode.runbook.notifyRunbookUpdate(true);
    }
    this.fetchTargetAccountAlarmsList(alias);
    this.activeNode.showHideWarning(!this.activeNode.isHealthyStep());
  }

  fetchTargetAccountAlarmsList = async alias => {
    this.setState({
      isFetchingAlarmList: true,
    });
    const data = await Api.fetchTargetAccountAlarmsList(alias);
    this.setState({
      targetAccountAlarmsList: data,
      isFetchingAlarmList: false,
    });
  };

  selectAlarmArn(data) {
    this.setState({
      selectedAlarmArn: data.value,
    });

    this.ssmAlarmArn.source = new RunbookStepInputSource(
      "constant",
      data.value,
    );
    this.activeNode.showHideWarning(!this.activeNode.isHealthyStep());
    this.activeNode.runbook.notifyRunbookUpdate(true);
  }

  render() {
    return (
      <TabCollection
        active="input"
        activeClassName="editor-detail-tab-wrap editor-detail-active-tab"
        inactiveClassName="editor-detail-tab-wrap editor-detail-inactive-tab"
        contentTop={50}
      >
        <Tab title="Input" name="input">
          <TabContent>
            <h4 className="editor-node-name">{this.props.activeNode.name}</h4>
            <React.Fragment>
              <Accordion isExpanded={true} useArrow={true}>
                <AccordionLabel className="editor-accordion-label margin-top-10">
                  Required Inputs
                </AccordionLabel>
                <AccordionSection>
                  <div className="editor-detail-panel editor-detail-panel-column">
                    <div className="editor-select-container">
                      <LabelTop label={`Alias:`} labelClassName="label">
                        {" "}
                      </LabelTop>
                      <ReactSelect
                        id="container-alias"
                        name="container-alias"
                        value={{
                          value: this.state.selectedAlias,
                          label: this.state.selectedAlias
                            ? this.state.selectedAlias
                            : "Select from below",
                        }}
                        handleChange={data => {
                          this.selectAlias(data.value);
                        }}
                        selectOptions={this.state.cloudwatchConfiguredTargetAccounts?.map(
                          item => {
                            return {
                              value: item.alias,
                              label: item.alias,
                            };
                          },
                        )}
                      />
                      <br />
                      <LabelTop
                        label={`Alarm:`}
                        labelClassName="label"
                      ></LabelTop>

                      {this.state.isFetchingAlarmList ? (
                        <span>
                          Fetching {this.state.selectedAlias} Alarms ...
                        </span>
                      ) : (
                        <ReactSelect
                          id="container-alias"
                          name="container-alias"
                          value={{
                            value: this.state.selectedAlarmArn,
                            label: this.state.selectedAlarmArn
                              ?.split(":")
                              .slice(-1)
                              .pop(),
                          }}
                          handleChange={data => {
                            this.selectAlarmArn(data);
                          }}
                          selectOptions={this.state.targetAccountAlarmsList?.map(
                            item => {
                              return {
                                value: item.arn,
                                label: item.name,
                              };
                            },
                          )}
                        />
                      )}
                    </div>
                  </div>
                </AccordionSection>
              </Accordion>
            </React.Fragment>
          </TabContent>
        </Tab>
        <Tab title="Output" name="output">
          <TabContent>
            <Output />
          </TabContent>
        </Tab>
        <Tab title="Details" name="details">
          <TabContent>
            <Details />
          </TabContent>
        </Tab>
      </TabCollection>
    );
  }
}

const mapStateToProps = state => ({
  cloudwatchConfiguredTargetAccounts:
    state.snippetsReducer.cloudwatchConfiguredTargetAccounts,
});

const mapDispatchToProps = dispatch => {
  return {
    fetchCloudwatchTargetAccounts: () =>
      dispatch(fetchCloudwatchTargetAccounts()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CloudwatchAlertConnectorPanel);
