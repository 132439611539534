import React from "react";
import { Button, LabelTop, Select, SelectOption } from "@components/ui";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  saveConnecterSchema,
  isFetching,
} from "@redux/actions/settingsPanel.action";
import { fetchGitRepos } from "@redux/actions/gitRepo.actions";
import { Formik } from "formik";
import * as Yup from "yup";
import FormInput from "../../../settings-panel-components/SettingsRightConfigPanel/FormInput";
import { Wait } from "@components/ui";
import { Redirect } from "react-router";
import IconFilter from "@assets/images/icons/icon-filter.svg";
import { RouteConstants } from "../../../../../routes/Constants";

const validationSchema = Yup.object({
  module_id: Yup.string().required("Module Id is required"),
  directory: Yup.string().required("Directory is required"),
  ref: Yup.string().required("Ref is required"),
  repo_id: Yup.string().required("Repository Id is required"),
});

class RightPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      doRedirect: false,
      redirectUrl: "",
    };
  }

  componentDidMount() {
    // Adding spinner title here.
    this.props.fetchGitRepos("Fetching Terraform CLI");
  }

  goBack = () => {
    this.setState({
      doRedirect: true,
      redirectUrl: RouteConstants.settings.url,
    });
  };

  onSubmitHandle = async (accountDetails, actions) => {
    this.props.isFetching(true, "Integration in progress");
    actions.setSubmitting(true);
    const { module_id, ...rest } = accountDetails;
    const module = { [module_id]: rest };
    const modules = { modules: module };
    const payload = { credentials: modules };
    try {
      await this.props.saveConnecterSchema(payload, "terraform_cli");
    } catch (e) {
      actions.setStatus({
        formSubmitMessage: "Oops! Something went wrong !!!",
      });
    }

    actions.setSubmitting(false);
    this.props.isFetching(false, "");
    if (this.props.formSubmittedSuccess) {
      actions.setStatus({
        formSubmitMessage: "Connector details saved successfully!!!",
      });
      setTimeout(() => {
        this.goBack();
      }, 1500);
    } else {
      actions.setStatus({
        formSubmitMessage: "Oops! Something went wrong !!!",
      });
    }
  };

  selectRepoId = (repo_id, setFieldValue) => {
    setFieldValue("repo_id", repo_id);
  };

  render() {
    return (
      <>
        {this.state.doRedirect && <Redirect to={this.state.redirectUrl} push />}
        {this.props.message && <Wait text={this.props.message} />}
        <div className="instructions__right-terraform">
          <div className="onboarding-form">
            <div className="onboarding-header-wrap">
              <h2>Authorize Fylamynt</h2>
            </div>
            <div className="onboarding-card">
              <Formik
                initialValues={{
                  module_id: "",
                  directory: "",
                  ref: "",
                  repo_id: "",
                }}
                validationSchema={validationSchema}
                onSubmit={this.onSubmitHandle}
              >
                {formik => (
                  <>
                    <FormInput
                      fieldName="Module Id"
                      subText="(Name for the Terraform module)"
                      name="module_id"
                      id="module_id"
                      handleChange={formik.handleChange}
                      touched={formik.touched}
                      errors={formik.errors}
                      autoComplete="off"
                    />

                    <div className="account-info__wrap">
                      <LabelTop
                        label={`Repo Id:`}
                        labelClassName="label label__padding-left no-margin"
                      >
                        <LabelTop
                          label={`(Repository ID to pull terraform config from)`}
                          labelClassName="label label__padding-left"
                        ></LabelTop>
                        <Select
                          value={formik.values.repo_id}
                          width="100%"
                          icon={IconFilter}
                          onChange={repo_id =>
                            this.selectRepoId(repo_id, formik.setFieldValue)
                          }
                          allowEmpty={false}
                          searchMin={2}
                          required={true}
                          title="Select from below"
                          titleClassname={
                            !formik.values.repo_id
                              ? `formik-field-error`
                              : `no-error`
                          }
                          optionHeight="35"
                        >
                          {(this.props.gitRepoList || []).map((repo, i) => (
                            <SelectOption
                              value={repo.name}
                              text={repo.name}
                              key={repo.name}
                              className="text-small"
                              animationBorder={"hover"}
                            />
                          ))}
                        </Select>
                      </LabelTop>
                      {formik.errors["repo_id"] &&
                        formik.touched["repo_id"] && (
                          <div className="input-feedback">
                            {formik.errors["repo_id"]}
                          </div>
                        )}
                    </div>

                    <FormInput
                      fieldName="Directory"
                      subText="(Directory in the repository to use for the root module to execute)"
                      name="directory"
                      id="directory"
                      handleChange={formik.handleChange}
                      touched={formik.touched}
                      errors={formik.errors}
                      autoComplete="off"
                    />

                    <FormInput
                      fieldName="Ref"
                      subText="(Branch, tag, or commit to checkout)"
                      name="ref"
                      id="ref"
                      handleChange={formik.handleChange}
                      touched={formik.touched}
                      errors={formik.errors}
                      autoComplete="off"
                    />

                    <div className="form-footer">
                      <Button
                        text="Cancel"
                        buttonStyle="secondary"
                        style={{ width: "48%", marginRight: "1rem" }}
                        size="large"
                        type="button"
                        onClick={this.goBack}
                      />
                      <Button
                        text="Authorize"
                        style={{ width: "48%" }}
                        buttonStyle="primary"
                        size="large"
                        onClick={formik.handleSubmit}
                      />
                    </div>

                    <div style={{ marginTop: "1rem" }}>
                      {!!formik.status && formik.status.formSubmitMessage}
                    </div>
                  </>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  message: state.runbooksReducer.message,
  formSubmittedSuccess: state.settingsPanelReducer.formSubmittedSuccess,
  gitRepoList: state.GITReposReducer.gitRepoList,
});
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      saveConnecterSchema,
      isFetching,
      fetchGitRepos,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(RightPanel);
