export class SnippetLibrary {
  static stepTypeNameFromID(id) {
    return id.replace(/_\d+$/, "");
  }

  constructor(snippets) {
    // TODO: this is wrong.  Why are we using opaque IDs for snippets
    // that are not used anywhere else in the app. MEanwhile the NAME of the snippet

    this.snippets = {};
    let snippetDef;
    for (snippetDef of snippets) {
      //Build the data structure right.
      this.snippets[snippetDef.name] = snippetDef;
    }
  }

  getSnippetDefinition(name) {
    // is really the ID.
    return this.snippets[name];
  }

  getSnippetDefinitionForStepID(stepID) {
    return this.getSnippetDefinition(SnippetLibrary.stepTypeNameFromID(stepID));
  }

  getSnippetDefinitionForActionNode() {
    return this.snippets["AWS"];
  }

  getSnippetDefinitionForLoop() {
    return this.snippets["Loop_For_Each"];
  }

  waitForResourceStepSnippetDefinition() {
    return this.snippets["Wait_For_Resource"];
  }

  isNeurOpsControl(stepID) {
    return !!this.getSnippetDefinitionForStepID(stepID);
  }
}
