import React from "react";
const JiraSettingsMainScreenshot = require("@assets/images/screenshots/settings-jira/Jira-Settings-main-screenshot.png");
const JiraSettingsIntegrationsScreenshot = require("@assets/images/screenshots/settings-jira/Jira-Settings-integrations-screenshot.png");
const JiraSettingsConfigureLinkScreenshot = require("@assets/images/screenshots/settings-jira/Jira-Settings-configure-link-screenshot.png");
const JiraSettingsConfigureAppUrlScreenshot = require("@assets/images/screenshots/settings-jira/Jira-Settings-configure-app-url-screenshot.png");
const JiraSettingsLinkAppsScreenshot = require("@assets/images/screenshots/settings-jira/Jira-Settings-link-apps-screenshot.png");
const JiraSettingsIncomingLinkScreenshot = require("@assets/images/screenshots/settings-jira/Jira-Settings-incoming-link-screenshot.png");
const JiraSettingsConsumerKeyNameScreenshot = require("@assets/images/screenshots/settings-jira/Jira-Settings-consumer-key-name-screenshot.png");
const AllowAccess = require("@assets/images/screenshots/settings-jira/Allow-access.png");
const FormSubmitStatus = require("@assets/images/screenshots/settings-jira/Form-submit-status.png");

export default () => (
  <>
    <div className="list-row">
      <div className="step-number">
        <div>1</div>
      </div>
      <div>
        Jira User (with admin privileges) should navigate to the
        <b> Jira settings</b> menu and select <b>Products.</b>
      </div>
    </div>
    <div className="list-image">
      <img
        src={JiraSettingsMainScreenshot}
        alt="JiraSettingsMainScreenshot"
        width="70%"
      />
    </div>
    <div className="list-row">
      <div className="step-number">
        <div>2</div>
      </div>
      <div>
        {" "}
        Under <b>INTEGRATIONS</b> in menu, select <b>Application links.</b>
      </div>
    </div>
    <div className="list-image">
      <img
        src={JiraSettingsIntegrationsScreenshot}
        alt="JiraSettingsIntegrationsScreenshot"
        width="70%"
        height="500"
      />
    </div>
    <div className="list-row">
      <div className="step-number">
        <div>3</div>
      </div>
      <div>
        Enter http://api.<i>fylamynt_saas_domain_name</i>/ as URL and click{" "}
        <b>Create new link</b>
      </div>
    </div>
    <div className="list-image">
      <img
        src={JiraSettingsConfigureLinkScreenshot}
        alt="JiraSettingsConfigureLinkScreenshot"
        width="70%"
      />
    </div>
    <div className="list-image">
      <img
        src={JiraSettingsConfigureAppUrlScreenshot}
        alt="JiraSettingsConfigureAppUrlScreenshot"
        width="70%"
      />
    </div>
    <div className="list-row">
      <div className="step-number">
        <div>4</div>
      </div>
      <div>
        Fill in Application Name with any name (used for identification) and
        leave Generic Application selected.
      </div>
    </div>
    <div className="list-image">
      <img
        src={JiraSettingsLinkAppsScreenshot}
        alt="JiraSettingsLinkAppsScreenshot"
        width="70%"
      />
    </div>
    <div className="list-row">
      <div className="step-number">
        <div>5</div>
      </div>
      <div>
        Scroll to the bottom of the form, check <b>Create incoming link</b>, and
        click <b>Continue.</b>
      </div>
    </div>
    <div className="list-image">
      <img
        src={JiraSettingsIncomingLinkScreenshot}
        alt="JiraSettingsIncomingLinkScreenshot"
        width="70%"
      />
    </div>
    <div className="list-row">
      <div className="step-number">
        <div>6</div>
      </div>
      <div>
        For the next page, copy-paste the "Consumer Key", "Consumer Name", and
        "Public Key" from the Authorize Fylamynt panel on the right, and click{" "}
        <b>Continue.</b>
      </div>
    </div>
    <div className="list-image">
      <img
        src={JiraSettingsConsumerKeyNameScreenshot}
        alt="JiraSettingsConsumerKeyNameScreenshot"
        width="70%"
      />
    </div>
    <h3 className="h2-margin">
      Follow below steps to complete "Step 2" in Authorize Fylamynt
    </h3>
    <div className="list-row" style={{ marginTop: "1rem" }}>
      <div className="step-number">
        <div>1</div>
      </div>
      <div>
        Copy the URL of your JIRA account (Example: https://acme.atlassian.net/)
        into the form under "Step 2" of "Authorize Fylamynt" in the right panel.
      </div>
    </div>
    <div className="list-row" style={{ marginTop: "1rem" }}>
      <div className="step-number">
        <div>2</div>
      </div>
      <div>
        Click on "Setup OAuth1" then you will be redirected to your JIRA account
        to "Allow" access for Fylamynt domain.
      </div>
    </div>
    <div className="list-image">
      <img src={AllowAccess} alt="AllowAccess" width="70%" />
    </div>
    <div className="list-row" style={{ marginTop: "1rem" }}>
      <div className="step-number">
        <div>3</div>
      </div>
      <div>Status message which confirms the JIRA integration.</div>
    </div>
    <div className="list-image">
      <img src={FormSubmitStatus} alt="FormSubmitStatus" width="70%" />
    </div>
  </>
);
