import React, { useState, useEffect } from "react";
import { Modal } from "@components/ui";
const prefix = "editor-accordion-row";

export default props => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [modalError, setModalError] = useState(null);

  const [action, setAction] = useState(null);
  const [modalTitle, setModalTitle] = useState(null);
  const [modalMessage, setModalMessage] = useState(null);
  let currentVersion = props.version.DocumentVersion;

  useEffect(() => {
    // create dummy event object to trick props.onClick
    let dummyEvent = new Event("none-placeholder");
    // this line returns div.editor-accordion-row that has a child of span.default-version
    let div = [].filter.call(
      document.querySelectorAll("div.editor-accordion-row"),
      i => {
        return i.querySelector("span.default-version");
      },
    )[0];
    if (div) {
      div.dispatchEvent(dummyEvent);
      if (processing) {
        switch (action) {
          case "default":
            //code for default
            if (!props.isFetchingVersion) {
              if (props.isVersionUpdateFailed) {
                setModalError("Could not set this version as Default");
              }
              if (props.isVersionUpdateSuccess) {
                setShowConfirm(false);
              }
            }
            break;
          case "delete":
            // eslint-disable-next-line react-hooks/exhaustive-deps
            let key = `${props.name}-${currentVersion}`;
            setShowConfirm(
              props.runbookVersionDeleteStatus[key] ? false : true,
            );
            if (props.runbookVersionDeleteStatus[key]) {
              props.onClick(
                dummyEvent,
                props.active
                  ? props.defaultVersion
                  : props.activeRunbookVersion,
                // Load default version if currently checked out runbook version is getting deleted,
                // Else load current active version
              );
            } else if (props.runbookVersionDeleteStatus[key] === false) {
              setModalError("Could not delete this version");
            }

            break;
          default:
            break;
        }
      }
    }
  }, [props, currentVersion, processing, action]);

  return (
    <>
      {showConfirm && (
        <Modal
          title={modalTitle}
          showClose={true}
          coverClass={`modal-cover__editor`}
          containerClass={`modal-container__editor-transactional`}
          contentClass={`modal-content__editor-transactional`}
          titleClass={`modal-title__json`}
          closeClass={`modal-close__editor`}
          footerClass={`modal-footer__editor`}
          onClose={() => {
            setShowConfirm(false);
          }}
          footer={
            <GetFooter
              setShowConfirm={setShowConfirm}
              deleteAction={props.deleteRunbookVersion}
              processing={processing}
              setProcessing={setProcessing}
              version={currentVersion}
              name={props.name}
              action={action}
              updateAction={props.updateDefaultVersion}
            />
          }
        >
          {modalMessage}
          <span className="error">{modalError}</span>
        </Modal>
      )}

      <div
        className={props.active ? `${prefix} ${prefix}-active` : prefix}
        onClick={e => {
          e.stopPropagation();
          props.onClick(e);
        }}
      >
        <div className={`${prefix}-icon`}></div>
        <div style={{ display: "flex", alignItems: "center" }}>
          {currentVersion}{" "}
          {props.version.IsDefaultVersion ? (
            <span className="default-version">default</span>
          ) : null}
        </div>
        <div className={`${prefix}__dropdown`} onClick={e => {}}>
          {props.version.IsDefaultVersion ||
          props.version.DocumentVersion === "Draft" ? null : (
            <>
              <div className={`${prefix}__dropdown-icon`} />
              <div className={props.className}>
                <div
                  className={`${prefix}__dropdown-link`}
                  onClick={e => {
                    e.stopPropagation();
                    setAction("default");
                    setModalTitle("Set As Default");
                    setModalMessage(
                      `You are about to set version (${currentVersion}) of ${props.name} as Default`,
                    );
                    setProcessing(false);
                    setShowConfirm(true);
                    //props.updateDefaultVersion(props.name, currentVersion);
                  }}
                >
                  Set Default
                </div>
                <div
                  className={`${prefix}__dropdown-link ${prefix}__dropdown-delete`}
                  onClick={e => {
                    e.stopPropagation();
                    setAction("delete");
                    setModalTitle("Confirm Deleting");
                    let msg = (
                      <div>
                        You are about to delete version ({currentVersion}) of{" "}
                        {props.name}
                      </div>
                    );
                    let currentSchedule = props.runbookSchedule.find(s => {
                      const workflowSplit = s.workflow.split("/");
                      return (
                        Number(workflowSplit[workflowSplit.length - 1]) ===
                        parseInt(currentVersion)
                      );
                    });

                    if (currentSchedule) {
                      msg = (
                        <div>
                          {msg}
                          This will also delete "
                          {currentSchedule.schedule_expression}" scheduler
                        </div>
                      );
                    }
                    setModalMessage(msg);
                    setProcessing(false);
                    setShowConfirm(true);
                  }}
                >
                  Delete
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

const GetFooter = props => {
  return (
    <div className="modal-buttons-footer__editor">
      <button
        type="button"
        className="footer-btn footer-btn-cancel"
        onClick={() => {
          props.setShowConfirm(false);
        }}
      >
        Cancel
      </button>
      <button
        type="button"
        className="footer-btn__danger footer-btn"
        onClick={e => {
          props.setProcessing(true);
          if (props.action === "default")
            props.updateAction(props.name, props.version);
          if (props.action === "delete")
            props.deleteAction(props.name, props.version);
        }}
      >
        Confirm
      </button>
    </div>
  );
};
