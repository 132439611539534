import React, { useContext } from "react";
import {
  Select,
  SelectOption,
  LabelTop,
  TextArea,
  AccordionLabel,
  AccordionSection,
} from "@components/ui";
import { Consumer } from "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.context";

const JiraOptionalFields = ({
  showIssueDetails,
  updateInputFieldValue,
  description,
  summary,
  transition,
  transitionsList,
  priority,
  prioritiesList,
}) => {
  const { notifyRunbookUpdate } = useContext(Consumer as any);

  return (
    <div className="mt-20">
      <AccordionLabel className="editor-accordion-label margin-top-10">
        Optional Inputs
      </AccordionLabel>
      <AccordionSection>
        {showIssueDetails && (
          <div className="editor-detail-panel editor-detail-panel-column">
          <div className="editor-select-container">
            <LabelTop
              label={"Description"}
              labelClassName="label"
            ></LabelTop>
            <TextArea
              onKeyUp={newValue =>
                updateInputFieldValue(
                  newValue,
                  notifyRunbookUpdate,
                  "description",
                )
              }
              style={{ width: "calc(100% - 14px)", marginBottom: "16px" }}
              value={
                description && typeof description === "string"
                  ? description
                  : ""
              }
              className={"compact-text-area"}
            ></TextArea>
            <LabelTop
              label={"Summary"}
              labelClassName="label"
            ></LabelTop>
            <TextArea
              onKeyUp={newValue =>
                updateInputFieldValue(newValue, notifyRunbookUpdate, "summary")
              }
              style={{ width: "calc(100% - 14px)", marginBottom: "16px" }}
              value={summary && typeof summary === "string" ? summary : ""}
              className={"compact-text-area"}
              maxLength={255}
            ></TextArea>
            <LabelTop
              label={"Transition to"}
              labelClassName="label"
            ></LabelTop>
            <Select
              title={
                transition && typeof transition === "string"
                  ? transition
                  : "Transition"
              }
              value={
                transition && typeof transition === "string" ? transition : ""
              }
              iconLeft={"icon-filter"}
              optionHeight={32}
              style={{ width: "calc(100% - 8px)"}}
              onChange={newValue => {
                updateInputFieldValue(
                  newValue,
                  notifyRunbookUpdate,
                  "transition",
                );
              }}
            >
              {transitionsList &&
                transitionsList.map(output => (
                  <SelectOption
                    value={output}
                    text={output}
                    key={output}
                    className="text-small"
                  />
                ))}
            </Select>
            <LabelTop
              label={"Priority"}
              labelClassName="label"
            ></LabelTop>
            <Select
              title={
                priority && typeof priority === "string"
                  ? priority
                  : "Select from below"
              }
              value={priority && typeof priority === "string" ? priority : ""}
              optionHeight={32}
              style={{ width: "calc(100% - 8px)"}}
              onChange={newValue => {
                updateInputFieldValue(
                  newValue,
                  notifyRunbookUpdate,
                  "priority",
                );
              }}
              iconLeft={"icon-filter"}
            >
              {prioritiesList &&
                prioritiesList.map(output => (
                  <SelectOption
                    value={output}
                    text={output}
                    key={output}
                    className="text-small"
                  />
                ))}
            </Select>
          </div>
          </div>
        )}
      </AccordionSection>
    </div>
  );
};

export default JiraOptionalFields;
