import React from "react";
import PanelsHeader from "./panels-header";
import { Consumer } from "../../runbooks-lib/runbooks.context";
import Panel from "./panel";
import NoResultFound from "@components/shared/NoSearchResults/NoResults";

export default props => (
  <>
    <PanelsHeader />
    <Consumer>
      {({ runbooksList, runbookSearchTerm, searchAndSort }) => (
        <React.Fragment>
          {runbookSearchTerm && !runbooksList.length && (
            <NoResultFound
              message={`No results found for ${runbookSearchTerm}`}
              detail="Try adjusting your search to find what you're looking for."
              className="mt-100"
              fallbackComponent={
                <span
                  className="reset-search"
                  onClick={() => searchAndSort({ search: "" })}
                >
                  Clear Search
                </span>
              }
            />
          )}
          <div className="runbooks-grid">
            {!!runbooksList.length &&
              runbooksList.map(runbook => (
                <Panel runbook={runbook} key={runbook.Name} />
              ))}
          </div>
        </React.Fragment>
      )}
    </Consumer>
  </>
);
