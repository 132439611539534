import React from "react";
import {
  Accordion,
  AccordionLabel,
  AccordionSection,
  DropDown,
  DropDownOption,
  LabelTop,
} from "@components/ui";
import { Tab, TabCollection, TabContent } from "@components/ui/tabs";
import { Consumer } from "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.context";
import { RunbookStepInputSource } from "../../../../runbook-editor-lib/ssm/nodeinputoutput";
import Output from "../output";
import Details from "../details";
import { connect } from "react-redux";
import {
  getTerraformWorkspaces,
  fetchTerraformVariables,
  updateMessage,
} from "@redux/actions/snippets.actions";
import JSONEditor from "@components/ui/InputTypes/JSONEditor/JSONEditor";

class TerraformUpdateVarsConnectorPanel extends React.Component {
  constructor(props) {
    super(props);
    let selectedInputs = {};
    // eslint-disable-next-line
    for (let item of props.activeNode.extras.runbookNode.parameterInputs) {
      if (
        typeof item.source.sourceValue === "string" ||
        Array.isArray(item.source.sourceValue)
      ) {
        selectedInputs[item.name] = item.source.sourceValue;
      }
    }

    this.state = {
      autoApplyStatus: false,
      selectedInputs: selectedInputs,
    };
    this.terraformWorkspaces = [];
  }

  componentDidMount() {
    if (!this.props.terraformWorkspaces.length) {
      this.props.getTerraformWorkspaces().finally(e => {
        this.props.updateMessage();
      });
      this.props.updateMessage("Fetching Terraform workspaces");
    }
  }

  componentWillUnmount() {
    this.props.activeNode.extras.runbookNode.showHideWarning(false);
  }

  setTerraformVariables = () => {
    const input = this.props.activeNode.extras.runbookNode.parameterInputs.find(
      ele => ele.name === "variables",
    );
    if (input) {
      input.source = new RunbookStepInputSource(
        `constant`,
        this.state.selectedInputs.variables,
      );
    }
  };

  WorkSpaceSelected = async (
    value,
    terraformWorkspaces,
    notifyRunbookUpdate,
  ) => {
    let paramInputs = this.props.activeNode.extras.runbookNode.parameterInputs;
    let workspaceInput = paramInputs.filter(
      item => item.name === "workspace_id",
    );
    workspaceInput[0].source = new RunbookStepInputSource(`constant`, value);
    await this.props.fetchTerraformVariables(value);
    let i, autoApplyStatus;
    for (i in terraformWorkspaces) {
      if (value === terraformWorkspaces[i].id) {
        if (terraformWorkspaces[i]["auto-apply"]) {
          autoApplyStatus = true;
        } else {
          autoApplyStatus = false;
        }
      }
    }
    this.setState(
      {
        autoApplyStatus: autoApplyStatus,
        selectedInputs: {
          workspace_id: value,
          variables: this.props.terraformVariables,
        },
      },
      () => {
        this.setTerraformVariables();
      },
    );

    notifyRunbookUpdate(true);
  };

  handleJSONEdit = (value, id, notifyRunbookUpdate) => {
    const input = this.props.activeNode.extras.runbookNode.parameterInputs.find(
      ele => ele.name === "variables",
    );
    if (input) {
      let terraformVariables = [...this.state.selectedInputs.variables];
      let terraformVariableIndex = terraformVariables.findIndex(
        ele => ele.id === id,
      );
      let terraformVariable = { ...terraformVariables[terraformVariableIndex] };
      terraformVariable["attributes"] = value;
      /**
       * assign the updated terraform variable to Terraform Var Arr
       * and update the state var Selected Inputs
       * */

      terraformVariables[terraformVariableIndex] = terraformVariable;
      input.source = new RunbookStepInputSource(`constant`, terraformVariables);

      const selectedInputs = {
        ...this.state.selectedInputs,
        variables: terraformVariables,
      };
      this.setState(
        {
          selectedInputs,
        },
        () => notifyRunbookUpdate(true),
      );
    }
  };

  getWorkspaceVariables = notifyRunbookUpdate => {
    return this.props.isFetchingTerraformVariables ? (
      <div className="mt-10">Loading variables...</div>
    ) : (
      this.state.selectedInputs.workspace_id &&
        this.state.selectedInputs.variables && (
          <LabelTop label={`Variables:`} labelClassName="label mt-10">
            {this.state.selectedInputs.variables &&
              this.state.selectedInputs.variables.map((variable, idx) => {
                let { id, type, ...attributes } = variable;
                let { attributes: attributeObj } = attributes;
                return (
                  <JSONEditor
                    key={idx}
                    title={variable.id}
                    editorTitle={variable.id}
                    value={JSON.stringify(attributeObj, undefined, 4)}
                    displayContainerClassName="mb-15"
                    onChange={value => {
                      this.handleJSONEdit(
                        value,
                        variable.id,
                        notifyRunbookUpdate,
                      );
                    }}
                  />
                );
              })}
          </LabelTop>
        )
    );
  };

  render() {
    return (
      <Consumer>
        {({ notifyRunbookUpdate }) => {
          let terraformWorkspaces = this.props.terraformWorkspaces;
          return (
            <TabCollection
              active="input"
              activeClassName="editor-detail-tab-wrap editor-detail-active-tab"
              inactiveClassName="editor-detail-tab-wrap editor-detail-inactive-tab"
              contentTop={50}
            >
              <Tab title="Input" name="input">
                <TabContent>
                  <h4 className="editor-node-name">
                    {this.props.activeNode.name}
                  </h4>
                  <React.Fragment>
                    <Accordion isExpanded={true} useArrow={true}>
                      <AccordionLabel className="editor-accordion-label margin-top-10">
                        Required Inputs
                      </AccordionLabel>
                      <AccordionSection>
                        <div className="editor-detail-panel editor-detail-panel-column">
                          <div className="editor-terraform-container">
                            <LabelTop
                              label={`Workspace:`}
                              labelClassName="label"
                            >
                              <DropDown
                                showArrow={true}
                                iconLeft="icon-filter"
                                style={{
                                  width: "calc(100% - 8px)",
                                  marginTop: "0px",
                                }}
                                title={
                                  terraformWorkspaces.length &&
                                  this.state.selectedInputs.workspace_id
                                    ? terraformWorkspaces.filter(
                                        space =>
                                          space.id ===
                                          this.state.selectedInputs
                                            .workspace_id,
                                      )[0]?.name
                                    : "Select"
                                }
                                onChange={(text, value) => {
                                  this.WorkSpaceSelected(
                                    value,
                                    terraformWorkspaces,
                                    notifyRunbookUpdate,
                                  );
                                }}
                              >
                                {terraformWorkspaces.length &&
                                  terraformWorkspaces.map(space => {
                                    return (
                                      <DropDownOption
                                        text={space.name}
                                        key={space.id}
                                        value={space.id}
                                      />
                                    );
                                  })}
                              </DropDown>
                            </LabelTop>
                            {this.getWorkspaceVariables(notifyRunbookUpdate)}
                            <br />
                            {this.state.autoApplyStatus &&
                            terraformWorkspaces.length
                              ? `"${
                                  terraformWorkspaces.filter(
                                    space =>
                                      space.id ===
                                      this.state.selectedInputs.workspace_id,
                                  )[0]?.name
                                }" workspace settings are set to auto apply`
                              : ``}
                          </div>
                        </div>
                      </AccordionSection>
                    </Accordion>
                  </React.Fragment>
                </TabContent>
              </Tab>
              <Tab title="Output" name="output">
                <TabContent>
                  <Output />
                </TabContent>
              </Tab>
              <Tab title="Details" name="details">
                <TabContent>
                  <Details />
                </TabContent>
              </Tab>
            </TabCollection>
          );
        }}
      </Consumer>
    );
  }
}

const mapState = state => ({
  terraformWorkspaces: state.snippetsReducer.terraformWorkspaces,
  isFetchingTerraformVariables:
    state.snippetsReducer.isFetchingTerraformVariables,
  terraformVariables: state.snippetsReducer.terraformVariables,
});
const mapDispatch = dispatch => ({
  getTerraformWorkspaces: () => dispatch(getTerraformWorkspaces()),
  fetchTerraformVariables: workspaceId =>
    dispatch(fetchTerraformVariables(workspaceId)),
  updateMessage: (message, showWait) =>
    dispatch(updateMessage(message, showWait)),
});

export default connect(
  mapState,
  mapDispatch,
)(TerraformUpdateVarsConnectorPanel);
