import React, { Component } from "react";
import { DataTable } from "@components/ui/data-table";
import tableColumns from "./APIKeyListHelper";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  fetchAPIKeys,
  deleteAPIKey,
  toggleDeleteConfirmBox,
} from "@redux/actions/apikeys.actions";
import { Confirm, Wait } from "@components/ui";
import { Redirect } from "react-router";

type APIKeysListPropType = {
  apiKeyList: any;
  isFetching: boolean;
  isDeleting: boolean;
  showConfirm: boolean;
  toggleDeleteConfirmBox: any;
  fetchAPIKeys: () => void;
  deleteAPIKey: (name: string) => void;
};

type APIKeysListStateType = {
  tableColumns: any;
  currentRow: any;
  confirmRequiredText: string;
  doRedirect: boolean;
  redirectUrl: string;
  keys: object;
};

class APIKeysList extends Component<APIKeysListPropType, APIKeysListStateType> {
  constructor(props) {
    super(props);
    this.state = {
      tableColumns: [],
      confirmRequiredText: "",
      currentRow: {},
      doRedirect: false,
      redirectUrl: "",
      keys: {},
    };
  }

  componentDidMount() {
    this.setState({
      tableColumns: tableColumns(
        this.onRemoveHandler,
        this.state.keys,
        this.toggleShow,
      ),
    });
    this.props.fetchAPIKeys();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.keys !== this.state.keys) {
      this.setState({
        tableColumns: tableColumns(
          this.onRemoveHandler,
          this.state.keys,
          this.toggleShow,
        ),
      });
    }
  }

  toggleShow = value => {
    let keys = { ...this.state.keys };
    if (keys.hasOwnProperty(value)) {
      delete keys[value];
    } else {
      keys[value] = "";
    }
    this.setState({
      keys,
    });
  };

  onRemoveHandler = (row: any) => {
    this.setState({
      currentRow: row,
      confirmRequiredText: `Confirm by typing '${row.ApikeyName}' below`,
    });
    this.props.toggleDeleteConfirmBox(true);
  };

  render() {
    return (
      <>
        {this.state.doRedirect && <Redirect to={this.state.redirectUrl} push />}
        {this.props.isFetching && <Wait text="Fetching API Key List" />}
        {this.props.isDeleting && <Wait text="Deleting API Key" />}
        <div className="aws-settings-list">
          <DataTable
            className="snippet-list-table"
            columns={this.state.tableColumns}
            data={this.props.apiKeyList}
            responsive={true}
            fixedHeader={true}
          />
        </div>
        {this.props.showConfirm && (
          <Confirm
            heading="Delete Confirmation"
            cancelText="Cancel"
            confirmText="Delete"
            message="This api key will be deleted permanently."
            confirmValue={this.state.currentRow.ApikeyName}
            confirmRequiredText={this.state.confirmRequiredText}
            verifiedCallback={async () => {
              this.props.toggleDeleteConfirmBox(false);
              await this.props.deleteAPIKey(this.state.currentRow.ApikeyName);
            }}
          />
        )}
      </>
    );
  }
}
const mapStateToProps = state => ({
  apiKeyList: state.APIKeysReducer.apiKeyList,
  isFetching: state.APIKeysReducer.isFetchingAPIKeys,
  isDeleting: state.APIKeysReducer.isDeletingAPIKey,
  showConfirm: state.uiReducer.showConfirm,
});
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      fetchAPIKeys,
      deleteAPIKey,
      toggleDeleteConfirmBox,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(APIKeysList);
