import React from "react";
import { Consumer } from "../../../../../runbook-editor-lib/runbook-editor.context";
import { Accordion, AccordionLabel, AccordionSection } from "@components/ui";
import SnippetControl from "./SnippetControl";
import flatMap from "lodash/flatMap";
import {
  getFilteredSnippetList,
  getBlacklistConnectorList,
  addBucketName,
  addIsDisconnectedAttribute,
} from "@lib/utils";

export default class SnippetConnectorsList extends React.Component {
  getSnippetConnectors = (snippets, blacklistConnectorList) => {
    let snippetList = { ...snippets };
    let snippetConnectors = { ...snippetList?.CONNECTOR };

    let snippetConnectorList = addIsDisconnectedAttribute(
      snippetConnectors,
      blacklistConnectorList,
    );
    return snippetConnectorList;
  };

  render() {
    return (
      <Consumer>
        {({ snippets, connectors }) => {
          const blacklistConnectorList = getBlacklistConnectorList(connectors);
          let snippetConnectors = this.getSnippetConnectors(
            snippets,
            blacklistConnectorList,
          );
          const category = "CONNECTORS";
          const flatConnectorList = flatMap(Object.values(snippetConnectors));
          const finalConnectorList = getFilteredSnippetList(
            addBucketName(flatConnectorList, `${category} Actions`),
            this.props.searchVal,
          );
          return (
            finalConnectorList.length > 0 &&
            snippets.hasOwnProperty("CONNECTOR") && (
              <Accordion isExpanded={true} useArrow={true} key={category}>
                <AccordionLabel className="editor-accordion-label">
                  CONNECTOR ACTIONS
                </AccordionLabel>
                <AccordionSection>
                  <div className="snippet-block">
                    {finalConnectorList.map(snippet => (
                      <SnippetControl snippet={snippet} key={snippet.id} />
                    ))}
                  </div>
                </AccordionSection>
              </Accordion>
            )
          );
        }}
      </Consumer>
    );
  }
}
