function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React from "react";

var _ref2 = React.createElement("title", null, "Group 2");

var _ref3 = React.createElement("desc", null, "Created with Sketch.");

var _ref4 = React.createElement("g", {
  id: "Filament-Logos",
  stroke: "none",
  strokeWidth: 1,
  fill: "none",
  fillRule: "evenodd"
}, React.createElement("g", {
  id: "Option-1---White-BG-Copy-4",
  transform: "translate(-190.000000, -160.000000)",
  fill: "#6201FF"
}, React.createElement("g", {
  id: "Group-2",
  transform: "translate(190.500000, 160.500000)"
}, React.createElement("rect", {
  id: "Rectangle",
  x: 12.4576271,
  y: 137.033898,
  width: 10.7966102,
  height: 10.7966102
}), React.createElement("rect", {
  id: "Rectangle",
  x: 37.3728814,
  y: 24.9152542,
  width: 48.1694915,
  height: 10.7966102
}), React.createElement("rect", {
  id: "Rectangle",
  x: 0,
  y: 0,
  width: 35.7118644,
  height: 10.7966102
}), React.createElement("polygon", {
  id: "Rectangle",
  transform: "translate(30.313559, 17.855932) rotate(90.000000) translate(-30.313559, -17.855932) ",
  points: "12.4576271 12.4576271 48.1694915 12.4576271 48.1694915 23.2542373 12.4576271 23.2542373"
}), React.createElement("polygon", {
  id: "Rectangle",
  transform: "translate(5.398305, 24.084746) rotate(-90.000000) translate(-5.398305, -24.084746) ",
  points: "-6.22881356 18.6864407 17.0254237 18.6864407 17.0254237 29.4830508 -6.22881356 29.4830508"
}), React.createElement("rect", {
  id: "Rectangle",
  x: 4.09272616e-12,
  y: 12.4576271,
  width: 23.2542373,
  height: 10.7966102
}), React.createElement("polygon", {
  id: "Rectangle",
  transform: "translate(42.771186, 11.627119) rotate(-90.000000) translate(-42.771186, -11.627119) ",
  points: "31.1440678 6.22881356 54.3983051 6.22881356 54.3983051 17.0254237 31.1440678 17.0254237"
}), React.createElement("rect", {
  id: "Rectangle",
  x: 37.3728814,
  y: 12.4576271,
  width: 35.7118644,
  height: 10.7966102
}), React.createElement("rect", {
  id: "Rectangle",
  transform: "translate(17.855932, 86.372881) scale(1, -1) rotate(-90.000000) translate(-17.855932, -86.372881) ",
  x: 6.22881356,
  y: 80.9745763,
  width: 23.2542373,
  height: 10.7966102
}), React.createElement("rect", {
  id: "Rectangle",
  transform: "translate(30.313559, 80.144068) scale(1, -1) translate(-30.313559, -80.144068) ",
  x: 12.4576271,
  y: 74.7457627,
  width: 35.7118644,
  height: 10.7966102
}), React.createElement("polygon", {
  id: "Rectangle",
  transform: "translate(5.398305, 98.830508) scale(1, -1) rotate(-90.000000) translate(-5.398305, -98.830508) ",
  points: "-6.22881356 93.4322034 17.0254237 93.4322034 17.0254237 104.228814 -6.22881356 104.228814"
}), React.createElement("polygon", {
  id: "Rectangle",
  transform: "translate(30.313559, 98.830508) scale(1, -1) rotate(-90.000000) translate(-30.313559, -98.830508) ",
  points: "18.6864407 93.4322034 41.940678 93.4322034 41.940678 104.228814 18.6864407 104.228814"
}), React.createElement("rect", {
  id: "Rectangle",
  transform: "translate(17.855932, 105.059322) scale(1, -1) translate(-17.855932, -105.059322) ",
  x: 6.59383659e-12,
  y: 99.6610169,
  width: 35.7118644,
  height: 10.7966102
}), React.createElement("polygon", {
  id: "Rectangle",
  transform: "translate(5.398305, 129.974576) scale(1, -1) rotate(-90.000000) translate(-5.398305, -129.974576) ",
  points: "-12.4576271 124.576271 23.2542373 124.576271 23.2542373 135.372881 -12.4576271 135.372881"
}), React.createElement("rect", {
  id: "Rectangle",
  transform: "translate(11.627119, 117.516949) scale(1, -1) translate(-11.627119, -117.516949) ",
  x: 5.91171556e-12,
  y: 112.118644,
  width: 23.2542373,
  height: 10.7966102
}), React.createElement("polygon", {
  id: "Rectangle",
  transform: "translate(30.313559, 129.974576) scale(1, -1) rotate(-90.000000) translate(-30.313559, -129.974576) ",
  points: "12.4576271 124.576271 48.1694915 124.576271 48.1694915 135.372881 12.4576271 135.372881"
}), React.createElement("rect", {
  id: "Rectangle",
  transform: "translate(24.084746, 129.974576) scale(1, -1) translate(-24.084746, -129.974576) ",
  x: 12.4576271,
  y: 124.576271,
  width: 23.2542373,
  height: 10.7966102
}), React.createElement("polygon", {
  id: "Rectangle",
  transform: "translate(55.228814, 86.372881) scale(1, -1) rotate(-90.000000) translate(-55.228814, -86.372881) ",
  points: "43.6016949 80.9745763 66.8559322 80.9745763 66.8559322 91.7711864 43.6016949 91.7711864"
}), React.createElement("rect", {
  id: "Rectangle",
  transform: "translate(55.228814, 92.601695) scale(1, -1) translate(-55.228814, -92.601695) ",
  x: 37.3728814,
  y: 87.2033898,
  width: 35.7118644,
  height: 10.7966102
}), React.createElement("polygon", {
  id: "Rectangle",
  transform: "translate(80.144068, 11.627119) rotate(-90.000000) translate(-80.144068, -11.627119) ",
  points: "68.5169492 6.22881356 91.7711864 6.22881356 91.7711864 17.0254237 68.5169492 17.0254237"
}), React.createElement("polygon", {
  id: "Rectangle",
  transform: "translate(92.601695, 24.084746) rotate(-90.000000) translate(-92.601695, -24.084746) ",
  points: "80.9745763 18.6864407 104.228814 18.6864407 104.228814 29.4830508 80.9745763 29.4830508"
}), React.createElement("rect", {
  id: "Rectangle",
  x: 74.7457627,
  y: 12.4576271,
  width: 23.2542373,
  height: 10.7966102
}), React.createElement("rect", {
  id: "Rectangle",
  x: 49.8305085,
  y: 0,
  width: 23.2542373,
  height: 10.7966102
}), React.createElement("rect", {
  id: "Rectangle",
  x: 87.2033898,
  y: 0,
  width: 10.7966102,
  height: 10.7966102
}), React.createElement("polygon", {
  id: "Rectangle",
  transform: "translate(17.855932, 36.542373) rotate(-90.000000) translate(-17.855932, -36.542373) ",
  points: "6.22881356 31.1440678 29.4830508 31.1440678 29.4830508 41.940678 6.22881356 41.940678"
}), React.createElement("polygon", {
  id: "Rectangle",
  transform: "translate(30.313559, 49.000000) rotate(-90.000000) translate(-30.313559, -49.000000) ",
  points: "18.6864407 43.6016949 41.940678 43.6016949 41.940678 54.3983051 18.6864407 54.3983051"
}), React.createElement("rect", {
  id: "Rectangle",
  x: 12.4576271,
  y: 37.3728814,
  width: 23.2542373,
  height: 10.7966102
}), React.createElement("polygon", {
  id: "Rectangle",
  transform: "translate(17.855932, 61.457627) rotate(-90.000000) translate(-17.855932, -61.457627) ",
  points: "6.22881356 56.059322 29.4830508 56.059322 29.4830508 66.8559322 6.22881356 66.8559322"
}), React.createElement("rect", {
  id: "Rectangle",
  x: 12.4576271,
  y: 62.2881356,
  width: 23.2542373,
  height: 10.7966102
}), React.createElement("polygon", {
  id: "Rectangle",
  transform: "translate(67.686441, 73.915254) rotate(-90.000000) translate(-67.686441, -73.915254) ",
  points: "56.059322 68.5169492 79.3135593 68.5169492 79.3135593 79.3135593 56.059322 79.3135593"
}), React.createElement("rect", {
  id: "Rectangle",
  x: 37.3728814,
  y: 62.2881356,
  width: 34.8813559,
  height: 10.7966102
}), React.createElement("polygon", {
  id: "Rectangle",
  transform: "translate(5.398305, 61.457627) rotate(-90.000000) translate(-5.398305, -61.457627) ",
  points: "-18.6864407 56.059322 29.4830508 56.059322 29.4830508 66.8559322 -18.6864407 66.8559322"
}))));

var SvgFylamyntLogo = function SvgFylamyntLogo(_ref) {
  var svgRef = _ref.svgRef,
      title = _ref.title,
      props = _objectWithoutProperties(_ref, ["svgRef", "title"]);

  return React.createElement("svg", _extends({
    width: "99px",
    height: "149px",
    viewBox: "0 0 99 149",
    ref: svgRef
  }, props), title === undefined ? _ref2 : title ? React.createElement("title", null, title) : null, _ref3, _ref4);
};

var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(SvgFylamyntLogo, _extends({
    svgRef: ref
  }, props));
});
export default __webpack_public_path__ + "static/media/fylamynt-logo.cca0255b.svg";
export { ForwardRef as ReactComponent };