import React from "react";
import SettingPanelTile from "./settings-panel-tile";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  toggleConfigPanel,
  setCurrentNode,
  updateCurrentNodeDetails,
  fetchSettingsConnectors,
  isFetching,
} from "@redux/actions/settingsPanel.action";
import { fetchDefaultAccounts } from "@redux/actions/getStarted.actions";
import { SettingsPanelHeader } from "./../settings-panel-components";
import { isEmpty, capitalizeFirstLetter, kebabToCamel } from "@lib/utils";
import { Wait, Modal, Button } from "@components/ui";
import { Redirect } from "react-router";
import Api from "@lib/api/api";
import CloudWarningCard from "@components/ui/WarningSign/CloudWarningCard";
import { ScrollTabCollection, ScrollTab } from "@components/ui/scrolltabs";
import { RouteConstants } from "../../../routes/Constants";

class SettingsPanelContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cloudConfigNodes: [],
      integrationConfigNodes: [],
      resourceConfigNodes: [],
      formattedData: [],
      doRedirect: false,
      redirectUrl: "",
      showConfirmModal: false,
      confirmModalResponse: "",
      selectedNode: {},
    };
  }
  async componentDidMount() {
    try {
      this.loadConnectors();
    } catch (error) {
      console.log(error);
    }
  }

  loadConnectors = async () => {
    this.props.fetchDefaultAccounts();
    await this.props.fetchSettingsConnectors();
    let formattedData = this.formatData(this.props.connectors) || [];

    formattedData.unshift({
      name: "API-Keys",
      status: "api-key",
      displayName: "API Keys",
    });
    let cloudNodes = formattedData.filter(data => ["AWS"].includes(data.name));
    let integrationNodes = formattedData.filter(
      data =>
        !["AWS", "API-Keys", "Git", "Ssh", "S3", "Cloudformation", "Cloudwatch"].includes(
          data.name,
        ),
    );
    let resourceNodes = formattedData.filter(data =>
      ["API-Keys", "Git", "Ssh", "S3", "Cloudformation", "Cloudwatch"].includes(data.name),
    );

    let cloudConfigNodes = this.getSettingsConfigNodes(cloudNodes);
    let integrationConfigNodes = this.getSettingsConfigNodes(integrationNodes);
    let resourceConfigNodes = this.getSettingsConfigNodes(resourceNodes);
    this.setState({
      cloudConfigNodes,
      integrationConfigNodes,
      resourceConfigNodes,
      formattedData,
    });
  };

  getFooter = () => {
    return (
      <div className="modal-buttons-footer">
        <Button
          text="Cancel"
          buttonStyle="secondary"
          style={{ width: "50%" }}
          onClick={this.toggleConfirmModal}
          type="button"
        />
        <Button
          text="Confirm"
          buttonStyle="warning"
          style={{ width: "50%" }}
          size="large"
          onClick={async e => {
            this.props.isFetching(true, "Removing Integraion");
            const connectorName = this.state.selectedNode.name.toLowerCase();
            let response;
            if (connectorName === "terraform_cli") {
              response = await Api.removeTerraformCLIIntegration(
                connectorName,
              ).catch(e => console.log(e));
            } else {
              response = await Api.removeIntegration(connectorName).catch(e =>
                console.log(e),
              );
            }
            this.props.isFetching(false, "");
            if (
              !isEmpty(response) &&
              (response.statusCode === 200 || response.statusCode === 201)
            ) {
              this.toggleConfirmModal();
              this.loadConnectors();
            } else {
              this.setState({
                confirmModalResponse: "Oops! Something went wrong !!!",
              });
            }
          }}
        />
      </div>
    );
  };

  getCloudNodesStatus = connectorName => {
    if (connectorName === "AWS") {
      if (
        this.props.isAccountDetailFetched &&
        !this.props.isFetchingDefaultAccounts
      ) {
        return this.props?.userDefaultAccounts?.length
          ? "Connected"
          : "Not Setup";
      }
    }
  };

  getConnectorProps = (dataObj, val) => {
    let constantName = kebabToCamel(val);
    switch (val.toLowerCase()) {
      case "git":
        return {
          name: capitalizeFirstLetter(val),
          displayName: RouteConstants[constantName].displayName,
          status: "git",
        };

      case "ssh":
        return {
          name: capitalizeFirstLetter(val),
          displayName: RouteConstants[constantName].displayName,
          status: "ssh",
        };

      case "s3":
        return {
          name: capitalizeFirstLetter(val),
          displayName: RouteConstants[constantName].displayName,
          status: "s3",
        };

      case "cloudformation":
        return {
          name: capitalizeFirstLetter(val),
          displayName: RouteConstants[constantName].displayName,
          status: "cloudformation",
        };

      case "cloudwatch":
        return {
          name: capitalizeFirstLetter(val),
          displayName: RouteConstants[val].displayName,
          status: 'cloudwatch',
        }
      default:
        let obj = {
          name: capitalizeFirstLetter(val),
          displayName: RouteConstants[constantName]
            ? RouteConstants[constantName].displayName
            : capitalizeFirstLetter(constantName),
          status: dataObj[val]["is_configured"] ? "Connected" : "Not Setup",
        };
        return obj;
    }
  };

  formatData = dataObj =>
    !isEmpty(dataObj) &&
    [
      {
        name: "AWS",
        status: this.getCloudNodesStatus("AWS"),
        displayName: "AWS",
      },
    ].concat(
      Object.keys(dataObj).map(val => this.getConnectorProps(dataObj, val)),
    );

  handleClick = async event => {
    event.preventDefault();
    const node = this.state.formattedData.find(
      val => val.name === event.currentTarget.title,
    );
    if (node && !this.props.isLoading) {
      switch (node.name.toLowerCase()) {
        case "aws":
          this.setState({
            doRedirect: true,
            redirectUrl: RouteConstants.awsSettings.url,
          });
          break;

        case "git":
          this.setState({
            doRedirect: true,
            redirectUrl: RouteConstants.git.url,
          });
          break;

        case "api-keys":
          this.setState({
            doRedirect: true,
            redirectUrl: RouteConstants.apiKeys.url,
          });
          break;

        case "terraform_cli":
          this.setState({
            doRedirect: true,
            redirectUrl: RouteConstants.editTerraformCLI.url,
          });
          break;

        case "ssh":
          this.setState({
            doRedirect: true,
            redirectUrl: RouteConstants.ssh.url,
          });
          break;

        case "s3":
          this.setState({
            doRedirect: true,
            redirectUrl: RouteConstants.s3.url,
          });
          break;

        case "cloudformation":
          this.setState({
            doRedirect: true,
            redirectUrl: RouteConstants.cloudformation.url,
          });
          break;

        case "cloudwatch":
          this.setState({
            doRedirect: true,
            redirectUrl: RouteConstants.cloudwatch.url,
          });
          break;

        default:
          if (!this.props.showConfigPanel) {
            await this.props.updateCurrentNodeDetails(node);
          }
          this.props.toggleConfigPanel(!this.props.showConfigPanel);
          break;
      }
    }
  };

  toggleConfirmModal = () => {
    this.setState({ showConfirmModal: !this.state.showConfirmModal });
  };

  onRemoveIntegration = (event, nodeName) => {
    event.preventDefault();
    const selectedNode = this.state.formattedData.find(
      val => val.name === nodeName,
    );
    this.setState(
      {
        selectedNode,
        confirmModalResponse: "",
      },
      () => {
        this.toggleConfirmModal();
      },
    );
  };

  getAddIntegrationUrl = (nodeName = "") =>
    nodeName && RouteConstants[kebabToCamel(nodeName?.toLowerCase())]?.url;

  getSettingsConfigNodes = data =>
    !!data.length
      ? data.map((val, index) => (
          <SettingPanelTile
            handleClick={this.handleClick}
            addIntegrationUrl={this.getAddIntegrationUrl(val.name)}
            key={index}
            name={val.name}
            displayName={val.displayName}
            status={val.status}
            handleRemove={this.onRemoveIntegration}
          />
        ))
      : "No Connectors Available";

  render() {
    return (
      <>
        {this.state.doRedirect && <Redirect to={this.state.redirectUrl} push />}
        {this.props.message && <Wait text={this.props.message} />}
        {this.state.showConfirmModal && (
          <Modal
            title="Remove Integration"
            showClose={true}
            onClose={this.toggleConfirmModal}
            footer={this.getFooter()}
          >
            Are you sure you want to remove the integration between{" "}
            <b>{this.state.selectedNode.name}</b> and <b>Fylamynt</b>?
            <br />
            <br />
            <span className="error">{this.state.confirmModalResponse}</span>
          </Modal>
        )}

        <SettingsPanelHeader
          heading="Account Settings"
          breadCrumbText="Settings"
          isBreadCrumb={true}
        />
        <ScrollTabCollection active={`cloudServices`}>
          <ScrollTab
            name={`cloudServices`}
            scrollToId={`cloud-services`}
            title={`Cloud Services`}
          />
          <ScrollTab
            name={`integrations`}
            scrollToId={`integrations`}
            title={`Integrations`}
          />
          <ScrollTab
            name={`resources`}
            scrollToId={`resources`}
            title={`Resources`}
          />
        </ScrollTabCollection>

        <div id={`cloud-services`}>
          <SettingsPanelHeader isBreadCrumb={false} heading="Cloud Services" />
          {this.props.isAccountDetailFetched &&
          !this.props.userDefaultAccounts.length ? (
            <CloudWarningCard />
          ) : (
            <>
              <div className="account-id">
                AWS Account ID : {this.props.accountId}
              </div>
              <div className="mt-40 ml-15 mb-50">
                {this.props.responseMessage ? (
                  this.props.responseMessage
                ) : (
                  <>
                    <div className="connectors-wrap">
                      {this.state.cloudConfigNodes}
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>
        <div id={`integrations`}>
          <SettingsPanelHeader isBreadCrumb={false} heading="Integrations" />
          <div className="mt-40 ml-15 mb-50">
            <div className="connectors-wrap">
              {this.state.integrationConfigNodes}
            </div>
          </div>
        </div>
        <div id={`resources`}>
          <SettingsPanelHeader isBreadCrumb={false} heading="Resources" />
          <div className="mt-40 ml-15">
            <div className="connectors-wrap">
              {this.state.resourceConfigNodes}
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = state => ({
  showConfigPanel: state.settingsPanelReducer.showConfigPanel,
  isLoading: state.settingsPanelReducer.formSaving,
  connectors: state.settingsPanelReducer.connectors,
  responseMessage: state.settingsPanelReducer.responseMessage,
  accountId: state.appReducer.appConfig.awsAccountId,
  message: state.runbooksReducer.message,
  isAccountDetailFetched: state.getStartedReducer.isAccountDetailFetched,
  userDefaultAccounts: state.getStartedReducer.userDefaultAccounts,
  isFetchingDefaultAccounts: state.getStartedReducer.isFetchingDefaultAccounts,
});
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      toggleConfigPanel,
      setCurrentNode,
      updateCurrentNodeDetails,
      fetchSettingsConnectors,
      isFetching,
      fetchDefaultAccounts,
    },
    dispatch,
  );
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SettingsPanelContent);
