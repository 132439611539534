import React from "react";
import getInstructions from "./profile-instructions-helper";

const LeftPanel = () => {
  const mainHeading = "Twilio Integration";
  const instructions = getInstructions();

  return (
    <>
      <div className="instructions__left__margin-top">
        <div className="instructions__title">
          <h1 className="page-title">{mainHeading}</h1>
        </div>
        <div className="onboarding-panel">
          <h3 className="h2-margin">
            Instructions to setup Twilio Integration
          </h3>
          Follow below steps on your Twilio Account and complete the Steps
          {instructions}
        </div>
      </div>
    </>
  );
};

export default LeftPanel;
