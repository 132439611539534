import React from "react";

export default props => (
  <div className="runbook-item__meta">
    <h2 className="runbook-item__title">
      <span>
        {props.runbook.Name}
      </span>
    </h2>
    <div className="runbook-item__description">{props.runbook.Description}</div>
    <div className="fill-text">
      Sed ut perspiciatis unde omnis iste natus error
    </div>
  </div>
);
