import {
  lowerCaseRegex,
  numericRegex,
  specialCharRegex,
  upperCaseRegex,
} from "@lib/utils/constants";
import Auth from "@aws-amplify/auth";
import Bugsnag from '@bugsnag/js'

export const setCustomIdentityId = async () => {
  let promises = [
    Auth.currentCredentials(),
    Auth.currentAuthenticatedUser(),
    Auth.currentUserInfo(),
  ];
  const response = await Promise.all(promises);
  const [credentials, userAuth, userAttributes] = response;

  if (!userAttributes.attributes["custom:identityid"]) {
    await Auth.updateUserAttributes(userAuth, {
      "custom:identityid": credentials.identityId,
    });
  }
};

export const keyUpHandler = (e, submitFn, isSubmitting) => {
  if ((e.key === "Enter" || e.keyCode === 13) && !isSubmitting) {
    submitFn();
  }
};

export const checkCognitoPasswordStrength = password => {
  if ([...password].length < 6 || !password.match(/[A-Z]/)) {
    return 1;
  } else if (
    ([...password].length > 5 &&
      password.match(upperCaseRegex) &&
      password.match(lowerCaseRegex) &&
      password.match(numericRegex) &&
      !password.match(specialCharRegex)) ||
    ([...password].length > 5 &&
      specialCharRegex.test(password) &&
      !password.match(numericRegex)) ||
    ([...password].length > 5 &&
      [...password].length < 8 &&
      password.match(upperCaseRegex) &&
      password.match(lowerCaseRegex) &&
      password.match(numericRegex) &&
      password.match(specialCharRegex))
  ) {
    return 3;
  } else if (
    [...password].length > 7 &&
    password.match(upperCaseRegex) &&
    password.match(lowerCaseRegex) &&
    password.match(numericRegex) &&
    password.match(specialCharRegex)
  ) {
    return 5;
  }
  return 1;
};

export const isPassed = (test, password) => {
  switch (test) {
    case "minLength":
      return password.length > 7 ? true : false;

    case "upperCase":
      return password.match(upperCaseRegex) ? true : false;

    case "lowerCase":
      return password.match(lowerCaseRegex) ? true : false;

    case "number":
      return password.match(numericRegex) ? true : false;

    case "specialCharacter":
      return password.match(specialCharRegex) ? true : false;

    default:
      break;
  }
};

export const trackIdentity = email => {
  // Track user identity for Heap analytics
  // @ts-ignore
  window.heap.identify(email); // Heap analytics user identifier
  // @ts-ignore
  window.heap.addUserProperties({ email });

  // Setup metadata for tracking Bugsnag events against user
  // Usage ref.: Bugsnag.setUser(userId, userEmail)
  // https://docs.bugsnag.com/platforms/javascript/customizing-error-reports/#adding-user-data
  Bugsnag.setUser(email, email)
};
