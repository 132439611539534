import React from "react";
import { HeaderMain } from "@components/modules";
import { Consumer } from "../runbook-details-lib/runbook-details.context";
import RunbookDetailsTags from "./runbook-details-tags";
import { Breadcrumbs } from "@components/ui";
import RunbookDetailHeaderButtons from "./RunbookDetailHeaderButtons";

export default () => (
  <Consumer>
    {({ runbook, setShowConfirm, openDuplicateRunbookModal }) => (
      <React.Fragment>
        <Breadcrumbs text={`Workflows / Workflow Details`} />
        <HeaderMain
          title={runbook?.Name || runbook.Description}
          showBreadcrumbs={false}
        >
          <RunbookDetailHeaderButtons
            runbook={runbook}
            setShowConfirm={setShowConfirm}
            openDuplicateRunbookModal={openDuplicateRunbookModal}
          />
        </HeaderMain>
        <div className="title-wrap mt-5">
          <RunbookDetailsTags tags={runbook.Tags} />
        </div>
      </React.Fragment>
    )}
  </Consumer>
);
