import React from "react";
import { Consumer } from "../../runbook-editor-lib/runbook-editor.context";
import {
  EditorAddSnippets,
  EditorVersions,
  EditorSettings,
} from "./menu-slideouts";
import backIcon from "@assets/images/icons/icon-back.svg";

export default class EditorMenuSlideouts extends React.Component {
  autoClose = false;
  listener = null;

  componentDidUpdate() {
    this.toggleCloseButton();
  }

  setAutoclose = autoClose => {
    this.autoClose = autoClose;
    if (autoClose && !this.listener) {
      this.listener = "closeListener";
      document.body.addEventListener("click", e => this.closeSlideout(e), true);
    } else {
      document.body.removeEventListener(
        "click",
        e => this.closeSlideout(e),
        true,
      );
    }
  };

  toggleCloseButton() {
    const closeElement = document.querySelector(".close-editor-menu-icon");
    if (this.autoClose) {
      if (closeElement) {
        closeElement.style.display = "none";
      }
    } else {
      if (closeElement) {
        closeElement.style.display = "";
      }
    }
  }

  closeSlideout = e => {
    if (!this.autoClose || !this.props.activePanel) {
      return;
    }
    const withinContainer = e.target.closest(
      ".editor-menu-tab-content-container",
    );
    if (withinContainer) {
      return;
    }
    this.props.closePanel();
  };

  componentWillUnmount() {
    document.body.removeEventListener("click", e => this.close(e), false);
  }

  renderTabContent = activePanel => {
    switch (activePanel) {
      case "add":
        this.setAutoclose(false);
        return <EditorAddSnippets />;

      case "versions":
        this.setAutoclose(true);
        return <EditorVersions />;

      case "settings":
        this.setAutoclose(true);
        return (
          <EditorSettings
            updateRunbookDescription={this.props.updateRunbookDescription}
          />
        );

      default:
        return;
    }
  };
  render() {
    return (
      <Consumer>
        {({ activePanel, activePanelTitle }) => (
          <React.Fragment>
            {activePanel && (
              <div
                className="editor-menu-tab-content-container"
                onClick={this.canedlBubble}
              >
                <div className="editor-menu-tab-content ">
                  <div className="editor-menu-tab-content__title">
                    <div className={"editor-menu-title"}>
                      {activePanelTitle}
                      {this.props.actionButton &&
                      activePanel === this.props.actionButton.activePanel ? (
                        <div
                          className="action-button action-button-absolute"
                          onClick={this.props.actionButton.action}
                        >
                          <img src={this.props.actionButton.icon} alt="" />
                        </div>
                      ) : null}
                    </div>
                    <img
                      src={backIcon}
                      alt="backIcon"
                      height="13"
                      width="13"
                      className="pointer"
                      onClick={this.props.closePanel}
                    />
                  </div>
                  {this.renderTabContent(activePanel)}
                </div>
              </div>
            )}
          </React.Fragment>
        )}
      </Consumer>
    );
  }
}

EditorMenuSlideouts.propTypes = {};
