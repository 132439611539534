import React from "react";
import { connect } from "react-redux";
import { NeuropsWebSocket } from "@components/modules";
import Auth from "@aws-amplify/auth";
import { Redirect } from "react-router-dom";
import { loginSuccess, logout } from "@redux/actions/user.actions";
import { fetchDefaultAccounts } from "@redux/actions/getStarted.actions";
import { Logo, MainMenuLeft } from "@components/modules";

class RequireAuthentication extends React.Component {
  state = {
    currentToken: null,
    user: null,
  };
  cognitoKeepaliveInterval = null;
  time = 10;

  componentDidMount = () => {
    this.neuropsWebSocket = new NeuropsWebSocket();
    this.startCognitoKeepaliveInterval(this.time);

    if (
      this.props.isLoggedIn &&
      !this.props.isAccountDetailFetched &&
      !this.props.userDefaultAccounts.length
    ) {
      this.props.fetchDefaultAccounts();
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentToken !== this.state.currentToken) {
      this.props.loginSuccess(this.state.user);
    }
  }

  startCognitoKeepaliveInterval(minutes) {
    if (this.cognitoKeepaliveInterval)
      clearInterval(this.cognitoKeepaliveInterval);

    this.cognitoKeepaliveInterval = setInterval(async () => {
      try {
        const session = await Auth.currentSession();
        const user = await Auth.currentAuthenticatedUser();
        user.refreshSession(session.getRefreshToken(), () => {
          this.setState({
            currentToken: user.signInUserSession.idToken.jwtToken,
            user: user,
          });
        });
      } catch (e) {
        console.log(e);
      }
    }, minutes * 60 * 1000);
  }

  render() {
    if (this.props.isLoggedIn) {
      if (this.neuropsWebSocket) this.neuropsWebSocket.connect();
      const location =
        this.props.location?.pathname?.toLowerCase() || "/workflows";
      if (
        location?.includes("workflow/editor/") ||
        (location?.includes("/get-started") &&
          !location?.includes("/settings/get-started"))
      ) {
        return this.props.children;
      } else {
        return (
          <div className="main">
            <div className="main-left">
              <Logo />
              <MainMenuLeft />
            </div>
            <div className="main-right">{this.props.children}</div>
          </div>
        );
      }
    } else {
      return <Redirect push to="/" />;
    }
  }
}

export const mapState = ({ userReducer, getStartedReducer }) => {
  return {
    user: userReducer.user,
    isLoggedIn: userReducer.isLoggedIn || false,
    userDefaultAccounts: getStartedReducer.userDefaultAccounts,
    isAccountDetailFetched: getStartedReducer.isAccountDetailFetched,
  };
};
export const mapDispatch = dispatch => ({
  loginSuccess: data => dispatch(loginSuccess(data)),
  logout: () => dispatch(logout()),
  fetchDefaultAccounts: () => dispatch(fetchDefaultAccounts()),
});

export default connect(mapState, mapDispatch)(RequireAuthentication);
