import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchInstanaEntityTypes,
  fetchInstanaEntityNames,
} from "@redux/actions/snippets.actions";

import {
  Accordion,
  AccordionLabel,
  AccordionSection,
  Select,
  SelectOption,
  LabelTop,
} from "@components/ui";

export function InstanaAlertInput(props) {
  const dispatch = useDispatch();
  const [entityType, setEntityType] = useState(props.runbookNode.entityType);
  const [entityName, setEntityName] = useState(props.runbookNode.entityName);
  const [alertType, setAlertType] = useState(props.runbookNode.alertType);
  const [eventId, setEventId] = useState(props.runbookNode.eventId);
  const alertTypes = ["onOpen", "onClose", "onChange"];
  const { runbookNode, notifyRunbookUpdate, setOutputs } = props;

  const isFetchingInstanaEntityTypes = useSelector(
    state => state.snippetsReducer.isFetchingInstanaEntityTypes,
  );
  const instanaEntityTypes = useSelector(
    state => state.snippetsReducer.instanaEntityTypes,
  );
  const isFetchingInstanaEntityNames = useSelector(
    state => state.snippetsReducer.isFetchingInstanaEntityNames,
  );
  const instanaEntityNames = useSelector(
    state => state.snippetsReducer.instanaEntityNames,
  );

  const selectEntityType = entityType => {
    dispatch(fetchInstanaEntityNames(entityType));
    setEntityType(entityType);
  };

  const selectEntityName = entityName => {
    setEntityName(entityName);
    setEventId(instanaEntityNames[entityName]);
  };

  const selectAlertType = alertType => {
    setAlertType(alertType);
  };

  const isFirstRunA = useRef(true);
  useEffect(() => {
    if (isFirstRunA.current) {
      isFirstRunA.current = false;
      return;
    } else {
      runbookNode.setEntityType(entityType);
      runbookNode.setEntityName(entityName);
      runbookNode.setEventId(eventId);

      if (runbookNode.outputs) {
        setOutputs(runbookNode.outputs);
      }
      notifyRunbookUpdate(true);
    }
  }, [
    eventId,
    entityType,
    entityName,
    notifyRunbookUpdate,
    runbookNode,
    setOutputs,
  ]);

  const isFirstRunB = useRef(true);
  useEffect(() => {
    if (isFirstRunB.current) {
      isFirstRunB.current = false;
      return;
    } else {
      runbookNode && runbookNode.setAlertType(alertType);
      if (runbookNode?.outputs) {
        setOutputs(runbookNode.outputs);
      }
      notifyRunbookUpdate(true);
    }
  }, [alertType, notifyRunbookUpdate, runbookNode, setOutputs]);

  useEffect(() => {
    dispatch(fetchInstanaEntityTypes());
  }, [dispatch]);

  const isFirstRunC = useRef(true);
  useEffect(() => {
    if (isFirstRunC.current) {
      isFirstRunC.current = false;
      dispatch(fetchInstanaEntityNames(entityType));
      return;
    } else {
      setEntityName("");
      setEventId("");
    }
  }, [entityType, dispatch]);

  return (
    <React.Fragment>
      <h4 className="editor-node-name">
        {(runbookNode && runbookNode.name) || "Instana_Alert"}
      </h4>
      <React.Fragment>
        <Accordion isExpanded={true} useArrow={true}>
          <AccordionLabel className="editor-accordion-label margin-top-10">
            Required Inputs
          </AccordionLabel>
          <AccordionSection>
            <div className="editor-detail-panel editor-detail-panel-column">
              <div className="editor-select-container">
                <LabelTop
                  label={`Alert Type`}
                  labelClassName="label"
                ></LabelTop>
                <Select
                  title={alertType || "Select from below"}
                  iconLeft="icon-filter"
                  width="100%"
                  style={{ width: "calc(100% - 8px)" }}
                  onChange={newValue => selectAlertType(newValue)}
                  required={true}
                  value={alertType}
                >
                  {alertTypes.map((alert, i) => (
                    <SelectOption
                      text={alert}
                      value={alert}
                      key={`${alert}`}
                      className="text-small"
                    />
                  ))}
                </Select>
                {!isFetchingInstanaEntityTypes ? (
                  <>
                    <LabelTop
                      label={`Entity Types`}
                      labelClassName="label"
                    ></LabelTop>
                    <Select
                      title={entityType || "Select from below"}
                      iconLeft="icon-filter"
                      width="100%"
                      style={{ width: "calc(100% - 8px)" }}
                      onChange={newValue => selectEntityType(newValue)}
                      required={true}
                      value={entityType}
                    >
                      {instanaEntityTypes?.map(entity => (
                        <SelectOption
                          text={entity}
                          value={entity}
                          key={`${entity}`}
                          className="text-small"
                        />
                      ))}
                    </Select>
                  </>
                ) : (
                  <div className="mb-10">Fetching entity types...</div>
                )}
                {entityType &&
                  (!isFetchingInstanaEntityNames && instanaEntityNames ? (
                    <>
                      <LabelTop
                        label={`Entity Names`}
                        labelClassName="label"
                      ></LabelTop>
                      <Select
                        title={entityName || "Select from below"}
                        iconLeft="icon-filter"
                        width="100%"
                        style={{ width: "calc(100% - 8px)" }}
                        onChange={newValue => selectEntityName(newValue)}
                        required={true}
                        value={entityName}
                      >
                        {instanaEntityNames &&
                          Object.keys(
                            instanaEntityNames,
                          )?.map((entityName, i) => (
                            <SelectOption
                              text={entityName}
                              value={entityName}
                              key={`${entityName}`}
                              className="text-small"
                            />
                          ))}
                      </Select>
                    </>
                  ) : (
                    <div className="mb-10">Fetching entity names...</div>
                  ))}
                <div className="label">
                  <span>Event Id: </span>
                  <span>{eventId}</span>
                </div>
              </div>
            </div>
          </AccordionSection>
        </Accordion>
      </React.Fragment>
    </React.Fragment>
  );
}
