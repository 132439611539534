import React from "react";
import { Consumer } from "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.context";
import { Accordion, AccordionLabel, AccordionSection } from "@components/ui";
import { hasKeys } from "@lib/utils";

export default () => (
  <Consumer>
    {({ activeNode }) => (
      <div>
        <div className="editor-detail-panel-title">
          <div className="editor-detail-panel-icon editor-detail-panel-icon-select" />
          <div>{activeNode.name}</div>
        </div>
        <Accordion isExpanded={true} useArrow={true}>
          <AccordionLabel className="editor-accordion-label">
            Description
          </AccordionLabel>
          <AccordionSection>
            <div className="paragraph">
              {hasKeys(
                activeNode,
                "extras.runbookNode.snippetDef.description",
              ) && activeNode.extras.runbookNode.snippetDef.description}
            </div>
          </AccordionSection>
        </Accordion>
        <Accordion isExpanded={true} useArrow={true}>
          <AccordionLabel className="editor-accordion-label">
            Tags
          </AccordionLabel>
          <AccordionSection>
            <div className="paragraph">
              {hasKeys(activeNode, "extras.runbookNode.snippetDef.tags") &&
                activeNode.extras.runbookNode.snippetDef.tags.map(tag => (
                  <div key={tag}>{tag}</div>
                ))}
            </div>
          </AccordionSection>
        </Accordion>
      </div>
    )}
  </Consumer>
);
