function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React from "react";

var _ref2 = React.createElement("title", null, "icon color");

var _ref3 = React.createElement("desc", null, "Created with Sketch.");

var _ref4 = React.createElement("defs", null, React.createElement("path", {
  d: "M7,0 C3.15,0 0,3.15 0,7 C0,10.85 3.15,14 7,14 C10.85,14 14,10.85 14,7 C14,3.15 10.85,0 7,0 Z M7,13 C3.7,13 1,10.3 1,7 C1,3.7 3.7,1 7,1 C10.3,1 13,3.7 13,7 C13,10.3 10.3,13 7,13 Z M7,11 C7.41421356,11 7.75,10.6642136 7.75,10.25 C7.75,9.83578644 7.41421356,9.5 7,9.5 C6.58578644,9.5 6.25,9.83578644 6.25,10.25 C6.25,10.6642136 6.58578644,11 7,11 Z M7.5,3 L6.75,3 C5.5,3 4.5,4 4.5,5.25 L4.5,5.5 L5.5,5.5 L5.5,5.25 C5.5,4.55 6.05,4 6.75,4 L7.5,4 C8.2,4 8.75,4.55 8.75,5.25 C8.75,5.95 8.2,6.5 7.5,6.5 L6.5,6.5 L6.5,8.5 L7.5,8.5 L7.5,7.5 C8.75,7.5 9.75,6.5 9.75,5.25 C9.75,4 8.75,3 7.5,3 Z",
  id: "path-help"
}));

var _ref5 = React.createElement("g", {
  id: "Page-1",
  stroke: "none",
  strokeWidth: 1,
  fill: "none",
  fillRule: "evenodd"
}, React.createElement("g", {
  id: "Artboard",
  transform: "translate(-440.000000, -201.000000)"
}, React.createElement("g", {
  id: "icon/status/help/16",
  transform: "translate(440.000000, 201.000000)"
}, React.createElement("mask", {
  id: "mask-2",
  fill: "white"
}, React.createElement("use", {
  xlinkHref: "#path-help"
})), React.createElement("use", {
  id: "icon-color",
  fill: "#F3F3F3",
  xlinkHref: "#path-help"
}))));

var SvgIconHelp = function SvgIconHelp(_ref) {
  var svgRef = _ref.svgRef,
      title = _ref.title,
      props = _objectWithoutProperties(_ref, ["svgRef", "title"]);

  return React.createElement("svg", _extends({
    width: "14px",
    height: "14px",
    viewBox: "0 0 14 14",
    ref: svgRef
  }, props), title === undefined ? _ref2 : title ? React.createElement("title", null, title) : null, _ref3, _ref4, _ref5);
};

var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(SvgIconHelp, _extends({
    svgRef: ref
  }, props));
});
export default __webpack_public_path__ + "static/media/icon-help.a89b33fe.svg";
export { ForwardRef as ReactComponent };