import React from "react";
import { OverflowMenu } from "@components/ui";
import { Consumer } from "@containers/Runbooks/runbooks-lib/runbooks.context";

const duplicate = (props, callBack) => {
  callBack(props.runbook);
};

const deleteItem = (props, callBack) => {
  callBack(props.runbook);
};

export default props => (
  <Consumer>
    {({ setShowConfirm, openDuplicateRunbookModal }) => (
      <div className="runbook-item__overflow-menu">
        <OverflowMenu>
          <div
            onMouseDown={e => duplicate(props, openDuplicateRunbookModal)}
            className="button-grey"
          >
            Duplicate
          </div>
          {!props.runbook?.Tags?.toString().includes("release:") && (
            <div
              onMouseDown={e => deleteItem(props, setShowConfirm)}
              className="button-delete"
            >
              Delete
            </div>
          )}
        </OverflowMenu>
      </div>
    )}
  </Consumer>
);
