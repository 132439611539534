import React from "react";

export default props => (
  <div style={props.style}>
    <div style={{ marginBottom: props.noMargin ? 0 : 0 }}>
      <label className={`label-top ${props.labelClassName}`}>
        {props.label}
      </label>
    </div>
    {props.children}
  </div>
);
