import { connect } from "react-redux";
import RunbookDetails from "../RunbookDetails";
import {
  fetchActiveVersion,
  fetchActiveRunbook,
  onChangeVersionAction,
  getResourcesList,
  updateDefaultVersion,
  deleteRunbook,
  deleteRunbookVersion,
  unsetDuplicateRunbook,
} from "@redux/actions/runbook.action";

import { fetchRunbooks } from "@redux/actions/runbooks.actions";
import { SHOW_CONFIRM } from "@redux/types";

import { doExecution } from "@redux/actions/execution.action";
import { fetchAccountPlanInfo } from "@redux/actions/accountPlan.actions";
import { fetchSettingsConnectors } from "@redux/actions/settingsPanel.action";

const mapState = ({
  runbookReducer,
  executionsReducer,
  runbooksReducer,
  uiReducer,
  getStartedReducer,
  accountPlanReducer,
  settingsPanelReducer,
}) => {
  return {
    activeRunbook: runbookReducer.activeRunbook,
    activeRunbookVersion: runbookReducer.activeRunbookVersion,
    isFetchingVersion: runbookReducer.isFetchingVersion,
    isFetching: runbookReducer.isFetching,
    runbookNew: runbookReducer.runbookNew,
    executionId: executionsReducer.executionId || "",
    runbookFetchError: runbooksReducer.runbookFetchError,
    resourcesList: runbookReducer.resourcesList,
    showConfirm: uiReducer.showConfirm,
    deletingMessage: runbookReducer.message,
    isRunbookDeleted: runbooksReducer.isRunbookDeleted,
    isDeleting: runbookReducer.isDeleting,
    isVersionUpdateSuccess: runbookReducer.isVersionUpdateSuccess,
    isVersionUpdateFailed: runbookReducer.isVersionUpdateFailed,
    runbookVersionDeleteStatus: runbookReducer.runbookVersionDeleteStatus,
    runbookDuplicate: runbookReducer.runbookDuplicate,
    runbookList: runbooksReducer.runbookList,
    runbookSchedule: runbookReducer.runbookSchedule,
    isAccountDetailFetched: getStartedReducer.isAccountDetailFetched,
    userDefaultAccounts: getStartedReducer.userDefaultAccounts,
    quotas: accountPlanReducer.quotas,
    message: runbooksReducer.message,
    showLoading: runbooksReducer.showLoading,
    connectors: settingsPanelReducer.connectors,
  };
};

const mapDispatch = dispatch => ({
  setActiveVersion: version => dispatch(fetchActiveVersion(version)),
  fetchActiveRunbook: (runbookId, version) =>
    dispatch(fetchActiveRunbook(runbookId, version)),
  onChangeVersionAction: value => {
    dispatch(onChangeVersionAction(value));
  },
  doExecution: (runbookName, parameters, version) => {
    dispatch(doExecution(runbookName, parameters, version));
  },
  getResourcesList: () => {
    dispatch(getResourcesList());
  },
  updateDefaultVersion: (name, version) => {
    dispatch(updateDefaultVersion(name, version));
  },
  setShowConfirm: show =>
    dispatch({
      type: SHOW_CONFIRM,
      payload: show,
    }),
  deleteRunbook: (runbook, owner) => dispatch(deleteRunbook(runbook, owner)),
  deleteRunbookVersion: (name, version) => {
    dispatch(deleteRunbookVersion(name, version));
  },
  unsetDuplicateRunbook: () => dispatch(unsetDuplicateRunbook()),
  fetchRunbooks: owner => dispatch(fetchRunbooks(owner)),
  fetchAccountPlanInfo: () => dispatch(fetchAccountPlanInfo()),
  fetchSettingsConnectors: () => dispatch(fetchSettingsConnectors()),
});

export default connect(mapState, mapDispatch)(RunbookDetails);
