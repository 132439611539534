import React from "react";
import { Accordion, AccordionLabel, AccordionSection } from "@components/ui";
import StepTable from "./step-table";

const getContainerCss = index => {
  let retVal = "stepper-wrap";
  if (index > 1) {
    retVal += " stepper-child";
  }
  return retVal;
};
export default props => (
  <div className={getContainerCss(props.stepNumber)}>
    <div className="stepper-content">
      <div className="stepper-circle">{props.stepNumber}</div>
      <div className="stepper-table-container">
        <Accordion isExpanded={true} useArrow={true} arrowSide="left">
          <AccordionLabel className="stepper-accordion-label">
            {props.step.StepName}
          </AccordionLabel>
          <AccordionSection>
            <StepTable step={props.step} stepNumber={props.stepNumber} />
          </AccordionSection>
        </Accordion>
      </div>
    </div>
  </div>
);
