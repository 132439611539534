import React from "react";
import PropTypes from "prop-types";
import * as SelectLib from "../lib/select.lib";
import * as SelectLifecycles from "../lib/select-option.lifecycles";
import * as SelectEvents from "../lib/select-option.events";
import SelectFound from "./SelectFound";
import SelectNotFound from "./SelectNotFound";

export default class SelectOption extends React.PureComponent {
  constructor(props) {
    super(props);
    SelectLifecycles.init.call(this);
  }

  componentDidMount() {
    SelectLifecycles.didMount.call(this);
  }

  componentDidUpdate(prevProps) {
    SelectLifecycles.didUpdate.call(this, prevProps);
  }

  /* if you click in an option, we set the value here so that we can update the SelectSelected value with this */
  onClick = e => {
    e.preventDefault();
    SelectEvents.onClick.call(this, e);
  };

  /* this actually gets the value that is in the option value prop */
  getValue = () => {
    return SelectEvents.getValue.call(this);
  };

  render() {
    let value = this.getValue();
    let inlineStyle = SelectLib.getInlineStyle.call(this);
    return (
      <React.Fragment>
        {value !== this.props.selectedValue && (
          <li
            onClick={this.onClick}
            className={`${this.props.className} ${this.state.foundClassname} select-option-default`}
            style={inlineStyle}
            ref={this.ref}
            data-value={value}
            data-text={this.props.text || this.props.children}
          >
            {this.state.foundElement ? (
              <SelectFound
                onClick={this.onClick}
                foundClassName={this.state.foundClassname}
                nonHighlightedText1={this.state.nonHighlightedText1}
                nonHighlightedText2={this.state.nonHighlightedText2}
                highlightedText={this.state.highlightedText}
              />
            ) : (
              <SelectNotFound text={this.state.nonHighlightedText1} />
            )}
          </li>
        )}
      </React.Fragment>
    );
  }
}

SelectOption.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  text: PropTypes.string,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

SelectOption.defaultProps = {
  className: "",
};
