import React from "react";
import { Consumer } from "../execution-run-details-lib/execution-run-details.context";
import { Link } from "react-router-dom";
import { HeaderMain } from "@components/modules";
import { StatusBlock } from "@components/modules";
import { RouteConstants } from "../../../routes/Constants";

export default () => (
  <Consumer>
    {({ executionRunDetails, executionDetails }) => (
      <React.Fragment>
        {/* Added `PersistFilter` to maintain search results on Executions page 
          when user clicks on breadcrumb */}
        <div className="d-flex align-items-baseline ml-15">
          <Link
            to={{
              pathname: RouteConstants.executions.url,
              state: { persistFilter: true },
            }}
            className="breadcrumb-link"
          >
            Executions
          </Link>
          <span className="ml-5 mr-5">/</span>
          <Link
            to={`${RouteConstants.executionDetails.url}/${executionRunDetails.executionId}`}
            className={"breadcrumb-link"}
          >{`Execution ID: ${executionRunDetails.executionId}`}</Link>
        </div>
        <HeaderMain
          title={`Step ${executionRunDetails.stepNumber}: ${executionRunDetails.StepName}`}
          showBreadcrumbs={false}
        />
        <StatusBlock
          status={
            executionDetails?.AutomationExecutionStatus === "Cancelled" &&
            executionRunDetails.StepStatus === "InProgress"
              ? "Cancelled"
              : executionRunDetails.StepStatus
          }
          className={`ml-15 mt-5`}
        />
      </React.Fragment>
    )}
  </Consumer>
);
