import React from "react";

const InstanaAlertChannels = require("@assets/images/screenshots/settings-instana/Instana-alert-channels.png");
const InstanaCreateWebhook = require("@assets/images/screenshots/settings-instana/Instana-create-webhook.png");
const InstanaSettings = require("@assets/images/screenshots/settings-instana/Instana-settings.png");
const InstanaWebhook = require("@assets/images/screenshots/settings-instana/Instana-webhook.png");
const InstanaAPIToken = require("@assets/images/screenshots/settings-instana/Instana_api_token.png");

export default () => (
  <>
    <h3 className="h2-margin">Instructions to setup Instana Integration</h3>
    Follow below steps on your Instana Account and complete the Steps
    <div className="list-row pt-10">
      <div className="step-number">
        <div>1</div>
      </div>
      <div>
        Login to your Instana Account. Copy the URL of your Instana site into
        the <b>Instana URL</b> field under <b>Authorize Fylamynt</b>.
      </div>
    </div>
    <div className="list-image">
      <img src={InstanaSettings} alt="InstanaSettings" />
    </div>
    <div className="list-row">
      <div className="step-number">
        <div>2</div>
      </div>
      <div>
        Go to <b>Settings</b> --{`>`} <b>Team Settings</b> --{`>`}{" "}
        <b>ACCESS CONTROL</b> --{`>`} <b>API Tokens</b>. Click 'Add API Token'
        to create a new token (or) select an existing API Token. Copy the token
        into the <b>Instana API Token</b> field under <b>Authorize Fylamynt</b>.
      </div>
    </div>
    <div className="list-image">
      <img src={InstanaAPIToken} alt="InstanaAPIToken" />
    </div>
    <div className="list-row">
      <div className="step-number">
        <div>3</div>
      </div>
      <div>
        Go to <b>Settings</b> --{`>`}
        <b> Team Settings</b> --{`>`} <b>EVENTS & ALERTS</b> --{`>`}
        <b> Alert Channels</b>
      </div>
    </div>
    <div className="list-image">
      <img src={InstanaAlertChannels} alt="InstanaAlertChannels" />
    </div>
    <div className="list-row">
      <div className="step-number">
        <div>4</div>
      </div>
      <div>
        In <b>Alert Channels</b> click on <b>Add Alert Channel </b>
        and Select <b>Webhook</b>
      </div>
    </div>
    <div className="list-image">
      <img src={InstanaWebhook} alt="InstanaWebhook" />
    </div>
    <div className="list-row">
      <div className="step-number">
        <div>5</div>
      </div>
      <div>
        Add below details to <b>Create Webhook Alert Channel</b>
      </div>
    </div>
    <div className="list-image">
      <div className="pt-15">
        - Copy <b>Instana Webhook Name</b> from <b>Authorize Fylamynt</b> as
        Instana's WebHook Alert Channel Name<br></br> - Copy{" "}
        <b>Instana Webhook URL</b> from <b>Authorize Fylamynt</b> as Instana's
        WebHook URLs
      </div>

      <div className="pt-15">
        - Under <b>Authorize Fylamynt</b>, choose one of the{" "}
        <b>Webhook API Key Name</b> from the dropdown, and the corresponding{" "}
        <b>Webhook API Key Value</b> should be shown. If no options are
        available for the Webhook API Key Name, first go to Settings {`-->`} API
        Keys {`-->`} Manage Keys to Create a New API Key.
      </div>
      <div className="pt-15">
        - Under <b>Custom HTTP Request Headers</b>, add the following two
        key-value pairs: <br />
        1. Add "x-api-key" as Key, and copy-paste the{" "}
        <b>Webhook API Key Value</b> as Value <br />
        2. Add "fylamynt-external-id" as Key, and copy-paste the{" "}
        <b>External ID</b> as Value.
      </div>

      <img
        src={InstanaCreateWebhook}
        alt="InstanaCreateWebhook"
        className="pt-10"
      />
    </div>
    <div className="list-row pt-10">
      <div className="step-number">
        <div>6</div>
      </div>
      <div>
        Before saving the Alert Channel details click on <b>Test Channel</b> and{" "}
        <b>Save</b> the Alert Channel
      </div>
    </div>
    <div className="list-row pt-10">
      <div className="step-number">
        <div>7</div>
      </div>
      <div>
        Under <b>Authorize Fylamynt</b>, check to see if all the attributes are
        filled correctly. Click on <b>Authorize</b> to finish Instana
        integration with Fylamynt.
      </div>
    </div>
  </>
);
