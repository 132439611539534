import React from "react";
import { Consumer } from "../runbook-details-lib/runbook-details.context";
import DetailLine from "./runbook-details-block-line";
import { getFriendlyDate } from "@lib/utils";

const defaultRunbook = {
  Description: "",
  PlatformTypes: [],
  CreatedDate: "n/a",
  Hash: "",
  HashType: "",
  Versions: [],
};

export default () => {
  return (
    <Consumer>
      {({ runbook }) => {
        const updatedDefaultVersion = runbook?.Versions?.find(
          v => v.IsDefaultVersion,
        );
        runbook = Object.assign({}, defaultRunbook, runbook);
        return (
          <div className="margin-top-20 pl-15">
            <h4>Details</h4>
            <div className="details__block-wrap">
              <div className="details-block">
                <DetailLine title={"Description"} text={runbook.Description} />
                <DetailLine
                  title={"Created"}
                  text={getFriendlyDate(runbook.CreatedDate)}
                />
                <DetailLine
                  title={"Versions"}
                  text={runbook.Versions?.length}
                />
              </div>
              <div className="details-block">
                <DetailLine
                  title={"DefaultVersion"}
                  text={updatedDefaultVersion?.DocumentVersion}
                />
                <DetailLine
                  title={"LatestVersion"}
                  text={runbook.LatestVersion}
                />
              </div>
            </div>
          </div>
        );
      }}
    </Consumer>
  );
};
