import React from "react";
import * as SelectLib from "./select.lib";

export function init() {
  this.state = {
    display: "contracted",
    selectedText: "",
    toggled: false,
    style: {},
    width: this.props.width,
    searchValue: null,
    searchSuccess: false,
    scrollToText: null,
    totalHeight: 0,
    value: null,
    text: null,
    parent: null,
    showNoValueAlert: false,
  };

  this.ref = React.createRef();
  this.scrollToText = null;
  this.scrollToResult = SelectLib.scrollToResult.bind(this);
  this.setStyle = SelectLib.setStyle.bind(this);
  this.initChildren = SelectLib.initChildren.bind(this);
  this.calculateHeight = SelectLib.calculateHeight.bind(this);
  this.removeFoundFormatting = SelectLib.removeFoundFormatting.bind(this);
  this.titleBarRef = React.createRef();
  this.ulRef = React.createRef();
}

export function didMount() {
  window.addEventListener("click", this.close, false);
  this.setState(
    {
      parent: this.ref.current,
    },
    () => {
      SelectLib.setZIndex();
    },
  );
  this.setStyle();
}

export function update(prevProps) {
  if (
    prevProps.value !== this.props.value &&
    prevProps.value !== this.state.value
  ) {
    if (this.props.value === "") {
      this.setState({
        value: "",
        selectedText: "",
      });
    }
  }
}
