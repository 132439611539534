import React from "react";
import { Provider } from "./schedules-lib/schedules.context";
import { HeaderMain } from "@components/modules";
import SchedulesFilter from "./schedules-components/SchedulesFilter";
import { SchedulesTable } from "./schedules-components/SchedulesTable";
import _ from "lodash";
import "./schedules.scss";
import { setTitle } from "@lib/utils";

export default class Schedules extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scheduledRunbooks: this.props.scheduledRunbooks || [],
      filteredSchedules: [],
      searchTerm:
        new URLSearchParams(this.props.location.search).get("searchTerm") || "",
    };
    setTitle("Fylamynt | Schedules");
  }
  componentDidMount = () => {
    this.searchSchedules(this.state.searchTerm);
    this.props.fetchSchedules();
  };

  componentDidUpdate = prevProps => {
    let diff = _(prevProps.scheduledRunbooks)
      .xorWith(this.props.scheduledRunbooks, _.isEqual)
      .isEmpty();
    if (!diff) {
      this.setState(
        {
          scheduledRunbooks: this.props.scheduledRunbooks,
        },
        () => this.searchSchedules(this.state.searchTerm),
      );
    }
  };

  searchSchedules = e => {
    let foundSchedules = [];
    this.state.scheduledRunbooks.forEach(item => {
      if (item.workflow.match(_.escapeRegExp(e))) {
        foundSchedules.push(item);
      }
    });

    this.setState({
      filteredSchedules: foundSchedules,
      searchTerm: e,
    });
  };

  removeScheduledItem = data => {
    this.setState(prevState => {
      return {
        filteredSchedules: prevState["filteredSchedules"].filter(
          item => item.rule !== data.rule,
        ),
        scheduledRunbooks: prevState["scheduledRunbooks"].filter(
          item => item.rule !== data.rule,
        ),
      };
    });
  };

  render() {
    const contextValue = {
      isFetchingSchedules: this.props.isFetchingSchedules,
      schedules: this.state.searchTerm
        ? this.state.filteredSchedules
        : this.state.scheduledRunbooks,
      searchSchedules: this.searchSchedules,
    };
    return (
      <Provider value={contextValue}>
        <div className="d-flex flex-column2">
          <HeaderMain title={`Scheduled Workflows`} />
          <div className="ml-15">
            <SchedulesFilter searchTerm={this.state.searchTerm} />
            <SchedulesTable
              fetchSchedules={this.props.fetchSchedules}
              removeScheduledItem={this.removeScheduledItem}
            />
          </div>
        </div>
      </Provider>
    );
  }
}
