import React from "react";
import { connect } from "react-redux";
import {
  Select,
  SelectOption,
  LabelTop,
  TextArea,
  Accordion,
  AccordionLabel,
  AccordionSection,
} from "@components/ui";
import { Tab, TabCollection, TabContent } from "@components/ui/tabs";
import { Consumer } from "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.context";
import { RunbookStepInputSource } from "@containers/RunbookEditor/runbook-editor-lib/ssm/nodeinputoutput";
import {
  getJiraProjectsIssueFields,
  updateMessage,
} from "@redux/actions/snippets.actions";
import Output from "../../../output";
import invert from "lodash/invert";
import "../JiraConnectorPanel.scss";

export type jiraDetailsType = {
  projects: Array<string>;
  issueTypes: Array<string>;
  reporters: Array<string>;
  priorities: Array<string>;
  labels: [{ name: string; id: string }];
  description: string;
  summary: string;
  createdOn: Date | string;
  id: string;
  issues: [{ name: string; id: string }];
};

type JiraCreateIssuePanelProps = {
  jiraDetails: jiraDetailsType;
  activeNode: any;
  fetchJiraDetails: () => {};
  getJiraProjectsIssueFields: (project_key: any) => {};
  updateMessage: (msg: string, flag: boolean) => void;
};

type selectedInputsType = {
  project: string;
  issueType: string;
  reporter: string;
  priority: string;
  description: string;
  summary: string;
};

type JiraCreateIssuePanelState = {
  selectedInputs: selectedInputsType;
};

const fieldInputMap = {
  issueType: "issuetype",
  description: "description",
  summary: "summary",
  project: "project_key",
  priority: "priority",
  reporter: "reporter",
};

class JiraCreateIssuePanel extends React.Component<
  JiraCreateIssuePanelProps,
  JiraCreateIssuePanelState
> {
  constructor(props) {
    super(props);

    let selectedInputs: selectedInputsType = {
      project: "",
      issueType: "",
      reporter: "",
      priority: "",
      description: "",
      summary: "",
    };
    // eslint-disable-next-line
    const invertedFieldInputMap = invert(fieldInputMap);

    for (let item of props.activeNode.extras.runbookNode.parameterInputs) {
      if (typeof item.source.sourceValue === "string") {
        selectedInputs[invertedFieldInputMap[item.name]] =
          typeof item.source.sourceValue === "string" &&
          item.source.sourceValue;
      }
    }

    this.state = {
      selectedInputs: selectedInputs,
    };
  }
  componentDidMount() {
    if (this.props.jiraDetails && !Object.keys(this.props.jiraDetails).length) {
      this.props.fetchJiraDetails();
    }
    if (this.state.selectedInputs.project) {
      this.props.getJiraProjectsIssueFields(this.state.selectedInputs.project);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.jiraDetails.issueTypes &&
      prevProps.jiraDetails.issueTypes !== this.props.jiraDetails.issueTypes
    ) {
      this.props.updateMessage("", false);
    }
  }

  updateInputFieldValue = (newValue, notifyRunbookUpdate, field) => {
    // notify reducer that current runbook got updates.

    const input = this.props.activeNode.extras.runbookNode.parameterInputs.find(
      ip => ip.name === fieldInputMap[field],
    );
    if (input) {
      input.source = new RunbookStepInputSource("constant", newValue);
      notifyRunbookUpdate(true);
    }

    this.setState({
      selectedInputs: {
        ...this.state.selectedInputs,
        [field]: newValue,
      },
    });
  };

  updateSelectedProject = (newValue, notifyRunbookUpdate) => {
    // notify reducer that current runbook got updates.
    ["issueType", "description", "summary", "reporter", "priority"].forEach(
      field => {
        const input = this.props.activeNode.extras.runbookNode.parameterInputs.find(
          ip => ip.name === fieldInputMap[field],
        );
        if (input) {
          input.source = new RunbookStepInputSource("constant", "");
        }
      },
    );

    const input = this.props.activeNode.extras.runbookNode.parameterInputs.find(
      ip => ip.name === fieldInputMap["project"],
    );
    if (input) {
      input.source = new RunbookStepInputSource("constant", newValue);
    }

    newValue && this.props.getJiraProjectsIssueFields(newValue);
    this.setState({
      selectedInputs: {
        ...this.state.selectedInputs,
        project: newValue,
        issueType: "",
        description: "",
        summary: "",
        reporter: "",
        priority: "",
      },
    });

    notifyRunbookUpdate(true);
  };

  render() {
    return (
      <Consumer>
        {({ activeNode, notifyRunbookUpdate }) => {
          let project = this.state.selectedInputs.project;
          let issueType = this.state.selectedInputs.issueType;
          let reporter = this.state.selectedInputs.reporter;
          let priority = this.state.selectedInputs.priority;
          let description = this.state.selectedInputs.description;
          let summary = this.state.selectedInputs.summary;

          return (
            <TabCollection
              active="input"
              activeClassName="editor-detail-tab-wrap editor-detail-active-tab"
              inactiveClassName="editor-detail-tab-wrap editor-detail-inactive-tab"
              contentTop={50}
            >
              <Tab title="Input" name="input">
                <TabContent>
                  <h4 className="editor-node-name">
                    {this.props.activeNode.name}
                  </h4>
                  <Accordion isExpanded={true} useArrow={true}>
                    <AccordionLabel className="editor-accordion-label margin-top-10">
                      Required Inputs
                    </AccordionLabel>

                    <AccordionSection>
                    <div className="editor-detail-panel editor-detail-panel-column">
                    <div className="editor-select-container">
                        <LabelTop
                          label={"Jira Projects"}
                          labelClassName="label"
                        ></LabelTop>
                        <Select
                          title={
                            project && typeof project === "string"
                              ? project
                              : "Select from below"
                          }
                          value={
                            project && typeof project === "string"
                              ? project
                              : ""
                          }
                          iconLeft="icon-filter"
                          optionHeight={32}
                          style={{ width: "calc(100% - 8px)"}}
                          width="calc(100% - 20px)"
                          onChange={newValue => {
                            this.updateSelectedProject(
                              newValue,
                              notifyRunbookUpdate,
                            );
                          }}
                          required={true}
                        >
                          {this.props.jiraDetails.projects &&
                            this.props.jiraDetails.projects.map(output => (
                              <SelectOption
                                value={output}
                                text={output}
                                key={output}
                                className="text-small"
                              />
                            ))}
                        </Select>
                        <LabelTop
                          label={"Issue Types"}
                          labelClassName="label"
                        ></LabelTop>
                        <Select
                          title={
                            issueType && typeof issueType === "string"
                              ? issueType
                              : "Select from below"
                          }
                          value={
                            issueType && typeof issueType === "string"
                              ? issueType
                              : ""
                          }
                          iconLeft="icon-filter"
                          style={{ width: "calc(100% - 8px)"}}
                          optionHeight={32}
                          iconRight="icon-arrow-right"
                          onChange={newValue => {
                            this.updateInputFieldValue(
                              newValue,
                              notifyRunbookUpdate,
                              "issueType",
                            );
                          }}
                          required={true}
                        >
                          {this.props.jiraDetails.issueTypes &&
                            this.props.jiraDetails.issueTypes.map(output => (
                              <SelectOption
                                value={output}
                                text={output}
                                key={output}
                                className="text-small"
                              />
                            ))}
                        </Select>
                        <LabelTop
                          label={"Description"}
                          labelClassName="label"
                        ></LabelTop>
                        <TextArea
                          onKeyUp={newValue =>
                            this.updateInputFieldValue(
                              newValue,
                              notifyRunbookUpdate,
                              "description",
                            )
                          }
                          style={{ width: "calc(100% - 30px)", padding: "10px" }}
                          value={
                            description && typeof description === "string"
                              ? description
                              : ""
                          }
                          className={
                            !!description
                              ? "compact-text-area"
                              : "compact-text-area field-required"
                          }
                          required={true}
                        ></TextArea>
                        <LabelTop
                          label={"Summary"}
                          labelClassName="label"
                          style={{marginTop: "16px"}}
                        ></LabelTop>
                        <TextArea
                          onKeyUp={newValue =>
                            this.updateInputFieldValue(
                              newValue,
                              notifyRunbookUpdate,
                              "summary",
                            )
                          }
                          style={{ width: "calc(100% - 30px)", padding: "10px" }}
                          value={
                            summary && typeof summary === "string"
                              ? summary
                              : ""
                          }
                          className={
                            !!summary
                              ? "compact-text-area"
                              : "compact-text-area field-required"
                          }
                          maxLength={255}
                          required={true}
                        ></TextArea>
                      </div>
                      </div>
                    </AccordionSection>
                  </Accordion>
                  <Accordion isExpanded={true} useArrow={true}>
                    <AccordionLabel className="editor-accordion-label margin-top-10">
                      Optional Inputs
                    </AccordionLabel>
                    <AccordionSection>
                    <div className="editor-detail-panel editor-detail-panel-column">
                    <div className="editor-select-container">
                        <LabelTop
                          label={"Reporter"}
                          labelClassName="label"
                        ></LabelTop>
                        <Select
                          title={
                            reporter && typeof reporter === "string"
                              ? reporter
                              : "Select from below"
                          }
                          value={
                            reporter && typeof reporter === "string"
                              ? reporter
                              : ""
                          }
                          iconLeft="icon-filter"
                          optionHeight={32}
                          style={{ width: "calc(100% - 8px)"}}
                          onChange={newValue => {
                            this.updateInputFieldValue(
                              newValue,
                              notifyRunbookUpdate,
                              "reporter",
                            );
                          }}
                        >
                          {this.props.jiraDetails.reporters &&
                            this.props.jiraDetails.reporters.map(output => (
                              <SelectOption
                                value={output}
                                text={output}
                                key={output}
                                className="text-small"
                              />
                            ))}
                        </Select>
                        <LabelTop
                          label={"Priority"}
                          labelClassName="label"
                        ></LabelTop>
                        <Select
                          title={
                            priority && typeof priority === "string"
                              ? priority
                              : "Select from below"
                          }
                          value={
                            priority && typeof priority === "string"
                              ? priority
                              : ""
                          }
                          showArrow={false}
                          iconLeft="icon-filter"
                          optionHeight={32}
                          style={{ width: "calc(100% - 8px)"}}
                          onChange={newValue => {
                            this.updateInputFieldValue(
                              newValue,
                              notifyRunbookUpdate,
                              "priority",
                            );
                          }}
                        >
                          {this.props.jiraDetails.priorities &&
                            this.props.jiraDetails.priorities.map(output => (
                              <SelectOption
                                value={output}
                                text={output}
                                key={output}
                                className="text-small"
                              />
                            ))}
                        </Select>
                      </div>
                      </div>
                    </AccordionSection>
                  </Accordion>
                </TabContent>
              </Tab>
              <Tab title="Output" name="output">
                <TabContent>
                  <Output />
                </TabContent>
              </Tab>
              <Tab title="Details" name="details">
                <TabContent></TabContent>
              </Tab>
            </TabCollection>
          );
        }}
      </Consumer>
    );
  }
}

const mapState = state => ({
  jiraDetails: state.snippetsReducer.jiraDetails,
});

const mapDispatch = {
  getJiraProjectsIssueFields: getJiraProjectsIssueFields,
  updateMessage: updateMessage,
};

export default connect(mapState, mapDispatch)(JiraCreateIssuePanel);
