import React from "react";
const FylamyntSlackApprovalScreenshot = require("@assets/images/screenshots/settings-slack/Fylamynt-SlackApp-Approve.png");

export default () => (
  <>
    <div className="list-row">
      <div className="step-number">
        <div>1</div>
      </div>
      <div>
        Click on the "Add to Slack" button on the right side of this page.
      </div>
    </div>

    <div className="list-row">
      <div className="step-number">
        <div>2</div>
      </div>
      <div>
        You may be prompted to log into your Slack workspace. If not, verify
        that you are logged into the Slack workspace that you want to add
        Fylamynt to.
      </div>
    </div>

    <div className="list-row">
      <div className="step-number">
        <div>3</div>
      </div>
      <div>
        Review the permissions required to install Fylamynt and click "Allow".
      </div>
    </div>

    <div className="list-image">
      <img
        src={FylamyntSlackApprovalScreenshot}
        alt="FylamyntSlackApprovalScreenshot"
        width="85%"
      />
    </div>
    <div className="list-row">
      <div className="step-number">
        <div>4</div>
      </div>
      <div>
        If installation is successful, you will be redirected back to the Slack
        integration settings page of your Fylamynt instance.
      </div>
    </div>

    <div className="list-row">
      <div className="step-number">
        <div>5</div>
      </div>
      <div>
        After installation, the <b>"@fylamynt"</b> Slack bot user must be
        invited to the channel(s) that you would like to receive messages on.
      </div>
    </div>

    <div className="list-row">
      <div className="step-number">
        <div>6</div>
      </div>
      <div>
        After Fylamynt Slack bot is invited to your Slack workspace you can
        visit{" "}
        <b>
          Fylamynt {`-->`} Settings {`-->`} Integrations(Slack) {`-->`} Edit
          Settings
        </b>{" "}
        and enable <b>Fylamynt Workflow notifications on Slack</b> to receive
        Workflow execution notifications.
      </div>
    </div>
  </>
);
