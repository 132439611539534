import React from "react";
import "./ExecutionRunDetails.scss";
import { Provider } from "./execution-run-details-lib/execution-run-details.context";
import { Wait } from "@components/ui";
import {
  ExecutionRunDetailsHeader,
  ExecutionRunDetailsBlock,
  ExecutionRunDetailsTabs,
} from "./execution-run-details-components";
import * as Helper from "./execution-run-details-lib/execution-run-details.helpers";

export default class ExecutionRunDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPreparing: true,
      message: "Fetching Step Details",
      executionRunDetails: Helper.runDetailsObject,
    };
  }

  componentDidMount() {
    this.props.fetchExecutionDetails(this.props.match.params.executionId);
  }

  componentDidUpdate() {
    if (this.props.isFetchingDetailsSuccess) {
      this.props.history.push("/workflows");
    }
  }

  static getDerivedStateFromProps(nextProps, prevProps) {
    const fetchedExecutionDetails = Helper.findStepInExectuionById(
      nextProps.executionDetails,
      nextProps.match.params.stepExecutionId,
    );
    if (!fetchedExecutionDetails.StepName) return null;
    return {
      isPreparing: false,
      executionRunDetails: {
        ...Helper.runDetailsObject,
        ...fetchedExecutionDetails,
        ...{ executionId: nextProps.match.params.executionId },
        documentName: nextProps.executionDetails.DocumentName,
      },
      executionDetails: nextProps.executionDetails,
    };
  }

  render() {
    const { isPreparing, message } = this.state;
    const { isFetchingDetails } = this.props;
    const contextValue = {
      executionRunDetails: this.state.executionRunDetails,
      executionDetails: this.state.executionDetails,
    };
    return (
      <Provider value={contextValue}>
        {isFetchingDetails && <Wait text={message} />}
        {!isPreparing && (
          <div className="d-flex flex-column2">
            <ExecutionRunDetailsHeader />
            <ExecutionRunDetailsBlock />
            <ExecutionRunDetailsTabs />
          </div>
        )}
      </Provider>
    );
  }
}
