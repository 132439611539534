import React from "react";
import { Consumer } from "../../../../../runbook-editor-lib/runbook-editor.context";
import Snippet from "./Snippet";
import { sortObject, getBlacklistConnectorList } from "@lib/utils";

export default class CustomSnippetsList extends React.Component {
  getSnippetExecution = (snippets, blacklistConnectorList) => {
    let snippetList = { ...snippets };
    let snippetFilter = { ...snippetList?.SNIPPET };
    let snippetExecution = snippetFilter["Execution"]
      ? snippetFilter["Execution"]
      : [];
    return sortObject(
      snippetExecution.map(snippet => {
        let rSnippet = { ...snippet };
        if (
          blacklistConnectorList.includes(snippet?.connector?.toLowerCase())
        ) {
          rSnippet["isDisconnected"] = true;
        }
        return rSnippet;
      }),
      "name",
    );
  };
  render() {
    return (
      <Consumer>
        {({ snippets, connectors }) => {
          const blacklistConnectorList = getBlacklistConnectorList(connectors);

          let snippetExecution = this.getSnippetExecution(
            snippets,
            blacklistConnectorList,
          );

          let updateExecutionSnippets = {
            SNIPPET: { Execution: snippetExecution },
          };

          return Object.keys(snippets?.SNIPPET || {})
            .filter(snippet => snippet === "Execution")
            .map(snippetName => {
              return (
                <Snippet
                  snippets={updateExecutionSnippets}
                  snippetName={snippetName}
                  key={snippetName}
                  searchVal={this.props.searchVal}
                />
              );
            });
        }}
      </Consumer>
    );
  }
}
