import Api from "@lib/api/api";
import { SCHEDULED_RUNBOOKS, IS_FETCHING_SCHEDULES } from "@redux/types";

export function fetchSchedules(runbook = null, version = null, showWait=true) {
  return async dispatch => {
    dispatch(fetchingSchedules(showWait));
    const data = await Api.getSchedules(runbook, version).catch(e=>{});
    dispatch({
      type: SCHEDULED_RUNBOOKS,
      payload: {
        data: data && Array.isArray(data) ? data : [],
        isSingle: runbook && version,
      },
    });
    dispatch(fetchingSchedules(false));
  };
}

export function enableDisableSchedule(runbook, data) {
  return async dispatch => {
    await Api.enableDisableSchedule(runbook, data);
  };
}

export function fetchingSchedules(bool) {
  return async dispatch =>
    dispatch({
      type: IS_FETCHING_SCHEDULES,
      payload: bool,
    });
}
