import React from "react";
import { formatSnippetName } from "../../../../../runbook-editor-lib/runbook-editor.helpers";
import { getSnippetCss } from "@containers/RunbookEditor/runbook-editor-lib/snippet-icons";
import { generateId } from "@lib/utils";
import iconAlertWhite from "@assets/images/icons/warning-icon-white.svg";
import ReactTooltip from "react-tooltip";

export default class SnippetControl extends React.Component {
  state = {
    active: false,
    dragging: false,
    div: null,
    id: generateId("snippet-control"),
  };

  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.disconnectedSnippetTooltip = `This connector is not yet configured. Go to "Settings" and click "Add Integration" to configure.`;
  }

  registerDragStart = (event, snippet) => {
    const div = this.ref.current;
    let shiftX = event.clientX - div.getBoundingClientRect().left - 25;
    let shiftY = event.clientY - div.getBoundingClientRect().top - 25;
    const model = { type: "both", name: snippet.name, extras: snippet };
    event.dataTransfer.setData("storm-diagram-node", JSON.stringify(model));
    event.dataTransfer.setData("divId", this.state.id);
    event.dataTransfer.setData("shift", [shiftX, shiftY]);
  };

  componentDidMount() {
    this.setState({
      div: this.ref.current,
    });
  }

  render() {
    const snippet = this.props.snippet;
    return (
      <>
        {snippet.isDisconnected ? (
          <div
            className={`snippet-wrap-new-disconnected`}
            ref={this.ref}
            id={this.state.id}
          >
            <div className="snippet-icon snippet-disconnected">
              <div
                className={`${
                  snippet.type === "TRIGGER" ? "trigger-node" : ""
                } ${getSnippetCss(snippet.name)}`}
              />
            </div>
            <div className="snippet-name snippet-disconnected">
              {formatSnippetName(snippet.name)}
            </div>
            <div>
              <ReactTooltip
                id={`tooltip-${snippet.name}`}
                place="right"
                effect="float"
                type="light"
                className="snippet-tooltip-container"
              />
              <img
                src={iconAlertWhite}
                alt="iconAlertWhite"
                width="18"
                height="18"
                className="mr-10"
                data-for={`tooltip-${snippet.name}`}
                data-tip={this.disconnectedSnippetTooltip}
                data-html
              />
            </div>
          </div>
        ) : (
          <div
            className={`snippet-wrap-new`}
            ref={this.ref}
            draggable={true}
            onDragStart={e => this.registerDragStart(e, snippet)}
            id={this.state.id}
          >
            <div className="snippet-icon">
              <div
                className={`${
                  snippet.type === "TRIGGER" ? "trigger-node" : ""
                } ${getSnippetCss(snippet.name)}`}
              />
            </div>
            <div className="snippet-name">
              {formatSnippetName(snippet.name)}
            </div>
          </div>
        )}
      </>
    );
  }
}
