/**
 * The key name for connectors should be the same as it
 * is in the `/v1.0/settings/connectors` API
 */
export const RouteConstants = {
  forgotPassword: {
    displayName: "Forgot Password",
    url: "/forgot-password",
  },
  login: {
    displayName: "Login",
    url: "/login",
  },
  signUp: {
    displayName: "Sign Up",
    url: "/sign-up",
  },
  runbookEditor: {
    displayName: "Runbook Editor",
    url: "/workflow/editor",
  },
  runbookDetails: {
    displayName: "Runbook Details",
    url: "/workflows/*",
  },
  runbooks: {
    displayName: "Runbooks",
    url: "/workflows",
  },
  schedules: {
    displayName: "Schedules",
    url: "/schedules",
  },

  /** Settings Routes begin */
  settings: {
    displayName: "Settings",
    url: "/settings",
  },
  awsSettings: {
    displayName: "AWS Settings",
    url: "/settings/aws-settings",
  },
  git: {
    displayName: "GitHub Repos",
    url: "/settings/git-settings",
  },
  ssh: {
    displayName: "SSH Targets",
    url: "/settings/ssh-targets",
  },
  jira: { displayName: "Jira", url: "/settings/jira/get-started" },
  terraform: {
    displayName: "Terraform",
    url: "/settings/terraform/get-started",
  },
  slack: { displayName: "Slack", url: "/settings/slack/get-started" },
  slackAuth: {
    displayName: "SSH Auth",
    url: "/settings/slack/auth",
  },
  datadog: {
    displayName: "Datadog",
    url: "/settings/datadog/get-started",
  },
  instana: { displayName: "Instana", url: "/settings/instana/get-started" },
  ansible: {
    displayName: "Ansible",
    url: "/settings/ansible/get-started",
  },
  terraformCli: {
    displayName: "Terraform CLI",
    url: "/settings/terraform-cli/get-started",
  },
  editTerraformCLI: {
    displayName: "Edit Terraform CLI",
    url: "/settings/terraform-cli",
  },
  twilio: {
    displayName: "Twilio",
    url: "/settings/twilio/get-started",
  },
  pagerduty: {
    displayName: "PagerDuty",
    url: "/settings/pagerduty/get-started",
  },
  opsgenie: {
    displayName: "Opsgenie",
    url: "/settings/opsgenie/get-started",
  },
  pulumi: {
    displayName: "Pulumi",
    url: "/settings/pulumi/get-started",
  },
  apiKeys: {
    displayName: "API Keys",
    url: "/settings/api-keys",
  },
  aws: { displayName: "AWS Target Account", url: "/settings/get-started" },
  s3: { displayName: "S3 Buckets", url: "/settings/s3-buckets" },
  jenkins: { displayName: "Jenkins", url: "/settings/jenkins/get-started" },
  cloudwatch: {
    displayName: "CloudWatch Targets",
    url: "/settings/cloudwatch",
  },
  splunk: {
    displayName: "Splunk",
    url: "/settings/splunk/get-started",
  },
  cloudformation: {
    displayName: "CloudFormation Templates",
    url: "/settings/cloudformation",
  },
  splunkOnCall: {
    displayName: "Splunk On-Call (VictorOps)",
    url: "/settings/splunk-on-call/get-started",
  },
  /** Settings Routes end */

  actions: {
    displayName: "Actions",
    url: "/actions",
  },
  actionDetails: {
    displayName: "Action Details",
    url: "/actions/:name",
  },

  /** Execution Routes begin */
  executionsRun: {
    displayName: "Executions Run",
    url: "/executions/run/*",
  },
  executionDetails: {
    displayName: "Execution Details",
    url: "/executions/execution-details",
  },
  executionRunDetails: {
    displayName: "Execution Run Details",
    url: "/executions/details/:executionId/step/:stepExecutionId",
  },
  allExecutions: {
    displayName: "All Executions",
    url: "/executions/*",
  },
  executions: {
    displayName: "Executions",
    url: "/executions",
  },
  /** Execution Routes end */

  userProfile: {
    displayName: "User Profile",
    url: "/user-profile",
  },
  appInfo: {
    displayName: "App Info",
    url: "/app-info",
  },
};
