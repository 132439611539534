import React from "react";
import { Modal, TextInput, Button, Form } from "@components/ui";
import { Consumer } from "../runbooks-lib/runbooks.context";
import { connect } from "react-redux";
import { createNewRunbook } from "@redux/actions/runbook.action";
import { checkIfRunbookNameIsValid } from "@lib/utils";

const getFooter = closeFunction => {
  return (
    <div className="modal-buttons-footer">
      <Button
        text="Cancel"
        buttonStyle="secondary"
        size="none"
        onClick={closeFunction}
        style={{ width: "50%" }}
      />
      <Button
        text="Create Workflow"
        buttonStyle="primary"
        style={{ width: "50%" }}
        type={"submit"}
      />
    </div>
  );
};

class RunbookCreate extends React.Component {
  state = {
    formIsSubmitting: false,
    submissionError: "",
    success: false,
    runbookName: "",
    errorMessage: "",
  };

  checkForm = parameters => {
    if (!parameters) {
      return;
    }
    const userProvidedRunbookName = parameters["new-runbook"].trim
      ? parameters["new-runbook"].trim()
      : parameters["new-runbook"];

    const runbookNameValidityObj = checkIfRunbookNameIsValid(
      this.props.runbooks,
      userProvidedRunbookName,
    );

    if (runbookNameValidityObj.isValidName) {
      this.setState(
        {
          formIsSubmitting: true,
          runbookName: userProvidedRunbookName,
          errorMessage: "",
        },
        () => this.props.createNewRunbook(this.state.runbookName),
      );
    } else {
      this.setState({
        formIsSubmitting: false,
        runbookName: userProvidedRunbookName,
        errorMessage: runbookNameValidityObj.errorMessage,
      });
    }
  };

  render() {
    return (
      <Consumer>
        {({ toggleRunbookModal }) => (
          <Form
            method="post"
            action="./runbook-editor"
            onSubmit={this.checkForm}
            formIsSubmitting={this.state.formIsSubmitting}
            submissionError={this.state.errorMessage}
            id="create-form"
          >
            <Modal
              onClose={toggleRunbookModal}
              title="Create Workflow"
              showClose={true}
              footer={getFooter(toggleRunbookModal)}
            >
              <TextInput
                name="new-runbook"
                label="Workflow Name"
                labelClassName="label"
                labelPosition="top"
                minLength={3}
                value={this.state.runbookName}
                autoFocus={true}
              />
              <div className="form-error">{this.state.errorMessage}</div>
            </Modal>
          </Form>
        )}
      </Consumer>
    );
  }
}
const mapState = ({ runbooksReducer }) => ({
  runbooks: runbooksReducer.runbookList,
});
const mapDispatch = dispatch => ({
  createNewRunbook: runbookName => dispatch(createNewRunbook(runbookName)),
});
export default connect(mapState, mapDispatch)(RunbookCreate);
