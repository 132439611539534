import { StepTypes, ControlNames } from "./strings";
import { SSMActionNames } from "../ssm/strings";
import { InvokeLambdaStep } from "../ssm/invokelambdastep";
import { RunbookStepOutput } from "../ssm/nodeinputoutput";
import { ParameterType } from "../ssm/strings";
import { readLambdaPayload, ActionNodeStep } from "./actionnodestep";

export class DatadogConnectorStep extends InvokeLambdaStep {
  static fromControlNodeDefinition(snippetDef) {
    const { lambda_arn } = snippetDef.content;
    const payload = {
      monitor_id: "",
      synthetic: "",
      alert_body: "{{ DatadogAlertBody }}",
      message: "",
      workflow_session: "{{ WorkflowSession }}",
    };
    const stepJSON = {
      ...snippetDef.content,
      name: ControlNames.DatadogAlert,
      action: SSMActionNames.INVOKE_LAMBDA,
      editorNodeId: snippetDef.editorNodeId,
      inputs: {
        FunctionName: lambda_arn,
        Payload: JSON.stringify(payload),
      },
    };
    return new DatadogConnectorStep(stepJSON, snippetDef);
  }
  constructor(stepJSON, snippetDef) {
    super(stepJSON);
    this.stepType = StepTypes.DatadogConnectorStep;
    this.editable = true;
    this.lambdaPayload = readLambdaPayload(stepJSON);
    this.snippetDef = snippetDef;
    this.lambda_arn = snippetDef ? snippetDef.content.lambda_arn : "";
    this.monitorID = this.lambdaPayload?.monitor_id;
    this.outputs = [
      new RunbookStepOutput(this, "output", ParameterType.Object, ""),
    ];
    this.monitorInfo = {
      synthetic: this.lambdaPayload?.synthetic,
      message: this.lambdaPayload?.message,
    };
  }

  setMonitorInfo(monitorInfo) {
    this.monitorInfo = monitorInfo;
    this.monitorID = monitorInfo?.id;
    if (this.runbook) {
      this.runbook.notifyRunbookUpdate(true);
    }
  }

  writeLambdaPayload() {
    const payload = {
      monitor_id: this.monitorID?.toString(),
      synthetic: this.monitorInfo?.synthetic ? "true" : "false",
      alert_body: "{{ DatadogAlertBody }}",
      message: this.monitorInfo?.message,
      workflow_session: "{{ WorkflowSession }}",
    };
    return JSON.stringify(payload);
  }

  readInputSources(runbook) {
    super.readInputSources(runbook);

    const alertBodyInput = this.parameterInputs.find(
      input => input.name === "alert_body",
    );
    alertBodyInput.source.default = "{}";
    alertBodyInput.source.sourceValue.spec.ssm.default = "{}";
    alertBodyInput.source.sourceValue.isRequired = true;
    alertBodyInput.source.sourceValue.spec.description =
      "(Required - String) Stringified JSON of Datadog alert received at the EventBridge";

    alertBodyInput.source.sourceValue.spec.ssm.description =
      alertBodyInput.source.sourceValue.spec.description;
    runbook.ssmDoc.parameters.DatadogAlertBody =
      alertBodyInput.source.sourceValue;
  }

  toSSM = () => {
    const outputs = this.outputs ? this.outputs.map(out => out.toSSM()) : [];
    return {
      action: "aws:invokeLambdaFunction",
      inputs: {
        FunctionName: this.lambda_arn,
        Payload: this.writeLambdaPayload(),
        ClientContext: ActionNodeStep._writeSnippetDetailsForContext(
          this.snippetDef,
        ),
      },
      isEnd: !this.nextStep,
      maxAttempts: 1,
      name: this.name,
      nextStep: this.nextStep,
      onFailure: "Abort",
      outputs,
    };
  };

  isHealthyStep() {
    if (!this.monitorID) return false;
    return true;
  }
}
