import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { isFetching } from "@redux/actions/settingsPanel.action";
import { Formik } from "formik";
import { isEmpty } from "@lib/utils";
import Api from "@lib/api/api";
import { Wait } from "@components/ui";
import { Redirect } from "react-router";
import { RouteConstants } from "../../../../../routes/Constants";

const AddtoSlack = require("@assets/images/screenshots/settings-slack/add-to-slack-btn.svg");

class RightPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      doRedirect: false,
      redirectUrl: "",
      formMessage: "",
      formSubmitStatus: "",
    };
  }

  componentDidMount() {
    const url = new URL(window.location.href);
    const status = url.searchParams.get("status");
    const message = url.searchParams.get("message");
    if (!!status && status !== null) {
      this.setState({ formMessage: message, formSubmitStatus: status });
      setTimeout(() => {
        if (status === "success") {
          this.goBack();
        }
        // this.setState({ formMessage: "" });
      }, 2000);
    }
  }

  goBack = () => {
    this.setState({
      doRedirect: true,
      redirectUrl: RouteConstants.settings.url,
    });
  };

  onSubmitHandle = async () => {
    this.props.isFetching(true, "Fetching Slack Integration URL");
    let response = await Api.getConnectorIntegrationUrl("slack").catch(e =>
      console.log(e),
    );
    const integrationUrl =
      !isEmpty(response) && !isEmpty(response.body)
        ? JSON.parse(response.body).integration_url
        : "";

    this.props.isFetching(true, "Integration in progress");

    if (!!integrationUrl) {
      window.location.href = integrationUrl;
    } else {
      // actions.setStatus({
      //   formSubmitMessage: "Oops! Something went wrong !!!",
      // });
      this.setState({
        formMessage: "Oops! Something went wrong !!!",
        formSubmitStatus: "error",
      });
    }
    this.props.isFetching(false, "");
  };

  render() {
    return (
      <>
        {this.state.doRedirect && <Redirect to={this.state.redirectUrl} push />}
        {this.props.message && <Wait text={this.props.message} />}

        <div className="instructions__right-slack">
          <div className="onboarding-form">
            <div className="onboarding-header-wrap">
              <h2>Authorize Fylamynt</h2>
            </div>

            <div className="onboarding-card">
              <h4>
                <b>
                  Click the button below to add the Fylamynt Slack app to your
                  Slack Workspace
                </b>
              </h4>

              <Formik initialValues={{}} onSubmit={this.onSubmitHandle}>
                {formik => (
                  <>
                    <div className="form-footer">
                      <img
                        src={AddtoSlack}
                        alt="AddtoSlack"
                        onClick={formik.handleSubmit}
                        className="slack-integration-add"
                      />
                    </div>

                    <div
                      style={{ marginTop: "1rem" }}
                      className={
                        this.state.formSubmitStatus === "error"
                          ? "input-feedback"
                          : ""
                      }
                    >
                      {this.state.formMessage} <br />
                      {this.state.formSubmitStatus === "error" && (
                        <span className="input-feedback">
                          {" "}
                          Please try again.
                        </span>
                      )}
                    </div>
                  </>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  message: state.runbooksReducer.message,
});
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      isFetching,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(RightPanel);
