import "./WarningCard.scss";
import { Link } from "react-router-dom";
import React from "react";
import { RouteConstants } from "../../../routes/Constants";
import { getUnconfiguredNodeList } from "@lib/utils";

type nodeObj = { step: ""; connector: "" };
type WarningCardProps = {
  isAWSAccountConfigured: boolean;
  unconfiguredNodes: Array<nodeObj>;
};

const WarningCard: React.FC<WarningCardProps> = ({
  isAWSAccountConfigured,
  unconfiguredNodes,
}) => {
  let unconfiguredNodeList = getUnconfiguredNodeList(unconfiguredNodes);

  return (
    <div className="warning-card">
      <div className="warning-card-header">
        <div className="strong-span">Save-Only Mode</div>
      </div>
      <span className="warning-card-text">
        Running workflows is disabled due to the following items that are not
        yet setup:
      </span>
      <ul className="warning-card-ul">
        {/* Check if AWS node is configured and show it on top of warning if not */}
        {!isAWSAccountConfigured && (
          <li>
            <Link to={RouteConstants["aws"].url} className="warning-card-link">
              {RouteConstants["aws"].displayName}
            </Link>
          </li>
        )}
        {unconfiguredNodeList.map(nodeName =>
          RouteConstants.hasOwnProperty(nodeName) ? (
            <li key={nodeName}>
              <Link
                className="warning-card-link"
                to={RouteConstants[nodeName].url}
              >
                {RouteConstants[nodeName].displayName}
              </Link>
            </li>
          ) : (
            ""
          ),
        )}
      </ul>
    </div>
  );
};

export default WarningCard;
