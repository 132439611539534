import React from "react";
import ReactDOM from "react-dom";
import RoutedApp from "./routes/Routes";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { store, persistor } from "@redux/store";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { WaitMessage } from "@components/ui";
import AppConfig from "@components/AppConfig";
import { PersistGate } from "redux-persist/integration/react";
const target = document.getElementById("root");

// Initialize Bugsnag
const apiKey =
  process.env.BUGSNAG_API_KEY || "f6de3704d912cf8cee79c89651b3f3fd";
const appVersion = process.env.REACT_APP_VERSION || "1.0.0";
const releaseStage = process.env.REACT_APP_STAGE || "development";

Bugsnag.start({
  apiKey,
  plugins: [new BugsnagPluginReact()],
  appVersion,
  releaseStage,
});
const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <AppConfig>
        <ErrorBoundary>
          <WaitMessage />
          <RoutedApp />
        </ErrorBoundary>
      </AppConfig>
    </PersistGate>
  </Provider>,
  target,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
