import React from "react";
import { setZIndex, setStyle } from "./select-multiple.lib";

export function init() {
  this.onClick = this.onClick.bind(this);
  this.inputRef = React.createRef();
}

/* used by Select to instaniate child SelectOption components */
export function initChildren() {
  const children = React.Children.map(this.props.children, child => {
    return React.cloneElement(child, {
      onChange: this.onChange,
      addToValues: this.addToValues,
      forceDeselect: this.state.forceDeselect,
      optionHeight: this.props.optionHeight,
    });
  });
  return children;
}

export function didMount() {
  this.setState(
    {
      parent: this.ref.current,
    },
    () => {
      setZIndex.call(this);
    },
  );
  setStyle.call(this);
}
