import React from "react";
import {
  Accordion,
  AccordionLabel,
  AccordionSection,
  Select,
  SelectOption,
  LabelTop,
} from "@components/ui";
import { ControlNames } from "@containers/RunbookEditor/runbook-editor-lib/neuropssteps/strings";

export default class DatadogConnectorInput extends React.Component {
  state = {
    monitors: [],
    selectedMonitor: this.props.activeNode.extras.runbookNode.monitorInfo,
  };

  componentDidMount() {
    if (Object.keys(this.props.datadogMonitorList).length === 0) {
      this.props.getDatadogMonitorList();
    }else {
      this.updateDatadogMonitorList({})
    }
  }

  componentDidUpdate(prevProps) {
    this.updateDatadogMonitorList(prevProps);
  }

  updateDatadogMonitorList(prevProps) {
    if (
      this.props.datadogMonitorList &&
      this.datadogMonitorListUpdated(prevProps)
    ) {
      const { runbookNode } = this.props.activeNode.extras;
      const selectedID = runbookNode && runbookNode.monitorID;
      const selectedMonitor = this.props.datadogMonitorList.find(
        monitor => `${monitor.id}` === `${selectedID}`,
      );
      const monitors = this.props.datadogMonitorList;
      this.setState({ monitors, selectedMonitor });
    }
  }

  addSelectedMonitorToList(selected, monitors) {
    if (!monitors.find(monitor => monitor.id === selected.id)) {
      monitors.append(selected);
    }
    return [...monitors];
  }

  datadogMonitorListUpdated(prevProps) {
    const listAppeared =
      !prevProps.datadogMonitorList && this.props.datadogMonitorList;
    const listChanged =
      prevProps.datadogMonitorList !== this.props.datadogMonitorList;
    const listGrew =
      listLen(prevProps.datadogMonitorList) <
      listLen(this.props.datadogMonitorList);
    return listAppeared || listChanged || listGrew;
  }

  updateMonitor(newValue) {
    const selectedMonitor = this.state.monitors.find(
      monitor => monitor.name === newValue,
    );

    this.props.activeNode.extras.runbookNode.setMonitorInfo(selectedMonitor);
    this.setState({ selectedMonitor });
  }

  render() {
    const { runbookNode } = this.props;
    const { monitors, selectedMonitor } = this.state;
    return (
      <React.Fragment>
        <h4 className="editor-node-name">
          {(runbookNode && runbookNode.name) || ControlNames.DatadogAlert}
        </h4>
        <React.Fragment>
          <Accordion isExpanded={true} useArrow={true}>
            <AccordionLabel className="editor-accordion-label margin-top-10">
              Required Inputs
            </AccordionLabel>
            <AccordionSection>
            <div className="editor-detail-panel editor-detail-panel-column">
            <div className="editor-select-container">
                <LabelTop label={`Monitor`} labelClassName="label"></LabelTop>
                  <Select
                    title={
                      selectedMonitor
                        ? selectedMonitor.name
                        : "Select from below"
                    }
                    iconLeft="icon-filter"
                    style= {{ width: "calc(100% - 8px)"}}
                    onChange={newValue => this.updateMonitor(newValue)}
                    required={true}
                    value={selectedMonitor ? selectedMonitor.name : ""}
                  >
                    {(monitors || []).map((monitorInfo, i) => (
                      <SelectOption
                        text={monitorInfo.name}
                        value={monitorInfo.name}
                        key={`${monitorInfo.id}`}
                        className="text-small"
                      />
                    ))}
                  </Select>
                </div>
                </div>
            </AccordionSection>
          </Accordion>
          {selectedMonitor && (
            <Accordion isExpanded={true} useArrow={true}>
              <AccordionLabel className="editor-accordion-label margin-top-10">
                Monitor Details
              </AccordionLabel>
              <AccordionSection
                style={{
                  minHeight: "150px",
                  maxHeight: "300px",
                  overflow: "hidden",
                  width: "100%",
                  wordBreak: "break-all",
                }}
              >
                <div style={{ margin: 10 }}>
                  <p style={{ fontSize: "12px"}}>
                    <strong style={{ color: "#ddd" }}>Description:</strong>{" "}
                    {selectedMonitor.description}
                  </p>
                  <p style={{ fontSize: "12px"}}>
                    <strong style={{ color: "#ddd" }}>Rule:</strong>{" "}
                    {selectedMonitor.rule}
                  </p>
                  <p style={{ fontSize: "12px"}}>
                    <strong style={{ color: "#ddd" }}>Message:</strong>{" "}
                    {selectedMonitor.message}
                  </p>
                </div>
              </AccordionSection>
            </Accordion>
          )}
        </React.Fragment>
      </React.Fragment>
    );
  }
}

function listLen(l) {
  return l ? l.length : 0;
}
