import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useRef,
} from "react";
import { Accordion, AccordionLabel, AccordionSection } from "@components/ui";
import { RunbookEditorContext } from "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.context";
import { RunbookStepInputSource } from "@containers/RunbookEditor/runbook-editor-lib/ssm/nodeinputoutput";
import { isEmpty } from "@lib/utils";
import ReactSelect from "@components/ui/React-Select/ReactSelect";
import Api from "@lib/api/api";

type RequiredInputsProps = {};

const RequiredInputs: React.FC<RequiredInputsProps> = () => {
  const isFirstRun = useRef(true);

  const context = useContext(RunbookEditorContext) as any;
  const runbookNode = context.activeNode.extras.runbookNode;
  const notifyRunbookUpdate = context.notifyRunbookUpdate;

  const [isLoadingServiceList, setIsLoadingServiceList] = useState(false);
  const [serviceList, setServiceList] = useState([]);

  const [selectedServiceName, setSelectedServiceName] = useState("");
  const [selectedServiceId, setSelectedServiceId] = useState("");

  const getParamInput = useCallback(
    (input: string) => runbookNode.parameterInputs.find(p => p.name === input),
    [runbookNode.parameterInputs],
  );

  const getServiceList = useCallback(async () => {
    setIsLoadingServiceList(true);
    try {
      const res = await Api.fetchOpsgenieServiceList();
      let response = typeof res === "string" ? JSON.parse(res) : res;
      let opsgenieServices = response.opsgenie_services;
      setServiceList(opsgenieServices);
    } catch (error) {
      console.log(error);
    }
    setIsLoadingServiceList(false);
  }, []);

  /**
   * Find service name using the service Id stored in the database
   */
  useEffect(() => {
    let serviceName = Object.keys(serviceList).find(
      s => serviceList[s] === selectedServiceId,
    );
    if (serviceName) setSelectedServiceName(serviceName);
  }, [serviceList, selectedServiceId]);

  useEffect(() => {
    if (runbookNode) {
      runbookNode.showHideWarning(!selectedServiceId);
    }
  }, [runbookNode, selectedServiceId]);

  const setInitialParamValues = useCallback(() => {
    const serviceId = getParamInput("service_id").source?.sourceValue;
    if (serviceId && serviceId.constructor.name === "String") {
      setSelectedServiceId(serviceId);
    }
  }, [getParamInput]);

  useEffect(() => {
    if (isFirstRun.current) {
      setInitialParamValues();
      isFirstRun.current = false;
    }
  }, [setInitialParamValues]);

  useEffect(() => {
    getServiceList();
  }, [getServiceList]);

  const setPayload = value => {
    let serviceId = getParamInput("service_id");
    serviceId.source = new RunbookStepInputSource(
      `constant`,
      serviceList[value],
    );
    notifyRunbookUpdate(true);
  };

  return (
    <Accordion isExpanded={true} useArrow={true}>
      <AccordionLabel className="editor-accordion-label margin-top-10">
        Required Inputs
      </AccordionLabel>
      <AccordionSection>
        <div className="s3">
          <div className="s3-input">
            {isLoadingServiceList ? (
              <label className="s3-input-label loading-text">
                <i>Loading Services...</i>
              </label>
            ) : (
              <>
                <label className="s3-input-label">Select Service</label>
                <ReactSelect
                  id="service"
                  name="service"
                  value={{
                    value: selectedServiceName,
                    label: selectedServiceName
                      ? selectedServiceName
                      : "Select from below",
                  }}
                  handleChange={data => {
                    if (!isEmpty(data) && !!data.value) {
                      setSelectedServiceName(data.value);
                      setSelectedServiceId(serviceList[data.value]);
                      setPayload(data.value);
                    }
                  }}
                  selectOptions={Object.keys(serviceList).map(r => {
                    return {
                      value: r,
                      label: r,
                    };
                  })}
                  required
                />
              </>
            )}
          </div>
        </div>
      </AccordionSection>
    </Accordion>
  );
};

export default RequiredInputs;
