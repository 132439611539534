import {
  ActionNodePanel,
  CloudwatchAlertConnectorPanel,
  ConditionalPanel,
  DatadogConnectorPanel,
  JiraCreateIssuePanel,
  JiraExistingIssuePanel,
  LoopForEachPanel,
  SlackConnectorPanel,
  SnippetNoneSelected,
  SnippetPanel,
  TerraformPlanConnectorPanel,
  TerraformUpdateVarsConnectorPanel,
  WaitTimePanel,
  JSONPathPanel,
} from "./snippet-tabs";
import {
  inputType,
  resizeRightPanel,
} from "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.helpers";

import { Consumer } from "../../runbook-editor-lib/runbook-editor.context";
import { ControlNames } from "@containers/RunbookEditor/runbook-editor-lib/neuropssteps/strings";
import InstanaConnectorPanel from "./snippet-tabs/connector-panel/Instana/instana-connector-panel";
import React from "react";
import { Switch } from "@components/ui";
import UnconfiguredMessage from "./snippet-tabs/unconfigured-message/unconfigured-message";
import WaitForResourcePanel from "./snippet-tabs/wait-for-resource-panel/wait-for-resource-panel";
import Webhook from "./snippet-tabs/connector-panel/Webhook";
import { isCurrentNodeConfigured } from "@lib/utils";
import Container from "./snippet-tabs/connector-panel/Container/Container";
import TwilioConnectorPanel from "./snippet-tabs/connector-panel/Twilio/twilio-connector-panel";
import S3BucketPicker from "./snippet-tabs/connector-panel/S3BucketPicker/S3BucketPicker";
import PagerDuty from "./snippet-tabs/connector-panel/PagerDuty/PagerDuty";
import SplunkSearch from "./snippet-tabs/connector-panel/SplunkSearch/SplunkSearch";
import SplunkOnCall from "./snippet-tabs/connector-panel/SplunkOnCall/SplunkOnCall";
import JenkinsBuild from "./snippet-tabs/connector-panel/Jenkins/JenkinsBuild";
import Opsgenie from "./snippet-tabs/connector-panel/Opsgenie/Opsgenie";
import Pulumi from "./snippet-tabs/connector-panel/Pulumi/Pulumi";
import CloudFormationRunTemplate from "./snippet-tabs/connector-panel/CloudFormationRunTemplate/CloudFormationRunTemplate";

const UNCONFIGURED_NODE = "UnconfiguredNode";
export default class EditorRightPanel extends React.Component {
  constructor(props) {
    super(props);
    this.node = null;
  }

  componentDidMount() {
    resizeRightPanel();
  }

  getType = activeNode => {
    return isCurrentNodeConfigured(
      activeNode?.extras?.runbookNode?.name?.toLowerCase(),
      this.props.unconfiguredNodes,
    )
      ? inputType(activeNode)
      : UNCONFIGURED_NODE;
  };

  render() {
    return (
      <Consumer>
        {({ activeNode, notifyRunbookUpdate }) => (
          <div className="editor-detail-wrap" data-tut="reactour__rightPanel">
            <div className="editor-right-panel-resize" />
            <Switch value={this.getType(activeNode)}>
              <SnippetPanel
                inputOptions={true}
                activeNode={activeNode}
                caseValue="SnippetAction"
              />
              <ConditionalPanel
                activeNode={activeNode}
                rerenderEditor={this.props.rerenderEditor}
                addLinkToStep={this.props.addLinkToStep}
                caseValue="ConditionalAction"
              />
              <SnippetPanel activeNode={activeNode} caseValue="SSMStepAction" />
              <LoopForEachPanel
                activeNode={activeNode}
                caseValue="LoopForEach"
              />
              <WaitForResourcePanel
                activeNode={activeNode}
                caseValue="WaitForResource"
              />
              <ActionNodePanel activeNode={activeNode} caseValue="ActionNode" />
              <DatadogConnectorPanel
                activeNode={activeNode}
                caseValue={ControlNames.DatadogAlert}
              />
              <InstanaConnectorPanel
                caseValue="Instana_Alert"
                activeNode={activeNode}
              />
              <TerraformPlanConnectorPanel
                activeNode={activeNode}
                caseValue="TerraformPlanNode"
              />
              <TerraformUpdateVarsConnectorPanel
                activeNode={activeNode}
                caseValue="TerraformUpdateVarsNode"
              />
              <SlackConnectorPanel
                activeNode={activeNode}
                caseValue="Slack_Send_Message"
              />
              <JiraCreateIssuePanel
                activeNode={activeNode}
                fetchJiraDetails={this.props.fetchJiraDetails}
                caseValue="Jira_Create_Issue"
              />
              <JiraExistingIssuePanel
                activeNode={activeNode}
                fetchJiraDetails={this.props.fetchJiraDetails}
                notifyRunbookUpdate={notifyRunbookUpdate}
                caseValue="Jira_Existing_Issue"
              />
              <SnippetNoneSelected inputOptions={false} caseValue="default" />
              <WaitTimePanel
                notifyRunbookUpdate={notifyRunbookUpdate}
                caseValue="WaitTimeNode"
              />
              <CloudwatchAlertConnectorPanel
                activeNode={activeNode}
                fetchTargetAccountAlarmsList={
                  this.props.fetchTargetAccountAlarmsList
                }
                caseValue={ControlNames.CloudwatchAlert}
              />
              <JSONPathPanel
                activeNode={activeNode}
                caseValue={ControlNames.JSONPathStep}
                notifyRunbookUpdate={notifyRunbookUpdate}
              />
              <UnconfiguredMessage caseValue={UNCONFIGURED_NODE} />
              <Webhook caseValue="Webhook" />
              <TwilioConnectorPanel
                activeNode={activeNode}
                notifyRunbookUpdate={notifyRunbookUpdate}
                caseValue="Twilio_Send_SMS"
              />
              <Container caseValue="Container" />
              <S3BucketPicker caseValue={ControlNames.PickS3Bucket} />
              <PagerDuty caseValue={ControlNames.PagerDuty} />
              <Opsgenie caseValue={ControlNames.Opsgenie} />
              <SplunkSearch caseValue={ControlNames.SplunkSearch} />
              <Pulumi
                caseValue={ControlNames.Pulumi}
                typeName={activeNode?.name}
              />
              <SplunkOnCall caseValue={ControlNames.SplunkOnCallAlert} />
              <JenkinsBuild caseValue={ControlNames.JenkinsBuild} />
              <CloudFormationRunTemplate
                caseValue={ControlNames.CloudFormationRunTemplate}
              />
            </Switch>
          </div>
        )}
      </Consumer>
    );
  }
}
