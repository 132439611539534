export function createAnimatedBorder(element) {
  const rect = element.getBoundingClientRect();
  const divWrapper = createDiv(element, rect);
  const svg = createSvg(divWrapper, rect);
  createRects(svg, rect);
}

function createDiv(parent, rect) {
  const divWrapper = document.createElement("div");
  divWrapper.classList.add("svg-wrapper");
  divWrapper.style.position = "absolute";
  divWrapper.style.top = "0";
  divWrapper.style.left = "0";
  divWrapper.style.width = `${rect.width}px`;
  divWrapper.style.height = `${rect.height}px`;
  divWrapper.style.zIndex = "1000";
  divWrapper.style.background = "transparent";
  divWrapper.style.pointerEvents = "none";
  parent.appendChild(divWrapper);
  return divWrapper;
}

function createSvg(parent, rect) {
  const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
  svg.setAttribute("height", rect.height);
  svg.setAttribute("width", rect.width);
  parent.appendChild(svg);
  return svg;
}

function createRects(svg, rect) {
  const rectTop = document.createElementNS(
    "http://www.w3.org/2000/svg",
    "rect",
  );
  const rectBottom = document.createElementNS(
    "http://www.w3.org/2000/svg",
    "rect",
  );
  rectTop.classList.add("shape1");
  rectBottom.classList.add("shape2");
  rectTop.setAttribute("height", rect.height);
  rectTop.setAttribute("width", rect.width);
  rectBottom.setAttribute("height", rect.height);
  rectBottom.setAttribute("width", rect.width);
  svg.appendChild(rectTop);
  svg.appendChild(rectBottom);
}
