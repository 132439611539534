import Auth from "@aws-amplify/auth";
import { userResetState } from "@redux/reducers/";

export const authLogout = async dispatch => {
  try {
    await Auth.signOut({ global: true });
    dispatch(userResetState());
  } catch (e) {
    dispatch(userResetState());
    return;
  }
};
