import Api from "@lib/api/api";
import {
  IS_FETCHING_OPERATIONS,
  IS_FETCHING_SERVICES,
  SET_SERVICES,
  SET_SELECTED_SERVICE,
  SET_SELECTED_OPERATION,
  SERVICES_NEEDED,
  SET_OPERATIONS,
  IS_FETCHING_OPERATION_DETAILS,
  SET_OPERATION_DETAILS,
  SET_OUTPUTS,
} from "../types";
import { store } from "@redux/store";

export const fetchServices = () => {
  return async dispatch => {
    dispatch(isFetchingServices(true));
    const data = await Api.getAWSServices().catch(e => {
      console.log("Error fetching api.getAWSService: ", e);
      return;
    });
    dispatch({
      type: SERVICES_NEEDED,
      payload: false,
    });
    dispatch({ type: SET_SERVICES, payload: data?.services });
    dispatch(isFetchingServices(false));
  };
};

export const setSelectedService = selectedService => {
  return async dispatch => {
    dispatch({
      type: SET_SELECTED_SERVICE,
      payload: selectedService,
    });
    let operations = store.getState().actionNodeReducer.serviceOperationsCache[
      selectedService
    ];
    if (operations) {
      dispatch({
        type: SET_OPERATIONS,
        payload: { selectedService, operations },
      });
      return;
    }
    dispatch(fetchOperations(selectedService));
  };
};

export const fetchOperations = selectedService => {
  return async dispatch => {
    dispatch(isFetchingOperations(true));
    let data;
    if (selectedService) {
      data = await Api.getAWSServiceOperations(selectedService).catch(e => {
        console.log("Fetching aws error: ", e);
        return;
      });
    } else {
      dispatch({
        type: SET_OPERATIONS,
        payload: { selectedService: [], operations: [] },
      });
      dispatch(isFetchingOperations(false));
    }

    if (data) {
      const { operations } = data;
      dispatch({
        type: SET_OPERATIONS,
        payload: { selectedService, operations },
      });
      dispatch(isFetchingOperations(false));
    } else {
      dispatch(isFetchingOperations(false));
    }
  };
};

export const fetchAwsOperationDetails = operation => {
  return async dispatch => {
    dispatch(isFetchingOperationDetails(true));
    const selectedService = store.getState().actionNodeReducer.selectedService;
    const data = await Api.getAWSServiceOperationDetails(
      selectedService,
      operation,
    ).catch(e => {
      console.log(
        `Could not fetch aws details for ${selectedService} ${operation}: ${e}`,
        e,
      );
      return;
    });
    dispatch({ type: SET_OPERATION_DETAILS, payload: data });
    dispatch(isFetchingOperationDetails(false));
  };
};

export const fetchAwsOutputs = (service, operation) => {
  return async dispatch => {
    const data = await Api.getAWSServiceOperationDetails(
      service,
      operation,
    ).catch(e => {
      console.log(
        `Could not fetch aws details for ${service} ${operation}: ${e}`,
        e,
      );
      return;
    });
    dispatch({
      type: SET_OUTPUTS,
      payload: data?.output || [],
      key: `${service}-${operation}`,
    });
  };
};

export const setSelectedOperation = operation => {
  return async dispatch => {
    dispatch({
      type: SET_SELECTED_OPERATION,
      payload: operation,
    });
    if (operation) {
      dispatch(fetchAwsOperationDetails(operation));
    }
  };
};

/* is fetching ? */
export const isFetchingOperationDetails = bool => {
  return async dispatch => {
    dispatch({ type: IS_FETCHING_OPERATION_DETAILS, payload: bool });
  };
};

export const isFetchingServices = bool => {
  return async dispatch => {
    dispatch({ type: IS_FETCHING_SERVICES, payload: bool });
  };
};

export const isFetchingOperations = bool => {
  return async dispatch => {
    dispatch({ type: IS_FETCHING_OPERATIONS, payload: bool });
  };
};
