import React from "react";
import { LabelTop } from "@components/ui";
import FormInput from "../../settings-panel-components/SettingsRightConfigPanel/FormInput";
import ReactSelect from "@components/ui/React-Select/ReactSelect";

const WebhookForm = ({
  connectorName,
  formik,
  apiKeyList,
  handleClick,
  enableWebhookToggle,
}) => {
  const { values, setFieldValue, errors, touched, handleChange } = formik;

  const selectWebhookApiKeyName = (apiKey, setFieldValue) => {
    setFieldValue("webhook_api_key_name", apiKey ? apiKey.label : "");
    setFieldValue("webhook_api_key_value", apiKey ? apiKey.value : "");
  };

  return (
    <>
      <div className="mb-15">
        <FormInput
          fieldName={`${connectorName} Webhook URL:`}
          name="webhook_url"
          id="webhook_url"
          className={`overflow-ellipsis`}
          handleChange={handleChange}
          touched={touched}
          errors={errors}
          autoComplete="off"
          readOnly={true}
        />
      </div>
      <div className="account-info__wrap">
        <LabelTop label={`Webhook API Key Name:`} labelClassName={`label`}>
          <ReactSelect
            id="webhook_api_key_name"
            name="webhook_api_key_name"
            value={{
              value: values?.webhook_api_key_value || "",
              label: values?.webhook_api_key_name
                ? values.webhook_api_key_name
                : "Select from below",
            }}
            handleChange={data => {
              selectWebhookApiKeyName(data, setFieldValue);
            }}
            selectOptions={apiKeyList?.map(api => {
              return {
                value: api.Value,
                label: api.ApikeyName,
              };
            })}
            required
          />
        </LabelTop>
        {errors["webhook_api_key_name"] && touched["webhook_api_key_name"] && (
          <div className="input-feedback">{errors["webhook_api_key_name"]}</div>
        )}
      </div>
      {values.webhook_api_key_name && (
        <FormInput
          fieldName={`Webhook API Key Value:`}
          name={`webhook_api_key_value`}
          id={`webhook_api_key_value`}
          handleChange={handleChange}
          touched={touched}
          errors={errors}
          autoComplete="off"
          readOnly={true}
          isMasked={true}
        />
      )}
      {enableWebhookToggle && (
        <div className="mb-15">
          <div className="enable-notifications-toggle">
            <label className="label label__padding-left">
              {`Enable / Disable ${connectorName}  Webhook`}
            </label>
            <>
              <label className="switch">
                <input
                  type="checkbox"
                  name="webhook_status"
                  id="webhook_status"
                  value={values.webhook_status}
                  onClick={handleClick}
                  defaultChecked={values.webhook_status}
                />
                <span className="slider round"></span>
              </label>
            </>
          </div>
        </div>
      )}
    </>
  );
};

export default WebhookForm;
