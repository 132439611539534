export const dateTimeDisplayFormat: string = "ddd D MMM YYYY HH:mm:ss.SSS";
export const dateTimeOnHoverDisplayFormat: string =
  "ddd D MMM YYYY HH:mm:ss.SSS";
export const prohibitedPrefixesForRunbookName: string[] = [
  "aws",
  "amazon",
  "amzn",
];
export const draftStatus: string = "Draft";

export const executionStatusMap = {
  Success: "Succeeded",
  Pending: "Pending",
  Failed: "Failed",
  Cancelled: "Cancelled",
  Waiting: "Waiting",
  InProgress: "InProgress",
  TimedOut: "TimedOut",
};

export const executionStatusColorMap = {
  Succeeded: "#3dbb61",
  Pending: "#fd9b3f",
  Failed: "#fb4b53",
  Cancelled: "#bebebecc",
  Waiting: "#fdda3f",
  InProgress: "#8f598f",
  TimedOut: "#dfdfdf",
  all: "#6300ff",
};

export const lowerCaseRegex = /[a-z]/;
export const upperCaseRegex = /[A-Z]/;
export const numericRegex = /[0-9]/;
export const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/g;

export const workflowLimit =
  "You have reached your current plan's limit for total workflows. You can upgrade the plans by following the View Details link below.";
export const runsLimit =
  "You have reached your current plan's limit for total workflow runs per month. You can upgrade the plans by following the View Details link below.";

export const MIN_SUPPORTED_WIDTH = 990;

export const defaultExecutionTimeFilter = "1 day";
