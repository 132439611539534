import {
  SERVICES_NEEDED,
  IS_FETCHING_SERVICES,
  IS_FETCHING_OPERATIONS,
  SET_SERVICES,
  SET_OPERATIONS,
  SET_SELECTED_SERVICE,
  SET_SELECTED_OPERATION,
  SET_OPERATION_DETAILS,
  SET_OUTPUTS,
  SET_SELECTED_OUTPUT,
  IS_FETCHING_OPERATION_DETAILS,
} from "../types";
import produce from "immer";

const INITIAL_STATE = {
  servicesNeeded: true,
  operations: [],
  services: [],
  operationDetails: {},
  isFetchingServices: false,
  isFetchingOperations: false,
  selectedService: null,
  serviceOperationsCache: {},
  selectedOperation: null,
  isFetchingOperationDetails: false,
  outputs: {},
  selectedOutput: null,
};

export default (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    switch (action.type) {
      case SERVICES_NEEDED:
        draft.servicesNeeded = action.payload;
        break;

      case IS_FETCHING_SERVICES:
        draft.isFetchingServices = action.payload;
        break;

      case IS_FETCHING_OPERATIONS:
        draft.isFetchingOperations = action.payload;
        break;

      case SET_SERVICES:
        draft.services = action.payload;
        break;

      case SET_OPERATIONS:
        const key = action.payload.selectedService;
        draft.serviceOperationsCache[key] = action.payload.operations;
        draft.operations = action.payload.operations;
        break;

      case SET_OPERATION_DETAILS:
        draft.operationDetails = action.payload;
        break;

      case IS_FETCHING_OPERATION_DETAILS:
        draft.isFetchingOperationDetails = action.payload;
        break;

      case SET_SELECTED_OPERATION:
        draft.selectedOperation = action.payload;
        break;

      case SET_SELECTED_SERVICE:
        draft.selectedService = action.payload;
        break;

      case SET_OUTPUTS:
        draft.outputs[action.key] = action.payload;
        break;

      case SET_SELECTED_OUTPUT:
        draft.selectedOutput = action.payload;
        break;

      default:
        break;
    }
  });
