import React from "react";
import * as SelectLifecycles from "../lib/select-selected.lifecycles";
import * as SelectEvents from "../lib/select-selected.events";
import * as SelectRender from "../lib/select-selected.render";

export default class SelectSelected extends React.PureComponent {
  constructor(props) {
    super(props);
    SelectLifecycles.initState.call(this);
  }

  componentDidMount() {
    SelectLifecycles.didMount.call(this);
  }

  componentDidUpdate(prevProps) {
    SelectLifecycles.didUpdate.call(this, prevProps);
  }

  /* we have the option to search via a text input, this method is called when that input is clicked on */
  inputClick = e => {
    if (!this.props.expanded) {
      this.props.setStaticValue("", this.props.value);
    }
    SelectEvents.inputClick.call(this, e);
  };

  /* this gets called when we are inputing new data into the search area via keystrokes, 
  but does not get called when a new option is selected */
  onChange = e => {
    SelectEvents.onChange.call(this, e);
  };

  /* if there is no set text value, we can set the default display to the title */
  setTitleText = () => {
    SelectEvents.setTitleText.call(this);
  };

  /* every time we strike a key and we are in the search input, this will get called */
  keyPress = e => {
    SelectEvents.keyPress.call(this, e);
  };

  /* clears out the search area */
  clear = e => {
    SelectEvents.clear.call(this, e);
  };

  /* we actually set the value onmount or when an option has changed, but not on keypress during a search ...*/
  setValue = () => {
    SelectEvents.setValue.call(this);
  };

  render() {
    return (
      <React.Fragment>
        {SelectRender.renderDefault.call(this)}
        {this.props.searchable
          ? SelectRender.renderSearchable.call(this)
          : SelectRender.renderNonSearchable.call(this)}

        {this.props.showArrow && SelectRender.renderArrow.call(this)}
      </React.Fragment>
    );
  }
}
