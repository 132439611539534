import React from "react";
import {
  snakeToPascal,
  camelToSnake,
  isEmpty,
  getNodeDisplayName,
} from "@lib/utils";
import InputsRequired from "./InputsRequired";
import InputsOptional from "./InputsOptional";
import { updateActionNode } from "./input.helpers";
import ReactSelect from "@components/ui/React-Select/ReactSelect";

export default class ActionNodeInputOptions extends React.Component {
  state = {
    operationDetailsNeeded: true,
  };

  componentDidMount() {
    this.initServiceAndOperation();
  }

  componentDidUpdate(prevProps) {
    this.setOperationDetails(prevProps);
  }

  initServiceAndOperation = () => {
    let { service, operation } = this.props.runbookNode;
    this.props.setSelectedService(service);
    if (operation === "undefined") {
      operation = null;
    }
    if (operation) {
      operation = camelToSnake(operation);
    }
    this.setSelectedOperation(operation);
    if (this.props.servicesNeeded) {
      this.props.fetchServices();
    }
  };

  setService = newValue => {
    this.props.runbookNode.service = newValue;
    this.props.runbookNode.operation = null;
    this.props.setSelectedService(camelToSnake(newValue));
    this.props.runbookNode.showHideWarning(
      !this.props.runbookNode.isHealthyStep(),
    );
  };

  setSelectedOperation = operation => {
    this.props.setSelectedOperation(operation);
  };

  setOperationDetails = prevProps => {
    if (!this.state.operationDetailsNeeded) {
      return;
    }
    if (
      prevProps.isFetchingOperationDetails &&
      !this.props.isFetchingOperationDetails
    ) {
      updateActionNode(this);
    }
  };

  updateService = newValue => {
    this.setService(newValue);
    this.props.setSelectedOperation();
    this.props.notifyRunbookUpdate(true);
  };

  updateOperation = newValue => {
    this.setSelectedOperation(camelToSnake(newValue));
    this.setState({
      operationDetailsNeeded: true,
    });
    this.props.notifyRunbookUpdate(true);
  };

  operationsOnClear = () => {
    this.setSelectedOperation(null);
    updateActionNode(this);
    this.props.notifyRunbookUpdate(true);
  };

  getServicesSelect = () => {
    return (
      <React.Fragment>
        <span className="label-custom"> Service </span>

        {!this.props.isFetchingServices ? (
          <div className="editor-detail-panel editor-detail-panel-column pt-0 mb-0">
            <div className="editor-aws-container">
              <ReactSelect
                id="aws-services"
                name="aws-services"
                value={{
                  value: this.props.selectedService
                    ? snakeToPascal(this.props.selectedService)
                    : "",
                  label: this.props.selectedService
                    ? snakeToPascal(this.props.selectedService)
                    : "Select from below",
                }}
                isLoading={!this.props.services?.length}
                handleChange={data => {
                  !isEmpty(data) &&
                    !!data.value &&
                    this.updateService(data.value);
                }}
                selectOptions={
                  this.props.services && Array.isArray(this.props.services)
                    ? this.props.services.map(service => {
                        return {
                          value: snakeToPascal(service),
                          label: snakeToPascal(service),
                        };
                      })
                    : []
                }
                required
              />
            </div>
          </div>
        ) : (
          <div className="editor-is-fetching mb-5">...fetching services</div>
        )}
      </React.Fragment>
    );
  };

  getOperationsSelect = () => {
    return (
      <React.Fragment>
        <span className="label-custom mt-10">Operation</span>
        {!this.props.isFetchingOperations ? (
          <div className="editor-detail-panel editor-detail-panel-column pt-0">
            <div className="editor-aws-container">
              <ReactSelect
                id="aws-operations"
                name="aws-operations"
                value={{
                  value: this.props.selectedOperation
                    ? snakeToPascal(this.props.selectedOperation)
                    : "",
                  label: this.props.selectedOperation
                    ? snakeToPascal(this.props.selectedOperation)
                    : "Select from below",
                }}
                isLoading={!this.props.operations?.length}
                handleChange={data => {
                  !isEmpty(data) &&
                    !!data.value &&
                    this.updateOperation(data.value);
                }}
                selectOptions={
                  this.props.operations && Array.isArray(this.props.operations)
                    ? this.props.operations.map(operation => {
                        return {
                          value: snakeToPascal(operation),
                          label: snakeToPascal(operation),
                        };
                      })
                    : []
                }
                required
              />
            </div>
          </div>
        ) : (
          <div className="editor-is-fetching mb-5">...fetching operations</div>
        )}
      </React.Fragment>
    );
  };

  getHeader = runbookNode => (
    <h4 className="editor-node-name">
      {!!this.props.selectedService &&
      !!this.props.selectedOperation &&
      !this.props.isFetchingOperationDetails
        ? getNodeDisplayName(runbookNode.name)
        : "AWS Action Node"}
    </h4>
  );

  render() {
    const { runbookNode } = this.props;
    const inputs = runbookNode.parameterInputs;
    const requiredInputs = inputs && inputs.filter(input => input.required);
    const optionalInputs = inputs && inputs.filter(input => !input.required);
    return (
      <div className="d-flex flex-column2">
        {this.getHeader(runbookNode)}

        {this.getServicesSelect()}

        {this.getOperationsSelect()}

        <InputsRequired
          requiredInputs={requiredInputs}
          optionFilterId={this.props.optionFilterId}
          toggleInputOption={this.props.toggleInputOption}
          selectedAwsOperation={this.props.selectedOperation}
        />

        <InputsOptional
          optionalInputs={optionalInputs}
          optionFilterId={this.props.optionFilterId}
          toggleInputOption={this.props.toggleInputOption}
          selectedAwsOperation={this.props.selectedOperation}
        />
      </div>
    );
  }
}
