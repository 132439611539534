import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useRef,
} from "react";
import "./RequiredInputs.scss";
import { Accordion, AccordionLabel, AccordionSection } from "@components/ui";
import { RunbookEditorContext } from "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.context";
import { RunbookStepInputSource } from "@containers/RunbookEditor/runbook-editor-lib/ssm/nodeinputoutput";
import { isEmpty, sortResourceList } from "@lib/utils";
import ReactSelect from "@components/ui/React-Select/ReactSelect";
import Api from "@lib/api/api";

type RequiredInputsProps = {};

const RequiredInputs: React.FC<RequiredInputsProps> = () => {
  const isFirstRun = useRef(true);
  const context = useContext(RunbookEditorContext) as any;
  const runbookNode = context.activeNode.extras.runbookNode;
  const notifyRunbookUpdate = context.notifyRunbookUpdate;
  const aliasList = sortResourceList(context.resourcesList.targets);
  const [repositoriesList, setRepositoriesList] = useState([]);
  const [imageList, setImageList] = useState([]);
  const [selectedAlias, setSelectedAlias] = useState("");
  const [selectedRepository, setSelectedRepository] = useState("");
  const [selectedImageDigest, setSelectedImageDigest] = useState("");
  const [isLoadingRepository, setIsLoadingRepository] = useState(false);

  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const getParamInput = useCallback(
    (input: string) => runbookNode.parameterInputs.find(p => p.name === input),
    [runbookNode.parameterInputs],
  );

  useEffect(() => {
    if (runbookNode)
      runbookNode.showHideWarning(
        !selectedAlias || !selectedRepository || !selectedImageDigest,
      );
  }, [runbookNode, selectedImageDigest, selectedAlias, selectedRepository]);

  const getRepositories = useCallback(async (alias: string) => {
    setIsLoadingRepository(true);
    try {
      const repositoriesList = await Api.fetchRepositories(alias);
      setIsLoadingRepository(false);
      setRepositoriesList(repositoriesList);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const getRepositoryImages = useCallback(
    async (repository: string) => {
      setIsLoadingImage(true);
      try {
        const imageList = await Api.fetchImages(selectedAlias, repository);
        setIsLoadingImage(false);
        setImageList(imageList);
      } catch (error) {
        console.log(error);
      }
    },
    [selectedAlias],
  );

  const setInitialParamValues = useCallback(() => {
    const alias = getParamInput("alias").source?.sourceValue;
    if (alias && alias.constructor.name === "String") {
      setSelectedAlias(alias);
    }

    const repository = getParamInput("repository").source?.sourceValue;
    if (repository && repository.constructor.name === "String") {
      setSelectedRepository(repository);
    }

    const digest = getParamInput("digest").source?.sourceValue;
    setSelectedImageDigest(digest?.constructor.name === "String" ? digest : "");
  }, [getParamInput]);

  const setSnippetPayload = useCallback(
    imageDigest => {
      let alias = getParamInput("alias");
      alias.source = new RunbookStepInputSource(`constant`, selectedAlias);
      let repository = getParamInput("repository");
      repository.source = new RunbookStepInputSource(
        `constant`,
        selectedRepository,
      );
      let digest = getParamInput("digest");
      digest.source = new RunbookStepInputSource(`constant`, imageDigest);
      notifyRunbookUpdate(true);
    },
    [getParamInput, notifyRunbookUpdate, selectedAlias, selectedRepository],
  );

  useEffect(() => {
    if (isFirstRun.current) {
      setInitialParamValues();
      isFirstRun.current = false;
    }
  }, [setInitialParamValues]);

  useEffect(() => {
    !!selectedAlias && getRepositories(selectedAlias);
  }, [selectedAlias, getRepositories]);

  useEffect(() => {
    !!selectedAlias &&
      !!selectedRepository &&
      getRepositoryImages(selectedRepository);
  }, [selectedAlias, selectedRepository, getRepositoryImages]);

  useEffect(() => {
    setSnippetPayload(selectedImageDigest);
  }, [selectedImageDigest, setSnippetPayload]);

  return (
    <Accordion isExpanded={true} useArrow={true}>
      <AccordionLabel className="editor-accordion-label margin-top-10">
        Required Inputs
      </AccordionLabel>
      <AccordionSection>
        <div className="ci">
          <div className="ci-input">
            <label className="ci-input-label">Select Alias</label>
            <ReactSelect
              id="container-alias"
              name="container-alias"
              value={{
                value: selectedAlias,
                label: selectedAlias ? selectedAlias : "Select from below",
              }}
              handleChange={data => {
                if (!isEmpty(data) && !!data.value) {
                  setSelectedAlias(data.value);
                  setSelectedRepository("");
                }
              }}
              selectOptions={aliasList.map(r => {
                return {
                  value: r.alias,
                  label: r.alias,
                };
              })}
              required
            />
          </div>
          {!!selectedAlias && (
            <div className="ci-input mt-20">
              {isLoadingRepository ? (
                <label className="ci-input-label loading-text">
                  <i>Loading Repositories...</i>
                </label>
              ) : (
                <>
                  <label className="ci-input-label">Select Repository</label>
                  <ReactSelect
                    id="container-repositories"
                    name="container-repositories"
                    value={{
                      value: selectedRepository,
                      label: selectedRepository
                        ? selectedRepository
                        : "Select from below",
                    }}
                    handleChange={data => {
                      if (!isEmpty(data) && !!data.value) {
                        setSelectedRepository(data.value);
                        setSelectedImageDigest("");
                      }
                    }}
                    selectOptions={repositoriesList.map(r => {
                      return {
                        value: r,
                        label: r,
                      };
                    })}
                    required
                  />
                </>
              )}
            </div>
          )}
          {!!selectedRepository && (
            <div className="ci-input mt-20">
              {isLoadingImage ? (
                <label className="ci-input-label loading-text">
                  <i>Loading Images...</i>
                </label>
              ) : (
                <>
                  <label className="ci-input-label">Select Image</label>
                  <ReactSelect
                    id="container-repositories"
                    name="container-repositories"
                    value={{
                      value: selectedImageDigest,
                      label: selectedImageDigest || "Select from below",
                    }}
                    handleChange={data => {
                      !isEmpty(data) &&
                        !!data.value &&
                        setSelectedImageDigest(data.value);
                    }}
                    selectOptions={imageList.map(i => {
                      return {
                        value: i.digest,
                        label: i.digest,
                      };
                    })}
                    required
                  />
                </>
              )}
            </div>
          )}
        </div>
      </AccordionSection>
    </Accordion>
  );
};

export default RequiredInputs;
