import React, { useState } from "react";
import Button from "@components/ui/button/button";
import { Link } from "react-router-dom";
import PasswordStrength from "@components/authentication/utils/PasswordStrength";
import visibleIcon from "@assets/images/icons/eye-icon__allowed.svg";
import invisibleIcon from "@assets/images/icons/icon-eye__not-allowed.svg";
import { keyUpHandler } from "@components/authentication/utils/helpers";

type SetUserNameProps = {
  formik: any;
};

const submitBtnStyle = { width: "100%" };
export const SetUserName: React.FC<SetUserNameProps> = ({
  formik,
  ...props
}) => {
  return (
    <>
      <div className="fp-card-subheader">
        Enter your email address and we will send you a confirmation code to
        reset password.
      </div>
      <div className="fp-form-input">
        <label className="fp-form-input-label">Email Address</label>
        <input
          type="email"
          id="email"
          placeholder="johndoe@acme.com"
          className={`fp-form-input-text ${
            formik.errors.email && formik.touched.email && "error"
          }`}
          aria-describedby="basic-addon1"
          {...formik.getFieldProps("email")}
          onKeyUp={e =>
            keyUpHandler(e, formik.handleSubmit, formik.isSubmitting)
          }
          autoComplete="off"
          autoFocus
        />
        {formik.errors.email && formik.touched.email ? (
          <div className="input-feedback">{formik.errors.email}</div>
        ) : null}
      </div>
      <div className="form-footer">
        <Button
          text="Send Reset Code"
          style={submitBtnStyle}
          buttonStyle="primary"
          size="large"
          onClick={formik.handleSubmit}
          className="fp-form-submit-btn"
          disabled={formik.isSubmitting}
        />
      </div>
      <div className="fp-form-navigator-container">
        <Link to={"/"} className="fp-form-navigator-link">
          Back to Sign In
        </Link>
      </div>
    </>
  );
};

type SetPasswordProps = {
  formik: any;
  setShowAuthCode: (flag: boolean) => void;
};

export const SetPassword: React.FC<SetPasswordProps> = ({
  formik,
  setShowAuthCode,
  ...props
}) => {
  const [passwordVisible, togglePasswordVisibility] = useState<Boolean>(false);
  const [confirmPasswordVisible, toggleConfirmPasswordVisible] = useState(
    false,
  );
  const changePasswordVisibility = () =>
    togglePasswordVisibility(!passwordVisible);

  const changeConfirmPasswordVisibility = () =>
    toggleConfirmPasswordVisible(!confirmPasswordVisible);
  const [isPasswordStrong, setStrongPasswordFlag] = useState(false);

  return (
    <>
      <div className="fp-card-subheader">
        Enter confirmation code and create a new password.
      </div>
      <div className="fp-form-input">
        <label className="fp-form-input-label">Confirmation Code</label>
        <input
          id="activationCode"
          className={`fp-form-input-text ${
            formik.errors.activationCode &&
            formik.touched.activationCode &&
            "error"
          }`}
          aria-describedby="basic-addon1"
          type="text"
          {...formik.getFieldProps("activationCode")}
          onKeyUp={e =>
            keyUpHandler(e, formik.handleSubmit, formik.isSubmitting)
          }
          autoComplete="off"
          autoFocus
        />
        {formik.errors.activationCode && formik.touched.activationCode ? (
          <div className="input-feedback">{formik.errors.activationCode}</div>
        ) : null}
      </div>
      <div className="fp-form-input">
        <label className="fp-form-input-label">New Password</label>
        <div className="d-flex">
          <input
            id="password"
            className={`fp-form-input-text ${
              formik.errors.password && formik.touched.password && "error"
            }`}
            aria-describedby="basic-addon1"
            type={passwordVisible ? "text" : "password"}
            {...formik.getFieldProps("password")}
            onKeyUp={e =>
              keyUpHandler(e, formik.handleSubmit, formik.isSubmitting)
            }
            autoComplete="new-password"
          />
          <img
            onClick={changePasswordVisibility}
            src={passwordVisible ? visibleIcon : invisibleIcon}
            alt="invisibleIcon"
            className="password-eye-icon"
            width="18"
            height="18"
          />
        </div>
        {!!formik.values.password && (
          <PasswordStrength
            password={formik.values.password}
            setPasswordScore={setStrongPasswordFlag}
          />
        )}
        {formik.errors.password && formik.touched.password ? (
          <div className="input-feedback">{formik.errors.password}</div>
        ) : null}
      </div>
      <div className="fp-form-input">
        <label className="fp-form-input-label">Confirm New Password</label>
        <div className="d-flex">
          <input
            id="confirmPassword"
            className={`fp-form-input-text ${
              formik.errors.confirmPassword &&
              formik.touched.confirmPassword &&
              "error"
            }`}
            aria-describedby="basic-addon1"
            type={confirmPasswordVisible ? "text" : "password"}
            {...formik.getFieldProps("confirmPassword")}
            onKeyUp={e =>
              keyUpHandler(e, formik.handleSubmit, formik.isSubmitting)
            }
            autoComplete="new-password"
          />
          <img
            onClick={changeConfirmPasswordVisibility}
            src={confirmPasswordVisible ? visibleIcon : invisibleIcon}
            alt="invisibleIcon"
            className="password-eye-icon"
            width="18"
            height="18"
          />
        </div>
        {formik.errors.confirmPassword && formik.touched.confirmPassword ? (
          <div className="input-feedback">{formik.errors.confirmPassword}</div>
        ) : null}
      </div>
      <div className="form-footer">
        <Button
          text="Save and Sign In"
          style={submitBtnStyle}
          buttonStyle="primary"
          size="large"
          onClick={formik.handleSubmit}
          className="fp-form-submit-btn"
          disabled={formik.isSubmitting || !isPasswordStrong}
        />
      </div>
      <span
        className="pointer link-underline mt-10"
        onClick={() => setShowAuthCode(false)}
      >
        Go Back
      </span>
    </>
  );
};
