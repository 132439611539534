import React from "react";
import "../JenkinsSetup.scss";

const JenkinsInstruction = () => (
  <div className="instructions">
    <div className="pt-40 mb-40">
      <div className="list-row">
        <div className="step-number">
          <div>1</div>
        </div>
        Find your jenkins web url and port if needed, add it to the <span>Server Url</span>
        field of the 'Authorize Fylamynt' form.
      </div>

      <div className="list-row">
        <div className="step-number">
          <div>2</div>
        </div>
        <div>Login to your Jenkins Server.</div>
      </div>
      <div className="list-row">
        <div className="step-number">
          <div>3</div>
        </div>
        Go to <span>People</span> on the left menu
      </div>
      <div className="list-row">
        <div className="step-number">
          <div>4</div>
        </div>
        Select the user you want to integrate with Fylamynt and copy <span>Jenkins User
        ID</span> to the <span>Username</span> field.
      </div>
      <div className="list-row">
        <div className="step-number">
          <div>5</div>
        </div>
        Select <span>Configure</span> from the left menu, navigate to <span>API Token</span>, generate a new
        token, and paste the value in the <span>API Token</span> field.
      </div>
      <div className="list-row">
        <div className="step-number">
          <div>6</div>
        </div>
        Click <span>Authorize</span> button
      </div>
      <br/>
      <code>Note: Jenkins integration might not work when behind a proxy.</code>
    </div>
  </div>
);

export default JenkinsInstruction;
