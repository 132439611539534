import React from "react";
import { Button } from "@components/ui";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  saveConnecterSchema,
  isFetching,
} from "@redux/actions/settingsPanel.action";
import { Formik } from "formik";
import * as Yup from "yup";
import FormInput from "../../../settings-panel-components/SettingsRightConfigPanel/FormInput";
import { Wait } from "@components/ui";
import { Redirect } from "react-router";
import { RouteConstants } from "../../../../../routes/Constants";

const validationSchema = Yup.object({
  organization: Yup.string()
    .required("Organization is required")
    .trim("Organization is required"),
  token: Yup.string().required("Token is required").trim("Token is required"),
});

class RightPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      doRedirect: false,
      redirectUrl: "",
    };
  }

  goBack = () => {
    this.setState({
      doRedirect: true,
      redirectUrl: RouteConstants.settings.url,
    });
  };

  onSubmitHandle = async (accountDetails, actions) => {
    this.props.isFetching(true, "Integration in progress");
    actions.setSubmitting(true);
    const payload = { credentials: accountDetails };
    await this.props.saveConnecterSchema(payload, "terraform");
    actions.setSubmitting(false);
    this.props.isFetching(false, "");
    if (this.props.formSubmittedSuccess) {
      actions.setStatus({
        formSubmitMessage: "Connector details saved successfully!!!",
      });
      setTimeout(() => {
        this.goBack();
      }, 1500);
    } else {
      actions.setStatus({
        formSubmitMessage: "Oops! Something went wrong !!!",
      });
    }
  };

  render() {
    return (
      <>
        {this.state.doRedirect && <Redirect to={this.state.redirectUrl} push />}
        {this.props.message && <Wait text={this.props.message} />}
        <div className="instructions__right-terraform">
          <div className="onboarding-form">
            <div className="onboarding-header-wrap">
              <h2>Authorize Fylamynt</h2>
            </div>
            <div className="onboarding-card">
              <Formik
                initialValues={{
                  organization: "",
                  token: "",
                }}
                validationSchema={validationSchema}
                onSubmit={this.onSubmitHandle}
              >
                {formik => (
                  <>
                    <div className="account-info__wrap">
                      <FormInput
                        fieldName="Organization"
                        subText="(Terraform Cloud organization)"
                        name="organization"
                        id="organization"
                        handleChange={formik.handleChange}
                        touched={formik.touched}
                        errors={formik.errors}
                        autoComplete="off"
                      />
                    </div>
                    <div className="account-info__wrap">
                      <FormInput
                        fieldName="Token"
                        subText=" Terraform Cloud API token (User or team token only)"
                        name="token"
                        id="token"
                        handleChange={formik.handleChange}
                        touched={formik.touched}
                        errors={formik.errors}
                        autoComplete="off"
                      />
                    </div>

                    <div className="form-footer">
                      <Button
                        text="Cancel"
                        buttonStyle="secondary"
                        style={{ width: "48%", marginRight: "1rem" }}
                        size="large"
                        type="button"
                        onClick={this.goBack}
                      />
                      <Button
                        text="Authorize"
                        style={{ width: "48%" }}
                        buttonStyle="primary"
                        size="large"
                        onClick={formik.handleSubmit}
                      />
                    </div>

                    <div style={{ marginTop: "1rem" }}>
                      {!!formik.status && formik.status.formSubmitMessage}
                    </div>
                  </>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  message: state.runbooksReducer.message,
  formSubmittedSuccess: state.settingsPanelReducer.formSubmittedSuccess,
});
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      saveConnecterSchema,
      isFetching,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(RightPanel);
