import React from "react";
import "./ActionNodeOption.scss";
import { Modal } from "@components/ui";
import ModalSelectOutput from "./ModalSelectOutput";
import { connect } from "react-redux";
import { SET_SELECTED_OUTPUT } from "@redux/types";
import { hasKeys } from "@lib/utils";
//import { truncate } from "@lib/utils";

class ActionNodeOption extends React.PureComponent {
  state = {
    showModal: false,
    selectorValue: "",
  };

  componentDidMount() {
    if (
      typeof this.props.consumingInput?.source?.sourceValue === "object" &&
      hasKeys(this.props.consumingInput, "source.sourceValue.selector")
    ) {
      this.setState({
        selectorValue: this.props.consumingInput.source.sourceValue.selector,
      });
    } else {
      this.setState({
        selectorValue: this.props.selector,
      });
    }
  }

  componentDidUpdate(prevProps) {
    this.updateSelectedOutput(prevProps);
  }

  componentWillUnmount() {
    this.props.updateSelectedOutput();
  }

  updateSelectedOutput = prevProps => {
    if (
      !this.props.onlyCaptureSelector &&
      this.props.selectedOutput &&
      prevProps.selectedOutput !== this.props.selectedOutput
    ) {
      console.log(`updateSelectedOutput`, {
        consumingInput: this.props.consumingInput,
      });

      /**
       * Following code is to persist input source value in rule set for conditional node
       * in case of AWS snippet in case of new workflow design
       */
      const inputs = this.props.consumingInput?.snippetAction?.inputs;
      if (
        !!inputs &&
        inputs.Choices &&
        inputs.Choices[0]?.Variable === "{{ choiceParameter }}"
      ) {
        let variable = `${this.props.name}.${this.props.consumingInput?.source?.sourceValue?.name}`;
        inputs.Choices[0].Variable = `{{ ${variable} }}`;
        this.props.consumingInput.choice.condition.variable = `{{ ${variable} }}`;
      }

      if (
        typeof this.props.consumingInput?.source?.sourceValue === "object" &&
        hasKeys(this.props.consumingInput, "source.sourceValue.selector")
      ) {
        console.log(`setting selectorValue state`);
        this.setState(
          {
            selectorValue: this.props.consumingInput.source.sourceValue
              .selector,
          },
          () => {
            this.props.notifyRunbookUpdate(true);
          },
        );
      }
    } else if (this.props.onlyCaptureSelector) {
      // we're using this just to get a string, not to set an output
      console.log(
        "we're using this just to get a string, not to set an output",
        { props: this.props },
      );
      this.setState({ selectorValue: this.props.selector });
    }
  };

  modalCallback = () => {
    this.setState({
      showModal: false,
    });
  };

  toggleModal = () => {
    this.setState(prevState => ({
      showModal: !prevState.showModal,
    }));
  };

  render() {
    const { selectedOutput, updateHandler, onlyCaptureSelector } = this.props;

    return (
      <React.Fragment>
        <div className="aws-action-node-output-label">Output</div>
        <div className="mt-5" onClick={this.toggleModal}>
          <div
            title={selectedOutput || this.props.selector}
            className="aws-action-node-output-selector"
            style={{
              width: "calc(100% - 10px)",
            }}
          >
            {this.state.selectorValue}
          </div>
          <div className="link-light-blue aws-action-node-output-selector-label">
            Configure
          </div>
        </div>
        {this.state.showModal && (
          <Modal
            onClose={this.modalCallback}
            title={"Select output from previous: " + this.props.name}
            coverClass={`modal-cover__editor`}
            containerClass={`modal-container__editor`}
            contentClass={`modal-content__editor-json`}
            contentContainerClass={`modal-content-container__editor-json`}
            titleClass={`modal-title__json`}
            closeClass={`modal-close__editor`}
            showClose={true}
            appendToPortal={true}
          >
            <ModalSelectOutput
              sourceStep={this.props.step}
              toggleModal={this.toggleModal}
              consumingInput={this.props.consumingInput}
              updateHandler={updateHandler}
              onlyCaptureSelector={onlyCaptureSelector}
            />
          </Modal>
        )}
      </React.Fragment>
    );
  }
}

const mapState = ({ actionNodeReducer }) => ({
  selectedOutput: actionNodeReducer.selectedOutput,
});

const mapDispatch = dispatch => ({
  updateSelectedOutput: output => {
    dispatch({
      type: SET_SELECTED_OUTPUT,
      payload: output,
    });
  },
});

export default connect(mapState, mapDispatch)(ActionNodeOption);
