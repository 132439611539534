import React from "react";

import TwilioDashboard from "@assets/images/screenshots/settings-twilio/twilio-dashboard.png";
import TwilioSettings from "@assets/images/screenshots/settings-twilio/twilio-settings.png";

const TwilioInstruction = () => (
  <>
    <div className="list-row pt-10">
      <div className="step-number">
        <div>1</div>
      </div>
      <div>
        Login to your Twilio Account. And you should land on the{" "}
        <b>Dashboard</b> if not, navigate to dashboard.
      </div>
    </div>
    <div className="list-image">
      <img src={TwilioDashboard} alt="TwilioDashboard" />
    </div>
    <div className="list-row">
      <div className="step-number">
        <div>2</div>
      </div>
      <div>
        Go to <b>Settings</b> --{`>`} <b>General</b>.
      </div>
    </div>
    <div className="list-image">
      <img src={TwilioSettings} alt="TwilioSettings" />
    </div>
    <div className="list-row">
      <div className="step-number">
        <div>3</div>
      </div>
      <div>
        From the <b>Live Credentials</b> section copy the <b>Account SID</b> and{" "}
        <b>Auth Token</b>
        and paste it into the respective input fields.
      </div>
    </div>
  </>
);

export default TwilioInstruction;
