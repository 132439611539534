import {
  fetchAllSnippets,
  fetchJiraDetails,
} from "@redux/actions/snippets.actions";
import {
  fetchActiveRunbook,
  fetchAwsServices,
  fetchAwsOperations,
  fetchAwsOperationDetails,
  setRunbookIsNew,
  saveNewRunbook,
  updateRunbookData,
  updateRunbook,
  unsetActiveRunbook,
  deleteRunbookVersion,
  updateDefaultVersion,
  getResourcesList,
  clearNewRunbook,
  createNewRunbook,
} from "@redux/actions/runbook.action";
import { doExecution } from "@redux/actions/execution.action";
import { addToRunbooks } from "@redux/actions/runbooks.actions";
import {
  clearActiveActionNode,
  setActiveActionNode,
  setNode,
  updateNode,
} from "@redux/actions/node.action";
import {
  getDatadogMonitorList,
  getDatadogMonitor,
} from "@redux/actions/connectors.action";
import { fetchSettingsConnectors } from "@redux/actions/settingsPanel.action";
import {
  RUNBOOK_ADD_TAG,
  RUNBOOK_REMOVE_TAG,
  RESET_NODE,
  RUNBOOK_SET_ACTIVE,
  RUNBOOK_DID_UPDATE,
  SET_WAIT_MESSAGE,
  SHOW_WAIT,
} from "@redux/types";

export const mapState = ({
  snippetsReducer,
  runbookReducer,
  nodeReducer,
  executionsReducer,
  connectorsReducer,
  runbooksReducer,
  ui,
  settingsPanelReducer,
}) => {
  return {
    isFetchingSnippets: snippetsReducer.isFetchingSnippets,
    fetchingMessage: snippetsReducer.fetchingMessage,
    message: runbookReducer.message,
    snippets: snippetsReducer.snippetDetailsList,
    snippetsByCategory: snippetsReducer.snippetsByCategory,
    versions: runbookReducer.activeRunbook?.Versions,
    activeRunbook: runbookReducer.activeRunbook,
    activeRunbookBeforeEditing: runbookReducer.activeRunbookBeforeEditing,
    activeRunbookVersion: runbookReducer.activeRunbookVersion,
    isFetchingVersion: runbookReducer.isFetchingVersion,
    isFetchingActiveRunbook: runbookReducer.isFetching,
    activeNode: nodeReducer.activeNode,
    activeActionNode: nodeReducer.activeActionNode,
    runbookNew: runbookReducer.runbookNew,
    updateRunbookData: runbookReducer.updateRunbookData,
    runbookIsSaving: runbookReducer.isSaving,
    isFetchingAwsServices: runbookReducer.isFetchingAwsServices,
    isFetchingAwsOperations: runbookReducer.isFetchingAwsOperations,
    awsServices: runbookReducer.awsServices,
    awsOperations: runbookReducer.awsOperations,
    awsOperationDetails: runbookReducer.awsOperationDetails,
    executionId: executionsReducer.executionId || "",
    runbookDidUpdate: runbookReducer.runbookDidUpdate || false,
    runbookVersionDeleteStatus: runbookReducer.runbookVersionDeleteStatus,
    datadogMonitorList: connectorsReducer.datadogMonitorList,
    currentDatadogMonitor: connectorsReducer.currentDatadogMonitor,
    datadogError: connectorsReducer.datadogError,
    isFetchingDatadogMonitorList:
      connectorsReducer.isFetchingDatadogMonitorList,
    isFetchingDatadogMonitor: connectorsReducer.isFetchingDatadogMonitor,
    isConnectingDatadogMonitor: connectorsReducer.isConnectingDatadogMonitor,
    lastAwsOperationFetched: runbookReducer.lastAwsOperationFetched,
    terraformWorkspaces: runbookReducer.terraformWorkspaces,
    resourcesList: runbookReducer.resourcesList,
    runbookSaveError: runbookReducer.runbookSaveError,
    runbookFetchError: runbooksReducer.runbookFetchError,
    isFetchingSlackChannels: snippetsReducer.isFetchingSlackChannels,
    slackChannelList: snippetsReducer.slackChannelList,
    isVersionUpdateSuccess: runbookReducer.isVersionUpdateSuccess,
    isVersionUpdateFailed: runbookReducer.isVersionUpdateFailed,
    runbookSchedule: runbookReducer.runbookSchedule,
    runbooks: runbooksReducer.runbookList,
    connectors: settingsPanelReducer.connectors,
    showLoading: runbooksReducer.showLoading,
    runbooksMessage: runbooksReducer.runbooksMessage,
    isFetchingSenderNumbers: snippetsReducer.isFetchingSenderNumbers,
    senderNumbers: snippetsReducer.senderNumbers
  };
};

export const mapDispatch = dispatch => ({
  fetchSnippets: () => dispatch(fetchAllSnippets()),
  fetchActiveRunbook: runbookId => dispatch(fetchActiveRunbook(runbookId)),
  onChangeVersionAction: (runbookId, value) => {
    dispatch(fetchActiveRunbook(runbookId, value));
  },
  setActiveNode: node => dispatch(setNode(node)),
  updateNode: (node, obj) => dispatch(updateNode(node, obj)),
  setActiveActionNode: node => dispatch(setActiveActionNode(node)),
  clearActiveActionNode: () => dispatch(clearActiveActionNode()),
  setRunbookIsNew: isNew => dispatch(setRunbookIsNew(isNew)),
  updateRunbookData: (node, Key, value) =>
    dispatch(updateRunbookData(node, Key, value)),
  doExecution: (runbookName, parameters, version) =>
    dispatch(doExecution(runbookName, parameters, version)),
  saveNewRunbook: (runbookObj, version) => {
    dispatch(saveNewRunbook(runbookObj, version));
  },
  updateRunbook: runbookObj => {
    dispatch(updateRunbook(runbookObj));
  },
  addToRunbooks: runbook => dispatch(addToRunbooks(runbook)),
  fetchAwsServices: () => dispatch(fetchAwsServices()),
  fetchAwsOperations: service => dispatch(fetchAwsOperations(service)),
  fetchAwsOperationDetails: (service, operation) =>
    dispatch(fetchAwsOperationDetails(service, operation)),
  addTagToRunbook: tag =>
    dispatch({
      type: RUNBOOK_ADD_TAG,
      payload: tag,
    }),

  removeTagFromRunbook: tag =>
    dispatch({
      type: RUNBOOK_REMOVE_TAG,
      payload: tag,
    }),

  unsetNode: () =>
    dispatch({
      type: RESET_NODE,
    }),

  unsetActiveRunbook: () => {
    dispatch(unsetActiveRunbook());
  },

  clearNewRunbook: () => {
    dispatch(clearNewRunbook());
  },

  notifyRunbookUpdate: status => {
    dispatch({
      type: RUNBOOK_DID_UPDATE,
      payload: status,
    });
    // notify window() about RUNBOOK_DID_UPDATE
    let event = new Event(RUNBOOK_DID_UPDATE);
    window.dispatchEvent(event);
    return Promise.resolve();
  },

  deleteRunbookVersion: (name, version) => {
    dispatch(deleteRunbookVersion(name, version));
  },

  getDatadogMonitorList: () => {
    dispatch(getDatadogMonitorList());
  },

  getDatadogMonitor: id => {
    dispatch(getDatadogMonitor(id));
  },

  updateDefaultVersion: (name, version) => {
    dispatch(updateDefaultVersion(name, version));
  },

  getResourcesList: () => {
    dispatch(getResourcesList());
  },

  setWaitMessage: message => {
    dispatch({
      type: SET_WAIT_MESSAGE,
      payload: message,
    });
  },

  showWaitMessage: show => {
    dispatch({
      type: SHOW_WAIT,
      payload: show,
    });
  },

  setActiveRunbook: runbook =>
    dispatch({
      type: RUNBOOK_SET_ACTIVE,
      payload: runbook,
    }),
  fetchJiraDetails: () => dispatch(fetchJiraDetails()),
  createNewRunbook: runbookName => dispatch(createNewRunbook(runbookName)),
  fetchSettingsConnectors: () => dispatch(fetchSettingsConnectors()),
});
