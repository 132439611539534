import Api from "@lib/api/api";
import {
  IS_FETCHING_SNIPPETS,
  FETCH_SNIPPETS_SUCCESS,
  FETCH_SNIPPETS_ERROR,
  FETCH_SNIPPET_DETAILS_SUCCESS,
  FETCH_SNIPPET_DETAILS_ERROR,
  FETCH_SNIPPET_IDS_SUCCESS,
  CATEGORIZE_SNIPPETS,
  SEARCH_SNIPPETS,
  FILTER_BY_SERVICE,
  RESET_FILTERS,
  SET_WAIT_MESSAGE,
  SHOW_WAIT,
  IS_FETCHING_SLACK_CHANNEL_LIST,
  FETCH_SLACK_CHANNELS_ERROR,
  FETCH_SLACK_CHANNELS_SUCCESS,
  IS_FETCHING_JIRA_DETAILS,
  FETCH_JIRA_DETAILS_SUCCESS,
  FETCH_JIRA_DETAILS_ERROR,
  FETCH_JIRA_PROJECTS_ISSUE_LIST_SUCCESS,
  FETCH_JIRA_PROJECTS_ISSUE_LIST_ERROR,
  FETCH_JIRA_PROJECT_ISSUE_FIELDS_SUCCESS,
  FETCH_JIRA_PROJECTS_ISSUE_DETAILS_SUCCESS,
  CLEAR_SELECTED_ISSUE,
  FETCH_TERRAFORM_WORKSPACE,
  FETCH_TERRAFORM_VARIABLES_SUCCESS,
  FETCH_TERRAFORM_VARIABLES_ERROR,
  FETCHING_TERRAFORM_VARIABLES,
  FETCHING_INSTANA_ENTITY_TYPES,
  FETCH_INSTANA_ENTITY_TYPES_SUCCESS,
  FETCH_INSTANA_ENTITY_TYPES_ERROR,
  FETCHING_INSTANA_ENTITY_NAMES,
  FETCH_INSTANA_ENTITY_NAMES_SUCCESS,
  FETCH_INSTANA_ENTITY_NAMES_ERROR,
  FETCH_DD_WH_PAYLOAD_SUCCESS,
  IS_FETCHING_SENDER_NUMBERS,
  FETCH_SENDER_NUMBERS_ERROR,
  FETCH_SENDER_NUMBERS_SUCCESS,
  FETCH_CW_TARGETS,
} from "@redux/types";

export function fetchSnippets() {
  return async dispatch => {
    dispatch(fetchingSnippets(true));
    const data = await Api.getSnippets().catch(e =>
      dispatch({ type: FETCH_SNIPPETS_ERROR, payload: e }),
    );
    dispatch({
      type: FETCH_SNIPPETS_SUCCESS,
      payload:
        data && Array.isArray(data)
          ? data.filter(item => !["control"].includes(item.type.toLowerCase()))
          : [],
    });
  };
}

export function fetchSlackChannelList() {
  return async dispatch => {
    dispatch(fetchingSlackChannels(true));
    dispatch(updateMessage("Fetching Slack Channel List ..."));
    const data = await Api.fetchSlackChannels("slack").catch(e =>
      dispatch({ type: FETCH_SLACK_CHANNELS_ERROR, payload: e }),
    );
    dispatch({
      type: FETCH_SLACK_CHANNELS_SUCCESS,
      payload: data,
    });
    dispatch(updateMessage(undefined, false));
  };
}

export function fetchJiraDetails() {
  return async dispatch => {
    //dispatch(fetchingJiraDetails(true));
    dispatch(updateMessage("Fetching JIRA details ...", true));
    const data = await Api.getJiraDetails()
      .catch(e => dispatch({ type: FETCH_JIRA_DETAILS_ERROR, payload: e }))
      .catch(err =>
        dispatch({
          type: FETCH_JIRA_DETAILS_ERROR,
        }),
      );
    if (data) {
      dispatch({
        type: FETCH_JIRA_DETAILS_SUCCESS,
        payload: { projects: data.projects }, // Need to change
      });
    }
    dispatch(updateMessage("", false));
  };
}

export function getJiraProjectsIssueFields(projectKey) {
  return async dispatch => {
    dispatch(updateMessage("Fetching JIRA Issue fields ...", true));
    //dispatch(fetchingJiraDetails(true));
    const data = await Api.getJiraProjectsIssueFields(projectKey);

    if (data) {
      dispatch({
        type: FETCH_JIRA_PROJECT_ISSUE_FIELDS_SUCCESS,
        payload: {
          issueTypes: data.issue_types,
          priorities: data.priorities,
          reporters: data.users,
        },
      });
    }
    //dispatch(updateMessage("", false));
  };
}

export function clearSelectedIssue() {
  return {
    type: CLEAR_SELECTED_ISSUE,
  };
}

export const getProjectsIssueList = projectKey => {
  return async dispatch => {
    dispatch(fetchingJiraDetails(true));
    dispatch(updateMessage("Fetching JIRA issue list ...", true));
    const data = await Api.getJiraProjectsIssueList(projectKey).catch(e =>
      dispatch({ type: FETCH_JIRA_PROJECTS_ISSUE_LIST_ERROR, payload: e }),
    );

    if (data) {
      dispatch({
        type: FETCH_JIRA_PROJECTS_ISSUE_LIST_SUCCESS,
        payload: data.issues,
      });
    }
    dispatch(updateMessage("", false));
  };
};

export const getProjectsIssueDetail = issueId => {
  return async dispatch => {
    //dispatch(fetchingJiraDetails(true));
    dispatch(updateMessage("Fetching JIRA issue details ..."));
    const data = await Api.getProjectsIssueDetail(issueId);

    if (data) {
      dispatch({
        type: FETCH_JIRA_PROJECTS_ISSUE_DETAILS_SUCCESS,
        payload: data,
      });
    }
    dispatch(updateMessage(""));
  };
};

export const fetchCloudwatchTargetAccounts = () => {
  return async dispatch => {
    const data = await Api.fetchCloudwatchTargetAccounts();
    dispatch({ type: FETCH_CW_TARGETS, payload: data });
  };
};

/* this is for the runbooks editor page, we need additional information on the snippets */
export function fetchAllSnippets() {
  return async dispatch => {
    dispatch(fetchingSnippets(true));
    const data = await Api.getSnippets().catch(e =>
      dispatch({ type: FETCH_SNIPPETS_ERROR, payload: e }),
    );
    dispatch({ type: FETCH_SNIPPET_IDS_SUCCESS, payload: data });
    dispatch(fetchSnippetDetails(data));
  };
}

/* TERRAFORM CALLS */
export function getTerraformWorkspaces() {
  return async dispatch => {
    let data = await Api.getTerraformWorkspaces();
    dispatch({
      type: FETCH_TERRAFORM_WORKSPACE,
      payload: data,
    });
  };
}

export function fetchTerraformVariables(workspaceId) {
  return async dispatch => {
    try {
      dispatch({
        type: FETCHING_TERRAFORM_VARIABLES,
        payload: true,
      });
      let data = await Api.fetchTerraformVariables(workspaceId);
      dispatch({
        type: FETCHING_TERRAFORM_VARIABLES,
        payload: false,
      });
      return dispatch({
        type: FETCH_TERRAFORM_VARIABLES_SUCCESS,
        payload: data,
      });
    } catch (e) {
      dispatch({
        type: FETCHING_TERRAFORM_VARIABLES,
        payload: false,
      });
      return dispatch({
        type: FETCH_TERRAFORM_VARIABLES_ERROR,
        payload: e,
      });
    }
  };
}

export function updateMessage(message = "", showWait = true) {
  return async dispatch => {
    dispatch({
      type: SET_WAIT_MESSAGE,
      payload: message,
    });
    if (!message) {
      showWait = false;
    }
    dispatch({
      type: SHOW_WAIT,
      payload: showWait,
    });
  };
}

export function fetchingSnippets(bool) {
  return async dispatch =>
    dispatch({
      type: IS_FETCHING_SNIPPETS,
      payload: bool,
    });
}

export function fetchingSlackChannels(bool) {
  return async dispatch =>
    dispatch({
      type: IS_FETCHING_SLACK_CHANNEL_LIST,
      payload: bool,
    });
}

export function fetchingJiraDetails(bool) {
  return async dispatch =>
    dispatch({
      type: IS_FETCHING_JIRA_DETAILS,
      payload: bool,
    });
}

export function searchingSnippets(val) {
  return dispatch =>
    dispatch({
      type: SEARCH_SNIPPETS,
      payload: val,
    });
}

export function resetFilters() {
  return dispatch =>
    dispatch({
      type: RESET_FILTERS,
      payload: null,
    });
}

export function filterSnippetsByService(service) {
  return dispatch =>
    dispatch({
      type: FILTER_BY_SERVICE,
      payload: service,
    });
}

export function fetchSnippetDetails(snippetIdsList) {
  if (!snippetIdsList) {
    return async dispatch => {
      dispatch({
        type: FETCH_SNIPPET_DETAILS_ERROR,
        payload: "No action ids",
      });
    };
  }
  return async dispatch => {
    // const totalNumberOfSnippets = snippetIdsList.length - 1;

    dispatch(fetchingSnippets(true));
    //dispatch(updateMessage(`Fetching ${totalNumberOfSnippets} snippets ...`));

    const snippets = await Promise.all(
      Object.values(snippetIdsList).map((snippetId: any) => {
        const snippet = snippetId.name;
        return Api.getSnippetDetails(snippet).catch(e => null);
      }),
    ).catch(e => {
      dispatch({ type: FETCH_SNIPPET_DETAILS_ERROR, payload: e });
    });

    if (snippets) {
      let nonNullSnippets = snippets.filter(snippet => snippet);
      dispatch({
        type: FETCH_SNIPPET_DETAILS_SUCCESS,
        payload: nonNullSnippets,
      });
      dispatch({
        type: CATEGORIZE_SNIPPETS,
        payload: nonNullSnippets,
      });
    }
    dispatch(fetchingSnippets(false));
    //dispatch(updateMessage());
  };
}

/**INSTANA CALLS */
export function fetchInstanaEntityTypes() {
  return async dispatch => {
    try {
      dispatch({
        type: FETCHING_INSTANA_ENTITY_TYPES,
        payload: true,
      });
      let data = await Api.fetchInstanaEntityTypes();

      dispatch({
        type: FETCHING_INSTANA_ENTITY_TYPES,
        payload: false,
      });
      return dispatch({
        type: FETCH_INSTANA_ENTITY_TYPES_SUCCESS,
        payload: data.instana_entity_types,
      });
    } catch (e) {
      dispatch({
        type: FETCHING_INSTANA_ENTITY_TYPES,
        payload: false,
      });
      return dispatch({
        type: FETCH_INSTANA_ENTITY_TYPES_ERROR,
        payload: e,
      });
    }
  };
}

export function fetchInstanaEntityNames(entityType) {
  return async dispatch => {
    try {
      dispatch({
        type: FETCHING_INSTANA_ENTITY_NAMES,
        payload: true,
      });
      let data = await Api.fetchInstanaEntityNames(entityType);
      dispatch({
        type: FETCHING_INSTANA_ENTITY_NAMES,
        payload: false,
      });
      return dispatch({
        type: FETCH_INSTANA_ENTITY_NAMES_SUCCESS,
        payload: data.instana_entity_names,
      });
    } catch (e) {
      dispatch({
        type: FETCHING_INSTANA_ENTITY_NAMES,
        payload: false,
      });
      return dispatch({
        type: FETCH_INSTANA_ENTITY_NAMES_ERROR,
        payload: e,
      });
    }
  };
}

/* DATADOG */

export function fetchDatadogWebhookPayload() {
  return async dispatch => {
    const data = await Api.getDatadogWebhookPayload();
    dispatch({ type: FETCH_DD_WH_PAYLOAD_SUCCESS, payload: data });
  };
}

export function setIsFetchingSenderNumbers(bool) {
  return async dispatch =>
    dispatch({
      type: IS_FETCHING_SENDER_NUMBERS,
      payload: bool,
    });
}

export function fetchSenderNumbers() {
  return async dispatch => {
    dispatch(setIsFetchingSenderNumbers(true));
    const data = await Api.getSendersNumber().catch(e =>
      dispatch({ type: FETCH_SENDER_NUMBERS_ERROR, payload: e }),
    );
    dispatch({
      type: FETCH_SENDER_NUMBERS_SUCCESS,
      payload: data?.body?.sender_numbers || [],
    });
  };
}
