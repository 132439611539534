import { ConditionalStep } from "./conditionalstep";
import { SnippetStep } from "./snippetstep";
import { SnippetType, ControlNames } from "./strings";
import { Step } from "../ssm/steps";
import { WaitStep, NeurOpsStatusStep } from "./waitstep";
import { ActionNodeStep } from "./actionnodestep";
import { DatadogConnectorStep } from "./DatadogConnectorStep";
import { PauseStep } from "../ssm/pausestep";
import SlackConnectorStep from "./slackConnectorStep";
import JiraConnectorStep from "./jiraConnectorStep";
import { LoopStep } from "./loopstep";
import { WaitForResourceStep } from "./waitforresourcestep";
import InstanaStep from "./InstanaStep";
import JSONPathStep from "./JSONPathStep";
import TwilioConnectorStep from "./TwilioConnectorStep";
import SplunkSearchConnectorStep from "./SplunkSearchConnectorStep";
import JenkinsBuildStep from "./JenkinsBuildStep";
import TriggerStep from "./TriggerStep";

export function stepFromControlNodeData(data) {
  let step;
  // Set the runbookNode to be an Action object from our model.
  if (["Action_Node", "AWS"].includes(data.extras.name)) {
    return ActionNodeStep.createNewActionNode(data.extras, "ec2");
  } else if (data.extras.name === "Wait_For_Resource") {
    return WaitForResourceStep.create(data.extras, "ec2", "describe_instances");
  }
  switch (data.extras.type) {
    case SnippetType.CONTROL:
    case SnippetType.CONNECTOR:
      step = stepFromControlDef(data.extras);
      break;
    case SnippetType.TRIGGER:
      /**
       * TODO: Move the notifyRunbookUpdate method
       * on input update from step files to the editor panel files
       * for entries in customSnippets Array
       */

      const customSnippets = [
        ControlNames.InstanaAlert,
        ControlNames.DatadogAlert,
        //ControlNames.CloudwatchAlert,
      ];
      if (customSnippets.includes(data.extras?.name)) {
        step = stepFromControlDef(data.extras);
      } else {
        step = TriggerStep.fromSnippetDefinition(data.extras);
      }
      break;
    case SnippetType.SNIPPET:
      step = SnippetStep.fromSnippetDefinition(data.extras);
      break;
    default:
      break;
  }
  return step;
}

function stepFromControlDef(snippetDef) {
  switch (snippetDef.name) {
    case ControlNames.Conditional:
    case ControlNames.CheckELBResult:
    case ControlNames.CheckExeStatus:
      return ConditionalStep.fromControlNodeDefinition(snippetDef);
    case ControlNames.Wait:
      return WaitStep.fromControlNodeDefinition(snippetDef);
    case ControlNames.Success:
    case ControlNames.Fail:
    case ControlNames.Stop:
      return NeurOpsStatusStep.fromControlNodeDefinition(snippetDef);
    case ControlNames.Pause:
      return PauseStep.fromControlNodeDefinition(snippetDef);
    case ControlNames.DatadogAlert:
      return DatadogConnectorStep.fromControlNodeDefinition(snippetDef);
    case ControlNames.SlackConnector:
      return SlackConnectorStep.fromControlNodeDefinition(snippetDef);
    case ControlNames.JiraConnector:
    case ControlNames.JiraUpdateConnector:
      return JiraConnectorStep.fromControlNodeDefinition(snippetDef);
    case ControlNames.LoopForEach:
      return LoopStep.fromControlNodeDefinition(snippetDef);
    case ControlNames.InstanaAlert:
      return InstanaStep.fromControlNodeDef(snippetDef);
    case ControlNames.JSONPathStep:
      return JSONPathStep.fromControlNodeDefinition(snippetDef);
    case ControlNames.TwilioSendSMS:
      return TwilioConnectorStep.fromControlNodeDefinition(snippetDef);
    case ControlNames.SplunkSearch:
      return SplunkSearchConnectorStep.fromControlNodeDefinition(snippetDef);
    case ControlNames.JenkinsBuild:
      return JenkinsBuildStep.fromControlNodeDefinition(snippetDef)
    default:
      const name = `${snippetDef.name}_${Math.floor(
        1000 + 9000 * Math.random(),
      )}`;
      const ssm = { ...snippetDef.content, name: name };
      return new Step(ssm);
  }
}
