import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import AWSOperationSelector from "../../aws-operation-selector";
import { InputSelector } from "@containers/RunbookEditor/runbook-editor-components/editor-right-panel/input-selector";
import { DropDown, DropDownOption, LabelTop } from "@components/ui";
import { getSelectedOption } from "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.helpers";

import { InputGroup } from "./";

function LoopForEachInput(props) {
  const { runbookNode, notifyRunbookUpdate } = props;

  const [loopParameter, setLoopParameter] = useState(runbookNode.parameter);
  const [operationDetailsReady, setOperationDetailsReady] = useState(false);

  // can we remove this? it is getting set but not read. Smells like a bug.
  // eslint-disable-next-line no-unused-vars
  const [optionFilterId, setOptionFilterId] = useState("none");

  const inputs = runbookNode.parameterInputs || [];
  const requiredInputs = inputs.filter(
    input => input.required && input.name !== loopParameter,
  );
  const optionalInputs = inputs.filter(
    input => !input.required && input.name !== loopParameter,
  );

  useEffect(() => {
    if (loopParameter && loopParameter !== runbookNode.parameter) {
      runbookNode.setParameter(loopParameter);
      notifyRunbookUpdate(true);
    }
  }, [loopParameter, notifyRunbookUpdate, runbookNode]);

  useEffect(() => {
    if (
      props.selectedService &&
      props.selectedService !== runbookNode.service
    ) {
      runbookNode.setService(props.selectedService);
      setOperationDetailsReady(false);
    }
  }, [props.selectedService, notifyRunbookUpdate, runbookNode]);

  useEffect(() => {
    if (
      props.selectedOperation &&
      props.selectedOperation !== runbookNode.operation
    ) {
      runbookNode.setOperation(props.selectedOperation);
      setOperationDetailsReady(false);
    }
  }, [props.selectedOperation, notifyRunbookUpdate, runbookNode]);

  const handleOperationDetailsReady = () => {
    if (props.operationDetails) {
      runbookNode.setOperationDetails(props.operationDetails);

      setLoopParameter(runbookNode.parameter);
      setOperationDetailsReady(true);
    }
  };
  //   const requiredInputs = inputs && inputs.filter(input => input.required);

  const loopParameterDropdown = () => {
    return operationDetailsReady ? (
      <DropDown
        title={loopParameter}
        showArrow={false}
        iconLeft="icon-filter"
        iconRight="icon-arrow-right"
        onChange={setLoopParameter}
        style={{width: "calc(100% - 8px)"}}
      >
        {inputs.map(input => (
          <DropDownOption
            text={input.name}
            key={input.name}
            className="text-small"
          />
        ))}
      </DropDown>
    ) : (
      <div className="editor-is-fetching">...fetching inputs</div>
    );
  };

  const toggleInputOption = ofId => {
    if (typeof ofId === "object") {
      const obj = ofId.target;
      if (obj.classList.contains("checkmark")) {
        this.timer = setTimeout(() => {
          setOptionFilterId(ofId);
        }, 100);
      } else {
        if (obj.type === "text") {
          return;
        }

        setOptionFilterId("none");
      }
    } else {
      setOptionFilterId(ofId);
    }
  };

  const loopInputSelector = () => {
    const input = runbookNode.itemsInput;
    return input ? (
      <InputSelector
        optionFilterId={"none"}
        toggleInputOption={toggleInputOption}
        input={input}
        selectedOption={getSelectedOption(input)}
        label="Loop over list"
      />
    ) : (
      <div className="editor-is-fetching">
        ...cannot find input ${loopParameter}
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className="editor-detail-panel editor-detail-panel-row">
        <div className="editor-detail-panel-icon editor-detail-panel-icon-select" />
        <div>{runbookNode.name}</div>
      </div>
      {loopInputSelector()}
      <div className="editor-detail-panel editor-detail-panel-column">
        <div className="label" style={{color: "#f3f3f3"}} >
          Each item is an input to the following AWS operation:
        </div>
        <div className="editor-terraform-container">
      <AWSOperationSelector
        runbookNode={runbookNode}
        handleOperationDetailsReady={handleOperationDetailsReady}
        notifyRunbookUpdate={notifyRunbookUpdate}
      />

      <LabelTop label={`Loop Parameter`} labelClassName="label" style={{marginTop: "16px"}} >
        {loopParameterDropdown()}
      </LabelTop>
      <InputGroup
        groupName="Required Inputs"
        inputs={requiredInputs}
        optionFilterId={props.optionFilterId}
        toggleInputOption={toggleInputOption}
        selectedAwsOperation={props.selectedOperation}
      />
      </div>
      </div>

      <InputGroup
        groupName="Optional Inputs"
        inputs={optionalInputs}
        optionFilterId={props.optionFilterId}
        toggleInputOption={toggleInputOption}
        selectedAwsOperation={props.selectedOperation}
      />

    </React.Fragment>
  );
}

const mapState = ({ actionNodeReducer }) => {
  return {
    operations: actionNodeReducer.operations,
    services: actionNodeReducer.services,
    selectedService: actionNodeReducer.selectedService,
    selectedOperation: actionNodeReducer.selectedOperation,
    operationDetails: actionNodeReducer.operationDetails,
  };
};

export default connect(mapState)(LoopForEachInput);
