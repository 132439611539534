import React from "react";
import "./ExecutionDetails.scss";
import { Provider } from "./execution-details-lib/execution-details.context";
import { StatusBlock } from "@components/modules";
import {
  TabCollection,
  TabContent,
  Tab,
  Wait,
  Modal,
  Button,
} from "@components/ui";
import deepEqual from "fast-deep-equal";
import {
  ExecutionDetailsHeader,
  Details,
  Inputs,
  Output,
  Steps,
} from "./execution-details-components";
import Api from "@lib/api";
import { RouteConstants } from "../../../src/routes/Constants";

export default class ExecutionDetails extends React.Component {
  state = {
    isPreparing: true,
    message: "",
    executionId: this.props.match.params.executionId,
    executionDetails: null,
    showCancelExecutionModal: false,
    cancelExecutionActionInprogress: false,
  };

  constructor(props) {
    super(props);
    this.props.fetchExecutionDetails(this.state.executionId);
  }

  componentDidUpdate() {
    if (this.props.isFetchingDetailsSuccess) {
      this.props.history.push(RouteConstants.runbooks.url);
    }
  }

  static getDerivedStateFromProps(nextProps, prevProps) {
    if (deepEqual(nextProps, prevProps) || !nextProps.executionDetails) {
      return null;
    }

    let executionId = nextProps.executionDetails.AutomationExecutionId;
    if (
      nextProps.executionDetails &&
      nextProps.websocketExecutionSteps[executionId]
    ) {
      let stepExecutions = nextProps.executionDetails.StepExecutions;
      let websocketExecutionSteps =
        nextProps.websocketExecutionSteps[executionId];
      let executedLastNode = false;

      // loop through all executions and compare them to what's coming from websocket
      for (let i = 0; i <= stepExecutions.length - 1; i++) {
        // check if current step is in websocket coming message
        if (
          stepExecutions[i].StepName &&
          websocketExecutionSteps[stepExecutions[i].StepName]
        ) {
          nextProps.executionDetails.StepExecutions[i].StepStatus =
            websocketExecutionSteps[stepExecutions[i].StepName].Status;
          nextProps.executionDetails.StepExecutions[i].ExecutionEndTime =
            websocketExecutionSteps[stepExecutions[i].StepName].EndTime;
          nextProps.executionDetails.StepExecutions[i].ExecutionStartTime =
            websocketExecutionSteps[stepExecutions[i].StepName].StartTime;
          // if current step has property of IsEnd, it means we done here ... but not.
          if (stepExecutions[i].IsEnd) {
            executedLastNode = true;
          }
        }
      }
      // update execution status from websocket
      if (nextProps.websocketExecutionFinalStatus[executionId]) {
        nextProps.executionDetails.AutomationExecutionStatus =
          nextProps.websocketExecutionFinalStatus[executionId].detail.Status;
        nextProps.executionDetails.ExecutionStartTime =
          nextProps.websocketExecutionFinalStatus[executionId].StartTime;
        nextProps.executionDetails.ExecutionEndTime =
          nextProps.websocketExecutionFinalStatus[executionId].EndTime;
        // when got message from websocket saying the status of the execution is updated, check if one of nodes had IsEnd
        if (executedLastNode) {
          nextProps.executionDetails.StepExecutions.forEach(step => {
            if (step.StepStatus === "Pending") {
              step.StepStatus = "Cancelled";
            }
          });
        }
      }
    }

    return {
      executionDetails: nextProps.executionDetails,
      isPreparing: false,
    };
  }

  getFooter = () => {
    return (
      <div className="modal-buttons-footer">
        <Button
          text="Go Back"
          buttonStyle="secondary"
          style={{ width: "50%" }}
          onClick={this.closeCancelExecutionModal}
          type="button"
        />
        <Button
          text="Confirm"
          buttonStyle="delete"
          style={{ width: "50%" }}
          className={"delete-button"}
          isLoading={this.state.cancelExecutionActionInprogress}
          onClick={this.cancelRunbookExecution}
          size="large"
        />
      </div>
    );
  };

  cancelRunbookExecution = async () => {
    this.setState({ cancelExecutionActionInprogress: true });
    await Api.cancelRunbookExecution(this.state.executionId);
    await this.props.fetchExecutionDetails(this.state.executionId);
    this.setState({ cancelExecutionActionInprogress: false });
    this.closeCancelExecutionModal();
  };

  stepActionButtonClick = (type, stepName = ``) => {
    switch (type) {
      case "Datadog":
        this.props.resumeExecution(
          this.state.executionId,
          "Datadog",
          "resume",
          this.props.connectionId,
        );
        break;
      case "Resume_Pause":
        return this.props.resumeExecution(
          this.state.executionId,
          stepName,
          "resume",
          this.props.connectionId,
        );
      default:
        break;
    }
  };

  openCancelExecutionModal = () =>
    this.setState({
      showCancelExecutionModal: true,
    });

  closeCancelExecutionModal = () =>
    this.setState({
      showCancelExecutionModal: false,
    });

  render() {
    const { isPreparing, message, executionDetails } = this.state;
    const { isFetchingDetailsSuccess } = this.props;
    const status = executionDetails
      ? executionDetails.AutomationExecutionStatus
      : "";
    const contextValue = {
      executionId: this.state.executionId,
      executionDetails: this.state.executionDetails,
      stepActionButtonClick: this.stepActionButtonClick,
      executionStatus: status,
    };
    return (
      <Provider value={contextValue}>
        <>
          {isFetchingDetailsSuccess && <Wait text={message} />}
          {!isPreparing && (
            <div className="d-flex flex-column2">
              <ExecutionDetailsHeader
                openCancelExecutionModal={this.openCancelExecutionModal}
                closeCancelExecutionModal={this.closeCancelExecutionModal}
                AutomationExecutionStatus={
                  executionDetails.AutomationExecutionStatus
                }
              />
              <StatusBlock status={status} className="pl-15 mt-10" />
              <Details executionId={this.state.executionId} />
              <TabCollection
                active="steps"
                className="margin-top-20 width-full"
                contentTop={60}
              >
                <Tab title="Steps" name="steps">
                  <TabContent>
                    <Steps />
                  </TabContent>
                </Tab>
                <Tab title="Inputs" name="inputs">
                  <TabContent>
                    <Inputs />
                  </TabContent>
                </Tab>
                <Tab title="Outputs" name="outputs">
                  <TabContent>
                    <Output />
                  </TabContent>
                </Tab>
              </TabCollection>
            </div>
          )}
          {this.state.showCancelExecutionModal &&
            ["InProgress", "Waiting"].includes(
              executionDetails.AutomationExecutionStatus,
            ) && (
              <Modal
                onClose={this.closeCancelExecutionModal}
                title={"Cancel Execution"}
                showClose={true}
                footer={this.getFooter()}
              >
                Are you sure you want to cancel{" "}
                <b>Execution: {executionDetails.DocumentName}</b> ?
              </Modal>
            )}
        </>
      </Provider>
    );
  }
}
