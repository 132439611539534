import React from "react";
import { TextInput } from "@components/ui";
import { getInputLimit } from "@containers/RunbookEditor/runbook-editor-lib/ssm/util";

export default props => {
  return(
  <TextInput
    disabled
    name={props.input.name}
    style={{ width: "100%" }}
    label={props.label || props.input.name}
    labelClassName="label"
    labelPosition="top"
    className="rule-input"
    value={"Value will be set at runtime"}
    maxLength={getInputLimit(props.input.name)}
  />
)};
