import React from "react";
import * as SelectLib from "../lib/select.lib";

export function init() {
  this.state = {
    foundText: false,
    foundClassname: "",
    foundElement: true,
    foundContainer: "",
    highlightedText: "",
    nonHighlightedText1: this.props.text || this.props.children,
    nonHighlightedText2: "",
    originalText: this.props.text || this.props.children,
    clearSearch: false,
  };
  this.ref = React.createRef();
  this.textRef = React.createRef();
  this.foundTextRef = React.createRef();
  this.notFoundTextRef = React.createRef();
  this.matchedSelectedValue = false;
}

export function didMount() {
  if (this.props.selectedValue === this.props.value) {
    const text = this.props.text || this.props.children;
    this.props.setDisplay(this.props.text || this.props.children);
    this.props.setValue(this.props.value, text);
  }
}

export function didUpdate(prevProps) {
  if (prevProps.searchValue !== this.props.searchValue) {
    SelectLib.findText.call(this);
  }
}
