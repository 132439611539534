import Api from "@lib/api/api";
import {
  FETCH_RUNBOOK_LIST_SUCCESS,
  FETCH_RUNBOOK_LIST_ERROR,
  FETCHING_RUNBOOKS,
  ADD_RUNBOOK_TO_LIST,
  SET_FILTERED_RUNBOOKS,
} from "@redux/types";
import { runbookOwners } from "@config/constants";
import { searchRunbooks } from "@lib/utils";

export function fetchRunbooks(owner) {
  return async dispatch => {
    dispatch(isFetching(true));
    if (owner === "Fylamynt") {
      dispatch({
        type: FETCH_RUNBOOK_LIST_SUCCESS,
        owner: owner,
        payload: [],
      });
    } else {
      const data = await Api.getRunbooks(owner).catch(e =>
        dispatch({ type: FETCH_RUNBOOK_LIST_ERROR, payload: e }),
      );
      dispatch({
        type: FETCH_RUNBOOK_LIST_SUCCESS,
        owner: owner,
        payload: data && data.length > 0 ? data : [],
      });
    }

    dispatch(isFetching(false));
  };
}

// if a new runbook was added, let's add it to the redux state
export function addToRunbooks(runbook) {
  return dispatch => {
    return dispatch({
      type: ADD_RUNBOOK_TO_LIST,
      owner: "Self",
      payload: runbook,
    });
  };
}

export function searchAndSort(
  searchTerm,
  activeOwner,
  sortType,
  actualRunbookList,
  resortRunbooksList,
) {
  return async dispatch => {
    let runbookList = { ...actualRunbookList };
    if (searchTerm === "" && sortType === null) {
      runbookList = {
        [runbookOwners.self]: [],
        [runbookOwners.fylamynt]: [],
      };
    } else if (searchTerm !== "" && sortType === null) {
      runbookList[activeOwner] = searchRunbooks(
        runbookList[activeOwner],
        searchTerm,
      );
    } else if (searchTerm === "" && sortType !== null) {
      runbookList[activeOwner] = resortRunbooksList(
        sortType,
        runbookList[activeOwner],
      );
    } else {
      runbookList[activeOwner] = searchRunbooks(
        runbookList[activeOwner],
        searchTerm,
      );
      runbookList[activeOwner] = resortRunbooksList(
        sortType,
        runbookList[activeOwner],
      );
    }
    dispatch({
      type: SET_FILTERED_RUNBOOKS,
      activeOwner: activeOwner,
      searchTerm,
      runbookList,
    });
  };
}

export function isFetching(_isFetching) {
  return async dispatch =>
    dispatch({
      type: FETCHING_RUNBOOKS,
      payload: _isFetching,
      message: _isFetching ? "Fetching workflows" : "",
    });
}
