import { combineReducers } from "redux";
import appReducer from "./app.reducer";
import conditionalReducer from "./conditional.reducer";
import executionsReducer from "./execution.reducer";
import formReducer from "./form.reducer";
import nodeReducer from "./node.reducer";
import runbooksReducer from "./runbooks.reducer";
import runbookReducer from "./runbook.reducer";
import userReducer from "./user.reducer";
import snippetsReducer from "./snippets.reducer";
import uiReducer from "./ui.reducer";
import snippetDetailsReducer from "./snippetDetails.reducer";
import websocketReducer from "./websocket.reducer";
import connectorsReducer from "./connectors.reducer";
import actionNodeReducer from "./action-node.reducer";
import AWSSettingsReducer from "./AWSSettings.reducer";
import getStartedReducer from "./getStarted.reducer";
import settingsPanelReducer from "./settingsPanel.reducer";
import schedulesReducer from "./schedules.reducer";
import APIKeysReducer from "./apikeys.reducer";
import GITReposReducer from "./gitRepo.reducer";
import SSHTargetReducer from "./sshTarget.reducer";
import accountPlanReducer from "./accountPlan.reducer";
import commonReducer from "@redux/reducers/common.reducer";
import S3BucketsReducer from "@redux/reducers/s3Buckets.reducer";

const appReducers = combineReducers({
  actionNodeReducer,
  appReducer,
  conditionalReducer,
  executionsReducer,
  formReducer,
  nodeReducer,
  runbookReducer,
  runbooksReducer,
  snippetsReducer,
  uiReducer,
  userReducer,
  snippetDetailsReducer,
  websocketReducer,
  connectorsReducer,
  AWSSettingsReducer,
  getStartedReducer,
  settingsPanelReducer,
  schedulesReducer,
  APIKeysReducer,
  GITReposReducer,
  SSHTargetReducer,
  accountPlanReducer,
  commonReducer,
  S3BucketsReducer,
});

export const userResetState = () => ({
  type: "USER_RESET_STATE",
});

const rootReducer = (state, action) => {
  let newState = { ...state };
  if (action.type === "USER_RESET_STATE") {
    /** reset all reducers except appReducer */
    const { appReducer } = newState;
    newState = { appReducer };
  }

  return appReducers(newState, action);
};

export default rootReducer;
