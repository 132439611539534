import React from "react";
import { Button } from "@components/ui";
import { HeaderMain } from "@components/modules";
import { Consumer } from "../runbooks-lib/runbooks.context";

export default () => (
  <Consumer>
    {({ toggleRunbookModal }) => (
      <HeaderMain title="All Workflows">
        <Button
          buttonStyle="primary"
          onClick={toggleRunbookModal}
          size="small"
          text="New Workflow"
          icon="icon-plus"
        ></Button>
      </HeaderMain>
    )}
  </Consumer>
);
