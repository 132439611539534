import React from "react";
import { Consumer } from "../snippet-details-lib/snippet-details.context";
import { TabCollection, Tab, TabContent } from "@components/ui/tabs";
import DataTable from "./snippetDetailsDataTable";

export default class SnippetDetailsTabs extends React.Component {
  render() {
    return (
      <Consumer>
        {({ snippet }) => (
          <div className="snippet-details-tabs">
            <TabCollection active="inputs">
              <Tab title="Inputs" name="inputs">
                <TabContent className="snippet-details-tabs-content">
                  <div className="ml-15">This action uses the following input parameters:</div>
                  <br />
                  <DataTable show="inputs" />
                </TabContent>
              </Tab>
              <Tab title="Outputs" name="outputs">
                <TabContent className="snippet-details-tabs-content">
                  <div className="ml-15">This action produces the following outputs:</div>
                  <br />
                  <DataTable show="outputs" />
                </TabContent>
              </Tab>
            </TabCollection>
          </div>
        )}
      </Consumer>
    );
  }
}
