import { ActionNodeStep } from "../../../../runbook-editor-lib/neuropssteps/actionnodestep";

export function updateActionNode(ActionNodeInputClass) {
  const {
    selectedService,
    selectedOperation,
    operationDetails,
    runbookNode,
  } = ActionNodeInputClass.props;
  const { runbook } = runbookNode;
  if (nodeNeedsReplacement(runbookNode, selectedService, selectedOperation)) {
    let actionNodeDef = runbookNode.actionNodeDef || runbookNode.snippetDef;
    actionNodeDef.editorNodeId = runbookNode.editorNodeId;
    const newActionNode = ActionNodeStep.createNewActionNode(
      actionNodeDef,
      selectedService,
      selectedOperation,
      operationDetails,
    );
    runbook.replaceNonConditionalStep(runbookNode, newActionNode);
    ActionNodeInputClass.props.setActiveActionNode(newActionNode);
  } else {
    runbookNode.setOperationDetails(operationDetails);
    ActionNodeInputClass.props.setOutputs(runbookNode.outputs);
    ActionNodeInputClass.forceUpdate();
  }
}

function nodeNeedsReplacement(runbookNode, selectedService, selectedOperation) {
  return (
    runbookNode.service !== selectedService ||
    runbookNode.operation !== selectedOperation
  );
}
