export class SSMActionNames {
  static APPROVE = "aws:approve";
  static ASSERT_AWS_RESOURCE_PROPERTY = "aws:assertAwsResourceProperty";
  static BRANCH = "aws:branch";
  static CHANGE_INSTANCE_STATE = "aws:changeInstanceState";
  static COPY_IMAGE = "aws:copyImage";
  static CREATE_IMAGE = "aws:createImage";
  static CREATE_STACK = "aws:createStack";
  static CREATE_TAGS = "aws:createTags";
  static DELETE_IMAGE = "aws:deleteImage";
  static DELETE_STACK = "aws:deleteStack";
  static EXECUTE_AUTOMATION = "aws:executeAutomation";
  static EXECUTE_AWS_API = "aws:executeAwsApi";
  static EXECUTE_STATE_MACHINE = "aws:executeStateMachine";
  static INVOKE_LAMBDA = "aws:invokeLambdaFunction";
  static PAUSE = "aws:pause";
  static RUN_COMMAND = "aws:runCommand";
  static RUN_INSTANCES = "aws:runInstances";
  static SLEEP = "aws:sleep";
  static WAIT_FOR_AWS_RESOURCE_PROPERTY = "aws:waitForAwsResourceProperty";
}

export class ParameterType {
  static String = "String";
  static StringList = "StringList";
  static Boolean = "Boolean";
  static Integer = "Integer";
  static MapList = "MapList";
  static StringMap = "StringMap";
  static Decimal = "Decimal";
  static Object = "Object";
  static Map = "Map";
  static Text = "Text";
}

export class ComparisonOperators {
  static StringEquals = "StringEquals";
  static EqualsIgnoreCase = "EqualsIgnoreCase";
  static StartsWith = "StartsWith";
  static EndsWith = "EndsWith";
  static Contains = "Contains";

  static NumericEquals = "NumericEquals";
  static NumericGreater = "NumericGreater";
  static NumericLesser = "NumericLesser";
  static NumericGreaterOrEquals = "NumericGreaterOrEquals";
  static NumericLesserOrEquals = "NumericLesserOrEquals";

  static BooleanEquals = "BooleanEquals";

  static LeafOperators = [
    { name: ComparisonOperators.StringEquals, type: "String" },
    { name: ComparisonOperators.EqualsIgnoreCase, type: "String" },
    { name: ComparisonOperators.StartsWith, type: "String" },
    { name: ComparisonOperators.EndsWith, type: "String" },
    { name: ComparisonOperators.Contains, type: "String" },
    { name: ComparisonOperators.NumericEquals, type: "Numeric" },
    { name: ComparisonOperators.NumericGreater, type: "Numeric" },
    { name: ComparisonOperators.NumericLesser, type: "Numeric" },
    { name: ComparisonOperators.NumericGreaterOrEquals, type: "Numeric" },
    { name: ComparisonOperators.NumericLesserOrEquals, type: "Numeric" },
    { name: ComparisonOperators.BooleanEquals, type: "Boolean" },
  ];

  // Compound operators
  static And = "And";
  static Or = "Or";
  static Not = "Not";

  static argumentType(opName) {
    switch (opName) {
      case ComparisonOperators.StringEquals:
      case ComparisonOperators.EqualsIgnoreCase:
      case ComparisonOperators.StartsWith:
      case ComparisonOperators.EndsWith:
      case ComparisonOperators.Contains:
        return ParameterType.String;
      case ComparisonOperators.NumericEquals:
      case ComparisonOperators.NumericGreater:
      case ComparisonOperators.NumericLesser:
      case ComparisonOperators.NumericGreaterOrEquals:
      case ComparisonOperators.NumericLesserOrEquals:
        return ParameterType.Integer;
      case ComparisonOperators.BooleanEquals:
        return ParameterType.Boolean;
      default:
        break;
    }
    throw new Error(`Operator is not a valid aws:branch comparison: ${opName}`);
  }

  static isOperator(op) {
    return !!ComparisonOperators[op];
  }

  static isCompoundOperator(op) {
    switch (op) {
      case ComparisonOperators.And:
      case ComparisonOperators.Or:
      case ComparisonOperators.Not:
        return true;
      default:
        return false;
    }
  }
}
