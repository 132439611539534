import { hasKeys } from "@lib/utils";
import { StepTypeChecker } from "./neuropssteps/steptypechecker";
import { ParameterType } from "./ssm/strings";
import { positionDiagram } from "./editor-design-area.helper";
import difference from "lodash/difference";

export const formatSnippetName = (snippetName = "") => {
  let regEx = new RegExp(/_/g);
  return snippetName.replace(regEx, " ");
};

export const dropSnippet = event => {
  const divId = event.dataTransfer.getData("divId");
  const div = document.getElementById(divId);
  if (div) {
    const cloned = div.cloneNode();
    cloned.style.position = "absolute";
    cloned.style.zIndex = 1000;
    cloned.style.left = event.clientX - 60 + "px";
    cloned.style.top = event.clientY - 60 + "px";
    cloned.style.maxWidth = "80px";
    cloned.style.maxHeight = "80px";
    cloned.innerHTML = div.innerHTML;
    let target = document.querySelector(".editor-design-area-container");
    cloned.classList.add("scale-out");
    target.appendChild(cloned);
    setTimeout(() => {
      cloned.parentNode.removeChild(cloned);
    }, 100);
  }
};

export const isRequired = node => {
  if (!node) {
    return false;
  }
  if (hasKeys(node, "extras.runbookNode.snippetDef.required_inputs")) {
    return true;
  }
  return false;
};

export const inputType = node => {
  return StepTypeChecker.getType(node);
};

export const hasOptions = node => {
  if (!node) {
    return false;
  }
  if (hasKeys(node, "extras.runbookNode.inputs")) {
    return node.extras.runbookNode.parameterInputs.length > 0;
  }
  return false;
};

export const getInputOptions = node => ({
  required: _getRequiredInputs(node),
  optional: _getOptionalInputs(node),
});

const _getOptionalInputs = node => {
  if (hasKeys(node, "extras.runbookNode.parameterInputs")) {
    const nodes = node.extras.runbookNode.parameterInputs.filter(
      pi => !pi.required,
    );
    return nodes;
  } else {
    return [];
  }
};

const _getRequiredInputs = node => {
  if (hasKeys(node, "extras.runbookNode.parameterInputs")) {
    return node.extras.runbookNode.parameterInputs.filter(pi => pi.required);
  } else {
    return [];
  }
};

export function convertPayloadSpecToJSON(payload) {
  if (!payload) {
    return;
  }
  const replaced =
    payload &&
    payload.replace(/("\w+":\s*)({{\s*[\w.]+\s*}})(\s*,?)/g, '$1"$2"$3');
  return JSON.parse(replaced);
}

/* RIGHT PANEL STUFF */
export function getOptionValue(inputType, snippetInput) {
  if (!snippetInput) {
    return "";
  }
  const { type } = snippetInput;
  let value;
  if (inputType === snippetInput.source.type) {
    switch (snippetInput.source.type) {
      case "snippetOutput":
        value = snippetInput.source.sourceValue.getQualifiedName();
        break;
      case "userProvided":
        value = "[Provided by user at runtime]";
        break;
      case "actionNode":
        value = `${snippetInput.source.sourceValue.sourceStep.name}.${snippetInput.source.sourceValue.name}`;
        break;
      case "constant":
      default:
        value = snippetInput.source.sourceValue || "";
    }
  } else {
    value = "";
  }
  return { value, type };
}

export const getSelectedOption = input => {
  return (input && input.source && input.source.type) || "userProvided";
};

function typesCanBeConverted(sourceType, targetType) {
  return (
    sourceType === targetType ||
    (sourceType === ParameterType.String &&
      targetType === ParameterType.StringList)
  );
}

export function previousStepOptions(runbookNode, type) {
  const predecessorOutputs = runbookNode
    .predecessors()
    .flatMap(step => step.outputs)
    .filter(output => !!output);
  return type
    ? predecessorOutputs.filter(
        out => out && typesCanBeConverted(out.type, type),
      )
    : predecessorOutputs;
}

let startX;
let startWidth;

export function resizeRightPanel() {
  const div = document.querySelector(".editor-right-panel-resize");
  const parentDiv = document.querySelector(".editor-detail-wrap");
  const parentRect = parentDiv.getBoundingClientRect();
  parentDiv.style.width = parentRect.width + "px";
  const editorToolbarDiv = document.querySelector(".editor-toolbar-wrap");

  div.onmousedown = e => {
    init(e);
  };
  window.onmouseup = () => {
    window.removeEventListener("mousemove", mouseMove, false);
    makeSelectable(parentDiv, true);
    positionDiagram();
  };

  const init = e => {
    startX = e.clientX;
    startWidth = parseInt(parentDiv.style.width, 10);
    window.addEventListener("mousemove", mouseMove, false);
    makeSelectable(parentDiv, false);
  };

  const mouseMove = e => {
    // p.style.width = (startWidth + e.clientX - startX) + 'px';
    parentDiv.style.width = startWidth - e.clientX + startX + "px";
    editorToolbarDiv.style.paddingRight =
      startWidth - e.clientX + startX + "px";
    positionDiagram();
  };
}

function makeSelectable(node, unselectable) {
  const _selectable = unselectable ? "off" : "on";
  if (node.nodeType === 1) {
    node.setAttribute("unselectable", _selectable);
    if (unselectable) {
      node.classList.remove("unselectable");
    } else {
      node.classList.add("unselectable");
    }
  }
  var child = node.firstChild;
  while (child) {
    makeSelectable(child, unselectable);
    child = child.nextSibling;
  }
}

export function updateLinks(
  activeNode,
  runbookStep,
  addLinkToStep,
  rerenderEditor,
) {
  const links = activeNode
    .getOutPorts()
    .flatMap(port => Object.values(port.getLinks()));

  let linkTargets = links.map(
    link => link.targetPort?.getNode()?.extras?.runbookNode?.name || "",
  );

  // Removes empty strings from linksTargets to deal with "" added as a result of optional chaining check.
  linkTargets = linkTargets.filter(link => link);

  const newTargets = runbookStep.nextSteps();

  const addLinks = difference(newTargets, linkTargets);
  const removeLinks = difference(linkTargets, newTargets);
  let target;
  for (target of removeLinks) {
    const link = links.find(
      // eslint-disable-next-line no-loop-func
      l => l.targetPort.getNode().extras.runbookNode.name === target,
    );
    link.remove();
  }
  for (target of addLinks) {
    addLinkToStep(activeNode, runbookStep.runbook.mainStepIndex[target]);
  }
  rerenderEditor();
}

// force the runbook to have default values for runtime-params
export const shouldForceDefaultValues = (runbookObj, force = false) => {
  const found = (runbookObj?.mainSteps || []).find(step => {
    if (!step) return false;

    /**
     * Prompt user to set default values in case
     * Trigger node is included in the WF
     */
    if (step?.snippetDef?.type === "TRIGGER") {
      return true;
    }
    return false;
  });
  if (found || force) {
    const ssm = runbookObj.toSSM();
    let missingDefault = false;
    Object.values(ssm.parameters).forEach(param => {
      if (param.description.includes("(Required") && !param.default) {
        missingDefault = true;
      }
    });
    return missingDefault;
  }
  return false;
};
