import produce from "immer";

import {
  EXECUTION_IS_FETCHING,
  EXECUTION_IS_RUNNING,
  EXECUTION_FETCH_ERROR,
  EXECUTION_FETCH_SUCCESS,
  EXECUTION_FETCHING_DETAILS,
  EXECUTION_DETAILS_SUCCESS,
  EXECUTION_RESUME_SUCCESS,
  RUNBOOK_EXECUTION_ERROR,
  RUNBOOK_EXECUTION_SUCCESS,
  EXECUTION_STEP_FETCH_ERROR,
} from "@redux/types";

const INITIAL_STATE = {
  isFetchingExecution: false,
  isFetchingDetails: false,
  isFetchingDetailsSuccess: false,
  executionsData: null,
  executionDetails: {},
  executionIsRunning: false,
  executionId: "",
  execxutionRunId: null,
  message: "",
};

export default (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    switch (action.type) {
      case EXECUTION_IS_FETCHING:
        draft.isFetchingExecution = action.payload;
        draft.message = action.message;
        break;

      case EXECUTION_IS_RUNNING:
        draft.executionIsRunning = action.payload;
        draft.message = action.message;
        break;

      case EXECUTION_FETCHING_DETAILS:
        draft.isFetchingDetails = action.payload;
        break;

      case EXECUTION_STEP_FETCH_ERROR:
        draft.isFetchingDetailsSuccess = action.payload;
        console.log("Failed to fetch executions data " + action.payload);
        break;

      case EXECUTION_DETAILS_SUCCESS:
        draft.executionDetails[action.executionId] = action.payload;
        draft.executionId = action.executionId;
        break;

      case EXECUTION_FETCH_ERROR:
        console.log("Failed to fetch executions data " + action.payload);
        break;

      case EXECUTION_FETCH_SUCCESS:
        draft.executionsData = action.payload;
        draft.isFetchingExecution = false;
        break;

      case RUNBOOK_EXECUTION_ERROR:
        console.log("Failed to execute workflow " + action.payload);
        break;
      case RUNBOOK_EXECUTION_SUCCESS:
        draft.executionId = action.payload.exe_id;
        draft.newExecution = action.payload;
        break;

      case EXECUTION_RESUME_SUCCESS:
        console.log(action.payload);
        break;

      default:
        break;
    }
  });
