import React from "react";
import { Button } from "@components/ui";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  saveConnecterSchema,
  isFetching,
  fetchConnectorConfigDetails,
} from "@redux/actions/settingsPanel.action";
import { Formik } from "formik";
import * as Yup from "yup";
import FormInput from "../../../settings-panel-components/SettingsRightConfigPanel/FormInput";
import { Wait } from "@components/ui";
import { Redirect } from "react-router";
import { camelToSnake } from "@lib/utils";

const validationSchema = Yup.object({
  playbooks: Yup.string()
    .required("S3 bucket with ansible playbooks is required")
    .trim("S3 bucket with ansible playbooks is required"),
  sshPrivateKey: Yup.string()
    .required("SSH private key is required")
    .trim("SSH private key is required"),
});

const FOOTER_BTN_STYLE = { width: "48%" };
class ConnectorForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      doRedirect: false,
      redirectUrl: "",
      s3AccessRole: "",
    };
  }

  componentDidMount() {
    this.getAnsibleConfig();
  }

  getAnsibleConfig = async () => {
    this.props.isFetching(true, "Fetching Ansible Config");
    let action = await this.props.fetchConnectorConfigDetails("ansible");
    if (action.type === "CONNECTOR_CONFIGURATION_INFO_FETCH_SUCCESS") {
      let response = JSON.parse(this.props.connectorConfig);
      this.setState(
        {
          s3AccessRole: response?.s3_access_role,
        },
        () => {
          this.props.isFetching(false, "");
        },
      );
    } else {
      this.props.isFetching(false, "");
    }
  };

  goBack = () => {
    this.setState({ doRedirect: true, redirectUrl: "/settings" });
  };

  getPayload = formData => {
    let payload = {};
    Object.keys(formData).forEach(key => {
      payload[camelToSnake(key)] = formData[key];
    });
    return { credentials: payload };
  };

  onSubmitHandle = async (accountDetails, actions) => {
    this.props.isFetching(true, "Integration in progress");
    actions.setSubmitting(true);
    const payload = this.getPayload(accountDetails);
    await this.props.saveConnecterSchema(payload, "ansible");
    actions.setSubmitting(false);
    this.props.isFetching(false, "");
    if (this.props.formSubmittedSuccess) {
      actions.setStatus({
        formSubmitMessage: "Connector details saved successfully!!!",
      });
      setTimeout(() => {
        this.goBack();
      }, 1500);
    } else {
      actions.setStatus({
        formSubmitMessage: "Oops! Something went wrong !!!",
      });
    }
  };

  render() {
    return (
      <>
        {this.state.doRedirect && <Redirect to={this.state.redirectUrl} push />}
        {this.props.message && <Wait text={this.props.message} />}

        <Formik
          initialValues={{
            s3AccessRole: this.state.s3AccessRole,
            playbooks: "",
            logs: "",
            sshPrivateKey: "",
          }}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={this.onSubmitHandle}
        >
          {formik => (
            <div className="d-flex flex-column2">
              <h2 className="pt-10 pb-10 mb-15">Authorize Fylamynt</h2>
              <FormInput
                fieldName="Principal:"
                name="s3AccessRole"
                id="s3AccessRole"
                handleChange={formik.handleChange}
                touched={formik.touched}
                errors={formik.errors}
                autoComplete="off"
                readOnly={true}
              />
              <FormInput
                fieldName="S3 bucket with ansible playbooks:"
                name="playbooks"
                id="playbooks"
                handleChange={formik.handleChange}
                touched={formik.touched}
                errors={formik.errors}
                autoComplete="off"
              />
              <FormInput
                fieldName="S3 bucket for log files (optional):"
                name="logs"
                id="logs"
                handleChange={formik.handleChange}
                touched={formik.touched}
                errors={formik.errors}
                autoComplete="off"
              />
              <FormInput
                fieldName="SSH private key:"
                name="sshPrivateKey"
                id="sshPrivateKey"
                handleChange={formik.handleChange}
                touched={formik.touched}
                errors={formik.errors}
                autoComplete="off"
                as="textarea"
                className="text-area"
              />

              <div className="gs-form-footer">
                <Button
                  text="Cancel"
                  buttonStyle="secondary"
                  style={FOOTER_BTN_STYLE}
                  className="mr-15"
                  size="large"
                  type="button"
                  onClick={this.goBack}
                />
                <Button
                  text="Authorize"
                  style={{ width: "48%" }}
                  buttonStyle="primary"
                  size="large"
                  onClick={formik.handleSubmit}
                />
              </div>

              <div className="mt-20">
                {!!formik.status && formik.status.formSubmitMessage}
              </div>
            </div>
          )}
        </Formik>
      </>
    );
  }
}

const mapStateToProps = state => ({
  message: state.runbooksReducer.message,
  formSubmittedSuccess: state.settingsPanelReducer.formSubmittedSuccess,
  connectorConfig: state.settingsPanelReducer.currentNodeConfigInfo,
});
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      saveConnecterSchema,
      isFetching,
      fetchConnectorConfigDetails,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectorForm);
