import * as Yup from "yup";

export const getValidationSchema = (mode, moduleList) => {
  if (mode === "create") {
    return Yup.object({
      module_id: Yup.string()
        .required("Please enter Module Id")
        .notOneOf(
          moduleList ? moduleList.map(e => e.module_id) : [],
          "Module Id already exists",
        )
        .trim("Please enter Module Id"),
      repo_id: Yup.string()
        .required("Please enter Repo Id")
        .trim("Please enter Repo Id"),
      ref: Yup.string().required("Please enter Ref").trim("Please enter Ref"),
      directory: Yup.string()
        .required("Please enter Directory")
        .trim("Please enter Directory"),
    });
  } else {
    return Yup.object({
      repo_id: Yup.string()
        .required("Please enter Repo Id")
        .trim("Please enter Repo Id"),
      ref: Yup.string().required("Please enter Ref").trim("Please enter Ref"),
      directory: Yup.string()
        .required("Please enter Directory")
        .trim("Please enter Directory"),
    });
  }
};
