import { DropDown, DropDownOption, FTNotification } from "@components/ui";

import { Consumer } from "../../runbook-editor-lib/runbook-editor.context";
import JsonContent from "./json-content/json-content-button";
import { RUNBOOK_SET_ACTIVE } from "@redux/types";
import React from "react";
import SaveButton from "./editor-toolbar-save";
import WarningCard from "@components/ui/WarningSign/WarningCard";
import { connect } from "react-redux";
import { draftStatus } from "@lib/utils/constants";
import { fetchAccountPlanInfo } from "@redux/actions/accountPlan.actions";
import { limitExceeded } from "../../../../utils/common";
import { sanitizeDecimal } from "@lib/utils";

class EditorToolbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAWSAccountAdded:
        this.props.isAccountDetailFetched &&
        !!this.props.userDefaultAccounts.length,
      dropdownStyle:
        this.props.isAccountDetailFetched &&
        !!this.props.userDefaultAccounts.length
          ? { cursor: "pointer" }
          : { cursor: "not-allowed" },
    };
  }

  isInvalidWorkflow = runbookObj => {
    let edges = [];
    let reverseEdges = [];
    if (Object.keys(runbookObj._dag._edges).length > 1) {
      Object.keys(runbookObj._dag._edges).forEach(key => {
        edges = edges.concat(runbookObj._dag._edges[key]);
      });

      Object.keys(runbookObj._dag._reverse).forEach(key => {
        reverseEdges = reverseEdges.concat(runbookObj._dag._reverse[key]);
      });

      for (let node in runbookObj._dag._edges) {
        if (!edges.includes(node) && !reverseEdges.includes(node)) {
          return true;
        }
      }
    }

    return false;
  };

  hasInvalidWorkflowParams = runbookObj => {
    /**
     * Function to check if any node in the runbookObj has any required field empty.
     * This function prevents user to save such invalid runbook, so that while execution
     * it must not fail with an error related to required parameter not found.
     * @param {[Object]} runbookObj : Runbook object
     * @param {[Boolean]}           : Boolean value specifies if given object is valid or not.
     */
    let isInvalid = false;
    for (let stepIndex in runbookObj.mainSteps) {
      let step = runbookObj.mainSteps[stepIndex];
      for (let inputIndex in step.parameterInputs) {
        let input = step.parameterInputs[inputIndex];
        if (
          input?.source?.type === "constant" &&
          input.required === true &&
          input?.source?.sourceValue === null
        ) {
          isInvalid = true;
          break;
        }
      }
    }
    return isInvalid;
  };

  savingRunbook = async (runbookObj, saveRunbook, activeRunbookVersion) => {
    // If user tries to save Integer values in Decimal type, sanitize Decimal input type to convert Integer only values to flaoting/decimal type
    if (runbookObj.mainSteps) {
      runbookObj.mainSteps.forEach(step => {
        if (step.parameterInputs) {
          step.parameterInputs.forEach(item => {
            if (item.type === "Decimal" && item.source.sourceValue) {
              let newSourceValue = sanitizeDecimal(item.source.sourceValue);
              item.source.sourceValue = newSourceValue;
            }
          });
        }
      });
    }
    if (runbookObj.mainSteps.length > 0) {
      // Find if step has 'errors' property
      let step = runbookObj.mainSteps.find(step => step.errors);
      if (step && step.errors.errorMessage) {
        FTNotification.error({
          title: "Could not save",
          message: step?.errors?.errorMessage,
        });
        return;
      }
    }
    if (runbookObj.unHealthySteps.length > 0) {
      FTNotification.error({
        title: "Could not save",
        message: `You have (${runbookObj.unHealthySteps.length}) incomplete step(s)`,
      });
      return;
    } else if (this.isInvalidWorkflow(runbookObj)) {
      FTNotification.error({
        title: "Could not save",
        message: `Invalid workflow! You have disconnected node(s)`,
      });
      return;
    } else if (this.hasInvalidWorkflowParams(runbookObj)) {
      FTNotification.error({
        title: "Cannot save workflow",
        message: `Invalid input! Required input value cannot be empty.`,
      });
      return;
    }
    if (activeRunbookVersion === "Draft") {
      try {
        await this.props.fetchAccountPlanInfo();
        if (
          limitExceeded(
            this.props.quotas?.workflows?.current_usage,
            this.props.quotas?.workflows?.limit,
          )
        ) {
          this.props.toggleWorkflowLimitDialog();
          return;
        }
      } catch (e) {
        FTNotification.error({
          title: "Could not fetch Quotas",
        });
        return;
      }
    }
    saveRunbook();
  };

  handleRunNow = async toggleRunbookModal => {
    try {
      await this.props.fetchAccountPlanInfo();
      if (
        limitExceeded(
          this.props.quotas?.executions?.current_usage,
          this.props.quotas?.executions?.limit,
        )
      ) {
        this.props.toggleRunsLimitDialog();
        return;
      }
    } catch (e) {
      FTNotification.error({
        title: "Could not fetch Quotas",
      });
      return;
    }
    toggleRunbookModal();
  };

  handleScheduleRun = async toggleScheduleModal => {
    try {
      await this.props.fetchAccountPlanInfo();
      if (
        limitExceeded(
          this.props.quotas?.executions?.current_usage,
          this.props.quotas?.executions?.limit,
        )
      ) {
        this.props.toggleRunsLimitDialog();
        return;
      }
    } catch (e) {
      FTNotification.error({
        title: "Could not fetch Quotas",
      });
      return;
    }
    toggleScheduleModal();
  };

  render() {
    return (
      <Consumer>
        {({
          runbookId,
          setActivePanel,
          activeRunbookVersion,
          runbook,
          toggleRunbookModal,
          saveRunbook,
          runbookObj,
          runbookDidUpdate,
          versions,
          goBack,
          toggleScheduleModal,
        }) => (
          <div className="editor-toolbar-wrap" onMouseDown={this.props.onClick}>
            <div
              className="runbook-title"
              onClick={() => setActivePanel("versions", "Versions")}
            >
              {activeRunbookVersion !== draftStatus ? (
                <div
                  onClick={e => {
                    goBack(`/workflows/${runbookId}?v=${activeRunbookVersion}`);
                    e.stopPropagation();
                  }}
                  className="link-white link-underline"
                >
                  {runbookId}
                </div>
              ) : (
                runbookId
              )}
              {activeRunbookVersion && ` | Version: ${activeRunbookVersion}`}
            </div>
            <div className="editor-toolbar-right-container">
              <div className="editor-toolbar-right">
                {runbookObj && runbookObj.mainSteps.length > 0 && (
                  <JsonContent runbook={runbookObj} />
                )}
                <div className="ht-100p" data-tut="reactour__save">
                  {activeRunbookVersion === draftStatus || runbookDidUpdate ? (
                    <React.Fragment>
                      {runbookObj && runbookObj.mainSteps.length > 0 && (
                        <SaveButton
                          activeRunbookVersion={activeRunbookVersion}
                          saveClickHandler={() =>
                            this.savingRunbook(
                              runbookObj,
                              saveRunbook,
                              activeRunbookVersion,
                            )
                          }
                          version={
                            versions &&
                            versions.length > 0 &&
                            versions[0].DocumentVersion !== "Draft"
                              ? `New Version`
                              : ``
                          }
                        />
                      )}
                    </React.Fragment>
                  ) : (
                    <>
                      <DropDown
                        changeAble={false}
                        className={`workflow-run-dropdown ${
                          (!this.state.isAWSAccountAdded ||
                            this.props.unconfiguredNodes.length > 0) &&
                          "no-pointer"
                        }`}
                        title="Run"
                        style={this.state.dropdownStyle}
                      >
                        <DropDownOption
                          text="Run Now"
                          handleClick={() =>
                            this.handleRunNow(toggleRunbookModal)
                          }
                        >
                          <div className="btn-svg__run"></div>
                        </DropDownOption>

                        <DropDownOption
                          text="Schedule Run"
                          value={
                            runbook ? runbook.Name || runbook.Description : ""
                          }
                          handleClick={() =>
                            this.handleScheduleRun(toggleScheduleModal)
                          }
                        >
                          <div className="btn-svg__schedule" />
                        </DropDownOption>
                      </DropDown>
                    </>
                  )}
                </div>
              </div>
              {(!this.state.isAWSAccountAdded ||
                this.props.unconfiguredNodes.length > 0) && (
                <div className="m-2">
                  <WarningCard
                    unconfiguredNodes={this.props.unconfiguredNodes}
                    isAWSAccountConfigured={this.state.isAWSAccountAdded}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </Consumer>
    );
  }
}

const mapStateToProps = state => ({
  isAccountDetailFetched: state.getStartedReducer.isAccountDetailFetched,
  userDefaultAccounts: state.getStartedReducer.userDefaultAccounts,
  quotas: state.accountPlanReducer.quotas,
});

const mapDispatch = dispatch => ({
  setActiveRunbook: runbook =>
    dispatch({
      type: RUNBOOK_SET_ACTIVE,
      payload: runbook,
    }),
  fetchAccountPlanInfo: () => dispatch(fetchAccountPlanInfo()),
});

export default connect(mapStateToProps, mapDispatch)(EditorToolbar);
