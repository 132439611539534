import { RunbookStepInput, readLambdaParam } from "./nodeinputoutput";
import { SSMActionNames, ParameterType } from "./strings";
import { Step } from "./steps";
import { convertPayloadSpecToJSON } from "./util";

export class InvokeLambdaStep extends Step {
  static ACTION = SSMActionNames.INVOKE_LAMBDA;
  // eslint-disable-next-line
  constructor(stepJSON) {
    super(stepJSON);
  }

  consumedOutputs() {
    return this.parameterInputs
      ? this.parameterInputs
        .filter(
          input =>
            input.source.type === "snippetOutput" ||
            input.source.type === "actionNode",
        )
        .map(input => input.source.sourceValue)
      : [];
  }

  readInputSources(runbook) {
    const inputsWithSources = readInputSourcesFromSSM(this, runbook);

    //infer what we can
    this.parameterInputs = Object.keys(inputsWithSources).map(name => {
      const input = new RunbookStepInput(
        this,
        name,
        ParameterType.String,
        true,
        inputsWithSources[name],
      );
      return input;
    });
  }
}
// Helper functions
export function readInputSourcesFromSSM(lambdaStep, runbook) {
  try {
    let lambdaInput = lambdaStep.ssm.inputs.Payload;
    if (lambdaInput && (lambdaInput.includes("\n") || lambdaInput.includes("\t") || lambdaInput.includes("\r"))) {
      lambdaInput = lambdaInput.replace(/\n/g, "\\n").replace(/\r/g, "\\r").replace(/\t/g, "\\t")
    }
    const lambdaPayload = JSON.parse(
      convertPayloadSpecToJSON(lambdaInput) || "{}",
    );
    const sources = {};
    const payload = lambdaPayload.params || lambdaPayload;
    let name;
    for (name of Object.keys(payload)) {
      const source =
        payload[name] && readLambdaParam(runbook, payload[name])[0];
      if (source) {
        sources[name] = source;
      }
    }
    return sources;
  } catch (ex) {
    console.error(`Error parsing JSON: ${lambdaStep.inputs.Payload}`);
    console.error(ex);
    return [];
  }
}
