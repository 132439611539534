import React from "react";
import { JsonView } from "@components/ui";

const CloudwatchInstructions = props => {
  if (!props?.alias) {
    return (
      <div className="instructions">
        Please select target account from the right panel
      </div>
    );
  }

  let reg = props.alias.role_arn.match(/arn:aws:iam::(\d{12}):role\/(.*)/i);
  let account_id = reg[1];
  let role_name = reg[2];

  return (
    <div className="instructions">
      <div className="mb-40">
        <div className="list-row">
          <div className="step-number">
            <div>1</div>
          </div>
          Login to your <span>{props.alias?.alias}</span> AWS Account (
          {account_id}) and go to{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://console.aws.amazon.com/cloudwatch"
          >
            CloudWatch
          </a>{" "}
          service
        </div>

        <div className="list-row">
          <div className="step-number">
            <div>2</div>
          </div>
          <div>
            From the left menu, <span>Events</span> > <span>Rules</span> and
            click <span>Create rule</span> button
          </div>
        </div>
        <div className="list-row">
          <div className="step-number">
            <div>3</div>
          </div>
          <div>
            On Event Source select <span>Event Pattern</span> and then select{" "}
            <span>Events by Service</span>
          </div>
        </div>
        <div className="list-row">
          <div className="step-number">
            <div>4</div>
          </div>
          <div>
            In service name field select <span>CloudWatch</span> <br/>and for Event Type select <span>CloudWatch Alarm State Change</span>
          </div>
        </div>
        <div className="list-row">
          <div className="step-number">
            <div>5</div>
          </div>
          On Targets Select <span>Event bus in another AWS account</span>
        </div>
        <div className="list-row">
          <div className="step-number">
            <div>6</div>
          </div>
          for Account ID* set <span>{props.awsAccountId}</span>
        </div>
        <div className="list-row">
          <div className="step-number">
            <div>7</div>
          </div>
          for "CloudWatch Events needs permission to send events .." select{" "}
          <span>Create a new role for this specific resource</span>
        </div>

        <div className="list-row">
          <div className="step-number">
            <div>8</div>
          </div>
          Making sure Fylamynt has read access to the list of CloudWatch alarms
          in the <span>{props?.alias.alias}</span> AWS account:
        </div>
        <div className="list-row">
          <div className="step-number">
            <div>9</div>
          </div>
          go to{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://console.aws.amazon.com/iam"
          >
            IAM
          </a>{" "}
          and find <span>{role_name}</span> role.
        </div>
        <div className="list-row">
          <div className="step-number">
            <div>10</div>
          </div>
          if <span>cloudwatch:DescribeAlarms</span> permission does not exists,
          click <span>Attach policies</span> > <span>Create policy</span>
          and in the JSON editor paste the following:
          <JsonView
            showCopy={true}
            jsonIconClass="cloudwatch-instruction-json-copy-icon"
            data={`
          {
            "Action": [
                "cloudwatch:DescribeAlarms"
            ],
            "Resource": "arn:aws:cloudwatch:*:${account_id}:alarm:*",
            "Effect": "Allow"
        }`}
            className="position-relative mt-10 width-full"
            jsonViewClass={`scroll-x-auto`}
          />
        </div>

        <div className="list-row">
          <div className="step-number">
            <div>11</div>
          </div>
          Next <span>Tags</span> > Next <span>Review</span>: name your new
          policy and select <span>Create policy</span>
        </div>

        <div className="list-row">
          <div className="step-number">
            <div>12</div>
          </div>
          Click <span>Authorize</span> button
        </div>
      </div>
    </div>
  );
};

export default CloudwatchInstructions;
