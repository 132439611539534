import React from "react";

export default () => (
  <div className="instructions">
    <span>
      {" "}
      If no S3 buckets are available for selection, first go to the Settings
      page and click the 'S3 Buckets' card to configure.
      <br />
      <br />
    </span>
  </div>
);
