import React, { useEffect, useState } from "react";
import "./Login.scss";
import { loginSuccess } from "@redux/actions/user.actions";
import { Formik } from "formik";
import Auth from "@aws-amplify/auth";
import { Link } from "react-router-dom";
import Button from "@components/ui/button/button";
import { useDispatch, useSelector } from "react-redux";
import visibleIcon from "@assets/images/icons/eye-icon__allowed.svg";
import invisibleIcon from "@assets/images/icons/icon-eye__not-allowed.svg";
import FormSubmitError from "../utils/FormSubmitError";
import { getValidationSchema } from "./utils/ValidationSchema";
import Wait from "@components/ui/wait";
import { isFetching } from "@redux/actions/settingsPanel.action";
import {
  keyUpHandler,
  setCustomIdentityId,
  trackIdentity,
} from "../utils/helpers";
import { useHistory } from "react-router-dom";
import LogoHeader from "@components/shared/LogoHeader/LogoHeader";

type LoginProps = {};

const initialValues = { email: "", password: "", activationCode: "" };

const Login: React.FC<LoginProps> = () => {
  const [showFormSubmitError, setShowFormSubmitError] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  let [passwordVisible, togglePasswordVisibility] = useState<Boolean>(false);
  const submitBtnStyle = { width: "100%" };
  const [showAuthCodePage, setShowAuthCodePage] = useState(false);
  const validationSchema = getValidationSchema(showAuthCodePage);
  const loadingMessage = useSelector(state => state.runbooksReducer.message);
  const isLoggedIn = useSelector(state => state.userReducer.isLoggedIn);

  const toggleFormSubmitError = () =>
    setShowFormSubmitError(!showFormSubmitError);

  useEffect(() => {
    isLoggedIn && history.push("/workflows");
  }, [isLoggedIn, history]);

  const sendConfirmationCode = async (user, actions) => {
    dispatch(isFetching(true, "Sending Confirmation Code"));
    setShowFormSubmitError(false);
    try {
      await Auth.resendSignUp(user.email);
      dispatch(isFetching(false, ""));
      setShowAuthCodePage(true);
    } catch (error) {
      actions.setStatus({ formSubmitMessage: error.message });
      setShowFormSubmitError(true);
      dispatch(isFetching(false, ""));
    }
  };

  const handleSubmit = async (user, actions) => {
    actions.setSubmitting(true);
    if (showAuthCodePage) {
      dispatch(isFetching(true, "Activating User"));
      try {
        await Auth.confirmSignUp(user.email, user.activationCode);
        dispatch(isFetching(true, "Logging In"));
        setShowFormSubmitError(false);
        try {
          let authData = await Auth.signIn(user.email, user.password);
          setShowFormSubmitError(false);
          dispatch(loginSuccess(authData));
        } catch (error) {
          dispatch(isFetching(false, ""));
          setShowFormSubmitError(true);
          actions.setStatus({
            formSubmitMessage: "Your email or password don't match",
          });
        }
      } catch (error) {
        dispatch(isFetching(false, ""));
        actions.setStatus({ formSubmitMessage: error.message });
        setShowFormSubmitError(true);
      }
    } else {
      dispatch(isFetching(true, "Logging In"));
      try {
        let authData = await Auth.signIn(user.email, user.password);
        await Promise.all([setCustomIdentityId(), trackIdentity(user.email)]);
        dispatch(loginSuccess(authData));
      } catch (error) {
        actions.setStatus({
          formSubmitMessage: "Your email or password don't match",
        });
        if (error?.code === "UserNotConfirmedException") {
          sendConfirmationCode(user, actions);
        } else {
          dispatch(isFetching(false, ""));
          setShowFormSubmitError(true);
        }
      }
    }

    actions.setSubmitting(false);
  };

  const changePasswordVisibility = () =>
    togglePasswordVisibility(!passwordVisible);

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      {formik => (
        <div className="login-container">
          {loadingMessage && <Wait text={loadingMessage} />}
          <LogoHeader />
          <div className="login-card">
            {showAuthCodePage ? (
              <>
                <h1 className="login-card-header">User Activation</h1>
                {showFormSubmitError && (
                  <div className="mb-35">
                    <FormSubmitError
                      errorMsg={formik.status?.formSubmitMessage || ""}
                      toggleVisibility={toggleFormSubmitError}
                    />
                  </div>
                )}
                <div className="activation-code-helper">
                  Please enter the 6-digit code we’ve sent to{" "}
                  <b className="activation-code-helper-highlight">
                    {formik.values.email}.
                  </b>
                  <br />
                  Can’t find your code? Check your spam or{" "}
                  <span
                    className="login-form-navigator-link"
                    onClick={() => sendConfirmationCode(formik.values, formik)}
                  >
                    Resend Code
                  </span>
                </div>
                <div className="login-form-input">
                  <label className="login-form-input-label">
                    Activation Code
                  </label>
                  <input
                    id="activationCode"
                    className={`login-form-input-text ${
                      formik.errors.activationCode &&
                      formik.touched.activationCode &&
                      "error"
                    }`}
                    aria-describedby="basic-addon1"
                    type="text"
                    {...formik.getFieldProps("activationCode")}
                    autoComplete="off"
                    onKeyUp={e =>
                      keyUpHandler(e, formik.handleSubmit, formik.isSubmitting)
                    }
                  />
                  {formik.errors.activationCode &&
                  formik.touched.activationCode ? (
                    <div className="input-feedback">
                      {formik.errors.activationCode}
                    </div>
                  ) : null}
                </div>
                <div className="form-footer">
                  <Button
                    text={`Activate & Sign In`}
                    style={submitBtnStyle}
                    buttonStyle="primary"
                    size="large"
                    onClick={formik.handleSubmit}
                    className="login-form-submit-btn"
                    disabled={formik.isSubmitting}
                  />
                </div>
                <span
                  className="pointer link-underline mt-20"
                  onClick={() => {
                    setShowFormSubmitError(false);
                    setShowAuthCodePage(false);
                  }}
                >
                  Go Back
                </span>
              </>
            ) : (
              <>
                <h1 className="login-card-header">Sign In</h1>
                {showFormSubmitError && (
                  <div className="mb-35">
                    <FormSubmitError
                      errorMsg={formik.status?.formSubmitMessage || ""}
                      toggleVisibility={toggleFormSubmitError}
                    />
                  </div>
                )}
                <div className="login-form-input">
                  <label className="login-form-input-label">
                    Email Address
                  </label>
                  <input
                    type="email"
                    id="email"
                    placeholder="johndoe@acme.com"
                    className={`login-form-input-text ${
                      formik.errors.email && formik.touched.email && "error"
                    }`}
                    aria-describedby="basic-addon1"
                    {...formik.getFieldProps("email")}
                    autoComplete="off"
                    autoFocus
                    onKeyUp={e =>
                      keyUpHandler(e, formik.handleSubmit, formik.isSubmitting)
                    }
                  />
                  {formik.errors.email && formik.touched.email ? (
                    <div className="input-feedback">{formik.errors.email}</div>
                  ) : null}
                </div>
                <>
                  <div className="login-form-input">
                    <label className="login-form-input-label">Password</label>
                    <div className="d-flex">
                      <input
                        id="password"
                        placeholder=""
                        className={`login-form-input-text ${
                          formik.errors.password &&
                          formik.touched.password &&
                          "error"
                        }`}
                        aria-describedby="basic-addon1"
                        type={passwordVisible ? "text" : "password"}
                        {...formik.getFieldProps("password")}
                        autoComplete="off"
                        onKeyUp={e =>
                          keyUpHandler(
                            e,
                            formik.handleSubmit,
                            formik.isSubmitting,
                          )
                        }
                      />
                      <img
                        onClick={changePasswordVisibility}
                        src={passwordVisible ? visibleIcon : invisibleIcon}
                        alt="invisibleIcon"
                        className="password-eye-icon"
                        width="18"
                        height="18"
                      />
                    </div>
                    {formik.errors.password && formik.touched.password ? (
                      <div className="input-feedback">
                        {formik.errors.password}
                      </div>
                    ) : null}
                  </div>
                </>

                <div className="form-footer">
                  <Button
                    text="Sign In"
                    style={submitBtnStyle}
                    buttonStyle="primary"
                    size="large"
                    onClick={formik.handleSubmit}
                    className="login-form-submit-btn"
                    disabled={formik.isSubmitting}
                  />
                </div>
                <div className="login-form-navigator-container">
                  <Link to={"/sign-up"} className="login-form-navigator-link">
                    Don't have an account?
                  </Link>
                  <Link
                    to={"/forgot-password"}
                    className="login-form-navigator-link"
                  >
                    Forgot Password?
                  </Link>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </Formik>
  );
};

export default Login;
