import React from "react";
import getInstructions from "./profile-instructions-helper";

const LeftPanel = () => {
  const mainHeading = "Terraform CLI integration";
  const instructions = getInstructions();

  return (
    <>
      <div className="instructions__left__margin-top">
        <div className="instructions__title">
          <h1 className="page-title">{mainHeading}</h1>
        </div>
        <div className="onboarding-panel">
          <h3 className="h2-margin">Integration steps with Fylamynt.</h3>
          {instructions}
        </div>
      </div>
    </>
  );
};

export default LeftPanel;
