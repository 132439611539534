import React from "react";
import { Accordion, AccordionLabel, AccordionSection } from "@components/ui";

export default class RunbookActions extends React.PureComponent {
  componentDidUpdate(prevProps) {
    if (prevProps.activeNode !== this.props.activeNode) {
      this.setState({
        activeNode: this.props.activeNode,
      });
    }
  }
  render() {
    return (
      <React.Fragment>
        <Accordion isExpanded={true} useArrow={true}>
          <AccordionLabel className="editor-accordion-label">
            Workflow Actions
          </AccordionLabel>
          <AccordionSection>
            <div className="paragraph">
              <div>Actions...</div>
            </div>
          </AccordionSection>
        </Accordion>
      </React.Fragment>
    );
  }
}
