import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { ModalTitle, ModalClose, ModalFooter } from "./";
import "./modal.scss";

class Modal extends React.Component {
  componentDidMount() {
    this.calcHeight();
    window.addEventListener("resize", this.calcHeight, false);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.calcHeight, false);
  }

  calcHeight = () => {
    try {
      const parent = document.querySelector(".modal-container");
      const container = document.querySelector(".modal-content-container");
      let max = document.body.clientHeight * 0.8;
      parent.style.maxHeight = `${max}px`;
      container.style.maxHeight = `${max - 50}px`;
    } catch (e) {}
  };

  closeModal = event => {
    if (
      event.target.id === "modal-cover" ||
      event.target.id === "modal-close"
    ) {
      document.querySelector("#portal").classList.remove("background-content");
      this.props.onClose();
    }
  };

  appendToPortal = () => {
    return ReactDOM.createPortal(
      this.contents(),
      document.getElementById("portal"),
    );
  };

  contents = () => {
    return (
      <div
        className={`modal-cover ${this.props.coverClass}`}
        id="modal-cover"
        onClick={event => {
          if (!this.props.backgroundCanClose) return;
          this.closeModal(event);
        }}
      >
        <div
          className={`modal-container modal-slide-in ${this.props.containerClass}`}
        >
          {this.props.title && (
            <ModalTitle
              customClass={`${this.props.titleClass}`}
              title={this.props.title}
            />
          )}
          {this.props.showClose && (
            <ModalClose
              customClass={`${this.props.closeClass}`}
              onClick={this.closeModal}
            />
          )}
          <div
            className={`modal-content-container ${this.props.contentContainerClass}`}
          >
            <div className={`modal-content ${this.props.contentClass}`}>
              {this.props.children}
            </div>
          </div>

          {this.props.footer && (
            <ModalFooter customClass={`${this.props.footerClass}`}>
              {this.props.footer}
            </ModalFooter>
          )}
        </div>
      </div>
    );
  };

  render() {
    if (this.props.appendToPortal) {
      return this.appendToPortal();
    } else {
      return this.contents();
    }
  }
}

Modal.propTypes = {
  title: PropTypes.string,
  display: PropTypes.bool,
  backgroundCanClose: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  showTitle: PropTypes.bool,
  showClose: PropTypes.bool,
  size: PropTypes.oneOf(["large", "medium", "small"]),
  style: PropTypes.object,
  appendToPortal: PropTypes.bool,
  containerClass: PropTypes.string,
  contentContainerClass: PropTypes.string,
  contentClass: PropTypes.string,
  coverClass: PropTypes.string,
  titleClass: PropTypes.string,
  closeClass: PropTypes.string,
  footerClass: PropTypes.string,
};

Modal.defaultProps = {
  size: "medium",
  backgroundCanClose: true,
  style: {},
  appendToPortal: false,
};

export default Modal;
