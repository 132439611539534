import React from "react";
import "./SlackSetup.scss";
import { Logo } from "@components/modules";
import { Wait } from "@components/ui";
import Api from "@lib/api";

export default class SlackAuth extends React.Component {
  componentDidMount() {
    const api = new Api();
    const args = this.props.location?.search;
    let endpoint = `settings/slack/finish_oauth${args}`;
    const apiEndpoint = api.getEndpoint(endpoint);

    fetch(apiEndpoint, {
      method: "GET",
      mode: "cors",
      headers: { Authorization: api.Token },
      redirect: "manual",
    }).then(res => {
        res.text().then(r=> {
            window.location.href = encodeURI(r.replaceAll('"', ''))
        })
    });
  }
  render() {
    return (
      <div className="main-content">
        <div className="logo-header-flex">
          <div className="logo-header__inner-wrap"></div>
          <div className="logo-header__right-side"></div>
          <div className="logo-header__logo-wrap"></div>
        </div>
        <div className="onboarding-wrap__flex">
          <Wait text={`Authorizing Slack Integration`} />
          <Logo />
        </div>
      </div>
    );
  }
}
