import React from "react";

export default props => (
  <div className="label-inline">
    {props.children}
    <label className={`label-right ${props.labelClassName}`}>
      {props.label}
    </label>
  </div>
);
