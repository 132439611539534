import React from "react";
import { Logo } from "@components/modules";
import "./TerraformSetup.scss";
import LeftPanel from "./setup-form-helper/left-panel";
import RightPanel from "./setup-form-helper/RightPanel";
import { Link } from "react-router-dom";

class TerraformSetup extends React.Component {
  render() {
    return (
      <div className="main-content">
      <div className="logo-header-flex">
         <div className="logo-header__inner-wrap">
            <Link to="/settings" className="breadcrumb-link">
            ← Back To Settings
            </Link>
         </div>
         <div className="logo-header__right-side">
         </div>
         <div className="logo-header__logo-wrap">
         <Logo />
         </div>
      </div>
        <div className="onboarding-wrap__flex">
          <LeftPanel />
          <RightPanel nodeConfig={this.nodeConfig} />
        </div>
      </div>
    );
  }
}

export default TerraformSetup;
