import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  saveConnecterSchema,
  isFetching,
  fetchConnectorConfigDetails,
} from "@redux/actions/settingsPanel.action";
import { fetchAPIKeys } from "@redux/actions/apikeys.actions";
import { Formik } from "formik";
import * as Yup from "yup";
import FormInput from "../../../settings-panel-components/SettingsRightConfigPanel/FormInput";
import { Wait } from "@components/ui";
import { Redirect } from "react-router";
import { camelToSnake } from "@lib/utils";
import { RouteConstants } from "../../../../../routes/Constants";
import WebhookForm from "../../utils/WebhookForm";
import { FormFooter } from "@components/shared/GetStarted/Utils/Helpers";

const INITIAL_VALUES = {
  webhook_url: "",
  webhook_name: "",
  webhook_status: false,
  webhook_api_key_name: "",
  webhook_api_key_value: "",
  opsgenie_api_token: "",
};

const validationSchema = Yup.object({
  webhook_api_key_name: Yup.string()
    .required("Webhook API Key Name is required")
    .trim("Webhook API Key Name is required"),
  opsgenie_api_token: Yup.string()
    .required("Opsgenie API Token is required")
    .trim("Opsgenie API Token is required"),
});

class ConnectorForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      doRedirect: false,
      redirectUrl: "",
      initialValues: {},
      showForm: false,
    };
  }

  componentDidMount() {
    if (!(this.props.apiKeyList?.length > 0)) this.props.fetchAPIKeys();
    this.getConnectorConfig();
  }

  getConnectorConfig = async () => {
    this.props.isFetching(true, "Fetching Webhook URL");
    let action = await this.props.fetchConnectorConfigDetails("opsgenie");
    if (action.type === "CONNECTOR_CONFIGURATION_INFO_FETCH_SUCCESS") {
      let response = JSON.parse(this.props.connectorConfig);
      let webhook_status = response?.webhook_status === "ON" ? true : false;
      const initialValues = {
        ...INITIAL_VALUES,
        webhook_url: response?.webhook_url,
        webhook_name: response?.webhook_name,
        webhook_status,
      };
      this.setState(
        {
          initialValues,
          showForm: true,
        },
        () => {
          this.props.isFetching(false, "");
        },
      );
    } else {
      this.props.isFetching(false, "");
    }
  };

  toggleOpsgenieConnector = () => {
    this.setState(prevState => ({
      initialValues: {
        ...prevState.initialValues,
        webhook_status: !prevState.initialValues.webhook_status,
      },
    }));
  };

  goBack = () => {
    this.setState({
      doRedirect: true,
      redirectUrl: RouteConstants.settings.url,
    });
  };

  getPayload = formData => {
    let payload = {};
    Object.keys(formData).forEach(key => {
      payload[camelToSnake(key)] = formData[key];
    });
    payload["webhook_status"] =
      this.state.initialValues.webhook_status === true ? "ON" : "OFF";

    return { credentials: payload };
  };

  onSubmitHandle = async (accountDetails, actions) => {
    this.props.isFetching(true, "Integration in progress");
    actions.setSubmitting(true);
    const payload = this.getPayload(accountDetails);
    try {
      await this.props.saveConnecterSchema(payload, "opsgenie");
      actions.setStatus({
        formSubmitMessage: "Connector details saved successfully!!!",
      });
      setTimeout(() => {
        this.goBack();
      }, 1500);
    } catch (error) {
      actions.setStatus({
        formSubmitMessage: error?.message || "Oops! Something went wrong !!!",
      });
    }
    actions.setSubmitting(false);
    this.props.isFetching(false, "");
  };

  render() {
    return (
      <>
        {this.state.doRedirect && <Redirect to={this.state.redirectUrl} push />}
        {this.props.message && <Wait text={this.props.message} />}

        {this.state.showForm && (
          <Formik
            initialValues={this.state.initialValues}
            validationSchema={validationSchema}
            onSubmit={this.onSubmitHandle}
          >
            {formik => (
              <div className="d-flex flex-column2">
                <h2 className="pt-10 pb-10 mb-15">Authorize Fylamynt</h2>
                <div className="mb-15">
                  <FormInput
                    fieldName="Opsgenie API Token:"
                    name="opsgenie_api_token"
                    id="opsgenie_api_token"
                    handleChange={formik.handleChange}
                    touched={formik.touched}
                    errors={formik.errors}
                    autoComplete="new-password"
                    isMasked={true}
                  />
                </div>
                <WebhookForm
                  connectorName={`Opsgenie`}
                  formik={formik}
                  apiKeyList={this.props.apiKeyList}
                  handleClick={this.toggleOpsgenieConnector}
                  enableWebhookToggle={true}
                />
                <FormFooter onSubmit={formik.handleSubmit} />
                <div className="mt-20">
                  {!!formik.status && formik.status.formSubmitMessage}
                </div>
              </div>
            )}
          </Formik>
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  message: state.runbooksReducer.message,
  connectorConfig: state.settingsPanelReducer.currentNodeConfigInfo,
  apiKeyList: state.APIKeysReducer.apiKeyList,
});
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      saveConnecterSchema,
      isFetching,
      fetchConnectorConfigDetails,
      fetchAPIKeys,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectorForm);
