import React, { useEffect, useState, useContext, useRef } from "react";
import { Accordion, AccordionLabel, AccordionSection } from "@components/ui";
import { RunbookEditorContext } from "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.context";
import { RunbookStepInputSource } from "@containers/RunbookEditor/runbook-editor-lib/ssm/nodeinputoutput";
import ReactSelect from "@components/ui/React-Select/ReactSelect";
import { useSelector, useDispatch } from "react-redux";
import { fetchSplunkOnCallTeams } from "@redux/actions/runbook.action";
import { isEmpty } from "@lib/utils";

type RequiredInputsProps = {};

const SplunkOnCallInputs: React.FC<RequiredInputsProps> = () => {
  const isFirstRun = useRef(true);
  const dispatch = useDispatch();

  const context = useContext(RunbookEditorContext) as any;
  const runbookNode = context.activeNode.extras.runbookNode;
  const notifyRunbookUpdate = context.notifyRunbookUpdate;
  const teams = useSelector(state => state.runbookReducer.splunkOnCallTeams);
  const [policyList, setPolicyList] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState("");
  const [selectedPolicy, setSelectedPolicy] = useState("");

  useEffect(() => {
    isEmpty(teams) && dispatch(fetchSplunkOnCallTeams());
  }, [dispatch, teams]);

  useEffect(() => {
    if (selectedTeam && !isEmpty(teams)) {
      setPolicyList(teams[selectedTeam]);
    }
  }, [teams, selectedTeam]);

  useEffect(() => {
    if (runbookNode) {
      runbookNode.showHideWarning(!selectedTeam || !selectedPolicy);
    }
  }, [runbookNode, selectedTeam, selectedPolicy]);

  const getParamInput = (input: string) =>
    runbookNode.parameterInputs.find(p => p.name === input);

  const setInitialParamValues = useRef(() => {});

  setInitialParamValues.current = () => {
    const team = getParamInput("team_name").source?.sourceValue;
    if (team && team.constructor.name === "String") {
      setSelectedTeam(team);
      setPolicyList(teams[team]);
    }

    const policy = getParamInput("escalation_policy").source?.sourceValue;
    if (policy && policy.constructor.name === "String") {
      setSelectedPolicy(policy);
    }
  };

  const setPayload = (param, paramVal) => {
    const input = getParamInput(param);
    input.source = new RunbookStepInputSource(`constant`, paramVal);
    notifyRunbookUpdate(true);
  };

  const setTeam = team => {
    setSelectedTeam(team);
    const policies = teams[team];
    setPolicy("");
    setPolicyList(policies);
    setPayload("team_name", team);
  };

  const setPolicy = policy => {
    setSelectedPolicy(policy);
    setPayload("escalation_policy", policy);
  };

  useEffect(() => {
    if (isFirstRun.current) {
      setInitialParamValues.current();
      isFirstRun.current = false;
    }
  }, []);

  return (
    <>
      <Accordion isExpanded={true} useArrow={true}>
        <AccordionLabel className="editor-accordion-label mt-10">
          Required Inputs
        </AccordionLabel>
        <AccordionSection>
          <div className="editor-detail-panel editor-detail-panel-column">
            <label className="label">team_name</label>
            <ReactSelect
              id="container-team-name"
              name="container-team-name"
              value={{
                value: selectedTeam,
                label: selectedTeam || "Select from below",
              }}
              handleChange={data => {
                if (!!data?.value) {
                  setTeam(data.value);
                }
              }}
              selectOptions={Object.keys(teams)?.map(team => {
                return {
                  value: team,
                  label: team,
                };
              })}
              required
            />
          </div>
          {policyList?.length ? (
            <div className="editor-detail-panel editor-detail-panel-column">
              <label className="label">escalation_policy</label>
              <ReactSelect
                id="container-escalation-policy"
                name="container-escalation-policy"
                value={{
                  value: selectedPolicy,
                  label: selectedPolicy || "Select from below",
                }}
                handleChange={data => {
                  if (!!data?.value) {
                    setPolicy(data.value);
                  }
                }}
                selectOptions={policyList.map(policy => {
                  return {
                    value: policy,
                    label: policy,
                  };
                })}
                required
              />
            </div>
          ) : null}
        </AccordionSection>
      </Accordion>
    </>
  );
};

export default SplunkOnCallInputs;
