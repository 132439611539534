import React from "react";

export default props => (
  <div className="label-inline" style={props.style}>
    <label className={`label-left ${props.labelClassName}`}>
      {props.label}
    </label>
    {props.children}
  </div>
);
