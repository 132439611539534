import React from "react";

const IntegrationApi = require("@assets/images/screenshots/settings-splunk-on-call/API_page_screen.png");
const WebhookApi = require("@assets/images/screenshots/settings-splunk-on-call/Webhook_page_screen.png");

export default () => (
  <>
    <h3 className="h2-margin">
      Instructions to setup Splunk On-Call (VictorOps) Integration
    </h3>
    Follow below steps on your Splunk On-Call account
    <div className="list-row pt-10">
      <div className="step-number">
        <div>1</div>
      </div>
      <div>
        In your <b>Splunk On-Call</b> Account, go to <b>"Integrations "</b>--
        {`>`}<b>" API"</b>
      </div>
    </div>
    <div className="list-image">
      <img src={IntegrationApi} alt="ApiAccess" />
    </div>
    <div className="pt-15 pl-10">
      {" "}
      <b>"Activate API"</b> if API Access if OFF, else copy the available{" "}
      <b>API ID</b> and <b>API Key</b>
      <br />
      <br />
      Add the <b>API ID</b> and <b>API Key</b> details to the{" "}
      <b>"Authorize Fylamynt"</b> panel.
    </div>
    <div className="list-row pt-15">
      <div className="step-number">
        <div>2</div>
      </div>
      <div>
        Now setup Splunk On-Call to Fylamynt Outgoing Webook from <br />
        <b>"Integrations "</b>--{`>`}
        <b>" Outgoing Webhook"</b>
      </div>
    </div>
    <div className="list-image">
      <img src={WebhookApi} alt="CreateAPIKey" />
    </div>
    <div className="pt-10 pl-10">
      <b>"Add Webhook"</b> with below details:
      <br />
      <b className="pt-5">-- Method:</b> POST
      <br />
      <b className="pt-5">-- Content Type:</b> application/json
      <br />
      <b className="pt-5">-- Custom Headers:</b>
      <br />
      <div className="pl-15">
        <b>Key: x-api-key</b>
        <br />
        <b>Value:</b> add <b>"Webhook API Key Value"</b> from right side panel
      </div>
      <b className="pt-5">--To:</b>
      <br />
      <div className="pl-15">
        Copy webhook URL from <b>"Webhook URL"</b> in right side panel
      </div>
    </div>
    <div className="list-row">
      <div className="pt-15">
        Instructions to get Fylamynt <b>"Webhook API Key value":</b>
        <br />
        Under Authorize Fylamynt, choose one of the <b>
          Webhook API Key Name
        </b>{" "}
        from the dropdown, and the corresponding <b>Webhook API Key Value</b>{" "}
        should be shown. If no options are available for the{" "}
        <b>Webhook API Key Name</b>, first go to <b>Settings</b> --{`>`} API
        Keys --{`>`} <b>Manage Keys</b> to Create a New API Key
      </div>
    </div>
  </>
);
