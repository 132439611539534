import * as SRD from "storm-react-diagrams";
import { hasKeys, getNodeDisplayName } from "@lib/utils";
import { getSnippetCss } from "./snippet-icons";
import Bugsnag from "@bugsnag/js";
import { isEmpty } from "underscore";
import { SnippetType } from "./neuropssteps/strings";

const Application = new (function () {
  this.diagramEngine = new SRD.DiagramEngine();
  this.diagramEngine.installDefaultFactories();
  this.timer = null;
  this.cachedRunbook = null;

  this.selectNode = () => {};
  this.linkAdded = event => {
    const { link } = event;
    if (event.isCreated) {
      link.addListener({
        sourcePortChanged: this.linkSourceChanged(link),
        targetPortChanged: this.linkTargetChanged(link),
      });
    } else {
      const source = hasKeys(link, "sourcePort.parent.extras.runbookNode")
        ? link.sourcePort.parent.extras.runbookNode
        : null;
      const target = hasKeys(link, "targetPort.parent.extras.runbookNode")
        ? link.targetPort.parent.extras.runbookNode
        : null;
      this.runbook.removeLink(source, target);
    }
  };

  this.setPositionOfNodes = () => {
    const diagramElement = document.querySelector(".srd-diagram");
    const rect = diagramElement.getBoundingClientRect();
    let rightWidth = parseInt(
      document.querySelector(".editor-detail-wrap").style.width,
    );
    //const offset = 263;
    let adjustment = (rect.width - rightWidth) / 2;
    return adjustment;
  };

  this.getMousePosition = e => {
    const parent = document.querySelector(".srd-diagram");
    const rect = parent.getBoundingClientRect();
    let x = e.clientX + parent.scrollLeft - 30 - rect.x;
    let y = e.clientY + parent.scrollTop - 65;
    return { x, y };
  };

  // TODO: These listeners need to remove the old link if present.
  // to do this may need to look at all incoming nodes for the target
  // or all outgoing nodes for the source (depending on which changed)
  this.linkSourceChanged = link => event => {
    // Since the link source changed and we do not know the old
    // source port, we need to find all links in and pass them to
    // the model so that in can determine the change.
    //const { port } = event;
    if (link.sourcePort && link.targetPort) {
      // const incomingSources = link.targetPort.links.map(link.sourcePort.parent.extras.runbookNode.name);
      // target.updateSources(incomingSources);

      const source = link.sourcePort.parent.extras.runbookNode;
      const target = link.targetPort.parent.extras.runbookNode;

      try {
        this.runbook.linkActions(source, target);
      } catch (foundLoop) {
        console.error(foundLoop);
      }
    }
  };

  this.linkTargetChanged = link => event => {
    //const { port } = event;
    if (link.sourcePort && link.targetPort) {
      const source = link.sourcePort.parent.extras.runbookNode;
      const target = link.targetPort.parent.extras.runbookNode;
      const formerNextStep = source.nextStep;
      try {
        this.runbook.linkActions(source, target);
        if (source.stepType !== "ConditionalStep" && !!formerNextStep) {
          source.nextStep = target.name;
          link.remove();
          alert(
            `Cannot add another link to ${source.name}. Delete the previous link first before adding the new link.`,
          );
          source.nextStep = formerNextStep;
        }
      } catch (foundLoop) {
        console.error(foundLoop);
      }
    }
  };

  this.drawDiagram = runbook => {
    if (runbook) {
      this.cachedRunbook = runbook;
    } else {
      runbook = this.cachedRunbook;
    }

    this.activeModel = new SRD.DiagramModel();
    this.diagramEngine.setDiagramModel(this.activeModel);
    this.mostLeftPos = 200;
    this.nodeList = {};
    this.links = [];
    this.nodes = [];
    this.runbook = runbook;

    this.activeModel.addListener({
      zoomUpdated: () => {
        console.log((this.activeModel.zoom = 100));
      },
    });

    if (runbook) {
      try {
        this.activeModel.addListener({
          linksUpdated: this.linkAdded,
        });
      } catch (e) {
        const message = `Could not initialize linksUpdate listener ${e}`;
        Bugsnag.notify(new Error(message));
      }

      let step;
      for (step of runbook.mainSteps) {
        const newNode = {
          nodeObj: new SRD.DefaultNodeModel(
            getNodeDisplayName(step.name),
            "rgb(192,255,0)",
          ),
        };
        newNode.nodeObj.extras = {
          runbookNode: step,
        };
        try {
          this.addPorts(newNode);
        } catch (e) {
          const message = `Could not add ports ${e}`;
          Bugsnag.notify(new Error(message));
        }

        this.nodeList[step.name] = {
          type: step.type,
          name: step.name,
          description: "",
          action: step,
          content: step.toSSM(),
          parameterInputs: step.parameterInputs || [],
          nextNode: step.nextStep,
          node: newNode,
        };
      }
      if (runbook.mainSteps.length) {
        try {
          this.genNodesFromRunbook(runbook.mainSteps[0].name, 100, 0.5);
        } catch (e) {
          const message = `Could not generate nodes from runbook ${e}`;
          Bugsnag.notify(new Error(message));
        }
      }
      this.positionXY = {};
      this.setNodePositions();
      this.diagramEngine.recalculatePortsVisually();
    }
    const models = this.activeModel.addAll(...this.nodes, ...this.links);
    let time = 5;
    models.forEach(item => {
      time += 5;
      if (item instanceof SRD.DefaultNodeModel) {
        item.addListener({
          selectionChanged: this.selectNode.bind(this, item),
        });
      }
    });
    setTimeout(() => {
      this.addCssClasses(models);
    }, time);

    return this.activeModel;
  };
  /* end of drawDiagram */

  /**
   * Add InPort and OutPort to runbookNode on the basis
   * of isStart and isEnd attributes from
   * the content information in snippetDef
   * @param {Object} newNode RunbookNode
   */
  this.addPorts = newNode => {
    let content = newNode.nodeObj?.extras?.runbookNode?.snippetDef?.content;

    if (content?.isStart === true) {
      newNode.outPort = newNode.nodeObj.addOutPort("Out");
    } else if (content?.isEnd === true) {
      newNode.inPort = newNode.nodeObj.addInPort("In");
    } else {
      newNode.inPort = newNode.nodeObj.addInPort("In");
      newNode.outPort = newNode.nodeObj.addOutPort("Out");
    }
    newNode.nodeObj.extras.runbookNode.editorNodeId = newNode.nodeObj.id;
  };

  this.addCssClasses = models => {
    models.forEach(model => {
      this.addCssClass(model);
    });
  };

  this.addCssClass = model => {
    this.setSnippetName(model);
    let modelElementParent = document.querySelector(
      `div[data-nodeid="${model.id}"]`,
    );
    if (!modelElementParent) {
      return;
    }
    if (modelElementParent) {
      const iconDiv = document.createElement("div");
      let snippetClass = getSnippetCss(model.snippetName);
      if (!snippetClass) {
        return;
      }
      snippetClass = snippetClass.replace("snippet-icon-image ", "");
      iconDiv.classList.add("node-icon");
      iconDiv.classList.add(snippetClass);

      /** Change the color for node with type = "TRIGGER" */
      if (model.extras.runbookNode?.snippetDef?.type === SnippetType.TRIGGER) {
        iconDiv.classList.add("trigger-node");
      }

      // Add the extra opacity for unconfigured nodes.
      if (
        model.extras.runbookNode.hasOwnProperty("is_configured") &&
        !model.extras.runbookNode.is_configured
      ) {
        iconDiv.classList.add("opaque");
      }
      modelElementParent.appendChild(iconDiv);
    }
  };

  this.genNodesFromRunbook = (currentNodeID, x, y) => {
    try {
      const nodeObj = this.nodeList[currentNodeID].node.nodeObj;
      nodeObj.setPosition(0, y * 100);
      this.setSnippetName(nodeObj);
      y = y + 0.8;
      let nextNodeIDs = this.nodeList[currentNodeID].action.nextSteps();
      let offset = (nextNodeIDs.length - 1) * 80;
      let nextNodeID;
      for (nextNodeID of nextNodeIDs) {
        if (!this.nodeList[nextNodeID]) {
          console.warn(`No node in nodeList for ${nextNodeID}`);
          console.warn(nextNodeID);
          continue;
        }
        this.nodeList[nextNodeID].node.nodeObj.setPosition(x, y * 100);
        this.addNodeLinks(currentNodeID, nextNodeID);
        this.mostLeftPos =
          Math.min(x, x + offset) < this.mostLeftPos
            ? Math.min(x + offset)
            : this.mostLeftPos;
        this.genNodesFromRunbook(nextNodeID, x + offset, y);
        offset -= 160;
      }
    } catch (e) {
      const message = `Generating nodes from workflow error: ${e}`;
      Bugsnag.notify(new Error(message));
    }
  };

  this.addNodeLinks = (currentNodeID, nextNodeID) => {
    const { outPort } = this.nodeList[currentNodeID].node;
    const { inPort } = this.nodeList[nextNodeID].node;
    if (
      this.links.find(
        ln => ln.sourcePort.id === outPort.id && ln.targetPort.id === inPort.id,
      )
    ) {
      // don't add a link more than once
      return;
    }
    this.links.push(outPort.link(inPort));
  };

  this.getActiveDiagram = () => {
    return this.activeModel;
  };

  this.getDiagramEngine = () => {
    return this.diagramEngine;
  };

  this.getNodesDetail = () => {
    return this.nodeList;
  };

  this.setSnippetName = nodeObj => {
    let snippetName = "";
    /* start with the corner cases */
    if (nodeObj?.name) {
      if (nodeObj.name.match(/LoopForEach|Loop_For_Each/)) {
        snippetName = "loop";
      } else if (hasKeys(nodeObj.extras.runbookNode, "actionNodeDef")) {
        snippetName =
          nodeObj.extras.runbookNode.actionNodeDef?.name.toLowerCase() ||
          nodeObj.extras.runbookNode.name ||
          "action";
      } else if (hasKeys(nodeObj.extras.runbookNode, "snippetDef")) {
        snippetName =
          nodeObj.extras.runbookNode.snippetDef?.name.toLowerCase() ||
          nodeObj.extras.runbookNode.name;
      } else {
        snippetName = nodeObj.extras.runbookNode?.name || "action";
      }
    }
    nodeObj.snippetName = snippetName;
  };

  this.setNodesDetail = node => {
    this.nodeList[node.id] = {
      type: node.extras.type,
      name: node.extras.name,
      description: node.extras.description,
      content: node.extras.content,
      parameterInputs: node.extras.parameterInputs,
      node: node,
    };
  };

  this.getParent = childNode => {
    const linkObj = childNode.node.inPort;
    if (linkObj) {
      if (isEmpty(linkObj.links)) {
        return;
      } else {
        try {
          let sourcePortArray = Object.keys(linkObj.links);
          const sourcePort = linkObj.links[sourcePortArray[0]].sourcePort;
          const parent = sourcePort.parent;
          return parent;
        } catch (e) {
          const message = `No sourceport: ${e}`;
          Bugsnag.notify(new Error(message));
        }
      }
    }
  };

  this.setNodePositions = () => {
    const adjustLeftPos = this.setPositionOfNodes();
    let previousNode = null;
    let previousNodeIsConditional = false;
    let reg = new RegExp(/Conditional/gi);
    let updateX = adjustLeftPos;
    this.nodes = Object.keys(this.nodeList).map(nodeId => {
      const node = this.nodeList[nodeId];
      const parentNode = this.getParent(node);
      if (parentNode) {
        updateX = parentNode.x;
      }
      if (adjustLeftPos > 0) {
        let currentY = node.node.nodeObj.y;
        let positionKey = updateX.toString() + "_" + currentY.toString();
        if (this.positionXY[positionKey]) {
          if (previousNode) {
            node.node.nodeObj.setPosition(updateX + 120, currentY);
            currentY += 80;
          }
        } else {
          if (previousNodeIsConditional) {
            node.node.nodeObj.setPosition(updateX - 140, node.node.nodeObj.y);
          } else {
            node.node.nodeObj.setPosition(updateX, currentY);
          }
        }
        this.positionXY[updateX.toString() + "_" + currentY.toString()] = "1";
      }
      previousNode = node;
      previousNodeIsConditional = reg.test(node.name);

      return node.node.nodeObj;
    });
  };
})();

export default Application;
