import React from "react";
import PropTypes from "prop-types";
import { default as DT } from "react-data-table-component";
import "./data-table.scss";
import Wait from "@components/ui/wait";

const darkTheme = {
  title: {
    fontSize: "14px",
    fontColor: "#8c8c8c",
    backgroundColor: "#3d3d3d;",
  },
  contextMenu: {
    backgroundColor: "#E91E63",
    fontColor: "#FFFFFF",
  },
  header: {
    fontSize: "14px",
    fontColor: "#f3f3f3",
    backgroundColor: "#3d3d3d;",
    fontColorHover: "#5ca8ff",
    fontColorActive: "#5ca8ff",
  },
  rows: {
    fontColor: "#bebebe",
    backgroundColor: "#282828",
    borderColor: "rgba(255, 255, 255, .12)",
    hoverFontColor: "black",
    borderBottom: "1px solid #3d3d3d",
    hoverBackgroundColor: "rgba(0, 0, 0, .24)",
  },
  cells: {
    cellPadding: "32px",
  },
  pagination: {
    fontSize: "13px",
    fontColor: "#FFFFFF",
    backgroundColor: "#3d3d3d",
    buttonFontColor: "#FFFFFF",
    buttonHoverBackground: "rgba(255, 255, 255, .12)",
  },
  expander: {
    fontColor: "#FFFFFF",
    backgroundColor: "#363640",
    expanderColor: "#FFFFFF",
  },
};

export default class DataTable extends React.Component {
  render() {
    return (
      <div className="data-table-container">
        <DT
          title=""
          columns={this.props.columns}
          data={this.props.data}
          customTheme={this.props.theme}
          fixedHeader={this.props.fixedHeader}
          fixedHeaderScrollHeight={this.props.fixedHeaderScrollHeight}
          pagination={this.props.pagination}
        />
        {this.props.updating && <Wait />}
      </div>
    );
  }
}

/* columns should look like this:
columns = [{
  name: 'Display Title',
  selector: 'title',
  sortable: true
}]
*/

DataTable.propTypes = {
  theme: PropTypes.object,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
};

DataTable.defaultProps = {
  theme: darkTheme,
  columns: [],
  data: [],
  loading: false,
};
