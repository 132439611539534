import React from "react";
import { connect } from "react-redux";
import { DropDown, DropDownOption, LabelLeft } from "@components/ui";
import { snakeToPascal, camelToSnake } from "@lib/utils";
import { SERVICES_NEEDED } from "@redux/types";
import {
  fetchServices,
  setSelectedService,
  setSelectedOperation,
} from "@redux/actions/action-node.action";

class AWSOperationSelector extends React.Component {
  state = {
    operationDetailsNeeded: true,
  };

  componentDidMount() {
    this.initServiceAndOperation();
  }

  componentDidUpdate(prevProps) {
    this.setOperationDetails(prevProps);
  }

  initServiceAndOperation = () => {
    let { service, operation } = this.props.runbookNode;
    this.setService(service);
    if (operation === "undefined") {
      operation = null;
    }
    if (operation) {
      operation = camelToSnake(operation);
    }
    this.setSelectedOperation(operation);
    if (this.props.servicesNeeded) {
      this.props.fetchServices();
    }
  };

  setService = newValue => {
    this.props.setSelectedService(camelToSnake(newValue));
  };

  setSelectedOperation = operation => {
    this.props.setSelectedOperation(operation);
  };

  setOperationDetails = prevProps => {
    if (!this.state.operationDetailsNeeded) {
      return;
    }
    if (
      prevProps.isFetchingOperationDetails &&
      !this.props.isFetchingOperationDetails
    ) {
      this.props.handleOperationDetailsReady();
    }
  };

  willChangeRunbookNodeService = newService =>
    newService !== this.props.runbookNode.service;

  willChangeRunbookNodeOperation = newOperation =>
    newOperation !== this.props.runbookNode.operation;

  getServicesDropdown = () => {
    return (
      <React.Fragment>
        {this.props.isFetchingServices ? (
          <div className="editor-is-fetching">Fetching services </div>
        ) : (
          <DropDown
            title={snakeToPascal(this.props.selectedService)}
            showArrow={false}
            iconLeft="icon-filter"
            iconRight="icon-arrow-right"
            style={{width: "calc(100% - 8px)", marginBottom: "16px"}}
            onChange={newValue => {
              this.setService(newValue);
              this.props.setSelectedOperation();
              if (this.willChangeRunbookNodeService(newValue)) {
                this.props.notifyRunbookUpdate(true);
              }
            }}
            enableSearch={true}
          >
            {(this.props.services || []).map((service, i) => (
              <DropDownOption
                text={snakeToPascal(service)}
                key={`${service}`}
                className="text-small"
              />
            ))}
          </DropDown>

        )}
      </React.Fragment>
    );
  };

  getOperationsDropdown = () => {
    return (
      <React.Fragment>
        {!this.props.isFetchingOperations ? (
          <DropDown
            title={snakeToPascal(this.props.selectedOperation)}
            showArrow={false}
            iconLeft="icon-filter"
            iconRight="icon-arrow-right"
            style={{width: "calc(100% - 8px)"}}
            onChange={newValue => {
              this.setSelectedOperation(camelToSnake(newValue));
              this.setState({
                operationDetailsNeeded: true,
              });
              if (this.willChangeRunbookNodeOperation(newValue)) {
                this.props.notifyRunbookUpdate(true);
              }
            }}
            enableSearch={true}
          >
            {(this.props.operations || []).map((operation, i) => (
              <DropDownOption
                text={snakeToPascal(operation)}
                key={`${operation}`}
                className="text-small"
              />
            ))}
          </DropDown>
        ) : (
          <div className="editor-is-fetching">...fetching operations</div>
        )}
      </React.Fragment>
    );
  };

  render() {
    return (
      <React.Fragment>
        <LabelLeft label={`Service`} labelClassName="label">
          {this.getServicesDropdown()}
        </LabelLeft>

        <LabelLeft label={`Operation`} labelClassName="label">
          {this.getOperationsDropdown()}
        </LabelLeft>
      </React.Fragment>
    );
  }
}

const mapState = ({ actionNodeReducer }) => {
  return {
    operations: actionNodeReducer.operations,
    services: actionNodeReducer.services,
    servicesNeeded: actionNodeReducer.servicesNeeded,
    isFetchingOperations: actionNodeReducer.isFetchingOperations,
    isFetchingServices: actionNodeReducer.isFetchingServices,
    selectedService: actionNodeReducer.selectedService,
    selectedOperation: actionNodeReducer.selectedOperation,
    operationDetails: actionNodeReducer.operationDetails,
    isFetchingOperationDetails: actionNodeReducer.isFetchingOperationDetails,
  };
};

const mapDispatch = dispatch => ({
  setServicesNeeded: servicesNeeded =>
    dispatch({
      type: SERVICES_NEEDED,
      payload: servicesNeeded,
    }),
  fetchServices: () => dispatch(fetchServices()),
  setSelectedService: selectedService =>
    dispatch(setSelectedService(selectedService)),
  setSelectedOperation: selectedOperation =>
    dispatch(setSelectedOperation(selectedOperation)),
});

export default connect(mapState, mapDispatch)(AWSOperationSelector);
