import React from "react";

const Integrations = require("@assets/images/screenshots/settings-opsgenie/Opsgenie-Integration.png");
const Settings = require("@assets/images/screenshots/settings-opsgenie/Opsgenie-Settings.png");
const ActionChannel = require("@assets/images/screenshots/settings-opsgenie/Opsgenie-CreateAction.png");
const ManagedAction = require("@assets/images/screenshots/settings-opsgenie/Opsgenie-ManagedAction.png");
const Policy = require("@assets/images/screenshots/settings-opsgenie/Opsgenie-Policy.png");

export default () => (
  <>
    <h3 className="h2-margin">Instructions to setup Opsgenie Integration</h3>
    Follow below steps on your Opsgenie Account
    <div className="list-row pt-10">
      <div className="step-number">
        <div>1</div>
      </div>
      <div>
        In your <b>Opsgenie Account</b>, go to <b>Teams</b> --{`>`} Select your
        team --{`>`} <b>Integrations</b> --{`>`} Add Integration.
      </div>
    </div>
    <div className="list-image">
      <img src={Integrations} alt="ApiAccess" />
    </div>
    <div className="list-row">
      <div className="step-number">
        <div>2</div>
      </div>
      <div>
        Select <b>API</b> as a integration option.
      </div>
    </div>
    <div className="list-image">
      <img src={Settings} alt="CreateAPIKey" />
    </div>
    <div className="list-row">
      <div className="step-number">
        <div>3</div>
      </div>
      <div>
        Give read-only access, copy <b>API Key</b> and <b>Save Integration</b>
      </div>
    </div>
    <div className="list-row">
      <div className="step-number">
        <div>4</div>
      </div>
      <div>
        Configure Webhooks
        <br />
      </div>
    </div>
    <div className="list-image">
      <div className="pt-10 pb-15">
        Select <b>Teams</b> --{`>`} Your Team --{">"} Add Action Channel
        <br />
      </div>
      <img src={ActionChannel} alt="ServiceDirectory" />

      <div className="pt-15">
        <b>URL</b>: copy Webhook URL from the <b>Opsgenie Webhook URL</b> in the
        right side panel.
      </div>
      <div className="pt-15">
        <b>Headers:</b>
        <div className="pl-10">
          <b>Name</b>: <b>x-api-key</b>
        </div>
        <div className="pl-10">
          <b>Value</b>: add <b>"Webhook API Key value"</b> from right side panel
        </div>
      </div>
      <div className="pt-15">
        <b>Instructions to get Fylamynt " Webhook API Key value"</b>:
        <div className="pl-10">
          Under Authorize Fylamynt, choose one of the{" "}
          <b>Webhook API Key Name</b> from the dropdown, and the corresponding
          <b>Webhook API Key Value</b> should be shown. If no options are
          available for the <b>Webhook API Key Name</b>, first go to{" "}
          <b>Settings</b> --{`>`} <b>API Keys</b> --{`>`} <b>Manage Keys</b> to
          Create a New API Key.
        </div>
      </div>
    </div>
    <div className="list-row">
      <div className="step-number">
        <div>5</div>
      </div>
      <div>Create Managed Action</div>
    </div>
    <div className="list-image">
      <div className="pt-10 pb-15">
        Select <b>Teams</b> --{`>`} Action --{">"} Add Action
        <br />
      </div>
      <img src={ManagedAction} alt="CreateAPIKey" />
      <div className="pt-15 pb-15">
        Select <b>REST Endpoint</b> --{`>`} (previously created Action Channel)
        --{">"} <b>Next</b> --{">"} <b>Create</b>
        <br />
      </div>
    </div>
    <div className="list-row">
      <div className="step-number">
        <div>6</div>
      </div>
      <div>Create Policy for created managed action</div>
    </div>
    <div className="list-image">
      <div className="pt-10 pb-15">
        Select <b>Teams</b> --{`>`} Policies --{">"} Add Action Policy
        <br />
      </div>
      <img src={Policy} alt="CreateAPIKey" />
      <div className="pt-15 pb-15">
        Tick <b>Enable policy when condition</b>, add conditions when you want
        to trigger Webhooks.
        <br />
        Then click on <b>Create and enable policy</b>
      </div>
    </div>
  </>
);
