import { SnippetStep } from "./snippetstep";
import { StepTypes } from "./strings";
import { SSMActionNames } from "../ssm/strings";
import { Parameter } from "../ssm/parameters";

export default class TwilioConnectorStep extends SnippetStep {
  static fromControlNodeDefinition(controlDef) {
    const stepJSON = {
      ...controlDef.content,
      name: `${controlDef.name}_${Math.floor(1000 * Math.random())}`,
      action: SSMActionNames.INVOKE_LAMBDA,
      maxAttempts: 1,
      inputs: {
        ClientContext: super._writeSnippetDetailsForContext(controlDef),
        FunctionName: controlDef.content.lambda_arn,
        Payload: "",
      },
      outputs: Object.keys(controlDef.outputs).map(output => {
        return {
          Name: output,
          Selector: `$.${output}`,
          Type: controlDef.outputs[output],
        };
      }),
      editorNodeId: controlDef?.editorNodeId,
    };
    return new TwilioConnectorStep(stepJSON, controlDef);
  }

  constructor(stepJSON, controlDef) {
    super(stepJSON, controlDef);
    this.stepType = StepTypes.TwilioConnectorStep;
  }

  isHealthyStep() {
    let boolList = [];
    this.parameterInputs.forEach(param => {
      let sourceValue = param.source.sourceValue;
      if (
        param.required &&
        (sourceValue === null ||
          sourceValue instanceof Parameter ||
          !sourceValue)
      ) {
        boolList.push(false);
      }
    });
    return boolList.every(Boolean);
  }
}
