import React from "react";
import { Link } from "react-router-dom";
import { RouteConstants } from "../../../../routes/Constants";

type FormFooterProps = {
  onSubmit: () => void;
};
export const FormFooter: React.FC<FormFooterProps> = ({ onSubmit }) => (
  <div className="gs-form-footer">
    <Link
      to={RouteConstants.settings.url}
      className="gs-form-footer-btn gs-form-footer-btn-cancel mr-15"
    >
      Cancel
    </Link>
    <div
      className="gs-form-footer-btn gs-form-footer-btn-submit"
      onClick={onSubmit}
    >
      Authorize
    </div>
  </div>
);
