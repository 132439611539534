import React from "react";

const ApiAccess = require("@assets/images/screenshots/settings-pagerduty/PD-APIAccess.png");
const CreateAPIKey = require("@assets/images/screenshots/settings-pagerduty/PD-CreateAPIKey.png");
const ServiceDirectory = require("@assets/images/screenshots/settings-pagerduty/PD-ServiceDirectory.png");
const Webhook = require("@assets/images/screenshots/settings-pagerduty/PD-Webhook.png");

export default () => (
  <>
    <h3 className="h2-margin">Instructions to setup PagerDuty Integration</h3>
    Follow below steps on your PagerDuty Account and complete the Steps
    <div className="list-row pt-10">
      <div className="step-number">
        <div>1</div>
      </div>
      <div>
        In your <b>PagerDuty Account</b>, go to <b>"DEVELOPER TOOLS"</b> --{`>`}{" "}
        <b>"API Access"</b>.
      </div>
    </div>
    <div className="list-image">
      <img src={ApiAccess} alt="ApiAccess" />
    </div>
    <div className="list-row">
      <div className="step-number">
        <div>2</div>
      </div>
      <div>
        Create a new API Key and copy the <b>"API Key"</b> generated to the <b>"PagerDuty API Token"</b> field in the right panel.
      </div>
    </div>
    <div className="list-image">
      <img src={CreateAPIKey} alt="CreateAPIKey" />
    </div>
    <div className="list-row">
      <div className="step-number">
        <div>3</div>
      </div>
      <div>
        Now setup PagerDuty - Fylamynt workflow trigger with below steps
        <br />
      </div>
    </div>
    <div className="list-image">
      <div className="pt-10 pb-15">
        Go to <b>PagerDuty Services</b> --{`>`} <b>Service Directory</b>
        <br />
      </div>
      <img src={ServiceDirectory} alt="ServiceDirectory" />
      <div className="pt-15">
        Select any existing PagerDuty Service and go to Integrations
      </div>
      <div className="pt-15 pb-15">
        In Extensions and Add-Ons of Integrations, click on Add or manager
        extensions and Select <b>"Generic V2 Webhook"</b> type.
      </div>
      <img src={Webhook} alt="Webhook" />
      <div className="pt-15">
        Add below details to <b>New Extension</b> --{`>`}{" "}
        <b>Generic V2 Webhook</b>
      </div>
      - <b>URL</b>: copy URL from the <b>"PagerDuty Webhook URL"</b> in the right
      panel
      <div className="pt-15">
        Custom Headers:
        <div className="pl-10">
          <b>Header Name</b>: <b>x-api-key</b>
        </div>
        <div className="pl-10">
          <b>Header Value</b>: add <b>"Webhook API Key value"</b> from right
          side panel
        </div>
      </div>
      <div className="pt-15">
        <b>Instructions to get Fylamynt " Webhook API Key value"</b>:
        <div className="pl-10">
          Under Authorize Fylamynt, choose one of the{" "}
          <b>Webhook API Key Name</b> from the dropdown, and the corresponding
          <b>Webhook API Key Value</b> should be shown. If no options are
          available for the <b>Webhook API Key Name</b>, first go to{" "}
          <b>Settings</b> --{`>`} <b>API Keys</b> --{`>`} <b>Manage Keys</b> to
          Create a New API Key.
        </div>
      </div>
    </div>
  </>
);
