import React from "react";
import { previousStepOptions } from "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.helpers";
import { DropDown, DropDownOption } from "@components/ui";
import { getNodeByName, getType } from "../../lib/input-selector.lib";
import { getSourceStepName } from "../../lib/output.lib";
import {
  ActionNodeOption,
  ControlNodeOption,
  SnippetActionOption,
  SSMStepAction,
} from "./";
import { connect } from "react-redux";
import { SET_SELECTED_OUTPUT } from "@redux/types";
import { hasKeys } from "@lib/utils";
import { StepTypes } from "@containers/RunbookEditor/runbook-editor-lib/neuropssteps/strings";
import { ControlNames } from "@containers/RunbookEditor/runbook-editor-lib/neuropssteps/strings";

class FromPreviousStep extends React.Component {
  state = {
    stepType: null,
    title: "Choose a step",
    step: null,
    previousStepSourceValue: "",
  };

  componentDidMount() {
    this._init();
  }

  _init = async () => {
    if (!this.props.resetTitle) {
      this._initActionNode();
      this._initSnippetOutputNode();
    }
  };

  _initActionNode = () => {
    const stepName = getSourceStepName(this.props.input);

    if (stepName) {
      this._setPreviousStepInfo(stepName, this.props.runbookObj);
      this.props.updateSelectedOutput(
        this.props.input.source.sourceValue.selector,
      );
      return true;
    }
  };

  _initSnippetOutputNode = () => {
    if (hasKeys(this.props.input, "source.sourceValue.snippetAction.name")) {
      let stepName = this.props.input.source.sourceValue.snippetAction?.name;
      this.setState({
        previousStepSourceValue: this.props.input.source.sourceValue.name,
      });
      this._setPreviousStepInfo(stepName, this.props.runbookObj);
    } else if (
      hasKeys(this.props.input, "source.sourceValue.sourceStep.name")
    ) {
      let stepName = this.props.input.source.sourceValue.sourceStep?.name;
      this.setState({
        previousStepSourceValue: "output",
      });
      this._setPreviousStepInfo(stepName, this.props.runbookObj);
    } else if (this.props.custom_item) {
      this.setState({ title: this.props.custom_item });
    }
  };

  _previousStepOptions(type) {
    const runbookNode = this.props.input.snippetAction;
    return previousStepOptions(runbookNode, type);
  }

  _setPreviousStepInfo = (newValue, runbookObj) => {
    const node = getNodeByName(newValue, runbookObj);
    const type = getType(node);
    this.setState({
      stepType: type,
      step: node,
      title: newValue,
    });
    if (this.props?.readSelectedStep) {
      this.props.readSelectedStep(node || newValue);
    }
  };

  setPreviousStepSourceValue = previousStepSourceValue => {
    this.setState({
      previousStepSourceValue,
    });
  };

  getPreviousStepOutputOptions = (stepType, step, title) => {
    switch (stepType) {
      case "SSMStepAction":
        return <SSMStepAction />;
      case "SnippetControl":
        return <ControlNodeOption />;
      case "ActionNode":
        return this.props.ActionNodeOption ? (
          <ActionNodeOption
            step={step}
            consumingInput={this.props.input}
            name={title}
            runbookObj={this.props.runbookObj}
            onChangeCallBack={this.props.onChangeCallBack}
            updateInputControl={this.props.updateInputControl}
            previousStepSourceValue={this.state.previousStepSourceValue}
            notifyRunbookUpdate={this.props.notifyRunbookUpdate}
            updateHandler={this.props.onChangeCallBack}
          />
        ) : (
          ""
        );
      /**
       * Add the snippets whose outputs we want to show in the Previous Step Output dropdown
       */
      case "SnippetStep":
      case "SnippetAction":
      case "TerraformPlanNode":
      case "Instana_Alert":
      case "TerraformUpdateVarsNode":
      case ControlNames.SplunkSearch:
      case ControlNames.DatadogAlert:
      case ControlNames.JSONPathStep:
      case ControlNames.Webhook:
      case ControlNames.PagerDuty:
      case ControlNames.Container:
      case ControlNames.TwilioSendSMS:
      case ControlNames.Opsgenie:
      case ControlNames.PickS3Bucket:
      case ControlNames.CloudFormationRunTemplate:
      case ControlNames.Pulumi:
      case ControlNames.SplunkOnCallAlert:
      case ControlNames.CloudwatchAlert:
        return (
          <SnippetActionOption
            step={step}
            consumingInput={this.props.input}
            name={title}
            runbookObj={this.props.runbookObj}
            onChangeCallBack={this.props.onChangeCallBack}
            updateInputControl={this.props.updateInputControl}
            notifyRunbookUpdate={this.props.notifyRunbookUpdate}
            previousStepSourceValue={this.state.previousStepSourceValue}
            setPreviousStepSourceValue={this.setPreviousStepSourceValue}
          />
        );
      default:
        return;
    }
  };

  render() {
    const runbookNode = this.props.input.snippetAction || this.props.input;
    //const typeFilter = input.choice ? null : input.type;
    const previousStepNames = runbookNode
      .predecessors()
      .filter(
        step =>
          step.stepType === "ActionNodeStep" ||
          step.stepType === "SnippetStep" ||
          step.stepType === "ActionNode" ||
          step.stepType === StepTypes.InstanaAlertConnectorStep ||
          step.stepType === StepTypes.DatadogConnectorStep ||
          step.stepType === StepTypes.JSONPathStep ||
          step.stepType === StepTypes.SplunkSearchConnectorStep ||
          step?.snippetDef?.type === "TRIGGER",
      )
      .map(step => {
        return step.name;
      });
    const { title, stepType, step } = this.state;

    const style = {
      width: "100%",
      padding: "0",
    };
    return (
      <React.Fragment>
        <div className="editor-previous-step-label font-11" style={style}>
          {this.props.label}
        </div>
        <div className="editor-previous-step-container" style={style}>
          <DropDown
            title={title}
            showArrow={false}
            iconLeft="icon-filter"
            iconRight="icon-arrow-right"
            width="100%"
            style={{ width: "calc(100% - 8px)", marginTop: "7px" }}
            onChange={newValue => {
              this._setPreviousStepInfo(newValue, this.props.runbookObj);
            }}
          >
            {this.props.custom_item && (
              <DropDownOption
                text={this.props.custom_item}
                className="text-small"
              />
            )}
            {runbookNode &&
              previousStepNames.map(key => (
                <DropDownOption text={key} key={key} className="text-small" />
              ))}
          </DropDown>
          {stepType &&
          stepType === "ActionNode" &&
          step?.extras?.runbookNode?.actionNodeDef?.name === "AWS" ? (
            <>
              <SnippetActionOption
                step={step}
                consumingInput={this.props.input}
                name={title}
                runbookObj={this.props.runbookObj}
                onChangeCallBack={this.props.onChangeCallBack}
                updateInputControl={this.props.updateInputControl}
                notifyRunbookUpdate={this.props.notifyRunbookUpdate}
                previousStepSourceValue={this.state.previousStepSourceValue}
                setPreviousStepSourceValue={this.setPreviousStepSourceValue}
              />
              {this.state.previousStepSourceValue &&
                this.state.previousStepSourceValue !== "execution_status" &&
                this.props.showOutputPath && (
                  <ActionNodeOption
                    step={step}
                    consumingInput={this.props.input}
                    name={title}
                    runbookObj={this.props.runbookObj}
                    onChangeCallBack={this.props.onChangeCallBack}
                    updateInputControl={this.props.updateInputControl}
                    previousStepSourceValue={this.state.previousStepSourceValue}
                    notifyRunbookUpdate={this.props.notifyRunbookUpdate}
                    updateHandler={this.props.onChangeCallBack}
                  />
                )}
            </>
          ) : (
            this.getPreviousStepOutputOptions(stepType, step, title)
          )}
        </div>
      </React.Fragment>
    );
  }
}

FromPreviousStep.defaultProps = {
  showOutputPath: true,
};

const mapState = ({ actionNodeReducer }) => ({
  selectedOutput: actionNodeReducer.selectedOutput,
});

const mapDispatch = dispatch => ({
  updateSelectedOutput: output => {
    dispatch({
      type: SET_SELECTED_OUTPUT,
      payload: output,
    });
  },
});

export default connect(mapState, mapDispatch)(FromPreviousStep);
