import React from "react";
import PropTypes from "prop-types";
import SelectSelected from "./select-components/SelectSelected";
import * as SelectEvents from "./lib/select.events";
import "./select.scss";
import "./animation.scss";
import SelectOption from "./select-components/SelectOption";
import * as SelectLib from "./lib/select.lib";
import * as SelectLifecycles from "./lib/select.lifecycles";
import IconLeft from "./select-components/IconLeft";

let self;

export default class Select extends React.PureComponent {
  constructor(props) {
    super(props);
    SelectLifecycles.init.call(this);
    self = this;
  }

  componentDidMount() {
    SelectLifecycles.didMount.call(this);
  }

  componentDidUpdate(prevProps) {
    SelectLifecycles.update.call(this, prevProps);
  }

  componentWillUnmount() {
    /* we do not need to listen for a mouseclick on this component on unmount */
    window.removeEventListener("click", this.close, false);
  }

  /* we not only want to contract this toggle component, we also need to remove any
  formatting added when we found something we were searching for */
  close = () => {
    SelectEvents.close.call(this);
  };

  /* this will get called when we select an option via text search */
  setDisplay = async selectedText => {
    await SelectEvents.setDisplay.call(this, selectedText);
    SelectEvents.resetSearchSuccess.call(this);
  };

  /* this gets passed down as a prop to the select option and triggered when an option is clicked */
  setValue = (value, text) => {
    this.setState({
      value,
      text,
    });
  };

  /* when does this get called when we actually click on an option */
  onClick = async (value, text) => {
    SelectEvents.onClick.call(this, value, text);
  };

  /* this is called any time we select an option or click the right arrow */
  toggle = e => {
    SelectEvents.toggleAndCleanup.call(this, e);
  };

  /* updates the state value with the searchValue, which then triggers the find method via prop update in SelectSelected,
    this passes down a trigger in SelectOption which takes that search value and determines if there is a match within
    SelectOption text. Note: it searches the text, not the value
   */
  performSearch = searchValue => {
    this.setState({
      searchValue,
    });
  };

  clearSearch = () => {
    this.setState(
      {
        value: "",
        text: "",
        selectedText: "",
      },
      () => {
        if (this.props.onClear) {
          this.props.onClear();
        }
      },
    );
  };

  static clearSearchValue() {
    self.clearSearch();
  }

  /* if the <Enter> key is hit, we want to select first item that matches the search text */
  enterSelect = () => {
    SelectLib.selectFirstInstance.call(this);
  };

  setStaticValue = (value, text) => {
    this.props.onChange(value, text);
  };

  render() {
    /* Comes from SelectLib */
    const children = this.initChildren();
    const totalHeight = this.calculateHeight(children);
    return (
      <div
        className="select-container select-container-contracted svg-wrapper"
        ref={this.ref}
        style={{ width: this.state.width }}
      >
        <ul className={`select-menu ${this.state.display}`}>
          {this.state.parent && (
            <li className={this.props.classname}>
              <div
                className={`flex-column title-bar ${this.props.titleClassname}`}
                onClick={e => this.toggle(e)}
                style={
                  ({ ...this.state.style },
                  { height: `${this.props.optionHeight}px` })
                }
                ref={this.titleBarRef}
              >
                {this.props.iconLeft && (
                  <IconLeft
                    iconLeft={SelectLib.setIconImage(this.props.iconLeft)}
                  />
                )}
                <SelectSelected
                  selectedText={this.props.value}
                  value={this.state.value}
                  toggle={this.toggle}
                  showArrow={this.props.showArrow && totalHeight > 0}
                  expanded={this.state.toggled}
                  searchable={this.props.searchable}
                  performSearch={this.performSearch}
                  enterSelect={this.enterSelect}
                  searchSuccess={this.state.searchSuccess}
                  setStaticValue={this.setStaticValue}
                  animationBorder={this.props.animationBorder}
                  parent={this.state.parent}
                  drowpdownStyle={this.props.dropdownStyle}
                  required={this.props.required}
                  requiredText={this.props.requiredText}
                  selectedValue={this.state.value}
                  title={this.props.title}
                  toggled={this.state.toggled}
                  clearSearch={this.clearSearch}
                  max={this.props.max}
                />
              </div>
              <div className="flex-column">
                <ul
                  style={{
                    height: this.state.toggled
                      ? `${
                          totalHeight < this.props.maxHeight
                            ? totalHeight
                            : this.props.maxHeight
                        }px`
                      : "0",
                    top:
                      this.props.dropdownStyle === "overlay"
                        ? `${this.props.optionHeight}px`
                        : 0,
                  }}
                  ref={this.ulRef}
                  className={this.props.dropdownStyle}
                >
                  {this.props.allowEmpty && (
                    <SelectOption
                      value=""
                      onClick={this.onClick}
                      selectedValue={this.props.value}
                      setDisplay={this.setDisplay}
                      setValue={this.setValue}
                      searchValue={this.state.searchValue}
                      searchMin={this.props.searchMin}
                      scrollToResult={this.scrollToResult}
                    ></SelectOption>
                  )}
                  {children}
                </ul>
              </div>
            </li>
          )}
        </ul>
      </div>
    );
  }
}

Select.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  titleClassname: PropTypes.string,
  classname: PropTypes.string,
  showArrow: PropTypes.bool,
  style: PropTypes.object,
  searchMin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  searchable: PropTypes.bool,
  maxHeight: PropTypes.number,
  dropdownStyle: PropTypes.oneOf(["pushdown", "overlay"]),
  allowEmpty: PropTypes.bool,
  animationBorder: PropTypes.oneOf(["none", "hover", "click"]),
  width: PropTypes.string,
  filterOnSearch: PropTypes.bool,
  required: PropTypes.bool,
  requiredText: PropTypes.string,
  title: PropTypes.string,
  optionHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  max: PropTypes.number,
  iconLeft: PropTypes.string,
};

Select.defaultProps = {
  onChange: (text, value) => {
    console.log(text, value);
  },
  classname: "select-title-default",
  showArrow: true,
  style: {},
  searchMin: 3,
  searchable: true,
  maxHeight: 200,
  dropdownStyle: "pushdown",
  allowEmpty: false,
  animationBorder: "none",
  width: "250px",
  filterOnSearch: true,
  required: false,
  requiredText: "",
  title: "",
  optionHeight: "40",
  max: 45,
};
