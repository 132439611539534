import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DataTable } from "@components/ui/data-table";
import tableColumns from "./tableColumns";
import { SettingsPanelHeader } from "../settings-panel-components";
import { Wait, Confirm, FTNotification, Modal } from "@components/ui";
import ManageSSHTarget from "./ManageSSHTargets";
import { toggleDeleteConfirmBox } from "@redux/actions/AWSSettings.actions";
import Api from "@lib/api";
import { isFetching } from "@redux/actions/settingsPanel.action";
import {
  fetchSSHTargets,
  removeSSHTarget,
} from "@redux/actions/sshTargets.action";

export type TypeSSHTarget = {
  targetName: string;
  hostIdentifier: string;
  privateKey: string;
};

/* Constants */
const TEXT_AREA_STYLE = {
  width: "96%",
  height: "16rem",
  paddingTop: "0.5rem",
};

const INITIAL_TARGET_VALUE = {
  targetName: "",
  privateKey: "",
  hostIdentifier: "",
};

const SSHTargets = () => {
  const dispatch = useDispatch();
  const showConfirm = useSelector(state => state.uiReducer.showConfirm);

  const [selectedTarget, setSelectedTarget] = useState(INITIAL_TARGET_VALUE);
  const loadingMessage = useSelector(state => state.runbooksReducer.message);
  const sshTargetData = useSelector(state => state.SSHTargetReducer.sshTargets);

  /* Show manage(add/edit) modal */
  const [showManageSSHForm, setShowManageSSHForm] = useState(false);

  /* This is to determine if we want to create new target or updating existing one */
  const [sshMode, setSSHMode] = useState("add");
  const [showSSHKey, setShowSSHKey] = useState(false);

  const toggleSSHForm = (mode: string) => {
    setShowManageSSHForm(true);
    if (mode === "add") {
      setSelectedTarget(INITIAL_TARGET_VALUE);
      setSSHMode(mode);
    }
  };

  const onEditHandler = (row: TypeSSHTarget) => {
    setSelectedTarget(row);
    setSSHMode("edit");
    toggleSSHForm("edit");
  };
  const onRemoveHandler = (row: TypeSSHTarget) => {
    setSelectedTarget(row);
    dispatch(toggleDeleteConfirmBox(true));
  };

  const onVerifyAndDelete = async () => {
    dispatch(toggleDeleteConfirmBox(false));
    dispatch(isFetching(true, "Deleting SSH Target"));
    try {
      await Api.deleteSSHTarget(selectedTarget.targetName);
      dispatch(removeSSHTarget(selectedTarget));
      FTNotification.success({
        title: "SSH Target Deleted Successfully!",
      });
    } catch (error) {
      FTNotification.error({
        title: "Could not delete SSH target",
        message: error.message,
      });
    }
    dispatch(isFetching(false, ""));
  };

  const onShowSSHKeyHandler = (row: TypeSSHTarget) => {
    setSelectedTarget(row);
    setShowSSHKey(true);
  };

  const sshTargetCol = tableColumns(
    onRemoveHandler,
    onEditHandler,
    onShowSSHKeyHandler,
  );

  useEffect(() => {
    dispatch(fetchSSHTargets());
  }, [dispatch]);

  return (
    <>
      {showSSHKey && (
        <Modal
          onClose={() => {
            setShowSSHKey(false);
          }}
          title={"Private RSA Key"}
          showClose={true}
          appendToPortal={true}
        >
          <textarea
            className={`text-input w-input copy-text`}
            id="privateKey"
            name="privateKey"
            value={selectedTarget.privateKey}
            cols={60}
            rows={4}
            style={TEXT_AREA_STYLE}
            readOnly
          />
        </Modal>
      )}
      {!!loadingMessage && <Wait text={loadingMessage} />}
      {showConfirm && (
        <Confirm
          heading="Delete Confirmation"
          cancelText="Cancel"
          confirmText="Delete"
          message="This SSH target will be deleted permanently."
          confirmValue={selectedTarget.targetName}
          confirmRequiredText={`Confirm by typing '${selectedTarget.targetName}' below`}
          verifiedCallback={onVerifyAndDelete}
        />
      )}
      {showManageSSHForm && (
        <ManageSSHTarget
          selectedTarget={selectedTarget}
          closeForm={setShowManageSSHForm}
          mode={sshMode}
          sshTargets={sshTargetData}
        />
      )}
      <div className="d-flex flex-column2">
        <SettingsPanelHeader
          isBreadCrumb={true}
          page="ssh-targets"
          heading="SSH Targets"
          breadCrumbText="Settings"
          toggleGitRepoFormVisibility={toggleSSHForm}
        />
        <div className="mt-40 ml-15 gr-list-container aws-settings-list">
          <DataTable
            className="snippet-list-table"
            columns={sshTargetCol}
            data={sshTargetData}
            responsive={true}
            fixedHeader={true}
          />
        </div>
      </div>
    </>
  );
};

export default SSHTargets;
