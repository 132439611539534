import React from "react";

interface IProps {
  value?: string | number | Array<any>;
  text?: string;
  checked?: boolean;
  onChange?(x: boolean, y: string | number | Array<any>): void;
  addToValues(y: string | number | Array<any>);
  forceDeselect?: number;
  optionHeight?: number | string;
}

interface IState {
  checked: boolean;
}

export default class SelectMultipleOption extends React.PureComponent<
  IProps,
  IState
> {
  state = {
    checked: this.props.checked || false,
  };
  ref = null;
  liRef = null;

  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.liRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.checked) {
      this.props.addToValues(this.props.value);
    }
    const toggle = this.toggle.bind(this);
    this.ref.current.addEventListener("click", e => toggle(e), true);
    this.liRef.current.addEventListener("click", e => toggle(e), true);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.forceDeselect !== this.props.forceDeselect) {
      if (this.state.checked) {
        this.setState({
          checked: false,
        });
      }
    } else if (prevProps.checked !== this.props.checked) {
      this.setState({
        checked: this.props.checked,
      });
      if (!this.props.checked) {
        this.props.onChange(this.state.checked, this.props.value);
      }
    }
  }

  toggle = async e => {
    e.cancelBubble = true;
    await this.setState(prevState => ({
      ...prevState,
      checked: !this.ref.current.checked,
    }));
    if (this.props.onChange) {
      this.props.onChange(this.state.checked, this.props.value);
    }
  };

  render() {
    const style = {
      height: `${this.props.optionHeight}px`,
    };
    return (
      <li className="li-option multi" style={style} ref={this.liRef}>
        <input
          type="checkbox"
          value={this.props.value}
          checked={this.state.checked}
          className="default-cursor pr-5 styled-check-box"
          onChange={() => {}}
          ref={this.ref}
        />
        <label className="pl-5 multi">
          {this.props.text || this.props.children}
        </label>
      </li>
    );
  }
}
