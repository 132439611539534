import React from "react";
import { Modal, Button } from "@components/ui";
import { SettingsPanelHeader } from "./settings-panel-components";
import AWSSettingsList from "./AWSSettingsList.tsx";
import FormInput from "./settings-panel-components/SettingsRightConfigPanel/FormInput";
import * as Yup from "yup";
import { Formik } from "formik";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  editAWSTargetAccount,
  updateMessage,
} from "@redux/actions/getStarted.actions";
import { camelToSnake } from "@lib/utils";
import { Wait } from "@components/ui";
import Api from "@lib/api";
import { isEmpty } from "@lib/utils";

class AWSSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSettingsPopup: false,
      nodeName: "AWS Settings",
      showFormSubmitMessage: false,
      isAccountEdit: false,
      currentRow: {},
      isDefault: false,
    };
    this.validationSchema = Yup.object().shape({
      alias: Yup.string()
        .required("Required")
        .min(3, "Alias name should have minimum 3 letters")
        .matches(/^\S*$/, { message: "Alias cannot have spaces" }),
      roleArn: Yup.string().required("Required"),
      externalId: Yup.string().default(""),
      preferredRegion: Yup.string().default(""),
    });
  }
  toggleAWSSettingsModal = actionType => {
    this.setState({
      showFormSubmitMessage: false,
      showSettingsPopup: !this.state.showSettingsPopup,
      currentRow: actionType === "create" ? "" : this.state.currentRow,
      isDefault: actionType === "create" ? false : this.state.isDefault,
      isAccountEdit: actionType === "create" ? false : true,
    });
  };

  enableEditAccount = currentRow => {
    this.setState(
      {
        isAccountEdit: true,
        currentRow,
        showFormSubmitMessage: false,
        isDefault: currentRow.is_default,
      },
      () => {
        this.toggleAWSSettingsModal();
      },
    );
  };

  getFooter = handleSubmit => {
    return (
      <div className="modal-buttons-footer">
        <Button
          text="Cancel"
          buttonStyle="secondary"
          style={{ width: "50%" }}
          onClick={this.toggleAWSSettingsModal}
          type="button"
        />
        <Button
          text={this.state.isAccountEdit ? "Update" : "Save"}
          buttonStyle="primary"
          style={{ width: "50%" }}
          size="large"
          onClick={handleSubmit}
        />
      </div>
    );
  };

  formatData = formData => {
    let finalData = {};
    const ignoreVal = ["preferredRegion", "externalId"];
    Object.keys(formData).forEach(val => {
      if (
        (this.state.isAccountEdit && ignoreVal.indexOf(val) !== -1) ||
        formData[val] !== ""
      ) {
        finalData[camelToSnake(val)] = formData[val];
      }
    });
    finalData["is_default"] = this.state.isDefault;
    return finalData;
  };

  render() {
    return (
      <>
        {this.props.message && <Wait text={this.props.message} />}

        <div className="d-flex flex-column2">
          {/* <Breadcrumbs text={"Settings"} navigateTo="settings" /> */}
          <SettingsPanelHeader
            isBreadCrumb={true}
            page="aws-settings"
            toggleAWSSettingsModal={this.toggleAWSSettingsModal}
            heading="AWS Target Accounts"
            breadCrumbText="Settings"
          />
          <>
            {this.state.showSettingsPopup && (
              <Formik
                initialValues={{
                  alias:
                    this.state.currentRow && this.state.currentRow.alias
                      ? this.state.currentRow.alias
                      : "",
                  roleArn:
                    this.state.currentRow && this.state.currentRow.role_arn
                      ? this.state.currentRow.role_arn
                      : "",
                  externalId:
                    this.state.currentRow && this.state.currentRow.external_id
                      ? this.state.currentRow.external_id
                      : "",
                  preferredRegion:
                    this.state.currentRow &&
                    this.state.currentRow.preferred_region
                      ? this.state.currentRow.preferred_region
                      : "",
                  isDefault: this.state.isDefault,
                }}
                onSubmit={async (values, actions) => {
                  this.props.updateMessage("Updating Target Account ...");
                  const finalFormData = this.formatData(values);
                  try {
                    const alias = finalFormData.alias;
                    delete finalFormData.alias;
                    const response = await Api.updateAWSTargetAccount(
                      finalFormData,
                      alias,
                    );
                    this.props.updateMessage("");
                    if (
                      !isEmpty(response) &&
                      (response.status === 200 || response.status === 201)
                    ) {
                      const data =
                        typeof response.data === "string"
                          ? JSON.parse(response.data)
                          : response.data;

                      // Update AWS account list with the filtered list in case of EDIT
                      const updatedAccountsList = this.props.awsTargetAccountList.map(
                        val => {
                          if (val.alias === data.alias) {
                            return data;
                          }
                          return val;
                        },
                      );

                      this.props.editAWSTargetAccount(updatedAccountsList);
                      this.toggleAWSSettingsModal();
                      this.setState({
                        currentRow: {},
                        isDefault: false,
                      });
                    } else {
                      actions.setStatus({
                        formSubmitMessage: "Could not update information",
                      });
                    }
                  } catch (error) {
                    this.props.updateMessage("");
                    actions.setStatus({
                      formSubmitMessage: "Could not update information",
                    });
                  }
                }}
                validationSchema={this.validationSchema}
              >
                {props => {
                  const {
                    touched,
                    errors,
                    handleChange,
                    handleSubmit,
                    status,
                  } = props;
                  return (
                    <Modal
                      onClose={this.toggleAWSSettingsModal}
                      title="AWS Settings"
                      showClose={true}
                      footer={this.getFooter(handleSubmit)}
                    >
                      <div className="form-item__mb-l">
                        <FormInput
                          fieldName="Account Alias"
                          name={"alias"}
                          id="alias"
                          handleChange={handleChange}
                          touched={touched}
                          errors={errors}
                          readOnly={this.state.isAccountEdit}
                        />
                      </div>
                      <div className="form-item__mb-l">
                        <FormInput
                          fieldName="AssumeRole ARN"
                          name="roleArn"
                          id="roleArn"
                          handleChange={handleChange}
                          touched={touched}
                          errors={errors}
                        />
                      </div>
                      <div className="form-item__mb-l">
                        <FormInput
                          fieldName="External ID"
                          id="externalId"
                          name="externalId"
                          handleChange={handleChange}
                          touched={touched}
                          errors={errors}
                        />
                      </div>
                      <div className="form-item__mb-l">
                        <FormInput
                          fieldName="Preferred Region"
                          id="preferredRegion"
                          name="preferredRegion"
                          handleChange={handleChange}
                          touched={touched}
                          errors={errors}
                        />
                      </div>
                      {!this.state.isAccountEdit && (
                        <>
                          <input
                            type="checkbox"
                            className="styled-check-box"
                            checked={this.state.isDefault}
                            onChange={() =>
                              this.setState({
                                isDefault: !this.state.isDefault,
                              })
                            }
                            value={this.state.isDefault}
                            id={`dropdown-option-checkbox-default-target-account`}
                          />
                          <label
                            className="label-for-check-box"
                            htmlFor={`dropdown-option-checkbox-default-target-account`}
                          >
                            Is Default
                          </label>
                        </>
                      )}
                      <br />
                      {!!status && (
                        <div className="input-feedback">
                          {status.formSubmitMessage}
                        </div>
                      )}
                    </Modal>
                  );
                }}
              </Formik>
            )}
          </>
          <AWSSettingsList enableEditAccount={this.enableEditAccount} />
        </div>
      </>
    );
  }
}
const mapStateToProps = state => ({
  awsTargetAccountList: state.getStartedReducer.userDefaultAccounts,
  message: state.runbooksReducer.message,
});
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      editAWSTargetAccount,
      updateMessage,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AWSSettings);
