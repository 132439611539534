import React from "react";
import PropTypes from "prop-types";

const InstanaWebhookStatus = ({
  instanaWebhookStatus,
  toggleInstanaWebhookStatus,
}) => {
  return (
    <div className="slack-notifications">
      <div className="slack-notifications-toggle">
        <label className="label label__padding-left">
          Enable/Disable Instana Webhook
        </label>
        <>
          <label className="switch">
            <input
              type="checkbox"
              id="toggle_slack_notifications"
              value={instanaWebhookStatus}
              onClick={toggleInstanaWebhookStatus}
              defaultChecked={instanaWebhookStatus}
            />
            <span className="slider round"></span>
          </label>
        </>
      </div>
    </div>
  );
};

InstanaWebhookStatus.propTypes = {
  instanaWebhookStatus: PropTypes.bool,
  toggleInstanaWebhookStatus: PropTypes.func,
};

export default InstanaWebhookStatus;
