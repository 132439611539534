import React from "react";
import difference from "lodash/difference";
import {
  Accordion,
  AccordionLabel,
  AccordionSection,
  DropDown,
  DropDownOption,
  LabelTop,
  FTNotification,
} from "@components/ui";
import { Consumer } from "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.context";
import RuleDetails from "./rule-list/rule-details";
import ReactCSSTransition from "@components/shared/ReactCSSTransition";
import { updateLinks } from "./../../../../runbook-editor-lib/runbook-editor.helpers";
import { RunbookStepInputSource } from "./../../../../runbook-editor-lib/ssm/nodeinputoutput";
import join from "lodash/join";

export default class ConditionalInput extends React.Component {
  state = {
    openRules: false,
    invalidFields: [],
  };

  validateRuleDetails = activeNode => {
    let invalidFields = [];
    if (!activeNode.extras) return false;

    if (!activeNode.extras.runbookNode.choices[0].nextStep) {
      invalidFields.push("Destination");
    }

    if (
      ["", null, undefined].includes(
        activeNode.extras.runbookNode.choices[0].condition.value,
      )
    ) {
      invalidFields.push("Comparison Value");
    }
    if (invalidFields.length) {
      FTNotification.error({
        title: "Required Fields",
        message: `Please fill: ${join(invalidFields, ", ")}`,
        timeout: 2,
      });
      return false;
    }
    return true;
  };

  toggleRules = () => {
    this.setState({
      openRules: !this.state.openRules,
    });
  };

  ruleDefaultFromNode(activeNode) {
    const { runbookNode } = activeNode.extras;
    return runbookNode.getDefault
      ? runbookNode.getDefault()
      : runbookNode.inputs.Default;
  }

  ruleDefaultControlForNode(activeNode) {
    const { runbookNode } = activeNode.extras;
    return (
      <div className="editor-detail-panel editor-detail-panel-column">
        <div className="editor-terraform-container">
          <LabelTop
            label={`Next step if no rule is satisfied`}
            labelClassName="label"
          >
            <DropDown
              title={runbookNode.defaultNextStep || ""}
              showArrow={true}
              iconLeft="icon-filter"
              style={{ width: "calc(100% - 8px)", marginTop: "0px" }}
              onChange={newValue => {
                runbookNode.defaultNextStep = newValue;
                runbookNode.runbook.updateDAGAtStep(runbookNode);
                this._updateLinks(activeNode, runbookNode);
              }}
            >
              {runbookNode.legalSuccessors().map(succ => (
                <DropDownOption text={succ.name} key={succ.name} />
              ))}
            </DropDown>
          </LabelTop>
        </div>
      </div>
    );
  }

  _updateLinks(activeNode, runbookStep) {
    const links = activeNode
      .getOutPorts()
      .flatMap(port => Object.values(port.getLinks()));

    let linkTargets = links.map(
      link => link.targetPort?.getNode()?.extras?.runbookNode?.name || "",
    );
    // Removes empty strings from linksTargets to deal with "" added as a result of optional chaining check.
    linkTargets = linkTargets.filter(link => link);

    const newTargets = runbookStep.nextSteps();

    const addLinks = difference(newTargets, linkTargets);
    const removeLinks = difference(linkTargets, newTargets);
    let target;
    for (target of removeLinks) {
      const link = links.find(
        // eslint-disable-next-line no-loop-func
        l => l.targetPort.getNode().extras.runbookNode.name === target,
      );
      link.remove();
    }
    for (target of addLinks) {
      this.props.addLinkToStep(
        activeNode,
        runbookStep.runbook.mainStepIndex[target],
      );
    }
    activeNode.extras.runbookNode.showHideWarning(
      !activeNode.extras.runbookNode.isHealthyStep(),
    );
    this.props.rerenderEditor();
  }

  toggleInputOption = optionFilterId => {
    if (typeof optionFilterId === "object") {
      const obj = optionFilterId.target;
      if (obj.classList.contains("checkmark")) {
        this.timer = setTimeout(() => {
          this.setState({ optionFilterId: "none" });
        }, 100);
      } else {
        if (obj.type === "text") {
          return;
        }

        this.setState({ optionFilterId: "none" });
      }
    } else {
      this.setState({ optionFilterId });
    }
  };

  deleteConditionalRule = (activeNode, notifyRunbookUpdate) => {
    let choice = activeNode.extras.runbookNode.choices[0];
    choice.condition.operator = "";
    choice.condition.value = "";
    choice.nextStep = "";

    choice.ssmStep.runbook.updateDAGAtStep(choice.ssmStep);

    updateLinks(
      activeNode,
      choice.ssmStep,
      this.props.addLinkToStep,
      this.props.rerenderEditor,
    );
    let input = choice.inputs()[0];

    input.source = new RunbookStepInputSource("constant", "");
    activeNode.extras.runbookNode.didUpdateStep({});
    notifyRunbookUpdate(true);
    this.forceUpdate();
  };

  updateSelectedVariableType = selectedVariableType =>
    this.setState({ selectedVariableType });

  render() {
    return (
      <Consumer>
        {({ activeNode, notifyRunbookUpdate }) => {
          return (
            <ReactCSSTransition panel={"input-panel"}>
              {!this.state.openRules ? (
                <div key={"rule"}>
                  <div className="editor-detail-panel editor-detail-panel-row">
                    <div className="editor-detail-panel-icon editor-detail-panel-icon-select" />
                    <div>Conditional</div>
                  </div>
                  <Accordion isExpanded={true} useArrow={true}>
                    <AccordionLabel className="editor-accordion-label">
                      RULES
                    </AccordionLabel>
                    <AccordionSection>
                      <div className="editor-detail-panel editor-detail-panel-column">
                        <div className="label">
                          Define conditional by adding a rule
                        </div>
                        <div className="rule-actions"></div>

                        {!!activeNode.extras.runbookNode.choices[0].condition
                          .value ||
                        activeNode.extras.runbookNode.choices[0].nextStep ? (
                          <div
                            className="rule-list rule-list-wrap"
                            style={{
                              width: "calc(100% - 8px)",
                              marginTop: "0px",
                            }}
                          >
                            <div className="rule-list-item">
                              <div>Rule 1</div>
                              <div className="rule-actions">
                                <div
                                  className="rule-icon rule-icon-edit"
                                  onClick={this.toggleRules}
                                />
                                <div
                                  className="rule-icon rule-icon-delete"
                                  onClick={e => {
                                    e.preventDefault();
                                    this.deleteConditionalRule(
                                      activeNode,
                                      notifyRunbookUpdate,
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div
                            className="rule-list rule-list-wrap rule-list-empty mt-10"
                            onClick={this.toggleRules}
                            style={{
                              width: "calc(100% - 12px)",
                              marginTop: "0px",
                            }}
                          >
                            + Add Rule
                          </div>
                        )}
                      </div>
                    </AccordionSection>
                  </Accordion>
                  <Accordion isExpanded={true} useArrow={true}>
                    <AccordionLabel className="editor-accordion-label">
                      Fallback Destination
                    </AccordionLabel>
                    <AccordionSection>
                      <div>
                        <div>{this.ruleDefaultControlForNode(activeNode)}</div>
                      </div>
                    </AccordionSection>
                  </Accordion>
                </div>
              ) : (
                <>
                  <RuleDetails
                    key={"ruleDetails"}
                    rerenderEditor={this.props.rerenderEditor}
                    addLinkToStep={this.props.addLinkToStep}
                    toggle={() =>
                      this.validateRuleDetails(activeNode) && this.toggleRules()
                    }
                    toggleInputOption={this.toggleInputOption}
                    optionFilterId={this.state.optionFilterId}
                    choice={activeNode.extras.runbookNode.choices[0]}
                  />
                </>
              )}
            </ReactCSSTransition>
          );
        }}
      </Consumer>
    );
  }
}
