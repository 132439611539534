import React, { useState } from "react";
import { IconHelp } from "@components/ui/icons";
import { Consumer } from "../../../runbook-editor-lib/runbook-editor.context";

const Help = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const toggleIsDropdownOpen = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const handleSupport = () => {
    toggleIsDropdownOpen();
  };
  const handleResetWalkthrough = resetWalkthrough => {
    toggleIsDropdownOpen();
    resetWalkthrough();
  };
  return (
    <Consumer>
      {({ resetWalkthrough }) => (
        <>
          <div
            className="editor-menu-item-container help"
            data-tut="reactour__help"
            onClick={toggleIsDropdownOpen}
          >
            <IconHelp className="editor-menu-item-svg" />
          </div>
          {isDropdownOpen && (
            <div className="help-overlay" onClick={toggleIsDropdownOpen}></div>
          )}
          {isDropdownOpen && (
            <div className="help-dropdown">
              <div className="help-dropdown-option" onClick={handleSupport}>
                <div className="help-dropdown-option-icons help-dropdown-option-icons-support"></div>
                <span>Support</span>
              </div>
              <div className="help-dropdown-carrot"></div>
              <div
                className="help-dropdown-option"
                onClick={() => handleResetWalkthrough(resetWalkthrough)}
              >
                <div className="help-dropdown-option-icons help-dropdown-option-icons-reset"></div>
                <span>Reset Walkthrough</span>
              </div>
            </div>
          )}
        </>
      )}
    </Consumer>
  );
};

export default Help;
