import React from "react";
import { Provider } from "./executions-lib/executions.context";
import { Wait } from "@components/ui";
import {
  ExecutionsQuery,
  ExecutionsOverTime,
  ExecutionsTable,
} from "./executions-components";
import {
  executionsFilterData,
  timeSeriesValues,
  getCutoffDateBasedOnFilter,
} from "./executions-lib/executions.helpers";
import "./Executions.scss";
import { setTitle } from "@lib/utils";
import remove from "lodash/remove";
import {
  executionStatusMap,
  defaultExecutionTimeFilter,
} from "@lib/utils/constants";
import moment from "moment";
import { HeaderMain } from "@components/modules";
import IconRefresh from "@assets/images/icons/icon-refresh.svg";
import ReactTooltip from "react-tooltip";

export default class Executions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      executionsData: this.props.executionsData,
      filteredExecutionsData: this.props.executionsData,
      filteredExecutionsBySearchTerm: this.props.executionsData,
      searchTerm: "",
      selectedStatuses: [],
      filteredData: {},
      currentTimeOption: defaultExecutionTimeFilter,
    };
  }
  componentDidMount() {
    let searchTerm =
      new URLSearchParams(this.props.location?.search).get("searchTerm") || "";
    this.props.fetchExecutions(searchTerm);

    /**
     * Flag to check if user is coming from executions sub-components through Breadcrumb
     */
    const isFilterPersisted = this.props?.location?.state?.persistFilter;
    /* Update search term in persisted reducer (CommonReducer) if present in URL */
    !!searchTerm && this.props.updateExecutionSearchTerm(searchTerm);

    if (!searchTerm && !!this.props.executionSearchTerm) {
      /* case if: user coming from details page through breadcrumb, 
                  search result maintained from persisted store
        case else: user coming from somewhere else / manually removing search term from url
      */
      if (!!isFilterPersisted) searchTerm = this.props.executionSearchTerm;
      else this.props.updateExecutionSearchTerm(searchTerm);
    }

    let selectedStatuses = [...this.state.selectedStatuses];
    /* Update status filter in persisted reducer (CommonReducer) */
    /* case inner if: update current status filter from stored status if coming from breadcrumb 
      case inner else: Update status filter in persisted reducer 
      with fresh status on page load from anywhere else other than breadcrumb
    */

    if (
      !this.state.selectedStatuses.length &&
      this.props.executionStatusFilter.length
    ) {
      !!isFilterPersisted
        ? (selectedStatuses = this.props.executionStatusFilter)
        : this.props.updateExecutionStatusFilter(this.state.selectedStatuses);
    }

    /* Update time filter in persisted reducer (CommonReducer) */
    /* case inner if: update current time filter from stored time if coming from breadcrumb 
      case inner else: Update time filter in persisted reducer 
      with fresh time on page load from anywhere else other than breadcrumb
    */
    let currentTimeOption = this.state.currentTimeOption;

    if (currentTimeOption !== this.props.executionTimeFilter) {
      !!isFilterPersisted
        ? (currentTimeOption = this.props.executionTimeFilter)
        : this.props.updateExecutionTimeFilter(currentTimeOption);
    }
    this.setState(
      {
        searchTerm,
        selectedStatuses,
        currentTimeOption,
      },
      () => this.filterExecutionDetails(),
    );
    setTitle("Fylamynt | Executions");
  }

  componentDidUpdate(prevProps) {
    if (prevProps.executionsData !== this.props.executionsData) {
      this.setState(
        {
          executionsData: this.props.executionsData,
          filteredExecutionsData: this.props.executionsData,
          filteredExecutionsBySearchTerm: this.props.executionsData,
        },
        () => {
          this.filterData(this.state.currentTimeOption);
          this.filterExecutionDetails();
        },
      );
    }
  }

  filterData = timeOption => {
    /**
     * Update Time filter in store with current time option
     */
    this.props.updateExecutionTimeFilter(timeOption);
    this.setState({ currentTimeOption: timeOption });
    if (!this.props.executionsData) {
      //this.props.fetchExecutions();
      return;
    }
    let timeSeriesObject = {
      all: this.state.filteredExecutionsBySearchTerm,
    };

    this.state.selectedStatuses.length &&
      this.state.selectedStatuses.map(status => {
        timeSeriesObject[status] = this.state.executionsData.filter(
          execution =>
            status === executionStatusMap[execution.AutomationExecutionStatus],
        );
        return null;
      });

    !!this.state.searchTerm &&
      Object.keys(timeSeriesObject).map(val => {
        timeSeriesObject[val] = timeSeriesObject[val].filter(execution => {
          const searchTerm =
            this.state.searchTerm && this.state.searchTerm.toLowerCase().trim();
          const executionName =
            execution.DocumentName && execution.DocumentName.toLowerCase();
          return executionName.includes(searchTerm);
        });
        return null;
      });
    Object.keys(timeSeriesObject).map(val => {
      timeSeriesObject[val] = timeSeriesValues(
        executionsFilterData(timeSeriesObject[val], timeOption)["all"],
        timeOption,
      );
      return null;
    });
    const cutOffDate = getCutoffDateBasedOnFilter(timeOption);
    this.setState({
      filteredData: timeSeriesObject,
      filteredExecutionsBySearchTerm: this.props.executionsData
        .filter(item => moment(item.ExecutionStartTime).isAfter(cutOffDate))
        .filter(item =>
          item?.DocumentName.toLowerCase().includes(
            this.state.searchTerm.toLowerCase(),
          ),
        ),
    });
  };

  filterExecutionDetails = () => {
    if (!this.state.executionsData) return;
    const executionsDataCopy = [...this.state.executionsData];

    const executionsFilteredBySearchTerm = this.state.searchTerm
      ? executionsDataCopy.filter(execution => {
          const searchTerm =
            this.state.searchTerm && this.state.searchTerm.toLowerCase().trim();
          const executionName =
            execution.DocumentName && execution.DocumentName.toLowerCase();

          return executionName.includes(searchTerm);
        })
      : executionsDataCopy;

    const executionsFilteredByStatuses = this.state.selectedStatuses.length
      ? executionsFilteredBySearchTerm.filter(execution => {
          return this.state.selectedStatuses.find(
            status =>
              status ===
              executionStatusMap[execution.AutomationExecutionStatus],
          );
        })
      : executionsFilteredBySearchTerm;

    this.setState(
      {
        filteredExecutionsData: executionsFilteredByStatuses,
        filteredExecutionsBySearchTerm: executionsFilteredBySearchTerm,
      },
      () => this.filterData(this.state.currentTimeOption),
    );
  };

  updateFilterByStatuses = status => {
    if (Array.isArray(status)) {
      this.props.updateExecutionStatusFilter(status);
      this.setState(
        {
          selectedStatuses: status,
        },
        this.filterExecutionDetails,
      );
    } else {
      let selectedStatusesCopy = [...this.state.selectedStatuses];
      this.state.selectedStatuses.indexOf(status) === -1
        ? selectedStatusesCopy.push(status)
        : remove(
            selectedStatusesCopy,
            selectedStatus => selectedStatus === status,
          );
      this.setState(
        {
          selectedStatuses: selectedStatusesCopy,
        },
        () => {
          /**
           * Update stored Status filter with current status filter
           */
          this.props.updateExecutionStatusFilter(selectedStatusesCopy);
          this.filterExecutionDetails();
        },
      );
    }
  };

  updateSearchTerm = searchTerm => {
    /**
     * Update stored Search Term with current search term
     */
    this.props.updateExecutionSearchTerm(searchTerm);
    this.setState({ searchTerm }, this.filterExecutionDetails);
  };

  clearReducerFilters = () => {
    this.props.updateExecutionSearchTerm("");
    this.props.updateExecutionStatusFilter([]);
    this.props.updateExecutionTimeFilter();
  };

  resetFilters = () => {
    /**
     * Clear Stored Reducer Filters
     */
    this.clearReducerFilters();
    this.setState(
      {
        searchTerm: "",
        selectedStatuses: [],
        currentTimeOption: defaultExecutionTimeFilter,
      },
      this.filterExecutionDetails,
    );
  };

  render() {
    const { isFetchingExecution } = this.props;
    const contextValue = {
      versions: this.props.versions,
      executionsData: this.state.executionsData,
      filteredExecutionsData: this.state.filteredExecutionsData?.filter(item =>
        moment(item.ExecutionStartTime).isAfter(
          getCutoffDateBasedOnFilter(this.state.currentTimeOption),
        ),
      ),
      filterData: this.filterData,
      filteredData: this.state.filteredData,
      defaultTimeFilter: this.state.currentTimeOption,
    };
    return (
      <Provider value={contextValue}>
        <>
          {isFetchingExecution && <Wait text={this.props.message} />}
          <div className="d-flex flex-column2">
            <HeaderMain title="All Executions" showBreadcrumbs={true} />
            <div className="executions-container">
              <ExecutionsQuery
                updateFilterByStatuses={this.updateFilterByStatuses}
                selectedStatuses={this.state.selectedStatuses}
                updateSearchTerm={this.updateSearchTerm}
                searchTerm={this.state.searchTerm}
              />
              <ExecutionsOverTime
                resetFilters={this.resetFilters}
                filteredExecutionsBySearchTerm={
                  this.state.filteredExecutionsBySearchTerm
                }
              />
              <ReactTooltip
                id="refresh-executions"
                place="left"
                effect="solid"
                type="light"
                className="tooltip-container"
              />
              <div
                className="executions-refresh-icon"
                data-for="refresh-executions"
                data-tip="Refresh Executions"
                onClick={() => {
                  this.props.fetchExecutions(this.state.searchTerm);
                }}
              >
                <img
                  src={IconRefresh}
                  alt="refreshIcon"
                  height="16"
                  width="16"
                />
              </div>
              <ExecutionsTable />
            </div>
          </div>
        </>
      </Provider>
    );
  }
}
