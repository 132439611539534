import React from "react";
import ConfigPanelHeader from "./ConfigPanelHeader";
import ConfigPanelContent from "./ConfigPanelContent";
import "./settings-panel-config-pane.scss";

class SettingsPanelConfigPane extends React.Component {
  render() {
    return (
      <div>
        <ConfigPanelHeader />
        <ConfigPanelContent />
      </div>
    );
  }
}

export default SettingsPanelConfigPane;
