import React from "react";

export default () => (
  <>
    <h3 className="h2-margin">Instructions to setup Pulumi Integration</h3>
    Follow these step to allow Fylamynt access:
    <div className="list-row pt-30 pl-10">
      <div className="step-number">
        <div>1</div>
      </div>
      <div>
        Create/Pick an S3 bucket that is accessible by the Target Account. Enter
        this as the <b>Backend</b>
      </div>
    </div>
    <div className="list-row pl-10">
      <div className="step-number">
        <div>2</div>
      </div>
      <div>
        For a new Pulumi stack, add the <b>Passphrase</b>
      </div>
    </div>
    <div className="list-row  pl-10">
      <div className="step-number">
        <div>3</div>
      </div>
      <div>
        For an existing Pulumi stack, make sure that the secret provider used is
        `passphrase` by running the following command in the Pulumi project
        directory:
        <br />
        <code className="instruction-higlight-code ">
          pulumi stack change-secrets-provider passphrase{" "}
        </code>
      </div>
    </div>
  </>
);
