import React from "react";
import { Provider } from "./snippets-lib/snippets.context";
import * as utils from "@lib/utils";
import {
  SnippetsHeader,
  SnippetsTableContainer,
  SnippetsFilters,
} from "./snippets-components";

export default class Snippets extends React.Component {
  componentDidMount = () => {
    utils.setTitle("Fylamynt | Actions");
    this.props.fetchSnippets();
  };

  render() {
    const contextValue = {
      snippetsList: this.props.snippetsList,
      searchTerm: this.props.searchTerm,
      searchSnippets: this.props.searchSnippets,
      filterSnippetsByService: this.props.filterSnippetsByService,
      resetFilters: this.props.resetFilters,
      filteredServicesSet: this.props.filteredServicesSet,
    };
    return (
      <Provider value={contextValue}>
        <div className="d-flex flex-column2">
          <SnippetsHeader />
          <div className="pl-15">
            <SnippetsFilters />
            <SnippetsTableContainer />
          </div>{" "}
        </div>
      </Provider>
    );
  }
}
