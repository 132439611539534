import React from "react";
import { StatusBlock } from "@components/modules";
import { Link } from "react-router-dom";
import { Consumer } from "../execution-details-lib/execution-details.context";
import DatadogButton from "./step-action-buttons/datadog-button";
import ResumeButton from "./step-action-buttons/resume-button";
import * as moment from "moment";
import {
  dateTimeDisplayFormat,
  dateTimeOnHoverDisplayFormat,
} from "@lib/utils/constants";
import OnHover from "@components/ui/OnHover";

export default class StepTable extends React.Component {
  render() {
    const {
      StepStatus,
      ExecutionEndTime,
      ExecutionStartTime,
      StepExecutionId,
      StepName,
    } = this.props.step;
    return (
      <Consumer>
        {({ executionId, executionStatus, executionDetails }) => (
          <div className="width-full" style={{ marginBottom: "10px" }}>
            <div className="flex-table align-left">
              <div className="flex-row-header">
                <div className="pct-25">Step ID</div>
                <div className="pct-15">Step Status</div>
                <div className="pct-25">Start Time</div>
                <div className="pct-25">End Time</div>
                <div className="pct-15"></div>
              </div>
              <div className="flex-row" role="rowgroup">
                <div className="pct-35">
                  <Link
                    className="link-light-blue"
                    to={`/executions/details/${executionId}/step/${StepExecutionId}`}
                  >
                    {StepExecutionId}
                  </Link>
                </div>
                <div className="pct-15">
                  <StatusBlock
                    status={StepStatus}
                    showBg={false}
                    executionStatus={executionStatus}
                  />
                </div>
                <div className="pct-25">
                  {StepStatus !== "Pending" && (
                    <OnHover
                      title={moment
                        .utc(ExecutionStartTime)
                        .local()
                        .format(dateTimeOnHoverDisplayFormat)}
                    >
                      {moment
                        .utc(ExecutionStartTime)
                        .local()
                        .format(dateTimeDisplayFormat)}
                    </OnHover>
                  )}
                </div>
                <div className="pct-25">
                  {StepStatus === "Success" ||
                  StepStatus === "Failed" ||
                  StepStatus === "TimedOut" ? (
                    <OnHover
                      title={moment
                        .utc(ExecutionEndTime)
                        .local()
                        .format(dateTimeOnHoverDisplayFormat)}
                    >
                      {moment
                        .utc(ExecutionEndTime)
                        .local()
                        .format(dateTimeDisplayFormat)}
                    </OnHover>
                  ) : StepStatus === "Cancelled" ? (
                    <OnHover
                      title={moment
                        .utc(executionDetails.ExecutionEndTime)
                        .local()
                        .format(dateTimeOnHoverDisplayFormat)}
                    >
                      {moment
                        .utc(executionDetails.ExecutionEndTime)
                        .local()
                        .format(dateTimeDisplayFormat)}
                    </OnHover>
                  ) : (
                    ""
                  )}
                </div>
                <div className="pct-15">
                  {this.props.step.StepName === "Datadog" && (
                    <DatadogButton
                      step={this.props.step}
                      executionStatus={executionStatus}
                    />
                  )}
                  {this.props.step.StepName.match(/Pause_[0-9]+/gi) && (
                    <ResumeButton
                      step={this.props.step}
                      executionStatus={executionStatus}
                      text={["Resume", "Resuming", "Resumed"]}
                    />
                  )}
                  {StepName.match(/CloudWatch_Alert/gi) && (
                    <ResumeButton
                      step={this.props.step}
                      executionStatus={executionStatus}
                      text={["Start", "Starting", "Started"]}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </Consumer>
    );
  }
}
