import React from "react";
import { TextInput, TextArea } from "@components/ui";
import { ParameterType } from "@containers/RunbookEditor/runbook-editor-lib/ssm/strings";
import { getInputLimit } from "@containers/RunbookEditor/runbook-editor-lib/ssm/util";
import Filters from "@components/ui/InputTypes/Filters/Filters";
import Tags from "@components/ui/InputTypes/Tags/Tags";
import JSONEditor from "@components/ui/InputTypes/JSONEditor/JSONEditor";
import MapList from "@components/ui/InputTypes/MapList/MapList";
import StringMap from "@components/ui/InputTypes/StringMap/StringMap";
import StringList from "@components/ui/InputTypes/StringList/StringList";
import Password from "@components/ui/InputTypes/Password/Password";
import URL from "@components/ui/InputTypes/URL/URL";
import Boolean from "@components/ui/InputTypes/Boolean/Boolean";
import Number from "@components/ui/InputTypes/Number/Number";

export default class SetValueNow extends React.Component {
  isRequired = (activeNode, type) =>
    activeNode.extras.runbookNode.parameterInputs.find(p => p.name === type)
      ?.required;

  getInputContent = () => {
    const {
      input,
      callBack,
      activeNode,
      updateConstantValueControl,
      searchResourcesList,
      selectResource,
      resourcesListSearch,
    } = this.props;
    const value = prepareTextInput(input);
    const { type } = input;
    let label = this.props.label || input.name;
    let labelName = label;
    if (type === ParameterType.StringList) {
      label = `${label} (Enter an array, e.g. ["i1", "i2"])`;
    }
    let isInvalidInput =
      this.props.input.required &&
      this.props.input?.source?.sourceValue === null;

    switch (labelName) {
      case "Filters":
        return (
          <Filters
            filters={input?.source?.sourceValue ? input.source.sourceValue : []}
            onChange={filters =>
              updateConstantValueControl(filters, callBack, activeNode)
            }
          />
        );
      case "tags":
      case "Tags":
        return (
          <Tags
            title={labelName}
            tags={input?.source?.sourceValue ? input.source.sourceValue : []}
            onChange={tags =>
              updateConstantValueControl(tags, callBack, activeNode)
            }
          />
        );
      case "password":
      case "Password":
        return (
          <Password
            title={labelName}
            readOnly={false}
            value={input?.source?.sourceValue ? input.source.sourceValue : ""}
            onChange={value =>
              updateConstantValueControl(value, callBack, activeNode)
            }
          />
        );
      case "url":
      case "URL":
        return (
          <URL
            title={labelName}
            value={input?.source?.sourceValue ? input.source.sourceValue : ""}
            onChange={value => {
              updateConstantValueControl(value, callBack, activeNode);
            }}
          />
        );
      default:
        switch (type) {
          case "StringList":
            return (
              <StringList
                title={labelName}
                StringListArr={
                  input?.source?.sourceValue ? input.source.sourceValue : []
                }
                onChange={strings =>
                  updateConstantValueControl(strings, callBack, activeNode)
                }
              />
            );
          case "MapList":
            return (
              <MapList
                title={labelName}
                mapListArray={
                  input?.source?.sourceValue ? input.source.sourceValue : []
                }
                onChange={ml =>
                  updateConstantValueControl(ml, callBack, activeNode)
                }
              />
            );
          case "Text":
            return (
              <div className="w-96">
                <label className="label">{labelName}</label>
                <TextArea
                  value={
                    input?.source?.sourceValue ? input.source.sourceValue : ""
                  }
                  onKeyUp={value =>
                    updateConstantValueControl(value, callBack, activeNode)
                  }
                  className="compact-text-area p-10"
                />
              </div>
            );
          case "StringMap":
            let inputValue = input?.source?.sourceValue || {};
            return (
              <StringMap
                title={labelName}
                stringMap={
                  inputValue && typeof inputValue === "string"
                    ? JSON.parse(inputValue)
                    : inputValue
                }
                onChange={value => {
                  updateConstantValueControl(value, callBack, activeNode);
                }}
              />
            );
          case "Map":
          case "Object":
            return (
              <JSONEditor
                value={
                  input.source.sourceValue
                    ? JSON.stringify(input?.source?.sourceValue, undefined, 4)
                    : ""
                }
                onChange={value => {
                  updateConstantValueControl(value, callBack, activeNode);
                }}
                title={labelName}
              />
            );
          case "Boolean":
            return (
              <Boolean
                title={labelName}
                value={
                  input?.source?.sourceValue !== null
                    ? input.source.sourceValue
                    : ""
                }
                onChange={value => {
                  updateConstantValueControl(value, callBack, activeNode);
                }}
              />
            );
          case "Integer":
            return (
              <Number
                title={labelName}
                value={
                  input?.source?.sourceValue ? input.source.sourceValue : null
                }
                onChange={value => {
                  updateConstantValueControl(value, callBack, activeNode);
                }}
                isRequired={this.isRequired(activeNode, type)}
              />
            );
          case "Decimal":
            return (
              <Number
                title={labelName}
                value={
                  input?.source?.sourceValue ? input.source.sourceValue : null
                }
                onChange={value => {
                  updateConstantValueControl(value, callBack, activeNode);
                }}
                isDecimal={true}
                isRequired={this.isRequired(activeNode, type)}
              />
            );

          default:
            return (
              <div className="position-relative">
                <TextInput
                  name={input.name}
                  id={input.name}
                  label={label}
                  labelClassName="label"
                  labelPosition="top"
                  className={
                    isInvalidInput
                      ? "rule-input rule-input-error"
                      : "rule-input"
                  }
                  value={value}
                  onBlur={this.props.onBlur}
                  maxLength={getInputLimit(input.name)}
                  onChange={newValue => {
                    updateConstantValueControl(newValue, callBack, activeNode);
                    searchResourcesList(newValue);
                  }}
                  onClick={event => {
                    searchResourcesList();
                  }}
                />
                {resourcesListSearch && resourcesListSearch.length ? (
                  <div className="constant-autocomplete-list">
                    {resourcesListSearch.map((item, idx) => {
                      return (
                        <ul key={idx} onClick={() => selectResource(item)}>
                          {Object.keys(item).map((i, idx) => {
                            return (
                              <li key={idx}>
                                {i}:{" "}
                                {typeof item[i] === "boolean"
                                  ? item[i] === true
                                    ? "Yes"
                                    : "No"
                                  : item[i]}
                              </li>
                            );
                          })}
                        </ul>
                      );
                    })}
                  </div>
                ) : null}
              </div>
            );
        }
    }
  };
  render() {
    return this.getInputContent();
  }
}

function prepareTextInput(input) {
  let value;
  const sourceVal = input?.source?.sourceValue;
  if (
    typeof sourceVal === "object" ||
    typeof sourceVal === "boolean" ||
    typeof sourceVal === "number"
  ) {
    // hack alert
    try {
      value = JSON.stringify(input.source.sourceValue);
      if (value === "null") {
        value = "";
      }
    } catch (e) {
      //console.log(`ERROR: `, e);
    }
  } else {
    value = sourceVal;
  }
  return value;
}
