import { SchemaError } from "../exceptions";
import { InvokeLambdaStep } from "../ssm/invokelambdastep";
import { SnippetType, ControlNames, StepTypes } from "./strings";
import { BranchStep } from "../ssm/branchstep";
import { SleepStep } from "../ssm/sleepstep";
import { PauseStep } from "../ssm/pausestep";
import { hasKeys } from "@lib/utils";
import { WaitForResourceStep } from "./waitforresourcestep";

export class StepTypeChecker {
  constructor(snippetLibrary) {
    this.snippetLibrary = snippetLibrary;
    this.isStep = StepTypeChecker.isStep;
    this.isInvokeLambdaStep = StepTypeChecker.isInvokeLambdaStep;
    this.isBranchStep = StepTypeChecker.isBranchStep;
  }

  static isStep(json) {
    return !!(json.name && json.action && json.inputs);
  }

  static assertIsSSMStep(json) {
    if (!StepTypeChecker.isStep(json)) {
      throw new SchemaError("SSMStep", json);
    }
  }

  static isInvokeLambdaStep(json) {
    return (
      StepTypeChecker.isStep(json) && json.action === InvokeLambdaStep.ACTION
    );
  }

  static isPauseStep(json) {
    return StepTypeChecker.isStep(json) && json.action === PauseStep.ACTION;
  }

  static isDatadogConnectorStep(json) {
    return json.name.startsWith(ControlNames.DatadogAlert);
  }

  static isTerraformPlanNode(json) {
    return json.name.startsWith("Terraform_Plan");
  }

  static isTerraformUpdateVarsNode(json) {
    return json.name.startsWith("Terraform_Update_Vars");
  }

  static isSlackConnectorStep(json) {
    return StepTypeChecker.isStep(json) && json.name.startsWith("Slack");
  }

  static isJiraConnectorStep(json) {
    return (
      StepTypeChecker.isStep(json) && json.name.startsWith("Jira_Create_Issue")
    );
  }

  static isJiraExistingIssueStep(json) {
    return json.name.startsWith("Jira_Update_Issue");
  }

  static isInstanaAlertStep(json) {
    return (
      StepTypeChecker.isInvokeLambdaStep(json) &&
      json.name.startsWith("Instana_Alert")
    );
  }

  static isWaitForResourceStep(json) {
    // TODO: make a better regex!
    const statemachineRegex = /^.*WaitForResource.*/;
    return (
      json.action === WaitForResourceStep.action &&
      statemachineRegex.test(json.inputs.stateMachineArn)
    );
  }

  static isLoopStep(json) {
    const loopARNRegex = /^arn:aws:lambda:.*:.*:function:loop-for-each-.*-loop$/;
    const loopNameRegex = /^loop-for-each-.*-loop$/;

    return (
      StepTypeChecker.isInvokeLambdaStep(json) &&
      (loopARNRegex.test(json.inputs.FunctionName) ||
        loopNameRegex.test(json.inputs.FunctionName))
    );
  }

  static isLoopSupportStep(stepJSON) {
    const loopPauseRegex = /^.*LoopPause/;
    const loopPostProcessRegex = /^.*LoopPost[Pp]rocess/;
    const isLoopPause =
      stepJSON.action === "aws:pause" && loopPauseRegex.test(stepJSON.name);
    const isLoopPostProcess =
      stepJSON.action === "aws:invokeLambdaFunction" &&
      loopPostProcessRegex.test(stepJSON.name);

    return isLoopPause || isLoopPostProcess;
  }

  static isActionNodeStep(json) {
    const actionNodeARNRegex = /^arn:aws:lambda:.*:.*:function:action-node-.*-action_node$/;
    const actionNodeParamRegex = /\{\{\s*ActionNodeLambda\s*\}\}/;

    return (
      StepTypeChecker.isInvokeLambdaStep(json) &&
      (actionNodeParamRegex.test(json.inputs.FunctionName) ||
        actionNodeARNRegex.test(json.inputs.FunctionName))
    );
  }

  isSnippetStep(json) {
    const snippetDef = this.snippetLibrary.getSnippetDefinitionForStepID(
      json.name,
    );
    return (
      StepTypeChecker.isInvokeLambdaStep(json) &&
      snippetDef &&
      snippetDef.type === SnippetType.SNIPPET
    );
  }

  isTriggerStep(json) {
    const snippetDef = this.snippetLibrary.getSnippetDefinitionForStepID(
      json.name,
    );
    return (
      StepTypeChecker.isInvokeLambdaStep(json) &&
      snippetDef &&
      snippetDef.type === SnippetType.TRIGGER
    );
  }

  static isBranchStep(json) {
    return StepTypeChecker.isStep(json) && json.action === BranchStep.ACTION;
  }

  isConditionalStep(json) {
    const snippetDef = this.snippetLibrary.getSnippetDefinitionForStepID(
      json.name,
    );
    return (
      StepTypeChecker.isBranchStep(json) &&
      snippetDef &&
      snippetDef.type === SnippetType.CONTROL &&
      (snippetDef.name === ControlNames.Conditional ||
        snippetDef.name === ControlNames.CheckELBResult ||
        snippetDef.name === ControlNames.CheckExeStatus)
    );
  }

  isNeurOpsCustomConditionalStep(json) {
    return (
      this.isConditionalStep(json) &&
      (json.name.startsWith(ControlNames.CheckExeStatus) ||
        json.name.startsWith(ControlNames.CheckELBResult))
    );
  }

  static isSleepStep(json) {
    return StepTypeChecker.isStep(json) && json.action === SleepStep.ACTION;
  }

  static isSleepWaitStep(json) {
    return (
      StepTypeChecker.isSleepStep(json) &&
      json.name.startsWith(ControlNames.Wait)
    );
  }

  static isCloudwatchAlertStep(json) {
    return json.name.startsWith(ControlNames.CloudwatchAlert);
  }

  static isJSONPathStep(json) {
    return json.name.startsWith(ControlNames.JSONPathStep);
  }

  static isBucketPickerNode(json) {
    return json.name.startsWith(ControlNames.PickS3Bucket);
  }

  static getType(node) {
    if (node && Object.keys(node).length === 0) {
      return;
    }

    if (hasKeys(node, "extras.runbookNode")) {
      if (StepTypeChecker.isActionNodeStep(node.extras.runbookNode.ssm)) {
        return "ActionNode";
      }
      if (StepTypeChecker.isDatadogConnectorStep(node.extras.runbookNode.ssm)) {
        return ControlNames.DatadogAlert;
      }
      if (StepTypeChecker.isTerraformPlanNode(node.extras.runbookNode.ssm)) {
        return "TerraformPlanNode";
      }

      if (
        StepTypeChecker.isTerraformUpdateVarsNode(node.extras.runbookNode.ssm)
      ) {
        return "TerraformUpdateVarsNode";
      }

      if (StepTypeChecker.isSlackConnectorStep(node.extras.runbookNode.ssm)) {
        return "Slack_Send_Message";
      }

      if (StepTypeChecker.isJiraConnectorStep(node.extras.runbookNode.ssm)) {
        return "Jira_Create_Issue";
      }

      if (
        StepTypeChecker.isJiraExistingIssueStep(node.extras.runbookNode.ssm)
      ) {
        return "Jira_Existing_Issue";
      }

      if (StepTypeChecker.isLoopStep(node.extras.runbookNode.ssm)) {
        return "LoopForEach";
      }

      if (StepTypeChecker.isWaitForResourceStep(node.extras.runbookNode.ssm)) {
        return "WaitForResource";
      }
      if (this.isSleepWaitStep(node.extras.runbookNode.ssm)) {
        return "WaitTimeNode";
      }
      if (this.isCloudwatchAlertStep(node.extras.runbookNode.ssm)) {
        return ControlNames.CloudwatchAlert;
      }
      if (this.isInstanaAlertStep(node.extras.runbookNode.ssm)) {
        return "Instana_Alert";
      }
      if (this.isJSONPathStep(node.extras.runbookNode.ssm)) {
        return ControlNames.JSONPathStep;
      }

      if (this.isWebhookStep(node.extras.runbookNode.ssm)) {
        return "Webhook";
      }

      if (this.isTwilioConnector(node.extras.runbookNode.ssm)) {
        return "Twilio_Send_SMS";
      }

      if (this.isContainerStep(node.extras.runbookNode.ssm)) {
        return "Container";
      }

      if (this.isPagerDutyAlertStep(node.extras.runbookNode.ssm)) {
        return ControlNames.PagerDuty;
      }

      if (this.isOpsgenieAlertStep(node.extras.runbookNode.ssm)) {
        return ControlNames.Opsgenie;
      }

      if (this.isPulumi(node.extras.runbookNode.ssm)) {
        return ControlNames.Pulumi;
      }

      if (this.isSplunkSearchStep(node.extras.runbookNode.ssm)) {
        return ControlNames.SplunkSearch;
      }

      if (this.isSplunkOnCallStep(node.extras.runbookNode.ssm)) {
        return ControlNames.SplunkOnCallAlert;
      }

      if (StepTypeChecker.isBucketPickerNode(node.extras.runbookNode.ssm)) {
        return ControlNames.PickS3Bucket;
      }

      if (StepTypeChecker.isJenkinsBuildStep(node.extras.runbookNode.ssm)) {
        return ControlNames.JenkinsBuild;
      }
      if (
        StepTypeChecker.isCloudFormationRunTemplateStep(
          node.extras.runbookNode.ssm,
        )
      ) {
        return ControlNames.CloudFormationRunTemplate;
      }
      if (node.extras.runbookNode.hasOwnProperty("snippetDef")) {
        switch (node.extras.runbookNode.snippetDef?.type) {
          case "SNIPPET":
            return StepTypes.SnippetAction;
          case "CONTROL":
            return node.extras.runbookNode.snippetDef.content.action ===
              "aws:branch"
              ? "ConditionalAction"
              : "SSMStepAction";
          default:
            return "SSMStepAction";
        }
      } else {
        return node.extras.runbookNode.action === "aws:branch"
          ? "ConditionalAction"
          : "SSMStepAction";
      }
    }
  }

  /**
   * WaitStep is the NeurOps Wait control, implemented with aws:sleep
   */
  isWaitStep(json) {
    return (
      StepTypeChecker.isSleepStep(json) &&
      json.name.startsWith(ControlNames.Wait)
    );
  }

  isNeurOpsStatusStep(json) {
    return (
      StepTypeChecker.isSleepStep(json) &&
      (json.name.startsWith(ControlNames.Success) ||
        json.name.startsWith(ControlNames.Stop) ||
        json.name.startsWith(ControlNames.Fail))
    );
  }

  static isWebhookStep(json) {
    return json.name.startsWith(ControlNames.Webhook);
  }
  static isContainerStep(json) {
    return json.name.startsWith(ControlNames.Container);
  }

  static isTwilioConnector(json) {
    return json.name.startsWith(ControlNames.TwilioSendSMS);
  }

  static isPagerDutyAlertStep(json) {
    return (
      StepTypeChecker.isStep(json) &&
      json.name.startsWith(ControlNames.PagerDuty)
    );
  }

  static isOpsgenieAlertStep(json) {
    return (
      StepTypeChecker.isStep(json) &&
      json.name.startsWith(ControlNames.Opsgenie)
    );
  }

  static isPulumi(json) {
    return (
      StepTypeChecker.isStep(json) && json.name.startsWith(ControlNames.Pulumi)
    );
  }

  static isSplunkSearchStep(json) {
    return json.name.includes(ControlNames.SplunkSearch);
  }

  static isSplunkOnCallStep(json) {
    return json.name.includes(ControlNames.SplunkOnCallAlert);
  }

  static isJenkinsBuildStep(json) {
    return json.name.startsWith(ControlNames.JenkinsBuild);
  }

  static isCloudFormationRunTemplateStep(json) {
    return json.name.startsWith(ControlNames.CloudFormationRunTemplate);
  }
}
