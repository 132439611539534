import React from "react";
import { Link } from "react-router-dom";
import { TextInput, DropDown } from "@components/ui/";
import { DropDownOptionMulti } from "@components/ui/drop-down/drop-down-option";
import { Consumer } from "../snippets-lib/snippets.context";
import { getFilteredSnippets } from "@lib/utils";

export default SnippetsFilters => (
  <Consumer>
    {({
      snippetsList,
      searchTerm,
      searchSnippets,
      filterSnippetsByService,
      resetFilters,
      filteredServicesSet,
    }) => {
      let list = getFilteredSnippets(
        snippetsList,
        searchTerm,
        filteredServicesSet,
      );

      // get list of services in the orginal snippets list for service dropdown filter
      let services = snippetsList.reduce((acc, item) => {
        acc.add(item.service);
        return acc;
      }, new Set());

      // get count of services in the filterd snippets list
      let servicesFiltered = list.reduce((acc, item) => {
        acc.add(item.service);
        return acc;
      }, new Set());

      return (
        <>
          <div className="d-flex d-flex__sb mt-40">
            <TextInput
              placeholder="Search"
              name="snippets-search"
              className="text-input text-input__darker"
              containerClass="snippets-filter-input"
              onKeyUp={searchSnippets}
              value={searchTerm}
            />
            <DropDown
              title={"Service"}
              multi={true}
              onClick={e => {
                // send the value to redux store to save the selected service for future use,
                // since snippets filters are still not yet fully decided on, so more filters to come
                filterSnippetsByService(e);
              }}
            >
              {[...services].map((service, idx) => {
                let checked = filteredServicesSet.has(service);
                return (
                  <DropDownOptionMulti
                    key={idx}
                    id={idx}
                    text={service}
                    checked={checked}
                  />
                );
              })}
            </DropDown>
          </div>
          <div className="snippets-clear-filters">
            {list.length || 0} actions across{" "}
            <b>{servicesFiltered.size || 0} service‍s</b>
            <Link to={`#`} onClick={resetFilters}>
              Clear all filters
            </Link>
          </div>
        </>
      );
    }}
  </Consumer>
);
