import React from "react";
import { Consumer } from "../../executions-lib/executions.context";
import Chart from "./executions-over-time-chart";
import Stats from "./stats";

const TimeSeries = props => {
  return (
    <Consumer>
      {({ versions }) => (
        <div className="margin-top-20 width-full margin-left-16">
          <div>
            <h4>
              Executions over time{" "}
              <span
                className="executions-clear-filters"
                onClick={props.resetFilters}
              >
                Clear all filters
              </span>
            </h4>
          </div>
          <Chart />
          <Stats
            filteredExecutionsBySearchTerm={
              props.filteredExecutionsBySearchTerm
            }
          />
        </div>
      )}
    </Consumer>
  );
};

export default TimeSeries;
