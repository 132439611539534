import React from "react";
import { connect } from "react-redux";
import { Tab, TabCollection, TabContent } from "@components/ui/tabs";
import { Consumer } from "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.context";

import { Output, Details, WaitForResourceInput } from ".";

function WaitForResourcePanel(props) {
  const { activeNode } = props;
  return (
    <Consumer>
      {({ notifyRunbookUpdate }) => (
        <TabCollection
          active="input"
          activeClassName="editor-detail-tab-wrap editor-detail-active-tab"
          inactiveClassName="editor-detail-tab-wrap editor-detail-inactive-tab"
          contentTop={50}
        >
          <Tab title="Input" name="input">
            <TabContent>
              <WaitForResourceInput
                activeNode={activeNode}
                runbookNode={activeNode.extras.runbookNode}
                notifyRunbookUpdate={notifyRunbookUpdate}
              />
            </TabContent>
          </Tab>
          <Tab title="Output" name="output">
            <TabContent>
              <Output />
            </TabContent>
          </Tab>
          <Tab title="Details" name="details">
            <TabContent>
              <Details />
            </TabContent>
          </Tab>
        </TabCollection>
      )}
    </Consumer>
  );
}

const mapState = ({ actionNodeReducer }) => {
  return {
    operations: actionNodeReducer.operations,
  };
};

export default connect(mapState)(WaitForResourcePanel);
