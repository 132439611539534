import React from "react";
import { Consumer } from "../../runbooks-lib/runbooks.context";
import { Form, Select, SelectOption, TextInput } from "@components/ui/";

export default props => (
  <Consumer>
    {({ reSort, searchAndSort, runbookSearchTerm, sortType }) => (
      <Form name="panel-form" onSubmit={searchAndSort}>
        <div className="tab-content__actions-wrap">
          <TextInput
            name="search"
            placeholder="Search by Name or Tags"
            className="text-input text-input__darker w-input"
            value={runbookSearchTerm}
            onReset={() => {
              searchAndSort({ search: "" });
            }}
          />
          <Select
            value={sortType}
            onChange={reSort}
            searchable={false}
            dropdownStyle={"overlay"}
            allowEmpty={false}
            title={"Sort by Name"}
          >
            <SelectOption text="Name (A-Z)" value="Name (A-Z)" />
            <SelectOption text="Name (Z-A)" value="Name (Z-A)" />
          </Select>
        </div>
      </Form>
    )}
  </Consumer>
);
