import React, { useState } from "react";
import "./StringList.scss";
import TextInputView from "../Layouts/TextInputView/TextInputView";
import AddButton from "../Layouts/AddButton/AddButton";
import { ManageKeyValue } from "../Layouts/ManageKeyValue/ManageKeyValue";

type StringListProps = {
  title?: string;
  StringListArr: Array<string>;
  onChange: (value) => void;
};

const StringList: React.FC<StringListProps> = ({
  title = "StringList",
  StringListArr,
  onChange,
}) => {
  const [stringList, setStringList] = useState(
    StringListArr ? StringListArr : [],
  );
  const [isManageKeyValueOpen, setIsManageKeyValueOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedStringIdx, setSelectedStringIdx] = useState(null);

  const toggleIsManageKeyValueOpen = () => {
    setIsManageKeyValueOpen(!isManageKeyValueOpen);
  };

  /**
   * Delete a string from stringList
   * @param {string} number - string value to be deleted
   */
  const onDeleteString = (string: string) => {
    let updatedStringList = stringList.filter(s => s !== string);
    setStringList(updatedStringList);
    onChange(updatedStringList);
  };

  /**
   * Called when "+ Add To StringList" is clicked
   */
  const onAddString = () => {
    setIsEditMode(false);
    setSelectedStringIdx(null);
    toggleIsManageKeyValueOpen();
  };

  /**
   * Called when "Edit string" is clicked
   * @param {index} number - index of string value to be edited
   */
  const onEditString = (idx: Number) => {
    setIsEditMode(true);
    setSelectedStringIdx(idx);
    toggleIsManageKeyValueOpen();
  };

  /**
   * Close the key/value popup
   */
  const onCancel = () => {
    toggleIsManageKeyValueOpen();
  };

  /**
   * Edit/Add a string ("abc")
   * in stringList
   * @param {value} string - edited or newly created string
   * @return {void}
   */
  const onSave = ({ inputValue }) => {
    let updatedStringList = [];
    if (isEditMode) {
      updatedStringList = stringList.map((s, id) => {
        if (id === selectedStringIdx) {
          return inputValue;
        }
        return s;
      });
    } else {
      updatedStringList = [...stringList, inputValue];
    }
    setStringList(updatedStringList);
    toggleIsManageKeyValueOpen();
    onChange(updatedStringList);
  };

  return (
    <>
      <div className={`sl-header ${stringList.length > 0 && "mb-5"}`}>
        <label>{title}</label>
      </div>
      <div className="sl-container">
        {!!stringList &&
          Array.isArray(stringList) &&
          stringList.map((string, idx) => {
            return (
              <TextInputView
                key={idx}
                value={string}
                onEdit={() => onEditString(idx)}
                onDelete={() => onDeleteString(string)}
              />
            );
          })}
      </div>
      {isManageKeyValueOpen && (
        <ManageKeyValue
          showKey={false}
          isEdit={isEditMode}
          selectedInput={{
            inputKey: selectedStringIdx,
            inputValue: stringList[selectedStringIdx],
          }}
          onCancel={onCancel}
          onSave={value => onSave(value)}
          type="StringList"
          allowDuplicateKey={false}
          list={stringList}
        />
      )}
      <AddButton title={"Add To StringList"} handleClick={onAddString} />
    </>
  );
};

export default StringList;
