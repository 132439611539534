import React from "react";
import JSONPretty from "react-json-pretty";
import ReactTooltip from "react-tooltip";
require("react-json-pretty/themes/monikai.css");
require("./json-view.scss");

export default class JsonView extends React.Component {
  constructor(props) {
    super(props);
    this.timer = null;
  }
  state = {
    message: null,
    hasMounted: false,
  };
  componentDidMount() {
    this.setState({
      hasMounted: true,
    });
  }

  componentWillUnmount() {
    this.setState({
      hasMounted: false,
    });
  }

  saveJson = () => {
    const data = this.props.data;
    const el = document.createElement("textarea");
    el.value = JSON.stringify(data);
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    this.setState({
      message: `Copied!`,
    });
    clearTimeout(this.timer);
    if (this.state.hasMounted) {
      this.timer = setTimeout(() => {
        if (this.state.hasMounted) {
          console.log("Mounted");
          this.setState({ message: null });
        }
      }, 3000);
    }
  };

  render() {
    return (
      <div className={this.props.className} style={this.props.style}>
        {this.props.showCopy && (
          <div
            className={`copy-outerwrap ${this.props.jsonIconClass}`}
            onClick={this.saveJson}
          >
            {this.state.message && (
              <div className="message fade-slow">{this.state.message}</div>
            )}

            <ReactTooltip
              id={this.props.id || "json-copy-tootltip"}
              place={
                this.props.tooltipPosition
                  ? this.props.tooltipPosition
                  : "bottom"
              }
              effect="solid"
              type="light"
              className={`json-icon-tooltip-container`}
            />
            <div
              data-for={this.props.id || "json-copy-tootltip"}
              data-tip={`${this.props?.title || "Copy JSON"}`}
              className="icon-copy-wrapper"
            >
              <div className="icon-copy" />
            </div>
          </div>
        )}

        <JSONPretty
          id="json-pretty"
          className={`json-pretty ${this.props.jsonViewClass}`}
          data={this.props.data}
        ></JSONPretty>
      </div>
    );
  }
}
