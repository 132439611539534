import React from "react";
import { connect } from "react-redux";
import { Tab, TabCollection, TabContent } from "@components/ui/tabs";
import { Consumer } from "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.context";
import { Input, Output, Details } from ".";
import { SERVICES_NEEDED } from "@redux/types";
import {
  fetchServices,
  setSelectedService,
  setSelectedOperation,
} from "@redux/actions/action-node.action";

class ActionNodePanel extends React.Component {
  state = {};

  setOutputs = outputs => {
    this.setState(outputs);
  };

  render() {
    return (
      <Consumer>
        {({
          activeNode,
          setActiveActionNode,
          activeActionNode,
          notifyRunbookUpdate,
        }) => (
          <TabCollection
            active="input"
            activeClassName="editor-detail-tab-wrap editor-detail-active-tab"
            inactiveClassName="editor-detail-tab-wrap editor-detail-inactive-tab"
            contentTop={50}
          >
            <Tab title="Input" name="input">
              <TabContent>
                <Input
                  activeNode={activeNode}
                  runbookNode={activeNode.extras.runbookNode}
                  operations={this.props.operations}
                  services={this.props.services}
                  servicesNeeded={this.props.servicesNeeded}
                  setServicesNeeded={this.props.setServicesNeeded}
                  setActiveActionNode={setActiveActionNode}
                  activeActionNode={activeActionNode}
                  fetchServices={this.props.fetchServices}
                  fetchOperations={this.props.fetchOperations}
                  isFetchingServices={this.props.isFetchingServices}
                  isFetchingOperations={this.props.isFetchingOperations}
                  setSelectedService={this.props.setSelectedService}
                  setSelectedOperation={this.props.setSelectedOperation}
                  selectedOperation={this.props.selectedOperation}
                  selectedService={this.props.selectedService}
                  isFetchingOperationDetails={
                    this.props.isFetchingOperationDetails
                  }
                  operationDetails={this.props.operationDetails}
                  notifyRunbookUpdate={notifyRunbookUpdate}
                  setOutputs={this.setOutputs}
                />
              </TabContent>
            </Tab>
            <Tab title="Output" name="output">
              <TabContent>
                <Output outputs={this.state.outputs} />
              </TabContent>
            </Tab>
            <Tab title="Details" name="details">
              <TabContent>
                <Details />
              </TabContent>
            </Tab>
          </TabCollection>
        )}
      </Consumer>
    );
  }
}

const mapState = ({ actionNodeReducer }) => {
  return {
    operations: actionNodeReducer.operations,
    services: actionNodeReducer.services,
    servicesNeeded: actionNodeReducer.servicesNeeded,
    isFetchingOperations: actionNodeReducer.isFetchingOperations,
    isFetchingServices: actionNodeReducer.isFetchingServices,
    selectedService: actionNodeReducer.selectedService,
    selectedOperation: actionNodeReducer.selectedOperation,
    operationDetails: actionNodeReducer.operationDetails,
    isFetchingOperationDetails: actionNodeReducer.isFetchingOperationDetails,
  };
};

const mapDispatch = dispatch => ({
  setServicesNeeded: servicesNeeded =>
    dispatch({
      type: SERVICES_NEEDED,
      payload: servicesNeeded,
    }),
  fetchServices: () => dispatch(fetchServices()),
  setSelectedService: selectedService =>
    dispatch(setSelectedService(selectedService)),
  setSelectedOperation: selectedOperation =>
    dispatch(setSelectedOperation(selectedOperation)),
});

export default connect(mapState, mapDispatch)(ActionNodePanel);
