import React from "react";
import { Button } from "@components/ui";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  isFetching,
  fetchConnectorConfigDetails,
} from "@redux/actions/settingsPanel.action";
import { Formik } from "formik";
import * as Yup from "yup";
import FormInput from "@containers/SettingsPanel/settings-panel-components/SettingsRightConfigPanel/FormInput";
import { isEmpty } from "@lib/utils";
import Api from "@lib/api/api";
import { Wait } from "@components/ui";
import { Redirect } from "react-router";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { RouteConstants } from "../../../../../routes/Constants";

const validationSchema = Yup.object({
  consumerKey: Yup.string()
    .required("Consumer Key is required")
    .trim("Consumer Key is required"),
  consumerName: Yup.string()
    .required("Consumer Name is required")
    .trim("Consumer Name is required"),
  publicKey: Yup.string()
    .required("Public Key is required")
    .trim("Public Key is required"),
  accountUrl: Yup.string()
    .required("JIRA URL is required")
    .trim("JIRA URL is required"),
});

class RightPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: false,
      nodeConfig: {},
      doRedirect: false,
      redirectUrl: "",
    };
    this.getJiraConfig();
  }

  getJiraConfig = async () => {
    this.props.isFetching(true, "Loading connector details");
    let action = await this.props.fetchConnectorConfigDetails("jira");
    if (action.type === "CONNECTOR_CONFIGURATION_INFO_FETCH_SUCCESS") {
      this.setState(
        {
          nodeConfig: JSON.parse(this.props.connectorConfig),
          showForm: true,
        },
        () => {
          this.props.isFetching(false, "");
        },
      );
    } else {
      this.props.isFetching(false, "");
    }
  };

  goBack = () => {
    this.setState({
      doRedirect: true,
      redirectUrl: RouteConstants.settings.url,
    });
  };

  onSubmitHandle = async (accountDetails, actions) => {
    this.props.isFetching(true, "Integration in progress");
    const response = await Api.getJiraOauth(
      "jira",
      accountDetails.accountUrl,
    ).catch(e => console.log(e));
    const oAuthDetails = !isEmpty(response) ? JSON.parse(response.body) : {};
    if (!isEmpty(oAuthDetails)) {
      window.open(oAuthDetails["JIRA_URL"]);
      const url =
        accountDetails.accountUrl[accountDetails.accountUrl.length - 1] === "/"
          ? accountDetails.accountUrl
          : accountDetails.accountUrl + "/";
      const permissionResponse = await Api.requestJiraPermission(
        "jira",
        url,
        oAuthDetails["REQUEST_TOKEN"],
        oAuthDetails["REQUEST_TOKEN_SECRET"],
      ).catch(e => console.log(e));

      if (!isEmpty(permissionResponse)) {
        const resBody = JSON.parse(permissionResponse.body);
        actions.setStatus({ formSubmitMessage: resBody.message });
        setTimeout(() => {
          this.goBack();
        }, 1500);
      } else {
        actions.setStatus({
          formSubmitMessage: "Oops! Something went wrong !!!",
        });
      }
    } else {
      actions.setStatus({
        formSubmitMessage: "Oops! Something went wrong !!!",
      });
    }
    this.props.isFetching(false, "");
  };

  formatPublicKey = value => {
    if (Array.isArray(value)) {
      return value.join();
    }
    if (typeof value === "string") {
      const val = !!value && JSON.parse(value);
      if (!!val && Array.isArray(JSON.parse(value))) {
        return val.join();
      }
    }
  };

  render() {
    return (
      <>
        {this.state.doRedirect && <Redirect to={this.state.redirectUrl} push />}
        {this.props.message && <Wait text={this.props.message} />}

        <div className="instructions__right-jira">
          <div className="onboarding-form">
            <div className="onboarding-header-wrap">
              <h2>Authorize Fylamynt</h2>
            </div>
            {this.state.showForm && (
              <div className="onboarding-card">
                <h4>
                  <b>Step 1: Link Fylamynt Application in Jira</b>
                </h4>

                <Formik
                  initialValues={{
                    consumerKey: this.state.nodeConfig["JIRA_CONSUMER_KEY"],
                    consumerName: this.state.nodeConfig["JIRA_CONSUMER_NAME"],
                    publicKey: this.formatPublicKey(
                      this.state.nodeConfig["JIRA_PUBLIC_KEY"],
                    ),
                    accountUrl: "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={this.onSubmitHandle}
                >
                  {formik => (
                    <>
                      <div className="account-info__wrap">
                        <CopyToClipboard
                          text={`${formik.values.consumerKey}`}
                          onCopy={() => console.log("Consumer Key copied")}
                        >
                          <div className="copy-icon jira-copy-icon"></div>
                        </CopyToClipboard>

                        <FormInput
                          fieldName="Consumer Key:"
                          name="consumerKey"
                          id="consumerKey"
                          handleChange={formik.handleChange}
                          touched={formik.touched}
                          errors={formik.errors}
                          className="copy-text"
                          readOnly
                        />
                      </div>
                      <div className="account-info__wrap">
                        <CopyToClipboard
                          text={`${formik.values.consumerName}`}
                          onCopy={() => console.log("Consumer Name copied")}
                        >
                          <div className="copy-icon jira-copy-icon"></div>
                        </CopyToClipboard>
                        <FormInput
                          fieldName="Consumer Name:"
                          name="consumerName"
                          id="consumerName"
                          handleChange={formik.handleChange}
                          touched={formik.touched}
                          errors={formik.errors}
                          className="copy-text"
                          readOnly
                        />
                      </div>

                      <div className="account-info__wrap">
                        <div className="form-item form-item__margin-bottom">
                          <label
                            htmlFor="publicKey"
                            className="label label__padding-left"
                          >
                            Public Key:
                          </label>
                          <CopyToClipboard
                            text={`${formik.values.publicKey}`}
                            onCopy={() => console.log("Public Key copied")}
                          >
                            <div className="copy-icon jira-copy-icon"></div>
                          </CopyToClipboard>
                          <textarea
                            className={
                              formik.errors.publicKey &&
                              formik.touched.publicKey
                                ? "copy-text text-input w-input input-error"
                                : "copy-text text-input w-input"
                            }
                            id="publicKey"
                            name="publicKey"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.publicKey}
                            col={50}
                            row={4}
                            style={{
                              width: "92%",
                              height: "6.5rem",
                              paddingTop: "0.5rem",
                            }}
                            readOnly
                          />
                          {formik.errors.publicKey ? (
                            <div className="input-feedback">
                              {formik.errors.publicKey}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <h4>
                        <b>Step 2: Configure Jira OAuth1</b>
                      </h4>
                      <div className="account-info__wrap">
                        <FormInput
                          fieldName="URL of JIRA Account:"
                          name="accountUrl"
                          id="accountUrl"
                          handleChange={formik.handleChange}
                          touched={formik.touched}
                          errors={formik.errors}
                          autoComplete="off"
                        />
                      </div>
                      <div className="form-footer">
                        <Button
                          text="Cancel"
                          buttonStyle="secondary"
                          style={{ width: "48%", marginRight: "1rem" }}
                          size="large"
                          type="button"
                          onClick={this.goBack}
                        />
                        <Button
                          text="Setup OAuth1"
                          style={{ width: "48%" }}
                          buttonStyle="primary"
                          size="large"
                          onClick={formik.handleSubmit}
                        />
                      </div>

                      <div style={{ marginTop: "1rem" }}>
                        {!!formik.status && formik.status.formSubmitMessage}
                      </div>
                    </>
                  )}
                </Formik>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  message: state.runbooksReducer.message,
  connectorConfig: state.settingsPanelReducer.currentNodeConfigInfo,
});
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      isFetching,
      fetchConnectorConfigDetails,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(RightPanel);
