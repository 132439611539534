import { SSMActionNames } from "./strings";
import { Step } from "./steps";

export class PauseStep extends Step {
  static ACTION = SSMActionNames.PAUSE;

  static fromControlNodeDefinition(snippetDef) {
    const name = `${snippetDef.name}_${Math.floor(1000 * Math.random())}`;
    const ssm = { ...snippetDef.content, name };

    return new PauseStep(ssm, snippetDef);
  }

  toSSM() {
    const result = {
      ...this.ssm,
      nextStep: this.nextStep,
      isEnd: !this.nextStep,
    };
    result.inputs = {};

    return result;
  }

  constructor(stepJSON, snippetDef) {
    super(stepJSON);
    this.snippetDef = snippetDef;
    this.statusName = snippetDef?.name;
  }
}
