export class SnippetType {
  static CONTROL = "CONTROL";
  static SNIPPET = "SNIPPET";
  static ACTION = "ACTION";
  static CONNECTOR = "CONNECTOR";
  static TRIGGER = "TRIGGER";
}

export class ControlNames {
  static Conditional = "Conditional";
  static Wait = "Wait";
  static Success = "Success";
  static Fail = "Fail";
  static Stop = "Stop";
  static Pause = "Pause";
  static LoopForEach = "Loop_For_Each";
  static CheckELBResult = "Check_ELB_Result";
  static CheckExeStatus = "Check_Exe_Status";
  static DatadogAlert = "Datadog_Alert";
  static SlackConnector = "Slack_Send_Message";
  static JiraConnector = "Jira_Create_Issue";
  static JiraUpdateConnector = "Jira_Update_Issue";
  static CloudwatchAlert = "CloudWatch_Alert";
  static InstanaAlert = "Instana_Alert";
  static JSONPathStep = "JSONPath";
  static Webhook = "Webhook";
  static Container = "Container";
  static TwilioSendSMS = "Twilio_Send_SMS";
  static PagerDuty = "PagerDuty_Alert";
  static SplunkSearch = "Splunk_Search";
  static SplunkOnCallAlert = "Splunk_On_Call_Alert";
  static JenkinsBuild = "Jenkins_Build";
  static Opsgenie = "Opsgenie_Alert";
  static Pulumi = "Pulumi";
  static PickS3Bucket = "Pick_S3_Bucket";
  static CloudFormationRunTemplate = "CloudFormation_Run_Template";
}

export class StepTypes {
  static ActionNodeStep = "ActionNodeStep";
  static ConditionalStep = "ConditionalStep";
  static SnippetStep = "SnippetStep";
  static TriggerStep = "TriggerStep";
  static SnippetAction = "SnippetAction";
  static WaitStep = "WaitStep";
  static LoopStep = "LoopStep";
  static WaitForResourceStep = "WaitForResourceStep";

  static DatadogConnectorStep = "DatadogConnectorStep";
  static SlackConnectorStep = "SlackConnectorStep";
  static JiraConnectorStep = "JiraConnectorStep";
  static CloudwatchAlertConnectorStep = "CloudwatchAlertConnectorStep";
  static InstanaAlertConnectorStep = "InstanaAlertConnectorStep";
  static JSONPathStep = "JSONPathStep";
  static TwilioConnectorStep = "TwilioConnectorStep";
  static WebhookTriggerStep = "WebhookTriggerStep";
  static PagerDutyTriggerStep = "PagerDutyTriggerStep";
  static OpsgenieTriggerStep = "OpsgenieTriggerStep";
  static SplunkSearchConnectorStep = "SplunkSearchConnectorStep";
  static JenkinsBuildStep = "JenkinsBuildStep";
}
