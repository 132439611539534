import React from "react";
import { initBorder } from "./select.lib";

/* "this" is the <SelectSelected ... /> component */
export function initState() {
  this.state = {
    value: this.props.selectedText,
    text: this.props.selectedText,
    showClear: false,
    searchSuccess: false,
    title: this.props.title,
  };
  this.inputRef = React.createRef();
  this.selectRef = React.createRef();
  this.minSize = 10;
}

export function didMount() {
  if (this.props.parent && this.props.parent !== null) {
    initBorder.call(this);
  }
}

export function didUpdate(prevProps) {
  if (prevProps.selectedText !== this.props.selectedText) {
    this.setTitleText();
    this.setValue();
  }
  if (prevProps.expanded !== this.props.expanded) {
    this.setState({
      showClear: this.props.expanded,
    });
  }
  if (this.props.searchSuccess) {
    this.setValue();
  }
  if (prevProps.value !== this.props.value) {
    this.setValue(this.props.value);
  }
}
