import React, { useState } from "react";
import { Button } from "@components/ui";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  saveConnecterSchema,
  isFetching,
} from "@redux/actions/settingsPanel.action";
import { Formik } from "formik";
import * as Yup from "yup";
import FormInput from "../../../settings-panel-components/SettingsRightConfigPanel/FormInput";
import { Wait } from "@components/ui";
import { Redirect } from "react-router";
import Api from "@lib/api/api";
import { FTNotification } from "@components/ui";
import { RouteConstants } from "../../../../../routes/Constants";

const ERROR_MSG = {
  accountSid: "AccountSid is required",
  authToken: "AuthToken is required",
};

const validationSchema = Yup.object({
  accountSid: Yup.string()
    .required(ERROR_MSG.accountSid)
    .trim(ERROR_MSG.accountSid),
  authToken: Yup.string()
    .required(ERROR_MSG.authToken)
    .trim(ERROR_MSG.authToken),
});

type PROP_MODEL = {
  message: string;
  formSubmittedSuccess: boolean;
  saveConnecterSchema: any;
  isFetching: any;
};

const RightPanel = (props: PROP_MODEL) => {
  /**
   * Application local state
   */
  const [redirect, setRedirect] = useState({
    doRedirect: false,
    redirectUrl: "",
  });

  /**
   * Sets state to navigate to settings page
   */
  const goBack = () => {
    setRedirect({ doRedirect: true, redirectUrl: RouteConstants.settings.url });
  };

  /**
   * Prepares payload
   * @param formData
   */
  const getPayload = (formData: any) => {
    return { credentials: formData };
  };

  /**
   * This function is used to validate creadentials
   * @param accountSid
   * @param authToken
   * @param actions
   */
  const validateToken = async (
    accountSid: string,
    authToken: string,
    actions: any,
  ) => {
    const response = await Api.validateTwilioToken({ authToken, accountSid });
    if (response?.ERROR?.response.status === 401) {
      const error = "Account SID or Auth Token is invalid";
      FTNotification.error({
        title: "Validation Failed",
        message: error,
        timeout: 10,
      });

      actions.setSubmitting(false);
      props.isFetching(false, "");
      actions.setStatus({
        isError: true,
        formSubmitMessage: error,
      });
      return false;
    }

    return true;
  };

  /**
   * This is separate function to avoid code duplication
   * @param actions Formik actions
   */
  const setErrorStatus = (actions: any) => {
    actions.setStatus({
      isError: true,
      formSubmitMessage: "Oops! Something went wrong !!!",
    });
  };

  /**
   * This function handles formik submission
   * @param formInput Formik input data
   * @param actions formik actions object
   */
  const onSubmitHandle = async (formInput: any, actions: any) => {
    props.isFetching(true, "Integration in progress");
    actions.setSubmitting(true);
    const payload = getPayload(formInput);

    // Validate credentials before saving it
    const validationResponse = await validateToken(
      formInput.accountSid,
      formInput.authToken,
      actions,
    );

    if (!validationResponse) {
      // Abort, because validation failed
      return;
    }

    try {
      const response = await Api.saveConnectorSchema(payload, "twilio");
      if (response?.statusCode === 200) {
        actions.setStatus({
          formSubmitMessage: "Connector details saved successfully!!!",
        });

        // Navigate to settings page
        setTimeout(() => {
          goBack();
        }, 1500);
      } else {
        setErrorStatus(actions);
      }
    } catch (error) {
      setErrorStatus(actions);
    }
    actions.setSubmitting(false);
    props.isFetching(false, "");
  };

  return (
    <>
      {redirect.doRedirect && <Redirect to={redirect.redirectUrl} push />}
      {props.message && <Wait text={props.message} />}
      <div className="instructions__right-instana">
        <div className="onboarding-form">
          <div className="onboarding-header-wrap">
            <h2>Authorize Fylamynt</h2>
          </div>
          <div className="onboarding-card">
            {
              <Formik
                initialValues={{
                  accountSid: "",
                  authToken: "",
                }}
                validationSchema={validationSchema}
                onSubmit={onSubmitHandle}
              >
                {formik => (
                  <>
                    <div className="account-info__wrap">
                      <FormInput
                        fieldName="Account Sid"
                        name="accountSid"
                        id="accountSid"
                        handleChange={formik.handleChange}
                        touched={formik.touched}
                        errors={formik.errors}
                      />
                    </div>
                    <div className="account-info__wrap">
                      <FormInput
                        fieldName="Auth Token"
                        name="authToken"
                        id="authToken"
                        handleChange={formik.handleChange}
                        touched={formik.touched}
                        errors={formik.errors}
                        autoComplete="new-password"
                        isMasked={true}
                      />
                    </div>

                    <div className="form-footer">
                      <Button
                        text="Cancel"
                        buttonStyle="secondary"
                        style={{ width: "48%", marginRight: "1rem" }}
                        size="large"
                        type="button"
                        onClick={goBack}
                      />
                      <Button
                        text="Authorize"
                        style={{ width: "48%" }}
                        buttonStyle="primary"
                        size="large"
                        onClick={formik.handleSubmit}
                      />
                    </div>

                    <div
                      className={`${
                        formik.status?.isError ? "input-feedback" : ""
                      } pt-15`}
                    >
                      {!!formik.status && formik.status.formSubmitMessage}
                    </div>
                  </>
                )}
              </Formik>
            }
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  message: state.runbooksReducer.message,
  formSubmittedSuccess: state.settingsPanelReducer.formSubmittedSuccess,
});
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      saveConnecterSchema,
      isFetching,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(RightPanel);
