import produce from "immer";
import {
  EXECUTION_UPDATE_SEARCH_TERM,
  EXECUTION_UPDATE_STATUS_FILTER,
  EXECUTION_UPDATE_TIME_FILTER,
} from "@redux/types";
import { defaultExecutionTimeFilter } from "@lib/utils/constants";

const INITIAL_STATE = {
  executionSearchTerm: "",
  executionStatusFilter: [],
  executionTimeFilter: defaultExecutionTimeFilter,
};

export default (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    switch (action.type) {
      case EXECUTION_UPDATE_SEARCH_TERM:
        draft.executionSearchTerm = action.payload;
        break;

      case EXECUTION_UPDATE_STATUS_FILTER:
        draft.executionStatusFilter = action.payload;
        break;

      case EXECUTION_UPDATE_TIME_FILTER:
        draft.executionTimeFilter = action.payload;
        break;

      default:
        break;
    }
  });
