import React from "react";
import "./scrolltabs.scss";

const ScrollTab = props => {
  return (
    <div
      className={`tab-tabheader ${
        props.active === props.name && "tab-selected"
      }`}
      onClick={() => props.tabClickHandler(props.name, props.scrollToId)}
    >
      {props.title}
    </div>
  );
};

export default ScrollTab;
