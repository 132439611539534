import React, { useContext, useState } from "react";
import { Tab, TabCollection, TabContent } from "@components/ui/tabs";
import Output from "../../output";
import Details from "../../details";
import RequiredInputs from "./Helpers/RequiredInputs";
import InputsOptional from "../../snippet-panel/inputs-optional";
import { RunbookEditorContext } from "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.context";
import { getInputOptions } from "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.helpers";

type ContainerProps = {};

const Container: React.FC<ContainerProps> = () => {
  const context = useContext(RunbookEditorContext) as any;
  const { activeNode, updateActiveNode, runbookObj } = context;
  const optionalInputs = getInputOptions(activeNode).optional;
  const [optionFilterId, setOptionFilterId] = useState(null);

  const toggleInputOption = (optionFilterId: any) => {
    if (typeof optionFilterId === "object") {
      const obj = optionFilterId.target;
      if (obj.type === "text") {
        return;
      }
      setTimeout(() => {
        setOptionFilterId("none");
      }, 100);
    } else {
      setOptionFilterId(optionFilterId);
    }
  };

  return (
    <TabCollection
      active="input"
      activeClassName="editor-detail-tab-wrap editor-detail-active-tab"
      inactiveClassName="editor-detail-tab-wrap editor-detail-inactive-tab"
      contentTop={50}
    >
      <Tab title="Input" name="input">
        <TabContent>
          <h4 className="editor-node-name">Container</h4>
          <RequiredInputs />
          <InputsOptional
            activeNode={activeNode}
            updateActiveNode={updateActiveNode}
            optionalInputs={optionalInputs}
            optionFilterId={optionFilterId}
            toggleInputOption={toggleInputOption}
            runbookObj={runbookObj}
          />
        </TabContent>
      </Tab>
      <Tab title="Output" name="output">
        <TabContent>
          <Output />
        </TabContent>
      </Tab>
      <Tab title="Details" name="details">
        <TabContent>
          <Details />
        </TabContent>
      </Tab>
    </TabCollection>
  );
};

export default Container;
