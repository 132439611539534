import React, { useState } from "react";
import "./scrolltabs.scss";

const ScrollTabCollection = props => {
  let [active, setActiveTab] = useState(props.active);

  const scrollToContent = id => {
    const sectionEl = document.getElementById(id);
    if (!!sectionEl) {
      sectionEl.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  };

  const tabClickHandler = (name, id) => {
    setActiveTab(name);
    scrollToContent(id);
  };

  const childrenWithProps = React.Children.map(props.children, child => {
    const props = { active, tabClickHandler };
    if (React.isValidElement(child)) {
      return React.cloneElement(child, props);
    }
    return child;
  });

  return <div className="tab-container">{childrenWithProps}</div>;
};

export default ScrollTabCollection;
