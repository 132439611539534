import React from "react";
import { Formik } from "formik";
import "./EditTerraformCLI.scss";
import FormInput from "../../../settings-panel-components/SettingsRightConfigPanel/FormInput";
import { getValidationSchema } from "./utils/ValidationSchema";
import {
  Modal,
  Button,
  Wait,
  FTNotification,
  LabelTop,
  Select,
  SelectOption,
} from "@components/ui";
import IconFilter from "@assets/images/icons/icon-filter.svg";
import { useDispatch, useSelector } from "react-redux";
import { isFetching } from "@redux/actions/settingsPanel.action";
import { convertToPayload } from "@containers/SettingsPanel/ConnectorSetup/TerraformCLI/EditTerraformCLI/utils/helper";

import {
  createTerraformCLIModule,
  editTerraformCLIModule,
} from "@redux/actions/settingsPanel.action";

type TerraformCLIModalProps = {
  selectedModule?: any;
  closeForm: (flag: boolean) => void;
  mode: string;
  moduleList: Array<object>;
};

const TerraformCLIModal: React.FC<TerraformCLIModalProps> = ({
  selectedModule,
  mode,
  closeForm,
  moduleList,
  ...props
}) => {
  const dispatch = useDispatch();
  const loadingMessage = useSelector(state => state.runbooksReducer.message);
  const initialValues = selectedModule;
  const validationSchema = getValidationSchema(mode, moduleList);
  const gitRepoList = useSelector(state => state.GITReposReducer.gitRepoList);

  const getFooter = handleSubmit => {
    return (
      <div className="modal-buttons-footer">
        <Button
          text="Cancel"
          buttonStyle="secondary"
          style={{ width: "50%" }}
          size="large"
          type="button"
          onClick={() => closeForm(false)}
        />
        <Button
          text={
            mode === "create"
              ? "Save Terraform CLI Module"
              : "Update Terraform CLI Module"
          }
          style={{ width: "50%" }}
          buttonStyle="primary"
          size="large"
          onClick={handleSubmit}
        />
      </div>
    );
  };

  const onSubmitHandler = async (formik, actions) => {
    actions.setSubmitting(true);
    dispatch(isFetching(true, "Saving Terraform CLI Module"));
    const payload = convertToPayload(formik);
    try {
      if (mode === "create") {
        await dispatch(createTerraformCLIModule(formik.module_id, payload));
        FTNotification.success({
          title: "Terraform CLI Module Saved Successfully!",
        });
      } else {
        await dispatch(editTerraformCLIModule(formik.module_id, payload));
        FTNotification.success({
          title: "Terraform CLI Module Updated Successfully!",
        });
      }
    } catch (error) {
      if (mode === "create") {
        FTNotification.error({
          title: "Could not save Terraform CLI Module",
          message: error.message,
        });
      } else {
        FTNotification.error({
          title: "Could not update Terraform CLI Module",
          message: error.message,
        });
      }
    }
    dispatch(isFetching(false, ""));
    actions.setSubmitting(false);
    closeForm(false);
  };

  const selectRepoId = (repo_id, setFieldValue) => {
    setFieldValue("repo_id", repo_id);
  };

  return (
    <>
      {loadingMessage && <Wait text={loadingMessage} />}
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmitHandler}
        validationSchema={validationSchema}
      >
        {formik => {
          return (
            <Modal
              onClose={() => {
                closeForm(false);
              }}
              title={
                mode === "create"
                  ? "Add Terraform CLI Module"
                  : "Update Terraform CLI Module"
              }
              footer={getFooter(formik.handleSubmit)}
              showClose={true}
            >
              <FormInput
                fieldName="Module Id"
                subText="(Name for the Terraform module)"
                name="module_id"
                id="module_id"
                handleChange={formik.handleChange}
                touched={formik.touched}
                errors={formik.errors}
                readOnly={mode === "create" ? false : true}
                autoComplete="off"
              />

              <div className="account-info__wrap">
                <LabelTop
                  label={`Repo Id:`}
                  labelClassName="label label__padding-left no-margin"
                >
                  <LabelTop
                    label={`(Repository ID to pull terraform config from)`}
                    labelClassName="label label__padding-left"
                  ></LabelTop>
                  <Select
                    value={formik.values.repo_id}
                    width="100%"
                    icon={IconFilter}
                    onChange={repo_id =>
                      selectRepoId(repo_id, formik.setFieldValue)
                    }
                    allowEmpty={false}
                    searchMin={2}
                    required={true}
                    title="Select from below"
                    titleClassname={
                      !formik.values.repo_id ? `formik-field-error` : `no-error`
                    }
                    optionHeight="35"
                  >
                    {(gitRepoList || []).map((repo, i) => (
                      <SelectOption
                        value={repo.name}
                        text={repo.name}
                        key={repo.name}
                        className="text-small"
                        animationBorder={"hover"}
                      />
                    ))}
                  </Select>
                </LabelTop>
                {formik.errors["repo_id"] && formik.touched["repo_id"] && (
                  <div className="input-feedback">
                    {formik.errors["repo_id"]}
                  </div>
                )}
              </div>

              <FormInput
                fieldName="Directory"
                subText="(Directory in the repository to use for the root module to execute)"
                name="directory"
                id="directory"
                handleChange={formik.handleChange}
                touched={formik.touched}
                errors={formik.errors}
                autoComplete="off"
              />

              <FormInput
                fieldName="Ref"
                subText="(Branch, tag, or commit to checkout)"
                name="ref"
                id="ref"
                handleChange={formik.handleChange}
                touched={formik.touched}
                errors={formik.errors}
                autoComplete="off"
              />

              <div style={{ marginTop: "1rem" }}>
                {!!formik.status && formik.status.formSubmitMessage}
              </div>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};

export default TerraformCLIModal;
