import React, {
  useEffect,
  useState,
  useContext,
} from "react";
import { Accordion, AccordionLabel, AccordionSection } from "@components/ui";
import { RunbookEditorContext } from "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.context";
import { RunbookStepInputSource } from "@containers/RunbookEditor/runbook-editor-lib/ssm/nodeinputoutput";
import { isEmpty } from "@lib/utils";
import ReactSelect from "@components/ui/React-Select/ReactSelect";
import Api from "@lib/api/api";
import JSONEditor from "@components/ui/InputTypes/JSONEditor/JSONEditor";

type RequiredInputsProps = {};

const RequiredInputs: React.FC<RequiredInputsProps> = () => {
  const context = useContext(RunbookEditorContext) as any;
  const runbookNode = context.activeNode.extras.runbookNode;
  const notifyRunbookUpdate = context.notifyRunbookUpdate;
  let JobNameInputSource = runbookNode.parameterInputs.find(
    p => p.name === "job_name",
  );
  let paramsInputSource = runbookNode.parameterInputs.find(
    p => p.name === "parameters",
  );

  const [isFetchingJobs, setIsFetchingJob] = useState(false);
  const [jobList, setJobsList] = useState([]);
  const [selectedJob, setSelectedJob] = useState(
    JobNameInputSource?.source?.type === "constant"
      ? JobNameInputSource?.source?.sourceValue
      : "",
  );
  const [params, setParams] = useState(
    paramsInputSource?.source?.type === "constant"
      ? typeof paramsInputSource.source.sourceValue === "string"
        ? JSON.parse(paramsInputSource.source.sourceValue)
        : paramsInputSource.source.sourceValue
      : "",
  );

  useEffect(() => {
    const getJobsList = async () => {
      const result = await Api.fetchJenkinsJobList();
      setJobsList(result);
    };
    setIsFetchingJob(true);
    getJobsList()
    setIsFetchingJob(false);
  }, []);

  const setJobPayload = value => {
    JobNameInputSource.source = new RunbookStepInputSource(`constant`, value);
    notifyRunbookUpdate(true);
  };

  const setParamsPayload = value => {
    paramsInputSource.source = new RunbookStepInputSource(
      `constant`,
      JSON.stringify(value),
    );
    notifyRunbookUpdate(true);
  };

  return (
    <Accordion isExpanded={true} useArrow={true}>
      <AccordionLabel className="editor-accordion-label margin-top-10">
        Required Inputs
      </AccordionLabel>
      <AccordionSection>
        <div className="accordion-section accordion-contracted">
          <div className="editor-detail-panel editor-detail-panel-column">
            {isFetchingJobs ? (
              <label className="s3-input-label loading-text">
                <i>Fetching Jenkins Jobs...</i>
              </label>
            ) : (
              <>
                <label className="label">Select a job</label>
                <ReactSelect
                  id="job"
                  name="job"
                  value={{
                    value: selectedJob,
                    label: selectedJob || `Select from below`,
                  }}
                  handleChange={data => {
                    if (!isEmpty(data) && !!data.value) {
                      setSelectedJob(data.value);
                      setJobPayload(data.value);
                    }
                  }}
                  selectOptions={jobList.map(r => {
                    return {
                      value: r,
                      label: r,
                    };
                  })}
                  required
                />
              </>
            )}
          </div>
          <div className="editor-detail-panel editor-detail-panel-column">
            <label className="label">Add job parameters</label>
            <JSONEditor
              value={params ? JSON.stringify(params, undefined, 4) : ``}
              onChange={value => {
                setParams(value);
                setParamsPayload(value);
              }}
              title={`Parameters`}
            />
          </div>
        </div>
      </AccordionSection>
    </Accordion>
  );
};

export default RequiredInputs;
