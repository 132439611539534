import React from "react";
import PropTypes from "prop-types";
import { Accordion, AccordionLabel, AccordionSection } from "@components/ui";
import SnippetControl from "./SnippetControl";
import { getFilteredSnippetList, addBucketName } from "@lib/utils";

export default class Snippet extends React.Component {
  render() {
    const { snippetName, snippets } = this.props;
    const snips = snippets.SNIPPET[snippetName];

    /**
     * Disable Pick_S3_Bucket if blacklistConnectorList
     * includes s3
     */
    let snippetList = snips.map(snippet => {
      if (
        snippet.name === "Pick_S3_Bucket" &&
        this.props.blacklistConnectorList.includes("s3")
      ) {
        let snip = { ...snippet };
        snip["isDisconnected"] = true;
        return snip;
      }
      return snippet;
    });

    const finalSnippetList = getFilteredSnippetList(
      addBucketName(snippetList, `${snippetName} Actions`),
      this.props.searchVal,
    );
    return (
      finalSnippetList.length > 0 && (
        <Accordion isExpanded={true} useArrow={true} key={snippetName}>
          <AccordionLabel className="editor-accordion-label">
            {snippetName.toUpperCase()} ACTIONS
          </AccordionLabel>
          <AccordionSection>
            <div className="snippet-block">
              {finalSnippetList.map(snippet => (
                <SnippetControl snippet={snippet} key={snippet.id} />
              ))}
            </div>
          </AccordionSection>
        </Accordion>
      )
    );
  }
}

Snippet.propTypes = {
  snippetName: PropTypes.any.isRequired,
  snippets: PropTypes.any.isRequired,
  searchVal: PropTypes.string,
};
