import { setContainerBorder } from "./select.lib";

/* onClick option event */
export function onClick(value, text) {
  /* we never want to show 'undefined' as a display or value, better to make it blank */
  if (typeof value === "undefined") {
    value = "";
  }
  if (typeof text === "undefined") {
    text = "";
  }

  /* set the state value, text */
  this.setValue(value, text);

  /* here we need to actually set the text that is displayed in the SelectSelected component */
  this.setDisplay(text);

  /* send the value and text back to whatever onChange prop has been initialized */
  this.props.onChange(value, text);

  /* resset the styling changes applied to search text input */
  this.removeFoundFormatting();

  /* we need to contract the select list after an option has been selected */
  this.toggle();
}

export function close() {
  this.setState(
    {
      display: "contracted",
      toggled: false,
    },
    () => {
      setContainerBorder.call(this);
      this.removeFoundFormatting();
    },
  );
}

export function setDisplay(selectedText) {
  new Promise(resolve => {
    this.setState({
      selectedText,
    });
    resolve();
  });
}

export function resetSearchSuccess() {
  this.setState({ searchSuccess: false });
}

export async function toggleAndCleanup(e) {
  if (e) {
    e.stopPropagation();
  }
  /* await? because we want to set the toggled state before moving on */
  await setToggle.call(this);
  await setExpandedOrContracted.call(this);
  /* we want to show a border when this component is active (or hide when inactive) */
  setContainerBorder.call(this);
}

async function setToggle() {
  return new Promise(resolve => {
    this.setState(
      state => ({
        toggled: !state.toggled,
      }),
      () => resolve(),
    );
  });
}

/* this effects a css styling that allows the expand or contract to work on the dropdown portion of the select */
function setExpandedOrContracted() {
  return new Promise(async resolve => {
    addRemoveScroll.bind(this);
    await this.setState(state => ({
      display: state.toggled ? "expanded" : "contracted",
    }));
    await addRemoveScroll.call(this, 500);
    resolve();
  });
}

function addRemoveScroll(timeToWait) {
  const scrollableElement = this.ref.current.querySelector(".select-menu");
  const addClass = this.state.toggled ? "expanded-overflow" : "";
  const removeClass = this.state.toggled ? "" : "expanded-overflow";
  return new Promise(resolve => {
    window.setTimeout(() => {
      if (addClass) {
        scrollableElement.classList.add(addClass);
      } else {
        scrollableElement.classList.remove(removeClass);
      }
    }, timeToWait);
    resolve();
  });
}
