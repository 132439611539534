import {
  EXECUTION_UPDATE_SEARCH_TERM,
  EXECUTION_UPDATE_STATUS_FILTER,
  EXECUTION_UPDATE_TIME_FILTER,
} from "@redux/types";
import { defaultExecutionTimeFilter } from "@lib/utils/constants";

export const updateExecutionSearchTerm = (searchTerm = "") => {
  return dispatch => {
    dispatch({
      type: EXECUTION_UPDATE_SEARCH_TERM,
      payload: searchTerm,
    });
  };
};

export const updateExecutionStatusFilter = (status = []) => {
  return dispatch => {
    dispatch({
      type: EXECUTION_UPDATE_STATUS_FILTER,
      payload: status,
    });
  };
};

export const updateExecutionTimeFilter = (
  time = defaultExecutionTimeFilter,
) => {
  return dispatch => {
    dispatch({
      type: EXECUTION_UPDATE_TIME_FILTER,
      payload: time,
    });
  };
};
