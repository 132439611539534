import React from "react";
import { Button, LabelTop, Select, SelectOption } from "@components/ui";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  saveConnecterSchema,
  isFetching,
  fetchConnectorConfigDetails,
} from "@redux/actions/settingsPanel.action";
import { fetchAPIKeys } from "@redux/actions/apikeys.actions";
import { Formik } from "formik";
import * as Yup from "yup";
import FormInput from "../../../settings-panel-components/SettingsRightConfigPanel/FormInput";
import { Wait } from "@components/ui";
import { Redirect } from "react-router";
import { camelToSnake } from "@lib/utils";
import IconFilter from "@assets/images/icons/icon-filter.svg";
import copyIcon from "@assets/images/icons/icon-copy.svg";

import { CopyToClipboard } from "react-copy-to-clipboard";
import uuidv1 from "uuid/v1";

const INITIAL_VALUES = {
  external_id: uuidv1(),
  webhook_url: "",
  webhook_name: "",
  webhook_status: false,
  webhook_api_key_name: "",
  webhook_api_key_value: "",
  instana_url: "",
  instana_api_token: "",
};

const validationSchema = Yup.object({
  webhook_api_key_name: Yup.string()
    .required("Webhook API Key Name is required")
    .trim("Webhook API Key Name is required"),
  instana_url: Yup.string()
    .required("Instana URL is required")
    .trim("Instana URL is required"),
  instana_api_token: Yup.string()
    .required("Instana API Token is required")
    .trim("Instana API Token is required"),
});

const FOOTER_BTN_STYLE = { width: "48%" };
class ConnectorForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      doRedirect: false,
      redirectUrl: "",
      initialValues: {},
      showForm: false,
    };
  }

  componentDidMount() {
    if (!(this.props.apiKeyList?.length > 0)) this.props.fetchAPIKeys();
    this.getInstanaConfig();
  }

  getInstanaConfig = async () => {
    this.props.isFetching(true, "Fetching Webhook URL");
    let action = await this.props.fetchConnectorConfigDetails("instana");
    if (action.type === "CONNECTOR_CONFIGURATION_INFO_FETCH_SUCCESS") {
      let response = JSON.parse(this.props.connectorConfig);
      let webhook_status = response?.webhook_status === "ON" ? true : false;
      const initialValues = {
        ...INITIAL_VALUES,
        webhook_url: response?.webhook_url,
        webhook_name: response?.webhook_name,
        webhook_status,
      };
      this.setState(
        {
          initialValues,
          showForm: true,
        },
        () => {
          this.props.isFetching(false, "");
        },
      );
    } else {
      this.props.isFetching(false, "");
    }
  };

  toggleInstanaConnector = () => {
    this.setState(prevState => ({
      initialValues: {
        ...prevState.initialValues,
        webhook_status: !prevState.initialValues.webhook_status,
      },
    }));
  };

  goBack = () => {
    this.setState({ doRedirect: true, redirectUrl: "/settings" });
  };

  getPayload = formData => {
    let payload = {};
    Object.keys(formData).forEach(key => {
      payload[camelToSnake(key)] = formData[key];
    });
    payload["webhook_status"] =
      this.state.initialValues.webhook_status === true ? "ON" : "OFF";

    return { credentials: payload };
  };

  onSubmitHandle = async (accountDetails, actions) => {
    this.props.isFetching(true, "Integration in progress");
    actions.setSubmitting(true);
    const payload = this.getPayload(accountDetails);
    await this.props.saveConnecterSchema(payload, "instana");
    actions.setSubmitting(false);
    this.props.isFetching(false, "");
    if (this.props.formSubmittedSuccess) {
      actions.setStatus({
        formSubmitMessage: "Connector details saved successfully!!!",
      });
      setTimeout(() => {
        this.goBack();
      }, 1500);
    } else {
      actions.setStatus({
        formSubmitMessage: "Oops! Something went wrong !!!",
      });
    }
  };

  selectWebhookApiKeyName = (apiKey, name, setFieldValue) => {
    setFieldValue("webhook_api_key_name", apiKey ? apiKey.ApikeyName : "");
    setFieldValue("webhook_api_key_value", apiKey ? apiKey.Value : "");
  };

  render() {
    return (
      <>
        {this.state.doRedirect && <Redirect to={this.state.redirectUrl} push />}
        {this.props.message && <Wait text={this.props.message} />}

        {this.state.showForm && (
          <Formik
            initialValues={this.state.initialValues}
            validationSchema={validationSchema}
            onSubmit={this.onSubmitHandle}
          >
            {formik => (
              <div className="d-flex flex-column2">
                <h2 className="pt-10 pb-10 mb-15">Authorize Fylamynt</h2>
                <div className="mb-15 d-flex flex-column2">
                  <CopyToClipboard
                    text={`${formik.values.external_id}`}
                    onCopy={() => console.log("External ID copied")}
                  >
                    <img
                      src={copyIcon}
                      width="15"
                      height="15"
                      className="gs-copy-icon pointer"
                      alt="copyIcon"
                    />
                  </CopyToClipboard>

                  <FormInput
                    fieldName="External ID:"
                    name="external_id"
                    id="external_id"
                    handleChange={formik.handleChange}
                    touched={formik.touched}
                    errors={formik.errors}
                    className="copy-text"
                    readOnly
                  />
                </div>
                <div className="mb-15">
                  <FormInput
                    fieldName="Instana URL:"
                    name="instana_url"
                    id="instana_url"
                    handleChange={formik.handleChange}
                    touched={formik.touched}
                    errors={formik.errors}
                    autoComplete="new-password"
                  />
                </div>
                <div className="mb-15">
                  <FormInput
                    fieldName="Instana API Token:"
                    name="instana_api_token"
                    id="instana_api_token"
                    handleChange={formik.handleChange}
                    touched={formik.touched}
                    errors={formik.errors}
                    autoComplete="new-password"
                    isMasked={true}
                  />
                </div>
                <div className="mb-15">
                  <FormInput
                    fieldName="Instana Webhook Name:"
                    name="webhook_name"
                    id="webhook_name"
                    handleChange={formik.handleChange}
                    touched={formik.touched}
                    errors={formik.errors}
                    autoComplete="off"
                    readOnly={true}
                  />
                </div>
                <div className="mb-15">
                  <FormInput
                    fieldName="Instana Webhook URL:"
                    name="webhook_url"
                    id="webhook_url"
                    handleChange={formik.handleChange}
                    touched={formik.touched}
                    errors={formik.errors}
                    autoComplete="off"
                    readOnly={true}
                  />
                </div>
                <div className="account-info__wrap ">
                  <LabelTop
                    label={`Webhook API Key Name:`}
                    labelClassName="label label__padding-left"
                  >
                    <Select
                      value={formik.values.webhook_api_key_name}
                      width="100%"
                      icon={IconFilter}
                      onChange={(apiKey, name) =>
                        this.selectWebhookApiKeyName(
                          apiKey,
                          name,
                          formik.setFieldValue,
                        )
                      }
                      allowEmpty={false}
                      searchMin={2}
                      required={true}
                      title="Select from below"
                      titleClassname={
                        !formik.values.webhook_api_key_name
                          ? `formik-field-error`
                          : `no-error`
                      }
                      optionHeight="41"
                    >
                      {(this.props.apiKeyList || []).map((apiKey, i) => (
                        <SelectOption
                          value={apiKey}
                          text={apiKey.ApikeyName}
                          key={`${apiKey.Value}`}
                          className="text-small"
                          animationBorder={"hover"}
                        />
                      ))}
                    </Select>
                  </LabelTop>
                  {formik.errors["webhook_api_key_name"] &&
                    formik.touched["webhook_api_key_name"] && (
                      <div className="input-feedback">
                        {formik.errors["webhook_api_key_name"]}
                      </div>
                    )}
                </div>
                {formik.values.webhook_api_key_name && (
                  <FormInput
                    fieldName="Webhook API Key Value:"
                    name="webhook_api_key_value"
                    id="webhook_api_key_value"
                    handleChange={formik.handleChange}
                    touched={formik.touched}
                    errors={formik.errors}
                    autoComplete="off"
                    readOnly={true}
                    isMasked={true}
                  />
                )}
                <div className="mb-15">
                  <div className="enable-notifications-toggle">
                    <label className="label label__padding-left">
                      Enable / Disable Instana Webhook
                    </label>
                    <>
                      <label className="switch">
                        <input
                          type="checkbox"
                          name="webhook_status"
                          id="webhook_status"
                          value={formik.values.webhook_status}
                          onClick={this.toggleInstanaConnector}
                          defaultChecked={formik.values.webhook_status}
                        />
                        <span className="slider round"></span>
                      </label>
                    </>
                  </div>
                </div>

                <div className="gs-form-footer">
                  <Button
                    text="Cancel"
                    buttonStyle="secondary"
                    style={FOOTER_BTN_STYLE}
                    size="large"
                    type="button"
                    className="mr-15"
                    onClick={this.goBack}
                  />
                  <Button
                    text="Authorize"
                    style={FOOTER_BTN_STYLE}
                    buttonStyle="primary"
                    size="large"
                    onClick={formik.handleSubmit}
                  />
                </div>

                <div className="mt-20">
                  {!!formik.status && formik.status.formSubmitMessage}
                </div>
              </div>
            )}
          </Formik>
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  message: state.runbooksReducer.message,
  formSubmittedSuccess: state.settingsPanelReducer.formSubmittedSuccess,
  connectorConfig: state.settingsPanelReducer.currentNodeConfigInfo,
  apiKeyList: state.APIKeysReducer.apiKeyList,
});
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      saveConnecterSchema,
      isFetching,
      fetchConnectorConfigDetails,
      fetchAPIKeys,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectorForm);
