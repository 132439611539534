import uniq from "lodash/uniq";
import { NotImplementedError } from "../exceptions";
import { RunbookStepOutput } from "./nodeinputoutput";
import { SSMActionNames } from "./strings";
import { StepTypes } from "../neuropssteps/strings";
import { RUNBOOK_DID_UPDATE } from "@redux/types";
import { checkInvalidRequiredInput } from "./util";

// STEPS
export class Step {
  constructor(stepJSON) {
    Object.assign(this, stepJSON);
    this.ssm = stepJSON;
    this.editable = false;

    if (this.ssm.outputs) {
      this.outputs = this.ssm.outputs.map(
        output =>
          new RunbookStepOutput(
            this,
            output.Name,
            output.Type,
            output.Selector,
          ),
      );
    }
    this.validateTypes = [
      StepTypes.CloudwatchAlertConnectorStep,
      StepTypes.DatadogConnectorStep,
      StepTypes.SlackConnectorStep,
      StepTypes.JiraConnectorStep,
    ];

    window.setTimeout(e => this.showHideWarning(!this.isHealthyStep()), 200);

    window.addEventListener(RUNBOOK_DID_UPDATE, e => {
      this.showHideWarning(!this.isHealthyStep());
    });
  }

  isHealthyStep() {
    return checkInvalidRequiredInput(this?.parameterInputs);
  }

  destructor() {
    this.updateRunbookUnhealthySteps(false);
  }

  rename(newName) {
    if (this.runbook) {
      this.runbook.renameStep(this.name, newName);
    }
  }

  nextSteps() {
    //const { ssm } = this;
    const nextSteps = [];
    let choice;
    if (
      this.onFailure &&
      this.onFailure !== "Abort" &&
      this.onFailure !== "Continue"
    ) {
      nextSteps.push(this.onFailure);
    }

    if (this.nextStep) {
      nextSteps.push(this.nextStep);
    }
    if (this.action === SSMActionNames.BRANCH) {
      if (this.defaultNextStep) {
        nextSteps.push(this.defaultNextStep);
      }
      for (choice of this.choices) {
        if (choice.nextStep) {
          nextSteps.push(choice.nextStep);
        }
      }
    }

    return uniq(nextSteps);
  }

  isLeaf() {
    return this.nextSteps().length === 0;
  }

  removeTarget(targetName) {
    this.nextStep = undefined;
  }
  consumedOutputs() {
    return [];
  }

  toSSM() {
    if (this.editable) {
      throw new NotImplementedError(
        "toSSM",
        "editable SSM Steps must override this method.",
      );
    }
    return this.ssm;
  }

  // this method travels with activeNode
  // should be called when an update on the node happens
  // and it's overwritten on child instances
  didUpdateStep = options => {
    console.log("updateSteps->", options);
  };

  showHideWarning = bool => {
    const nodeDiv = document.querySelector(
      `[data-nodeid="${this.editorNodeId}"]`,
    );
    const errorDiv = nodeDiv && nodeDiv.querySelector(".editor-icon-error");
    if (bool) {
      const warningAlertDiv = document.createElement("div");
      warningAlertDiv.setAttribute("class", "editor-icon-error");
      warningAlertDiv.setAttribute("style", `top: 0; right: 0`);
      if (nodeDiv && !nodeDiv.contains(errorDiv)) {
        nodeDiv.appendChild(warningAlertDiv);
        this.updateRunbookUnhealthySteps(true);
      }
    } else if (!bool && errorDiv) {
      nodeDiv.removeChild(errorDiv);
      this.updateRunbookUnhealthySteps(false);
    }
  };

  updateRunbookUnhealthySteps = bool => {
    if (this.runbook && this.editorNodeId) {
      let idx = this.runbook.unHealthySteps.indexOf(this.editorNodeId);
      if (idx > -1 && !bool) {
        this.runbook.unHealthySteps.splice(idx, 1);
      } else if (idx === -1 && bool) {
        this.runbook.unHealthySteps.push(this.editorNodeId);
      }
      this.runbook.updateRunbookObj(this.runbook);
    }
  };
}
