export const init = (ref, json, leafOnclick) => {
  const jsonTree = new JsonTree(ref.current, json, leafOnclick);
  //jsonTree.buildTreeDom(json, ref.current);
  jsonTree.buildTree(json, ref.current, "$.output");
};

class JsonTree {
  constructor(element, json, leafOnclick) {
    this.element = element;
    this.json = json;
    this.leafOnclick = leafOnclick;
    this.expandContractChildren = false;
  }

  cancelChildNodes = () => {
    this.expandContractChildren = false;
  };

  buildTree(json, parentElement, concatenatedValue) {
    if (typeof json === "object") {
      Object.keys(json).forEach(key => {
        const item = json[key];
        if (typeof item === "object") {
          const { div, newValue } = this.createBranchNode(
            key,
            parentElement,
            concatenatedValue,
          );
          this.buildTree(item, div, newValue);
        } else {
          const type = json[key];
          this.createLeafNode(key, parentElement, concatenatedValue, type);
        }
      });
    }
  }

  createLeafNode(key, parent, value, type) {
    let leafValue;
    let text;
    if (this.isNumeric(key)) {
      text = "String";
      leafValue = value + "[0]";
    } else {
      text = key;
      leafValue = value + "." + key;
    }

    const div = document.createElement("div");
    div.classList.add("margin-left-10");
    div.classList.add("link-white");
    div.setAttribute("data-value", leafValue);
    if (this.leafOnclick) {
      div.onclick = () => this.leafOnclick(leafValue, type);
    }
    div.innerHTML = text;
    parent.appendChild(div);
  }

  isNumeric(value) {
    const regEx = new RegExp("[a-zA-Z]", "gi");
    return !regEx.test(value);
  }

  collapseExpand(node) {
    let collapseState = node.getAttribute("data-collapse");
    let display;
    if (collapseState === "expanded") {
      node.querySelector(".tree-sign").innerHTML = "+";
      node.setAttribute("data-collapse", "collapsed");
      display = "none";
    } else {
      node.querySelector(".tree-sign").innerHTML = "-";
      node.setAttribute("data-collapse", "expanded");
      display = "";
    }
    let elements = node.childNodes;

    for (let i = 1; i < elements.length; i++) {
      const element = elements[i];
      element.style.display = display;
      this.updatePlusMinus(element, display);
    }
  }

  updatePlusMinus(element, display) {
    const treeNodes = element.querySelectorAll(".tree-sign");
    if (treeNodes) {
      [].slice.call(treeNodes).forEach(node => {
        if (display === "none") {
          node.innerHTML = "+";
        } else {
          node.innerHTML = "-";
        }
      });
    }
  }

  createBranchNode(key, parent, concatenatedValue) {
    if (isNaN(key)) {
      const div = document.createElement("div");
      div.classList.add("margin-left-10");
      div.setAttribute("data-collapse", "expanded");
      parent.appendChild(div);
      const childDiv = document.createElement("div");
      childDiv.classList.add("link-light-blue");
      childDiv.innerHTML = `<span class='tree-sign'>-</span><span>${key}</span>`;
      childDiv.onclick = () => {
        this.collapseExpand(div, childDiv);
      };
      div.appendChild(childDiv);
      return { div, newValue: this.getValue(concatenatedValue, key) };
    } else {
      return { div: parent, newValue: this.getValue(concatenatedValue, key) };
    }
  }

  getValue = (concatenatedValue, value) => {
    if (!value) {
      return "";
    }
    const keyValue = parseInt(value);
    if (isNaN(keyValue)) {
      return concatenatedValue + "." + value;
    } else {
      return concatenatedValue + "[" + value + "]";
    }
  };
}
