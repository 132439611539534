import React from "react";
import { Consumer } from "../runbook-details-lib/runbook-details.context";
import { JsonView } from "@components/ui";
import { TabCollection, Tab, TabContent } from "@components/ui/tabs";
import DataTable from "./runbook-details-data-table";

class RunbookDetailsTabs extends React.Component {
  render() {
    return (
      <Consumer>
        {({ runbook }) => (
          <div className="runbook-details-tabs">
            <TabCollection active="parameters">
              <Tab title="Parameters" name="parameters">
                <TabContent className="runbook-details-tabs-content">
                  <DataTable />
                </TabContent>
              </Tab>
              <Tab title="Content" name="content">
                <TabContent className="runbook-details-tabs-content">
                  {runbook && (
                    <JsonView
                      data={JSON.stringify(runbook.Content)}
                      showCopy={false}
                      className="content-document"
                    />
                  )}
                </TabContent>
              </Tab>
            </TabCollection>
          </div>
        )}
      </Consumer>
    );
  }
}

export default RunbookDetailsTabs;
