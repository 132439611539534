import React from "react";
import { Modal } from "@components/ui";
import { Consumer } from "../../runbook-editor-lib/runbook-editor.context";
import GearIcon from "@assets/images/icons/icon-gear.svg";

const getFooter = (toggleWarning, setActivePanel) => (
  <div className="modal-buttons-footer__editor">
    <button
      type="button"
      className="footer-btn footer-btn-cancel"
      onClick={toggleWarning}
    >
      Cancel
    </button>
    <button
      type="button"
      className="footer-btn footer-btn-save"
      onClick={() => {
        setActivePanel("settings", "Settings");
        toggleWarning();
      }}
    >
      Ok
    </button>
  </div>
);

export default props => (
  <Consumer>
    {({ setActivePanel }) => (
      <Modal
        title={`Alert`}
        showClose={true}
        coverClass={`modal-cover__editor`}
        containerClass={`modal-container__editor-transactional`}
        contentClass={`modal-content__editor-transactional`}
        titleClass={`modal-title__json`}
        closeClass={`modal-close__editor`}
        footerClass={`modal-footer__editor`}
        footer={getFooter(props.toggleWarning, setActivePanel)}
        onClose={props.toggleWarning}
      >
        <div>
          <div className="mb-20">
            You must fill in a description before this workflow can be saved.{" "}
          </div>
          <div>
            To add a description, click the
            <img
              className="ml-5 mr-5"
              src={GearIcon}
              height="15"
              width="15"
              alt="Open settings"
            />
            icon in the left-side menu.
          </div>
        </div>
      </Modal>
    )}
  </Consumer>
);
