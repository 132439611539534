function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React from "react";

var _ref2 = React.createElement("title", null, "icon color");

var _ref3 = React.createElement("desc", null, "Created with Sketch.");

var _ref4 = React.createElement("defs", null, React.createElement("polygon", {
  id: "path-15",
  points: "5.68434189e-14 5 5 0 5.707 0.707 1.414 5 5.707 9.293 5 10"
}));

var _ref5 = React.createElement("g", {
  id: "Modal",
  stroke: "none",
  strokeWidth: 1,
  fill: "none",
  fillRule: "evenodd"
}, React.createElement("g", {
  id: "Modal---White-theme",
  transform: "translate(-621.000000, -365.000000)"
}, React.createElement("g", {
  id: "icon/navigation/chevron/left/16",
  transform: "translate(621.000000, 365.000000)"
}, React.createElement("mask", {
  id: "mask-2",
  fill: "white"
}, React.createElement("use", {
  xlinkHref: "#path-15"
})), React.createElement("use", {
  id: "icon-color",
  fill: "#F3F3F3",
  xlinkHref: "#path-15"
}))));

var SvgIconBack = function SvgIconBack(_ref) {
  var svgRef = _ref.svgRef,
      title = _ref.title,
      props = _objectWithoutProperties(_ref, ["svgRef", "title"]);

  return React.createElement("svg", _extends({
    width: "6px",
    height: "10px",
    viewBox: "0 0 6 10",
    ref: svgRef
  }, props), title === undefined ? _ref2 : title ? React.createElement("title", null, title) : null, _ref3, _ref4, _ref5);
};

var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(SvgIconBack, _extends({
    svgRef: ref
  }, props));
});
export default __webpack_public_path__ + "static/media/icon-back.ec4f8643.svg";
export { ForwardRef as ReactComponent };