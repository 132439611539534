import React from "react";
import PropTypes from "prop-types";
import { SelectMultiple, SelectMultipleOption, LabelTop } from "@components/ui";

const SlackNotification = ({
  slackNotificationFlag,
  slackChannels,
  selectedSlackChannels,
  toggleSlackNotification,
  handleChannelOptions,
}) => {
  return (
    <div className="slack-notifications">
      <div className="slack-notifications-toggle form-item__mb-l">
        <label className="label label__padding-left">
          Notification of Workflow Executions on Slack
        </label>
        <>
          <label className="switch">
            <input
              type="checkbox"
              id="toggle_slack_notifications"
              value={slackNotificationFlag}
              onClick={toggleSlackNotification}
              defaultChecked={slackNotificationFlag}
            />
            <span className="slider round"></span>
          </label>
        </>
      </div>
      {slackNotificationFlag &&
        (slackChannels.length ? (
          <div>
            <LabelTop
              label={
                "Add Slack channels to get Fylamynt Workflow Notifications:"
              }
            ></LabelTop>

            <SelectMultiple
              title={
                selectedSlackChannels.length
                  ? selectedSlackChannels.join(",")
                  : "Select from below"
              }
              dropDownStyle="overlay"
              maxHeight={"250"}
              showReset={false}
              optionHeight={30}
              titleHeight={40}
              values={selectedSlackChannels}
              onChange={handleChannelOptions}
              titleClassname={
                slackNotificationFlag && selectedSlackChannels.length === 0
                  ? `text-input formik-field-error`
                  : `text-input w-input`
              }
            >
              {slackChannels.map((channel, idx) => {
                const isChecked = selectedSlackChannels.length
                  ? !!selectedSlackChannels.find(
                      selectedChannel => selectedChannel === channel,
                    )
                  : false;
                return (
                  <SelectMultipleOption
                    value={channel}
                    text={channel}
                    checked={isChecked}
                    key={idx}
                  />
                );
              })}
            </SelectMultiple>
            {slackNotificationFlag && selectedSlackChannels.length === 0 && (
              <div className="input-feedback">
                Select Slack Channel to enable Notification
              </div>
            )}
          </div>
        ) : (
          <div>
            No slack channels configured. Please invite Fylamynt App{" "}
            <b>/invite @Fylamynt</b> to channels in your Slack workspace
          </div>
        ))}
    </div>
  );
};

SlackNotification.propTypes = {
  slackNotificationFlag: PropTypes.bool,
  slackChannels: PropTypes.array,
  selectedSlackChannels: PropTypes.array,
  toggleSlackNotification: PropTypes.func,
  handleChannelOptions: PropTypes.func,
};

export default SlackNotification;
