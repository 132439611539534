import React from "react";
import RadioOptionsFilter from "./RadioOptionsFilter";
import { getSelectedOption } from "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.helpers";
import { Consumer } from "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.context";
import {
  RunbookStepInputSource,
  RunbookStepInput,
} from "../../../runbook-editor-lib/ssm/nodeinputoutput";
import {
  _searchResourceList,
  _updateInputControl,
  _updateTextInput,
} from "./lib/input-selector.lib";
import {
  SetValueNow,
  FromPreviousStep,
  SetValueAtRuntime,
} from "./option-actions";
import { Parameter } from "@containers/RunbookEditor/runbook-editor-lib/ssm/parameters";
import { awsFriendlyName } from "@containers/RunbookEditor/runbook-editor-lib/ssm/util";

export default class InputSelector extends React.PureComponent {
  state = {
    resourcesListSearch: [],
    inputType: this.props.input.source.type || "userProvided",
  };

  constructor(props) {
    super(props);
    this.resourcesList = [];
  }

  _changeInputType = inputType => {
    this._updateForInputType(inputType);
    this.setState({ inputType });
  };

  searchResourcesList = value => {
    _searchResourceList.call(this, value, () => {
      if (!value) {
        _updateTextInput(
          this.props.input.source.sourceValue,
          this.props.input.name,
        );
      }
    });
  };

  selectResource = item => {
    if (this.props.input.resourcesAutocomplete?.length !== 2) return;
    const value = item[this.props.input.resourcesAutocomplete[1]];
    this.props.input.source = new RunbookStepInputSource("constant", value);
    this.setState(
      {
        resourcesListSearch: [],
      },
      () => {
        _updateTextInput.call(this, value, this.props.input.name);
      },
    );

    // notify reducer that current runbook got updates.
    this.notifyRunbookUpdate(true);
  };

  onBlurEvent = event => {
    /* this is just a hack to give the click
       on resource attribute sometime to call this.selectResource()
     @TODO: better way to handle it.
    */
    window.setTimeout(() => {
      this.setState({
        resourcesListSearch: [],
      });
    }, 250);
  };

  _updateForInputType = newType => {
    const { input } = this.props;
    if (newType !== input.source.type) {
      switch (newType) {
        case "userProvided":
          this.updateUserProvidedInputControl();
          break;
        case "snippetOutput":
        case "actionNode":
          this.updateInputControl("");
          break;
        case "constant":
          this.updateConstantValueControl("");
          break;
        default:
          console.warn(`Unknown input type: ${newType}`);
      }
    }
  };

  updateUserProvidedInputControl() {
    const { input } = this.props;
    const name = RunbookStepInput.parameterName(input.name, input); // `${input.name}${input.snippetAction.name}`;
    const parameterName = awsFriendlyName(name);
    const description = `(${input.required ? "Required" : "Optional"} - ${
      input.type
    }) ${input.snippetAction.name} - ${input.name}`;
    const param = Parameter.createFylamyntParameter(parameterName, description);

    input.snippetAction.runbook.ssmDoc.parameters.parameters[
      parameterName
    ] = param;
    input.source = new RunbookStepInputSource("userProvided", param);

    // notify reducer that current runbook got updates.
    this.notifyRunbookUpdate(true);
  }

  updateConstantValueControl = newValue => {
    // notify reducer that current runbook got updates.
    const { input } = this.props;
    input.source = new RunbookStepInputSource("constant", newValue);

    this.notifyRunbookUpdate(true);
  };

  updateInputControl = (sourceStep, sourceOutput) => {
    // notify reducer that current runbook got updates.
    const { input } = this.props;
    input.source = new RunbookStepInputSource("actionNode", null);
    this.notifyRunbookUpdate(true);

    _updateInputControl.call(this, sourceStep, sourceOutput);
  };

  inputControl(
    input,
    onChangeCallBack,
    activeNode,
    notifyRunbookUpdate,
    resourcesList,
    runbookObj,
  ) {
    this.notifyRunbookUpdate = notifyRunbookUpdate;
    this.resourcesList = resourcesList;
    const label = this.props.label || this.props.input.name;
    switch (this.state.inputType) {
      case "snippetOutput":
        return (
          <FromPreviousStep
            input={input}
            updateInputControl={this.updateInputControl}
            onChangeCallBack={onChangeCallBack}
            runbookObj={runbookObj}
            label={label}
          />
        );
      case "actionNode":
        return (
          <FromPreviousStep
            input={input}
            label={label}
            updateInputControl={this.updateInputControl}
            onChangeCallBack={onChangeCallBack}
            runbookObj={runbookObj}
            notifyRunbookUpdate={notifyRunbookUpdate}
          />
        );
      case "userProvided":
        return <SetValueAtRuntime input={input} label={this.props.label} />;

      case "constant":
      default:
        return (
          <SetValueNow
            input={input}
            onChangeCallBack={onChangeCallBack}
            activeNode={activeNode}
            notifyRunbookUpdate={notifyRunbookUpdate}
            resourceList={resourcesList}
            searchResourcesList={this.searchResourcesList}
            selectResource={this.selectResource}
            label={this.props.label}
            resourcesListSearch={this.state.resourcesListSearch}
            updateConstantValueControl={this.updateConstantValueControl}
            onBlur={this.onBlurEvent}
          />
        );
    }
  }

  render() {
    const { input } = this.props;
    return (
      <Consumer>
        {({
          updateRunbook,
          activeNode,
          notifyRunbookUpdate,
          resourcesList,
          runbookObj,
        }) => {
          return (
            <div
              className="editor-detail-panel editor-detail-panel-column"
              key={input.name}
            >
              {this.inputControl(
                input,
                updateRunbook,
                activeNode,
                notifyRunbookUpdate,
                resourcesList,
                runbookObj,
              )}
              <RadioOptionsFilter
                optionFilterId={this.props.optionFilterId}
                toggleInputOption={this.props.toggleInputOption}
                selectedOption={getSelectedOption(input)}
                updateSelection={this._changeInputType}
                isConditionalVariableInput={
                  this.props.isConditionalVariableInput
                }
              />
            </div>
          );
        }}
      </Consumer>
    );
  }
}
