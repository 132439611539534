import React from "react";
import { Consumer } from "../../../runbook-editor-lib/runbook-editor.context";
import { isEmpty } from "@lib/utils";
import showSettingsIcon from "@assets/images/icons/icon-gear.svg";
import ReactTooltip from "react-tooltip";

export default class Settings extends React.Component {
  render() {
    return (
      <Consumer>
        {({ setActivePanel, activePanel, runbook }) => (
          <div
            data-tut="reactour__settings"
            onClick={() => setActivePanel("settings", "Settings")}
            className={`editor-menu-item-container ${
              activePanel === "settings"
                ? "editor-menu-item-active"
                : "editor-menu-item-inactive"
            }`}
          >
            {runbook && isEmpty(runbook.Description) && (
              <div className="editor-icon-error" />
            )}
            <ReactTooltip
              id="workflow-settings"
              place="right"
              effect="solid"
              type="light"
            />
            <img
              src={showSettingsIcon}
              alt="showSettingsIcon"
              className="editor-menu-item-image"
              width="18"
              height="18"
              data-for="workflow-settings"
              data-tip="Workflow Settings"
            />
          </div>
        )}
      </Consumer>
    );
  }
}
