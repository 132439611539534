import React from "react";
import { Tab, TabCollection, TabContent } from "@components/ui/tabs";
import Output from "../../output";
import Details from "../../details";
import WebhookInputs from "./Helpers/WebhookInputs";
import SetupInstructions from "../../../SetupInstructions/SetupInstructions";
import getInstructions from "./Helpers/Instructions";

type WebhookProps = {};

const Webhook: React.FC<WebhookProps> = () => {
  return (
    <TabCollection
      active="input"
      activeClassName="editor-detail-tab-wrap editor-detail-active-tab"
      inactiveClassName="editor-detail-tab-wrap editor-detail-inactive-tab"
      contentTop={50}
    >
      <Tab title="Input" name="input">
        <TabContent>
          <WebhookInputs />
          <SetupInstructions>{getInstructions()}</SetupInstructions>
        </TabContent>
      </Tab>
      <Tab title="Output" name="output">
        <TabContent>
          <Output />
        </TabContent>
      </Tab>
      <Tab title="Details" name="details">
        <TabContent>
          <Details />
        </TabContent>
      </Tab>
    </TabCollection>
  );
};

export default Webhook;
