import React from "react";
import { TextInput } from "@components/ui";
import { Consumer } from "./output-configure.context";

export default class ConfigureMapItem extends React.PureComponent {
  updateOutput = (key, value, callBack) => {
    if (callBack) {
      callBack(key, value);
    }
  };

  getLabel = () => {
    return this.props.leading + " " + this.props.label;
  };

  render() {
    const label = this.getLabel();
    return (
      <Consumer>
        {({ updateOutput }) => (
          <div className="configure-modal__item-wrap">
            <span className="mr-5">{label}: </span>
            <span>
              <TextInput
                value={"*"}
                name={label}
                className={`rule-input configure-modal__input`}
                onKeyUp={value =>
                  this.updateOutput(this.props.itemKey, value, updateOutput)
                }
                onLoad={() => {
                  this.updateOutput(this.props.itemKey, "*", updateOutput);
                }}
              />
            </span>
          </div>
        )}
      </Consumer>
    );
  }
}
