import React from "react";
import { Modal, Button } from "@components/ui";
import { connect } from "react-redux";
import CronReader from "@lib/utils/cron-reader";
import range from "lodash/range";
import Api from "@lib/api";
import { FTNotification } from "@components/ui";
import { store } from "@redux/store";
import { RUNBOOK_SCHEDULE } from "@redux/types";

const getFooter = (toggleRunbookModal, isLoading, scheduleAction) => (
  <div className="modal-buttons-footer">
    <Button
      text="Cancel"
      buttonStyle="secondary"
      style={{ width: "50%" }}
      onClick={toggleRunbookModal}
      type="button"
    />
    <Button
      text={`Schedule`}
      buttonStyle="primary"
      style={{ width: "50%" }}
      type={"button"}
      size="large"
      isLoading={isLoading}
      onClick={scheduleAction}
    />
  </div>
);

class RunbookScheduleModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.cronReader = new CronReader();
    let rateTime = 0;
    let rateTimeOption = "minutes";
    let fixedRate = null;
    let scheduleExpression = ["*", "*", "*", "*", "?", "*"];
    let expressionText = "";
    let currentSchedule = props?.currentSchedule?.filter(
      s => s.version === parseInt(props.runbook.DocumentVersion),
    )?.[0];

    if (currentSchedule?.schedule_expression?.indexOf("rate(") > -1) {
      fixedRate = currentSchedule.schedule_expression;
      let match = currentSchedule.schedule_expression.match(/rate\((.*)\)/i);
      rateTime = match[1].split(" ")[0];
      rateTimeOption = match[1].split(" ")[1];
      let { r, o } = this.generateFixedRateText(rateTime, rateTimeOption);
      expressionText = "Once every " + (r === 1 ? `` : r) + " " + o;
    } else if (currentSchedule?.schedule_expression?.indexOf("cron(") > -1) {
      let match = currentSchedule.schedule_expression.match(/cron\((.*)\)/i);
      scheduleExpression = match[1].split(" ");
      expressionText = this.cronReader.humanize(scheduleExpression.join(" "));
    }
    this.state = {
      rateTime: rateTime,
      rateTimeOption: rateTimeOption.endsWith("s")
        ? rateTimeOption
        : `${rateTimeOption}s`,
      fixedRate: fixedRate,
      scheduleExpression: scheduleExpression,
      expressionText: expressionText,
    };
  }

  componentDidMount() {}

  setRateTime = e => {
    if (e.target.value === "") return;
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      e => {
        let { r, o } = this.generateFixedRateText(
          this.state.rateTime,
          this.state.rateTimeOption,
        );
        if (r === 0) return;
        this.setState({
          expressionText: "Once every " + (r === 1 ? `` : r) + " " + o,
          fixedRate: `rate(${r} ${o})`,
          scheduleExpression: ["*", "*", "*", "*", "?", "*"], // reset
        });
      },
    );
  };

  generateFixedRateText = (rateTime, rateTimeOption) => {
    let r = parseInt(rateTime);
    let o = r < 2 ? rateTimeOption.replace("s", "") : rateTimeOption;
    return { r, o };
  };

  generateCronExpression = e => {
    let value = e.target.value;
    let exp = this.state.scheduleExpression;
    switch (e.target.name) {
      case "minutes":
        if (parseInt(value) < 0 || value === "") value = "*";
        exp[0] = value;
        break;
      case "hours":
        if (parseInt(value) < 0 || value === "") value = "*";
        exp[1] = value;
        break;
      case "day":
        exp[2] = value;
        exp[4] = "?";
        break;
      case "month":
        exp[3] = value;
        break;
      case "week-day":
        exp[4] = value;
        exp[2] = "?";
        break;
      case "year":
        exp[5] = value;
        break;

      default:
        break;
    }

    this.setState({
      scheduleExpression: exp,
      expressionText: this.cronReader.humanize(exp.join(" ")),
      // reset
      fixedRate: null,
      rateTime: 0,
      rateTimeOption: "minutes",
    });
  };

  setScheduleExpression = e => {
    this.setState({
      scheduleExpression: e.target.value.split(" "),
      expressionText: this.cronReader.humanize(e.target.value),
      // reset
      fixedRate: null,
      rateTime: 0,
      rateTimeOption: "minutes",
    });
  };

  scheduleAction = (runbookName, runbookVersion, toggleScheduleModal) => {
    let exp = this.state.fixedRate
      ? this.state.fixedRate
      : `cron(${this.state.scheduleExpression.join(" ")})`;
    let body = {
      version: parseInt(runbookVersion),
      expression: exp,
    };
    Api.scheduleRunbook(runbookName, body).then(data => {
      if (data.ERROR) {
        FTNotification.error({
          title: "Could not schedule runbook execution",
          message: data.ERROR,
        });
      } else {
        FTNotification.success({
          title: "Runbook execution scheduled successfully",
        });
        toggleScheduleModal();

        try {
          let currentSchedule = this.props.currentSchedule.filter(
            a => a.version !== parseInt(runbookVersion),
          );
          currentSchedule.push({
            schedule_expression: exp,
            version: parseInt(runbookVersion),
          });
          store.dispatch({
            type: RUNBOOK_SCHEDULE,
            payload: currentSchedule,
          });
        } catch (error) {
          console.error(error);
        }
      }
    });
  };

  render() {
    const { toggleScheduleModal, runbook } = this.props;
    const runbookName = runbook.name || runbook.Name;

    return (
      <React.Fragment>
        {this.props.runbook && (
          <Modal
            onClose={toggleScheduleModal}
            title={`Schedule workflow "${runbookName}:v${runbook.DocumentVersion}" for execution`}
            backgroundCanClose={false}
            showClose={true}
            footer={getFooter(toggleScheduleModal, false, () =>
              this.scheduleAction(
                runbookName,
                runbook.DocumentVersion,
                toggleScheduleModal,
              ),
            )}
          >
            <div className="schedule-run">
              <div className="space-line">
                <span>Fixed Rate</span>
              </div>
              <div className="schedule-run-rate">
                Every:&nbsp;&nbsp;
                <input
                  type="number"
                  min={1}
                  className="text-input"
                  name="rateTime"
                  value={this.state.rateTime}
                  onChange={this.setRateTime}
                  style={{ width: "3rem", marginTop: 0 }}
                />
                &nbsp;&nbsp;
                <select
                  name="rateTimeOption"
                  onChange={this.setRateTime}
                  value={this.state.rateTimeOption}
                >
                  <option value="minutes">Minutes</option>
                  <option value="hours">Hours</option>
                  <option value="days">Days</option>
                </select>
              </div>
              <div className="space-line">
                <span>Cron Expression</span>
              </div>
              <div className="">
                &nbsp;&nbsp;
                <div>
                  <div className="cron-expression">
                    <div>
                      Minutes:
                      <br />
                      <input
                        type="number"
                        min={-1}
                        max={59}
                        name="minutes"
                        className="text-input"
                        value={this.state.scheduleExpression[0]}
                        onChange={this.generateCronExpression}
                      />
                    </div>
                    <div>
                      Hours:
                      <br />
                      <input
                        type="number"
                        min={-1}
                        max={24}
                        className="text-input"
                        name="hours"
                        value={this.state.scheduleExpression[1]}
                        onChange={this.generateCronExpression}
                      />
                    </div>
                    <div>
                      Day:
                      <br />
                      <select
                        onChange={this.generateCronExpression}
                        name="day"
                        value={this.state.scheduleExpression[2]}
                      >
                        {Array.from(new Array(32).keys()).map(n => {
                          if (n === 0)
                            return (
                              <option value={`*`} key={"*"}>
                                Every Day
                              </option>
                            );
                          return (
                            <option value={n} key={n}>
                              {n}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div>
                      Month:
                      <br />
                      <select
                        name="month"
                        onChange={this.generateCronExpression}
                        value={this.state.scheduleExpression[3]}
                      >
                        {Array.from(new Array(13).keys()).map(n => {
                          if (n === 0)
                            return (
                              <option value={`*`} key={"*"}>
                                Every Month
                              </option>
                            );
                          return (
                            <option value={n} key={n}>
                              {this.cronReader.monthNames[n]}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div>
                      Day of week:
                      <br />
                      <select
                        name="week-day"
                        onChange={this.generateCronExpression}
                        value={this.state.scheduleExpression[4]}
                      >
                        {Array.from(new Array(8).keys()).map(n => {
                          if (n === 0)
                            return (
                              <option value={`?`} key={"?"}>
                                Every Day
                              </option>
                            );
                          return (
                            <option value={n} key={n}>
                              {this.cronReader.dayNames[n]}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div>
                      Year:
                      <br />
                      <select
                        name="year"
                        onChange={this.generateCronExpression}
                        value={this.state.scheduleExpression[5]}
                      >
                        <option value={`*`}>All</option>
                        {range(
                          new Date().getFullYear(),
                          new Date().getFullYear() + 11,
                        ).map(n => {
                          return (
                            <option value={n} key={n}>
                              {n}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <br />
                  Cron Expression:&nbsp;&nbsp;
                  <input
                    type="text"
                    min={1}
                    className="text-input expression-input"
                    name="rate-time"
                    value={this.state.scheduleExpression.join(" ")}
                    onChange={this.setScheduleExpression}
                  />
                  <br />
                  <span>
                    (Minutes Hours Day-of-month Month Day-of-week Year)
                    <br />
                    Example: "15 12 * * ? *" Run at 12:15 pm (UTC) every day
                  </span>
                  <br />
                  <div style={{ display: "flex" }}>
                    <div
                      className="expression-text"
                      style={{ marginRight: "8px" }}
                    >
                      Result:
                    </div>
                    <div
                      className="expression-text"
                      style={{ color: "#98C379" }}
                    >
                      {this.state.expressionText}
                    </div>
                  </div>
                </div>
                <br />
              </div>
            </div>
          </Modal>
        )}
      </React.Fragment>
    );
  }
}

const mapState = ({ runbookReducer, websocketReducer }) => {
  return {
    runbook: runbookReducer.activeRunbook,
    version: runbookReducer.activeRunbookVersion,
    websocketConnectionId: websocketReducer.connectionId,
    resourcesList: runbookReducer.resourcesList,
  };
};
export default connect(mapState)(RunbookScheduleModal);
