import React, { useEffect, useState, useContext, useRef } from "react";
import {
  Accordion,
  AccordionLabel,
  AccordionSection,
  TextArea,
  TextInput,
} from "@components/ui";
import { RunbookEditorContext } from "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.context";
import { RunbookStepInputSource } from "@containers/RunbookEditor/runbook-editor-lib/ssm/nodeinputoutput";
import ReactSelect from "@components/ui/React-Select/ReactSelect";
import { sortResourceList } from "@lib/utils";

type RequiredInputsProps = {};

const SplunkSearchInputs: React.FC<RequiredInputsProps> = () => {
  const isFirstRun = useRef(true);

  const context = useContext(RunbookEditorContext) as any;
  const runbookNode = context.activeNode.extras.runbookNode;
  const notifyRunbookUpdate = context.notifyRunbookUpdate;
  const aliasList = sortResourceList(context.resourcesList.targets);

  const [selectedAlias, setSelectedAlias] = useState("");
  const [queryValue, setQueryValue] = useState("");
  const [s3Bucket, setS3Bucket] = useState("");

  const getParamInput = (input: string) =>
    runbookNode.parameterInputs.find(p => p.name === input);

  const setAlias = (alias: string) => {
    setSelectedAlias(alias);
    parsePayload();
    const aliasVal = getParamInput("alias");
    aliasVal.source = new RunbookStepInputSource(`constant`, alias);
    notifyRunbookUpdate(true);
  };
  const setPayload = query => {
    const payloadQuery = JSON.stringify(query).replace(/^"(.+)"$/, "$1");
    let queryParam = getParamInput("query");
    queryParam.source = new RunbookStepInputSource(`constant`, payloadQuery);
    notifyRunbookUpdate(true);
  };

  const setS3BucketValue = bucket => {
    setS3Bucket(bucket);
    parsePayload();
    const bucketParam = getParamInput("s3_bucket");
    bucketParam.source = new RunbookStepInputSource(`constant`, bucket);
    notifyRunbookUpdate(true);
  };

  useEffect(() => {
    if (runbookNode) {
      runbookNode.showHideWarning(!queryValue || !selectedAlias);
    }
  }, [runbookNode, queryValue, selectedAlias]);

  const parsePayload = () => {
    queryValue && setPayload(queryValue);
  };

  const setInitialParamValues = useRef(() => {});

  setInitialParamValues.current = () => {
    const alias = getParamInput("alias").source?.sourceValue;
    if (alias && alias.constructor.name === "String") {
      setSelectedAlias(alias);
    }

    const query = getParamInput("query").source?.sourceValue;
    if (query && query.constructor.name === "String") {
      setQueryValue(query.replace(/\\"/g, '"'));
    }

    const bucket = getParamInput("s3_bucket").source?.sourceValue;
    if (bucket && bucket.constructor.name === "String") {
      setS3Bucket(bucket);
    }
  };

  useEffect(() => {
    if (isFirstRun.current) {
      setInitialParamValues.current();
      isFirstRun.current = false;
    }
  }, []);

  return (
    <>
      <Accordion isExpanded={true} useArrow={true}>
        <AccordionLabel className="editor-accordion-label margin-top-10">
          Required Inputs
        </AccordionLabel>
        <AccordionSection>
          <div className="editor-detail-panel editor-detail-panel-column">
            <label className="label">alias</label>
            <ReactSelect
              id="container-alias"
              name="container-alias"
              value={{
                value: selectedAlias,
                label: selectedAlias || "Select from below",
              }}
              handleChange={data => {
                if (!!data?.value) {
                  setAlias(data.value);
                }
              }}
              selectOptions={aliasList.map(r => {
                return {
                  value: r.alias,
                  label: r.alias,
                };
              })}
              required
            />
          </div>
          <div className="editor-detail-panel editor-detail-panel-column">
            <label className="label">query</label>
            <TextArea
              value={queryValue}
              onKeyUp={value => {
                setQueryValue(value);
                setPayload(value);
              }}
              className={`compact-text-area p-10 w-auto ${
                !queryValue ? "rule-input-error" : ""
              }`}
            />
          </div>
        </AccordionSection>
      </Accordion>
      <Accordion isExpanded={true} useArrow={true}>
        <AccordionLabel className="editor-accordion-label mt-10">
          Optional Inputs
        </AccordionLabel>
        <AccordionSection>
          <div className="editor-detail-panel editor-detail-panel-column">
            <label className="label">s3_bucket</label>
            <TextInput
              name={"s3_buckets"}
              id={"s3_buckets"}
              className={"rule-input"}
              value={s3Bucket}
              onChange={newValue => {
                setS3BucketValue(newValue);
              }}
            />
          </div>
        </AccordionSection>
      </Accordion>
    </>
  );
};

export default SplunkSearchInputs;
