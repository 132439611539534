import React from "react";

const TerraformCLI_Step1 = require("@assets/images/screenshots/settings-terraform-cli/TerraformCLI_Step1.png");
export default () => (
  <>
    <div className="list-row">
      <div className="step-number">
        <div>1</div>
      </div>
      <div>
        First, go to “Settings” → “GitHub Repos” → “Manage Repos”. Click “Add
        New” to configure one or more GitHub accounts for the repositories where
        your Terraform modules are stored.
      </div>
    </div>
    <div className="list-image">
      <img
        src={TerraformCLI_Step1}
        alt="TerraformSettingsTokens"
        width="90%"
        height="350"
      />
    </div>
    <div className="list-row">
      <div className="step-number">
        <div>2</div>
      </div>
      <div>
        Use the form on the right hand side to configure the first Terraform
        module. Under “Module Id”, enter a name for the module that is easy for
        you to remember. Under “Repo Ids”, choose one that matches the GitHub
        repo where your Terraform module data is stored.
      </div>
    </div>
  </>
);
