import React from "react";
import {
  Accordion,
  AccordionLabel,
  AccordionSection,
  DropDown,
  DropDownOption,
  LabelTop,
} from "@components/ui";
import { Tab, TabCollection, TabContent } from "@components/ui/tabs";
import { Consumer } from "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.context";
import { RunbookStepInputSource } from "../../../../runbook-editor-lib/ssm/nodeinputoutput";
import Output from "./../output";
import Details from "./../details";
import { connect } from "react-redux";
import {
  getTerraformWorkspaces,
  updateMessage,
} from "@redux/actions/snippets.actions";

class TerraformPlanConnectorPanel extends React.Component {
  constructor(props) {
    super(props);
    let selectedInputs = {
      workspaceId: null,
    };
    // eslint-disable-next-line
    for (let item of props.activeNode.extras.runbookNode.parameterInputs) {
      if (typeof item.source.sourceValue === "string") {
        selectedInputs[item.name] = item.source.sourceValue;
      }
    }

    this.state = {
      autoApplyStatus: false,
      selectedInputs: selectedInputs,
    };
    this.terraformWorkspaces = [];
  }

  componentDidMount() {
    if (!this.props.terraformWorkspaces.length) {
      this.props.getTerraformWorkspaces().finally(e => {
        this.props.updateMessage();
      });
      this.props.updateMessage("Fetching Terraform workspaces");
    }
  }

  WorkSpaceSelected = (value, terraformWorkspaces, notifyRunbookUpdate) => {
    let paramInputs = this.props.activeNode.extras.runbookNode.parameterInputs;
    let workspaceInput = paramInputs.filter(
      item => item.name === "workspaceId",
    );
    workspaceInput[0].source = new RunbookStepInputSource(`constant`, value);

    let i, selectedWorkspace, autoApplyStatus;
    for (i in terraformWorkspaces) {
      if (value === terraformWorkspaces[i].id) {
        selectedWorkspace = terraformWorkspaces[i];
        if (terraformWorkspaces[i]["auto-apply"]) {
          autoApplyStatus = true;
        } else {
          autoApplyStatus = false;
        }
      }
    }
    this.setState({
      autoApplyStatus: autoApplyStatus,
      selectedWorkspace: selectedWorkspace,
      selectedInputs: {
        workspaceId: value,
      },
    });
    notifyRunbookUpdate(true);
  };

  onKeyUp = (value, id, notifyRunbookUpdate) => {
    console.log(this.props.activeNode.extras.runbookNode.parameterInputs);
    const input = this.props.activeNode.extras.runbookNode.parameterInputs.find(
      ele => (ele.name = "workspaceId"),
    );
    if (input) {
      console.log(JSON.stringify(input.snippetAction.ssm.inputs));
    }

    notifyRunbookUpdate(true);
  };

  render() {
    return (
      <Consumer>
        {({ notifyRunbookUpdate }) => {
          let terraformWorkspaces = this.props.terraformWorkspaces;
          return (
            <TabCollection
              active="input"
              activeClassName="editor-detail-tab-wrap editor-detail-active-tab"
              inactiveClassName="editor-detail-tab-wrap editor-detail-inactive-tab"
              contentTop={50}
            >
              <Tab title="Input" name="input">
                <TabContent>
                  <h4 className="editor-node-name">
                    {this.props.activeNode.name}
                  </h4>
                  <React.Fragment>
                    <Accordion isExpanded={true} useArrow={true}>
                      <AccordionLabel className="editor-accordion-label margin-top-10">
                        Required Inputs
                      </AccordionLabel>
                      <AccordionSection>
                      <div className="editor-detail-panel editor-detail-panel-column">
                        <div className="editor-terraform-container">
                          <LabelTop
                            label={`Workspace:`}
                            labelClassName="label"
                          >
                            <DropDown
                              showArrow={true}
                              iconLeft="icon-filter"
                              width="100%"
                              style={{ width: "calc(100% - 8px)", marginTop: "0px" }}
                              title={
                                terraformWorkspaces.length &&
                                this.state.selectedInputs.workspaceId
                                  ? terraformWorkspaces.filter(
                                      space =>
                                        space.id ===
                                        this.state.selectedInputs.workspaceId,
                                    )[0]?.name
                                  : "Select"
                              }
                              onChange={(text, value) => {
                                this.WorkSpaceSelected(
                                  value,
                                  terraformWorkspaces,
                                  notifyRunbookUpdate,
                                );
                              }}
                            >
                              {terraformWorkspaces.length &&
                                terraformWorkspaces.map(space => {
                                  return (
                                    <DropDownOption
                                      text={space.name}
                                      key={space.id}
                                      value={space.id}
                                    />
                                  );
                                })}
                            </DropDown>
                          </LabelTop>
                          <br />
                          {this.state.autoApplyStatus &&
                          terraformWorkspaces.length
                            ? `"${
                                terraformWorkspaces.filter(
                                  space =>
                                    space.id ===
                                    this.state.selectedInputs.workspaceId,
                                )[0]?.name
                              }" workspace settings are set to auto apply`
                            : ``}
                        </div>
                        </div>
                      </AccordionSection>
                    </Accordion>
                  </React.Fragment>
                </TabContent>
              </Tab>
              <Tab title="Output" name="output">
                <TabContent>
                  <Output />
                </TabContent>
              </Tab>
              <Tab title="Details" name="details">
                <TabContent>
                  <Details />
                </TabContent>
              </Tab>
            </TabCollection>
          );
        }}
      </Consumer>
    );
  }
}

const mapState = state => ({
  terraformWorkspaces: state.snippetsReducer.terraformWorkspaces,
});
const mapDispatch = dispatch => ({
  getTerraformWorkspaces: () => dispatch(getTerraformWorkspaces()),
  updateMessage: (message, showWait) =>
    dispatch(updateMessage(message, showWait)),
});

export default connect(mapState, mapDispatch)(TerraformPlanConnectorPanel);
