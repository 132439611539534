import React from "react";
import { generateId } from "@lib/utils";
import { RadioSet, Radio } from "@components/ui";

export default class RadioOptionsFilter extends React.PureComponent {
  state = {
    display: "none",
    id: generateId("filter-option"),
    selectedOption: this.props.selectedOption,
  };

  componentDidMount() {
    document.body.addEventListener("click", this.hideOptionFilter, true);
    this.setState({
      name: generateId("radio-options"),
    });
  }

  componentWillUnmount() {
    document.body.removeEventListener("click", this.hideOptionFilter, true);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedOption !== this.props.selectedOption) {
      this.setState({
        selectedOption: this.props.selectedOption,
      });
    }
    if (prevProps.optionFilterId !== this.props.optionFilterId) {
      this.setState({
        display: this.props.optionFilterId === this.state.id ? "block" : "none",
      });
    }
  }

  hideOptionFilter = () => {
    this.setState({
      display: "none",
    });
  };

  toggleDisplay = () => {
    this.setState({
      display: this.state.display === "block" ? "none" : "block",
    });
  };

  updateSelection = selection => {
    if (this.props.updateSelection) {
      this.props.updateSelection(selection);
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="editor-gear-icon-wrap">
          <div className="editor-gear-icon" onClick={this.toggleDisplay} />
        </div>
        <div style={{ display: this.state.display }}>
          <nav
            className="editor-dropdown editor-dropdown-list editor-dropdown-container"
            id="w-dropdown-toggle-9"
          >
            <RadioSet
              name={this.state.name || "radio"}
              className="label label-width"
              onChange={this.updateSelection}
              defaultSelected={
                this.props.isConditionalVariableInput
                  ? "actionNode"
                  : this.state.selectedOption || "userProvided"
              }
            >
              {this.props.isConditionalVariableInput ? (
                ""
              ) : (
                <>
                  <div className="editor-dropdown-link">
                    <Radio value="constant">
                      <div>Set value now</div>
                    </Radio>
                  </div>
                  <div className="editor-dropdown-link">
                    <Radio value="userProvided">
                      <div>Set value at runtime</div>
                    </Radio>
                  </div>
                </>
              )}

              <div className="editor-dropdown-link">
                <Radio value="actionNode">
                  <div>Set value from a previous step</div>
                </Radio>
              </div>
            </RadioSet>
          </nav>
        </div>
      </React.Fragment>
    );
  }
}
