import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toggleConfigPanel } from "@redux/actions/settingsPanel.action";
import { RouteConstants } from "../../../../routes/Constants";
import { kebabToCamel } from "@lib/utils";
class ConfigPanelHeader extends React.Component {
  render() {
    return (
      <div className="slide-panel__header">
        <h2>
          Manage{" "}
          {
            RouteConstants[
              kebabToCamel(this.props.currentNode?.name?.toLowerCase())
            ]?.displayName
          }{" "}
          Integration
        </h2>
        <button
          className="slide-panel__close"
          onClick={() => this.props.toggleConfigPanel(false)}
        >
          ✕
        </button>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  currentNode: state.settingsPanelReducer.currentNode,
});
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      toggleConfigPanel,
    },
    dispatch,
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(ConfigPanelHeader);
