import React from "react";
import { Accordion, AccordionLabel, AccordionSection } from "@components/ui";

const SetupInstructions = props => (
  <Accordion isExpanded={true} useArrow={true}>
    <AccordionLabel className="editor-accordion-label mt-10">
      Setup Instructions
    </AccordionLabel>
    <AccordionSection>{props.children}</AccordionSection>
  </Accordion>
);

export default SetupInstructions;
