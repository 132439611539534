import React from "react";
import { Accordion, AccordionLabel, AccordionSection } from "@components/ui";
import RunbookParameter from "./parameter";
import { parameterIsDisplayed } from "../../../../runbook-editor-lib/ssm/parameters";

export default function RunbookParameters({ runbookObj, notifyRunbookUpdate }) {
  let ssmDocParameters = runbookObj?.ssmDoc?.parameters?.parameters || {};

  return (
    <div>
      <Accordion isExpanded={true} useArrow={true}>
        <AccordionLabel className="editor-accordion-label">
          Parameters
        </AccordionLabel>
        <AccordionSection>
          {Object.keys(ssmDocParameters)
            .filter(param => parameterIsDisplayed(param))
            .map(param => (
              <RunbookParameter
                parameter={runbookObj.ssmDoc.parameters.parameters[param]}
                runbookObj={runbookObj}
                key={param}
                notifyRunbookUpdate={notifyRunbookUpdate}
              />
            ))}
        </AccordionSection>
      </Accordion>
    </div>
  );
}
