import React from "react";
import { Provider } from "./tabs.context";
import { PropTypes } from "prop-types";
import "./tabs.scss";

class TabCollection extends React.PureComponent {
  state = {
    active: this.props.active,
  };

  setActiveTab = tabName => {
    this.setState({ active: tabName });
    if (this.props.tabChange) {
      this.props.tabChange(tabName);
    }
  };

  render() {
    const contextValue = {
      handleChange: this.setActiveTab,
      active: this.state.active,
      activeClassName: this.props.activeClassName,
      inactiveClassName: this.props.inactiveClassName,
      contentTop: this.props.contentTop,
    };
    return (
      <div
        data-duration-in="300"
        data-duration-out="100"
        className={`${this.props.className} tabs-wrap w-tabs`}
      >
        <div className="tabs w-tab-menu">
          <Provider value={contextValue}>{this.props.children}</Provider>
        </div>
      </div>
    );
  }
}

TabCollection.propTypes = {
  activeClassName: PropTypes.string,
  inactiveClassName: PropTypes.string,
  contentTop: PropTypes.number,
  className: PropTypes.string,
};

TabCollection.defaultProps = {
  activeClassName:
    "tabs__nav-item-dark w-inline-block w-tab-link tabs__nav-item-dark-active",
  inactiveClassName:
    "tabs__nav-item-dark w-inline-block w-tab-link tabs__nav-item-dark-inactive",
  contentTop: 88,
};

export default TabCollection;
