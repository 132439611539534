import { connect } from "react-redux";
import {
  fetchSnippets,
  searchingSnippets,
  filterSnippetsByService,
  resetFilters,
} from "@redux/actions/snippets.actions";
import Snippets from "../Snippets";

const mapState = ({ snippetsReducer }) => ({
  showLoading: true,
  snippetsList: snippetsReducer.snippetsList,
  searchTerm: snippetsReducer.searchTerm,
  snippetDetailsList: snippetsReducer.snippetDetailsList || [],
  filteredServicesSet: snippetsReducer.filteredServicesSet || new Set(),
});

const mapDispatch = dispatch => ({
  fetchSnippets: () => dispatch(fetchSnippets("SNIPPET")),
  searchSnippets: val => dispatch(searchingSnippets(val)),
  resetFilters: () => dispatch(resetFilters()),
  filterSnippetsByService: service =>
    dispatch(filterSnippetsByService(service)),
});

export default connect(mapState, mapDispatch)(Snippets);
