import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useRef,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { Accordion, AccordionLabel, AccordionSection } from "@components/ui";
import { RunbookEditorContext } from "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.context";
import { RunbookStepInputSource } from "@containers/RunbookEditor/runbook-editor-lib/ssm/nodeinputoutput";
import { isEmpty } from "@lib/utils";
import ReactSelect from "@components/ui/React-Select/ReactSelect";
import { fetchCloudFormationTemplates } from "@redux/actions/settingsPanel.action";
import JSONEditor from "@components/ui/InputTypes/JSONEditor/JSONEditor";

type CloudFormationRunTemplateInputsProps = {};

const CloudFormationRunTemplateInputs: React.FC<CloudFormationRunTemplateInputsProps> = () => {
  const isFirstRun = useRef(true);
  const context = useContext(RunbookEditorContext) as any;
  const dispatch = useDispatch();

  const runbookNode = context.activeNode.extras.runbookNode;
  const notifyRunbookUpdate = context.notifyRunbookUpdate;
  const aliasList = context.resourcesList.targets;

  const cloudFormationTemplateList = useSelector(
    state => state.settingsPanelReducer.cloudFormationTemplateList,
  );

  const [selectedAlias, setSelectedAlias] = useState("");
  const [selectedTemplateId, setSelectedTemplateId] = useState("");

  const getParamInput = useCallback(
    (input: string) => runbookNode.parameterInputs.find(p => p.name === input),
    [runbookNode.parameterInputs],
  );

  const setInitialParamValues = useCallback(() => {
    const alias = getParamInput("alias").source?.sourceValue;
    if (alias && alias.constructor.name === "String") {
      setSelectedAlias(alias);
    }

    const templateId = getParamInput("template_id").source?.sourceValue;
    if (templateId && templateId.constructor.name === "String") {
      setSelectedTemplateId(templateId);
    }
  }, [getParamInput]);

  useEffect(() => {
    if (runbookNode) {
      runbookNode.showHideWarning(!selectedAlias || !selectedTemplateId);
    }
  }, [runbookNode, getParamInput, selectedAlias, selectedTemplateId]);

  useEffect(() => {
    if (isFirstRun.current) {
      dispatch(fetchCloudFormationTemplates());
      setInitialParamValues();
      isFirstRun.current = false;
    }
  }, [setInitialParamValues, dispatch]);

  const setPayload = (input, value) => {
    switch (input) {
      case "alias":
        let alias = getParamInput("alias");
        alias.source = new RunbookStepInputSource(`constant`, value);
        break;
      case "template_id":
        let templateId = getParamInput("template_id");
        templateId.source = new RunbookStepInputSource(`constant`, value);
        break;
      case "parameters":
        let parameters = getParamInput("parameters");
        parameters.source = new RunbookStepInputSource(`constant`, value);
        break;
    }
    notifyRunbookUpdate(true);
  };

  return (
    <Accordion isExpanded={true} useArrow={true}>
      <AccordionLabel className="editor-accordion-label margin-top-10">
        Required Inputs
      </AccordionLabel>
      <AccordionSection>
        <div className="cf">
          <div className="cf-input">
            <label className="cf-input-label">Select Alias</label>
            <ReactSelect
              id="cf-alias"
              name="cf-alias"
              value={{
                value: selectedAlias,
                label: selectedAlias ? selectedAlias : "Select from below",
              }}
              handleChange={data => {
                if (!isEmpty(data) && !!data.value) {
                  setSelectedAlias(data.value);
                  setPayload("alias", data.value);
                }
              }}
              selectOptions={aliasList.map(r => {
                return {
                  value: r.alias,
                  label: r.alias,
                };
              })}
              required
            />
          </div>
          {!!cloudFormationTemplateList && (
            <div className="cf-input">
              <label className="cf-input-label">Select Template</label>
              <ReactSelect
                id="cf-template-id"
                name="cf-template-id"
                value={{
                  value: selectedTemplateId,
                  label: selectedTemplateId
                    ? selectedTemplateId
                    : "Select from below",
                }}
                handleChange={data => {
                  if (!isEmpty(data) && !!data.value) {
                    setSelectedTemplateId(data.value);
                    setPayload("template_id", data.value);
                  }
                }}
                selectOptions={cloudFormationTemplateList.map(r => {
                  return {
                    value: r.templateId,
                    label: r.templateId,
                  };
                })}
                required
              />
            </div>
          )}
        </div>
      </AccordionSection>
      <AccordionLabel className="editor-accordion-label margin-top-10">
        Optional Inputs
      </AccordionLabel>
      <AccordionSection>
        <div className="cf-json">
          <JSONEditor
            title="parameters"
            editorTitle="parameters"
            value={
              getParamInput("parameters").source?.sourceValue
                ? JSON.stringify(
                    getParamInput("parameters").source?.sourceValue,
                    undefined,
                    4,
                  )
                : ""
            }
            displayContainerClassName="mb-15"
            onChange={value => {
              setPayload("parameters", value);
            }}
          />
        </div>
      </AccordionSection>
    </Accordion>
  );
};

export default CloudFormationRunTemplateInputs;
