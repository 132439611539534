import React from "react";
import PropTypes from "prop-types";
import { Select, SelectOption, LabelTop } from "@components/ui";
import FormInput from "../FormInput";

const InstanaWebhookKey = ({
  formik,
  apiKeyList,
  getSubtext,
  selectWebhookApiKeyName,
}) => {
  return (
    <>
      <div className="account-info__wrap">
        <LabelTop
          label={`Webhook API Key Name:`}
          labelClassName="label label__padding-left"
        >
          <Select
            value={formik.values.webhook_api_key_name}
            width="100%"
            onChange={(apiKey, name) =>
              selectWebhookApiKeyName(apiKey, name, formik.setFieldValue)
            }
            allowEmpty={false}
            searchMin={2}
            required={true}
            title={formik.values.webhook_api_key_name}
            titleClassname={
              !formik.values.webhook_api_key_name
                ? `formik-field-error`
                : `no-error`
            }
            optionHeight="35"
          >
            {(apiKeyList || []).map((apiKey, i) => (
              <SelectOption
                value={apiKey}
                text={apiKey.ApikeyName}
                key={`${apiKey.Value}`}
                className="text-small"
                animationBorder={"hover"}
              />
            ))}
          </Select>
        </LabelTop>
        {formik.errors["webhook_api_key_name"] &&
          formik.touched["webhook_api_key_name"] && (
            <div className="input-feedback">
              {formik.errors["webhook_api_key_name"]}
            </div>
          )}
      </div>
      {formik.values.webhook_api_key_name && (
        <FormInput
          fieldName="Webhook API Key Value:"
          showJson={true}
          name="webhook_api_key_value"
          id="webhook_api_key_value"
          handleChange={formik.handleChange}
          touched={formik.touched}
          errors={formik.errors}
          subText={getSubtext("webhook_api_key_value")}
          autoComplete="off"
          readOnly={true}
          isMasked={true}
        />
      )}
    </>
  );
};

InstanaWebhookKey.propTypes = {
  formik: PropTypes.object,
  apiKeyList: PropTypes.array,
  getSubtext: PropTypes.func,
  selectWebhookApiKeyName: PropTypes.func,
};

export default InstanaWebhookKey;
