import React from "react";
import getInstructions from "./utils/InstructionHelper";
import GetStarted from "@components/shared/GetStarted/GetStartedForm";

const SplunkSetup = () => {
  const Instructions = getInstructions();

  return <GetStarted instructionPanel={Instructions} />;
};

export default SplunkSetup;
