import React from "react";
import { connect } from "react-redux";
import * as OutputLib from "../../input-selector/lib/output.lib";
import { getType } from "../../input-selector/lib/input-selector.lib";
import { fetchAwsOutputs } from "@redux/actions/action-node.action";
import { fetchDatadogWebhookPayload } from "@redux/actions/snippets.actions.ts";
import { Switch } from "@components/ui";
import JsonToGrid from "../../input-selector/option-actions/from-previous-step/json-to-grid/JsonToGrid.js";
import { ControlNames } from "@containers/RunbookEditor/runbook-editor-lib/neuropssteps/strings";

const Loading = () => (
  <div className="centered-div margin-top-20">Loading outputs...</div>
);

class OutputModal extends React.PureComponent {
  state = {
    outputs: null,
    isLoading: true,
    outputComponent: "Loading",
    serviceOps: {},
  };

  componentDidMount() {
    console.log(this.props.activeNode);
    if (getType(this.props.activeNode) === ControlNames.DatadogAlert) {
      if (Object.keys(this.props.datadogWebhookPayloadData).length === 0) {
        this.props.fetchDatadogWebhookPayload();
      } else {
        this.setLoading(false);
        let payload = this.props.datadogWebhookPayloadData;
        if (typeof payload === "string") payload = JSON.parse(payload);
        this.setOutputs(payload);
      }
    } else {
      this.setServiceOps();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.checkForOutputs(prevProps);
    this.checkForComponentSwitch(prevState);
  }

  setServiceOps = () => {
    const serviceOps = OutputLib.getServiceOps(
      this.props.activeNode?.extras.runbookNode,
    );
    if (serviceOps) {
      this.setState({ serviceOps }, () => {
        const { service, operation } = serviceOps;
        OutputLib.fetchOutputsAndSetComponent.call(this, service, operation);
      });
    }
  };

  checkForOutputs = async prevProps => {
    if (prevProps.outputs !== this.props.outputs) {
      await this.setLoading(false);
      const { service, operation } = this.state.serviceOps;
      const key = `${service}-${operation}`;
      await this.setOutputs(this.props.outputs[key]);
    }
    if (
      prevProps.datadogWebhookPayloadData !==
      this.props.datadogWebhookPayloadData
    ) {
      await this.setLoading(false);
      let payload = this.props.datadogWebhookPayloadData;
      if (typeof payload === "string") payload = JSON.parse(payload);
      await this.setOutputs(payload);
    }
  };

  checkForComponentSwitch = prevState => {
    if (!this.state.isLoading && this.state.isLoading !== prevState.isLoading) {
      this.setComponent("Loaded");
    }
  };

  setLoading = isLoading => {
    return new Promise(resolve => {
      this.setState({ isLoading }, () => {
        resolve();
      });
    });
  };

  setOutputs = output => {
    return new Promise(resolve => {
      this.setState({ outputs: output, isLoading: false }, () => resolve(true));
    });
  };

  setComponent = outputComponent => {
    return new Promise(() => {
      if (this.state.outputComponent !== outputComponent) {
        this.setState({ outputComponent });
      }
    });
  };

  render() {
    return (
      <Switch value={this.state.outputComponent}>
        <Loading caseValue={"Loading"} />
        <JsonToGrid
          caseValue={"Loaded"}
          json={this.state.outputs}
          label={this.props.activeNode.name}
          updateOutput={this.updateOutput}
          leafOnclick={() => {}}
        />
      </Switch>
    );
  }
}

const mapState = ({ actionNodeReducer, snippetsReducer }) => ({
  outputs: actionNodeReducer.outputs,
  datadogWebhookPayloadData: snippetsReducer.datadogWebhookPayloadData,
});

const mapDispatch = dispatch => ({
  fetchAwsOutputs: (service, operation) =>
    dispatch(fetchAwsOutputs(service, operation)),
  fetchDatadogWebhookPayload: () => dispatch(fetchDatadogWebhookPayload()),
});

export default connect(mapState, mapDispatch)(OutputModal);
