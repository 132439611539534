import React from "react";

const DDIntegration = require("@assets/images/screenshots/settings-datadog/dd-integrations.png");

const DDApiKey = require("@assets/images/screenshots/settings-datadog/dd-api-key.png");

const SearchWebhookImage = require("@assets/images/screenshots/settings-datadog/search_webhook.png");

const AddNewImage = require("@assets/images/screenshots/settings-datadog/add_new.png");

const NewWebhookInfoImage = require("@assets/images/screenshots/settings-datadog/new_webhook_info.png");

const TeamsPage = require("@assets/images/screenshots/settings-datadog/dd-teams-page.png");

const DDAppKeyNew = require("@assets/images/screenshots/settings-datadog/dd-app-key-new.png");

export default () => (
  <>
    <h3 className="h2-margin">Instructions to setup Datadog Integration</h3>
    Follow below steps on your Datadog Account and complete the Steps
    <div className="list-row pt-10">
      <div className="step-number">
        <div>1</div>
      </div>
      <div>
        Login to your <b>Datadog Account</b> and go to <b>Integrations</b> page
      </div>
    </div>
    <div className="list-image">
      <img src={DDIntegration} alt="DDIntegration" />
    </div>
    <div className="list-row">
      <div className="step-number">
        <div>2</div>
      </div>
      <div>
        In the <b>"APIs tab"</b> under <b>API Keys</b> create a new{" "}
        <b>API Key</b> or Use an existing one. Enter the <b>API Key</b> in the{" "}
        <b>Authorize Fylamynt</b> tab --{`>`} <b>Datadog API Key</b> field on
        the right
      </div>
    </div>
    <div className="list-image">
      <img src={DDApiKey} alt="DDApiKey" />
    </div>
    <div className="list-row">
      <div className="step-number">
        <div>3</div>
      </div>
      <div>Go to the Team page.</div>
    </div>
    <div className="list-image">
      <img src={TeamsPage} alt="TeamsPage" />
    </div>
    <div className="list-row">
      <div className="step-number">
        <div>4</div>
      </div>
      <div>
        In the <b>Application Keys</b> tab, create a new Application Key or Use
        an existing one. Enter the Application Key in the Authorize Fylamynt tab{" "}
        {`-->`} Datadog Application Key field on the right
      </div>
    </div>
    <div className="list-image">
      <img src={DDAppKeyNew} alt="DDAppKeyNew" />
    </div>
    <div className="list-row">
      <div className="step-number">
        <div>5</div>
      </div>
      <div>
        In the <b>"Integrations tab"</b> search for the Installed{" "}
        <b>"Webhook"</b>
      </div>
    </div>
    <div className="list-image">
      <img
        src={SearchWebhookImage}
        alt="SearchWebhookImage"
        className="pt-10"
      />
    </div>
    <div className="list-row pt-10">
      <div className="step-number">
        <div>6</div>
      </div>
      <div>
        Click <b>+ New</b> button to create a new webhook.
      </div>
    </div>
    <div className="list-image">
      <img src={AddNewImage} alt="AddNewImage" />
    </div>
    <div className="list-row">
      <div className="step-number">
        <div>7</div>
      </div>
      <div>
        Complete “New Webhook” panel using the following details,
        <ul>
          <li>
            Copy <strong>Datadog Webhook Name</strong> from{" "}
            <strong>Authorize Fylamynt</strong> as the <strong>Name</strong> of
            the New Webhook
          </li>
          <li>
            Copy <strong>Datadog Webhook URL</strong> from{" "}
            <strong>Authorize Fylamynt</strong> as the <strong>URL</strong> of
            the New Webhook
          </li>
          <li>
            Add <strong>"alert_id": "$ALERT_ID"</strong> in the JSON object for
            Payload. Feel free to add or remove any other fields.
          </li>
          <li>
            Check the <strong>Custom Headers</strong> box in Datadog panel.
            Then, populate the JSON below and enter it there.
          </li>
          <code>
            <pre>
              {JSON.stringify(
                {
                  "x-api-key": "<fylamynt-api-key>",

                  "fylamynt-external-id": "<fylamynt-external-id>",

                  "Content-Type": "application/json",
                },
                null,
                4,
              )}
            </pre>
          </code>
          <strong>fylamynt-api-key</strong>
          <br />
          <br />
          Under <strong>Authorize Fylamynt</strong>, choose one of the{" "}
          <strong>Webhook API Key </strong> Name from the dropdown, and the
          corresponding <strong>Webhook API Key Value </strong>should be shown.
          If no options are available for the Webhook API Key Name, first go to
          Settings --&gt; API Keys --&gt; Manage Keys to Create a New API Key.
          Then, copy-paste the Webhook API Key Value as Value.
          <br />
          <br />
          <strong>fylamynt-external-id</strong>
          <br /> <br />
          From <strong>Authorize Fylamynt</strong>, copy-paste the{" "}
          <strong>External ID</strong> as Value.
          <br /> <br />
          <div className="list-image">
            <img src={NewWebhookInfoImage} alt="NewWebhookInfoImage" />
          </div>
          <br /> <br />
          <li>
            Click <strong>Save</strong> to complete webhook creation
          </li>
        </ul>
      </div>
    </div>
    <div className="list-row">
      <div className="step-number">
        <div>8</div>
      </div>
      <div>
        Click on the <strong>Authorize</strong> button to complete the
        integration between Fylamynt and Datadog
      </div>
    </div>
  </>
);
