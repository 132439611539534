import React from "react";
import { Formik } from "formik";
import "./ManageGitRepo.scss";
import FormInput from "../settings-panel-components/SettingsRightConfigPanel/FormInput";
import { getValidationSchema } from "./utils/ValidationSchema";
import { Modal, Button, Wait, FTNotification } from "@components/ui";
import { useDispatch, useSelector } from "react-redux";
import { isFetching } from "@redux/actions/settingsPanel.action";
import Api from "@lib/api";
import { mapData, mapPayload } from "./utils/helper";
import { addGitRepo, updateGitRepo } from "@redux/actions/gitRepo.actions";

type ManageGitRepoProps = {
  selectedRepo?: any;
  closeForm: (flag: boolean) => void;
  mode: string;
  repoList: Array<object>;
};

const ManageGitRepo: React.FC<ManageGitRepoProps> = ({
  selectedRepo,
  mode,
  closeForm,
  repoList,
  ...props
}) => {
  const dispatch = useDispatch();
  const loadingMessage = useSelector(state => state.runbooksReducer.message);
  const initialValues = selectedRepo;
  const validationSchema = getValidationSchema(mode, repoList);

  const getFooter = handleSubmit => {
    return (
      <div className="modal-buttons-footer">
        <Button
          text="Cancel"
          buttonStyle="secondary"
          style={{ width: "50%" }}
          size="large"
          type="button"
          onClick={() => closeForm(false)}
        />
        <Button
          text={
            mode === "create" ? "Save GitHub Account" : "Update GitHub Account"
          }
          style={{ width: "50%" }}
          buttonStyle="primary"
          size="large"
          onClick={handleSubmit}
        />
      </div>
    );
  };

  const onSubmitHandler = async (formik, actions) => {
    actions.setSubmitting(true);
    dispatch(isFetching(true, "Saving GitHub Account"));
    const payload = mapPayload(mapData(formik));
    try {
      await Api.saveGitRepo(payload, formik.name);
      if (mode === "create") {
        dispatch(addGitRepo(mapData(formik)));
        FTNotification.success({
          title: "GitHub Account Saved Successfully!",
        });
      } else {
        dispatch(updateGitRepo(mapData(formik)));
        FTNotification.success({
          title: "GitHub Account Updated Successfully!",
        });
      }
    } catch (error) {
      if (mode === "create") {
        FTNotification.error({
          title: "Could not save GitHub Account",
          message: error.message,
        });
      } else {
        FTNotification.error({
          title: "Could not update GitHub Account",
          message: error.message,
        });
      }
    }
    dispatch(isFetching(false, ""));
    actions.setSubmitting(false);
    closeForm(false);
  };

  return (
    <>
      {loadingMessage && <Wait text={loadingMessage} />}
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmitHandler}
        validationSchema={validationSchema}
      >
        {formik => {
          const { touched, errors, handleChange, handleSubmit } = formik;
          return (
            <Modal
              onClose={() => {
                closeForm(false);
              }}
              title="Add GitHub Account"
              footer={getFooter(handleSubmit)}
              showClose={true}
            >
              <div className="form-item__mb-l">
                <FormInput
                  fieldName="Repo Id"
                  name="name"
                  id="name"
                  handleChange={handleChange}
                  touched={touched}
                  errors={errors}
                  readOnly={mode === "edit"}
                />
              </div>
              <div className="form-item form-item__margin-bottom form-item__mb-l">
                <label htmlFor="sshKey" className="label label__padding-left">
                  SSH Key:
                </label>
                <div className="field-wrapper">
                  <textarea
                    className={`text-area w-input ${
                      formik.errors.sshKey &&
                      formik.touched.sshKey &&
                      "text-area-error"
                    }`}
                    id="sshKey"
                    name="sshKey"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.sshKey}
                    cols={50}
                    rows={4}
                  />
                </div>
                {formik.errors.sshKey && formik.touched.sshKey ? (
                  <div className="input-feedback">{formik.errors.sshKey}</div>
                ) : null}
              </div>
              <FormInput
                fieldName="Access Token"
                name="accessToken"
                id="accessToken"
                handleChange={handleChange}
                touched={touched}
                errors={errors}
                readOnly={false}
              />
              <FormInput
                fieldName="URL"
                name="url"
                id="url"
                handleChange={handleChange}
                touched={touched}
                errors={errors}
                readOnly={false}
              />
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};

export default ManageGitRepo;
