import React from "react";
import { DataTable } from "@components/ui";
import { Consumer } from "../../executions-lib/executions.context";
import moment from "moment";
import { columns } from "./table-header-columns";

/**
 *  add duration to data for sorting
 */
function addDurationDataForSorting(filteredExecutionsData) {
  for (const row of filteredExecutionsData) {
    let start = moment(row.ExecutionStartTime);
    let end = moment(row.ExecutionEndTime);
    row["ExecutionDuration"] = end.diff(start);
  }
  return filteredExecutionsData;
}
export default props => {
  return (
    <Consumer>
      {({ filteredExecutionsData }) => (
        <React.Fragment>
          {filteredExecutionsData && (
            <div className="width-full">
              <DataTable
                columns={columns}
                data={addDurationDataForSorting(filteredExecutionsData)}
                className="width-full"
                fixedHeader={true}
                fixedScrollHeight={"60px"}
                pagination={true}
              />
            </div>
          )}
        </React.Fragment>
      )}
    </Consumer>
  );
};
