import React, { useState } from "react";
import SnippetControlsList from "./snippets/SnippetControlsList";
import SnippetSnippetsList from "./snippets/SnippetSnippetsList";
import SnippetConnectorsList from "./snippets/SnippetConnectorsList";
import CustomSnippetsList from "./snippets/CustomSnippetsList";
import SnippetTriggersList from "./snippets/SnippetTriggersList";
import clearIcon from "@assets/images/icons/icon-close.svg";

const EditorAddSnippets = () => {
  const [searchVal, setSearchVal] = useState("");
  return (
    <div className="editor-menu-tab-content-panel">
      <div className="editor-search-container">
        <input
          type="text"
          className="editor-search-input"
          value={searchVal}
          placeholder="Search Snippet"
          onChange={e => setSearchVal(e.target.value)}
          autoFocus
        />
        {!!searchVal && (
          <img
            onClick={() => setSearchVal("")}
            src={clearIcon}
            alt="clearIcon"
            className="editor-search-clear"
            width="10"
            height="10"
          />
        )}
      </div>

      <div className="editor-menu-tab-content-area">
        <CustomSnippetsList searchVal={searchVal} />
        <SnippetTriggersList searchVal={searchVal} />
        <SnippetConnectorsList searchVal={searchVal} />
        <SnippetControlsList searchVal={searchVal} />
        <SnippetSnippetsList searchVal={searchVal} />
      </div>
    </div>
  );
};
export default EditorAddSnippets;
