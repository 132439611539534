function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React from "react";

var _ref2 = React.createElement("title", null, "icon color");

var _ref3 = React.createElement("desc", null, "Created with Sketch.");

var _ref4 = React.createElement("defs", null, React.createElement("path", {
  d: "M14,13.295 L10.225,9.5 C12.0996648,7.2380802 11.8657415,3.90219848 9.69374842,1.92404398 C7.52175532,-0.0541105278 4.17859243,0.0239205462 2.10125649,2.10125649 C0.0239205462,4.17859243 -0.0541105278,7.52175532 1.92404398,9.69374842 C3.90219848,11.8657415 7.2380802,12.0996648 9.5,10.225 L13.295,14 L14,13.295 Z M1.5,6 C1.5,3.51471863 3.51471863,1.5 6,1.5 C8.48528137,1.5 10.5,3.51471863 10.5,6 C10.5,8.48528137 8.48528137,10.5 6,10.5 C3.51471863,10.5 1.5,8.48528137 1.5,6 Z",
  id: "path-search"
}));

var _ref5 = React.createElement("g", {
  id: "Page-1",
  stroke: "none",
  strokeWidth: 1,
  fill: "none",
  fillRule: "evenodd"
}, React.createElement("g", {
  id: "Artboard",
  transform: "translate(-457.000000, -190.000000)"
}, React.createElement("g", {
  id: "icon/image/search/16",
  transform: "translate(457.000000, 190.000000)"
}, React.createElement("mask", {
  id: "mask-2",
  fill: "white"
}, React.createElement("use", {
  xlinkHref: "#path-search"
})), React.createElement("use", {
  id: "icon-color",
  fill: "#F3F3F3",
  xlinkHref: "#path-search"
}))));

var SvgIconSearch = function SvgIconSearch(_ref) {
  var svgRef = _ref.svgRef,
      title = _ref.title,
      props = _objectWithoutProperties(_ref, ["svgRef", "title"]);

  return React.createElement("svg", _extends({
    width: "14px",
    height: "14px",
    viewBox: "0 0 14 14",
    ref: svgRef
  }, props), title === undefined ? _ref2 : title ? React.createElement("title", null, title) : null, _ref3, _ref4, _ref5);
};

var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(SvgIconSearch, _extends({
    svgRef: ref
  }, props));
});
export default __webpack_public_path__ + "static/media/icon-search.17b8737c.svg";
export { ForwardRef as ReactComponent };