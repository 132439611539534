import React from "react";
import { Consumer } from "../../../../../runbook-editor-lib/runbook-editor.context";
import { Accordion, AccordionLabel, AccordionSection } from "@components/ui";
import SnippetControl from "./SnippetControl";
import flatMap from "lodash/flatMap";
import {
  getFilteredSnippetList,
  getBlacklistConnectorList,
  addBucketName,
  addIsDisconnectedAttribute,
} from "@lib/utils";
type SnippetTriggersListProps = {
  searchVal?: string;
};

const SnippetTriggersList: React.FC<SnippetTriggersListProps> = ({
  searchVal,
}) => {
  const getSnippetTriggers = (snippets, blacklistConnectorList) => {
    let snippetList = { ...snippets };
    let snippetTriggers = { ...snippetList?.TRIGGER };

    let snippetTriggerList = addIsDisconnectedAttribute(
      snippetTriggers,
      blacklistConnectorList,
    );
    return snippetTriggerList;
  };

  return (
    <Consumer>
      {({ snippets, connectors }) => {
        const blacklistConnectorList = getBlacklistConnectorList(connectors);
        let snippetTriggers = getSnippetTriggers(
          snippets,
          blacklistConnectorList,
        );
        const category = "TRIGGER";
        const flatTriggerList = flatMap(Object.values(snippetTriggers));
        const finalTriggerList = getFilteredSnippetList(
          addBucketName(flatTriggerList, `${category} STRUCTURES`),
          searchVal,
        );
        return (
          finalTriggerList.length > 0 &&
          snippets.hasOwnProperty("TRIGGER") && (
            <Accordion isExpanded={true} useArrow={true} key={category}>
              <AccordionLabel className="editor-accordion-label">
                TRIGGER ACTIONS
              </AccordionLabel>
              <AccordionSection>
                <div className="snippet-block">
                  {finalTriggerList.map(snippet => (
                    <SnippetControl snippet={snippet} key={snippet.id} />
                  ))}
                </div>
              </AccordionSection>
            </Accordion>
          )
        );
      }}
    </Consumer>
  );
};

export default SnippetTriggersList;
