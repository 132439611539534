import React from "react";
import { useLocation } from "react-router-dom";
import "./UserProfile.scss";
import { ProfileHeader } from "./utils/Header";
import ProfileInfo from "./utils/ProfileInfo";
import ChangePassword from "./utils/ChangePassword";
import AccountPlan from "./utils/AccountPlan";
import { TabCollection, Tab, TabContent } from "@components/ui/tabs";

const UserProfile: React.FC = () => {
  const location = useLocation();
  const activeTab =
    location?.state?.activeTab === "account-plan"
      ? "account-plan"
      : "information";

  return (
    <>
      <div className="d-flex flex-column2">
        <ProfileHeader />
        <br />
        <br />
        <TabCollection active={activeTab} className={`tabs-menu-border`}>
          <Tab title={`Information`} name={`information`}>
            <TabContent>
              <ProfileInfo />
            </TabContent>
          </Tab>
          <Tab title={`Password`} name={`password`}>
            <TabContent>
              <ChangePassword />
            </TabContent>
          </Tab>
          <Tab title={`Account Plan`} name={`account-plan`}>
            <TabContent>
              <AccountPlan />
            </TabContent>
          </Tab>
        </TabCollection>
      </div>
    </>
  );
};

export default UserProfile;
