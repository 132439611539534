function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React from "react";

var _ref2 = React.createElement("desc", null, "Created with Sketch.");

var _ref3 = React.createElement("defs", null, React.createElement("polygon", {
  id: "path-13",
  points: "5.5 4.5 5.5 0.5 4.5 0.5 4.5 4.5 0.5 4.5 0.5 5.5 4.5 5.5 4.5 9.5 5.5 9.5 5.5 5.5 9.5 5.5 9.5 4.5"
}));

var _ref4 = React.createElement("g", {
  id: "icon-plus",
  stroke: "none",
  strokeWidth: 1,
  fill: "none",
  fillRule: "evenodd"
}, React.createElement("g", {
  id: "Artboard-Copy-34"
}, React.createElement("g", {
  id: "icon/navigation/add/16"
}, React.createElement("mask", {
  id: "mask-2",
  fill: "white"
}, React.createElement("use", {
  xlinkHref: "#path-13"
})), React.createElement("use", {
  id: "icon-color",
  fill: "#FFFFFF",
  xlinkHref: "#path-13"
}))));

var SvgIconPlus = function SvgIconPlus(_ref) {
  var svgRef = _ref.svgRef,
      title = _ref.title,
      props = _objectWithoutProperties(_ref, ["svgRef", "title"]);

  return React.createElement("svg", _extends({
    width: "10px",
    height: "10px",
    viewBox: "0 0 10 10",
    ref: svgRef
  }, props), title ? React.createElement("title", null, title) : null, _ref2, _ref3, _ref4);
};

var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(SvgIconPlus, _extends({
    svgRef: ref
  }, props));
});
export default __webpack_public_path__ + "static/media/icon-plus.9c444612.svg";
export { ForwardRef as ReactComponent };