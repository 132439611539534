import React, { useEffect, useState, useContext, useCallback } from "react";
import "./WebhookInputs.scss";
import { Accordion, AccordionLabel, AccordionSection } from "@components/ui";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Select, SelectOption, LabelTop } from "@components/ui";
import { fetchAPIKeys } from "@redux/actions/apikeys.actions";
import { useDispatch, useSelector } from "react-redux";
import copyIcon from "@assets/images/icons/icon-copy.svg";
import IconEye from "@components/ui/icons/icon-eye";
import IconEyeCancelled from "@components/ui/icons/icon-eye-cancelled";
import { RunbookEditorContext } from "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.context";
import { RunbookStepInputSource } from "@containers/RunbookEditor/runbook-editor-lib/ssm/nodeinputoutput";
import { getApiServerUrl } from "@lib/utils";
import { Link } from "react-router-dom";

type WebhookInputProps = {};

const WebhookInputs: React.FC<WebhookInputProps> = () => {
  const context = useContext(RunbookEditorContext) as any;
  const runbookNode = context.activeNode.extras.runbookNode;
  const notifyRunbookUpdate = context.notifyRunbookUpdate;
  const dispatch = useDispatch();
  const [webhookApiValueType, setWebhookApiValueType] = useState("password");
  const [url, setUrl] = useState("");
  const [apiCount, setApiCount] = useState(0);
  const [showApiError, setShowApiError] = useState(false);

  const getParamInput = useCallback(
    (input: string) =>
      runbookNode.parameterInputs.filter(p => p.name === input),
    [runbookNode.parameterInputs],
  );

  const prepareUrl = useCallback(
    name => {
      const origin = getApiServerUrl();
      let initPath = origin.split("/");
      initPath.pop();
      setUrl(initPath.join("/") + "/webhooks/run_workflow/" + name);
    },
    [setUrl],
  );

  const webhookApiKeys = useSelector(state => state.APIKeysReducer.apiKeyList);

  const setInitialParamValues = useCallback(() => {
    const apiKeyName = getParamInput("api_key_name")[0].source?.sourceValue;
    const ApikeyName =
      apiKeyName?.constructor.name === "String" ? apiKeyName : "";
    const apiKeyValue = webhookApiKeys.find(k => k.ApikeyName === ApikeyName);
    const Value = !!apiKeyValue && apiKeyValue.Value ? apiKeyValue.Value : "";

    setSelectedWebhookInputs({
      ApikeyName,
      Value,
    });
  }, [getParamInput, webhookApiKeys]);
  const [selectedWebhookInputs, setSelectedWebhookInputs] = useState({
    ApikeyName: "",
    Value: "",
  });

  useEffect(() => {
    /**
     * Fetching Webhook API Keys
     * on Component Mount if already connected
     */

    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (!webhookApiKeys.length) {
      if (apiCount === 0) {
        setShowApiError(false);
        dispatch(fetchAPIKeys());
        setApiCount(apiCount + 1);
      } else {
        setShowApiError(true);
      }
    } else {
      setShowApiError(false);
    }
    if (webhookApiKeys.length > 0) {
      setInitialParamValues();
    }
  }, [dispatch, webhookApiKeys.length, setInitialParamValues, apiCount]);

  useEffect(() => {
    if (runbookNode)
      runbookNode.showHideWarning(!selectedWebhookInputs.ApikeyName);
  }, [runbookNode, selectedWebhookInputs]);

  useEffect(() => {
    prepareUrl(context.runbook.Name);
  }, [context.runbook.Name, prepareUrl]);

  const selectWebhookApiKeyName = apiKey => {
    const ApikeyName = apiKey ? apiKey.ApikeyName : "";
    const Value = apiKey ? apiKey.Value : "";
    setSelectedWebhookInputs({
      ApikeyName,
      Value,
    });
    let webhookApiKeyName = getParamInput("api_key_name");
    webhookApiKeyName[0].source = new RunbookStepInputSource(
      `constant`,
      ApikeyName,
    );

    notifyRunbookUpdate(true);
  };
  return (
    <>
      <Accordion isExpanded={true} useArrow={true}>
        <AccordionLabel className="editor-accordion-label margin-top-10">
          Required Inputs
        </AccordionLabel>
        <AccordionSection>
          <div className="d-flex flex-column2">
            <div className="webhook-input">
              <div className="webhook-copy-container">
                <label className="webhook-input-label">URL</label>
              </div>
              <div className="copy-wrap">
                <input
                  name={"url"}
                  value={url}
                  className="webhook-input-container"
                  id={"url"}
                  maxLength={256}
                  required={true}
                  readOnly
                />
                <CopyToClipboard
                  text={url}
                  onCopy={() => console.log("URL copied")}
                >
                  <div className="copy-icon-container">
                    <img
                      src={copyIcon}
                      alt="invisibleIcon"
                      className="mr-5 pointer"
                      width="14"
                      height="14"
                    />
                  </div>
                </CopyToClipboard>
              </div>
            </div>
            <div className="webhook-input">
              {showApiError ? (
                <label className="label">
                  Please set up API Key &nbsp;
                  <Link className="settings-link" to="/settings/api-keys">
                    here.
                  </Link>
                </label>
              ) : (
                <LabelTop
                  label={`Webhook API Key Name:`}
                  labelClassName="webhook-input-label"
                >
                  <Select
                    value={selectedWebhookInputs.ApikeyName}
                    width="100%"
                    onChange={apiKey => selectWebhookApiKeyName(apiKey)}
                    allowEmpty={false}
                    searchMin={2}
                    required={true}
                    title={
                      selectedWebhookInputs.ApikeyName
                        ? selectedWebhookInputs.ApikeyName
                        : "Select from below"
                    }
                    titleClassname={
                      !selectedWebhookInputs.ApikeyName
                        ? `formik-field-error`
                        : `no-error`
                    }
                    optionHeight="32"
                  >
                    {(webhookApiKeys || []).map((apiKey, i) => (
                      <SelectOption
                        value={apiKey}
                        text={apiKey.ApikeyName}
                        key={`${apiKey.Value}`}
                        className="text-small"
                        animationBorder={"hover"}
                      />
                    ))}
                  </Select>
                </LabelTop>
              )}
            </div>

            {selectedWebhookInputs.ApikeyName && (
              <div className="webhook-input">
                <div className="webhook-copy-container">
                  <label className="webhook-input-label">
                    Webhook API Key Value
                  </label>
                </div>
                <div className="copy-wrap">
                  <input
                    name={"webhookApiKeyValue"}
                    value={selectedWebhookInputs.Value}
                    id={"webhookApiKeyValue"}
                    className={`webhook-input-container mask-input ${
                      webhookApiValueType === "password" && "small-font-size"
                    }`}
                    maxLength={256}
                    required={true}
                    type={webhookApiValueType}
                    readOnly
                  />
                  {webhookApiValueType === "text" ? (
                    <div className="input-eye-icon-wrap">
                      <IconEye
                        className="input-eye-icon"
                        handleClick={() => setWebhookApiValueType("password")}
                        height="14"
                        width="14"
                      />
                    </div>
                  ) : (
                    <div className="input-eye-icon-wrap">
                      <IconEyeCancelled
                        className="input-eye-icon"
                        handleClick={() => setWebhookApiValueType("text")}
                        height="14"
                        width="14"
                      />
                    </div>
                  )}
                  <CopyToClipboard
                    text={selectedWebhookInputs.Value}
                    onCopy={() => console.log("Webhook API Key Value copied")}
                  >
                    <div className="copy-icon-container">
                      <img
                        src={copyIcon}
                        alt="invisibleIcon"
                        className="mr-5 pointer"
                        width="14"
                        height="14"
                      />
                    </div>
                  </CopyToClipboard>
                </div>
              </div>
            )}
          </div>
        </AccordionSection>
      </Accordion>
    </>
  );
};

export default WebhookInputs;
