import React from "react";
import { Consumer } from "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.context";
import OutputActionNode from "./OutputActionNode";
import OutputNonActionNode from "./OutputNonAction";
import { Switch } from "@components/ui";

export default () => {
  return (
    <Consumer>
      {({ activeNode }) => {
        return (
          <Switch value={activeNode.extras.runbookNode.stepType}>
            <OutputActionNode
              caseValue="ActionNodeStep|DatadogConnectorStep"
              activeNode={activeNode}
            />
            <OutputNonActionNode caseValue="default" activeNode={activeNode} />
            {/*
            //this was showing a modal of output coming from datadog snippet-def
            // can be used with other static output
            <OutputStatic caseValue="" activeNode={activeNode} />
            */}
          </Switch>
        );
      }}
    </Consumer>
  );
};
