import React, { useEffect, useState, useContext, useRef } from "react";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionLabel,
  AccordionSection,
  TextInput,
} from "@components/ui";
import { RunbookEditorContext } from "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.context";
import { RunbookStepInputSource } from "@containers/RunbookEditor/runbook-editor-lib/ssm/nodeinputoutput";
import ReactSelect from "@components/ui/React-Select/ReactSelect";
import { sortResourceList } from "@lib/utils";
import Api from "@lib/api";
import Bugsnag from "@bugsnag/js";

type RequiredInputsProps = {};

const RequiredInputs: React.FC<RequiredInputsProps> = () => {
  const isFirstRun = useRef(true);

  const context = useContext(RunbookEditorContext) as any;
  const runbookNode = context.activeNode.extras.runbookNode;
  const notifyRunbookUpdate = context.notifyRunbookUpdate;
  const aliasList = sortResourceList(context.resourcesList.targets);

  const [selectedAlias, setSelectedAlias] = useState("");
  const [logBucket, setLogBucket] = useState("");
  const [stackValue, setStackValue] = useState("");

  // Semi-Optional Inputs
  const [selectedGitRepo, setSelectedGitRepo] = useState("");
  const [selectedS3Bucket, setSelectedS3Bucket] = useState("");
  const [gitRepoList, setGitRepoList] = useState([]);
  const [s3BucketList, setS3BucketList] = useState([]);
  const [isLoadingGitRepo, setIsLoadingGitRepo] = useState(true);
  const [isLoadingS3BucketList, setIsLoadingS3BucketList] = useState(true);
  const [selectedOption, setSelectedOption] = useState("");
  const [showGitRepoError, setShowGitRepoError] = useState(false);
  const [showS3BucketError, setShowS3BucketError] = useState(false);

  const optionList = [
    { value: "git_repo", label: "Git Repo" },
    { value: "s3_bucket", label: "S3 Bucket" },
  ];

  // Fetch data from backend (Semi-Optional Inputs)
  const getGitRepo = useRef(() => {});
  getGitRepo.current = async () => {
    setIsLoadingGitRepo(true);
    setShowGitRepoError(false);
    try {
      const data = await Api.getGitRepoIds();
      const parsedData = JSON.parse(data.body);
      setIsLoadingGitRepo(false);
      setGitRepoList([...parsedData]);
      if (!parsedData.length) {
        setShowGitRepoError(true);
      }
    } catch (error) {
      setIsLoadingGitRepo(false);
      setShowGitRepoError(true);
      Bugsnag.notify(new Error(error?.message));
    }
  };

  const getS3Bucket = useRef(() => {});
  getS3Bucket.current = async () => {
    setIsLoadingS3BucketList(true);
    setShowS3BucketError(false);
    try {
      const data = await Api.fetchSelectedS3BucketList(selectedAlias);
      const parsedData = JSON.parse(data.body);
      setIsLoadingS3BucketList(false);
      setS3BucketList([...parsedData]);
      if (!parsedData.length) {
        setShowS3BucketError(true);
      }
    } catch (error) {
      setIsLoadingS3BucketList(false);
      setShowS3BucketError(true);
      Bugsnag.notify(new Error(error?.message));
    }
  };

  const getParamInput = (input: string) =>
    runbookNode.parameterInputs.find(p => p.name === input);

  const setPayload = (input, param) => {
    const paramValue = getParamInput(param);
    if (paramValue) {
      paramValue.source = new RunbookStepInputSource(`constant`, input);
      notifyRunbookUpdate(true);
    }
  };

  const setAliasUpdate = (alias: string) => {
    setSelectedAlias(alias);
    setPayload(alias, "alias");
  };

  const setLogBucketUpdate = (input: string) => {
    setLogBucket(input);
    setPayload(input, "log_bucket");
  };

  const setStackUpdate = (input: string) => {
    setStackValue(input);
    setPayload(input, "stack");
  };

  // Runbook update functions (Semi-Optional Inputs)
  const setGitRepoUpdate = (input: string) => {
    setSelectedGitRepo(input);
    setPayload(input, "git_repo");
  };

  const setS3BucketUpdate = (input: string) => {
    setSelectedS3Bucket(input);
    setPayload(input, "s3_bucket");
  };

  const removeRunbookUpdates = (input: string) => {
    let inputParameter = getParamInput(input);
    if (inputParameter) {
      inputParameter.source.sourceValue = null;
      notifyRunbookUpdate(true);
    }
    // Clear all dependent state variables as well to maintain consistency
    if (input === "git_repo") {
      setSelectedGitRepo("");
    }
    if (input === "s3_bucket") {
      setSelectedS3Bucket("");
    }
  };

  const setInitialParamValues = useRef(() => {});

  setInitialParamValues.current = () => {
    const alias = getParamInput("alias").source?.sourceValue;
    if (alias && alias.constructor.name === "String") {
      setSelectedAlias(alias);
    }

    const logBucketVal = getParamInput("log_bucket").source?.sourceValue;
    if (logBucketVal && logBucketVal.constructor.name === "String") {
      setLogBucket(logBucketVal);
    }

    const stackVal = getParamInput("stack").source?.sourceValue;
    if (stackVal && stackVal.constructor.name === "String") {
      setStackValue(stackVal);
    }

    const gitRepoVal = getParamInput("git_repo").source?.sourceValue;
    if (gitRepoVal && gitRepoVal.constructor.name === "String") {
      setSelectedOption("git_repo");
      setSelectedGitRepo(gitRepoVal);
    }

    const s3BucketVal = getParamInput("s3_bucket").source?.sourceValue;
    if (s3BucketVal && s3BucketVal.constructor.name === "String") {
      setSelectedOption("s3_bucket");
      setSelectedS3Bucket(s3BucketVal);
    }
  };

  useEffect(() => {
    getGitRepo.current();
    getS3Bucket.current();
  }, []);

  useEffect(() => {
    if (runbookNode) {
      let showError =
        !selectedAlias ||
        !logBucket ||
        !stackValue ||
        !selectedOption ||
        (selectedOption === `git_repo` && showGitRepoError) ||
        (selectedOption === `s3_bucket` && showS3BucketError) ||
        !(selectedOption && (selectedGitRepo || selectedS3Bucket));

      runbookNode.showHideWarning(showError);
    }
  }, [
    runbookNode,
    selectedOption,
    selectedAlias,
    logBucket,
    stackValue,
    selectedGitRepo,
    selectedS3Bucket,
    showGitRepoError,
    showS3BucketError,
  ]);

  useEffect(() => {
    if (isFirstRun.current) {
      setInitialParamValues.current();
      isFirstRun.current = false;
    }
  }, []);

  useEffect(() => {
    // Refresh S3 buckets
    getS3Bucket.current();
  }, [selectedAlias]);

  const setChoice = choice => {
    optionList.forEach(option => {
      if (option.value !== choice) {
        removeRunbookUpdates(option.value);
      }
    });
    setSelectedOption(choice);
  };

  const getContent = (input: string) => {
    if (!input) return;

    if (input === "git_repo") {
      return isLoadingGitRepo ? (
        <div className="editor-detail-panel editor-detail-panel-column">
          <label className="label">Loading...</label>
        </div>
      ) : !showGitRepoError ? (
        <div className="editor-detail-panel editor-detail-panel-column">
          <label className="label">git_repo</label>
          <ReactSelect
            id="optional-input-gitrepo"
            name="optional-input-gitrepo"
            value={{
              value: selectedGitRepo,
              label: selectedGitRepo || "Select from below",
            }}
            handleChange={data => {
              if (!!data?.value) {
                setGitRepoUpdate(data.value);
              }
            }}
            selectOptions={gitRepoList.map(repo => {
              return {
                value: repo,
                label: repo,
              };
            })}
            required
          />
        </div>
      ) : (
        <div className="editor-detail-panel editor-detail-panel-column">
          <label className="label">
            Please set up Git Repos &nbsp;
            <Link className="settings-link" to="/settings/git-settings">
              here.
            </Link>
          </label>
        </div>
      );
    }
    if (input === "s3_bucket") {
      if (!selectedAlias) {
        return (
          <div className="editor-detail-panel editor-detail-panel-column">
            <label className="label">Please select alias field first!</label>
          </div>
        );
      }
      return isLoadingS3BucketList ? (
        <div className="editor-detail-panel editor-detail-panel-column">
          <label className="label">Loading...</label>
        </div>
      ) : !showS3BucketError ? (
        <div className="editor-detail-panel editor-detail-panel-column">
          <label className="label">s3_bucket</label>
          <ReactSelect
            id="optional-input-s3bukcet"
            name="optional-input-s3bucket"
            value={{
              value: selectedS3Bucket,
              label: selectedS3Bucket || "Select from below",
            }}
            handleChange={data => {
              if (!!data.value) {
                setS3BucketUpdate(data.value);
              }
            }}
            selectOptions={s3BucketList.map(bucket => {
              return {
                value: bucket.Name,
                label: bucket.Name,
              };
            })}
            required
          />
        </div>
      ) : (
        <div className="editor-detail-panel editor-detail-panel-column">
          <label className="label">
            Please set up S3 Buckets &nbsp;
            <Link className="settings-link" to="/settings/s3-buckets">
              here.
            </Link>
          </label>
        </div>
      );
    }
  };

  return (
    <Accordion isExpanded={true} useArrow={true}>
      <AccordionLabel className="editor-accordion-label mt-10">
        Required Inputs
      </AccordionLabel>
      <AccordionSection>
        <div className="editor-detail-panel editor-detail-panel-column">
          <label className="label">alias</label>
          <ReactSelect
            id="container-alias"
            name="container-alias"
            value={{
              value: selectedAlias,
              label: selectedAlias || "Select from below",
            }}
            handleChange={data => {
              if (!!data?.value) {
                setAliasUpdate(data.value);
              }
            }}
            selectOptions={aliasList.map(r => {
              return {
                value: r.alias,
                label: r.alias,
              };
            })}
            required
          />
        </div>
        <div className="editor-detail-panel editor-detail-panel-column">
          <label className="label">log_bucket</label>
          <TextInput
            name={`log_bucket`}
            value={logBucket}
            onKeyUp={value => {
              setLogBucket(value);
              setLogBucketUpdate(value);
            }}
            className={`rule-input p-10 w-auto ${
              !logBucket ? "rule-input-error" : ""
            }`}
            required
          />
        </div>
        <div className="editor-detail-panel editor-detail-panel-column">
          <label className="label">stack</label>
          <TextInput
            name={`stack`}
            value={stackValue}
            onKeyUp={value => {
              setStackValue(value);
              setStackUpdate(value);
            }}
            className={`rule-input p-10 w-auto ${
              !stackValue ? "rule-input-error" : ""
            }`}
            required
          />
        </div>

        <div className="editor-detail-panel editor-detail-panel-column">
          <label className="label">Select Input</label>
          <ReactSelect
            id="select-input"
            name="select-input"
            value={{
              value: selectedOption,
              label: selectedOption || "Select from below",
            }}
            handleChange={data => {
              if (!!data?.value) {
                setChoice(data.value);
              }
            }}
            selectOptions={optionList.map(option => {
              return {
                value: option.value,
                label: option.label,
              };
            })}
            required
          />
        </div>
        {selectedOption && getContent(selectedOption)}
      </AccordionSection>
    </Accordion>
  );
};

export default RequiredInputs;
