import { setContainerBorder } from "./select-multiple.lib";

export function onChange(checked, value) {
  console.log("Item was checked?: ", checked, " value was ", value);
  const values = this.state.values;
  const index = this.state.values.indexOf(value);
  if (checked) {
    addToValues.call(this, value);
  } else {
    values.splice(index, 1);
    this.setState(
      {
        values,
      },
      () => {
        if (this.props.onChange) {
          this.props.onChange(this.state.values);
        }
      },
    );
  }
}

export function addToValues(value) {
  const index = this.state.values.indexOf(value);
  const values = this.state.values;
  if (index < 0) {
    values.push(value);
    this.setState(
      {
        values,
      },
      () => {
        if (this.props.onChange) {
          this.props.onChange(this.state.values);
        }
      },
    );
  }
}

export function deselectAll() {
  this.setState(
    {
      values: [],
      forceDeselect: new Date().getTime(),
    },
    () => {
      if (this.props.onChange) {
        this.props.onChange(this.state.values);
      }
    },
  );
}

export async function onTitleClick(e) {
  const expand = !this.state.expanded;
  addRemoveScroll.bind(this);
  await this.setState(state => ({
    expanded: expand,
  }));
  await addRemoveScroll.call(this, 500);
  setContainerBorder.call(this);
}

function addRemoveScroll(timeToWait) {
  const scrollableElement = this.ref.current.querySelector(".select-menu");
  const addClass = this.state.expanded ? "expanded-overflow" : "";
  const removeClass = this.state.expanded ? "" : "expanded-overflow";
  return new Promise(resolve => {
    window.setTimeout(() => {
      if (addClass) {
        scrollableElement.classList.add(addClass);
      } else {
        scrollableElement.classList.remove(removeClass);
      }
    }, timeToWait);
    resolve();
  });
}

async function close(e) {
  if (!this.state.expanded) {
    return;
  }
  const expand = !this.state.expanded;
  addRemoveScroll.bind(this);
  await this.setState(state => ({
    expanded: expand,
  }));
  await addRemoveScroll.call(this, 500);
  setContainerBorder.call(this);
}

export function setWindowOnclick(e) {
  this.ref.current.addEventListener(
    "click",
    e => {
      if (e.target.classList.contains("multi")) {
        return;
      }
      e.cancelBubble = true;
      this.onTitleClick();
    },
    true,
  );

  document.body.addEventListener(
    "click",
    e => {
      close.call(this, e);
    },
    false,
  );
}
