import { ParameterSet } from "../ssm/parameters";
import { Step } from "../ssm/steps";
import { InvokeLambdaStep } from "../ssm/invokelambdastep";
import { SnippetStep } from "../neuropssteps/snippetstep";
import { SSMDocument } from "../ssm/ssmdocument";
import { SSMActionNames } from "../ssm/strings";
import { StepTypeChecker } from "../neuropssteps/steptypechecker";
import { BranchStep } from "../ssm/branchstep";
import {
  ConditionalStep,
  NeurOpsCustomConditionalStep,
} from "../neuropssteps/conditionalstep";
import { NeurOpsRunbook } from "./runbook";
import { WaitStep, NeurOpsStatusStep } from "../neuropssteps/waitstep";
import { SleepStep } from "../ssm/sleepstep";
import { isEmpty } from "@lib/utils";
import { ActionNodeStep } from "../neuropssteps/actionnodestep";
import { PauseStep } from "../ssm/pausestep";
import { DatadogConnectorStep } from "../neuropssteps/DatadogConnectorStep";
import SlackConnectorStep from "../neuropssteps/slackConnectorStep";
import JiraConnectorStep from "./../neuropssteps/jiraConnectorStep";
import { LoopStep } from "../neuropssteps/loopstep";
import { WaitForResourceStep } from "../neuropssteps/waitforresourcestep";
import InstanaStep from "../neuropssteps/InstanaStep";
import JSONPathStep from "../neuropssteps/JSONPathStep";
import TwilioConnectorStep from "../neuropssteps/TwilioConnectorStep";
import SplunkSearchConnectorStep from "../neuropssteps/SplunkSearchConnectorStep";
import JenkinsBuildStep from "../neuropssteps/JenkinsBuildStep";
import TriggerStep from "../neuropssteps/TriggerStep";

// SSM Document reader

export class RunbookReader {
  constructor(snippetLibrary) {
    this.snippetLibrary = snippetLibrary;
    this.typeChecker = new StepTypeChecker(snippetLibrary);
  }

  readAPIResponse(apiResponse) {
    const body =
      typeof apiResponse.Content === "string"
        ? JSON.parse(apiResponse.Content)
        : apiResponse.Content;
    const ssmdoc = this.readDocument(body, apiResponse.Tags);
    return new NeurOpsRunbook(
      ssmdoc,
      apiResponse.Name,
      apiResponse.Tags,
      apiResponse.DocumentVersion,
    );
  }

  readDocument(ssm, tags) {
    if (isEmpty(ssm)) {
      return;
    }
    const { description } = ssm;
    const parameters = new ParameterSet(ssm.parameters);
    const mainSteps = ssm.mainSteps
      .map(step => this.readStep(step, tags))
      .filter(step => !!step);
    return new SSMDocument(description, parameters, mainSteps);
  }

  readStep(stepJSON) {
    StepTypeChecker.assertIsSSMStep(stepJSON);

    if (this.lastLoopStep && StepTypeChecker.isLoopSupportStep(stepJSON)) {
      addSupportStepToLoop(this.lastLoopStep, stepJSON);
      return null;
    } else {
      this.lastLoopStep = undefined;
    }
    switch (stepJSON.action) {
      case SSMActionNames.INVOKE_LAMBDA:
        if (this.typeChecker.isSnippetStep(stepJSON)) {
          return new SnippetStep(
            stepJSON,
            this.snippetLibrary.getSnippetDefinitionForStepID(stepJSON.name),
          );
        } else if (StepTypeChecker.isActionNodeStep(stepJSON)) {
          return new ActionNodeStep(
            stepJSON,
            this.snippetLibrary.getSnippetDefinitionForActionNode(),
          );
        } else if (StepTypeChecker.isLoopStep(stepJSON)) {
          const loopStep = new LoopStep(
            stepJSON,
            this.snippetLibrary.getSnippetDefinitionForLoop(),
          );
          this.lastLoopStep = loopStep;
          return loopStep;
        } else if (StepTypeChecker.isSlackConnectorStep(stepJSON)) {
          return new SlackConnectorStep(
            stepJSON,
            this.snippetLibrary.getSnippetDefinitionForStepID(stepJSON.name),
          );
        } else if (
          StepTypeChecker.isJiraConnectorStep(stepJSON) ||
          StepTypeChecker.isJiraExistingIssueStep(stepJSON)
        ) {
          return new JiraConnectorStep(
            stepJSON,
            this.snippetLibrary.getSnippetDefinitionForStepID(stepJSON.name),
          );
        } else if (StepTypeChecker.isInstanaAlertStep(stepJSON)) {
          return new InstanaStep(
            stepJSON,
            this.snippetLibrary.getSnippetDefinitionForStepID(stepJSON.name),
          );
        } else if (StepTypeChecker.isDatadogConnectorStep(stepJSON)) {
          return new DatadogConnectorStep(
            stepJSON,
            this.snippetLibrary.getSnippetDefinitionForStepID(stepJSON.name),
          );
        } else if (StepTypeChecker.isJSONPathStep(stepJSON)) {
          return new JSONPathStep(
            stepJSON,
            this.snippetLibrary.getSnippetDefinitionForStepID(stepJSON.name),
          );
        } else if (StepTypeChecker.isTwilioConnector(stepJSON)) {
          return new TwilioConnectorStep(
            stepJSON,
            this.snippetLibrary.getSnippetDefinitionForStepID(stepJSON.name),
          );
        } else if (StepTypeChecker.isSplunkSearchStep(stepJSON)) {
          return new SplunkSearchConnectorStep(
            stepJSON,
            this.snippetLibrary.getSnippetDefinitionForStepID(stepJSON.name),
          );
        } else if (this.typeChecker.isTriggerStep(stepJSON)) {
          return new TriggerStep(
            stepJSON,
            this.snippetLibrary.getSnippetDefinitionForStepID(stepJSON.name),
          );
        } else if (StepTypeChecker.isJenkinsBuildStep(stepJSON)) {
          return new JenkinsBuildStep(
            stepJSON,
            this.snippetLibrary.getSnippetDefinitionForStepID(stepJSON.name),
          );
        }

        return new InvokeLambdaStep(stepJSON);
      case SSMActionNames.BRANCH:
        if (this.typeChecker.isConditionalStep(stepJSON)) {
          if (this.typeChecker.isNeurOpsCustomConditionalStep(stepJSON)) {
            return new NeurOpsCustomConditionalStep(
              stepJSON,
              this.snippetLibrary.getSnippetDefinitionForStepID(stepJSON.name),
            );
          }
          return new ConditionalStep(
            stepJSON,
            this.snippetLibrary.getSnippetDefinitionForStepID(stepJSON.name),
          );
        }
        return new BranchStep(stepJSON);
      case SSMActionNames.SLEEP:
        if (this.typeChecker.isNeurOpsStatusStep(stepJSON)) {
          return new NeurOpsStatusStep(
            stepJSON,
            this.snippetLibrary.getSnippetDefinitionForStepID(stepJSON.name),
          );
        }
        if (this.typeChecker.isWaitStep(stepJSON)) {
          return new WaitStep(
            stepJSON,
            this.snippetLibrary.getSnippetDefinitionForStepID(stepJSON.name),
          );
        }
        return new SleepStep(stepJSON);
      case SSMActionNames.PAUSE:
        return new PauseStep(stepJSON);
      case SSMActionNames.EXECUTE_STATE_MACHINE:
        if (StepTypeChecker.isWaitForResourceStep(stepJSON)) {
          return new WaitForResourceStep(
            stepJSON,
            this.snippetLibrary.waitForResourceStepSnippetDefinition(),
          );
        } else {
          return new Step(stepJSON);
        }
      case SSMActionNames.EXECUTE_AWS_API:
      default:
        return new Step(stepJSON);
    }
  }
}

function addSupportStepToLoop(loopStep, supportStep) {
  // precondition - loop support steps immediately follow
  // the loop step
  if (/^([\w-]*)LoopPost[Pp]rocess*$/.test(supportStep.name)) {
    loopStep.nextStep = supportStep.nextStep;
  }
}
