import React from "react";
import "./CloudWarningCard.scss";
import { Link } from "react-router-dom";
import warningSign from "@assets/images/icons/warning-triangle.svg";
import addSign from "@assets/images/snippets/icon-plus.svg";


type CloudWarningCardProps = {};

const CloudWarningCard:React.FC<CloudWarningCardProps> = () => {
    return (
        <div className="cloud-wc">
            <div className="cloud-wc-icon">
                <img
                  src={warningSign}
                  alt="warningSign"
                  width="20"
                  height="20"
                />
            </div>
            <div className="cloud-wc-content">
                <h4 className="cloud-wc-content-header">Set Up AWS&nbsp;Target Account</h4>
                <div className="cloud-wc-content-text">To use Fylamynt, you'll need to create a new role in your AWS&nbsp;account. <br/>We'll walk you through the process with a few options.</div>
                <Link to="/settings/get-started" className="cloud-wc-content-btn">
                    <span>Add AWS Account</span>
                    <img
                        src={addSign}
                        alt="addSign"
                        width="15"
                        height="15"
                    />
                </Link>
            </div>
        </div>
    );
}

export default CloudWarningCard;
