import React, { useState } from "react";
import "./StringMap.scss";
import TextInputView from "../Layouts/TextInputView/TextInputView";
import AddButton from "../Layouts/AddButton/AddButton";
import { ManageKeyValue } from "../Layouts/ManageKeyValue/ManageKeyValue";
import { isEmpty } from "@lib/utils";
import { InputResult } from "../Layouts/Result/InputResult";

type StringMapProps = {
  title?: string;
  stringMap: object;
  onChange?: (value) => void;
};

const StringMap: React.FC<StringMapProps> = ({
  title = "StringMap",
  stringMap,
  onChange,
}) => {
  const [stringMapObj, setStringMapObj] = useState(stringMap);
  const [isManageKeyValueOpen, setIsManageKeyValueOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedKey, setSelectedKey] = useState(null);

  const toggleIsManageKeyValueOpen = () => {
    setIsManageKeyValueOpen(!isManageKeyValueOpen);
  };

  /**
   * Delete a key from stringMapObj
   * @param {object} key - key object to be deleted
   */
  const onDelete = key => {
    let updatedStringMapObj = { ...stringMapObj };
    delete updatedStringMapObj[key];
    setStringMapObj(updatedStringMapObj);
    onChange(JSON.stringify(updatedStringMapObj));
  };

  /**
   * Called when "+ Add to StringMap" is clicked
   */
  const onAddKey = () => {
    setIsEditMode(false);
    setSelectedKey(null);
    toggleIsManageKeyValueOpen();
  };

  /**
   * Called when "Edit key" is clicked
   * @param {object} key - key object to be edited
   */
  const onEditKey = key => {
    setIsEditMode(true);
    setSelectedKey({ inputKey: key, inputValue: stringMapObj[key] });
    toggleIsManageKeyValueOpen();
  };

  /**
   * Close the key/value popup
   */
  const onCancel = () => {
    toggleIsManageKeyValueOpen();
  };

  /**
   * Edit/Add a key ({"Key": "abc", "Value": "xyz"})
   * in stringMapObj
   * @param {object} key - edited or newly created key object
   * @return {void}
   */
  const onSave = ({ inputKey, inputValue }) => {
    let updatedStringMapObj = { ...stringMapObj };
    updatedStringMapObj[inputKey] = inputValue;
    setStringMapObj(updatedStringMapObj);
    toggleIsManageKeyValueOpen();
    onChange(JSON.stringify(updatedStringMapObj));
  };

  return (
    <>
      <div className={`sm-header ${!isEmpty(stringMapObj) && "mb-15"}`}>
        <label>{title}</label>
      </div>
      <div className="sm-container">
        {!isEmpty(stringMapObj) &&
          Object.keys(stringMapObj).map((key, idx) => {
            return (
              <TextInputView
                key={idx}
                value={`"${key}": "${stringMapObj[key]}"`}
                onEdit={() => onEditKey(key)}
                onDelete={() => onDelete(key)}
              />
            );
          })}
      </div>
      {isManageKeyValueOpen && (
        <ManageKeyValue
          isEdit={isEditMode}
          selectedInput={selectedKey}
          onCancel={onCancel}
          onSave={value => onSave(value)}
          allowDuplicateKey={false}
          list={Object.keys(stringMapObj)}
          type="StringMap"
        />
      )}
      <AddButton title={`Add To StringMap`} handleClick={onAddKey} />
      <InputResult
        displayValue={
          !isEmpty(stringMapObj) ? JSON.stringify(stringMapObj) : ""
        }
      />
    </>
  );
};

export default StringMap;
