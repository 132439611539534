import React from "react";
import { Consumer } from "../execution-details-lib/execution-details.context";
import { objectLength } from "@lib/utils";

export default class Inputs extends React.Component {
  render() {
    return (
      <Consumer>
        {({ executionDetails }) => (
          <div className="margin-top-20">
            {executionDetails && objectLength(executionDetails.Outputs) ? (
              <div className="content-text">
                This execution uses the following output parameters:
                <div className="margin-top-20">
                  {Object.keys(executionDetails.Outputs).map(key => {
                    const arr = executionDetails.Outputs[key];
                    return (
                      <div key={key} className="table-col-2">
                        <div className="table-col-left">{key}</div>
                        <div className="table-col-right">
                          {arr.map((item, index) => (
                            <div key={`item-${index}`}>{item}</div>
                          ))}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div className="content-text">
                <div className="ml-15">This execution has no output parameters</div>
              </div>
            )}
          </div>
        )}
      </Consumer>
    );
  }
}
