import React from "react";

export default () => (
  <>
    <h3 className="h2-margin">Instructions to setup Splunk Integration</h3>
    Follow below steps on your Splunk Account and complete the Steps
    <div className="list-row pt-10">
      <div className="step-number">
        <div>1</div>
      </div>
      <div>
        Enter the URL to your Splunk Cloud instance in <b>Splunk URL</b> field
        in the right panel. This should be in the format{" "}
        <b>
          <i>{`<yoursubdomain>`}.splunkcloud.com</i>
        </b>
        . Please do not add{" "}
        <b>
          <i>https://</i>
        </b>{" "}
        or{" "}
        <b>
          <i>http://</i>
        </b>{" "}
        at the beginning.
      </div>
    </div>
    <div className="list-row">
      <div className="step-number">
        <div>2</div>
      </div>
      <div>
        Generate an access token by following the instruction in{" "}
        <a
          className="instruction-link"
          href="https://docs.splunk.com/Documentation/SplunkCloud/8.1.2012/Security/CreateAuthTokens"
        >
          https://docs.splunk.com/Documentation/SplunkCloud/8.1.2012/Security/CreateAuthTokens.
        </a>
        {` `}Enter the access token in <b>Access Token</b> field in the right
        panel.
      </div>
    </div>
    <div className="list-row">
      <div className="step-number">
        <div>3</div>
      </div>
      <div>
        Click <b>Authorize</b> button to complete integration.
      </div>
    </div>
  </>
);
