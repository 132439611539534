import React from "react";
import { connect } from "react-redux";
import { fetchAwsOutputs } from "@redux/actions/action-node.action";
import { fetchDatadogWebhookPayload } from "@redux/actions/snippets.actions.ts";
import JsonToGrid from "./json-to-grid/JsonToGrid";
import { SET_SELECTED_OUTPUT } from "@redux/types";
import { ParameterType } from "@containers/RunbookEditor/runbook-editor-lib/ssm/strings";
import {
  ActionNodeInputSource,
  fixTypeName,
} from "@containers/RunbookEditor/runbook-editor-lib/ssm/nodeinputoutput";
import { Switch } from "@components/ui";
import ConfigureOutput from "./ConfigureOutput";
import { Provider } from "./output-configure.context";
import * as OutputLib from "../../lib/output.lib";
import { hasKeys } from "@lib/utils";
import { getType } from "../../../input-selector/lib/input-selector.lib";
import { ControlNames } from "@containers/RunbookEditor/runbook-editor-lib/neuropssteps/strings";

/*
  selectedOutput is whatever the user has clicked on in this modal window,
*/

const Loading = () => (
  <div className="centered-div margin-top-20">Loading outputs...</div>
);

class ModalSelectOutput extends React.PureComponent {
  state = {
    outputs: null,
    outputComponent: "Loading",
    isLoading: true,
    outputString: "",
    outputType: ParameterType.StringList,
    outputArray: [],
  };

  constructor(props) {
    super(props);
    this.outputString = "";
  }

  componentDidMount() {
    if (getType(this.props.sourceStep) === ControlNames.DatadogAlert) {
      if (Object.keys(this.props.datadogWebhookPayloadData).length === 0) {
        this.props.fetchDatadogWebhookPayload();
      } else {
        this.setLoading(false);
        let payload = this.props.datadogWebhookPayloadData;
        if (typeof payload === "string") payload = JSON.parse(payload);
        this.setOutputs(payload);
      }
    } else {
      this.setServiceOps();
    }
  }

  setServiceOps() {
    const serviceOps = OutputLib.getServiceOps(
      this.props.sourceStep?.extras.runbookNode,
    );
    OutputLib.fetchOutputsAndSetComponent.call(
      this,
      serviceOps.service,
      serviceOps.operation,
    );
    //this.props.updateSelectedOutput();
  }

  componentDidUpdate(prevProps, prevState) {
    this.checkForOutputs(prevProps);
    this.checkForComponentSwitch(prevState);
  }

  checkForOutputs = async prevProps => {
    if (prevProps.outputs !== this.props.outputs) {
      await this.setLoading(false);
      const { service, operation } = OutputLib.getServiceOps.call(
        this,
        this.props.sourceStep.extras.runbookNode,
      );
      const key = `${service}-${operation}`;
      await this.setOutputs(this.props.outputs[key]);
    }

    if (
      prevProps.datadogWebhookPayloadData !==
      this.props.datadogWebhookPayloadData
    ) {
      await this.setLoading(false);
      let payload = this.props.datadogWebhookPayloadData;
      if (typeof payload === "string") payload = JSON.parse(payload);
      await this.setOutputs(payload);
    }
  };

  setComponent = outputComponent => {
    return new Promise(() => {
      if (this.state.outputComponent !== outputComponent) {
        this.setState({ outputComponent });
      }
    });
  };

  checkForComponentSwitch = prevState => {
    if (!this.state.isLoading && this.state.isLoading !== prevState.isLoading) {
      this.setComponent("JsonTree");
    }
  };

  setLoading = isLoading => {
    return new Promise(resolve => {
      this.setState({ isLoading }, () => {
        resolve();
      });
    });
  };

  setOutputs = output => {
    return new Promise(resolve => {
      this.setState({ outputs: output, isLoading: false }, () => resolve(true));
    });
  };

  updateRunbookState = value => {
    const {
      consumingInput,
      sourceStep,
      updateHandler,
      onlyCaptureSelector,
    } = this.props;
    const { runbookNode } = sourceStep.extras;

    if (updateHandler) {
      updateHandler(value, sourceStep);
    }
    if (onlyCaptureSelector) {
      return;
    }
    const requestedType = consumingInput.type;
    let outputType = ParameterType.StringList;
    // if value does not looks like $.output.instances[*].id
    if (value.match(/\[\*\]/g) === null) {
      outputType = fixTypeName(this.state.outputType);
    } else {
      // We are getting an array back and StringList is our only
      // possible array type.  Conversions will need to be done in the back end.
      // console.log(`not reseting type`, {
      //   value,
      //   state: this.state,
      //   match: value.match(/\[.*\]/g),
      // });
    }

    const source = new ActionNodeInputSource(
      sourceStep.extras.runbookNode,
      value,
      requestedType,
    );
    source.sourceValue.originalType = outputType;
    // console.log(`creating new action node source`, { source });

    const oldSource = consumingInput.source;
    if (!runbookNode.outputs) {
      runbookNode.outputs = [];
    }
    runbookNode.outputs.push(source.sourceValue);
    consumingInput.source = source;
    //now remove oldSource if it isn't consumed
    if (
      hasKeys(oldSource, "oldSource.sourceValue.isConsumed") &&
      oldSource.sourceValue.isConsumed &&
      !oldSource.sourceValue.isConsumed()
    ) {
      runbookNode.outputs = runbookNode.outputs.filter(output => {
        return output !== oldSource.sourceValue;
      });
    }
  };

  initializeSelelctedOutput = (value, type) => {
    this.setState(
      {
        outputString: value,
        outputType: type,
        outputArray: OutputLib.outputToArray.call(this, value),
      },
      () => {
        this.outputString = value;
        this.setComponent("ConfigureOutput");
      },
    );
  };

  saveAndClose = () => {
    this.props.toggleModal();
    this.updateRunbookState(this.outputString);
    this.props.updateSelectedOutput(this.outputString);
  };

  render() {
    const contextValue = {
      updateOutput: (key, value) =>
        OutputLib.updateOutput.call(this, key, value),
      saveOutput: this.saveAndClose,
      returnToTree: () => this.setComponent("JsonTree"),
    };
    return (
      <Provider value={contextValue}>
        <Switch value={this.state.outputComponent}>
          <Loading caseValue={"Loading"} />
          <JsonToGrid
            json={this.state.outputs}
            seedValue="$.output"
            leafOnclick={this.initializeSelelctedOutput}
            caseValue="JsonTree"
            label={this.props}
            updateOutput={this.updateOutput}
          />
          <ConfigureOutput
            caseValue="ConfigureOutput"
            outputString={this.state.outputString}
            outputArray={this.state.outputArray}
          />
        </Switch>
      </Provider>
    );
  }
}

const mapState = ({ actionNodeReducer, snippetsReducer }) => ({
  outputs: actionNodeReducer.outputs,
  datadogWebhookPayloadData: snippetsReducer.datadogWebhookPayloadData,
});

const mapDispatch = dispatch => ({
  fetchAwsOutputs: (service, operation) =>
    dispatch(fetchAwsOutputs(service, operation)),
  updateSelectedOutput: output => {
    dispatch({
      type: SET_SELECTED_OUTPUT,
      payload: output,
    });
  },
  fetchDatadogWebhookPayload: () => dispatch(fetchDatadogWebhookPayload()),
});

export default connect(mapState, mapDispatch)(ModalSelectOutput);
