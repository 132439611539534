import React from "react";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import { Route } from "react-router";
import RequireAuthentication from "@components/authentication/requireAuthentication";
import * as utils from "@lib/utils";
import "@scss/app.scss";
import UserProfile from "@components/authentication/UserProfile/UserProfile";
import ForgotPassword from "@components/authentication/ForgotPassword/ForgotPassword";
import Login from "@components/authentication/Login/Login";
import Signup from "@components/authentication/Signup/Signup";
import TerraformCLISetup from "@containers/SettingsPanel/ConnectorSetup/TerraformCLI/TerraformCLISetup";
import EditTerraformCLI from "@containers/SettingsPanel/ConnectorSetup/TerraformCLI/EditTerraformCLI";
import AmplifyConfig from "@components/AmplifyConfig/index";
import Runbooks from "@containers/Runbooks/runbooks-lib/runbooks.connect";
import RunbookDetails from "@containers/RunbookDetails/runbook-details-lib/runbook-details.connect";
import RunbookEditor from "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.connect";
import Executions from "@containers/Executions/executions-lib/executions.connect";
import ExecutionsRun from "@containers/ExecutionsRun/executions-run-lib/executions-run.connect";
import ExecutionDetails from "@containers/ExecutionDetails/execution-details-lib/execution-details.connect";
import ExecutionRunDetails from "@containers/ExecutionRunDetails/execution-run-details-lib/exeuction-run-details.connect";
import SettingsPanel from "@containers/SettingsPanel/SettingsPanel";
import Schedules from "@containers/Schedules/schedules-lib/schedules.connect";
import AWSSettings from "@containers/SettingsPanel/AWSSettings";
import Snippets from "@containers/Snippets/snippets-lib/snippets.connect";
import SnippetDetails from "@containers/SnippetDetails/snippet-details-lib/snippet-details.connect";
import SettingsAccountInfo from "@containers/SettingsPanel/SettingsAccountInfo";
import GetStarted from "@containers/GetStarted/GetStarted";
import AppInfo from "@containers/AppInfo/AppInfo";
import JiraSetup from "@containers/SettingsPanel/ConnectorSetup/JIRA/JiraSetup";
import TerraformSetup from "@containers/SettingsPanel/ConnectorSetup/Terraform/TerraformSetup";
import SlackSetup from "@containers/SettingsPanel/ConnectorSetup/Slack/SlackSetup";
import DatadogSetup from "@containers/SettingsPanel/ConnectorSetup/Datadog/DatadogSetup";
import APIKeys from "@containers/SettingsPanel/APIKeys";
import InstanaSetup from "@containers/SettingsPanel/ConnectorSetup/Instana/InstanaSetup";
import PagerDutySetup from "@containers/SettingsPanel/ConnectorSetup/PagerDuty/PagerDutySetup";
import AnsibleSetup from "@containers/SettingsPanel/ConnectorSetup/Ansible/AnsibleSetup";
import GitRepos from "@containers/SettingsPanel/GIT/GITRepos";
import SlackAuth from "@containers/SettingsPanel/ConnectorSetup/Slack/SlackAuth";
import TwilioSetup from "@containers/SettingsPanel/ConnectorSetup/Twilio/TwilioSetup";
import JenkinsSetup from "@containers/SettingsPanel/ConnectorSetup/Jenkins/JenkinsSetup";
import S3Buckets from "@containers/SettingsPanel/S3Buckets/S3Buckets.tsx";
import SSHTargets from "@containers/SettingsPanel/SSH";
import SmallScreenError from "@components/SmallScreenError/SmallScreenError";
import { MIN_SUPPORTED_WIDTH } from "@lib/utils/constants";
import { RouteConstants } from "./Constants";
import SplunkSetup from "@containers/SettingsPanel/ConnectorSetup/Splunk/SplunkSetup";
import CloudFormation from "@containers/SettingsPanel/ConnectorSetup/CloudFormation/CloudFormation";
import OpsgenieSetup from "@containers/SettingsPanel/ConnectorSetup/Opsgenie/OpsgenieSetup";
import CloudwatchTargets from "@containers/SettingsPanel/Cloudwatch";
import CloudwatchSetup from "@containers/SettingsPanel/Cloudwatch/setup";
import PulumiSetup from "@containers/SettingsPanel/ConnectorSetup/Pulumi/PulumiSetup";
import SplunkOnCallSetup from "@containers/SettingsPanel/ConnectorSetup/SplunkOnCall/SplunkOnCallSetup";
utils.removeHashFromAddressBar();

export default class RoutedApp extends React.Component {
  render() {
    return (
      <Router>
        <AmplifyConfig>
          <Switch>
            <Route
              exact
              path={RouteConstants.forgotPassword.url}
              component={ForgotPassword}
            />
            <Route exact path="/" component={Login} />
            <Route exact path={RouteConstants.login.url} component={Login} />
            <Route exact path={RouteConstants.signUp.url} component={Signup} />
            {window.innerWidth > MIN_SUPPORTED_WIDTH ? (
              <RequireAuthentication>
                <Switch>
                  <Route
                    exact
                    path={`${RouteConstants.runbookEditor.url}/:runbookId`}
                    component={RunbookEditor}
                  />
                  <Route
                    path={RouteConstants.runbookDetails.url}
                    component={RunbookDetails}
                  />
                  <Route
                    exact
                    path={RouteConstants.runbooks.url}
                    component={Runbooks}
                  />
                  <Route
                    exact
                    path={RouteConstants.schedules.url}
                    component={Schedules}
                  />
                  <Route
                    exact
                    path={RouteConstants.settings.url}
                    component={SettingsPanel}
                  />
                  <Route
                    exact
                    path={RouteConstants.awsSettings.url}
                    component={AWSSettings}
                  />
                  <Route
                    exact
                    path={RouteConstants.git.url}
                    component={GitRepos}
                  />
                  <Route
                    exact
                    path={RouteConstants.ssh.url}
                    component={SSHTargets}
                  />
                  <Route
                    exact
                    path={RouteConstants.jira.url}
                    component={JiraSetup}
                  />
                  <Route
                    exact
                    path={RouteConstants.opsgenie.url}
                    component={OpsgenieSetup}
                  />
                  <Route
                    exact
                    path={RouteConstants.pulumi.url}
                    component={PulumiSetup}
                  />
                  <Route
                    exact
                    path={RouteConstants.terraform.url}
                    component={TerraformSetup}
                  />
                  <Route
                    exact
                    path={RouteConstants.slack.url}
                    component={SlackSetup}
                  />
                  <Route
                    exact
                    path={RouteConstants.slackAuth.url}
                    component={SlackAuth}
                  />
                  <Route
                    exact
                    path={RouteConstants.datadog.url}
                    component={DatadogSetup}
                  />
                  <Route
                    exact
                    path={RouteConstants.instana.url}
                    component={InstanaSetup}
                  />
                  <Route
                    exact
                    path={RouteConstants.pagerduty.url}
                    component={PagerDutySetup}
                  />
                  <Route
                    exact
                    path={RouteConstants.ansible.url}
                    component={AnsibleSetup}
                  />
                  <Route
                    exact
                    path={RouteConstants.splunk.url}
                    component={SplunkSetup}
                  />
                  <Route
                    exact
                    path={RouteConstants.splunkOnCall.url}
                    component={SplunkOnCallSetup}
                  />
                  <Route
                    exact
                    path={RouteConstants.terraformCli.url}
                    component={TerraformCLISetup}
                  />
                  <Route
                    exact
                    path={RouteConstants.editTerraformCLI.url}
                    component={EditTerraformCLI}
                  />
                  <Route
                    exact
                    path={RouteConstants.twilio.url}
                    component={TwilioSetup}
                  />
                  <Route
                    exact
                    path={RouteConstants.s3.url}
                    component={S3Buckets}
                  />
                  <Route
                    exact
                    path={RouteConstants.cloudformation.url}
                    component={CloudFormation}
                  />
                  <Route
                    exact
                    path={RouteConstants.apiKeys.url}
                    component={APIKeys}
                  />
                  <Route
                    exact
                    path={RouteConstants.aws.url}
                    component={GetStarted}
                  />
                  <Route
                    exact
                    path={`${RouteConstants.awsSettings.url}/:alias`}
                    component={SettingsAccountInfo}
                  />
                  <Route
                    exact
                    path={RouteConstants.actions.url}
                    component={Snippets}
                  />
                  <Route
                    exact
                    path={RouteConstants.actionDetails.url}
                    component={SnippetDetails}
                  />
                  <Route
                    path={RouteConstants.executionsRun.url}
                    component={ExecutionsRun}
                  />
                  <Route
                    exact
                    path={RouteConstants.executionDetails.url}
                    component={ExecutionDetails}
                  />
                  <Route
                    exact
                    path={`${RouteConstants.executionDetails.url}/:executionId`}
                    component={ExecutionDetails}
                  />
                  <Route
                    exact
                    path={RouteConstants.executionRunDetails.url}
                    component={ExecutionRunDetails}
                  />
                  <Route
                    path={RouteConstants.allExecutions.url}
                    component={Executions}
                  />
                  <Route
                    path={RouteConstants.executions.url}
                    component={Executions}
                  />
                  <Route
                    exact
                    path={RouteConstants.userProfile.url}
                    component={UserProfile}
                  />
                  <Route
                    exact
                    path={RouteConstants.appInfo.url}
                    component={AppInfo}
                  />
                  <Route
                    exact
                    path={RouteConstants.jenkins.url}
                    component={JenkinsSetup}
                  />
                  <Route
                    exact
                    path={RouteConstants.cloudwatch.url}
                    component={CloudwatchTargets}
                  />
                  <Route
                    exact
                    path={`${RouteConstants.cloudwatch.url}/add-integration`}
                    component={CloudwatchSetup}
                  />
                  <Route path="*">
                    <Redirect to={RouteConstants.runbooks.url} push />
                  </Route>
                </Switch>
              </RequireAuthentication>
            ) : (
              <SmallScreenError />
            )}
          </Switch>
        </AmplifyConfig>
      </Router>
    );
  }
}
