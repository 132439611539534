import React from "react";
import { Tab, TabCollection, TabContent } from "@components/ui/tabs";
import Output from "../../output";
import Details from "../../details";
import TwilioInputs from "./twilio-inputs";

const TwilioSendSMS: React.FC<any> = ({ activeNode, notifyRunbookUpdate }) => {
  return (
    <TabCollection
      active="input"
      activeClassName="editor-detail-tab-wrap editor-detail-active-tab"
      inactiveClassName="editor-detail-tab-wrap editor-detail-inactive-tab"
      contentTop={50}
    >
      <Tab title="Input" name="input">
        <TabContent>
          <h4 className="editor-node-name">{activeNode.name}</h4>
          <TwilioInputs
            activeNode={activeNode}
            notifyRunbookUpdate={notifyRunbookUpdate}
          />
        </TabContent>
      </Tab>
      <Tab title="Output" name="output">
        <TabContent>
          <Output />
        </TabContent>
      </Tab>
      <Tab title="Details" name="details">
        <TabContent>
          <Details />
        </TabContent>
      </Tab>
    </TabCollection>
  );
};

export default TwilioSendSMS;
