import React from "react";
import Output from "./../output";
import Details from "./../details";
import { Tab, TabCollection, TabContent } from "@components/ui/tabs";
import {
  TextInput,
  JsonView,
  Modal,
  AccordionSection,
  AccordionLabel,
  Accordion,
} from "@components/ui";
import { Consumer } from "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.context";
import { StepTypeChecker } from "@containers/RunbookEditor/runbook-editor-lib/neuropssteps/steptypechecker";
import { FromPreviousStep } from "@containers/RunbookEditor/runbook-editor-components/editor-right-panel/input-selector/option-actions";
import * as OutputLib from "../../input-selector/lib/output.lib";
import Api from "@lib/api";
import { JSONPath } from "jsonpath-plus"; //https://github.com/JSONPath-Plus/JSONPath
import iconExternal from "@assets/images/icons/icon-external.svg";
import { ControlNames } from "@containers/RunbookEditor/runbook-editor-lib/neuropssteps/strings";
import { ParameterType } from "@containers/RunbookEditor/runbook-editor-lib/ssm/strings";
import {
  RunbookStepInputSource,
  ActionNodeOutput,
} from "@containers/RunbookEditor/runbook-editor-lib/ssm/nodeinputoutput";
import { hasKeys } from "@lib/utils";
import { default as JSONEditor } from "@monaco-editor/react";

export default class JSONPathPanel extends React.Component {
  constructor(props) {
    super(props);

    this.activeNode = this.props.activeNode.extras.runbookNode;
    this.json_path_input = this.activeNode.parameterInputs.find(
      i => i.name === "json_path",
    );
    if (this.json_path_input.source.type === "userProvided") {
      let source = new RunbookStepInputSource("constant", "$");
      this.json_path_input.source = source;
    }
    this.json_object_input = this.activeNode.parameterInputs.find(
      i => i.name === "json_object",
    );

    this.state = {
      json_path: `$`,
      filteredJson: {},
      showCustomModal: false,
      isValidJsonPath: true,
      invalidCustomJson: false,
      lastSelectedInput: null,
    };

    this.json_editor = null;
  }

  componentDidMount = async () => {
    const source_type = this.json_object_input.source.type;
    if (source_type === "constant") {
      const json_object_input_value = this.json_object_input.source.sourceValue;
      this.setState(
        {
          json_path: this.json_path_input.source.sourceValue,
          custom_json:
            typeof json_object_input_value === "string"
              ? json_object_input_value
              : JSON.stringify(json_object_input_value),
          json:
            typeof json_object_input_value === "string"
              ? JSON.parse(json_object_input_value)
              : json_object_input_value,
          lastSelectedInput: "Custom JSON",
        },
        () => {
          this.pathChanged(this.json_path_input.source.sourceValue, false);
        },
      );
    } else if (source_type === "actionNode") {
      // action-node
      if (
        hasKeys(this.json_object_input.source.sourceValue, "sourceStep.name")
      ) {
        const serviceOps = OutputLib.getServiceOps(
          this.json_object_input.source.sourceValue.sourceStep,
        );
        let response = await Api.getAWSServiceOperationDetails(
          serviceOps.service,
          serviceOps.operation,
        );
        await this.setState({
          json: response.output,
          filteredJson: JSONPath({
            path: this.json_path_input.source.sourceValue,
            json: response.output,
            wrap: false,
          }),
          json_path: this.json_path_input.source.sourceValue,
          lastSelectedInput: "ActionNode",
        });
      } else if (
        hasKeys(this.json_object_input.source.sourceValue, "snippetAction") &&
        this.json_object_input.source.sourceValue.snippetAction.name ===
          ControlNames.DatadogAlert
      ) {
        this.fetchDatadogOutput();
      } else {
        await this.setState({
          json: {},
          filteredJson: {},
          json_path: this.json_path_input.source.sourceValue,
          lastSelectedInput: null,
        });
      }
    }
  };

  pathChanged = (e, update = false) => {
    try {
      const result = JSONPath({ path: e, json: this.state.json, wrap: false });
      this.setState({
        filteredJson: result,
        isValidJsonPath: true,
        json_path: e,
      });
      let source = new RunbookStepInputSource("constant", e);
      this.json_path_input.source = source;
      if (update) {
        this.props.notifyRunbookUpdate(true);
      }
    } catch (e) {
      console.warn(e);
      this.setState({ isValidJsonPath: false });
    }
  };

  readSelectedStep = async node => {
    node = StepTypeChecker.getType(node) || node;
    console.log("node-<", node);
    if (this.state.lastSelectedInput !== node) {
      this.setState({
        filteredJson: {},
        lastSelectedInput: node,
      });
    }
    switch (node) {
      case "Custom JSON":
        this.setState({
          showCustomModal: true,
        });
        break;
      default:
        break;
    }
  };

  previousStepChanged = async e => {
    let type = this.activeNode.runbook.stepOutputType(e);
    let outputStep = this.activeNode.runbook.getStepByName(e);
    let stepName = StepTypeChecker.getType({
      extras: { runbookNode: outputStep },
    });

    switch (stepName) {
      case "ActionNode":
        const serviceOps = OutputLib.getServiceOps(outputStep);
        let response = await Api.getAWSServiceOperationDetails(
          serviceOps.service,
          serviceOps.operation,
        );
        this.setState({
          json: response.output,
          filteredJson: response.output,
        });

        let actionnode_output = new ActionNodeOutput(
          outputStep,
          "$",
          ParameterType.String,
        );
        actionnode_output.selectorName = actionnode_output.selectorName.replace(
          "$_",
          "all_",
        );
        actionnode_output.ssmStepOutputType = ParameterType.String;
        const source = new RunbookStepInputSource(
          "actionNode",
          actionnode_output,
        );
        source.sourceValue.originalType = "String";
        if (!outputStep.outputs) {
          outputStep.outputs = [];
        }
        outputStep.outputs.push(source.sourceValue);
        this.json_object_input.source = source;
        break;
      case ControlNames.DatadogAlert:
        this.fetchDatadogOutput();
        break;
      default:
        this.json_object_input.type = "raw";
        let output_name = e.split(".")[1];
        if (type !== ParameterType.String) {
          const output = outputStep.outputs.find(o => o.name === output_name);
          output.ssmStepOutputType = ParameterType.String;
          output.type = ParameterType.String;
        }

        break;
    }
    this.props.notifyRunbookUpdate(true);
  };

  fetchDatadogOutput = async () => {
    const output = await Api.getDatadogWebhookPayload();
    this.setState({
      json: JSON.parse(output),
      filteredJson: JSONPath({
        path: this.json_path_input.source.sourceValue,
        json: JSON.parse(output),
        wrap: false,
      }),
      lastSelectedInput: "Datadog_Alert",
      json_path: this.json_path_input.source.sourceValue,
    });
  };

  closeCustomJSONModal = e => {
    this.setState({
      showCustomModal: false,
      invalidCustomJson: false,
    });
  };

  customJsonChanged = (newValue, e) => {
    try {
      JSON.parse(newValue);
      this.setState({
        invalidCustomJson: false,
      });
    } catch (e) {
      this.setState({
        invalidCustomJson: true,
      });
    }
  };

  saveCustomJson = async () => {
    let custom_json;
    try {
      custom_json = JSON.parse(this.json_editor.getValue());
    } catch (error) {
      console.warn(error);
      this.setState({
        invalidCustomJson: true,
      });
      return;
    }
    await this.setState({
      filteredJson: custom_json,
      json: custom_json,
      custom_json: JSON.stringify(custom_json),
    });
    this.closeCustomJSONModal();
    const source = new RunbookStepInputSource(
      "constant",
      JSON.stringify(custom_json),
    );
    this.json_object_input.source = source;
    this.pathChanged(this.state.json_path, false);
    this.props.notifyRunbookUpdate(true);
  };

  jsonEditorDidMount = editor => {
    this.json_editor = editor;
  };

  getCustomJsonModelFooter = () => {
    return (
      <div className="modal-buttons-footer__editor pt-20">
        <button
          type="button"
          className="footer-btn footer-btn-cancel"
          onClick={this.closeCustomJSONModal}
        >
          Cancel
        </button>
        <button
          type="button"
          className="footer-btn footer-btn-save"
          onClick={this.saveCustomJson}
        >
          Save
        </button>
      </div>
    );
  };

  render() {
    return (
      <Consumer>
        {({ activeNode, notifyRunbookUpdate }) => {
          let json_path_classname =
            `rule-input ` + (this.state.isValidJsonPath ? `` : `error`);
          let hide_error = this.state.invalidCustomJson ? `hide-error` : ``;
          return (
            <TabCollection
              active="input"
              activeClassName="editor-detail-tab-wrap editor-detail-active-tab"
              inactiveClassName="editor-detail-tab-wrap editor-detail-inactive-tab"
              contentTop={50}
            >
              {this.state.showCustomModal && (
                <Modal
                  title={`Custom JSON`}
                  backgroundCanClose={true}
                  showClose={true}
                  onClose={this.closeCustomJSONModal}
                  coverClass={`modal-cover__editor`}
                  containerClass={`modal-container__editor`}
                  contentClass={`modal-content__editor-json`}
                  titleClass={`modal-title__json`}
                  closeClass={`modal-close__editor`}
                  footer={this.getCustomJsonModelFooter()}
                  footerClass={`modal-footer__editor`}
                >
                  <div>
                    <JSONEditor
                      onMount={this.jsonEditorDidMount}
                      onChange={this.customJsonChanged}
                      height="40vh"
                      language="json"
                      theme="vs-dark"
                      className={`mb-10`}
                      defaultValue={
                        this.state.custom_json &&
                        JSON.stringify(
                          JSON.parse(this.state.custom_json),
                          undefined,
                          4,
                        )
                      }
                    />
                    <span className={`invalid-json ` + hide_error}>
                      Invalid JSON. Closing this window will discard invalid
                      changes.
                    </span>
                  </div>
                </Modal>
              )}
              <Tab title="Input" name="input">
                <TabContent>
                  <h4 className="editor-node-name">
                    {this.props.activeNode.name}
                  </h4>
                  <React.Fragment>
                    <Accordion isExpanded={true} useArrow={true}>
                      <AccordionLabel className="editor-accordion-label margin-top-10">
                        Required Inputs
                      </AccordionLabel>
                      <AccordionSection>
                        <div className="editor-detail-panel editor-detail-panel-column">
                          <div className="label mb-0"> JSON Inputs</div>
                          <div className="editor-select-container">
                            <FromPreviousStep
                              input={this.json_object_input}
                              runbookObj={activeNode.extras.runbookNode.runbook}
                              notifyRunbookUpdate={notifyRunbookUpdate}
                              onChangeCallBack={this.previousStepChanged}
                              showOutputPath={false}
                              readSelectedStep={this.readSelectedStep}
                              custom_item={`Custom JSON`}
                            />
                          </div>
                        </div>
                        <div className="editor-detail-panel flex-column2 pt-0">
                          <div className="d-flex-sb">
                            <div className="label">JSON Path Expression</div>
                            <a
                              href="https://goessner.net/articles/JsonPath/index.html#e2"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <div className="label-link">Help</div>
                              <img
                                alt="Help with JSONPath"
                                src={iconExternal}
                                height="10px"
                                style={{ opacity: "0.7" }}
                              />
                            </a>
                          </div>
                          <div className="editor-select-container">
                            <TextInput
                              name={`jsonpath_syntax`}
                              className={json_path_classname}
                              onChange={e => this.pathChanged(e, true)}
                              value={this.state.json_path}
                            />
                          </div>
                        </div>
                        <div className="editor-detail-panel editor-detail-panel-column">
                          <div className="label">Expected Output</div>
                          <div className="json-output">
                            <JsonView
                              data={this.state.filteredJson}
                              showCopy={false}
                            ></JsonView>
                          </div>
                        </div>
                      </AccordionSection>
                    </Accordion>
                  </React.Fragment>
                </TabContent>
              </Tab>
              <Tab title="Output" name="output">
                <TabContent>
                  <Output />
                </TabContent>
              </Tab>
              <Tab title="Details" name="details">
                <TabContent>
                  <Details />
                </TabContent>
              </Tab>
            </TabCollection>
          );
        }}
      </Consumer>
    );
  }
}
