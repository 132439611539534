import React, { useState } from "react";
import copyIcon from "@assets/images/icons/icon-copy.svg";
import IconEye from "@components/ui/icons/icon-eye";
import IconEyeCancelled from "@components/ui/icons/icon-eye-cancelled";
import { CopyToClipboard } from "react-copy-to-clipboard";
import "./Password.scss";

type PasswordProps = {
  title?: string;
  value: string;
  readOnly: boolean;
  onChange: (value) => void;
};

const Password: React.FC<PasswordProps> = ({
  title = "",
  value,
  readOnly = false,
  onChange,
}) => {
  const [inputType, setInputType] = useState("password");
  const [password, setPassword] = useState(value);
  return (
    <div className="input-wrap">
      <label className="input-wrap-label">{title}</label>
      <div className="input-wrap">
        <input
          defaultValue={password}
          id={"input-password"}
          className={`input-wrap-input-container ${inputType === "password"}`}
          maxLength={256}
          required={true}
          type={inputType}
          readOnly={readOnly}
          onChange={e => {
            const input = e.target.value;
            setPassword(input);
            onChange(input);
          }}
        />
        {inputType === "text" ? (
          <div className="input-wrap-icon input-wrap-icon__eye">
            <IconEye
              className="eye-icon"
              handleClick={() => setInputType("password")}
              height="14"
              width="14"
            />
          </div>
        ) : (
          <div className="input-wrap-icon input-wrap-icon__eye">
            <IconEyeCancelled
              className="eye-icon"
              handleClick={() => setInputType("text")}
              height="14"
              width="14"
            />
          </div>
        )}
        <CopyToClipboard text={password}>
          <div className="input-wrap-icon input-wrap-icon__copy">
            <img
              src={copyIcon}
              alt="invisibleIcon"
              className="mr-5 pointer"
              width="14"
              height="14"
            />
          </div>
        </CopyToClipboard>
      </div>
    </div>
  );
};

export default Password;
