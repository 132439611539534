import React from "react";

const BlockPublicAccess = require("@assets/images/screenshots/settings-ansible/block_public_access.png");
const BucketPolicyPlaybook = require("@assets/images/screenshots/settings-ansible/bucket_policy_playbook.png");
const BucketPolicyLogs = require("@assets/images/screenshots/settings-ansible/bucket_policy_logs.png");

export default () => (
  <>
    <h3 className="h2-margin">Instructions to setup Ansible Integration</h3>
    The Ansible directory hierarchy is expected to be available in an{" "}
    <i>S3 bucket with ansible playbooks</i>. The output of a run will be
    uploaded to the same bucket, or an optional <i>S3 bucket for log files</i>.
    Access to these resources needs to be granted by following the instructions
    below. A <i>SSH private key</i> that allows access to the managed nodes
    needs to be provided.
    <div className="list-row pt-10">
      <div className="step-number">
        <div>1</div>
      </div>
      <div>
        Login to the <b>AWS console</b>
      </div>
    </div>
    <div className="list-row">
      <div className="step-number">
        <div>2</div>
      </div>
      <div>
        Enable cross-account access to the{" "}
        <i>S3 bucket with ansible playbooks</i> and the optional{" "}
        <i>S3 bucket for log files</i>:
      </div>
    </div>
    <div className="list-image">
      <img src={BlockPublicAccess} alt="BlockPublicAccess" />
    </div>
    <div className="list-row">
      <div className="step-number">
        <div>3</div>
      </div>
      <div>
        Paste the value for <b>Principal</b> from the right pane into the green{" "}
        <i>Principal</i> field for the <b>Bucket policy</b>. The red{" "}
        <i>Resource</i> field should have entries for the bucket and the objects
        within.
        <div className="list-row">
          <div className="step-number">
            <div>a</div>
          </div>
          <div>
            The policy for a single bucket or the optional{" "}
            <i>S3 bucket for log files</i> should be:
          </div>
        </div>
        <div className="list-image">
          <img src={BucketPolicyLogs} alt="BucketPolicyLogs" />
        </div>
        <div className="list-row">
          <div className="step-number">
            <div>b</div>
          </div>
          <div>
            A more restrictive policy for a separate{" "}
            <i>S3 bucket with ansible playbooks</i> can be:
          </div>
        </div>
        <div className="list-image">
          <img src={BucketPolicyPlaybook} alt="BucketPolicyPlaybook" />
        </div>
      </div>
    </div>
  </>
);
