import React from "react";
import JsonContentModal from "./json-content-modal";
import showJSONIcon from "@assets/images/icons/icon-snippets.svg";
import ReactTooltip from "react-tooltip";

export default class EditorToolbarJsonContent extends React.Component {
  state = {
    showModal: false,
  };

  setModal = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };

  render() {
    return (
      <React.Fragment>
        <div
          className="editor-toolbar-json-content-button"
          onClick={this.setModal}
        >
          <ReactTooltip
            id="view-code-editor"
            place="left"
            effect="solid"
            type="light"
          />
          <img
            src={showJSONIcon}
            alt="showJSONIcon"
            width="20"
            height="20"
            data-for="view-code-editor"
            data-tip="View Code"
          />
        </div>
        {this.state.showModal && (
          <JsonContentModal
            closeModal={this.setModal}
            json={this.props.runbook.toSSM()}
          />
        )}
      </React.Fragment>
    );
  }
}
