import React from "react";
import { connect } from "react-redux";
import "@scss/quill.css";
import {
  LabelTop,
  Accordion,
  AccordionLabel,
  AccordionSection,
  Select,
  SelectOption,
} from "@components/ui";
import { Tab, TabCollection, TabContent } from "@components/ui/tabs";
import { Consumer } from "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.context";
import {
  RunbookStepInputSource,
  ActionNodeOutput,
} from "@containers/RunbookEditor/runbook-editor-lib/ssm/nodeinputoutput";
import ReactQuill from "react-quill";
import { Button, TextInput } from "@components/ui";
import FromPreviousStep from "./../../input-selector/option-actions/from-previous-step/FromPreviousStep";
import { FTNotification } from "@components/ui";
import { ParameterType } from "@containers/RunbookEditor/runbook-editor-lib/ssm/strings";
import { fetchSlackChannelList } from "@redux/actions/snippets.actions";

type SlackConnectorPanelProps = {
  slackChannelList: any;
  input: any;
  activeNode: any;
  fetchSlackChannelList: Function;
};

type SlackConnectorPanelState = {
  displayMessage: string;
  selectedInputs?: any;
  addVariablePanelVisible: boolean;
  slackVariableName: string;
  slackVariableValue: string;
  isOutputVariableListVisible: boolean;
  slackMessageEditorLastCursorPosition: null | number;
};

class SlackConnectorPanel extends React.Component<
  SlackConnectorPanelProps,
  SlackConnectorPanelState
> {
  quillRef: any;

  constructor(props) {
    super(props);

    let selectedInputs = {};
    // eslint-disable-next-line
    for (let item of props.activeNode.extras.runbookNode.parameterInputs) {
      if (typeof item.source.sourceValue === "string") {
        selectedInputs[item.name] = item.source.sourceValue;
      }
    }
    this.state = {
      displayMessage: props.activeNode.extras.runbookNode.displayMessage,
      selectedInputs: selectedInputs,
      addVariablePanelVisible: false,
      slackVariableName: "",
      slackVariableValue: "",
      isOutputVariableListVisible: false,
      slackMessageEditorLastCursorPosition: null,
    };
    this.quillRef = null;
  }
  componentDidMount() {
    if (!this.props.slackChannelList?.channels?.length) {
      this.props.fetchSlackChannelList();
    }
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = event => {
    if (!event.target.id || event.target.id !== "slack-output-variable")
      this.setState({ isOutputVariableListVisible: false });
  };

  updateSlackChannel(newValue, notifyRunbookUpdate) {
    // notify reducer that current runbook got updates.

    const input = this.props.activeNode.extras.runbookNode.parameterInputs.find(
      ip => ip.name === "channel_name",
    );
    input.source = new RunbookStepInputSource("constant", newValue);
    notifyRunbookUpdate(true);
    this.setState({
      selectedInputs: { ...this.state.selectedInputs, channel_name: newValue },
    });
  }

  formatSlackMessage = (message = "") =>
    message
      .replace(/\n/g, "")
      .replace(/"/g, "'")
      .replace(/<br >/g, "")
      .replace(/"/g, "'")
      .replace(/<br\/>/g, "")
      .replace(/rel="noopener noreferrer"/g, "")
      .replace(/target="_blank"/g, "")
      .replace(/style="background-color: rgb\(61, 61, 61\);"/g, "")
      .replace(/spellcheck="false"/g, "");

  updateSlackMessage(message, notifyRunbookUpdate) {
    let escapedValue = this.formatSlackMessage(message);

    let htmlElement = document.createElement("DIV");
    htmlElement.innerHTML = escapedValue;
    this.props.activeNode.extras.runbookNode.displayMessage = (htmlElement as any)
      .textContent
      ? escapedValue
      : "";
    // notify reducer that current runbook got updates.
    notifyRunbookUpdate(true);
  }

  stopEventPropogation = e => {
    let key = e.key;
    if (key === "Backspace" || key === "Delete") {
      e.stopPropagation();
      e.cancelBubble = false;
    }
  };

  insertTextAtCurrentPosition = text => {
    if (this.state.slackMessageEditorLastCursorPosition !== null) {
      this.quillRef
        .getEditor()
        .clipboard.quill.insertText(
          this.state.slackMessageEditorLastCursorPosition,
          text,
        );
    } else {
      FTNotification.error({
        message: "Please click on position where you want to insert variable",
      });
    }
    this.setState({ isOutputVariableListVisible: false });
  };

  setSlackVariableName = slackVariableName => {
    this.setState({ slackVariableName });
  };

  setSlackVariableValue = slackVariableValue => {
    this.setState({ slackVariableValue });
  };

  saveSlackVariable = notifyRunbookUpdate => {
    const { slackVariableName, slackVariableValue } = this.state;
    const input = this.props.activeNode.extras.runbookNode.parameterInputs.find(
      ip => ip.name === "dynamic_variables",
    );
    let sourceValue =
      input.source.sourceValue &&
      (typeof input.source.sourceValue === "string"
        ? JSON.parse(input.source.sourceValue)
        : input.source.sourceValue);
    if (sourceValue) {
      sourceValue[slackVariableName] = slackVariableValue;
    } else {
      sourceValue = { [slackVariableName]: slackVariableValue };
    }
    input.source.sourceValue = JSON.stringify(sourceValue);

    notifyRunbookUpdate(true);
    FTNotification.success({
      title: "Success",
      message: `Output variable saved successfully`,
      timeout: 4,
    });
    this.setState({
      slackVariableName: "",
      slackVariableValue: "",
      addVariablePanelVisible: !this.state.addVariablePanelVisible,
    });
  };

  toggleOutputVariableListVisibility = () => {
    this.setState({
      isOutputVariableListVisible: !this.state.isOutputVariableListVisible,
    });
  };

  setSlackMessageEditorLastCursorPosition = () => {
    var range = this.quillRef.getEditorSelection();
    if (range !== null) {
      this.setState({ slackMessageEditorLastCursorPosition: range.index });
    }
  };

  getSlackOutputVariableSection = () => {
    const input = this.props.activeNode.extras.runbookNode.parameterInputs.find(
      ip => ip.name === "dynamic_variables",
    );

    let sourceValue =
      input.source.sourceValue &&
      (typeof input.source.sourceValue === "string"
        ? JSON.parse(input.source.sourceValue)
        : input.source.sourceValue);
    const dynamicVariables = (sourceValue && Object.keys(sourceValue)) || [];

    return dynamicVariables?.length ? (
      <div className="insert-dynamic-variable-button">
        <button
          onClick={() => {
            this.toggleOutputVariableListVisibility();
            if (this.quillRef.getEditorSelection()) {
              this.setSlackMessageEditorLastCursorPosition();
            }
          }}
        >
          Insert Variables
        </button>
        {this.state.isOutputVariableListVisible && (
          <div className="slack-dynamic-variable-list">
            <ul className="no-list-style">
              {dynamicVariables.map((variable, i) => (
                <li
                  key={i}
                  id="slack-output-variable"
                  onClick={() =>
                    this.insertTextAtCurrentPosition(" {{" + variable + "}} ")
                  }
                >
                  {variable}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    ) : null;
  };

  onSelectActionNodeOutput = (selector, sourceStep) => {
    if (sourceStep) {
      const { runbookNode: sourceRunbookNode } = sourceStep.extras;

      const sourceOutput = new ActionNodeOutput(
        sourceRunbookNode,
        selector,
        ParameterType.String,
      );

      if (!sourceRunbookNode.outputs) {
        sourceRunbookNode.outputs = [];
      }
      sourceRunbookNode.outputs.push(sourceOutput);
      this.setSlackVariableValue(
        `${sourceRunbookNode.name}.${sourceOutput.name}`,
      );
    } else {
      this.setSlackVariableValue(selector);
    }
  };

  render() {
    return (
      <Consumer>
        {({ activeNode, runbookObj, notifyRunbookUpdate }) => {
          let channelName = this.state.selectedInputs.channel_name;

          return (
            <TabCollection
              active="input"
              activeClassName="editor-detail-tab-wrap editor-detail-active-tab"
              inactiveClassName="editor-detail-tab-wrap editor-detail-inactive-tab"
              contentTop={50}
            >
              <Tab title="Input" name="input">
                <TabContent>
                  <h4 className="editor-node-name">
                    {this.props.activeNode.name}
                  </h4>
                  <React.Fragment>
                    <Accordion isExpanded={true} useArrow={true}>
                      <AccordionLabel className="editor-accordion-label margin-top-10">
                        Required Inputs
                      </AccordionLabel>
                      <AccordionSection>
                        <div className="editor-detail-panel editor-detail-panel-column">
                          <div className="editor-select-container">
                            <LabelTop
                              label={"Slack Channel"}
                              style={{ width: "calc(100%)" }}
                              labelClassName="label"
                            ></LabelTop>
                            <Select
                              title={
                                channelName && typeof channelName === "string"
                                  ? channelName
                                  : "Select from below"
                              }
                              style={{ width: "calc(100% - 8px)" }}
                              value={
                                channelName && typeof channelName === "string"
                                  ? channelName
                                  : ""
                              }
                              width="100%"
                              optionHeight={32}
                              required={true}
                              onChange={newValue => {
                                this.updateSlackChannel(
                                  newValue,
                                  notifyRunbookUpdate,
                                );
                              }}
                              iconLeft="icon-filter"
                            >
                              {this.props.slackChannelList?.channels?.map(
                                channel => (
                                  <SelectOption
                                    text={channel}
                                    value={channel}
                                    key={channel}
                                    className="text-small"
                                  />
                                ),
                              )}
                            </Select>
                            <LabelTop
                              label={"Message Text"}
                              labelClassName="label"
                            >
                              <div
                                className="slack-editor"
                                style={{
                                  backgroundColor: "#555",
                                  width: "calc(100% - 8px)",
                                }}
                              >
                                <ReactQuill
                                  ref={el => {
                                    this.quillRef = el;
                                  }}
                                  defaultValue={this.state.displayMessage}
                                  onKeyUp={this.stopEventPropogation}
                                  onKeyPress={this.stopEventPropogation}
                                  modules={{
                                    toolbar: {
                                      container: [
                                        "bold",
                                        "italic",
                                        "code-block",
                                        "strike",
                                        "link",
                                      ],
                                      handlers: {},
                                    },
                                  }}
                                  onChange={message => {
                                    message.includes("unselectable") &&
                                      this.updateSlackMessage(
                                        message,
                                        notifyRunbookUpdate,
                                      );
                                  }}
                                />
                                {this.getSlackOutputVariableSection()}
                              </div>
                            </LabelTop>
                            <div className="mt-10">
                              <Button
                                text="+ Add Slack Variables"
                                onClick={() => {
                                  this.setState({
                                    addVariablePanelVisible: !this.state
                                      .addVariablePanelVisible,
                                  });
                                }}
                                buttonStyle="secondary"
                                className="add-slackvariable-button"
                              />
                            </div>
                            {/* <ReactCSSTransition panel={"input-panel"}> */}
                            {this.state.addVariablePanelVisible && (
                              <div className="mt-10">
                                <div className="editor-previous-step-label font-11 padding-0">
                                  Set Slack Variable
                                </div>
                                <TextInput
                                  className="rule-input"
                                  placeholder="Variable name"
                                  name={this.state.slackVariableName}
                                  value={this.state.slackVariableName}
                                  onChange={this.setSlackVariableName}
                                  style={{
                                    width: "calc(100% - 8px)",
                                    marginBottom: "16px",
                                  }}
                                />
                                {activeNode.extras.runbookNode && (
                                  <FromPreviousStep
                                    resetTitle={true}
                                    input={activeNode.extras.runbookNode}
                                    label={"label"}
                                    onChangeCallBack={
                                      this.onSelectActionNodeOutput
                                    }
                                    runbookObj={runbookObj}
                                    notifyRunbookUpdate={notifyRunbookUpdate}
                                  />
                                )}
                                {!!this.state.slackVariableName &&
                                  !!this.state.slackVariableValue && (
                                    <Button
                                      text="Save Variable"
                                      onClick={() =>
                                        this.saveSlackVariable(
                                          notifyRunbookUpdate,
                                        )
                                      }
                                      buttonStyle="primary"
                                      className="save-slackvariable-button mt-20"
                                    />
                                  )}
                              </div>
                            )}
                            {/* </ReactCSSTransition> */}
                          </div>
                        </div>
                      </AccordionSection>
                    </Accordion>
                  </React.Fragment>
                </TabContent>
              </Tab>
              <Tab title="Output" name="output">
                <TabContent></TabContent>
              </Tab>
              <Tab title="Details" name="details">
                <TabContent></TabContent>
              </Tab>
            </TabCollection>
          );
        }}
      </Consumer>
    );
  }
}

const mapState = state => ({
  slackChannelList: state.snippetsReducer.slackChannelList,
});

const mapDispatch = dispatch => {
  return {
    fetchSlackChannelList: () => dispatch(fetchSlackChannelList()),
  };
};

export default connect(mapState, mapDispatch)(SlackConnectorPanel);
