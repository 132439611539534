import React from "react";
import PropTypes from "prop-types";
import { getBreadCrumbs, toProperCase } from "@lib/utils";
import { Link } from "react-router-dom";
import "./breadcrumbs.scss";

class BreadCrumbs extends React.PureComponent {
  state = { links: [] };

  componentDidMount() {
    this.textToLinks();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.text !== this.props.text) {
      this.textToLinks();
    }
  }

  textToLinks = () => {
    const breadCrumbs = getBreadCrumbs();
    const linkTextArray = this.props.text ? this.props.text.split("/") : [];
    let links = [];
    breadCrumbs.forEach((crumb, i) => {
      let link = crumb;
      let text =
        linkTextArray.length && linkTextArray.length > i - 1
          ? linkTextArray[i]
          : toProperCase(crumb);
      links.push({
        link: link,
        text: text,
      });
    });

    this.setState({
      links,
    });
  };

  displayBreadcrumbs() {
    const links = this.state.links;
    this.tree = "";
    return (
      <React.Fragment>
        {this.props.navigateTo ? (
          <Link
            to={this.props.navigateTo}
            className="breadcrumb-link"
            key={`breadcrumb`}
          >
            {this.props.text}
          </Link>
        ) : (
          links &&
          links.map((breadcrumb, i) => {
            const trailing =
              i < links.length - 1 ? (
                <span className="breadcrumb-pike">/</span>
              ) : (
                  ""
                );
            if (i > 0) {
              this.tree += "/" + breadcrumb.link;
            } else {
              this.tree = "../../" + breadcrumb.link;
            }
            return (
              <Link
                to={this.tree}
                className="breadcrumb-link"
                key={`breadcrumb-${i}`}
              >
                {breadcrumb.text}
                {trailing}
              </Link>
            );
          })
        )}
      </React.Fragment>
    );
  }

  render() {
    return (
      <div className="breadcrumb-wrap">
        <nav className="breadcrumbs">
          {this.state.links && this.displayBreadcrumbs()}
        </nav>
      </div>
    );
  }
}

BreadCrumbs.propTypes = {
  link: PropTypes.string,
  navigateTo: PropTypes.string,
};

BreadCrumbs.defaultProps = {
  link: "# ",
  navigateTo: "",
};

export default BreadCrumbs;
