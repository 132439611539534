import React from "react";
import { DataTable } from "@components/ui";
import { Consumer } from "../snippet-details-lib/snippet-details.context";

export default class SnippetDetailsDataTable extends React.Component {
  render() {
    return (
      <Consumer>
        {({ snippet }) => {
          let columns = [
            {
              name: "Name",
              selector: "Name",
              sortable: false,
            },
            {
              name: "Type",
              selector: "Val",
              sortable: false,
            },
          ];
          var data = [];
          if (this.props.show === "inputs") {
            if (snippet.required_inputs) {
              data = Object.keys(snippet.required_inputs).map(k => {
                return { Name: k, Val: snippet.required_inputs[k].split("|")[0] };
              });
            }
            if (snippet.optional_inputs) {
              data = [
                ...data,
                ...Object.keys(snippet.optional_inputs).map(k => {
                  return {
                    Name: k + " (Optional)",
                    Val: snippet.optional_inputs[k],
                  };
                }),
              ];
            }
          } else if (this.props.show === "outputs") {
            if (snippet.required_inputs) {
              data = snippet.outputs && Object.keys(snippet.outputs).map(k => {
                return { Name: k, Val: snippet.outputs[k] };
              });
            }
          }
          return (
            <DataTable
              columns={columns}
              data={data}
              fixedHeader={true}
              fixedHeaderScrollHeight="192px"
              updating={false}
            />
          );
        }}
      </Consumer>
    );
  }
}
