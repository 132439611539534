import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DataTable } from "@components/ui/data-table";
import tableColumns from "./tableColumns";
import { SettingsPanelHeader } from "../../../settings-panel-components";
import { Wait, Confirm, FTNotification } from "@components/ui";
import TerraformCLIModal from "./TerraformCLIModal";
import { toggleDeleteConfirmBox } from "@redux/actions/AWSSettings.actions";
import {
  fetchTerraformCLIModules,
  deleteTerraformCLIModule,
} from "@redux/actions/settingsPanel.action";
import { fetchGitRepos } from "@redux/actions/gitRepo.actions";

const EditTerraformCLI = () => {
  const newTerraformCLIValues = {
    module_id: "",
    repo_id: "",
    directory: "",
    ref: "",
  };
  const dispatch = useDispatch();
  const showConfirm = useSelector(state => state.uiReducer.showConfirm);
  const loadingMessage = useSelector(state => state.runbooksReducer.message);
  const terraformCLIModuleList = useSelector(
    state => state.settingsPanelReducer.terraformCLIModuleList,
  );

  const [selectedModule, setSelectedModule] = useState(newTerraformCLIValues);
  const [showTerraformCLIModal, setShowTerraformCLIModal] = useState(false);
  const [terraformCLIMode, setTerraformCLIMode] = useState("create");
  const [isDeleting, setIsDeleting] = useState(false);

  const toggleTerraformCLIFormVisibility = mode => {
    setShowTerraformCLIModal(true);
    if (mode === "create") {
      setSelectedModule(newTerraformCLIValues);
      setTerraformCLIMode(mode);
    }
  };

  const onEditHandler = row => {
    setSelectedModule(row);
    setTerraformCLIMode("edit");
    toggleTerraformCLIFormVisibility("edit");
  };
  const onRemoveHandler = row => {
    setSelectedModule(row);
    dispatch(toggleDeleteConfirmBox(true));
  };

  const terraformCLIColumns = tableColumns(onRemoveHandler, onEditHandler);

  useEffect(() => {
    dispatch(fetchGitRepos());
    dispatch(fetchTerraformCLIModules());
  }, [dispatch]);

  return (
    <>
      {!!loadingMessage && <Wait text={loadingMessage} />}
      {showConfirm && (
        <Confirm
          heading="Delete Confirmation"
          cancelText="Cancel"
          confirmText="Delete"
          message="This Terraform CLI Module will be deleted permanently."
          confirmValue={selectedModule.module_id}
          confirmRequiredText={`Confirm by typing '${selectedModule.module_id}' below`}
          isLoading={isDeleting}
          verifiedCallback={async () => {
            setIsDeleting(true);
            const action = await dispatch(
              deleteTerraformCLIModule(selectedModule.module_id),
            );
            dispatch(toggleDeleteConfirmBox(false));
            setIsDeleting(false);
            if (action.type === "DELETE_TERRAFORM_CLI_MODULE_SUCCESS") {
              FTNotification.success({
                title: "Terraform CLI Module Deleted Successfully!",
              });
            } else {
              FTNotification.error({
                title: "Could not delete Terraform CLI Module",
                message: "",
              });
            }
          }}
        />
      )}
      {showTerraformCLIModal && (
        <TerraformCLIModal
          selectedModule={selectedModule}
          closeForm={setShowTerraformCLIModal}
          mode={terraformCLIMode}
          moduleList={terraformCLIModuleList}
        />
      )}

      <div className="d-flex flex-column2">
        <SettingsPanelHeader
          isBreadCrumb={true}
          page="terraform-cli-modules"
          heading="Terraform CLI Modules"
          breadCrumbText="Settings"
          toggleTerraformCLIFormVisibility={toggleTerraformCLIFormVisibility}
        />
        <div className="mt-40 ml-15 gr-list-container aws-settings-list">
          <DataTable
            className="snippet-list-table"
            columns={terraformCLIColumns}
            data={terraformCLIModuleList}
            responsive={true}
            fixedHeader={true}
          />
        </div>
      </div>
    </>
  );
};

export default EditTerraformCLI;
