import React, { useState } from "react";
import { Modal } from "@components/ui";
import OutputModal from "./snippet-panel/OutputModal";

export default props => {
  const [displayModal, toggleModal] = useState(false);
  return (
    <React.Fragment>
      <div>
        <div className="editor-detail-panel-title">
          <div className="editor-detail-panel-icon editor-detail-panel-icon-select" />
          <div>{props.activeNode.name}</div>
        </div>
        <div style={{ width: "100%", margin: 20 }}>
          <div
            className="link-light-blue"
            onClick={() => {
              toggleModal(!displayModal);
            }}
          >
            View Outputs
          </div>
        </div>
      </div>
      {displayModal && (
        <Modal
          onClose={() => {
            toggleModal(!displayModal);
          }}
          title={`Outputs: ${props.activeNode.extras.runbookNode.name}`}
          backgroundCanClose={true}
          showClose={true}
          style={{ height: "600px" }}
          appendToPortal={true}
        >
          <OutputModal activeNode={props.activeNode} />
        </Modal>
      )}
    </React.Fragment>
  );
};
