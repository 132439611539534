import React from "react";
import "./TextInputView.scss";

type TextInputViewProps = {
  value: string;
  onEdit: (value) => void;
  onDelete: (value) => void;
};
const TextInputView: React.FC<TextInputViewProps> = ({
  value = "",
  onEdit,
  onDelete,
}) => {
  return (
    <div className="input-view">
      <div className="input-view-container">
        <div className="input-view-container-left">
          <div>{value}</div>
        </div>
        <div className="input-view-container-right">
          <div className="input-view-container-right-edit" onClick={onEdit}>
            <img
              alt="edit"
              src="https://assets.website-files.com/5d7fdec85e3a80dddda38d90/5d81ae771374b94c6a53c117_modify-icon.svg"
            />
          </div>
          <div className="input-view-container-right-delete" onClick={onDelete}>
            <img
              alt="delete"
              src="https://assets.website-files.com/5d7fdec85e3a80dddda38d90/5d81acbd072bc32f5f569b12_trash-icon.svg"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextInputView;
