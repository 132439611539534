import React from "react";
import Reset from "./select-multiple-components/Reset";
import * as SelectLifecycles from "./lib/select-multiple.lifecycles";
import * as Lib from "./lib/select-multiple.lib";
import * as Events from "./lib/select-multiple.events";
import "../select/select.scss";

interface IProps {
  title: string;
  width?: string;
  titleClassname?: string;
  maxHeight?: string | number;
  dropDownStyle: string;
  onChange?(x: boolean, y: string): void;
  showReset: boolean;
  optionHeight: string | number;
  titleHeight: string | number;
  values: Array<any>;
}

interface IState {
  values: Array<any>;
  display?: string;
  expanded: boolean;
  forceDeselct: number;
}

const DefaultProps = {
  width: "100%",
  dropDownStyle: "pushdown" || "overlay",
  maxHeight: "200",
  titleClassname: "select-title-default",
  showReset: true,
  optionHeight: 35,
  titleHeight: 35,
  values: [],
};

export default class SelectMultiple extends React.Component<IProps, IState> {
  state = {
    values: this.props.values,
    display: "",
    expanded: false,
    forceDeselct: null,
  };
  ref = null;
  private ulRef = React.createRef<HTMLUListElement>();
  private titleBarRef = React.createRef<HTMLLIElement>();

  static defaultProps = DefaultProps;

  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  componentDidMount() {
    SelectLifecycles.didMount.call(this);
    Events.setWindowOnclick.call(this);
    Lib.setContainerBorder.call(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.values !== this.props.values) {
      this.setState({
        values: this.props.values,
      });
    }
  }

  addToValues = value => {
    Events.addToValues.call(this, value);
  };

  onChange = (checked, value) => {
    Events.onChange.call(this, checked, value);
  };

  onTitleClick = e => {
    Events.onTitleClick.call(this, e);
  };

  deselectAll = () => {
    Events.deselectAll.call(this);
  };

  render() {
    const children = SelectLifecycles.initChildren.call(this);
    const totalHeight = Lib.calculateHeight.call(this, children);
    const ulStyle = Lib.getUlStyle.call(this, totalHeight);
    const titleBarStyle = Lib.getTitleStyle.call(this);
    return (
      <div
        className="select-container select-container-contracted svg-wrapper"
        ref={this.ref}
        style={{ width: this.props.width }}
      >
        <ul className={`select-menu select-menu-ul ${this.state.display}`}>
          <li
            className={this.props.titleClassname}
            style={titleBarStyle}
            ref={this.titleBarRef}
          >
            {Lib.renderTitle.call(this)}
            <ul
              style={ulStyle}
              className={this.props.dropDownStyle}
              ref={this.ulRef}
            >
              {this.props.showReset && (
                <Reset
                  deselectAll={this.deselectAll}
                  optionHeight={this.props.optionHeight}
                />
              )}
              {children}
            </ul>
          </li>
        </ul>
      </div>
    );
  }
}
