import React from "react";

export default props => (
  <React.Fragment>
    {/* eslint-disable-next-line */}
    <a
      role="button"
      onClick={props.onClick}
      className={props.foundClassname}
      tabIndex={0}
      href={"# "}
      style={{ flexGrow: "1" }}
    >
      <span>
        <span>{props.nonHighlightedText1}</span>
        <span className="select-search-found-highlight">
          {props.highlightedText}
        </span>
        <span>{props.nonHighlightedText2}</span>
      </span>
    </a>
  </React.Fragment>
);
