import React from "react";

export function renderDefault() {
  if (this.state.text || (this.props.selectedValue && this.props.toggled)) {
    return <React.Fragment />;
  } else {
    let showTitle = this.props.title && !this.state.text;
    if (this.props.toggled && this.props.searchable) {
      showTitle = false;
    }
    const showRequired = this.props.required && !this.props.toggled;
    return (
      <React.Fragment>
        {showRequired && (
          <span className="pl-5">{this.props.requiredText}</span>
        )}
        {showTitle && <span className="pl-5">{this.props.title}</span>}
      </React.Fragment>
    );
  }
}

export function renderSearchable() {
  const value = this.state.text || "";
  const valueLength = this.state.value ? this.state.value.length : 0;
  let size = valueLength + 1 < this.minSize ? this.minSize : valueLength + 1;
  let style = {
    width: !this.props.toggled ? "0px" : "",
  };
  return (
    <div className="flex-column" ref={this.selectRef} style={style}>
      <input
        type="text"
        onClick={this.inputClick}
        value={value}
        className="select-input"
        onChange={this.onChange}
        onKeyUp={this.keyPress}
        size={size > this.props.max - 8 ? this.props.max - 8 : size}
        ref={this.inputRef}
        max={this.props.max}
        title={value}
      />
      {valueLength > 0 && this.state.showClear && (
        <span className="pointer select-clear" onClick={this.clear}>
          &times;
        </span>
      )}
    </div>
  );
}

export function renderNonSearchable() {
  return (
    <React.Fragment>
      {/* eslint-disable-next-line */}
      <a
        role="button"
        onClick={this.onClick}
        data-text={this.state.text}
        data-value={this.state.value}
        href="# "
        style={{ flexGrow: "1" }}
      >
        {this.state.text}
      </a>
    </React.Fragment>
  );
}

export function renderArrow() {
  return (
    <div
      className={`select-arrow ${
        this.props.expanded ? "arrow-up arrow" : "arrow-down arrow"
      }`}
      onClick={this.forceOnclick}
    >
      &nbsp;
    </div>
  );
}
