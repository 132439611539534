import React from "react";
import "./SnippetDetails.scss";
import { SnippetDetailsHeader } from "./snippet-details-components";
import { Provider } from "./snippet-details-lib/snippet-details.context";
import { Wait } from "@components/ui";
import {
  SnippetDetailsBlock,
  SnippetDetailsTabs,
} from "./snippet-details-components";
import { HeaderMain } from "@components/modules";

export default class SnippetDetails extends React.Component {
  componentDidMount = () => {
    this.props.fetchSnippetDetails(this.props.match.params.name);
  };

  render() {
    const context = {
      snippet: this.props.snippetDetails,
    };

    return (
      <Provider value={context}>
        <div className="snippet-details">
          {this.props.isFetchingAsnippet ? (
            <Wait text="Fetching Action Details" />
          ) : this.props.snippetDetailsError ? (
            <HeaderMain title={`Error while fetching the action details ...`} />
          ) : (
            <>
              <SnippetDetailsHeader />
              <SnippetDetailsBlock />
              <div className="mt-50">
                <SnippetDetailsTabs />
              </div>
            </>
          )}
        </div>
      </Provider>
    );
  }
}
