import * as Yup from "yup";

import React, { useState } from "react";
import {
  saveDefaultAccount,
  updateMessage,
} from "@redux/actions/getStarted.actions";
import { useDispatch, useSelector } from "react-redux";

import Api from "@lib/api/api";
import { Button } from "@components/ui";
import { CopyToClipboard } from "react-copy-to-clipboard";
import FormInput from "@containers/SettingsPanel/settings-panel-components/SettingsRightConfigPanel/FormInput";
import { Formik } from "formik";
import Wait from "@components/ui/wait";
import { isEmpty } from "@lib/utils";
import { useHistory } from "react-router-dom";
import uuidv1 from "uuid/v1";

const RightPanel = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const accountId = useSelector(
    state => state.appReducer.appConfig.awsAccountId,
  );
  const userDefaultAccounts = useSelector(
    state => state.getStartedReducer.userDefaultAccounts,
  );
  const isAccountDetailFetched = useSelector(
    state => state.getStartedReducer.isAccountDetailFetched,
  );
  const isFirstUser = isAccountDetailFetched && !userDefaultAccounts.length;
  const loadingMessage = useSelector(state => state.runbooksReducer.message);
  const [isDefault, setIsDefault] = useState(false);
  const initialValues = {
    accountId: accountId,
    externalId: uuidv1(),
    assumedRole: "",
    alias: "",
    preferredRegion: "",
  };
  const validationSchema = Yup.object({
    accountId: Yup.string().required("Account ID is required"),
    externalId: Yup.string().default(""),
    assumedRole: Yup.string().required("AssumeRole ARN is required"),
    alias: Yup.string()
      .required("Alias is required")
      .min(3, "Alias name should have minimum 3 letters")
      .matches(/^\S*$/, { message: "Alias cannot have spaces" })
      .test("alias", "Duplicate Alias", value => {
        return userDefaultAccounts.length
          ? !userDefaultAccounts.filter(acc => acc.alias === value).length
          : true;
      }),
    preferredRegion: Yup.string()
      .required("Preferred Region is required")
      .default(""),
  });

  const buildJSON = formData => {
    return {
      alias: formData.alias,
      role_arn: formData.assumedRole,
      external_id: formData.externalId,
      preferred_region: formData.preferredRegion,
      is_default: isFirstUser ? true : isDefault,
    };
  };
  const formatData = formData => {
    let finalData = {};
    Object.keys(formData).forEach(val => {
      if (formData[val] !== "") {
        finalData[val] = formData[val];
      }
    });
    return finalData;
  };
  const addAccount = async (formData, actions) => {
    dispatch(updateMessage("Saving AWS Account"));
    const finalFormData = formatData(buildJSON(formData));
    try {
      const resp = await Api.saveNewAWSTargetAccount(finalFormData);
      dispatch(updateMessage(""));
      if (!isEmpty(resp?.ERROR) && resp?.ERROR?.isAxiosError) {
        actions.setStatus({
          formSubmitMessage: resp.ERROR?.response?.data?.message,
        });
      } else {
        dispatch(saveDefaultAccount(finalFormData));
        history.push("/settings/aws-settings");
      }
    } catch (error) {
      actions.setStatus({ formSubmitMessage: error.message });
    }
  };

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={addAccount}
    >
      {formik => (
        <div className="instructions__right-aws">
          {loadingMessage && <Wait text={loadingMessage} />}
          <div className="onboarding-form">
            <div className="onboarding-header-wrap">
              <h2>Add AWS Target Account</h2>
            </div>
            <div className="onboarding-card">
              <div className="account-info__wrap">
                <CopyToClipboard
                  text={`${formik.values.accountId}`}
                  onCopy={() => console.log("Account ID copied")}
                >
                  <div className="copy-icon jira-copy-icon"></div>
                </CopyToClipboard>

                <FormInput
                  fieldName="Your Fylamynt Account ID:"
                  name="accountId"
                  id="accountId"
                  handleChange={formik.handleChange}
                  touched={formik.touched}
                  errors={formik.errors}
                  className="copy-text"
                  readOnly
                />
              </div>
              <div className="account-info__wrap">
                <CopyToClipboard
                  text={`${formik.values.externalId}`}
                  onCopy={() => console.log("External ID copied")}
                >
                  <div className="copy-icon jira-copy-icon"></div>
                </CopyToClipboard>

                <FormInput
                  fieldName="External ID:"
                  name="externalId"
                  id="externalId"
                  handleChange={formik.handleChange}
                  touched={formik.touched}
                  errors={formik.errors}
                  className="copy-text"
                  readOnly
                />
              </div>
              <div className="account-info__wrap">
                <FormInput
                  fieldName="AssumeRole ARN:"
                  name="assumedRole"
                  id="assumedRole"
                  handleChange={formik.handleChange}
                  touched={formik.touched}
                  errors={formik.errors}
                  autoComplete="off"
                  maxLength="256"
                />
              </div>
              <div className="account-info__wrap">
                <FormInput
                  fieldName="Account Alias:"
                  name="alias"
                  id="alias"
                  handleChange={formik.handleChange}
                  touched={formik.touched}
                  errors={formik.errors}
                  autoComplete="off"
                  maxLength="256"
                />
              </div>
              <div className="account-info__wrap">
                <FormInput
                  fieldName="Preferred Region:"
                  name="preferredRegion"
                  id="preferredRegion"
                  handleChange={formik.handleChange}
                  touched={formik.touched}
                  errors={formik.errors}
                  autoComplete="off"
                  maxLength="256"
                />
              </div>

              {!isFirstUser ? (
                <div className="d-flex mt-25">
                  <input
                    type="checkbox"
                    className="pointer mr-15 default-checkbox"
                    checked={isDefault}
                    onChange={e => {
                      setIsDefault(!isDefault);
                    }}
                    id={`check-default-target-account`}
                  />
                  <label
                    className="label-name"
                    htmlFor={`check-default-target-account`}
                  >
                    Set As Default
                  </label>
                </div>
              ) : null}
              {!!formik.status && (
                <div className="input-feedback">
                  {formik.status.formSubmitMessage}
                </div>
              )}

              <Button
                text="Add Target Account"
                style={{ width: "100%" }}
                buttonStyle="primary"
                size="large"
                onClick={formik.handleSubmit}
                className={"submit-btn"}
              />
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};

export default RightPanel;
