import React from "react";

/* used by Select to ininitialize the total height of the select component */
export function calculateHeight(children) {
  const optionHeight = parseInt(this.props.optionHeight);
  let totalHeight = children ? children.length * optionHeight : 0;
  if (this.props && this.props.showReset) {
    totalHeight += optionHeight;
  }
  return totalHeight + 1;
}

export function getUlStyle(totalHeight) {
  const style = {
    height: this.state.expanded
      ? `${
          totalHeight < this.props.maxHeight
            ? totalHeight
            : this.props.maxHeight
        }px`
      : "0",
  };
  return style;
}

export function getLiStyle() {
  const style = {
    height: `${this.props.optionHeight}px`,
  };
  return style;
}

export function getTitleStyle() {
  const style = {
    height: `${this.props.titleHeight}px`,
  };
  return style;
}

export function renderTitle() {
  const style = getTitleStyle.call(this);
  return (
    <React.Fragment>
      <div className="flex-column title-bar pointer width-full" style={style}>
        <span className="pl-15">{this.props.title}</span>
        {renderArrow.call(this)}
      </div>
    </React.Fragment>
  );
}

export function renderArrow() {
  return (
    <div
      className={`select-arrow ${
        this.state.expanded ? "arrow-up arrow" : "arrow-down arrow"
      }`}
    >
      &nbsp;
    </div>
  );
}

export function setZIndex() {
  const elements = document.querySelectorAll(".overlay");
  let count = 0;
  for (let i = elements.length; i > 0; i--) {
    const element = elements[count];
    const zIndex = i * 1000;
    element.style.zIndex = zIndex;
    count++;
  }
}

export function setStyle() {
  let style = this.props.style;

  if (this.props.icon) {
    const iconStyle = {
      backgroundImage: `url(${this.props.icon})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "16px 16px",
      backgroundPosition: "5px 50%",
      paddingLeft: "25px",
    };
    style = { ...style, ...iconStyle };
  }
  if (this.props.style && this.props.style.hasOwnProperty("width")) {
    this.setState({
      width: this.props.style.width,
    });
  }
  this.setState({
    style,
  });
}

export function setContainerBorder() {
  const wrapperDiv = this.ulRef.current;
  const titleBarDiv = this.titleBarRef.current;
  const titleBarContracted =
    this.props.required && !this.state.value
      ? "title-bar-contracted-required"
      : "title-bar-contracted";
  if (this.state.expanded) {
    addRemoveCssClass(
      wrapperDiv,
      "select-container-expanded",
      "select-container-contracted",
    );
    addRemoveCssClass(titleBarDiv, "title-bar-expanded", titleBarContracted);
  } else {
    addRemoveCssClass(
      wrapperDiv,
      "select-container-contracted",
      "select-container-expanded",
    );
    addRemoveCssClass(titleBarDiv, titleBarContracted, "title-bar-expanded");
  }
}

function addRemoveCssClass(div, add, remove) {
  div.classList.remove(remove);
  div.classList.add(add);
}
