import { connect } from "react-redux";
import SnippetDetails from "../SnippetDetails";
import { fetchingAsnippetDetails } from "@redux/actions/snippetDetails.actions";

const mapState = ({ snippetDetailsReducer }) => {
  return {
    snippetDetails: snippetDetailsReducer.snippetDetails,
    isFetchingAsnippet: snippetDetailsReducer.isFetchingAsnippet,
    snippetDetailsError: snippetDetailsReducer.snippetDetailsError,
  };
};

const mapDispatch = dispatch => ({
  fetchSnippetDetails: name => {
    dispatch(fetchingAsnippetDetails(name));
  },
});

export default connect(mapState, mapDispatch)(SnippetDetails);
