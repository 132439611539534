import React from "react";
import { Consumer } from "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.context";
import InputsRequired from "./inputs-required";
import InputsOptional from "./inputs-optional";
import { getInputOptions } from "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.helpers";

export default class InputOptions extends React.Component {
  state = {
    requiredOptions: {},
    optionalOptions: {},
    optionFilterId: null,
  };
  timer = null;
  mounted = false;

  constructor(props) {
    super(props);
    document.body.addEventListener("mouseup", e => this.toggleInputOption(e));
  }

  componentDidMount() {
    this.cacheOptions();
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.activeNode !== this.props.activeNode) {
      this.cacheOptions();
    }
  }

  cacheOptions = () => {
    const options = getInputOptions(this.props.activeNode);
    this.setState({
      requiredOptions: options.required,
      optionalOptions: options.optional,
    });
  };

  toggleInputOption = optionFilterId => {
    if (!this.mounted) {
      return;
    }
    if (typeof optionFilterId === "object") {
      const obj = optionFilterId.target;
      if (obj.classList.contains("checkmark")) {
        this.timer = setTimeout(() => {
          this.setState({ optionFilterId: "none" });
        }, 100);
      } else {
        if (obj.type === "text") {
          return;
        }

        this.setState({ optionFilterId: "none" });
      }
    } else {
      this.setState({ optionFilterId });
    }
  };

  render() {
    return (
      <Consumer>
        {({ activeNode, updateActiveNode, runbookObj }) => (
          <React.Fragment>
            <h4 className="editor-node-name">{activeNode.name}</h4>
            <InputsRequired
              activeNode={activeNode}
              updateActiveNode={updateActiveNode}
              requiredInputs={this.state.requiredOptions}
              optionFilterId={this.state.optionFilterId}
              toggleInputOption={this.toggleInputOption}
              runbookObj={runbookObj}
            />
            <InputsOptional
              activeNode={activeNode}
              updateActiveNode={updateActiveNode}
              optionalInputs={this.state.optionalOptions}
              optionFilterId={this.state.optionFilterId}
              toggleInputOption={this.toggleInputOption}
              runbookObj={runbookObj}
            />
          </React.Fragment>
        )}
      </Consumer>
    );
  }
}
