import { StepTypeChecker } from "@containers/RunbookEditor/runbook-editor-lib/neuropssteps/steptypechecker.js";
import { isEmpty, sortResourceList } from "@lib/utils";

export function _searchResourceList(value, callBack) {
  // if snippet inputs didn't define autocomplete attributes, return.
  // attributes at this point after filtering should look like: ["ec2", "InstanceId"]
  const { input } = this.props;
  if (input.resourcesAutocomplete?.length !== 2) {
    return;
  }
  let resourcesService = input.resourcesAutocomplete[0];
  let resourcesAttribute = input.resourcesAutocomplete[1];

  // resources list might not have input required service.
  if (!(resourcesService in this.resourcesList)) {
    return;
  }

  // if current input name not in service dict
  if (
    this.resourcesList.constructor.name === "Object" &&
    !isEmpty(this.resourcesList) &&
    this.resourcesList.hasOwnProperty(resourcesService) &&
    this.resourcesList[resourcesService].length > 0 &&
    !Object.keys(this.resourcesList[resourcesService][0]).includes(
      resourcesAttribute,
    )
  ) {
    console.warn(
      `resource list does not have ${resourcesAttribute}`,
      Object.keys(this.resourcesList[resourcesService][0]),
    );
    return;
  }
  let found = this.resourcesList[resourcesService].filter(item => {
    let values;
    //eslint-disable-next-line
    for (let i in item) {
      values += item[i];
    }
    if (!value) return true;
    return values.includes(value);
  });

  // Sort found array to prioritize array item with is_default property
  if (found && found.length > 0) {
    found = sortResourceList(found);
  }

  this.setState(
    {
      resourcesListSearch: found,
    },
    () => {
      if (callBack) {
        callBack();
      }
    },
  );
}

// calling _updateInputControl
export function _updateInputControl(sourceStepString, sourceOutputString) {
  // notify reducer that current runbook got updates.
  if (sourceStepString && sourceOutputString) {
    try {
      this.notifyRunbookUpdate(true);
      const json = {
        node: this.activeNode,
        value: this.props.input.snippetAction,
      };
      if (this.onChangeCallBack) {
        this.onChangeCallBack(json);
      }
    } catch (e) {
      //console.log(e);
    }
  }
}

export function _updateTextInput(value, name) {
  const element = document.querySelector(`#${name}`);
  if (element) {
    element.value = value;
  }
}

export function getNodeByName(name, runbook) {
  let retval = null;
  Object.keys(runbook.mainStepIndex).forEach(key => {
    if (key === name) {
      retval = {
        extras: {
          runbookNode: runbook.mainStepIndex[key],
        },
      };
    }
  });
  return retval;
}

export function getTypeByName(name, runbook) {
  let node = getNodeByName(name, runbook);
  let type = StepTypeChecker.getType(node);
  return type;
}

export function getType(node) {
  return StepTypeChecker.getType(node);
}
