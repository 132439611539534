import React from "react";
import {
  CustomNameCell,
  CustomTagsCell,
} from "../snippets-components/snippets-table-cells";

export const TableColumns = [
  {
    name: "Action Name",
    selector: "name",
    cell: row => <CustomNameCell name={row.name} />,
    grow: 1.5,
  },
  {
    name: "Description",
    selector: "description",
    grow: 4,
  },
  {
    name: "Service",
    selector: "service",
  },
  {
    name: "Tags",
    selector: "tags",
    cell: row => <CustomTagsCell tags={row.tags} />,
    grow: 1.75,
  },
];
