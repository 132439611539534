function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React from "react";

var _ref2 = React.createElement("desc", null, "Created with Sketch.");

var _ref3 = React.createElement("defs", null, React.createElement("path", {
  d: "M4.2383,1 C4.39780511,1.00026681 4.54767697,1.07637217 4.642,1.205 L5.65415,2.59 L5.95375,3 L12.9231,3 C12.968968,3.00476415 13.0029026,3.04487672 13,3.0909 L13,10.9091 C13.0029026,10.9551233 12.968968,10.9952358 12.9231,11 L1.0769,11 C1.03103204,10.9952358 0.997097424,10.9551233 1,10.9091 L1,1.0909 C0.997097424,1.04487672 1.03103204,1.00476415 1.0769,1 L4.2383,1 L4.2383,1 Z M4.2383,0 L1.0769,0 C0.478296539,0.00390861719 -0.00382314065,0.492295987 -2.25813747e-05,1.0909 L-2.25813747e-05,10.9091 C-0.00382314065,11.507704 0.478296539,11.9960914 1.0769,12 L12.9231,12 C13.5217035,11.9960914 14.0038231,11.507704 14.0000226,10.9091 L14.0000226,3.0909 C14.0038231,2.49229599 13.5217035,2.00390862 12.9231,2 L6.46155,2 L5.4494,0.615 C5.16696292,0.228490485 4.71700691,0 4.2383,0 Z",
  id: "path-11"
}));

var _ref4 = React.createElement("g", {
  id: "icon-folder",
  stroke: "none",
  strokeWidth: 1,
  fill: "none",
  fillRule: "evenodd"
}, React.createElement("g", {
  id: "Artboard-Copy-33"
}, React.createElement("g", {
  id: "icon/file/folder/16"
}, React.createElement("mask", {
  id: "mask-2",
  fill: "white"
}, React.createElement("use", {
  xlinkHref: "#path-11"
})), React.createElement("use", {
  id: "icon-color",
  fill: "#FFFFFF",
  xlinkHref: "#path-11"
}))));

var SvgIconFolder = function SvgIconFolder(_ref) {
  var svgRef = _ref.svgRef,
      title = _ref.title,
      props = _objectWithoutProperties(_ref, ["svgRef", "title"]);

  return React.createElement("svg", _extends({
    width: "14px",
    height: "12px",
    viewBox: "0 0 14 12",
    ref: svgRef
  }, props), title ? React.createElement("title", null, title) : null, _ref2, _ref3, _ref4);
};

var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(SvgIconFolder, _extends({
    svgRef: ref
  }, props));
});
export default __webpack_public_path__ + "static/media/icon-folder.deb908ed.svg";
export { ForwardRef as ReactComponent };