import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Tab, TabCollection, TabContent } from "@components/ui/tabs";
import { Consumer } from "@containers/RunbookEditor/runbook-editor-lib/runbook-editor.context";

import Details from "../../details";
import { InstanaAlertInput } from "./instana-input";
import OutputNonAction from "../../OutputNonAction";

function InstanaConnectorPanel(props) {
  const { activeNode } = props;
  const [outputs, setOutputs] = useState(activeNode?.runbookNode?.outputs);

  useEffect(() => {
    console.log(`outputs changed`, { outputs });
  }, [outputs]);

  return (
    <Consumer>
      {({ notifyRunbookUpdate }) => (
        <TabCollection
          active="input"
          activeClassName="editor-detail-tab-wrap editor-detail-active-tab"
          inactiveClassName="editor-detail-tab-wrap editor-detail-inactive-tab"
          contentTop={50}
        >
          <Tab title="Input" name="input">
            <TabContent>
              <InstanaAlertInput
                activeNode={activeNode}
                runbookNode={activeNode.extras.runbookNode}
                notifyRunbookUpdate={notifyRunbookUpdate}
                setOutputs={setOutputs}
              />
            </TabContent>
          </Tab>
          <Tab title="Output" name="output">
            <TabContent>
              <OutputNonAction outputs={outputs} activeNode={activeNode} />
            </TabContent>
          </Tab>
          <Tab title="Details" name="details">
            <TabContent>
              <Details />
            </TabContent>
          </Tab>
        </TabCollection>
      )}
    </Consumer>
  );
}

const mapState = ({ actionNodeReducer }) => {
  return {
    operations: actionNodeReducer.operations,
  };
};

export default connect(mapState)(InstanaConnectorPanel);
