import React from "react";
import { hasKeys, generateId } from "@lib/utils";

export default props => {
  const activeNode = props.activeNode;
  const outputs = props.outputs || activeNode.extras.runbookNode?.outputs;

  return (
    <div>
      <div className="editor-detail-panel-title">
        <div className="editor-detail-panel-icon editor-detail-panel-icon-select" />
        <div>{activeNode.name}</div>
      </div>
      <div style={{ width: "100%", margin: 20 }}>
        <div className="div-table">
          <div
            className="div-row header"
            style={{ backgroundColor: "#4d4d4d" }}
          >
            <div className="div-cell width-80-pct">Name</div>
            <div className="div-cell width-20-pct">Type</div>
          </div>
          {hasKeys(activeNode, "extras.runbookNode.outputs") &&
            outputs.map(item => (
              <div className="div-row" key={`${item.name}-${generateId()}`}>
                <div className="div-cell">{item.name}</div>
                <div className="div-cell">{item.type}</div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
