import React from "react";
import PropTypes from "prop-types";
import { Provider } from "./accordion.context";
import "./accordion.scss";

export default class Accordion extends React.Component {
  state = {
    isExpanded: this.props.isExpanded,
    useArrow: this.props.useArrow,
    showAnimation: this.props.showAnimation,
    arrowSide: this.props.arrowSide,
  };
  setExpanded = () => {
    this.setState({ isExpanded: !this.state.isExpanded, showAnimation: true });
  };
  render() {
    const contextValue = {
      isExpanded: this.state.isExpanded,
      useArrow: this.state.useArrow,
      setExpanded: this.setExpanded,
      showAnimation: this.state.showAnimation,
      arrowSide: this.state.arrowSide,
    };
    return (
      <Provider value={contextValue}>
        <React.Fragment>{this.props.children}</React.Fragment>
      </Provider>
    );
  }
}

Accordion.propTypes = {
  isExanded: PropTypes.bool,
  useArrow: PropTypes.bool,
  arrowSide: PropTypes.string,
  showAnimation: PropTypes.bool,
};

Accordion.defaultProps = {
  isExanded: false,
  useArrow: false,
  arrowSide: "right",
  showAnimation: true,
};
