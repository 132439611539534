import { Base64 } from "js-base64";
import {
  InvokeLambdaStep,
  readInputSourcesFromSSM,
} from "../ssm/invokelambdastep";
import { SSMActionNames } from "../ssm/strings";
import { RunbookStepInput, RunbookStepOutput } from "../ssm/nodeinputoutput";
import { StepTypes } from "./strings";

export class SnippetStep extends InvokeLambdaStep {
  /**
   * Creates simple SSM Step for the snippet, with no
   * input sources.
   * @param {*} snippetDef
   */
  static fromSnippetDefinition(snippetDef) {
    const name = `${snippetDef.name}_${Math.floor(1000 * Math.random())}`;
    const action = SSMActionNames.INVOKE_LAMBDA;

    const outputs = [];
    let field;
    for (field of Object.keys(snippetDef.outputs)) {
      const output = new RunbookStepOutput(
        null,
        field,
        snippetDef.outputs[field],
      );
      outputs.push(output.toSSM());
    }
    this._addNeurOpsDefaultOutputs(outputs);

    const ssm = {
      name,
      action,
      onFailure: "Abort",
      maxAttempts: 1,
      inputs: {
        FunctionName: snippetDef.content.lambda_arn,
        Payload: "",
        ClientContext: SnippetStep._writeSnippetDetailsForContext(snippetDef),
      },
      outputs,
      isEnd: true,
      editorNodeId: snippetDef.editorNodeId,
    };
    return new SnippetStep(ssm, snippetDef);
  }

  static _addNeurOpsDefaultOutputs(outputs) {
    const exeStatus = new RunbookStepOutput(
      null,
      "execution_status",
      "String",
    ).toSSM();

    if (!outputs.find(out => out.name === "execution_status")) {
      outputs.push(exeStatus);
    }
  }

  /**
   * Write the Snippet detail information in a format that can be packaged into
   * the lambda context.
   */
  static _writeSnippetDetailsForContext(snippetDefinition) {
    const {
      description,
      version,
      tags,
      outputs,
      optional_inputs,
      required_inputs,
    } = snippetDefinition || {};
    const obj = {
      custom: {
        description,
        version,
        tags,
        outputs,
        required_inputs,
        optional_inputs,
      },
    };
    return Base64.encode(JSON.stringify(obj));
  }

  constructor(stepJSON, snippetDef) {
    super(stepJSON);
    this.stepType = StepTypes.SnippetStep;
    this.editable = true;

    this.snippetDef = snippetDef;
    this.parameterInputs = [];
    let field;
    if (snippetDef?.required_inputs) {
      for (field of Object.keys(snippetDef.required_inputs)) {
        this.parameterInputs.push(
          new RunbookStepInput(
            this,
            field,
            snippetDef.required_inputs[field],
            true,
          ),
        );
      }
    }
    if (snippetDef?.optional_inputs) {
      for (field of Object.keys(snippetDef.optional_inputs)) {
        this.parameterInputs.push(
          new RunbookStepInput(
            this,
            field,
            snippetDef.optional_inputs[field],
            false,
          ),
        );
      }
    }
    if (snippetDef?.outputs) {
      for (field of Object.keys(snippetDef.outputs)) {
        // Just check that it is there. The outputs should be entirely
        // defined by the SSM document.
        //eslint-disable-next-line
        const found = this.outputs.find(out => {
          return out.name === field;
        });
        if (!found) {
          console.warn(`${field} not found`);
          console.warn(
            `An output '${field}' is given in a action definition but missing in the SSM step.
            action definition: ${JSON.stringify(snippetDef, undefined, 2)}
              SSM: ${JSON.stringify(this.ssm, undefined, 2)}`,
          );
          this.outputs.push(
            new RunbookStepOutput(this, field, snippetDef.outputs[field]),
          );
        } else {
          // get the type from the snippetDef
          found.type = snippetDef.outputs[field];
        }
      }
    }
  }

  writeInputParams() {
    const inputlist = this.parameterInputs
      .map(input => input.writeInputParam())
      .filter(param => !!param)
      .concat(['"workflow_session": "{{ WorkflowSession }}"'])
      .join(", ");
    return `{ ${inputlist} }`;
  }

  readInputSources(runbook) {
    const inputsWithSources = readInputSourcesFromSSM(this, runbook);
    if (this.parameterInputs) {
      // This is a SnippetStep and already expects certain inputs
      let input;
      for (input of this.parameterInputs) {
        const newSource = inputsWithSources[input.name];
        if (
          newSource &&
          newSource?.type !== "deleted" &&
          !runbook.isNodeDeleted
        )
          input.source = newSource;
      }
    }
  }

  toSSM() {
    const outputs = this.outputs ? this.outputs.map(out => out.toSSM()) : [];
    return {
      name: this.name,
      action: "aws:invokeLambdaFunction",
      onFailure: "Abort",
      maxAttempts: 1,
      inputs: {
        FunctionName: this.snippetDef?.content.lambda_arn,
        Payload: this.writeInputParams(),
        ClientContext: SnippetStep._writeSnippetDetailsForContext(
          this.snippetDef,
        ),
      },
      outputs,
      nextStep: this.nextStep,
      isEnd: !this.nextStep,
    };
  }
}
