import React from "react";
import eyeIcon, {
  ReactComponent as IconEye,
} from "@assets/images/icons/icon-eye.svg";

export default props => (
  <IconEye
    src={eyeIcon}
    className={props.className}
    onClick={props.handleClick}
  />
);
