import React from "react";

interface IProps {
  deselectAll(): void;
  optionHeight: number | string;
}

const Reset: React.SFC<IProps> = props => {
  const doReset = () => {
    console.log("Reset triggered");
    props.deselectAll();
  };

  const style = {
    height: `${props.optionHeight}px`,
  };

  return (
    <li className="li-option" onClick={doReset} data-tag="reset" style={style}>
      <label className="pointer unselect-label">Unselect All</label>
    </li>
  );
};

export default Reset;
