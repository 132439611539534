import React from "react";
import { connect } from "react-redux";
import { Tab, TabCollection, TabContent } from "@components/ui/tabs";
import { ConditionalInput, Output, Details } from ".";

class Conditional extends React.PureComponent {
  render() {
    return (
      <TabCollection
        active="input"
        activeClassName="editor-detail-tab-wrap editor-detail-active-tab"
        inactiveClassName="editor-detail-tab-wrap editor-detail-inactive-tab"
        contentTop={50}
      >
        <Tab title="Input" name="input">
          <TabContent>
            <ConditionalInput
              rerenderEditor={this.props.rerenderEditor}
              addLinkToStep={this.props.addLinkToStep}
            />
          </TabContent>
        </Tab>
        <Tab title="Output" name="output">
          <TabContent>
            <Output />
          </TabContent>
        </Tab>
        <Tab title="Details" name="details">
          <TabContent>
            <Details />
          </TabContent>
        </Tab>
      </TabCollection>
    );
  }
}

const mapState = () => ({});

const mapDispatch = () => ({});

export default connect(mapState, mapDispatch)(Conditional);
