import React from "react";
import { Consumer } from "../../executions-lib/executions.context";
import Options from "./options";
import { TimeSeries } from "@components/ui/visualizations";

class Chart extends React.Component {
  render() {
    return (
      <Consumer>
        {({ filterData, filteredData, groupBy, defaultTimeFilter }) => (
          <div className="width-full">
            <div className="executions-over-time-chart">
              <div
                className="grid-2-columns div-padding-10b font-size-14"
                style={{ height: "200px" }}
              >
                <div>
                  <Options
                    onChange={filterData}
                    defaultOption={defaultTimeFilter}
                  />
                </div>
                <div>
                  <TimeSeries
                    data={filteredData}
                    dateKey={"ExecutionEndTime"}
                    valueKey={"AutomationExecutionStatus"}
                    groupBy={"hour"}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </Consumer>
    );
  }
}

export default Chart;
