import React from "react";
import {
  Accordion,
  AccordionLabel,
  AccordionSection,
  DropDown,
  LabelTop,
  DropDownOption,
} from "@components/ui";
import { updateLinks } from "./../../../../../runbook-editor-lib/runbook-editor.helpers";

export default props => (
  <Accordion isExpanded={true} useArrow={true}>
    <AccordionLabel className="editor-accordion-label">
      4. Destination
    </AccordionLabel>
    <AccordionSection>
    <div className="editor-detail-panel editor-detail-panel-column">
      <div className="editor-terraform-container">
        <LabelTop
          label={`Next Step If This Rule Is Met`}
          labelClassName="label"
        >
          <DropDown
            title={(props.choice && props.choice.nextStep) || ""}
            showArrow={true}
            iconLeft="icon-filter"
            style={{ width: "calc(100% - 8px)", marginTop: "0px" }}
            onChange={newValue => {
              props.choice.nextStep = newValue;
              props.choice.ssmStep.runbook.updateDAGAtStep(
                props.choice.ssmStep,
              );
              updateLinks(
                props.activeNode,
                props.choice.ssmStep,
                props.addLinkToStep,
                props.rerenderEditor,
              );
              props.activeNode.extras.runbookNode.didUpdateStep({});
            }}
          >
            {props.choice.ssmStep.legalSuccessors().map(succ => (
              <DropDownOption text={succ.name} key={succ.name} />
            ))}
          </DropDown>
          </LabelTop>
      </div>
      </div>
    </AccordionSection>
  </Accordion>
);
