import React, { useState } from "react";
import { Link } from "react-router-dom";
import { TextInput } from "@components/ui/";
import { Consumer } from "../schedules-lib/schedules.context";

const SchedulesFilter = props => {
  const [searchTerm, setSearchTerm] = useState(props.searchTerm);
  return (
    <Consumer>
      {({ schedules, searchSchedules }) => {
        return (
          <>
            <div className="d-flex mt-40">
              <TextInput
                placeholder="Search"
                name="schedules-search"
                className="text-input text-input__darker"
                onChange={e => {
                  setSearchTerm(e);
                  searchSchedules(e);
                }}
                value={searchTerm}
              />
            </div>
            <div className="schedules-clear-filters">
              {searchTerm ? `Found` : `You have`} "{schedules.length}" scheduled
              workflows
              <Link
                to={`#`}
                onClick={e => {
                  searchSchedules("");
                  setSearchTerm("");
                }}
              >
                Clear all filters
              </Link>
            </div>
          </>
        );
      }}
    </Consumer>
  );
};

export default SchedulesFilter;
