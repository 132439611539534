import React from "react";
import { Link } from "react-router-dom";
import { toProperCase } from "@lib/utils";

export class CustomTagsCell extends React.Component {
  render() {
    return (
      <>
        {this.props.tags &&
          this.props.tags.map((tag, i) => {
            return (
              <span className="tag" key={i}>
                {tag}
              </span>
            );
          })}
      </>
    );
  }
}

export class CustomNameCell extends React.Component {
  render() {
    return (
      <Link to={`./actions/${this.props.name}`}>
        {toProperCase(this.props.name)}
      </Link>
    );
  }
}
