import React from "react";
import { Link } from "react-router-dom";
import { getUrlParameter } from "@lib/utils";
import Version from "./Version";
import warningSign from "@assets/images/icons/warning-triangle.svg";
import { useSelector } from "react-redux";

const MainMenuLeft = () => {
  const active = getUrlParameter(0);
  const userDefaultAccounts = useSelector(
    state => state.getStartedReducer.userDefaultAccounts,
  );
  const isAccountDetailFetched = useSelector(
    state => state.getStartedReducer.isAccountDetailFetched,
  );

  const user = useSelector(state => state.userReducer);

  const menus = {
    runbooks: {
      url: "workflows",
      display: "Workflows",
      className: "icon icon__runbooks",
    },
    snippets: {
      url: "actions",
      display: "Actions",
      className: "icon icon__snippets",
    },
    executions: {
      url: "executions",
      display: "Executions",
      className: "icon icon__executions",
    },
    schedules: {
      url: "schedules",
      display: "Schedules",
      className: "icon icon__calender",
    },
    settings: {
      url: "settings",
      display: "Settings",
      className: "icon icon__settings ",
    },
  };

  const supportMenus = {
    support: {
      url: "mailto:support@fylamynt.com", //"https://www.fylamynt.com/",
      display: "Support",
      className: "icon icon__external",
    },
    tutorials: {
      url: "https://www.fylamynt.com/",
      display: "Tutorials",
      className: "icon icon__external",
    },
  };

  return (
    <div className="menu-wrap">
      <div>
        <ul className="nav-menu">
          {Object.keys(menus).map((key, idx) => {
            const menu = menus[key];
            return (
              <li
                key={idx}
                className={`nav-menu__item ${
                  menu.url === active ? "w--current" : "w--not-selected"
                } ${menu.className}`}
              >
                <Link to={`/${menu.url}`} className="nav-link">
                  {menu.display}
                </Link>
                {menu.url === "settings" &&
                  isAccountDetailFetched &&
                  !userDefaultAccounts.length && (
                    <img
                      src={warningSign}
                      alt="warningSign"
                      width="20"
                      height="20"
                      className="mr-20"
                    />
                  )}
              </li>
            );
          })}
        </ul>
        <div className="divider-wrap">
          <div className="divider"></div>
        </div>
        <ul className="nav-menu">
          {Object.keys(supportMenus).map((key, idx) => {
            const menu = supportMenus[key];
            return (
              <li
                key={idx}
                className={`nav-menu__item ${
                  menu.url === active ? "w--current" : "w--not-selected"
                } ${menu.className} icon__support`}
              >
                <a
                  href={menu.url}
                  rel="noopener noreferrer"
                  target="_blank"
                  className="nav-link nav-link-support"
                >
                  {menu.display}
                </a>
              </li>
            );
          })}
        </ul>
        <div className="divider-wrap">
          <div className="divider"></div>
        </div>
        <Version />
      </div>
      <Link
        to="/user-profile"
        className={`bottom-section pointer ${
          active === "user-profile" ? "w--current" : "w--not-selected"
        }`}
      >
        <div className="user-details pointer">
          <div className="username">
            {user?.name && !!user.name
              ? user.name
              : user?.email &&
                user?.email.split("@")[0].substring(0, 1).toUpperCase() +
                  user?.email.split("@")[0].substring(1)}
          </div>
          <div className="email">{user?.email}</div>
        </div>
      </Link>
    </div>
  );
};

export default MainMenuLeft;
