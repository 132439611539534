import React from "react";
import { DataTable } from "@components/ui/data-table";
import { TableColumns } from "../snippets-lib/snippets-helper";
import { Consumer } from "../snippets-lib/snippets.context";
import "../snippets.scss";
import { getFilteredSnippets } from "@lib/utils";

export default class SnippetsTableContainer extends React.Component {
  render() {
    return (
      <Consumer>
        {({ snippetsList, searchTerm, filteredServicesSet }) => {
          let list = getFilteredSnippets(
            snippetsList,
            searchTerm,
            filteredServicesSet,
          );
          return (
            <DataTable
              className="snippet-list-table"
              columns={TableColumns}
              data={list}
              responsive={true}
            />
          );
        }}
      </Consumer>
    );
  }
}
